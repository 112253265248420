<template>
    <div>

      <div @click="$emit('update:status', null)"> BACK </div>
    </div>
</template>

<script>
	export default {
		name: "src-pages-components-mobile-categoryFilter",
    props: ['title', 'slug', 'status'],
    data() {
			return { }
		}
	}
</script>

<style scoped>

</style>
