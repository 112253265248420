<template>
  <div>
    <div :class="'mobileNavBar ' + deviceType">

      <div @click="routeBack('/', false)" class="goBack" style="">
        <span v-if="deviceType === 'ios'">
          <span
            style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;"></span>
          <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;" :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
        </span>
        <span v-else>
           <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
        </span>
      </div>
      <div class="title" style=" color: #2d3640;">
        Mesajlaşma
      </div>
      <div class="options">

      </div>
    </div>
    <div :class="'underAppBar ' + deviceType"></div>

    <app-redirect/>
  </div>
</template>

<script>

import appRedirect from "@/pages/components/appRedirect";
  export default {
    components:{appRedirect},
    name: "src-pages-body-workstation-messages-detail-v1_mobile",
    data() {
      return {}
    },
  }

</script>
