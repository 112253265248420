<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style=" overflow-y: scroll;">
      <div class="modal-container" @click.stop>



        <div class="send-custom-offer-main">
          <div class="close-div">
            <img @click="hide()" style="opacity: 1; cursor:pointer;width: 12px; height: 12px; opacity: 0.7;"
                 :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>

          <p class="custom-offer-header">Freelancer Telefon Hattı</p>



          <div class="bia-status-modal-session-container">

            <div class="hiddendiv10"></div>
            <img style="width: 48px; height: 48px; border-radius: 24px;" :src="this.receiver.avatar_url"/>
            <div class="hiddendiv20"></div>


            <div v-if="f_t_info.isActive" class="bia-status-modal-session-time">
              <div class="bia-status-modal-session-time-weekly-usage-container">

                <span class="bia-status-modal-session-time-weekly-usage-info">
                   Telefon
                </span>
                <div class="bia-status-modal-session-time-weekly-usage" >
                  <span v-if="f_t_info.isActive">0 (212) 909 23 10</span>
                  <span v-else> - </span>
                </div>
              </div>
              <div class="bia-status-modal-session-time-weekly-remaining-container">
                <span class="bia-status-modal-session-time-weekly-usage-info" >
                   Dahili Hat
                </span>
                <div class="bia-status-modal-session-time-weekly-usage" >
                  <span v-if="f_t_info.isPublic" style="letter-spacing: 4px">{{f_t_info.line}}</span>
                    <span v-else style="font-weight: 300; font-size: 16px; color: #2d3640;">
                      Gizli
                       <span
                         style="cursor:help;"
                         data-balloon="Freelancer dahili numarasını gizlemeyi tercih ediyor. Kendisine mesaj göndererek telefonla görüşme talebini iletebilirsin."
                         data-balloon-pos="left"
                         data-balloon-length="medium"
                         class="title-badge">
                <img style="width: 12px;" src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" alt="">
              </span>
                    </span>

                </div>
              </div>

            </div>

            <div v-else>
              <div class="warning" style="padding: 16px; line-height: 1.4;  margin-bottom: 16px; font-size:14px;">
                Bu kullanıcının aktif bir dahili hattı yok. Kendisi ile telefonda konuşmak istiyorsan, dahili hat açmasını isteyebilir veya kendi dahilini kullanıcıyla paylaşabilirsin. Bionluk'ta sadece freelancerlar dahili hat açabilir.
              </div>
            </div>


          </div>
          <div class="hiddendiv10"></div>

          <div v-if="f_t_info.isActive" style="margin-top: 15px;">
            <p style=" font-size: 13px;
	font-stretch: normal;
	line-height: 1.21;
	color: #2d3640;">Belirtilen telefon numarası ve dahili numara ile ilgili freelancer'a telefonla ulaşabilirsin. Operatörünün yurt içi sabit hat tarifesinden ücretlendirilirsin.
            </p>
          </div>

          <div v-if="user.seller.isSeller && user.info.f_t_status !== 3" style="margin-top: 24px">
            <router-link to="/settings" style="color: #e63262; font-size: 14px;">Siz de freelancer telefon hattı oluşturun</router-link>
          </div>

        </div>
      </div>

    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-freelancerChatLine-v1-mobile",
    data() {
      return {
        f_t_info:{
          isActive: false,
          isPublic: false,
          line: null
        },
        receiver:{
          avatar_url:""
        }

      }
    },

    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
    },

    created() {


      this.f_t_info = this.$store.state.activeModal.info.f_t_info;
      this.receiver = this.$store.state.activeModal.info.receiver;

    },

    beforeDestroy() {
      //clearInterval(this.interval);
    }
  }
</script>


<style scoped lang="scss">



  input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;
    /*required for proper track sizing in FF*/
    width: 100%;
    padding: 0;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: #d7dde3;
    border: 0;
    border-radius: 3px;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fd4056;
    margin-top: -4px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #d7dde3;;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: #d7dde3;
    border: none;
    border-radius: 3px;
  }

  input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fd4056;
  }


  /*hide the outline behind the border*/

  input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }

  input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fd4056;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #888;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
  }


  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: 80% !important;

    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    padding:20px;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .send-custom-offer-main {



    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-offer-header {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-label {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .price-duration {
    display: flex;
  }


  .super-button {
    min-width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #FD4056;
  }


  .bia-status-modal-header{
    margin-bottom: 10px;
  }

  .bia-status-modal-header-text{
    font-size: 32px;
    font-weight: 600;
    color: #2d3640;
    text-align: center;
  }





  .bia-status-modal-session-time-weekly-usage{
    font-size: 15px;
    font-weight: 500;
    color: #333333;

  }

  .bia-status-modal-session-time-weekly-usage-info{
    font-size: 12px;
    line-height: 1.17;
    color: #8b95a1;
  }

  .bia-status-modal-session-time-weekly-usage-type{
    font-size: 14px;
  }

  .bia-status-modal-info-text{
    font-size: 13px;
    font-stretch: normal;
    line-height: 1.21;
    color: rgb(45, 54, 64);
    text-align: center;
  }

  .bia-status-modal-info-text-link{
    cursor: pointer;
    text-decoration: underline;
    color: #fd4056;
  }

  .bia-status-modal-session-progress-bar-value{
    width: 14px;
    display: flex;
    justify-content: center;
  }

  .bia-status-modal-session-progress-bar-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bia-status-modal-session-time-weekly-remaining-section,
  .bia-status-modal-session-time-weekly-usage-section{
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .seperator{
    margin: 0;
    width: 2px;
    height: 100%;
    background-color: #efefef;
  }

  .bia-status-modal-action-button-container{
    display: flex;
    justify-content: center;
    margin: 10px 0 50px;
  }

  .bia-status-modal-remaining-timer-container{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bia-status-modal-remaining-timer{
    display: flex;
    justify-content: center;
    font-size: 36px;
    color: #333;
  }

  .bia-status-modal-session-container{
    margin-top: 20px;


    width: 95%!important;
    color: #2d3640;
    padding: 10px;
    border-radius: 2px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bia-status-modal-session-time{
    width: 100%;
    display: flex;
    padding-bottom: 10px;

  }

  .bia-status-modal-session-time-weekly-usage-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 80px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: solid 1px #dfe4ec;
  }



  .bia-status-modal-session-time-weekly-remaining-container {
    margin-left: 24px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: #fff;
    border: solid 1px #dfe4ec;
  }



  .warning {


      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 110px;
      background-color: #fff;
      border: solid 1px #dfe4ec;

  }

</style>
