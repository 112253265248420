<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div @click="slide('back')" class="backBox" :style="slideNo > 0 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg">
      </div>
      <div @click="slide('next')" class="nextBox" :style="totalSlides - slideNo > 3 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg">
      </div>
      <div style="position: relative!important; overflow: hidden">
        <div class="cardBoxContainer"  :style="'height:340px; transform: translate3d(-'+(308*slideNo)+'px, 0px, 0px);'">


          <div v-if="user.seller.isSeller && !user.seller.gigCount && type !== 'youMayNeedGigs' && type !== 'bia'" class="cardBoxFlexC">
            <div class="gigBox">
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                to="/panel/ilanlar/yeni">
                <div v-if="user.seller.oldGigCount" class="bg" style="background-image: url(https://gcdn.bionluk.com/site/cicons/reg-gi-g-image%402x.png)"></div>
                <div v-else class="bg" style="background-image: url(https://gcdn.bionluk.com/site/cicons/gi-g-image%402x.png)"></div>
              </router-link>

              <div style="width: 100%;">
                <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
                  <div style="width: 40px; padding-left: 16px;">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                      :to="'/'+user.username">
                      <img class="avatar" :src="user.avatar_url">
                    </router-link>
                  </div>
                  <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
                    <div style="height: 40px;">
                      <p class="username"><router-link
                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                        @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                        :to="'/'+user.username">{{user.username}}</router-link></p>
                      <p class="title">{{user.info.title}}</p>
                    </div>
                    <div style="height: 40px; padding-right: 16px;">
                      <p class="price">500<span>TL</span></p>
                      <p class="helpText">Başlangıç</p>
                    </div>
                  </div>
                </div>
              </div>

              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                class="gigTitleLink" to="/panel/ilanlar/yeni">
                <p v-if="user.seller.oldGigCount" class="gigTitle">Sevgili {{this.user.firstname ? this.user.firstname : this.user.username}}, yeni bir iş ilanı oluşturmak için buraya tıkla</p>
                <p v-else class="gigTitle">Sevgili {{this.user.firstname ? this.user.firstname : this.user.username}}, bu senin Bionluk Freelancer’ı olarak ilk iş ilanın olabilir</p>
              </router-link>
            </div>
          </div>


          <div class="cardBoxFlexC" v-for="(item, itemIndex) in items" :key="item.uuid">
            <div class="gigBox" style="background-color: white">
              <template v-if="item.seller.username === user.username">
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                  :to="'/panel/ilanlar/duzenle/'+item.uuid" class="likeAreaWhite">
                  <img class="likeIconWhite" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)">
                </router-link>
              </template>
              <div v-if="item.rating && item.rating.commentCount" class="starArea">
                <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                <p class="rate">{{item.rating.commentRating}}</p>
                <p class="count">({{item.rating.commentCount}})</p>
              </div>
              <div v-if="item.portfolios[0].fileType === 'audio'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <div v-if="item.portfolios[0].fileType === 'video'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'gig', impressionUUID, {gig_id:item.gig_id, seller_id:item.seller.id})"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'gig', impressionUUID,{gig_id:item.gig_id, seller_id:item.seller.id})"
                :to="'/'+item.seller.username+'/'+item.slug+'-'+item.gig_id">
                <div class="bg" :style="'background-image: url('+item.portfolios[0].image_url+')'">
                </div>
              </router-link>

              <div style="width: 100%;">
                <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
                  <div style="width: 40px; padding-left: 16px;">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                      :to="'/'+item.seller.username">
                      <img class="avatar" :src="item.seller.avatar">
                    </router-link>
                  </div>
                  <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
                    <div style="height: 40px;">
                      <p class="username"><router-link
                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                        @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                        :to="'/'+item.seller.username">{{item.seller.username}}</router-link></p>
                      <p class="title">{{item.seller.title}}</p>
                    </div>
                    <div style="height: 40px; padding-right: 16px;">
                      <p class="price" :style="item.price && String(item.price).length > 5 ? 'letter-spacing: -2.5px': null">{{item.price}}<span>TL</span></p>
                      <p class="helpText">{{item.priceHelpText}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'gig', impressionUUID, {gig_id:item.gig_id, seller_id:item.seller.id})"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'gig', impressionUUID, {gig_id:item.gig_id, seller_id:item.seller.id})"
                class="gigTitleLink" :to="'/'+item.seller.username+'/'+item.slug+'-'+item.gig_id">
                <p class="gigTitle" style="height: 48px">{{item.title}}</p>
              </router-link>
              <div v-if="item.seller.username !== user.username" style="width: 90%;">
                <p style="border-bottom: 1px solid #eff3f8; height: 10px"></p>
                <div class="add-list-area">
                  <div v-show="item.likeStatus.action !== -1" class="add-list-icon-container" @click="processAddListOpen(itemIndex, item.gig_id)">
                    <img class="add-list-icon" src="https://gcdn.bionluk.com/uploads/message/00f6fa53-091c-4f3e-a086-2c499c2f7fce.svg" onload="SVGInject(this)"/>
                  </div>
                  <div v-show="item.likeStatus.action === -1" class="add-list-icon-container" @click="processAddListOpen(itemIndex, item.gig_id)">
                    <img class="add-list-icon-red" src="https://gcdn.bionluk.com/uploads/message/205977ee-c82d-426d-93f7-ec98970c9fe3.svg" onload="SVGInject(this)">
                  </div>
                  <p v-if="item.likeStatus.action !== -1" @click="processAddListOpen(itemIndex, item.gig_id)" class="add-list-text">Listeye Ekle</p>
                  <p v-else-if="item.likeStatus.action === -1" @click="processAddListOpen(itemIndex, item.gig_id)" class="add-list-text-red">Listemde</p>

                  <div v-click-away="() => addListOpen = false" v-if="addListOpen && addListOpenIndex === itemIndex" class="setting-box">
                    <loader style="width: 30px; height: 30px" v-if="buttonLoading"/>
                    <template v-else>
                      <div class="setting-box-options-overflow">
                        <div class="setting-box-options" v-for="(liste, lin) in lists" :key="lin" :style="lin + 1 !== lists.length ? ' border-bottom: solid 1px #eaedf2;' : ''">
                          <custom-check
                            :loading="checkLoading"
                            called-from="singlegig"
                            :label="liste.title"
                            :single-gig="liste.counts"
                            :id="'list_'+item.uuid+'_'+liste.id"
                            :checked="liste.liked"
                            :value="liste.liked"
                            @input="likeChecked(liste,lin, itemIndex, item.gig_id)"
                          />



                        </div>


                        <div  class="settings-box-add-new mt-3" @click="openAddListModal">
                          <div class="ml-2">Yeni Liste Oluştur</div>
                          <div class="rounded-plus-button-gray">

                            <img class="icon" src="https://storage.googleapis.com/superlancer.appspot.com/public/ic-plus.svg" onload="SVGInject(this)">
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.type === 'ownGigs' && user.info.total_bicoin === 1000" class="cardBoxFlexC">
            <div class="gigBox">
              <div style="display: flex; margin-top: 35px;">
                <img src="https://gcdn.bionluk.com/site/cicons/bicoin.svg">
                <div style="margin-left: 15px;">
                  <p style="color: #fd4056; font-size: 40px; font-weight: bold;">{{user.info.total_bicoin}}</p>
                  <p style="color: #8b95a1; font-weight: 300; font-size: 12px;">Toplam bicoin</p>
                </div>
              </div>
              <p style="margin-top:38px;color: #2d3640; font-size: 16px; font-weight: bold">İlanlarını Öne Çıkar</p>
              <p style="margin-top:10px;width:220px;color: #2d3640; line-height: 1.43; font-size:14px;">bicoin’lerini kullanarak iş ilanını kategori sayfalarında öne çıkar.</p>
              <router-link to="/panel/bicoin" class="bia-button-gig-c">bicoin'lerini Kullan</router-link>
            </div>
          </div>
          <div v-if="1===2 && this.type === 'ownGigs'" class="cardBoxFlexC">
            <div class="gigBox">
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                to="/panel/ilanlar/yeni">
                <div class="bg" style="background-image: url(https://gcdn.bionluk.com/site/cicons/reg-gi-g-image%402x.png)">
                </div>
              </router-link>

              <div style="width: 100%;">
                <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
                  <div style="width: 40px; padding-left: 16px;">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                      :to="'/'+user.username">
                      <img class="avatar" :src="user.avatar_url">
                    </router-link>
                  </div>
                  <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
                    <div style="height: 40px;">
                      <p class="username"><router-link
                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, 'profile')"
                        @click.native="handleGlobalClick($store.state.previousComponentPage,type, 'profile')"
                        :to="'/'+user.username">{{user.username}}</router-link></p>
                      <p class="title">{{user.info.title}}</p>
                    </div>
                    <div style="height: 40px; padding-right: 16px;">
                      <p class="price">500<span>TL</span></p>
                      <p class="helpText">Başlangıç</p>
                    </div>
                  </div>
                </div>
              </div>

              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                class="gigTitleLink" to="/panel/ilanlar/yeni">
                <p class="gigTitle">Sevgili {{this.user.firstname ? this.user.firstname : this.user.username}}, yeni bir ilan eklemek için buraya tıkla.</p>
              </router-link>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";
	import CustomCheck from "@/pages/components/CustomCheck";
  export default {
    props: ["list","type", "impressionUUID"],
	  components:{Loader, CustomCheck},
    data() {
      return {
	      pageLoaded:false,
	      slideNo: 0,
	      sliding:false,
        items: this.list || [],
        addListOpen:false,
        addListOpenIndex:null,
        buttonLoading:false,
        lists:[],
        checkLoading:false
      };
    },

    methods:{
      processAddListOpen(itemIndex, gig_id){
        if(this.buttonLoading) return false;
        this.addListOpen = false;
        this.addListOpenIndex = itemIndex;
        this.lists = [];
        this.addListOpen = true;
        this.getLists(gig_id)
      },
      getLists(gig_id){
        this.buttonLoading = true;
        this.api.general.crudList('get_all', '', null, gig_id, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.lists = result.data.lists
            this.buttonLoading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          })

      },
      likeChecked(l,index, itemIndex, gig_id){
        if(l.liked){
          this.lists[index].liked = false;
          this.lists[index].counts.gig--;
          this.removeFromList(l.id, gig_id);
          this.items[itemIndex].likeStatus = {action:1}
          this.lists.forEach((ll) => {
            if(ll.liked){
              this.items[itemIndex].likeStatus = {action:-1}
            }
          })
        } else {
          this.lists[index].liked = true;
          this.lists[index].counts.gig++;
          this.addToList(l.id, gig_id);
          this.items[itemIndex].likeStatus = {action:-1}
        }
      },

      openAddListModal(){
        this.addListOpen = false;
        let data = {
          type: 'create',

        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_LIST, info: {data: data}});

      },
      addToList(listId,gig_id){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        let page = this.$store.state.componentMap.page;
        this.api.general.crudList('like_item', 'gig', listId, gig_id, this.$Progress, 0, page)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      removeFromList(listId, gig_id){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        this.api.general.crudList('delete_item', 'gig', listId, gig_id, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

	    retrieveItems() {
		    if(!this.list || this.list.length === 0){
			    this.pageLoaded = false;

			    let postedKind = this.type === 'youMayNeedGigs' && this.user.openOrders && this.user.openOrders ? this.user.openOrders && this.user.openOrders[0] && this.user.openOrders[0].category_sub_id ? this.user.openOrders[0].category_sub_id : this.user.info.lastOrderSubCategoryId : null;

			    this.api.feeds.newMainPage(this.type, postedKind)
				    .then(({data}) => {
					    let result = data;
					    this.pageLoaded = true;
					    if (result.success) {
                let trackGigObj = [];
                result.data.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.gig_id,
                    seller_id:gig.seller.id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'today',
                  placement:this.type,
                  info:trackGigObj,
                  title: this.impressionUUID
                };

                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);

                let items = result.data;
                this.api.general.getGigLikesV2(items.map(item => item.gig_id), 'null', '',  '')
                  .then(({data}) => {
                    let likeResult = data
                    items.forEach(gig => {
                      let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === String(gig.gig_id))
                      if (foundLike) {
                        gig.likeStatus = foundLike.likeStatus
                      }
                    })
                  })
						    this.items = items;
						    if(this.type === 'inspiringGigs'){
                  this.$store.state.inspiringGigs = items;
						    } if(this.type === 'youMayNeedGigs'){
                  this.$store.state.youMayNeedGigs = items;
						    } if(this.type === 'ownSimilarGigs'){
							    this.$store.state.ownSimilarGigs = items;
						    }
					    } else {
						    this.pageLoaded = true;
					    }
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: err});
				    }).finally(() => {

          });
		    } else {
			    this.pageLoaded = true;
        }
	    },
	    slide(what){

		    this.sliding = true;
		    setTimeout(function(){
			    this.sliding = false;
		    }, 500);

		    if(what === 'next'){
			    this.slideNo++;
		    } else{
			    this.slideNo--;
		    }

	    },


    },

    computed:{
    	totalSlides: function () {
    		if(this.type === 'inspiringGigs' && this.$store.state.user.seller.isSeller && !this.$store.state.user.seller.gigCount){
    			return this.items.length + 1;
        } else if(this.type === 'ownGigs'){
          if(this.$store.state.user.info.total_bicoin === 1000){
	          return this.items.length + 2;
          } else {
	          return this.items.length + 1;
          }
        } else {
			    return this.items.length;
        }


	    }

    },

    created() {
	    this.retrieveItems();
    }
  }
</script>

<style scoped lang="scss">



.rounded-plus-button{
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/superlancer.appspot.com/public/rounded-border-bg.svg);
  background-size: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon /deep/ {
    height:14px;
    width: 14px;
    path {
      fill: #65c4db
    }
  }
}

.rounded-plus-button-gray{
  width: 24px;
  height: 24px;
  background: #eff3f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  .icon /deep/ {
    height:11px;
    width: 11px;
    path {
      fill: #8b95a1
    }
  }
}

.setting-box {
  position: absolute;
  width: 240px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 0 20px 20px;


  //    border: solid 1px #bfc8d2;
  left: -15px;
  bottom: 30px;
  z-index: 99910;
  box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.2);
  border: solid 1px rgba(94, 107, 121, 0.2);


  .setting-box-options-overflow{
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    //padding-right: 1px;
    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dde3eb;
    }
  }
  .setting-box-options {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: #2d3740;
    }
    .setting-box-options-title{
      font-size: 16px;
      color: #5e6b79;
      text-overflow: ellipsis;
      width: 180px;
      white-space: pre;
      overflow: hidden;
    }

    .setting-box-options-sub-title{
      font-size: 11px;
      margin-top: 4px;
      font-style: italic;
      color: #8b95a1;

    }
  }

  .settings-box-add-new {
    width: 218px;
    height: 36px;
    border-radius: 5px;
    margin-top: 10px;

    display: flex;
    align-items: center;


    justify-content: space-around;

    cursor:pointer;color:#5e6b79;font-size:14px;
    border: dashed 2px #bfc8d2;
    &:hover{
      border-color: #5e6b79;
    }
    .te{
      font-size: 14px;
      color: #5e6b79;
    }
  }
}

.setting-box::after {
  content: '';
  position: absolute;
  left: 15px;
  bottom: -7.8px;
  width: 15px;
  height: 15px;
  border-bottom: solid 1px rgba(94, 107, 121, 0.2);
  border-right: solid 1px rgba(94, 107, 121, 0.2);
  box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.2);
  border-top: 0;
  border-left: 0;
  border-radius: 20% 0 0 0 / 25%;
  transform: rotate(45deg);
  background-color: #fff;
}


.add-list-area{
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;

  .add-list-text{
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #2d3640;
    margin-left: 5px;
  }
  .add-list-text-red{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fd4056;
    margin-left: 5px;
  }
  .add-list-icon-container{
    cursor: pointer;
    .add-list-icon /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #8b95a1;
      }
    }
    .add-list-icon-red /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #fd4056;
      }
    }
  }

  &:hover{
    .add-list-icon-container{
      .add-list-icon /deep/{
        width: 20px;
        height: 20px;
        path{
          fill: #5e6b79;
        }
      }
    }
  }
}
  .nextBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    right: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }


  .backBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }
  .cardBoxContainer{

    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;

    .cardBoxFlexC{

      display: flex;

    }


    .gigBox{

      position: relative;
      display: flex;



      flex-direction: column;
      align-items: center;
      margin-right: 28px;

      width: 280px;
      height: 333px;
      //height: 316px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;


      .bg{

        position:relative;width:280px; border-top-left-radius: 10px; border-top-right-radius: 10px; height:158px; background-size: contain; background-repeat: no-repeat;
      }

      .starArea{
        z-index: 33;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 113px;
        width: 100px;
        height: 30px;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;

        img{

          width: 16px;
          height: 16px;
        }

        .rate{

          margin-left: 3px;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffbe00;

        }

        .count{

          margin-left: 2px;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d3640;
        }
      }

      .likeAreaWhite{
        cursor: pointer;
        position: absolute;
        z-index: 33;
        right: 0;
        width: 40px;
        height: 40px;
        background-color: #2d3640;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .likeIconWhite /deep/ {
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }
        &:hover{
          .likeIconWhite /deep/ {
            width: 18px;
            height: 18px;
            path {
              fill: #fd4056;
            }
          }
        }


      }
      .likeAreaRed{
        cursor: pointer;
        position: absolute;
        z-index: 1;
        right: 0;
        width: 40px;
        height: 40px;
        background-color: #fd4056;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .likeIconRed /deep/ {
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }
      }
      .avatar{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
      .username{
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #2d3640;
      }

      .title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
        font-size: 13px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #8b95a1;
      }
      .price{
        font-size: 26px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;

        letter-spacing: -1.5px;
        margin-top: -8px;
        text-align: right;
        color: #26ca9a;
        span{
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;

          letter-spacing: normal;
          text-align: center;
          color: #26ca9a;
          padding-left: 3px;
        }
      }

      .helpText{
        font-size: 11px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #8b95a1;
      }

      .gigTitle{

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 250px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2d3640;
      }

      .gigTitleLink{
        &:hover{
          text-decoration: none!important;
        }
      }
    }


  }

  .bia-button-gig-c{

    margin-top: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 180px;
    height: 42px;
    padding-bottom: 2px;
    border-radius: 2px;
    border: solid 1px rgba(45, 54, 64, 0.2);

    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3640;
    transition: .2s;
    &:hover{
      background-color: #fd4056!important;
      color:#fff;
      text-decoration: none;
    }
    a{
      text-decoration: none;
      &:hover{
        color:#fff
      }
    }
  }


  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>
