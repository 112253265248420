/**
 * Created by haluk on 4/14/17.
 */

const v1 = resolve => require(['./v1.vue'], resolve);

export default {
  v1
}

