<template>
  <div class="input-body">
    <template v-if="inputType === null">
      <button
          :id="id"
          type="button"
          class="select-box-button"
          :class="value === 0 || !!value ? 'hasValue' : null"
          :style="getInputStyle('input')"
          @click="clickSelectBox()"
          @blur="selectEvents"
      >
        <span>{{ getSelectValue }}</span>
        <span class="select-arrow-down" />
      </button>
      <label v-if="label" class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <p class="helpText ml-1" v-if="helpText">{{ helpText }}</p>
      <ul v-if="openBox && !disabled" class="select-box" style="margin-top: 10px">
        <li v-for="(option, index) in options1" :key="index" class="select-box-item" :style="labelPosition === 'center' ? 'text-align: center;' : null" @click="selectBoxClick(option)" :rel="index">
          <a :tabindex="0">
            <span :style="hoveredOption1 === (customOption ? option[customOption.value] : option) ? 'background-color: #f4f5f5; padding: 1px 10px;' : ''">{{customOption ? option[customOption.name] : option}}</span>
          </a>
        </li>
      </ul>
    </template>

    <template v-if="inputType === 'search'">
      <input :ref="id"
             :id="id"
             :value="value"
             :autocomplete="autocomplete"
             :maxlength="maxlength"
             :placeholder="placeHolder"
             :disabled="disabled"
             class="input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             v-click-away="() =>  clickAway()"
             required />
      <label v-if="label" class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <span class="select-arrow-down" />
      <p v-if="helpText" class="helpText ml-1">{{ helpText }}</p>
      <ul v-if="openBox && options1.length" :class="['select-box', { 'paddingBottom0' : !exactMatchExists }, isInViewport(id) ? 'bottom' : 'top']">
        <li v-for="(option, index) in options1" :key="index" class="select-box-item" :style="labelPosition === 'center' ? 'text-align: center;' : null" @click="selectBoxClickForSearch(option)" :rel="index">
          <a :tabindex="0">
            <span>{{customOption ? option[customOption.name] : option}}</span>
          </a>
        </li>
        <li v-if="!exactMatchExists && suggestionEnabled" class="not-founded-select-box-item suggestion" @click="selectBoxClickForSearch(value, true)">
          <span style="font-size: 19px; padding-left: 5px;">+</span> Sen Öner: “{{value}}"
        </li>
      </ul>
      <!--suggestion-->
      <ul v-if="openBox && !options1.length && value" class="select-box mt-1" style="padding-bottom: 0;">
        <slot>
          <li class="not-founded-select-box-item suggestion-text">
            Böyle bir sonuç bulamadık. 😔 İstersen bunu bize öneri olarak gönderebilirsin.
          </li>
          <li class="not-founded-select-box-item suggestion" @click="selectBoxClickForSearch(value, true)">
            <span style="font-size: 19px;">+</span> Sen Öner: “{{value}}"
          </li>
        </slot>
      </ul>
    </template>
  </div>
</template>

<script>
import _isNil from 'lodash/isNil'

/**
   * Daha stabil calismasi icin id verilmeli.
   * Kredi karti tarihleri listelenecek ise name propertysi olarak expireMonth ve expireYear vermek yeterli olacaktir.
   * Selectbox icin width verilmeli
   * Selectbox listelenecek name ve geri dondurulecek value icin ex: customOption:{value: 'skill', name: 'skillName'}
   */


  export default {
    name: 'CustomSelect',
    props: {
      name: {
        type: String,
        default: null
      },
      autocomplete: {
        type: String,
        default: 'off'
      },
      inputType: {
        type: String,
        default: null
      },
      value: {
        default: null
      },
      label: {
        type: String,
        default: null,
      },
      placeHolder: {
        type: String,
        default: '',
      },
      helpText: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: 'inputId'
      },
      valid: {
        type: Boolean,
        default: null
      },
      options: {
        type: Array,
        default: () => []
      },
      exactMatchExists: {
        default: false
      },
      customOption: {
        type: Object,
        default: null
      },

      hoveredOption: {
        default: null
      },

      labelPosition: {
        type: String,
        default: 'left'
      },
      maxlength: {
        type: String,
        default: undefined
      },

      disabled: {
        type: Boolean,
        default: false
      },
      suggestionEnabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        openBox: false,
        options1: this.options,
        hoveredOption1: this.hoveredOption,
        hasFocus: false,
      }
    },

    methods: {
      isInViewport(id) {
        const el = document.getElementById(id)
        const rect = el.getBoundingClientRect()
        return (
            rect.top >= 0 &&
            (rect.bottom + 300) <= (window.innerHeight || document.documentElement.clientHeight)
        )
      },
      handleInput(e) {
        this.$emit('input', e.target.value);
      },
      inputFocus(event, focus) {
        this.hasFocus = focus;
        if (focus) {
          document.getElementById(this.id).focus();
          this.$emit('focus', event);
          this.openBox = focus;
        } else {
          document.getElementById(this.id).blur();
          this.$emit('blur', event);
        }
      },
      getInputStyle(type) {
        let style = '';
        switch (type) {
          case 'input':
            style = this.labelPosition === 'center' ? 'justify-content: center;' : 'padding-left: 20px;';
            if ((_isNil(this.valid) && (this.value === 0 || !!this.value)) || this.openBox || this.hasFocus) {
              style = style + 'borderColor: #2d3640;'
            } else if (this.valid === true && (this.value === 0 || !!this.value)) {
              style = style + 'borderColor: #49A278;'
            } else if (this.valid === false && (this.value === 0 || !!this.value)) {
              style = style + 'borderColor: #FD4056;'
            } else {
              style = style + 'borderColor: #CACACA;'
            }


            return style;
          case 'label':
            if ((_isNil(this.valid) && (this.value === 0 || !!this.value)) || this.openBox || this.hasFocus) {
              style = style + 'color: #2d3640;'
            } else if (this.valid === true && (this.value === 0 || !!this.value)) {
              style = style + 'color: #00a575;'
            } else if (this.valid === false && (this.value === 0 || !!this.value)) {
              style = style + 'color: #FD4056;'
            } else {
              style = style + 'color: #8b95a1;'
            }

            return style;
        }

      },
      selectBoxClick(option) {
        if (this.customOption === 0 || this.customOption) {
          this.$emit('input', option[this.customOption.value]);
        }
        else {
          this.$emit('input', option);
        }
        this.closeSelectBox();
      },
      selectBoxClickForSearch(option, suggestion = false) {
        if (suggestion) {
          const suggestionObj = {
            isSuggestion: true,
            value: option
          };
          this.$emit('change', suggestionObj);
        } else {
          this.$emit('change', option);
        }
        this.closeSelectBox();
      },
      clickSelectBox() {
        this.openBox = !this.openBox;
      },
      selectEvents(event) {
        if (!event.relatedTarget || (event.relatedTarget && event.relatedTarget.parentElement.className !== 'select-box-item')) this.openBox = false;
      },
      closeSelectBox() {
        this.openBox = false;
      },
      clickAway() {
        this.openBox = false;
      }
    },
    watch: {
      options(newVal) {
        if (newVal) {
          this.options1 = this.options;
        }
      },
      hoveredOption(newVal) {
        if (newVal) {
          this.hoveredOption1 = this.hoveredOption;
        }
      }
    },
    computed: {
      getSelectValue() {
        if (this.customOption) {
          let foundObj = this.options.find(option => option[this.customOption.value] === this.value)
          if (foundObj) {
            return foundObj[this.customOption.name]
          } else {
            return this.value
          }
        } else {
          return this.value
        }
      }
    },
    created() {
      if (this.name === 'expireMonth') {
        this.options1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.hoveredOption1 = new Date().getMonth() + 1
      } else if (this.name === 'expireYear') {
        let arr = [];
        for (let i = new Date().getFullYear(); i < 2035; i++) {
          arr.push(i);
        }
        this.options1 = arr;
        this.hoveredOption1 = new Date().getFullYear();
      }
    },

  }
</script>

<style scoped lang="scss">

  .paddingBottom0 {
    padding-bottom: 0px!important;
  }

  .input-body {
    position: relative;
    width: fit-content;
  }

  .input {
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position-x: 270px !important;
    -ms-background-position-x: 270px !important;
    appearance: none;
    padding: 0 30px 0 20px;
    max-width: 100%;
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

  .input:disabled + .input-label, .input:disabled {
    background-color: #eaedf2;
    border: none;

  }

  .select-box-button {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position-x: 270px !important;
    -ms-background-position-x: 270px !important;
    appearance: none;
    padding: 0 10px 0 0;
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid #CACACA;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #808896;;
    background-color: #fff !important;

    &:hover {
      background-color: #fff !important;
    }
  }

  .input-label {
    pointer-events: none;
    background-color: #fff;
    font-size: 16px;
    font-weight: 300;
    position: absolute;
    top: 14px;
    left: 17px;
    color: #808896;
    padding: 0 3px;
    margin-bottom: 0;

    transform-origin: 0 0;

    -ms-transition: all 200ms ease;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .input:focus + .input-label, .input:valid + .input-label, .hasValue + .input-label {
    transform-origin: 0 0;
    -ms-transform: translate3d(-7px, -21px, 0) scale(0.75);
    -webkit-transform: translate3d(-7px, -21px, 0) scale(0.75);
    -moz-transform: translate3d(-7px, -21px, 0) scale(0.75);
    -o-transform: translate3d(-7px, -21px, 0) scale(0.75);
    transform: translate3d(-7px, -21px, 0) scale(0.75);
  }

  .input::placeholder {
    font-weight: 300;
    color: #8b95a1;
    font-size: 16px;
    opacity: 0;
  }

  .input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  .input:focus::-moz-placeholder {
    opacity: 1;
  }

  .input:focus:-ms-input-placeholder {
    opacity: 1;
  }

  .input:focus:-moz-placeholder {
    opacity: 1;
  }

  .arrow-down {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto;
    opacity: .4;
    pointer-events: none;
  }

  .select-box {
    z-index: 10;
    padding: 10px 14px;
    position: absolute;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    right: 0;
    left: 0;
    background-color: #fff;
    max-height: 300px;
    overflow-y: auto;
  }

  .select-box.top {
    bottom: 56px
  }

  .select-box.bottom {
    top: 56px
  }

  .select-box-item {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    color: #808896;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      a {
        color: #00a575;
      }
    }

    a {
      display: block;
      color: #808896;
      span {
        padding: 1px 0;
      }

      width: 100%;
      outline: none !important;
      text-decoration: none;
    }
  }

  .not-founded-select-box-item {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px;
  }

  .suggestion-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    color: #5e6b79;
  }

  .suggestion {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #00a575;
    background-color: #eaedf2;
    border-radius: 0 0 0 8px;
  }

  .select-arrow-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000000 transparent transparent transparent;
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto;
    pointer-events: none;
  }

  .select-arrow-up {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #000000 transparent;
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto;
    pointer-events: none;
  }
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    height: 20px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #AAAAAA;
  }

  .helpText {
    position: absolute;
    font-size: 12px;
    color: #808896;
    margin-top: 3px;
  }
</style>
