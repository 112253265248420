<template>
  <div>
    <div v-if="user.status == 1 && !isMessengerActive">
      <!-- external message -->
      <template v-if="flashMessage">
        <div @click="flashMessage = null" class="flashMessageClose">
          X Kapat
        </div>

        <div @click="openChatWithClickv2(JSON.parse(JSON.stringify(flashMessage)),false,true)">
          <div v-if="flashMessage" class="flashMessageAvatar">
            <img style="height: 57px; width: 57px; border-radius: 50%; cursor: pointer;" :src="flashMessage.r_avatar_url">
          </div>

          <div v-if="flashMessage" class="flashMessageArea">
            <div class="flashMessageInside">
              {{ flashMessage.message_m ? flashMessage.message_m.substring(0, 24) + '...' : '' }}
            </div>
          </div>
        </div>
      </template>
      <!-- internal message -->
      <template v-else>
        <div v-show="showMessageBar" class="message-box">
          <div class="message-box-content" @click="initConversations">
            <div class="indicator"></div>
            <p style="align-self: center;"> Mesajlar</p>
            <span v-if="user.unreadMessageCount" style="cursor: pointer;" class="button-badge unread_message_count">{{ user.unreadMessageCount}}</span>
          </div>
          <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'side messages')"
            @click.native="handleGlobalClick($store.state.previousComponentPage,'side messages')"
            to="/settings/notifications"
                       style="display: flex; padding: 10px; height: 30px; width: 30px; -webkit-align-self: center; justify-content: center; align-items: center;">
            <img
              :src="$root.CDN_SITE + 'icon/ic_settings.svg'" style="height: 15px; width: 15px;"/>
          </router-link>
        </div>

        <div v-show="!showMessageBar" class="messageMenu">
          <div class="sideMessagesHeader"
               style="display: flex;align-items: center;background-color: #fff;color: #2d3640;padding: 20px;justify-content: space-between ">
            <p class="sideMessagesHeader-text"><span style="font-weight: 500">Anlık</span> Mesajlaşma</p>
            <div @click="closeConversations()" style="cursor: pointer;">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" style="height: 16px">
            </div>
          </div>
          <div class="sideMessages">
            <div class="search-container">
              <input class="search-input" placeholder="Kişilerde Ara" v-model="searchTerm" @keyup="keyUp"/>
              <img src="https://gcdn.bionluk.com/site/icon/kapat-siyah.svg" class="search-image" style="cursor: pointer;" v-if="searchTerm.length" @click="clearSearch()"/>
              <img src="https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg" class="search-image" v-else/>
              <button v-if="searchTerm.length" @click="searchingMode = true; getConversationsV2()" class="super-button" style="min-width:43px; width: 43px; font-size: 13px;  height:27px;position: absolute; top:13px; right: 10px; z-index: 3333">Ara</button>
            </div>

            <div class="new-titles">
              <div @click="makeActive('all')" class="new-titles-box" :style="$store.state.chatUserActiveTitle ==='all' ? 'background-color: #fd4056' : ''">
                <p class="new-titles-text" :style="$store.state.chatUserActiveTitle ==='all' ? 'color: #fff' : 'color:#5e6b79'">Gelen Kutusu</p>
              </div>
              <div  @click="makeActive('unread')" class="new-titles-box" :style="$store.state.chatUserActiveTitle ==='unread' ? 'background-color: #fd4056;margin-left: 5px;' : 'margin-left: 5px;'">
                <p class="new-titles-text"  :style="$store.state.chatUserActiveTitle ==='unread' ? 'color: #fff' : 'color:#5e6b79'">Okunmamış</p>
                <div v-if="user.unreadMessageCount" style="margin-left: 4px;" class="button-badge badge-for-title">{{ user.unreadMessageCount}}</div>

              </div>
            </div>


            <div style="display:none" class="title-type-body">
              <div :class="{'title-type-main': true ,'active-title': $store.state.chatUserActiveTitle ==='all'}"
                   @click="makeActive('all')">
                <p class="title-type">Gelen Kutusu</p>
              </div>
              <div :class="{'title-type-main': true ,'active-title': $store.state.chatUserActiveTitle ==='unread'}"
                   @click="makeActive('unread')">
                <p class="title-type">
                  Okunmamış
                  <span v-if="user.unreadMessageCount" class="button-badge badge-for-title">{{ user.unreadMessageCount}}</span>
                </p>
              </div>
            </div>
            <div v-disable-body-scroll v-if="!conversationOpenLoading" class="sideMessagesInside">
              <div v-for="(conversation, index) in chatUsers"
                   :key="index"
                   class="notifitemrow"
                   :style="{'background-color': conversation.isseen ? '#f7f9fb' : '#FFF'}"
                   @click="openChatWithClickv2(conversation)"
              >
                <div v-if="!conversation.isseen" class="chat-is-seen" />
                <div style="display: flex;align-items: center">
                  <div style="display: flex;position: relative">
                    <img class="message_avatar" style="width: 44px; height: 44px; border-radius: 50%;" :src="conversation.r_avatar_url">
                  </div>
                  <div :style="{'font-weight': conversation.isseen ? '400' : '600'}">
                    <div class="notifitem" :class="{ active: conversation.isseen }">{{ conversation.r_username }}</div>
                  </div>
                </div>
                <div v-show="!conversation.onlineStatus" style="position:absolute; right:10px; top:7px; color: #8b95a1; font-size: 11px">
                  {{ conversation.last_message_created_at | fromNow }}
                </div>
              </div>
              <div v-if="!chatUsers || chatUsers.length === 0">
                <img style="width: 180px; margin-top:100px;" src="https://gcdn.bionluk.com/site/cicons/ilMessages%402x.png"/>
                <p style="margin:20px auto; width:200px; line-height: 1.4; text-align:center;color: #8b95a1; font-size: 14px; font-weight: 300;">
                  {{ !searchingMode && $store.state.chatUserActiveTitle === 'all' ? 'Bu alanda Bionluk\'taki tüm özel mesajlarını yönetebilirsin.' : 'Bu bölümde hiç mesaj bulunamadı.'}}
                </p>
              </div>
            </div>
            <div v-if="!searchingMode && $store.state.chatUsersSeemoreDiv && !seemoreLoader && !conversationOpenLoading" @click="getConversationsV2(null, false, true)"
                 style="cursor: pointer; font-size:14px; font-weight: 600; text-align: center; color: #2d3640; margin-top: 10px">
              Daha fazla göster +
            </div>
            <div v-if="conversationOpenLoading || seemoreLoader" style="margin-top: 10px">
              <div class="loader" style="text-align: center; margin: 0 auto;width: 30px;height: 30px"></div>
            </div>
          </div>

        </div>

      </template>
      <!-- chat box -->
      <div  v-for="(chat, chatKey) in computedConversations" :key="chat.c_uuid">
        <div v-show="forceRerender"
             class="chatbox"
             :style="'right: '+ ( 250 + (chatKey*350 + 20 )) +'px;height: ' + extraHeight(chat) " @mouseup="chatBoxClick($event, chat)">
          <div v-if="chat" class="chatboxInner" :style="activeConversationId === chat.c_uuid ? 'background-color:#000; box-shadow: rgba(0, 0, 0, 1) 0px 10px 20px 0px;opacity:1' : ' opacity:0.9'">
            <div class="user-information-div">
              <div>
                <router-link
                  @contextmenu.native="handleGlobalClick('chat','chatBox')"
                  @click.native="handleGlobalClick('chat','chatBox')"
                  :to="'/'+chat.r_username"
                  style="color: white;">
                  {{ chat.r_username }}
                </router-link>
                <template v-if="chat && chat.receiver">
                  <p v-if="chat.receiver.onlineStatus && diffFromNow(chat.receiver.lastseen, 'seconds') < 90" style="color: #26ca9a; font-weight: 600;font-size: 12px; margin-top: 2px;">Çevrimiçi</p>
                  <p v-else style="color: #8B95A1; font-weight: 400;font-size: 12px; margin-top: 2px;">{{ chat.receiver.lastseen | lastSeen }} önce görüldü.</p>
                </template>
              </div>
            </div>
            <div class="icon-div">
              <div v-if="chat.f_t_info.isActive">
                <img class="chatbox-icons"
                     @click="openFreelancerChatLineModalV2(chat)"
                     title="Sesli Arama"
                     src="https://gcdn.bionluk.com/site/icon/mm.svg">
              </div>
              <div v-else data-balloon="Bu kullanıcının freelancer hattı mevcut değil. Bu yüzden arama yapılamaz."
                   data-balloon-pos="up"
                   data-balloon-length="medium"
                   data-balloon-break>
                <img
                  @click="openFreelancerChatLineModalV2(chat)"
                  class="chatbox-icons"
                  title="Sesli Arama"
                  src="https://gcdn.bionluk.com/site/icon/nn.svg">
              </div>

              <div style="position: relative;display: flex" class="setting-open-box" >
                <div class="setting-open-box">
                <img class="chatbox-icons setting-open-box dot-icon"
                     title="Ayarlar"
                     src="https://gcdn.bionluk.com/uploads/message/3be077b1-1416-4cac-b8ef-c9ef325912ea.png">

              </div>

              <div v-if="chat.openSettingBox" class="setting-box">

                  <p class="setting-box-options setting-box-options-for-click"
                     @click="goChatDetail(chat)"
                     style="display: flex; align-items: center; margin-top: 10px;"
                  >
                    <img :src="'https://gcdn.bionluk.com/site/cicons/ic-categories.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">
                    <span style="padding-left: 10px" class="setting-box-options-for-click">Mesajlarda Aç...</span>
                  </p>
                  <p class="setting-box-options setting-box-options-for-click"
                     @click="changeArchiveStatusV2(chat)"
                     style="display: flex; align-items: center; margin-top: 10px;"
                  >
                    <img :src="'https://gcdn.bionluk.com/site/cicons/ic-archive.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">
                    <span style="padding-left: 10px" class="setting-box-options-for-click">{{chat.archiveStatus ? 'Arşivle' : 'Arşivden Çıkar'}}</span>
                  </p>
                  <p class="setting-box-options setting-box-options-for-click"
                     style="display: flex; align-items: center; margin-top: 10px;"
                     @click="reportV2('conversation',chat.c_uuid, 16)">
                    <img :src="'https://gcdn.bionluk.com/site/cicons/ic-warning.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">
                    <span style="padding-left: 10px" class="setting-box-options-for-click">Şikayet Et</span>
                  </p>

                  <p class="setting-box-options setting-box-options-for-click"
                     @click="blockUserV2(chat)"
                     style="display: flex; align-items: center; margin-top: 10px; margin-bottom: 12px"
                  >
                    <img :src="'https://gcdn.bionluk.com/site/cicons/ic-block.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">
                    <span style="padding-left: 10px" class="setting-box-options-for-click">{{ chat.blocked ? 'Engeli Kaldır' : 'Engelle' }}</span>
                  </p>


                </div>
              </div>
              <img class="chatbox-icons"
                   @click="closeChatBoxMethodsV2(chat)"
                   title="Kapat"
                   src="https://gcdn.bionluk.com/site/icon/tansel_chat_head_close.svg">
            </div>
          </div>

          <div v-if="chat.infoBar && chat.infoBar.showOrderInfoBar" class="info-bar-container">
            <div class="info-bar">
              <p style="padding-right: 4px;">Bu kullanıcıyla aranda</p>
              <p v-if="chat.infoBar.orderCount > 0">
                <router-link
                  @contextmenu.native="handleGlobalClick('chat','chatBox')"
                  @click.native="handleGlobalClick('chat','chatBox')"
                  :to="'/panel/siparisler?username=' + chat.r_username" style="color: #fd4056; ">{{chat.infoBar.orderCount}} alış
                </router-link>
              </p>
              <p v-if="chat.infoBar.orderCount > 0 && chat.infoBar.salesCount > 0" style="padding-left: 4px;padding-right: 4px;"> ve </p>
              <p v-if="chat.infoBar.salesCount > 0">
                <router-link
                  @contextmenu.native="handleGlobalClick('chat','chatBox')"
                  @click.native="handleGlobalClick('chat','chatBox')"
                  :to="'/panel/satislar?username=' + chat.r_username" style="color: #fd4056;">{{chat.infoBar.salesCount}} satış
                </router-link>
              </p>
              <p style="padding-left: 4px;">işlemi var.</p>

            </div>
          </div>

          <!--chatbox loader-->
          <div class="changeheight"
               v-if="chat.chatboxOpenLoading"
               :style="`height:${chat.defaultChangeHeight}px;`">

            <div v-for="x in 2" style="display: flex;  align-items: center; margin-bottom: 40px; margin-top: 20px;">
              <div class="messages-body-avatar-loader chat-loader"></div>
              <div>
                <div class="messages-body-message-loader chat-loader"></div>
                <div class="messages-body-time-loader chat-loader"></div>
              </div>
            </div>

            <div v-for="x in 2" style="display: flex; justify-content: flex-end;  align-items: center; margin-bottom: 40px;">
              <div>
                <div class="messages-body-message-loader chat-loader"></div>
                <div class="messages-body-time-loader chat-loader" style="margin-left: auto;"></div>
              </div>
              <div class="messages-body-avatar-loader chat-loader" style="margin-right: 0; margin-left: 10px;"></div>
            </div>
          </div>

          <!--chatbox-->
          <div class="changeheight"
               :id="'globalScrollMessage'+chat.c_uuid"
               @scroll="scrollEvent($event, chat)"
               v-if="!chat.chatboxOpenLoading"
               :style="`height:${chat.defaultChangeHeight}px;`"
               v-disable-body-scroll>

            <div v-if="chat.seemorediv && !chat.chatOpenLoadingScroll" @click="openChatV2(chat, chat.offset, true)"
                 style="cursor: pointer; font-weight: 500; font-size: 14px; text-align: center; color: #e63262; margin-top: 15px;">Daha fazla gör +
            </div>
            <div v-show="chat.chatboxOpenLoading" class="loader" style="text-align: center; margin: 0 auto;"></div>
            <!-- min-height: 195px -->
            <div :id="'message-array-div'+chat.c_uuid" v-show="chat.receiver && !chat.chatboxOpenLoading" style="min-height: 190px">
              <div class="seemorediv" style="text-align: center; margin: 0 auto;">
                <div class="hiddendiv20"></div>
                <div v-show="chat.chatOpenLoadingScroll" class="loader" style="width: 25px; height: 25px; text-align: center; margin: 0 auto;"></div>
              </div>
              <div class="chat-where-from-info" v-if="chat.receiver && chat.receiver.whereFromInfo && chat.receiver.whereFromInfo.type">
                <p class="up-text">{{chat.receiver.whereFromInfo.props.help_text}}</p>
                <router-link
                  @contextmenu.native="handleGlobalClick('chat','chatBox')"
                  @click.native="handleGlobalClick('chat','chatBox')"
                  style="text-decoration: none;" :to="chat.receiver.whereFromInfo.props.url">
                  <div class="where-from-box">
                    <div class="icon-request-container">
                      <img class="icon-request" :src="chat.receiver.whereFromInfo.props.icon_svg" onload="SVGInject(this)"/>
                    </div>
                    <div class="where-from-title">
                      {{chat.receiver.whereFromInfo.props.title}}
                    </div>
                  </div>
                </router-link>


              </div>
              <div class="chat-welcome-message" v-if="chat.receiver && chat.receiver.showWelcomeMessage">
                <div class="chat-welcome-message-user-info">
                  <img :src="chat.receiver.avatar_url"/>
                  <div style="margin-left: 9px;">
                    <p class="welcome-message-user-info-username">{{chat.receiver.username}}</p>
                    <star-rating v-if="chat.receiver.rating.commentCount" :rating="chat.receiver.rating.commentRating"
                                 :max-rating="5"
                                 :star-size="12"
                                 :show-rating="false"
                                 inactive-color="#dce1e1"
                                 active-color="#ffb700"
                                 :read-only="true"></star-rating>
                  </div>
                </div>
                <div class="chat-welcome-message-content"
                     style="background-color: white; font-size: 12px; text-align: center; padding: 10px 5px; border-radius: 15px;">
                  <p style="">{{chat.receiver.welcomeMessage}}</p>
                </div>
                <div class="chat-welcome-message-time-info">
                  <div class="chat-welcome-message-time-info-inner">
                    <div v-if="chat.receiver.responseTime !== 0" style="display: flex;">

                      <p>Ort. Yanıt Süresi:</p>
                    </div>
                    <span v-if="chat.receiver.responseTime !== 0">{{convertAverageTime(chat.receiver.responseTime)}}</span>
                  </div>
                  <div v-if="chat.receiver.responseTime !== 0 && chat.receiver.lastseen !== 0" class="welcome-seperator"></div>
                  <div class="chat-welcome-message-time-info-inner">
                    <div style="display: flex;">

                      <p>Son Görülme:</p>
                      <p v-if="chat.receiver.onlineStatus" style="color: #67cc64;">Çevrimiçi</p>
                    </div>
                    <span v-if="!chat.receiver.onlineStatus && chat.receiver.lastseen !== 0">{{ chat.receiver.lastseen | lastSeen }} önce</span>
                  </div>
                </div>
              </div>
              <div v-for="chatMessage in chat.messages" class="message-array-inner">
                <div style="clear: both;"></div>
                <div
                  v-if="chatMessage.createdDay" class="chattarih">
                  <span>{{chatMessage.createdDay}}</span>
                </div>


                <div v-if="chatMessage.type === Constants.MESSAGE_TYPES.OFFER">
                  <div class="typeOrder"
                       :style="`background-color:${chatMessage.info.offer.bgcolor}; border: solid 1px ${chatMessage.info.offer.bordercolor};`">
                    <img style="height: 20px; margin-left: 20px;" :src="chatMessage.info.offer.icon">
                    <p style=" color: #2d3640; font-weight: 500; font-size: 12px; text-align: left;">
                      {{ chatMessage.message }}
                    </p>
                    <div class="order-button" @click="activateCustomOfferDetailModal(chatMessage.info.offer)" style="font-size: 10px;">
                      <p>{{chatMessage.info.offer.buttontext}}</p>
                    </div>
                  </div>
                  <div :class="chatMessage.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                       :style="chatMessage.u_uuid === user.uuid ? '' : 'margin-left: 5px;'">{{ chatMessage.created_at | customDateFormat('HH:mm') }}
                  </div>
                </div>


                <div v-else-if="chatMessage.type === 'order'">
                  <div class="typeOrder"
                       :style="`background-color:${chatMessage.info.order.bgcolor}; border: solid 1px ${chatMessage.info.order.bordercolor};`">
                    <img style="height: 20px; margin-left: 20px;" :src="chatMessage.info.order.icon">
                    <p style="color: #2d3640; font-weight: 500; font-size: 12px; text-align: left;">
                      {{ chatMessage.message }}
                    </p>
                    <router-link
                      @contextmenu.native="handleGlobalClick('chat','chatBox')"
                      @click.native="handleGlobalClick('chat','chatBox')"
                      style="text-decoration: none;" :to="'/orders/'+chatMessage.info.order.order_id" v-if="$store.state.route.fullPath !== '/orders/'+chatMessage.info.order.order_id">
                      <div class="order-button">
                        <p>{{chatMessage.info.order.buttontext}}</p>
                      </div>
                    </router-link>
                    <div class="order-button" @click="reloadOrderPage()" v-else>
                      <p>{{chatMessage.info.order.buttontext}}</p>
                    </div>
                  </div>
                  <div :class="chatMessage.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                       :style="chatMessage.u_uuid === user.uuid ? '' : 'margin-left: 5px;'">{{ chatMessage.created_at | customDateFormat('HH:mm') }}
                  </div>
                </div>


                <div v-else class="chatbubble-main" style=" margin-top: 5px;" >

                  <div v-if="chat && chatMessage.u_uuid !== user.uuid">
                    <img style="width: 36px; height: 36px; border-radius: 50%; float: left; margin-right: 10px; margin-top: 5px;"
                    :src="chat.r_avatar_url"/></div>

                  <!--hide message-->
                  <div v-show="chatMessage.hide_message"
                    style="max-width: 200px; display: flex;"
                    :class="chatMessage.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'">
                    <div style="font-size: 12px;  font-style: italic; font-weight: 300; display: flex; align-items: center">
                      <div class="info-icon-container"
                           :data-balloon="chatMessage.hide_message_description"
                           data-balloon-pos="up"
                           data-balloon-length="small"
                      >
                        <img class="info-icon" src="https://gcdn.bionluk.com/site/cicons/ic-info.svg"
                             onload="SVGInject(this)"/>
                      </div>
                      <p>{{chatMessage.hide_message_text}}</p>
                    </div>
                  </div>

                  <div v-if="chatMessage.hide_message">

                  </div>
                  <!--is call message-->
                  <div
                    v-else-if="chatMessage.info && chatMessage.info.iscall"
                    style="max-width: 280px; display: flex;"
                    :class="chatMessage.u_uuid === user.uuid ? 'chatbubble right-iscall' : 'chatbubble left-iscall'">
                    <div class="iscall-body">
                      <img class="iscall-phone-icon"
                           src="https://gcdn.bionluk.com/site/icon/tansel_missed_call.svg"/>
                      <div>
                        <p class="iscall-username">{{chat.receiver.username}} {{chatMessage.u_uuid === user.uuid ? 'arandı' : ' seni aradı'}}</p>
                        <p class="iscall-created-date">{{ chatMessage.created_at | customDateFormat('HH:mm') }}</p>
                      </div>
                    </div>
                  </div>

                  <!--normal message-->
                  <div
                    :style="chatMessage.type === Constants.MESSAGE_TYPES.UPLOAD ? 'padding:0; border: solid 1px rgba(43, 181, 215, 0.2); background-color: rgba(101, 196, 219, 0.2); ' :'max-width: 200px;'"
                    :class="chatMessage.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'"
                    v-else>
                    <span v-if="chatMessage.type !== Constants.MESSAGE_TYPES.UPLOAD" v-html="chatMessage.message" class="chatbuble-text" style="font-size: 13px;"></span>
                    <div class="delete-uploaded-file"
                         data-balloon="Dosyayı Sil"
                         data-balloon-pos="left"
                         v-if="chatMessage.u_uuid === user.uuid && chatMessage.type === 'upload'" @click="deleteUploadedFile(chat, chatMessage)">
                      <img src="https://gcdn.bionluk.com/site/c/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img"/>
                    </div>
                    <div class="partial-loader" v-if="chat.deleteFileLoader && chatMessage.u_uuid === user.uuid && chatMessage.type === 'upload'">
                      <loader style="width: 30px; height: 30px;"/>
                    </div>


                    <div v-for="(uploadChat, uindex) in chatMessage.uploads" :key="uindex">
                      <div v-if="uploadChat.image_url" style="width: 226px; height:130px; display:flex; align-items:center; justify-content:center; ">
                        <a target="_blank" :href="uploadChat.url" :download="uploadChat.filename" class="image-u-div">
                          <div class="playIcon"
                               v-show="( uploadChat.file_type === 'video')">
                            <img class="hoveropa"
                                 :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
                          </div>
                          <img style="width: 220px; height: 124px; border-radius: 6px; background: white" :src="uploadChat.image_url">
                          <div v-show="uploadChat.file_type === 'image'" class="si-icon-container">
                            <img class="si-icon" src="https://gcdn.bionluk.com/site/cicons/ic-search.svg"
                                 onload="SVGInject(this)"/>
                          </div>
                        </a>
                      </div>

                      <div v-else-if="uploadChat.file_type === 'audio'"  style="width: 230px; height:135px; ">
                        <wave-surfer :is-mini="true" :sound-url="uploadChat.url" :file-name="uploadChat.filename" style="width: 230px;"/>
                      </div>
                      <div v-else style="min-width: 230px; height: 70px; display:flex; align-items:center;">
                        <img :src="uploadChat.icon_png" style="width: 29px; height: 38px; margin-left: 15px;">
                        <div style="margin-left: 14px; margin-right: 15px;">
                          <p style="max-width: 155px;word-break: break-word;text-overflow: ellipsis;overflow: hidden; white-space: pre;line-height: 1.29;color: #8b95a1; font-size: 13px; margin-bottom: 8px;">{{ uploadChat.filename }}</p>
                          <a target="_blank" :href="uploadChat.url" :download="uploadChat.filename" class="download-icon-container">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon">
                            Dosyayı İndir
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="clear: both"></div>

                  <div :class="chatMessage.u_uuid === user.uuid ? 'chattime right' : 'chattime left'" style="display: flex;">
                    {{ chatMessage.created_at | customDateFormat('HH:mm') }}
                    <template v-if="chatMessage.u_uuid === user.uuid">
                      <p v-if="chatMessage.read_at" class="icon-message-seen-container"
                         data-balloon="Görüldü"
                         data-balloon-pos="left"
                      >
                        <img class="icon-message-seen-green" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                      </p>
                      <p v-show="!chatMessage.read_at"  class="icon-message-seen-container"
                         data-balloon="İletildi"
                         data-balloon-pos="left"
                         data-balloon-length="small"
                      >
                        <img class="icon-message-seen" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                      </p>
                    </template>
                  </div>




                </div>
              </div>

              <div class="hiddendiv"></div>

              <template v-if="$store.state.percentCompleted[chat.c_uuid]" v-for="(value, key, index) in $store.state.percentCompleted[chat.c_uuid]">

                <loader v-if=" value < 0.01 && value > 0"></loader>

                <div class="progress-main" v-if=" value > 0.01">
                  <progress class="progressbar" :value='value' max="100"></progress>
                  <p class="progress-percent">%{{ parseInt(value) }}</p>
                  <a style="margin-left: 20px; margin-right: 15px;" @click="cancelUploadRequest(parseInt(key))"> <img
                    src="https://gcdn.bionluk.com/site/icon/tansel_chat_attachmentcancel.svg"/> </a>
                </div>
              </template>


            </div>
            <div v-if="chat.onWriting" class="loading-div">
              {{chat.r_username}} {{chat.onWritingType === 'uploading' ? 'size dosya gönderiyor %' + parseInt(chat.uploadingPercentage) : 'yazıyor'}}
            </div>
            <div class="first-message-body first-message-click"  v-if="chat.firstMessage">
              <p class="first-message-header first-message-click">Bu kullanıcı listende bulunmuyor</p>
              <img style="width: 14px;" class="first-message-click" @click="chat.firstMessage = false; forceRerender = false; forceRerender = true;" :src="$root.CDN_SITE + 'icon/tansel_light_gray_close.svg'"/>
              <div class="first-message-button-div first-message-click">
                <div @click="firstMessageActionButtons(chat, 1)"
                     data-balloon-length="medium"
                     data-balloon="Gelen mesaj içerik olarak seni rahatsız ediyorsa ya da bu mesajın spam/reklam içerikli
                              olduğunu düşünüyorsan bu alanı kullanabilirsin. Böylece ’Yapılacaklar’ listenden
                              mesaja cevap ver uyarısı kalkacaktır. Bu alana tıkladığında karşı tarafa herhangi bir
                              bildirim gitmemektedir."
                     data-balloon-pos="up">Rahatsız oldum
                </div>
                <div @click="firstMessageActionButtons(chat, 2)"
                     data-balloon-length="medium"
                     data-balloon="Bir kullanıcıdan artık mesaj almak istemiyorsan engelle seçeneğini kullanabilirsin.
                               Bu durumda kullanıcıya sen de mesaj gönderemezsin. Kendisini engellediğin bilgisi
                                ilgili kullanıcıya iletilir. Sana mesaj attıktan sonra engelle seçeneğini kullanırsan
                                ’Yapılacaklar’ listenden mesaja cevap ver uyarısı kalkacaktır.
                 " data-balloon-pos="up">Engelle
                </div>
                <div @click="firstMessageActionButtons(chat, 3)"
                     data-balloon-length="medium"
                     data-balloon="Gelen mesajda rahatsız edici bir içerik yoksa bu mesaj önemli diyerek bize kullanıcı
                                hakkında olumlu bilgi verebilirsin. Bu alana tıkladığında karşı tarafa herhangi
                                bir bildirim gitmemektedir."
                     data-balloon-pos="up">Bu mesaj önemli
                </div>
              </div>
            </div>
            <div class="scroll-button" v-if="chat.scrollButton" @click="scrollDown(chat)" :style="'bottom:' + (48 + chat.defaultlistenHeight) + 'px;'">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
              <div class="scroll-button-message-count" v-if="chat.messageCount">
                {{chat.messageCount}}
              </div>
            </div>
          </div>


          <template v-if="chat.closedChatBoxText">
            <div class="blocked-user-input">{{chat.closedChatBoxText}}</div>
          </template>
          <template>
            <div class="listenheight" :style="chat.closedChatBoxText ? 'opacity:0; visibility:hidden' : ''">
              <div v-show="!chat.messageWillSend" class="input-placeholder" :style="activeConversationId === chat.c_uuid ? 'opacity:1; ' : 'opacity:0.4'">
                Bir mesaj yazın...
              </div>
              <div
                :id="'global-message-input'+chat.c_uuid"
                dir="auto"
                :value="chat.messageWillSend"
                :contenteditable="!chat.chatboxOpenLoading"
                class="input global-message-input"
                @input="setInputMessageV2($event, chat)"
                @keypress="keyPressedV2($event, chat)"
                @keydown.esc="closeChatBoxMethodsV2(chat)"
                @focus="focusEvent($event, chat)"
                @blur="blurEvent($event, chat)"
                @paste="pasteEventListener"
              />
              <div v-if="!chat.closedChatBoxText" class="chat-down-side" :style="activeConversationId === chat.c_uuid ? 'opacity:1; ' : 'opacity:0.4'">
                <div style=" cursor: pointer; display: flex; flex-direction: row; ">
                  <label :for="'files_chat'+chat.c_uuid" style="display: flex; flex-direction: row;">
                    <div class="attachment-icon">
                      <span style="line-height: 17px; padding-left:23px; font-size: 12px;font-weight: 600;">Dosya Ekle</span>
                    </div>
                    <input :id="'files_chat'+chat.c_uuid" style="display:none;" type="file" @change="onFileChangeV2($event, chat)" multiple>
                  </label>
                </div>
                <div>
                  <div class="offer-icon" v-if="user.seller.isSeller" @click="activateCustomOfferModal(chat)">
                    <span style="padding-left: 23px;line-height: 12px; font-size: 12px;font-weight: 600; ">Özel Teklif Gönder</span>
                  </div>
                </div>
              </div>
              <div :class="{'emoji-img': true, 'emoji-img-active': chat.openEmojiPicker}" @click="openEmojiDiv(chat)" :style="activeConversationId === chat.c_uuid ? 'opacity:1; ' : 'opacity:0.2'"></div>
              <emoji-picker class="emoji-picker"
                            v-click-outside="(emoji) => {pageClickHandler(emoji, chat)}"
                            v-if="chat.openEmojiPicker"
                            :emojiSize="32"
                            :emojisByRow="7"
                            :showSearch="true"
                            :i18n="{ search: 'Ara...'}"

                            @select="(emoji) => {addEmoji(emoji, chat)}"/>
            </div>
          </template>
          <div :id="'drag-drop'+chat.c_uuid"
               class="drag-drop-class"
               draggable="true"
               @drop="dropHandler($event, chat)"
               @dragover="dragOverHandler($event, chat)"
               @dragleave="dragLeaveHandler($event, chat)" >
            {{chat.dragDropText}}
          </div>
        </div>
      </div>

      <div class="chatboxMoreIconContainer" v-if="showExtraChatIcon > 1" :style="'right: '+ ( 250 + (showExtraChatIcon * 350 + 20 )) +'px; width'">

        <button class="extra-conversations-button" @focusout="extraFocusOut">
          <div v-if="computedExtraConversationsMessageCount && computedExtraConversationsMessageCount > 0" @click="showExtraConversations = !showExtraConversations" class="extra-messages-scroll-button" style="margin-top: 4px; margin-left: 3px; z-index: 1">
            <span class="scroll-button-message-count">
              {{computedExtraConversationsMessageCount}}
            </span>
          </div>
          <div @click="showExtraConversations = !showExtraConversations"  class="chatboxMoreIcon" >
            <img src="https://gcdn.bionluk.com/site/icon/aws/ic_commentt.svg" :style="showExtraConversations || computedExtraConversationsMessageCount > 0 ? 'opacity:1!important' : ''">
          </div>
          <div v-if="showExtraConversations" class="extra-conversations">
            <div v-for="chat in computedExtraConversations" :key="chat.c_uuid" @click="showChatFromExtraConversations(chat)" class="extra-conversations-item">
              <p class="extra-messages-scroll-button" v-if="chat && chat.messageCount" style="box-shadow: none;"><span class="scroll-button-message-count">{{chat.messageCount}}</span></p>
              {{ chat.r_username }}<img  @click.stop="closeChatBoxMethodsV2(chat)" src="https://gcdn.bionluk.com/site/icon/tansel_chat_head_close.svg">
            </div>
          </div>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
  import Loader from "../components/toolbox/loader";
  import StarRating from 'vue-star-rating';
  import { VEmojiPicker } from "v-emoji-picker-bi";
  import WaveSurfer from "@/pages/components/WaveSurfer";
  import { fromNow, diffFromNow } from '@/utils/dateFns'

  export default {
    name: "src-pages-message-v4",
    components: { Loader, StarRating, emojiPicker: VEmojiPicker, WaveSurfer },
    filters: {
      fromNow
    },
    data() {
      return {
	      forceRerender: true,
	      flashMessage: false,
	      showMessageBar: true,
	      conversationOpenLoading: false,
	      searchTerm: '',
        seemoreLoader: false,
        openEmojiPicker: false,
	      showExtraConversations: false,
	      conversationLimit: 10,
        searchedConversations: [],
        searchingMode: false,
        hide: null
      }
    },
    methods: {
      diffFromNow,
      initConversations(){
        this.showMessageBar = false;
        if(this.$store.state.chatUserActiveTitle !== 'all'){
          this.$store.state.chatUserActiveTitle = 'all';
          this.getConversationsV2('all', true);
        }
      },
      goChatDetail(chat){
        this.showMessageBar = true;
        this.$router.push('/chat/' + chat.r_username)
      },
	    showChatFromExtraConversations(chat){

	    	let c_uuid = chat.c_uuid;
		    let calculateShow = (this.$store.state.openConversations.length > 1 && window.innerWidth < 1439) ? 2 : this.$store.state.openConversations.length > 2 ? 3 : false;

		    const c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === c_uuid);



		    let t = this.$store.state.openConversations;
		    let removeConv = t[c_index];
		    t.splice(c_index, 1);
		    t.unshift(removeConv);
		    t = this.moveArrayItem(t,0,(calculateShow - 1));
		    this.$store.state.openConversations = t;

		    this.$store.state.activeConversationId = c_uuid;
		    this.$nextTick(() => {
			    document.getElementById('global-message-input'+chat.c_uuid).value = chat.messageWillSend || '';
			    document.getElementById('global-message-input'+chat.c_uuid).innerHTML = chat.messageWillSend || '';
			    this.scrollDown(chat, chat.scrollPosition);
			    this.setCursorToEnd(document.getElementById('global-message-input'+chat.c_uuid));
		    });
		      this.showExtraConversations = false;
      },
	    channelChatBlockActionsV2(data) {
		    const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === data.c_uuid)
		    let chat = this.$store.state.openConversations[chatIndex];
		    if(chatIndex !== -1 && chatIndex > -1){
			    if (data.u_uuid !== this.user.uuid) {

				    if (data.type === "block") {
					    chat.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
				    } else {
              if ((data.ban_type === "mutual") || (data.ban_type === "user")) {
                chat.blocked = true;
                chat.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCK_MESSAGE;
              } else {
                if (data.ban_type === "sender") {
                  chat.blocked = false;
                  chat.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
                } else {
                  // false durumu
                  chat.blocked = false;
                  chat.closedChatBoxText = null;
                }
              }
				    }
            this.$toasted.global.infoToast({description: data.message});
				    let tempConversations = this.$store.state.openConversations;
				    tempConversations[chatIndex] = chat;
				    this.$store.state.openConversations = tempConversations;
				    this.forceRerender = false;
				    this.forceRerender = true;
			    }
		    }
	    },
	    channelChatWritingV2(data) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === data.c_uuid)
		    if (chatIndex > -1) {
			    let chat = this.$store.state.openConversations[chatIndex];
			    if (data.u_uuid !== this.user.uuid) {
				    chat.onWritingType = data.type;
				    chat.uploadingPercentage = data.percentage;
				    if (!chat.onWriting) {
					    chat.onWriting = true;
					    chat.listenedWritingTimeout = setTimeout(() => {
                const chatIndex2 = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
						    chat.onWriting = false
						    let tempConversations2 = this.$store.state.openConversations;
						    tempConversations2[chatIndex2] = chat;
						    this.$store.state.openConversations = tempConversations2;
						    this.forceRerender = false;
						    this.forceRerender = true;
					    }, 4000);
				    } else {
					    clearTimeout(chat.listenedWritingTimeout);
					    chat.listenedWritingTimeout = null;
              const chatIndex2 = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
					    chat.onWriting = false
					    let tempConversations2 = this.$store.state.openConversations;
					    tempConversations2[chatIndex2] = chat;
					    this.$store.state.openConversations = tempConversations2;
					    this.forceRerender = false;
					    this.forceRerender = true;
					    chat.listenedWritingTimeout = setTimeout(() => {
						    chat.onWriting = false;
						    chat.onWritingType = null;
						    chat.uploadingPercentage = 0;
                const chatIndex2 = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
						    chat.onWriting = false
						    let tempConversations2 = this.$store.state.openConversations;
						    tempConversations2[chatIndex2] = chat;
						    this.$store.state.openConversations = tempConversations2;
						    this.forceRerender = false;
						    this.forceRerender = true;
					    }, 4000);
				    }

				    //mark karşı taraf online status true
            if(chat.receiver){
              chat.receiver.onlineStatus = true;
              chat.receiver.lastseen = Date.now()
            }
            chat.onlineStatus = true;
            chat.lastseen = Date.now()

				    let tempConversations = this.$store.state.openConversations;
				    tempConversations[chatIndex] = chat;
				    this.$store.state.openConversations = tempConversations;
				    this.forceRerender = false;
				    this.forceRerender = true;
			    }
		    }
	    },
	    channelChatSeenV2(data) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === data.c_uuid)
		    if (chatIndex > -1) {
			    let chat = this.$store.state.openConversations[chatIndex];
			    if (data.u_uuid !== this.user.uuid) {
				    chat.is_receiver_seen = data.created_at;

            chat.messages.forEach( (message) => {
              message.read_at = 1;
            })
            //mark karşı taraf online status true
            if(chat.receiver){
              chat.receiver.onlineStatus = true;
              chat.receiver.lastseen = Date.now()
            }
            chat.onlineStatus = true;
            chat.lastseen = Date.now()
            chat.is_receiver_seen = true;

				    let tempConversations = this.$store.state.openConversations;
				    tempConversations[chatIndex] = chat;
				    this.$store.state.openConversations = tempConversations;
				    this.$nextTick(function () {
					    let globalScrollMessage = document.getElementById('globalScrollMessage'+data.c_uuid);
				    	if(globalScrollMessage){
						    let height = globalScrollMessage.scrollHeight - globalScrollMessage.offsetHeight;
						    let scrollPosition = globalScrollMessage.scrollTop;
						    if (height === scrollPosition || height - scrollPosition < 100) {
							    globalScrollMessage.scrollTop = globalScrollMessage.scrollHeight;
						    }
					    }
				    });

				    this.forceRerender = false;
				    this.forceRerender = true;
			    }
		    }
	    },
	    channelChatV2(data) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === data.c_uuid)
		    if(chatIndex !== -1 && chatIndex > -1){
			    let chat = this.$store.state.openConversations[chatIndex];


			    if (data.u_uuid !== this.user.uuid ){
				    chat.isseen = false;
				    chat.is_receiver_seen = false;
				    chat.onWriting = false;
				    if (this.hide && this.activeConversationId === chat.c_uuid && chat.receiver && chat.receiver.username) { //kullanici focus ise seenmessage yapsin

              chat.isseen = true;
              this.api.workstation.seenMessage(chat.c_uuid,chat.receiver.username)
				    }


            const foundMessageIndexFromPayloadId = chat.messages.findIndex(x => x.payload_id === data.payload_id);
            if(foundMessageIndexFromPayloadId > -1){ //payload_id'den bulduk o zaman güncelle
              chat.messages[foundMessageIndexFromPayloadId] = data;
            } else {
              const foundMessageIndexFromMessageId = chat.messages.findIndex(x => x.id === data.id);
              if(foundMessageIndexFromMessageId > -1){ //id'den bulduk o zaman yine güncelle
                chat.messages[foundMessageIndexFromMessageId] = data;
              } else { // hiç bulamadık o zaman yaz
                chat.messages.push(data);
                chat.messageCount = chat.messageCount + 1;
                this.playReceivedMessageSound();
              }
            }

            if(chat.receiver){
              chat.receiver.onlineStatus = true;
              chat.receiver.lastseen = Date.now()
            }
            chat.onlineStatus = true;
            chat.lastseen = Date.now()

				    let tempConversations = this.$store.state.openConversations;
				    tempConversations[chatIndex] = chat;
				    this.$store.state.openConversations = tempConversations;

            this.forceRerender = false;
            this.forceRerender = true;

			    } else {
				    if (data.type === this.Constants.MESSAGE_TYPES.OFFER) {
					    chat.messages.push(data);
					    this.playSentMessageSound();
				    } else  {
				      const foundMessageIndexFromPayloadId = chat.messages.findIndex(x => x.payload_id === data.payload_id);
              if(foundMessageIndexFromPayloadId > -1){ //payload_id'den bulduk o zaman güncelle
                chat.messages[foundMessageIndexFromPayloadId] = data;
                this.playSentMessageSound();
              } else {
                const foundMessageIndexFromMessageId = chat.messages.findIndex(x => x.id === data.id);
                if(foundMessageIndexFromMessageId > -1){ //id'den bulduk o zaman yine güncelle
                  chat.messages[foundMessageIndexFromMessageId] = data;
                  this.playSentMessageSound();
                } else { // hiç bulamadık o zaman yaz
                  chat.messages.push(data);
                }
              }
				    }

            chat.isseen = true;
				    let tempConversations = this.$store.state.openConversations;
				    tempConversations[chatIndex] = chat;
				    this.$store.state.openConversations = tempConversations;
			    }

			    this.forceRerender = false;
			    this.forceRerender = true;
			    if (!chat.scrollButton) {
				    this.$nextTick(function () {
					    this.scrollDown(chat);
				    });

			    }


        }

	    },
	    reportV2(type, external_id, report_type_id) {
		    this.$store.commit(this.types.ACTIVE_MODAL, {
			    modalType: this.Modals.COMPLAINT,
			    info: {props:{whereFrom:'chat'},type: type, externalId: external_id, reportTypeId: report_type_id}
		    });
	    },
	    openFreelancerChatLineModalV2(chat) {
	    	const info = chat.f_t_info
        const receiver = chat.receiver

        const payload = {
          modalType: this.Modals.FREELANCER_CHAT_LINE,
          info: {
            f_t_info: info,
            receiver: receiver,
            props: {
              whereFrom: 'chat',
              userID: receiver.id,
              username: receiver.username
            }
          }
        }
		    this.$store.commit(this.types.ACTIVE_MODAL, payload)
	    },
    	extraFocusOut(){
        this.showExtraConversations = false;
      },
	    activateCustomOfferModal(chat) {
		    this.api.seller.retrieveGigs(null, 9999, 0, 1, 1, this.$Progress)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {
					    let props = {
						    whereFrom:'chat'
					    }
					    this.$store.commit(this.types.ACTIVE_MODAL, {
                modalType: this.Modals.CUSTOM_OFFER,
						    info: {
                  props,
                  data: {
                    type: 'customOffer',
                    gigs: result.data.gigs,
                    receiver: chat.receiver,
                    openedChannelID: chat.c_uuid
                  }
                }
					    })
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
	    activateCustomOfferDetailModal(offer) {
		    this.api.seller.getOfferDetail(offer.id)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {
					    let props = {
					    	whereFrom:'chat'
              }
					    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {props, data: result.data.offerDetail}});
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
      getConversationsV2(messageType = null, sendTracking = false, comingFromSeeMore = false) {

        if(this.conversationOpenLoading) return false;

        if(!messageType){
          messageType = this.$store.state.chatUserActiveTitle;
        }

        let fromInit = false;
        if(messageType === 'all_init'){
          fromInit = true;
          messageType = 'all';
          this.searchTerm = '';
          this.searchingMode = false;
          this.$store.state.chatUsersOffset = 0;
          this.$store.state.chatUsersSeemoreDiv = false;

        }

        let searchTerm = this.searchTerm.trim().replace(/ +/g, "");
        let offset = this.searchingMode && searchTerm ? 0 : this.$store.state.chatUsersOffset;
        let limit  = this.searchingMode && searchTerm ? 10 : 10;


        if (offset) {
          this.seemoreLoader = true;
        }


        if(comingFromSeeMore || fromInit || this.searchingMode || this.$store.state.chatUserActiveTitle !== messageType || this.chatUsers.length === 0){
          this.$store.state.chatUserActiveTitle = messageType;

          if (!offset) {
            this.conversationOpenLoading = true;
          }

          this.api.workstation.getConversations(messageType, searchTerm , limit, offset)
            .then(({data}) => {
              let result = data;


              if (result.success) {


                if(this.searchingMode){
                  this.searchedConversations = result.data.conversations;
                } else {
                  if (this.$store.state.chatUsersOffset === 0) {
                    this.$store.state.chatUsers = result.data.conversations
                  } else {
                    this.$store.state.chatUsers = this.$store.state.chatUsers.concat(result.data.conversations)
                  }

                  this.$store.state.chatUsersOffset = this.$store.state.chatUsersOffset + 10;

                  this.$store.state.chatUsersSeemoreDiv = result.data.seemorediv;
                  if (sendTracking) {
                    let trackingProps = {
                      version: "v4",
                    };
                    this.Helper.trackEvents.customEvent("messagessidebaropenned", trackingProps);
                  }

                }

                this.seemoreLoader = false;
                this.conversationOpenLoading = false;

                if(comingFromSeeMore){
                  this.$nextTick(() => {
                    let el = document.getElementsByClassName("sideMessagesInside");
                    if (el[0])
                      el[0].scrollTop = el[0].scrollHeight;
                  });
                }
              }
            })
            .catch(() => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
        } else {
          this.seemoreLoader = false;
        }
      },
	    closeConversations() {
		    this.showMessageBar = true;
		    this.searchTerm = '';
		    this.conversationOpenLoading = false;
		    this.searchingMode = false;
		    this.searchedConversations = [];
	    },
	    openEmojiDiv(chat){

		    chat.openEmojiPicker = !chat.openEmojiPicker;
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
		    let tempConversations = this.$store.state.openConversations;
		    tempConversations[chatIndex] = chat;
		    this.$store.state.openConversations = tempConversations;
		    this.forceRerender = false;
		    this.forceRerender = true;
      },
	    closeChatBoxMethodsV2(chat) {
		    if (!chat.chatboxOpenLoading) { //loaderday kapatma, adam kapatıyor pencereyi cevap geliyor bi daha setleniyor bosuna
          let alert = `Henüz mesajını göndermedin, yine de kapatmak istiyor musun?`
			    if (chat.messageWillSend && confirm(alert) || !chat.messageWillSend) {
              const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
              if (chatIndex > -1) {
                this.$store.state.openConversations.splice(chatIndex, 1);
              }
					    this.$store.state.activeConversationId = null
			    }
		    }
	    },
	    setInputMessageV2(event,chat) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)

		    chat.messageWillSend = event.target.innerHTML;
		    if (event.target.clientHeight > chat.defaultlistenHeight) {
			    chat.defaultChangeHeight -= (event.target.clientHeight - chat.defaultlistenHeight);
		    } else {
			    chat.defaultChangeHeight += (chat.defaultlistenHeight - event.target.clientHeight);
		    }

		    chat.defaultlistenHeight = event.target.clientHeight;

		    let tempConversations = this.$store.state.openConversations;
		    tempConversations[chatIndex] = chat;
		    this.$store.state.openConversations = tempConversations;


		    this.forceRerender = false;
		    this.forceRerender = true;

	    },
      keyPressedV2(event, chat) {
	    	let c_uuid = chat.c_uuid;

		    let globalScrollMessage = document.getElementById('globalScrollMessage'+chat.c_uuid);
		    if(globalScrollMessage){
			    let height = globalScrollMessage.scrollHeight - globalScrollMessage.offsetHeight;
			    let scrollPosition = globalScrollMessage.scrollTop;
			    if (height === scrollPosition || height - scrollPosition < 100) {
				    this.scrollDown(chat);
			    }
        }


		    if (event.keyCode === 13 && (event.shiftKey || event.altKey || event.ctrlKey)) {

			    if (event.altKey || event.ctrlKey) {
			    	let inputEle =  document.getElementById('global-message-input'+c_uuid);
			    	if(inputEle){
					    inputEle.innerHTML = inputEle.innerHTML ? inputEle.innerHTML : '';
					    if (!inputEle.childElementCount) {
						    let createdBr = document.createElement('br');
						    inputEle.appendChild(createdBr);
					    }
					    inputEle.appendChild(document.createElement('br'));
					    this.placeCaretAtEnd(inputEle);
				    }
			    }


		    } else if (event.keyCode === 13) {

			    event.returnValue = false;
			    let checkMessage = chat.messageWillSend ? chat.messageWillSend.split('<br>').join('') : null;
			    checkMessage = chat.messageWillSend ? checkMessage.trim() : null;

			    if (chat.messageWillSend && chat.messageWillSend.length && checkMessage && checkMessage.length && !chat.closedChatBoxText) {
				    this.sendMessage('message', chat.messageWillSend, null, c_uuid);

			    }
		    }

		    if (event.keyCode !== 13 && !event.altKey && !event.shiftKey && !event.ctrlKey) {
			    if (chat.sendWritingEvent) {
				    chat.sendWritingEvent = false;
				    chat.triggeredWritingTimeout = setTimeout(() => {
					    chat.sendWritingEvent = true
				    }, 5000);
				    this.api.workstation.sendWriting(c_uuid, null, null, chat.r_id, chat.r_uuid);
			    }
		    }
	    },
	    extraHeight(chat){
		    let height = 452;
		    let infoBar_plus = 0;

		    if (chat.infoBar.showOrderInfoBar) {
			    infoBar_plus = 50;
		    }

		    let huheight = height + infoBar_plus;

		    return huheight + 'px';
	    },
      pasteEvent(event, chat) {

      },
      openChatWithClickv2(conversationInfo, isItEventBus = false, isItFromClickedFlashMessage=false) {
        if (isItFromClickedFlashMessage) {
          this.showMessageBar = false
        }
        if (!conversationInfo || !conversationInfo.c_uuid) {
          return false
        }

        if (!isItEventBus) {
          this.flashMessage = null
        }
        //check if
        let c_uuid = conversationInfo.c_uuid
        const c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === c_uuid)

        let calculateShow = (this.$store.state.openConversations.length > 1 && window.innerWidth < 1439) ? 2 : this.$store.state.openConversations.length > 2 ? 3 : 0;
        if (c_index > -1) {
          conversationInfo.isseen = true
          if (this.showExtraChatIcon && c_index > (this.showExtraChatIcon - 1)) {
            let t = this.$store.state.openConversations
            let removeConv = t[c_index]
            t.splice(c_index, 1)
            t.unshift(removeConv)
            t = this.moveArrayItem(t,0, calculateShow - 1)
            this.$store.state.openConversations = t

            this.$store.state.activeConversationId = c_uuid
            this.$nextTick(() => {

              const inputEle = document.getElementById('global-message-input'+conversationInfo.c_uuid);
              if (inputEle) {
                inputEle.value = conversationInfo.messageWillSend || '';
                inputEle.innerHTML = conversationInfo.messageWillSend || '';
                this.scrollDown(conversationInfo, conversationInfo.scrollPosition);
                this.setCursorToEnd(inputEle);
              }
            })
          } else {
            this.$store.state.activeConversationId = c_uuid
            this.$nextTick(() => {
              const inputEle = document.getElementById('global-message-input'+conversationInfo.c_uuid);
              if (inputEle) {
                this.setCursorToEnd(inputEle)
              }
            })
          }
        } else {
          conversationInfo.uploads = [];
          conversationInfo.openSettingBox = false;
          conversationInfo.chatboxOpenLoading = true;
          conversationInfo.isseen = true;
          conversationInfo.onWriting = false;
          conversationInfo.messages = [];
          conversationInfo.defaultChangeHeight = 305;
          conversationInfo.defaultlistenHeight = 42;
          conversationInfo.offset = 0;
          conversationInfo.f_t_info = {};
          conversationInfo.infoBar = {};
          conversationInfo.disableClick = false;
          conversationInfo.messageWillSend = '';
          conversationInfo.deleteFileLoader = false;
          conversationInfo.uploadingPercentage = 0;
          conversationInfo.onWritingType = null;
          conversationInfo.listenedWritingTimeout = null;
          conversationInfo.triggeredWritingTimeout = null;
          conversationInfo.scrollPosition = null;
          conversationInfo.sendWritingEvent = true;
          conversationInfo.dragDropText = 'Dosyaları bu alana sürükle';
          conversationInfo.fromWhere = conversationInfo.fromWhere || 'Side Messages';
          conversationInfo.whereFrom = conversationInfo.fromWhere;
          conversationInfo.clickedFrom = this.$store.state.clickedFrom || null;
          conversationInfo.gigID = conversationInfo.gigID || null;
          conversationInfo.blogID = conversationInfo.blogID || null;
          conversationInfo.request_id = conversationInfo.request_id || null;
          conversationInfo.request_uuid = conversationInfo.request_uuid || null;
          conversationInfo.cat_id = conversationInfo.cat_id || null;
          conversationInfo.sub_cat_id = conversationInfo.sub_cat_id || null;

          this.EventBus.$on('dragAndDropFile'+c_uuid, droppedFile => {
            let dragDrop = document.getElementById('drag-drop'+c_uuid);
            if (dragDrop)
              dragDrop.style.top = '100vh';
          });

          if (calculateShow && this.$store.state.openConversations.length >= calculateShow) {
            let TempArray = this.$store.state.openConversations
            TempArray.push(conversationInfo)
            TempArray = this.moveArrayItem(TempArray,TempArray.length - 1, calculateShow - 1)
            this.$store.state.openConversations = TempArray

            this.$store.state.activeConversationId = conversationInfo.c_uuid
            this.openChatV2(conversationInfo, 0)
          } else {
            this.$store.state.openConversations.push(conversationInfo)
            this.$store.state.activeConversationId = conversationInfo.c_uuid
            this.openChatV2(conversationInfo, 0)
          }
        }
      },
      moveArrayItem(arr, old_index, new_index) {
        while (old_index < 0) {
          old_index += arr.length;
        }
        while (new_index < 0) {
          new_index += arr.length;
        }
        if (new_index >= arr.length) {
          let k = new_index - arr.length;
          while ((k--) + 1) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
      },
      openChatV2(conversationInfo, offset = 0, comingFromSeemore = false) {
        let c_uuid = conversationInfo.c_uuid;
        const c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === c_uuid);
        let tempConversation = this.$store.state.openConversations[c_index];

        let globalScrollMessage, oldHeight;
        let messageArrayDiv = document.getElementById("message-array-div"+c_uuid); // message-array-div
        if (comingFromSeemore) {
          tempConversation.chatOpenLoadingScroll = true;
          let tempConversations = this.$store.state.openConversations;
          tempConversations[c_index] = tempConversation
          this.$store.state.openConversations = tempConversations
          this.forceRerender = false;
          this.forceRerender = true;
          globalScrollMessage = document.getElementById('globalScrollMessage'+c_uuid);
          oldHeight = messageArrayDiv.getBoundingClientRect().height;
        }

        this.api.workstation.chatOpen(conversationInfo.r_username, offset, 20)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              tempConversation.disableClick = false;
              tempConversation.showChatBox = true;

              tempConversation.force_bicoin = result.data.force_bicoin;
              if (result.data.force_bicoin) {
                this.$store.commit(this.types.ACTIVE_MODAL, {
                  modalType: this.Modals.UNLOCK_CHAT,
                  info: {
                    props:{
                      whereFrom:'chat',
                    },
                    chatID: result.data.c_uuid,
                    comingFrom: "chatBox",
                    disintermediation: result.data.disintermediationModal,
                    totalBicoin: result.data.total_bicoin,
                    chat:conversationInfo

                  }
                });
              } else if (result.data.disintermediationModal) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.DISINTERMEDIATION, info: {props:{whereFrom:'chat'}, comingFrom: "chatBox", chat:conversationInfo}});
              }

              if ((result.data.ban_type === "mutual") || (result.data.ban_type === "user")) {
                tempConversation.blocked = true;
                tempConversation.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCK_MESSAGE;
              } else {
                if (result.data.ban_type === "sender") {
                  tempConversation.blocked = false;
                  tempConversation.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
                } else {
                  // false durumu
                  tempConversation.blocked = false;
                  tempConversation.closedChatBoxText = null;
                }
              }

              result.data.messages = result.data.messages.concat(tempConversation.messages);
              result.data.messages.forEach(function (message) {
                message.created_at = Number(message.created_at);
              });
              tempConversation.messages = result.data.messages;

              this.forceRerender = false;
              this.forceRerender = true;

              if (comingFromSeemore) {
                this.$nextTick(() => {
                  let messageArrayDivv = document.getElementById("message-array-div"+c_uuid);
                  let globalScrollMessage = document.getElementById('globalScrollMessage'+c_uuid);
                  globalScrollMessage.scrollTop = messageArrayDivv.getBoundingClientRect().height - oldHeight + 11 //38 = loader'ların height'ları
                })
              } else {
                this.Helper.trackEvents.pageView(this.Helper.chatPageProps(tempConversation), "chat");
              }
              tempConversation.seemorediv = result.data.seemorediv;
              tempConversation.isseen = true;



              if (offset === 0 && !tempConversation.blocked) {
                this.$nextTick(() => {
                  this.scrollDown(tempConversation);
                  if(!result.data.disintermediationModal && !result.data.force_bicoin) {
                    this.setCursorToEnd(document.getElementById('global-message-input'+c_uuid));
                  }
                });
              }

              if(!tempConversation.blocked){
                this.$nextTick(() => {
                  tempConversation.messages.forEach(function (message, index) {
                    if (document.getElementsByClassName("chatbuble-text")[index]) {
                      let element = document.getElementsByClassName("chatbuble-text")[index].getElementsByTagName("a");
                      if (element.length > 0) {
                        element[0].style.color = "#2d3740";
                      }
                    }
                  });
                });
              }

              tempConversation.offset += 20;
              tempConversation.receiver =  result.data.receiver;
              tempConversation.archiveStatus =  result.data.status;
              tempConversation.infoBar =  result.data.infoBar;
              tempConversation.f_t_info =  result.data.f_t_info;
              tempConversation.firstMessage =  result.data.firstMessage;
              tempConversation.scrollButton = false;
              tempConversation.messageCount = 0;

              if(!comingFromSeemore){
                tempConversation.channelId = c_uuid;
              }


              tempConversation.chatboxOpenLoading = false;
              tempConversation.chatOpenLoadingScroll = false;

              const chatIndex2 = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === c_uuid)
              let tempConversations = this.$store.state.openConversations;
              tempConversations[chatIndex2] = tempConversation;
              this.$store.state.openConversations = tempConversations;

              this.forceRerender = false;
              this.forceRerender = true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })

      },
      reloadOrderPage() {
        this.EventBus.$emit('reloadOrderPage', 'reload')
      },
      dragStartEvent(event) {

	      this.computedConversations.forEach( (chat) => {
		      let dragDrop = document.getElementById('drag-drop'+chat.c_uuid);
		      if (dragDrop)
			      dragDrop.style.top = '50px';
		      if (event.target.id === "drag-drop"+chat.c_uuid) {
			      chat.dragDropText = 'Dosyaları bu alana bırakın.';
		      }
	      });

      },
      dragLeaveEvent(event) {

	      this.computedConversations.forEach( (chat) => {
		      let dragDrop = document.getElementById('drag-drop'+chat.c_uuid);
		      if (dragDrop && event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
			      //outside body / window
			      dragDrop.style.top = '100vh';
		      } else if (dragDrop && (!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
			      dragDrop.style.top = '100vh';
		      } else if (event.target.id === "drag-drop"+chat.c_uuid) {
			      chat.dragDropText = 'Dosyaları bu alana sürükle.';
		      }
	      });

      },
      dropHandler(event, chat) {
        this.EventBus.$emit('dragAndDropFile'+chat.c_uuid, null);
        event.preventDefault();
        event.stopPropagation();
        this.onFileChangeV2(event, chat);

	      this.computedConversations.forEach(computedChat => {
		      let dragDrop = document.getElementById('drag-drop'+computedChat.c_uuid);
		      dragDrop.style.top = '100vh';
	      });

      },
      dragOverHandler(event, chat) {
    		if(chat.dragDropText !== 'Bu alana bırakın') {
          const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
			    chat.dragDropText = 'Bu alana bırakın';
			    let tempConversations = this.$store.state.openConversations;
			    tempConversations[chatIndex] = chat;
			    this.$store.state.openConversations = tempConversations;
			    this.forceRerender = false;
			    this.forceRerender = true;
        }

        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },
	    dragLeaveHandler(event, chat) {
		    if(chat.dragDropText === 'Bu alana bırakın') {
          const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
			    chat.dragDropText = 'Dosyaları bu alana sürükleyin';
			    let tempConversations = this.$store.state.openConversations;
			    tempConversations[chatIndex] = chat;
			    this.$store.state.openConversations = tempConversations;
			    this.forceRerender = false;
			    this.forceRerender = true;
		    }
	    },
      closeEvent(chat) {
	      if(chat.openSettingBox){
		      let c_uuid = chat.c_uuid;
		      const c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === c_uuid);
		      chat.openSettingBox = false
		      let tempConversations = this.$store.state.openConversations;
		      tempConversations[c_index] = chat;
		      this.$store.state.openConversations = tempConversations;
		      this.forceRerender = false;
		      this.forceRerender = true;
        }
      },
      scrollDown(chat, position = null) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
        this.$nextTick(function () {
          let element = document.getElementById('globalScrollMessage'+chat.c_uuid);
          if (element)
          	if(position !== null){
		          element.scrollTop = position;
            } else {
		          element.scrollTop = element.scrollHeight;
		          chat.messageCount = 0;
            }
        });

	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;

	      this.forceRerender = false;
	      this.forceRerender = true;
      },
      scrollEvent(e, chat) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
        let height = e.target.scrollHeight - e.target.offsetHeight;
        let scrollPosition = e.target.scrollTop;
        if (height - scrollPosition > 100) {
	        chat.scrollButton = true;
        } else {
	        chat.scrollButton = false;
	        chat.messageCount = 0;
        }

        chat.scrollPosition = e.target.scrollTop;


	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;

	      this.forceRerender = false;
	      this.forceRerender = true;

      },
      blockUserV2(chat) {
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
	      let tempConversations = this.$store.state.openConversations;
	    	let receiver = chat;

        let alert = `${receiver.r_username} isimli ${chat.blocked ? 'kullanıcının' : 'kullanıcıyı'} ${chat.blocked ? 'engelini kaldırmak' : 'engellemek'} istediğine emin misin?`;
        if (confirm(alert)) {
          if (chat.blocked) {
            this.api.workstation.unblockUser(receiver.r_uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {

                  chat.blocked = false;
                  chat.openSettingBox = false;
                  this.$toasted.global.infoToast({description: result.message});


	                tempConversations[chatIndex] = chat;
	                this.$store.state.openConversations = tempConversations;
	                this.forceRerender = false;
	                this.forceRerender = true;
	                this.closeChatBoxMethodsV2(chat);

                }
              }).catch(err => []);
          } else {
            this.api.workstation.blockUser(receiver.r_uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {

	                chat.blocked = true;
	                chat.openSettingBox = false;


	                tempConversations[chatIndex] = chat;
	                this.$store.state.openConversations = tempConversations;
	                this.forceRerender = false;
	                this.forceRerender = true;

                  this.$toasted.global.infoToast({description: result.message});
	                this.closeChatBoxMethodsV2(chat);
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
              }).catch(err => [])
          }
        }
      },
      changeArchiveStatusV2(chat) {
    		let username = chat.r_username;
        let status = chat.archiveStatus ? 0 : 1;
        this.api.chat.changeArchiveStatus(username, status)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              chat.openSettingBox = false;
              this.$toasted.global.infoToast({description: result.message});
              chat.archiveStatus = status;
              this.closeChatBoxMethodsV2(chat);
              this.$store.state.chatUsers = this.$store.state.chatUsers.filter(({ r_username }) => r_username !== username)
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
      },
      firstMessageActionButtons(chat, type, message = null) {
    	  let uuid = chat.c_uuid;
        this.api.chat.reportConversation(uuid, type, message)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if (type === 1 || type === 2) {
                this.closeChatBoxMethodsV2(chat);
              } else {
                const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
                this.$store.state.openConversations[chatIndex].firstMessage = false;
                this.forceRerender = false;
                this.forceRerender = true;
              }
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      convertAverageTime(time) {
        let timeStamp = time;
        let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
        if (years >= 1) {
          return years.toFixed(0) + " yıl"
        } else {
          let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
          if (months >= 1) {
            return months.toFixed(0) + " ay";
          } else {
            let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
            if (weeks >= 1) {
              return weeks.toFixed(0) + " hafta";
            } else {
              let days = timeStamp / 1000 / 60 / 60 / 24;
              if (days >= 1) {
                return days.toFixed(0) + " gün";
              } else {
                let hours = timeStamp / 1000 / 60 / 60;
                if (hours >= 1) {
                  return hours.toFixed(0) + " saat"
                } else {
                  let minutes = timeStamp / 1000 / 60;
                  if (minutes >= 1) {
                    return minutes.toFixed(0) + " dakika";
                  } else {
                    let seconds = timeStamp / 1000;
                    return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
                  }
                }
              }
            }
          }
        }
      },
      pasteEventListener(e) {
        e.preventDefault();
        let text;
        if (e.clipboardData || e.originalEvent.clipboardData) {
          text = (e.originalEvent || e).clipboardData.getData('text/plain');
        } else if (window.clipboardData) {
          text = window.clipboardData.getData('Text');
        }

        if (document.queryCommandSupported('insertHTML')) {
          document.execCommand('insertHTML', false, text);
        } else {
          document.execCommand('paste', false, text);
        }
      },
      focusEvent(event, chat) {
        this.hide = true;
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
        if (!chat.isseen) {
		      this.api.workstation.seenMessage(chat.c_uuid, chat.receiver.username)
			      .then(({data}) => {
				      chat.isseen = true;
			      });
        }
	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;
	      this.forceRerender = false;
	      this.forceRerender = true;
      },
      blurEvent(event, chat) {
        this.hide = false;
        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;
	      this.forceRerender = false;
	      this.forceRerender = true;
      },
      chatBoxClick(event, conversationInfo) {
	    	this.$store.state.activeConversationId = conversationInfo.c_uuid;

        //mouse click birakildiginda secilmis text var ise focus yapmayacak
        let highlightedText = "";
        if (window.getSelection) {
          highlightedText = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
          highlightedText = document.selection.createRange().text;
        }


        let hasClassEmojiImg = Array.from(event.target.classList).indexOf('emoji-img') > -1;
        let hasClassEmoji = Array.from(event.target.classList).indexOf('emoji') > -1;
        let hasPlaceHolder = event.target && event.target.getAttribute('placeholder') === 'Ara...';


        if (highlightedText.length === 0 && (!hasPlaceHolder && !hasClassEmojiImg && !hasClassEmoji && event.target.className !== 'input') ) {
          if (document.getElementById('global-message-input'+conversationInfo.c_uuid)){
	          document.getElementById('global-message-input'+conversationInfo.c_uuid).focus();
          }
            //this.setCursorToEnd(document.getElementById('global-message-input'+conversationInfo.c_uuid));
          this.openEmojiPicker = false;
        }

        let hasClassChatBoxIcons = Array.from(event.target.classList).indexOf('setting-open-box') > -1;
        let hasClassSettingsMenuBox = Array.from(event.target.classList).indexOf('setting-box-options-for-click') > -1;

        //console.log(Array.from(event.target.classList))

        let hasClassFirstMessageBody =  Array.from(event.target.classList).indexOf('first-message-click') > -1;

        if(!hasClassFirstMessageBody){
          let tempConversations = this.$store.state.openConversations;
          tempConversations.forEach(conversation => {
            if(conversationInfo.c_uuid !== conversation.c_uuid){
              conversation.openSettingBox = false;
            } else {
              if(!hasClassSettingsMenuBox){
                conversation.openSettingBox = hasClassChatBoxIcons ? !conversation.openSettingBox : false;
              }
            }
          });

          this.$store.state.openConversations = tempConversations;
          this.forceRerender = false;
          this.forceRerender = true;
        }

      },
      keyUp(event) {
        if (event.which === 13 && this.searchTerm) {
          this.searchingMode = true;
          this.getConversationsV2();
        }
        if(this.searchTerm.length === 0){
          this.searchingMode = false;
          this.getConversationsV2();
        }
      },
      clearSearch() {
        this.searchTerm = '';
        this.searchingMode = false;
      },
      addEmoji(emoji, chat) {
        chat.messageWillSend = (chat.messageWillSend === null ? '' : chat.messageWillSend) + emoji.data;
	      const ele = document.getElementById('global-message-input'+chat.c_uuid);
	      ele.innerHTML = ele.innerHTML + emoji.data;

        const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;
	      this.forceRerender = false;
	      this.forceRerender = true;

	      this.setCursorToEnd(ele);

      },
      setCursorToEnd(ele) {
	    	if(ele){
			    let range = document.createRange();
			    let sel = window.getSelection();
			    if (ele.innerHTML.length > 0) range.setStart(ele,1);
			    else range.setStart(ele, 0);
			    range.collapse(true);
			    sel.removeAllRanges();
			    sel.addRange(range);
			    ele.focus();
        }

      },
      pageClickHandler(event, chat) {
        if (chat.openEmojiPicker && !event.target.className.includes('emoji-img') && !event.target.parentNode.className.includes('emoji')) {
	        this.setCursorToEnd(document.getElementById('global-message-input'+chat.c_uuid));
        	chat.openEmojiPicker = false;
          const chatIndex = this.$store.state.openConversations.findIndex(conversation => conversation.c_uuid === chat.c_uuid)
          let tempConversations = this.$store.state.openConversations;
	        tempConversations[chatIndex] = chat;
	        this.$store.state.openConversations = tempConversations;
	        this.forceRerender = false;
	        this.forceRerender = true;
        }

      },
	    sendMessage(type, message = null, externalID = null, c_uuid = this.activeConversationId) {

		    let c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === c_uuid);
		    let chat = this.$store.state.openConversations[c_index];

		    if (chat.receiver){

			    chat.receiver.fromWhere = chat.fromWhere;
			    chat.receiver.gigID = chat.gigID;
          chat.receiver.request_id = chat.request_id;
          chat.receiver.request_uuid = chat.request_uuid;
			    chat.receiver.blogID = chat.blogID;
			    chat.receiver.cat_id = chat.cat_id;
			    chat.receiver.selectedTypes = chat.selectedTypes;
			    chat.receiver.sub_cat_id = chat.sub_cat_id;
			    chat.receiver.whereFrom = chat.fromWhere;
			    chat.receiver.clickedFrom = chat.clickedFrom || null;
        }
		    let arrangedTrackHelper = this.Helper.sendMessageProps("chatBox", chat.receiver)

		    this.Helper.trackEvents.sendMessage(arrangedTrackHelper);
		    chat.defaultChangeHeight = 305;
		    chat.defaultlistenHeight = 42;
		    chat.openEmojiPicker = false;
		    chat.firstMessage = false;

        chat.is_receiver_seen = null;

		    let payload_id = +new Date() + Math.floor(Math.random() * 1000) + 1;
		    payload_id = payload_id.toString();

		    // asagidaki islemi this.channelChat ' te yapiyoruz
        if(type === "message" && this.activeConversationId === c_uuid){
	        chat.messageWillSend = '';
	        document.getElementById('global-message-input'+c_uuid).value = '';
	        document.getElementById('global-message-input'+c_uuid).innerHTML = '';
	        chat.messages.push({message, u_uuid: this.user.uuid, payload_id});
	        this.scrollDown(chat);
        }

		    let tempConversations = this.$store.state.openConversations;
		    tempConversations[c_index] = chat;
		    this.$store.state.openConversations = tempConversations;
		    let lastSeenIdForRemove = '';

		    this.api.workstation.sendMessageV2(type, c_uuid, message, externalID, chat.force_bicoin, payload_id, lastSeenIdForRemove, JSON.stringify(arrangedTrackHelper))
			    .then(({data}) => {
				    let result = data;

				    if (!result.success) {
					    this.$toasted.global.errorToast({description: result.message});
					    this.scrollDown(chat);
				    }
			    })
			    .catch(err => {
				    console.log(err)
				    this.scrollDown(chat);
				    this.forceRerender = false;
				    this.forceRerender = true;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
        this.scrollDown(chat);
	    },
      makeActive(activeTitle) {
        this.searchingMode = false;
        this.searchTerm = '';
        if(this.$store.state.chatUserActiveTitle !== activeTitle){
          this.$store.state.chatUsersOffset = 0;
          this.getConversationsV2(activeTitle, false);
        }
      },
      async onFileChangeV2(e, chat) {
		    let files = e.target.files || e.dataTransfer.files;
		    if (!files.length)
			    return;


        const loadVideo = file => new Promise((resolve, reject) => {
          try {
            let video = document.createElement('video')
            video.preload = 'metadata'

            video.onloadedmetadata = function () {
              resolve({is_video:true, video:this})
            }

            video.onerror = function () {
              resolve({is_video:false, video:{}})
            }

            video.src = window.URL.createObjectURL(file)
          } catch (e) {
            resolve({is_video:false, video:{}})
          }
        })

		    let timeStamp = +new Date();
		    let timeStampObj = {};

		    for (let i = 0; i < files.length; i++) {
		    	let rand = i +  Math.floor(Math.random() * 1000) + 1;
			    timeStamp = timeStamp + rand;
			    timeStampObj['t_' + rand] = timeStamp;
			    chat.uploads.push({stamp: timeStampObj['t_' + rand]});

			    let chatIndex = this.$store.state.openConversations.findIndex(m => m.c_uuid === chat.c_uuid);
			    let tempConversations = this.$store.state.openConversations;
			    tempConversations[chatIndex] = chat;
			    this.$store.state.openConversations = tempConversations;
			    this.$nextTick(function () {
				    this.forceRerender = false;
				    this.forceRerender = true;
				    let element = document.getElementById('globalScrollMessage'+chat.c_uuid);
				    if (element)
					    this.scrollDown(chat);
			    });

          let video = await loadVideo(files[i])

			    this.uploadFileToGoogle(files[i], this.Constants.UPLOAD_TYPES.MESSAGE, chat.c_uuid, timeStamp, chat.r_id, chat.r_uuid, video.is_video ? video.video.duration : 0)
				    .then(result => {
					    this.sendMessage('upload', null, result.externalID, result.c_uuid);
				    }).catch(err => {
				    	console.log(err);
			    });
		    }

        this.$nextTick(function () {
          this.forceRerender = false;
          this.forceRerender = true;
          let element = document.getElementById('globalScrollMessage'+chat.c_uuid);
          if (element)
            this.scrollDown(chat);
        });

		    e.target.value = ''

	    },
      channelChatDeleteFile(data){
        if (data.c_uuid && data.u_uuid !== this.user.uuid) {
          const chatIndex = this.$store.state.openConversations.findIndex(m => m.c_uuid === data.c_uuid);
          if (chatIndex > -1) {
            let chat = this.$store.state.openConversations[chatIndex]
            const index = chat.messages.findIndex(m => m.id === data.id);
            if (index > -1) {
              chat.messages[index].hide_message = data.hide_message;
              chat.messages[index].hide_message_description = data.hide_message_description;
              chat.messages[index].hide_message_text = data.hide_message_text;

              const chatIndex2 = this.$store.state.openConversations.findIndex(m => m.c_uuid === chat.c_uuid);
              let tempConversations2 = this.$store.state.openConversations;
              tempConversations2[chatIndex2] = chat;
              this.$store.state.openConversations = tempConversations2;
              this.forceRerender = false;
              this.forceRerender = true;
            }
          }
        }
      },
      deleteUploadedFile(chat, message) {
	      chat.deleteFileLoader = true;
	      const chatIndex = this.$store.state.openConversations.findIndex(m => m.c_uuid === chat.c_uuid);
	      let tempConversations = this.$store.state.openConversations;
	      tempConversations[chatIndex] = chat;
	      this.$store.state.openConversations = tempConversations;

	      this.forceRerender = false;
	      this.forceRerender = true;

	      const id = message.id;
        this.api.chat.deleteMessage(id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              const index = chat.messages.findIndex(m => m.id === id);
              if (index !== -1) {
	              chat.messages[index].hide_message = result.data.hide_message;
	              chat.messages[index].hide_message_description = result.data.hide_message_description;
	              chat.messages[index].hide_message_text = result.data.hide_message_text;
              }

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            chat.deleteFileLoader = false;
	          const chatIndex2 = this.$store.state.openConversations.findIndex(m => m.c_uuid === chat.c_uuid);
	          let tempConversations2 = this.$store.state.openConversations;
	          tempConversations2[chatIndex2] = chat;
	          this.$store.state.openConversations = tempConversations2;
	          this.forceRerender = false;
	          this.forceRerender = true;
          })
          .catch(err => {
            chat.deleteFileLoader = false;
	          const chatIndex2 = this.$store.state.openConversations.findIndex(m => m.c_uuid === chat.c_uuid);
	          let tempConversations2 = this.$store.state.openConversations;
	          tempConversations2[chatIndex2] = chat;
	          this.$store.state.openConversations = tempConversations2;
	          this.forceRerender = false;
	          this.forceRerender = true;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
    },
    computed: {
      isMessengerActive() {
        let fullPath = this.$store.state.route.fullPath;
        let firstSegmentOfPath = fullPath.split('/')[1];
        return fullPath === "/panel/mesajlar/" || firstSegmentOfPath === "chat" || firstSegmentOfPath === "call";
      },
      activeConversationId() {
        return this.$store.state.activeConversationId;
      },
      chatUsers() {
        return this.searchingMode ? (this.searchedConversations || []) : (this.$store.state.chatUsers || []);
      },
    	computedConversations() {
		    return this.$store.state.openConversations.slice(0, this.showExtraChatIcon ? this.showExtraChatIcon : 4)
      },
	    computedExtraConversations() {
    		if(this.forceRerender){}
		    return this.$store.state.openConversations.slice(this.showExtraChatIcon ? this.showExtraChatIcon : 4, 30)
	    },
	    computedExtraConversationsMessageCount(){
    		let count = 0;
		    if(this.forceRerender){}
		    this.computedExtraConversations.forEach(function (chat) {
			    count += chat.messageCount
		    });
		    return count
      },
    	showExtraChatIcon() {
		    const width = this.$store.state.windowWidth
        const conversationCount = this.$store.state.openConversations.length
		    if (conversationCount > 2 && width < 1439) {
		    	return 2
        } else if (conversationCount > 3) {
			    return 3
		    } else {
		    	return 0
        }
	    }
    },
    beforeDestroy() {
      this.EventBus.$off('flashMessage');
      this.EventBus.$off('externalOpenChat');
      this.EventBus.$off('closeChat');
      this.EventBus.$off('closeConversationBar');
      this.EventBus.$off('bindPusher');
    },
    created() {
      this.EventBus.$on('bindPusher', payload => {
        if(payload.payloadType === 'new'){
          this.channelChatV2(payload);
        } else if(payload.payloadType === 'writing'){
          this.channelChatWritingV2(payload)
        } else if(payload.payloadType === 'chat_seen'){
          this.channelChatSeenV2(payload)
        } else if(payload.payloadType === 'blockActions'){
          this.channelChatBlockActionsV2(payload)
        } else if(payload.payloadType === 'delete'){
          this.channelChatDeleteFile(payload)
        }
      });

	    this.EventBus.$on('flashMessage', payload => {
	      if(!this.isMessengerActive){
          this.flashMessage = payload;
        }
      });

	    this.EventBus.$on('externalOpenChat', conversationInfo => {
		    this.openChatWithClickv2(conversationInfo, true);
	    });

	    this.EventBus.$on('closeChat', (payload) => {
		    if (payload) {
			    this.closeChatBoxMethodsV2(payload.chat);
		    }
	    });

      this.EventBus.$on('closeConversationBar', (payload) => {
        if (payload) {
         this.closeConversations();
        }
      });

	    window.addEventListener('dragleave', this.dragLeaveEvent, false);
	    window.addEventListener('dragenter', this.dragStartEvent, false);


	    this.getConversationsV2('all_init', false)

    }
  }
</script>

<style scoped lang="scss">
.info-icon-container {
  display: inline-block;
  cursor: help;

  margin-right: 8px;
  margin-top: 4px;


  .info-icon /deep/{
    width: 16px!important;
    height: 16px;
    path {
      fill: #8b95a1;
    }
  }
}

.playIcon {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  border: 1px solid white;
  background-color: white;
  border-radius: 50%;
  img {
    height: 35px;
    width: 35px;
  }
  &:hover{
    opacity: 0.9;
  }
}

.image-u-div{
  display: flex; align-items: center; justify-content: center; cursor: pointer;

  .si-icon-container{
    position: absolute;
    background-color: #2d3640;
    width: 32px;
    height: 32px;
    top:3px;
    right:3px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .si-icon /deep/ {

      width: 18px;
      height: 18px;

      path {
        fill: #fff;
      }
    }
  }

  &:hover{
    .si-icon-container{
      background-color: #f2b46d;
    }
  }
}


.download-icon-container{

  display: flex;
  align-items: center;
  height: 13px;
  cursor: pointer;
  color: #2d3640;
  letter-spacing: -0.5px;

}
.download-icon /deep/ {

  width: 13px;
  height: 13px;
  margin-right: 5px;
  path {
    fill: #2d3640;
  }
}


.icon-message-seen-container{
  margin-left: 4px;
  .icon-message-seen /deep/{
    width: 15px;
    height: 15px;
    path{
      fill: #8b95a1;
    }
  }

  .icon-message-seen-green /deep/{
    width: 15px;
    height: 15px;
    path{
      fill: #26ca9a;
      stroke: #26ca9a;
    }
  }
}

.dot-icon /deep/{
  height: 20px;
  path{
    fill: #8B95A1;
  }
}

.new-titles{
  display:flex;width: 100%; height: 46px; padding-left: 10px; margin-top: 10px; margin-bottom: 5px;
  .new-titles-box{
    cursor: pointer;
    height: 30px;

    width: 45%;
    border-radius: 2px;
    display: flex; align-items: center; justify-content: center; padding-bottom: 2px;

    .new-titles-text{
      font-weight: 500; font-size:14px;
    }
  }
}

  .chat-down-side{
    display: flex; flex-direction: row; margin-top: 4px;  margin-left: 10px; margin-right: 10px;
  }

  /*drag and drop div*/
  .drag-drop-class {
    position: absolute;
    max-height: calc(100% - 50px);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    top: 100vh;
    font-size: 16px;
    color: rgb(230, 50, 98);
    font-weight: 500;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 100;
      text-transform: uppercase;
      width: 80%;
      height: 60%;
      border: 2px dashed rgb(230, 50, 98);
    }
  }

  /*drag and drop div*/
  #drag-drop {
    position: absolute;
    max-height: calc(100% - 50px);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    top: 100vh;
    font-size: 16px;
    color: rgb(230, 50, 98);
    font-weight: 500;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 100;
      text-transform: uppercase;
      width: 80%;
      height: 60%;
      border: 2px dashed rgb(230, 50, 98);
    }
  }

  .message-box {
    cursor: pointer;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
    height: 50px;
    width: 210px;
    display: flex;
    bottom: 10px;
    right: 25px;
    position: fixed;
    z-index: 40;
    align-items: center;
    justify-content: space-between;
  }

  .message-box-content {
    height: 30px;
    width: 180px;
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .indicator {
    background: #469a1f;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    align-self: center;
    margin-right: 10px;
  }

  .changeheight {
    min-height: 245px;
    overflow-y: auto;
    padding: 0 22px;
    padding-bottom: 20px;
    background-color: #fff;
    margin-right: 2px;

    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfc8d2;
    }
  }

  .input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    margin-left: 10px;
    color: #a4a4a4;
    pointer-events: none;
    transition: opacity .08s linear;
    z-index: 100;
    line-height: 20px;
    font-size: 14px;
    margin-top: 10px; visibility: visible;
  }

  div.input {
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    background-color: #fefdfd;
    border: solid 1px #bfc8d2;
    resize: none;
    padding: 13px 40px 13px 10px;
    font-size: 16px;
    color: #2d3640;
    position: relative;
    z-index: 1;
    max-height: 80px;
  }

  div.input:focus {
    outline: none;
  }

  .chattarih {
    text-align: left;
    border-bottom: solid 1px #dde3eb;
    line-height: 0.1em;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .chattarih span {
    background: #fff;
    padding: 0 15px;
    font-size: 14px;

    color: #8b95a1;
  }

  .messageMenu {
    z-index: 124;
    text-align: center;
    bottom: 0;
    top: 0;
    position: fixed;
    right: 0;
    background-color: #fff;
    border-left: 1px solid #dde3eb;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    width: 248px;
    height: 100vh;
  }

  .notifitem {
    margin-left: 10px;
    font-size: 14px;
    color: #242424;
  }

  .notifitemrow:hover {
    background-color: #f7f9fb;
  }

  .flashMessageAvatar {
    bottom: 31px;
    right: 64px;
    position: fixed;
    z-index: 101;
  }

  .flashMessageArea {
    bottom: 35px;
    right: 130px;
    position: fixed;
    z-index: 101;

    .flashMessageInside{
      cursor: pointer;
      font-weight:300;
      padding-left: 20px;
      display: flex; align-items: center;
      width:240px; height:50px;background: #FFFFFF;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
      border-radius: 100px 100px 0 100px;
    }

  }

  .flashMessageClose {
    bottom: 90px;
    font-size: 14px;
    color: #bfc8d2;
    font-weight: 500;
    right: 140px;
    position: fixed;
    z-index: 101;
    background-color: #2d3640;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0.8;
    &:hover{
      opacity: 1;
    }

  }

  @media only screen and (max-width: 780px) {

    .flashMessageAvatar {
      bottom: 61px;
    }

    .flashMessageArea {
      bottom: 65px;
    }

    .flashMessageClose {
      bottom: 120px;

    }

  }

  .sideMessagesHeader-text {
    font-size: 18px;
    font-weight: 600;
    color: #2d3640;
  }

  .sideMessages {
    padding-top: 14px;
    max-height: calc(100% - 60px);
    box-sizing: border-box;
    background: #fff
  }

  .notifitemrow {
    height: 60px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #eaedf2;

    &:first-child {
      border-top: 1px solid #eaedf2;
    }
  }

  .chat-is-seen {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-color: #fd4056;
    border-radius: 50%;
    z-index: 99;
  }

  /*chat box*/

  .chatbox-container{
    display: flex;
  }



  .extra-conversations-button{
    background: none;
    background-color: white!important;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 0;
    &:hover{
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border-radius: 0;
      background-color: white!important;
    }
  }
  .chatboxMoreIconContainer{
    z-index: 99;
    position: fixed;
    bottom: 0;
    right: 50px;

    .chatboxMoreIcon{

      cursor: pointer;
      width: 50px;
      height: 50px;
      background-color: #FFFFFF;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        opacity: 0.70;
      }

      &:hover{
        img{
          opacity: 0.86;
        }
      }
    }





    .extra-conversations{
      position: absolute;
      right: -1px;
      bottom: 46px;
      padding:5px 0;

      background-clip: padding-box;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 3px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, .3);

      .extra-conversations-item{
        min-width: 80px;
        text-align: left;
        padding:6px 40px 6px 22px;
        font-size: 13px;
        color: #2d3640;
        display: flex;white-space: nowrap;

        img{
          opacity: 0;

          padding-bottom: 2px;
          right:10px;position:absolute;width: 10px; height: 10px; align-self: flex-end

        }
        &:hover{
          background-color: #2d3640;
          color: #fff;
          img{
            opacity: 0.8;
            &:hover{
              opacity: 1;
            }
          }
        }
      }
    }
  }


  .chatbox {

    z-index: 100;
    position: fixed;
    bottom: 0;
    right: 260px;
    width: 330px;
    height: 424px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .chatboxInner {
    height: 50px;
    background-color: #2d3640;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 10px 0;
  }

  .user-information-div {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .icon-div {
    display: flex;
    align-items: center;
    padding-right: 20px;

    .chatbox-icons {
      cursor: pointer;
      margin-left: 25px;
      &:hover{
        opacity: 0.6;
      }
    }
  }

.side-nav-icons-m /deep/ {
  width: 18px!important;
  height: 18px;
  path {
    opacity: 0.8;
    fill: #8b95a1;
  }
}

  .setting-box {
    position: absolute;
    width: 180px;
    border-radius: 2px;
    background-color: #ffffff;
    right: -20px;
    top: 40px;
    z-index: 10;
    box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.1);
    border: solid 1px rgba(94, 107, 121, 0.1);

    .setting-box-options {
      cursor: pointer;
      font-size: 14px;
      line-height: 2.29;
      color: #959aa8;
      margin-left: 19px;

      &:hover {
        color: #2d3740;
      }
    }
  }

  .setting-box::after {
    content: '';
    position: absolute;
    right: 25px;
    top: -7.8px;
    width: 15px;
    height: 15px;
    border-top: solid 1px rgba(94, 107, 121, 0.1);
    border-left: solid 1px rgba(94, 107, 121, 0.1);
    box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.1);
    border-bottom: 0;
    border-right: 0;
    border-radius: 20% 0 0 0 / 15%;
    transform: rotate(45deg);
    background-color: #fff;
  }

  .progress-main {
    background-color: #ebf9fc;
    height: 40px;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .progress-percent {
      font-size: 14px;
      font-weight: 500;
      color: #85aab1;
      margin-left: 9px;
      width: 45px;
    }
  }

  .progressbar {
    margin-top: 0;
    margin-left: 15px;
    width: 260px;
    height: 6px;
  }

  .progressbar::-webkit-progress-bar {
    background-color: #c9e7ed;
    border-radius: 100px;

  }

  .progressbar::-webkit-progress-value {
    border-radius: 100px;
    background-color: #67cc64;
  }

  .progressbar[value] {
    background-color: #c9e7ed;
    border-radius: 100px;
  }

  .progressbar::-moz-progress-bar {
    border-radius: 100px;
    background-color: #67cc64;
  }



  .global-message-input {
    font-size: 14px;
    padding: 8px 33px 13px 10px;
    min-height: 20px;
    background-color: #FFFFFF;
    border: none !important;
  }

  .blocked-user-input {
    word-break: break-word;
    padding: 8px 10px 13px;
    width: 300px;
    min-height: 33px;
    border: none;
    font-size: 13px;
    border-top:1px solid #e1e1e1;
  }

  .typeOrder {
    height: 78px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 0 !important;
    background-color: #d7f8f5;
  }

  .order-button {
    width: 80px;
    height: 30px;
    min-height: 30px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    line-height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #2d3640;
    margin-right: 20px;
  }

  .chatbubble.right {
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: rgba(163, 224, 225,0.5);
    float: right;
    border-radius: 7.5px 7.5px 0 7.5px;
  }

  .chatbubble.left {
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #e4e8ed;
    float: left;
    border-radius: 7.5px 7.5px 7.5px 0;
  }

  .chatbubble {
    position: relative;
    max-width: 420px;
    width: auto;
    display: inline-block;
    font-size: 13px;
    margin-top: 5px;
    line-height: 1.29;
    padding: 7px 17px 7px;
  }

  .chattime.right {
    position: relative;
    float: right;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-right: 5px;
  }

  .chattime.left {
    position: relative;
    float: left;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-left: 50px;
  }

  .loading-div {
    font-size: 13px;
    margin-bottom: -17px;
  }

  .loading-div:after {
    display: inline-block;
    animation: dotty steps(1, end) 1.5s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }

  .listenheight {
    position: relative;
    height: 1px;
    border-top: 1px solid #bfc8d2;
  }

  .attachment-icon {
    cursor: pointer;
    color: #8b95a1;
    position: relative;
    width: 88px;
    height: 17px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment.svg') no-repeat;
    background-size: contain;
  }

  .attachment-icon:hover {
    color: #2d3640 !important;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment_hover.svg') no-repeat;
    background-size: contain;

    .tooltiptext1 {
      visibility: visible;
    }
  }

  .tooltiptext1 {
    visibility: hidden;
    width: 124px;
    background-color: #ffffff;
    color: #afb8b8;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -95px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
  }

  .tooltiptext1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 13px;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  .offer-icon {
    cursor: pointer;
    color: #8b95a1;
    position: relative;
    margin-left: 15px;
    width: 130px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer.svg') no-repeat;
    height: 17px;
    line-height: 13px;
    background-size: 15px 17px;
  }

  .offer-icon:hover {
    color: #2d3640 !important;
    background-image: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer_hover.svg');
    background-size: 15px 17px;

    .tooltiptext2 {
      visibility: visible;
    }
  }

  .tooltiptext2 {
    visibility: hidden;
    width: 124px;
    background-color: #ffffff;
    color: #afb8b8;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -130px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
  }

  .tooltiptext2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 44px;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  .info-bar-container {
    height: 50px;
    background: white;
    border-bottom: 1px solid #eaedf2;
    display: flex;
    align-items: center;
    width: 100%;

  }

  .info-bar {

    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    height: 34px;
    border-radius: 5px;
    background-color: #eaedf2;

    color: #8b95a1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;

  }


  .tel-line-bar {

    height: 24px;
    padding-left: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    background: #e63262;
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
    color: #ffffff;
    opacity: 0.9;

  }

  /*firstmessage css*/

  .first-message-body {
    margin-top: 20px;
    padding-top: 10px;
    margin-right: -22px;
    margin-left: -22px;
    border-top: 1px solid #bfc8d2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    p {

    }

    img {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

  .first-message-button-div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15px;

    div {
      padding: 10px;
      background-color: #fff;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #bfc8d2;
      font-size: 14px;
      border-radius: 2px;

      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }
  }

  .first-message-header {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

  .chat-where-from-info{

    margin-bottom: 10px;

    .up-text{
      color: #939c9c;
      font-size: 11px;


    }

    .where-from-box{
      margin-top: 8px;
      border: solid 1px rgba(184, 234, 187, 0.4);
      background-color: rgba(38, 202, 154, 0.2);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      &:hover{
        border: solid 1px rgba(184, 234, 187, 0.8);
        background-color: rgba(38, 202, 154, 0.3);
      }
      .icon-request-container{
        margin-left: 10px;
        .icon-request /deep/{
          width: 20px;
          height: 20px;
          path {
            fill: #26ca9a;
          }
        }
      }

      .where-from-title{
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        color: #2d3640;
        margin-left: 10px;
        margin-right: 10px;

      }
    }
  }

  .chat-welcome-message {
    min-height: 156px;
    background-color: #f4f5f7;
    padding: 20px 32px;
  }

  .chat-welcome-message-user-info {
    display: flex;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      border-radius: 100px;
    }

    .welcome-message-user-info-username {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #242424;
      margin-bottom: 2px;
    }
  }

  .chat-welcome-message-content {
    font-size: 14px;
    line-height: 1.29;
    text-align: left;
    color: #8b95a1;
    margin-top: 16px;
    margin-bottom: 11px;
  }

  .chat-welcome-message-time-info {
    display: flex;
    flex-direction: column;

    .welcome-seperator {
      width: 100%;
      border-bottom: solid 1px #bfc8d2;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .chat-welcome-message-time-info-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 13px;
        line-height: 1.29;
        color: #8b95a1;
        margin-left: 7px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .sideMessagesInside {
    margin-right: 2px;
    overflow-y: auto;
    max-height: calc(100vh - 225px);

    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfc8d2;
    }
  }

  .right-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: #fff;
    float: right;
    border-radius: 5px;
    border: solid 1px #e4e8ed;
  }

  .left-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #fff;
    float: left;
    border: solid 1px #e4e8ed;
    border-radius: 5px;
  }

  .iscall-body {
    display: flex;
    flex-direction: row;

    .iscall-phone-icon {
      margin-right: 8px;
    }

    .iscall-username {
      margin-bottom: 5px;
    }

    .iscall-created-date {
      color: rgb(147, 156, 156);
    }
  }

  .scroll-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: solid 1px #bfc8d2;
    position: absolute;
    right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

    .scroll-button-message-count {
      position: absolute;
      top: -5px;
      left: -3px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: rgb(230, 50, 98);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 15px;
      font-weight: 600;
    }
  }


  .extra-messages-scroll-button {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: solid 1px #bfc8d2;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    left: 1px;
    margin-top:-2px;

    .scroll-button-message-count {
      position: absolute;

      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: rgb(230, 50, 98);
      color: #fff;
      font-size: 11px;
      text-align: center;
      line-height: 12px;
      font-weight: 600;
    }
  }

  .tooltiptext {
    visibility: hidden;
    width: 128px;
    background-color: #ffffff;
    color: #afb8b8;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    padding: 5px 10px;
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -130px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 44px;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  .chattarih {
    text-align: left;
    border-bottom: solid 1px #dde3eb;
    line-height: 0.1em;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .chattarih span {
    background: #fff;
    padding: 0 15px;
    font-size: 14px;

    color: #8b95a1;
  }

  /*loader part*/
  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .chat-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .messages-body-avatar-loader {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #dfe4ec;
    margin-right: 10px;
  }

  .messages-body-message-loader {
    width: 200px;
    height: 16px;
    margin-bottom: 10px;
    background-color: #dfe4ec;
  }

  .messages-body-time-loader {
    width: 60px;
    height: 10px;
    background-color: #dfe4ec;
  }


  .button-badge {
    background: #fd4056;
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    font-weight: 800;
    width: 17px;
    height: 17px;
    line-height: 14px;
    text-align: center;
    margin-left: 8px;
  }

  .badge-for-title {
    position: relative;
    margin: 0;
    top: -5px;
    left: 0px;
  }

  .search-container {
    position: relative;
  }

  .search-image {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 17px;
  }

  .search-input {
    width: calc(100% - 60px);
    height: 32px;
    border: none;
    border-top: 1px solid #eaedf2;
    border-bottom: 1px solid #eaedf2;
    padding: 9px 10px 11px 50px;
  }

  .title-type-body {
    display: flex;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 5px;
  }

  .title-type-main {
    cursor: pointer;
    width: 50%;
    height: 28px;
    color: #8b95a1;
    font-size: 14px;
    font-weight: 500;

    .title-type {
      text-align: center;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  .active-title {
    margin-bottom: -3px;
    border-bottom: 3px solid #242424;
    color: #242424;
  }



  .emoji-img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 10px;
    opacity: 1;
    cursor: pointer;
    z-index: 1;
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon.svg');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;

    &:hover {
      background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg');
      width: 22px;
      height: 22px;
      right: 14px;
      top: 9px;
    }
  }

  .emoji-img-active {
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg') !important;
  }

  .emoji-picker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    position: absolute;
   // height: 300px !important;
    left: 30px;
    top: -270px;
    z-index: 99999999999;
  }

  .delete-uploaded-file {
    z-index: 3;
    box-sizing: border-box;
    position: absolute;
    background-color: #2D3640;
    border: 2px solid #d7ebff;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -12px;
    right: -12px;
    cursor: pointer;

    .delete-uploaded-file-img /deep/ {
      width: 9px;
      height: 12px;
      margin-left: -1px;


      path {
        fill: #fff;
      }
    }
  }
</style>
