<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="verify-body">
          <p class="verify-header">Numaranı <span>Onayla</span></p>
          <p class="verify-text">
            Numaranı onaylayabilmen için <span>sonu {{gsm.slice(-4,-2)}} {{gsm.slice(-2)}}</span> ile biten cep telefonuna
            <span>5 haneli bir onay kodu</span> içeren <span>SMS</span> gönderdik. Bu kodu aşağıdaki alana girmelisin.
          </p>

          <div class="input-container">
            <input  class="verify-input" min="0" max="9" maxLength="1" @keyup="onKeyPress" @mousedown="onFocus" :disabled="waitUntilRequestDone" v-model="code1" ref="code1" name="1" autocomplete="off"/>
            <input class="verify-input" min="0" max="9" maxLength="1" @keyup="onKeyPress" @mousedown="onFocus" :disabled="waitUntilRequestDone" v-model="code2" ref="code2" name="2" autocomplete="off"/>
            <input  class="verify-input" min="0" max="9" maxLength="1" @keyup="onKeyPress" @mousedown="onFocus" :disabled="waitUntilRequestDone" v-model="code3" ref="code3" name="3" autocomplete="off"/>
            <input  class="verify-input" min="0" max="9" maxLength="1" @keyup="onKeyPress" @mousedown="onFocus" :disabled="waitUntilRequestDone" v-model="code4" ref="code4" name="4" autocomplete="off"/>
            <input  class="verify-input" min="0" max="9" maxLength="1" @keyup="onKeyPress" @mousedown="onFocus" :disabled="waitUntilRequestDone" v-model="code5" ref="code5" name="5" autocomplete="off"/>
          </div>

          <div class="italic-warning">
            <div class="dash"></div>
            <p class="italic-warning-text">SMS gelmedi mi? </p>
            <div class="dash"></div>
          </div>

          <custom-button button-type="border" style="width: 300px; margin-top: 30px;" :disabled="waitUntilRequestDone" @click="sendVerificationNumber()">Tekrar Gönder</custom-button>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton'

  export default {
    name: "src-pages-modals-addNewCar-v1",
    components: {
      CustomButton,
    },
    data() {
      return {
        openedModal: false,
        waitUntilRequestDone: false,
        gsm: '',
        code1: null,
        code2: null,
        code3: null,
        code4: null,
        code5: null,
      }
    },

    methods: {
      hide() {
        if(!this.waitUntilRequestDone) this.$store.commit(this.types.DISABLE_MODAL);
      },

      onKeyPress(e) {

        if (e.key === ' ' || isNaN(e.key)) {
          e.preventDefault();
          return false;
        } else if (e.target.value.length !== 0) {
          this['code' + e.target.name] = e.key;
          if (e.target.name != 5) this.$refs['code' + (Number(e.target.name) + 1)].focus();
          e.preventDefault();
          return false;
        }
        if (e.target.name != 5) this.$refs['code' + (Number(e.target.name) + 1)].focus();
        this.codeCompleted();
      },

      onFocus(e) {
        this['code' + e.target.name] = null;
        if(e.target.name === '1'){
          this['code2'] = null;
          this['code3'] = null;
          this['code4'] = null;
          this['code5'] = null;
        }
      },

      completeGSMActivation(code) {
        this.waitUntilRequestDone = true;
        this.api.user.completeGSMActivation(code, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.state.user.info.gsm_is_activated = true;
              this.$toasted.global.infoToast({description: result.message});
              this.waitUntilRequestDone = false;
              this.hide();
            } else {
              this.waitUntilRequestDone = false;
              this.$toasted.global.errorToast({description: result.message})
            }
          })
          .catch(err => {
            this.waitUntilRequestDone = false;
            if (err.code !== "ECONNABORTED") {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            }
          });
      },

      codeCompleted() {
        if(this.code1 && this.code2 && this.code3 && this.code4 && this.code5) {
          const code = '' + this.code1 + this.code2 + this.code3 + this.code4 + this.code5;
          this.completeGSMActivation(code)
        }
      },

      sendVerificationNumber() {
        this.waitUntilRequestDone = true;
        this.api.user.activateGSM(this.gsm, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message})

            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.waitUntilRequestDone = false;
          })
          .catch(err => {
            this.waitUntilRequestDone = false;
            if (err.code !== "ECONNABORTED") {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            }
          });
      }

    },

    watch: {

      code1(newVal) {
        if(newVal || newVal === 0) this.codeCompleted();
      },

      code2(newVal) {
        if(newVal || newVal === 0) this.codeCompleted();
      },

      code3(newVal) {
        if(newVal || newVal === 0) this.codeCompleted();
      },

      code4(newVal) {
        if(newVal || newVal === 0) this.codeCompleted();
      },

      code5(newVal) {
        if(newVal || newVal === 0) this.codeCompleted();
      },
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
          this.gsm = info.gsm;

        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 140px 72px 140px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .verify-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .verify-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;

    span {
      font-weight: 600;
    }

    color: #2d3640;
  }

  .verify-text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 45px;
    color: #2d3640;

    span {
      font-weight: 800;
    }
  }
  @font-face {
    font-family: 'OCRAExtendedRegular';
    src: url('https://gcdn.bionluk.com/site/general/OCRAEXT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .verify-input {
    font-family: 'OCRAExtendedRegular';
    -webkit-appearance: textfield;
    vertical-align: middle;
    box-sizing: border-box;
    width: 55px;
    height: 57px;
    line-height: 57px;
    border-radius: 4px;
    border: 1px solid rgba(45, 54, 64, 0.4);
    background-color: rgba(139, 149, 161, 0.05);
    color: #2d3640;
    font-size: 50px;
    text-align: center;
    font-weight: 800;
    padding: 0;
    margin-left: 9px;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-container {
    display: flex;
    margin-bottom: 45px;
  }

  .italic-warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    .italic-warning-text {
      font-weight: 400;
      font-style: italic;
      font-size: 12px;
      color: #8b95a1;
      margin-left: 12px;
      margin-right: 12px;
    }
    .dash {
      width: 20px;
      border-bottom: 1px solid #8b95a1;
    }
  }


</style>
