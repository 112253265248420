<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;     background-color: rgba(0, 0, 0, .8);">
      <div class="modal-container" @click.stop>
        <div style="position: absolute;top: 30px; right: 40px;z-index: 9998; ">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;" :src="$root.CDN_SITE + 'icon/ic_close_white.svg'">
        </div>
        <div class="modal-inner" v-if="getModalInfo">
          <p class="filter-title">Arama</p>
          <div style="display: flex;flex-direction: column">
            <label class="filter-label"></label>
            <input v-model="searchTerm" type="text" class="filter-text">
          </div>
          <button :disabled="disableButton" class="done-button" @click="doneButton()">ARA</button>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-userSearch-v1",
    data() {
      return {
        searchTerm: null
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      doneButton() {
        this.EventBus.$emit('userSearch', {searchTerm: this.searchTerm});
        this.hide();
      },
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info) {
          this.searchTerm = info.searchTerm;
        }
        return true;
      },
      disableButton() {
        return this.searchTerm ? this.searchTerm.trim().length === 0 : true;
      }
    },

    mounted() {
    }
  }

</script>

<style scoped>

  .modal-mask{
    z-index: 9999999!important;
  }
  .modal-container {
    font-family: 'sofia-pro';
    width: 290px !important;
    margin-top: 70px;
    height: 300px;
    background-color: #ffffff;
    overflow-y: scroll;
    padding: 0;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    padding: 26px 20px 30px;
  }

  .filter-title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2d3640;
  }

  .filter-label {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    color: #a1a9a9;
    margin-top: 24px;
  }

  .super-drop-tek-cizgi {
    margin-top: 10px;
    font-size: 16px !important;
    padding-left: 5px;
    width: 249px;
    background-position-x: 229px;
    position: relative;
  }

  .done-button {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    background-color: #ffffff;
    margin-top: 30px;
  }
  .filter-text{
    margin-top: 10px;
    width: auto;
  }
</style>
