/**
 * Created by haluk on 5/18/17.
 */

const v2 = resolve => require(['./v2.vue'], resolve);
const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);


export default {
  v2,
  v2_mobile
}
