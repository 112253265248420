/**
 * Created by haluk on 6/15/17.
 */


import v4 from './v4/index.vue';
const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);

export default {
  v4,
  v2_mobile
}
