<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilPaymentRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="checkout-body">
          <p class="checkout-header">Kartı <span>Sakla</span></p>
        <div class="checkout-new-card">
          <div class="card-section">
            <custom-input label="Kartın Üzerindeki İsim" v-model="cardOwner" inputType="text" :valid="validationObj.cardOwner" @blur="inputOnBlur('cardOwner')" autocomplete="cc-name" style="width: 343px;"/>
            <custom-input label="Kart Numarası" v-model="cardNumber" :card-image="cardImage" :valid="validationObj.cardNumber" @blur="inputOnBlur('cardNumber')" inputType="card" style="margin-top: 30px; width: 343px"/>

            <div class="expire-body">
              <div style="display: flex">
                <custom-select id="expireMonth" label="Ay"  :options="monthOptions" v-model="month" :valid="validationObj.month" autocomplete="cc-exp-month" name="expireMonth" labelPosition="center" style="width: 80px;"/>
                <custom-select class="year" id="expireYear" label="Yıl" v-model="year" :valid="validationObj.year" autocomplete="cc-exp-year" name="expireYear" labelPosition="center" style="width: 80px;"/>
              </div>
              <custom-input label="CVC 2" v-model="cvc" :valid="validationObj.cvc" autocomplete="cc-csc" inputType="cvc" style="width: 99px;" @focus="flippingCard($event, true)" @blur="flippingCard($event, false)"/>
            </div>

          </div>
          <div class="card-flip-body">
            <div class="card-image-section">
              <div class="card-numbers">
                <span class="card-number-item" v-for="item in cardNumber.split('-')" v-if="cardNumber.length">{{item}}</span>
                <span class="card-number-item" v-for="i in 4 - Math.ceil(cardNumber.replace(/-/g, '').length / 4)">****</span>
              </div>

              <div class="card-image-type-body" v-if="cardImageType.length">
                <img :src="cardImageType" class="card-image-type">
              </div>

              <div class="card-numbers cvc2">
                <span class="card-number-item" v-if="!cvc">CVC2</span>
                <span class="card-number-item" v-if="!!cvc">{{cvc}}</span>
              </div>
            </div>
          </div>

        </div>
          <div class="tabs-separator"></div>
          <custom-button buttonType="green" :disabled="disableToPay || waitUntilPaymentRequestDone" @click="saveCard()" style="align-self: center; margin-top: 25px;">
            Kartı Kaydet
          </custom-button>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import CustomSwitch from '../../components/CustomSwitch'
  import CustomInput from '../../components/CustomInput'
  import CustomButton from '../../components/CustomButton'
  import CustomSelect from '../../components/CustomSelect'

  import { getCurrentMonth, getCurrentYear } from '@/utils/dateFns'

  export default {
    name: "src-pages-modals-addNewCar-v1",
    components: {
      CustomSwitch,
      CustomInput,
      CustomButton,
      CustomSelect
    },
    data() {
      return {
        cardOwner: '',
        cardNumber: '',
        cvc: '',
        year: null,
        month: null,
        openedModal: false,
        monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        cardImage: '',
        cardImageType: '',
        validationObj: {
          cardOwner: null,
          cardNumber: null,
          cvc: null,
          year: null,
          month: null,
        },
        cardFlipped: false,
        waitUntilPaymentRequestDone: false
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      flippingCard(e, bool) {
        this.cardFlipped = bool;
        this.inputOnBlur('cvc');
      },

      inputOnBlur(type) {
        let validationObj = {
          ...this.validationObj
        };
        switch (type) {
          case 'cardOwner':
            const strArr = this.cardOwner.trim().split(' ');
            validationObj = {
              ...this.validationObj,
              cardOwner: strArr.length > 1 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
          case 'cardNumber':
            validationObj = {
              ...this.validationObj,
              cardNumber: this.cardNumber.replace(/-/g, "").length === 16 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
          case 'cvc':
            validationObj = {
              ...this.validationObj,
              cvc: this.cvc.length === 3 || this.cvc.length === 4 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
        }
      },

      saveCard() {
        this.waitUntilPaymentRequestDone = true;
        this.api.orders.saveCard(this.cardOwner, this.cardNumber.replace(/-/g, ""),this.cvc, this.month, this.year, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.EventBus.$emit('newCardAdded', result.data.cards);
              this.$toasted.global.infoToast({description: result.message});
              this.hide();
            } else {
              let validationObj = {
                cardOwner: null,
                cardNumber: null,
                cvc: null,
                year: null,
                month: null,
              };
              this.validationObj = validationObj;
              this.$toasted.global.errorToast({description: result.message});
            }
            this.waitUntilPaymentRequestDone = false;
          })
          .catch(err => {
            this.waitUntilPaymentRequestDone = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }

    },

    watch: {
      year(newValue) {
        const validationObj = {
          ...this.validationObj,
          year: !!newValue
        };
        this.validationObj = {...validationObj};
        if (newValue && getCurrentYear() === newValue) {
          if (getCurrentMonth() >= this.month) {
            this.month = getCurrentMonth() + 1;
          }
          const month = [];
          for (let i = getCurrentMonth() + 1; i < 13; i++) {
            month.push(i);
          }
          this.monthOptions = month;
        } else if (newValue) {
          this.monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }
      },

      month(newVal) {
        const validationObj = {
          ...this.validationObj,
          month: !!newVal
        };
        this.validationObj = {...validationObj};
      },

      cardFlipped(newVal, oldVal) {
        const ele = document.getElementsByClassName('card-image-section')[0];
        if (newVal) {
          ele.classList.add('card-flipped')
        } else {
          ele.className = ele.className.replace(/\bcard-flipped\b/g, "");
        }
      },

      cardNumber(newVal, oldVal) {
        const pureNewVal = newVal.replace(/-/g, "");
        const pureOldVal = oldVal.replace(/-/g, "");

        if (pureNewVal.length > 5 && pureNewVal.slice(0, 6) !== pureOldVal.slice(0, 6)) {
          this.api.orders.cardBin(pureNewVal)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.cardImage = result.data.card_image_url;
                this.cardImageType = result.data.card_type_image_url;
              }
            });
        } else if (pureNewVal.length < 6) {
          this.cardImage = '';
          this.cardImageType = '';
        }
      }
    },


    computed: {
      disableToPay() {
          return this.cardOwner.trim().split(' ').length <= 1 ||
            this.cardNumber.replace(/-/g, "").length !== 16 ||
            !this.month ||
            !this.year ||
            this.cvc.length < 3

      },

      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;

        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  @font-face {
    font-family: 'OCRAExtendedRegular';
    src: url('https://gcdn.bionluk.com/site/general/OCRAEXT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 0px 25px 0px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .checkout-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 60px;

    span {
      font-weight: 600;
    }

    color: #2d3640;
  }

  .expire-body {
    margin-top: 30px;
    width: 343px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .year {
    margin-left: 20px;
  }

  .card-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .checkout-body {
    display: flex;
    flex-direction: column;
  }

  .checkout-new-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 38px;
    padding-right: 38px;
  }

  .card-flip-body {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    width: 305px;
    height: 192px;
    margin-top: 7px;
    position: relative;
  }

  .card-image-section {
    position: relative;
    width: 305px;
    height: 192px;
    background-image: url(https://gcdn.bionluk.com/site/b/img/credit-card-front.png);
    background-size: cover;
    background-repeat: no-repeat;

    animation-direction: normal;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-flipped {

    background-image: url(https://gcdn.bionluk.com/site/b/img/credit-card-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .card-numbers {
    display: flex;
    position: absolute;
    left: 30px;
    top: 80px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-number-item {
    font-family: 'OCRAExtendedRegular';
    min-width: 48px;
    font-size: 13px;
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
    margin-left: 17px;
    letter-spacing: 4px;

    span {
      margin: 0;
      padding: 0;
    }

    &:first-child {
      margin-left: 0px;
    }
  }

  .card-image-type-body {
    position: absolute;
    width: 59px;
    height: 35px;
    right: 32px;
    bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-image-type {
    max-width: calc(100% - 7px);
    max-height: calc(100% - 7px);
  }

  .cvc2 {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .tabs-separator {
    background-color: #bfc8d2;
    width: 100%;
    align-self: center;
    height: 1px;
    margin-top: 50px;
  }
</style>
