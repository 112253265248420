<template>
    <div style="width: 100%; margin: 0 auto; text-align: center; display: flex; justify-content: center">
      <div v-if="!$store.state.isMobile" class="version-warning">
        <div  style=" width: 60px; display: flex; align-items: center; justify-content: center; margin-left: 50px;
  height: 60px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: rgb(255, 255, 255); margin-right: 30px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="bi-icon-off" data-inject-url="https://gcdn.bionluk.com/site/cicons/ic-no-internet.svg" data-v-191ab8d8="">
            <path fill="#000" fill-rule="nonzero" d="M4.08 10.89a1.49 1.49 0 0 0-.19 2.109 1.488 1.488 0 0 0 2.108.19c.29-.24.6-.45.91-.66l-2.159-2.15c-.22.16-.45.33-.669.51zm4.256 3.108c-.24.127-.474.267-.7.42a1.5 1.5 0 1 0 1.729 2.45c.375-.27.79-.48 1.229-.62l-2.258-2.25zM.514 7.361a1.51 1.51 0 0 0-.14 2.12 1.508 1.508 0 0 0 2.118.13c.27-.24.55-.45.829-.67L1.193 6.81c-.19.19-.46.35-.68.55zM23.69 22.285l-6.703-6.697a1.49 1.49 0 0 0-.63-1.2 7.342 7.342 0 0 0-1.279-.72L12.442 11a9.358 9.358 0 0 1 5.625 2.149 1.498 1.498 0 1 0 1.918-2.26 12.373 12.373 0 0 0-10.31-2.618L7.996 6.6a14.36 14.36 0 0 1 13.487 3c.276.241.632.373 1 .369a1.489 1.489 0 0 0 .998-2.619A17.356 17.356 0 0 0 5.66 4.252L1.712.294a1.003 1.003 0 1 0-1.418 1.42l21.978 21.99a.999.999 0 0 0 1.42 0 1 1 0 0 0 0-1.42zm-11.239-4.208a1.438 1.438 0 0 0-.46-.08 1.499 1.499 0 1 0 1.499 1.5 1.44 1.44 0 0 0-.08-.46l-.959-.96z"></path>
          </svg>
        </div>
        <p class="refresh-text" >İnternet bağlantısı sağlanamadı. İnternet bağlantını kontrol et veya lütfen daha sonra tekrar dene. Sorun devam ederse bize <span style="font-weight: bold">destek@bionluk.com</span> üzerinden ulaşabilirsin.</p>

        <!--
        <a class="close-warning" @click="$store.state.openedVersion = false">
          <img src="https://gcdn.bionluk.com/site/icon/ic_close_white.svg" alt="">
        </a>
        -->
      </div>
    </div>
</template>

<script>

	export default {
	  name: "src-pages-version-v1",
		data() {
			return {}
		},

    methods: {
			forceRefresh() {
				location.reload();
      }
    }
	}

</script>

<style scoped lang="scss">
  .bi-icon-off /deep/ {
    width: 26px;
    height: 24px;

    path {
      fill:#34516F
    }
  }

  .version-warning{
    animation: opening 1s;
    color: #fff;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    bottom: 10px;
    justify-content: space-between;

    position: fixed;
    z-index: 10;
    margin: 0 auto;


    width: 953px;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #1e4267;
    background-color: #34516f;

    img{
      margin-right: 20px;
    }
  }

  .refresh-text {
    margin-right: 50px;
    width: 755px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
   text-align: left;
    color: #ffffff;

    span {
      font-weight: bold;

    }
  }

  .refresh-button {
    cursor: pointer;




    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 3px;



    font-size: 16px;
    font-weight: 600;

    line-height: normal;


    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;


    width: 188px;
    height: 49px;
    border-radius: 2px;
    border: solid 1px rgb(255, 255, 255);
    &:hover{
     opacity: 0.8;
    }
  }
  @keyframes opening {
    from {
      bottom: -100px;
    }
    to {
      bottom: 10px;
    }
  }
  .close-warning{
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }

</style>
