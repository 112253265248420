/**
 * Created by haluk on 7/31/17.
 */

import GoogleLogin from "../components/googleLogin.vue"
import FacebookLogin from "../components/facebookLogin.vue"

export default {
  components: {
    FacebookLogin,
    GoogleLogin
  },
  data() {
    return {
      email: this.$store.state.user.email,
      password: "",
      disableForLogin: false,
      disableForFacebook: false
    }
  },
  methods: {
    login(email, password) {
      this.disableForLogin = true;
      this.api.user.login(email, password, this.$Progress, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query)
        .then(({ data }) => {
          this.disableForLogin = false;
          let result = data
          if (result.success) {

            if (result.visitor_id) {
              window.localStorage.setItem("visitor_id", result.visitor_id);
              this.$store.commit(this.types.SET_VISITOR_ID, result.visitor_id);
            }

            if(result.data && result.data.user && result.data.user.slog){
              this.$store.state.slog = true;
            }


            let trackingProps = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
              type: 'email',
              page: this.$store.state.componentMap.page
            };

            let forceMerge = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
            };
            this.Helper.trackEvents.customEvent("loggedin", trackingProps, forceMerge);

            this.$store.commit(this.types.SET_USER, result.data.user);
            this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
            this.$store.state.routerMap = result.data.routerMap;
            this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
            this.$router.push(result.data.redirect_url);
          } else {
            this.disableForLogin = false;
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(() => {
          this.disableForLogin = false
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
        })
    }
  },
  created() {
    this.EventBus.$on("loader", payload => {
      this.disableForLogin = payload;
    })
  },
  beforeDestroy() {
    this.EventBus.$off("loader")
  }
}
