/**
 * Created by haluk on 7/31/17.
 */

import FacebookLogin from "../components/facebookLogin.vue";
import GoogleLogin from "../components/googleLogin.vue";

export default {
  components: {
    GoogleLogin,
    FacebookLogin
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      username: null,
      email: null,
      password: null,
      disableRegister: false,
      errorInfo: {}
    }
  },

  methods: {
    createUsername() {
      if(!this.username) {
        this.username = this.escapeTurkishCharacters((this.firstname ? this.firstname : "") + (this.lastname ? this.lastname : "")).toLowerCase();
      }
    },

    register(firstname, lastname, username, email, password) {
      this.disableRegister = true;
      this.api.user.register(firstname, lastname, username, email, password, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query, this.$Progress,this.$store.state.redirectPreviousURL.calledFrom)
      .then(({data}) => {
        this.disableRegister = false;
        let result = data;
        if(result.success) {
          this.$store.commit(this.types.SET_MESSAGE_COMPONENT, result.data.messageComponent);
          this.$store.state.routerMap = result.data.routerMap;
          this.$store.commit(this.types.SET_MODAL_COMPONENT_MAP, result.data.modalComponents);
          this.$store.commit(this.types.SET_USER, result.data.user);
          this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
          this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
          this.trackConversionForRegisteredUsers();


          let trackingProps = {
            userID: result.data.user.id,
            userUUID: result.data.user.uuid,
            username: result.data.user.username,
            signupType:result.data.signupType
          };

          let forceMerge = {
            userID: result.data.user.id,
            userUUID: result.data.user.uuid,
            username: result.data.user.username,
          };
          //bu serviste artık
          //this.Helper.trackEvents.customEvent("signedup", trackingProps, forceMerge);

          if (result.visitor_id) {
            window.localStorage.setItem("visitor_id", result.visitor_id);
          }

          this.$router.push({ path: result.data.redirect_url, query: result.data.redirect_query });
        } else {
          let errorInfo = {};
          result.data.errorInfo.forEach(function (info) {
            errorInfo[info.errorClass] = {
              message: info.errorMessage
            }
          });
          this.errorInfo = errorInfo;

          this.$toasted.global.errorToast({description: result.message});
        }
      }).catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      });
    }
  },

  computed: {
    computedFirstname: {
      get() {
        if (!this.firstname) {
          this.firstname = this.user.firstname;
        }

        return this.firstname
      },

      set(firstname) {
        this.firstname = firstname;
        this.user.firstname = this.firstname;
        this.errorInfo.firstname = null;
      }
    },

    computedLastname: {
      get() {
        return this.lastname;
      },

      set(lastname) {
        this.lastname = lastname;
        this.user.lastname = this.firstname;
        this.errorInfo.lastname = null;
      }
    },

    computedEmail: {
      get() {
        if (!this.email) {
          this.email = this.user.email;
        }

        return this.email;
      },

      set(email) {
        this.email = email;
        this.user.email = this.email;
        this.errorInfo.email = null;
      }
    }
  },

  watch: {
    username(newVal, oldVal) {
      this.errorInfo.username = null;
    },

    password(newVal, oldVal) {
      this.errorInfo.password = null;
    }
  },

  created() {
    this.EventBus.$on("loader", payload => {
      this.disableRegister = payload;
    });

  },

  beforeDestroy() {
    this.EventBus.$off("loader")

  }
}
