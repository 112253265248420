<template>
  <div v-if="this.items.length || !this.hideBox">
        <div style="position: relative;">
          <transition name="fade" v-on:leave="leave">
            <div v-if="this.items.length && currentItem.request_id && show" style="margin-bottom: 70px">
              <div style="display: flex; justify-content: space-between; ">
                <p class="section-header-today">Sana Özel <span>Alıcı İstekleri</span><span v-if="this.items.length" style="padding-left: 10px; color: #fd4056; font-size: 15px; font-weight: normal">[<span style="font-weight: bold">{{this.items.length}} Yeni</span> Alıcı İsteğin Var]</span></p>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'buyer request box see all text')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'buyer request box see all text')"
                  to="/panel/alici-istekleri" class="tumu">Tümü</router-link>
              </div>
              <div style="height: 20px"></div>
              <div class="buyer-req-container">
                <div class="buyer-req-head">
                  <img class="avatar" :src="currentItem.buyer.avatar_url">
                  <div class="title"><span>{{currentItem.title}}</span> <div style="display: inline-block"><div class="new">Yeni</div></div></div>
                  <div style="display: flex; align-items: center; justify-content: center; margin-right: 15px; margin-left:45px">
                    <p @click="report(currentItem)" class="bildir">Sorun Bildir</p>
                    <button @click="sendMessage(currentItem)"  :disabled="buttonLoading" class="message-button">Mesaj At</button>
                    <button :disabled="buttonLoading" @click="activateBuyerRequestModal(currentItem)" class="teklif-button">Teklif Ver</button>
                    <p @click="slide" class="sonraki">[Sonraki]</p>
                  </div>

                </div>

                <div class="buyer-req-body">
                  <div v-if="currentItem.category_sub_id && (currentItem.category_sub_id === 38 || currentItem.category_sub_id === 61)" style="padding-left: 20px;padding-top: 20px">
                    <span v-if="currentItem.wordCount" class="extra-info-label">{{currentItem.wordCount}} Kelime</span>
                    <span class="extra-info-label" v-for="service_kind in currentItem.service_kinds">{{service_kind.name}}</span>
                    <span class="extra-info-label" v-for="service_type in currentItem.service_types">{{service_type.name}}</span>
                  </div>

                  <div v-html="currentItem.description" class="description">

                  </div>
                  <div v-for="upload in currentItem.uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
                        <img style="height: 27px; width: 21px; " :src="upload.icon_png">
                        <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
                  </div>
                </div>
                <div v-show="currentItem.expandible" @click="expand" class="read-more"><p>Devamını oku</p><p style="padding-top: 4px; padding-left: 4px;"><img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="read-more-icon"></p></div>

                <div class="buyer-req-foot-container">
                  <div class="buyer-req-foot">
                    <div class="date-container"><span class="date">{{ currentItem.approved_at | customDateFormat('dd MMMM') }}</span></div>
                    <div class="foot-menu">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg" onload="SVGInject(this)" class="buyer-req-budget-icon">
                      <p class="sub-title">Bütçe:</p>
                      <p class="value">{{currentItem.budget}}</p>
                    </div>
                    <div class="foot-menu">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg" onload="SVGInject(this)" class="buyer-req-budget-icon">
                      <p class="sub-title">Süre:</p>
                      <p class="value">{{currentItem.duration}}</p>
                    </div>
                    <div class="foot-menu">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg" onload="SVGInject(this)" class="buyer-req-budget-icon">
                      <p class="sub-title">Güncel Teklif:</p>
                      <p class="value">{{currentItem.offers.offer_count}}</p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div v-else-if="show && items.length === 0 && pageLoaded && !hideBox"  style="margin-bottom: 70px">
              <div style="display: flex; justify-content: space-between; ">
                <p class="section-header-today">Sana Özel <span>Alıcı İstekleri</span></p>
                <span @click="gizle" class="tumu">Gizle</span>
              </div>
              <div style="height: 20px"></div>
              <div class="buyer-req-container" style="height: 330px;">
                <div style="display: flex; flex-direction: column; align-items: center;  height: 100%;">
                  <img style="width: 168px; height: 129px; margin-top: 40px;" src="https://gcdn.bionluk.com/site/general/il-buyer-request.svg">
                  <div style="margin-top:3px;width: 760px; line-height: 1.5; color: #5e6b79; font-size: 16px; text-align: center">
                    Yeni alıcı isteği kalmadı. Sana uygun yeni alıcı istekleri olduğunda burada göstereceğiz.
                    <p>Takipte Kal! 😋</p>
                  </div>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'buyer request box see all button')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'buyer request box see all button')"
                    to="/panel/alici-istekleri" style="text-decoration: none!important;" class="button-all-req">Tüm Alıcı İsteklerine Ulaş</router-link>
                </div>
              </div>
            </div>
          </transition>


        </div>
    </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";

  export default {
    props: ["list"],
	  components:{Loader},
    data() {
      return {
	      buttonLoading:false,
      	show:true,
        hideBox:false,
      	pageLoaded:false,
        currentItem: this.list && this.list[0] ? this.list[0] : {
      		buyer:{},
          offers:{
      			offers:{
      				seller:{

              }
            }
          },
        },

        items: this.list || [],

      };
    },

    methods:{

      expand(){
        const request = JSON.parse(JSON.stringify(this.currentItem));
        let elems = document.getElementsByClassName("buyer-req-body");
        elems[0].style.height = request.expandible+'px';
        elems[0].style.overflow = 'visible';
        request.expandible = false
        this.currentItem = request;
      },


      isOverflown(element) {
        if(element){
          return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        } else {
          return false;
        }
      },

    	gizle(){
		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'hide buyer request box'});
		    this.hideBox = true;
		    this.api.feeds.hideBuyerRequestBox();
      },

	    activateBuyerRequestModal(request) {
    		if(this.buttonLoading === false){
    			this.buttonLoading = true;
			    this.$store.state.clickedFrom = 'Buyer Request Box'
			    this.api.seller.retrieveGigs(null, 9999, 0, 1, 1, this.$Progress)
				    .then(({data}) => {
					    let result = data;
					    if (result.success) {
						    this.$store.commit(this.types.ACTIVE_MODAL, {
                  modalType: this.Modals.CUSTOM_OFFER,
                  info: {
                    data: {
                      type: 'buyerRequest',
                      request,
                      gigs: result.data.gigs
                    }
                  }
                })
					    } else {
						    this.$toasted.global.errorToast({description: result.message});
					    }
					    this.buttonLoading = false;
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
					    this.buttonLoading = false;
				    });
        }
	    },
	    sendMessage(request) {
		    this.$store.state.clickedFrom = 'Buyer Request Box';
		    if (!this.$store.state.user.uuid) {
			    this.$router.push("/login");
		    } else {
			    if(this.buttonLoading === false){
				    this.buttonLoading = true;
				    this.api.workstation.chatOpen(request.buyer.username, 0,1)
					    .then(({data}) => {
						    let result = data;
						    if (result.success) {
							    let conversationSendData = {
							      request_id: request.request_id,
                    request_uuid:request.uuid,
								    r_username: request.buyer.username,
								    r_avatar_url: request.buyer.avatar_url,
								    r_id: parseInt(request.buyer.id),
								    r_uuid: request.buyer.uuid,
								    fromWhere: 'Buyer Requests',
								    c_uuid: result.data.c_uuid,
								    whereFrom: 'today',
							    };
							    this.EventBus.$emit('externalOpenChat', conversationSendData);
						    } else {
							    this.$toasted.global.errorToast({description: result.message});
						    }
						    this.buttonLoading = false;
					    })
					    .catch(err => {
						    this.buttonLoading = false;
						    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
					    });
			    }
        }
	    },
	    leave: function(el, done) {

		    let that = this;

		    setTimeout(function() {
			    that.show = true;
			    if(that.currentItem){
            that.$nextTick(function () {

              const item = JSON.parse(JSON.stringify(that.currentItem));

              const elem = document.getElementsByClassName("buyer-req-body");

              if(that.isOverflown(elem[0])){
                if(elem[0]){
                  item['expandible'] = elem[0].scrollHeight;
                }
              } else {
                item['expandible'] = false;
              }
              that.currentItem = item;

            });

          }
		    }, 400);
	    },
	    retrieveItems() {
		    this.pageLoaded = false;
		    this.api.request.list('20', '0', 'new')
			    .then(({data}) => {

				    let result = data;
				    this.pageLoaded = true;
				    if (result.success) {
					    this.items = result.data.requests;
					    this.$store.state.openBuyerRequest = result.data.requests;
					    if(result.data.requests && result.data.requests.length){
						    this.currentItem = this.items[0];
                this.$nextTick(function () {

                  const item = JSON.parse(JSON.stringify(this.currentItem));

                  const elem = document.getElementsByClassName("buyer-req-body");

                  if(this.isOverflown(elem[0])){
                    if(elem[0]){
                      item['expandible'] = elem[0].scrollHeight;
                    }
                  } else {
                    item['expandible'] = false;
                  }
                  this.currentItem = item;

                });


              } else if(result.data.hide_box){
					    	this.hideBox = true;
              }
				    } else {
					    this.pageLoaded = true;
				    }

			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },


      report(request){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.COMPLAINT, info: {type: "request", externalId: request.request_id, reportTypeId: 16}});
      },
	    slide(){

		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'buyer request box next'});
		    if(this.items[0] && this.items[0].request_id){
          console.log('slidegirdi');

			    this.api.feeds.markBuyerRequestSeen(this.items[0].request_id);
        }


	    	let mock = {
			    buyer:{},
			    offers:{
				    offers:{
					    seller:{

					    }
				    }
			    },
		    };
			  this.show=false;
		    this.items.splice(0, 1);
		    this.currentItem = this.items[0] || mock;

	    },

    },
    beforeDestroy(){
	    this.EventBus.$off('removeRequestFromMainPage');
    },
    watch:{
      currentItem: function (newVal, oldVal) {
        if((!oldVal && newVal) || (newVal && newVal.request_id !== oldVal.request_id)){

        }
      }
    },
    created() {
	    this.EventBus.$on('removeRequestFromMainPage', received => {
		    this.slide();
	    });
    	this.retrieveItems();
    }
  }
</script>

<style scoped lang="scss">


  .extra-info-label
  {

    height: 21px;
    padding: 2px 10px 3px;
    border-radius: 10px;
    background-color: #26ca9a;
    font-size: 12px;
    letter-spacing: -0.22px;
    font-weight: normal;

    text-align: center;
    margin-left: 10px;
    color: #ffffff;
  }

  .read-more{
    margin-left: 40px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #f75466;
    display: flex;
    align-items: center;
    height: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }

    .read-more-icon /deep/ {

      width: 14px;
      height: 14px;
      path {
        fill: #fd4056;
      }
    }

  }


  .buyer-req-body{
    height: 210px;overflow-y: hidden;
  }

  .buyer-req-container{
    width: 893px;
    border-radius: 10px;
    box-shadow: 0 20px 20px 0 #e9ebf0;
    border: solid 1px #eff3f8;
    background-color: #ffffff;

    a{
      &:hover{
        text-decoration: none!important;
      }
    }
    .button-all-req{
      width: 200px;
      height: 39px;
      border-radius: 2px;
      background-color: #fd4056;
      padding-bottom: 1px;
      font-size: 14px;
      font-weight: 600;


      letter-spacing: -0.32px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      &:hover{
        background-color: #fd4056!important;
        opacity: 0.9;
        text-decoration: underline;
        color: #ffffff;
      }
    }

    .buyer-req-head{
      width: 891px;
      height: 86px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 -1px 0 0 rgba(254, 179, 116, 0.5);
      background-color: #2d3640;

      display: flex;
      align-items: center;
    }

    .avatar{
      margin-left: 40px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 2px solid #bfc8d2;
    }

    .title{
      margin-left: 18px;
      max-height: 50px;
      overflow: hidden;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: #ffffff;
      width: 410px;

      display: inline-block;

    }

    .new{
      display: flex;
      width: 40px;
      height: 16px;
      border-radius: 10px;
      background-color: #26ca9a;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.22px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 2px;

      align-items: center;
      justify-content: center;
    }

    .message-button{
      width: 100px;
      height: 39px;
      border-radius: 2px;
      border: solid 1px rgba(255, 255, 255, 0.2);
      padding-bottom: 1px;
      background-color: #2d3640;

      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;

      &:hover{
        opacity: 0.8;
      }
    }
    .teklif-button{
      margin-left: 20px;
      width: 100px;
      height: 40px;
      border-radius: 2px;
      background-color: #fd4056;
      border:none;

      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.32px;
      text-align: center;
      color: #ffffff;

      &:hover{
        background-color: #FF2D55!important;
        color: #ffffff;
      }
    }

    .bildir{
      cursor: pointer;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      &:hover{
        text-decoration: underline;
      }
    }

    .sonraki{
      cursor: pointer;
      margin-left: 15px;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      &:hover{
        text-decoration: underline;
      }
    }

    .description{


      padding: 20px 40px;

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4b4f52;
      word-break: break-word;
      white-space: pre-wrap;
    }


    .buyer-req-foot-container{
      width: 893px;
      height: 70px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 1px 0 0 #eaedf2, 0 -1px 0 0 #eaedf2;
      background-color: #f9fafb;
    }
    .buyer-req-foot{

      margin-left: 40px;
      margin-right: 40px;
      height: 70px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      svg{
        height: 20px;
        width: 20px;
      }

      .buyer-req-budget-icon /deep/ {
        height: 20px;
        width: 20px;
        path {
          fill: #8b95a1;
        }
      }


      .foot-menu{
        display: flex;

        .sub-title{
          margin-left: 10px;
          font-size: 16px;
          color: #2d3640;
        }
        .value{
          margin-left: 5px;
          font-size: 16px;
          font-weight: 600;
          color: #2d3640;
        }
      }


      .date-container{
        min-width: 50px;
        height: 26px;
        border-radius: 5px;
        background-color: #f4f5f7;

        align-items: center;
        justify-content: center;
        display: flex;
        .date{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #8b95a1;
        }
      }
    }
  }




  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }


  .section-header-today{

    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    span{
      font-weight: bold;
    }
  }

  .tumu{
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a575;
    padding-right: 10px;
    padding-top: 12px;
    &:hover{
      text-decoration: underline!important;
      color: #00a575;
    }
  }


</style>
