<template>
  <transition name="modal" >
    <div class="modal-mask" :style="$store.state.isMobile ? 'z-index: 110000!important;' : ''">
      <div v-if="getModalInfo" v-loader-screen="waitUntilPaymentRequestDone" :class="$store.state.isMobile ? 'modal-container_mobile' : 'modal-container'" :style="cards.length && !isSinglePay ? 'padding-top: 20px; ' : ''" @click.stop>
        <div v-if="!paymentSucceeded" class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div v-if="showIframe">
          <iframe id="checkoutIframeV2" :width="$store.state.isMobile ? '100%' : '700px'" style="border: none; height:520px;"  src="" @load="checkIframe"></iframe>
        </div>

        <div v-if="!showIframe">

          <div v-if="!paymentSucceeded && !showAdBlockModal" class="checkout-body">
            <p class="checkout-header">Ödemeyi <span>Tamamla</span></p>

            <template v-if="walletAmount && walletAmount > 0 && walletAmount >= total">

            </template>
            <template v-else>
              <div v-if="$store.state.isMobile" class="checkout-tabs">
                <div v-if="cards.length" :class="activeTab === 1 ? 'tab_mobile active-tab' : 'tab_mobile'" @click="selectTab(1)" style="border-right: none;">
                  Kredi Kartı
                </div>
                <div v-if="cards.length" :class="activeTab === 2 ? 'tab_mobile active-tab' : 'tab_mobile'" @click="selectTab(2)" style="border-right: none;">
                  Yeni Kart
                </div>
                <!--
                 <div :class="activeTab === 3 ? 'tab_mobile active-tab' : 'tab_mobile'" @click="selectTab(3)" style="">
                  <img style="height: 15px; padding-right: 7px;" src="https://gcdn.bionluk.com/site/emails/iyzico.png">
                </div>
                -->
              </div>

              <div v-else class="checkout-tabs">
                <div v-if="cards.length" :class="activeTab === 1 ? 'tab active-tab' : 'tab'" @click="selectTab(1)" style="border-right: none;">
                  Kredi Kartı ile <span>Öde</span>
                </div>
                <div v-if="cards.length" :class="activeTab === 2 ? 'tab active-tab' : 'tab'" @click="selectTab(2)" style="border-right: none;">
                  Yeni Kart ile <span>Öde</span>
                </div>
                <!--
                 <div :class="activeTab === 3 ? 'tab active-tab' : 'tab'" @click="selectTab(3)" style="">
                  <img style="height: 18px; padding-right: 7px; padding-top: 5px;" src="https://gcdn.bionluk.com/site/emails/iyzico.png"> ile <span>Öde</span>
                </div>
                -->
              </div>
            </template>


            <div class="tabs-separator"></div>


            <template v-if="walletAmount && walletAmount > 0 && walletAmount >= total">
              <div class="iyzico-area">
                <p class="iyzico-header" style="margin-top: 0; font-size: 20px; font-weight: 500">Bionluk Bakiyen: <span style="font-size: 24px; ">{{walletAmount}}<span style="font-size: 18px; padding-left: 4px;">TL</span></span></p>
                <p class="iyzico-text" style="text-align: center">
                 Bu siparişin ödemesini <strong>Bionluk bakiyeni</strong> kullanarak yapabilirsin. Sipariş tutarı bakiyenden çekilecek.
                </p>
                <custom-button buttonType="red"  :style="$store.state.isMobile ? 'width: 260px; margin-top: 20px;' : 'width: 320px; margin-top: 50px;'" @click="makePayment()" >
                  Bakiyemden Ödeme Yap ({{total}} TL)
                </custom-button>
              </div>

            </template>
            <template v-else>


              <swiper v-if="$store.state.isMobile && activeTab === 1" :options="swiperOption" ref="mySwiper" class="checkout-cards-container_mobile">
                <swiper-slide v-for="(card, index) in cards" :key="index" class="checkout-cards-inner">
                  <div :class="selectedCard === card.card_id ? 'cards selected-card' : 'cards'" @click="selectCard(card)">
                    <div v-if="selectedCard === card.card_id" class="selected-card-check-body">
                      <img src="https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg" style="width: 20px; height: 15px; filter: brightness(0) invert(1);">
                    </div>
                    <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                      <img :src="card.card_image_url" style="max-width:100px;max-height: 40px"/>
                    </div>
                    <p class="card-name">{{card.card_bank_name}}</p>
                    <p class="card-number">{{card.card_no}}</p>
                    <div class="delete-card" @click="assignForgetCardAlertId($event, card.card_id)">
                      <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                      <p class="delete-card-text">Kartı Unut</p>
                    </div>
                    <div class="forget-card-alert" v-if="forgetCardAlertID === card.card_id">
                      <p class="forget-card-alert-text">Emin misin?</p>
                      <div style="display: flex;">
                        <div class="forget-card-alert-button" @click="deleteCard($event, card.card_id)">Evet</div>
                        <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                      </div>
                    </div>
                  </div>

                </swiper-slide>
              </swiper>
              <swiper v-else-if="activeTab === 1" :options="swiperOption" ref="mySwiper"  class="checkout-cards-container">
                <swiper-slide v-for="index in cardRange" :key="index" class="checkout-cards-inner">
                  <div v-if="cards[index]" :class="selectedCard === cards[index].card_id ? 'cards selected-card' : 'cards'" @click="selectCard(cards[index])">
                    <div v-if="selectedCard === cards[index].card_id" class="selected-card-check-body">
                      <img src="https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg" style="width: 20px; height: 15px; filter: brightness(0) invert(1);">
                    </div>
                    <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                      <img :src="cards[index].card_image_url" style="max-width:100px;max-height: 40px"/>
                    </div>
                    <p class="card-name">{{cards[index].card_bank_name}}</p>
                    <p class="card-number">{{cards[index].card_no}}</p>
                    <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index].card_id)">
                      <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                      <p class="delete-card-text">Kartı Unut</p>
                    </div>
                    <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index].card_id">
                      <p class="forget-card-alert-text">Emin misin?</p>
                      <div style="display: flex;">
                        <div class="forget-card-alert-button" @click="deleteCard($event, cards[index].card_id)">Evet</div>
                        <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                      </div>
                    </div>
                  </div>

                  <div v-if="cards[index + 1]" :class="selectedCard === cards[index + 1].card_id ? 'cards selected-card' : 'cards'" @click="selectCard(cards[index + 1])">
                    <div v-if="selectedCard === cards[index + 1].card_id" class="selected-card-check-body">
                      <img src="https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg" style="width: 20px; height: 15px; filter: brightness(0) invert(1);">
                    </div>
                    <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                      <img :src="cards[index + 1].card_image_url" style="max-width:100px;max-height: 40px"/>
                    </div>
                    <p class="card-name">{{cards[index + 1].card_bank_name}}</p>
                    <p class="card-number">{{cards[index + 1].card_no}}</p>
                    <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index + 1].card_id)">
                      <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                      <p class="delete-card-text">Kartı Unut</p>
                    </div>
                    <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index + 1].card_id">
                      <p class="forget-card-alert-text">Emin misin?</p>
                      <div style="display: flex;">
                        <div class="forget-card-alert-button" @click="deleteCard($event, cards[index + 1].card_id)">Evet</div>
                        <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                      </div>
                    </div>
                  </div>

                  <div v-if="cards[index + 2]" :class="selectedCard === cards[index + 2].card_id ? 'cards selected-card' : 'cards'" @click="selectCard(cards[index + 2])">
                    <div v-if="selectedCard === cards[index + 2].card_id" class="selected-card-check-body">
                      <img src="https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg" style="width: 20px; height: 15px; filter: brightness(0) invert(1);">
                    </div>
                    <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                      <img :src="cards[index + 2].card_image_url" style="max-width:100px;max-height: 40px"/>
                    </div>
                    <p class="card-name">{{cards[index + 2].card_bank_name}}</p>
                    <p class="card-number">{{cards[index + 2].card_no}}</p>
                    <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index + 2].card_id)">
                      <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                      <p class="delete-card-text">Kartı Unut</p>
                    </div>
                    <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index + 2].card_id">
                      <p class="forget-card-alert-text">Emin misin?</p>
                      <div style="display: flex;">
                        <div class="forget-card-alert-button" @click="deleteCard($event, cards[index + 2].card_id)">Evet</div>
                        <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                      </div>
                    </div>
                  </div>

                </swiper-slide>
                <div v-show="cards.length > 3" class="swiper-button-prev" slot="button-prev"></div>
                <div v-show="cards.length > 3" class="swiper-button-next" slot="button-next"></div>
              </swiper>

              <div class="checkout-new-card" v-if="activeTab === 2" :style="$store.state.isMobile ? 'padding-left:0; padding-right:0; align-items:center; justify-content:center' : ''">
                <div class="card-section">
                  <custom-input label="Kartın Üzerindeki İsim" v-model="cardOwner" inputType="text" :valid="validationObj.cardOwner" @blur="inputOnBlur('cardOwner')" autocomplete="cc-name" :style="!$store.state.isMobile ?' width: 343px' :  'width: 100%'"/>
                  <custom-input label="Kart Numarası" v-model="cardNumber" :card-image="cardImage" :valid="validationObj.cardNumber" @blur="inputOnBlur('cardNumber')" inputType="card" :style="!$store.state.isMobile ?' margin-top: 30px;width: 343px' :  'margin-top: 30px;width: 100%'"/>

                  <div class="expire-body" :style="!$store.state.isMobile ? ' ' :  'width: 100%'">
                    <div style="display: flex">
                      <custom-select id="expireMonth" label="Ay" :options="monthOptions" v-model="month" :valid="validationObj.month" autocomplete="cc-exp-month" name="expireMonth" labelPosition="center"  :style="!$store.state.isMobile ? ' width:80px' :  'width:70px'"/>
                      <custom-select class="year" id="expireYear" label="Yıl" v-model="year" :valid="validationObj.year" autocomplete="cc-exp-year" name="expireYear" labelPosition="center" :style="!$store.state.isMobile ? ' width:80px' :  'width:70px'"/>
                    </div>
                    <custom-input label="CVC 2" v-model="cvc" :valid="validationObj.cvc" autocomplete="cc-csc" inputType="cvc" :style="!$store.state.isMobile ? ' width:99px' :  'width:85px'" @focus="!$store.state.isMobile && flippingCard($event, true)" @blur="!$store.state.isMobile && flippingCard($event, false)"/>
                  </div>

                  <div class="remember-body">
                    <template >
                      <custom-switch v-model="rememberCard"/>
                      <p class="remember-text">Sonraki siparişlerim için hatırla</p>
                      <div v-if="!$store.state.isMobile"
                           class="remember-tooltip"
                           @click="visibleToolTip = false"
                           :data-balloon-visible="visibleToolTip"
                           @mouseover="visibleToolTip = false"
                           data-balloon-length="medium"
                           data-balloon="Bionluk’a kartlarını bir defa tanımlarsın, her siparişte kart bilgisi girmekten kurtulursun. Kart bilgilerin sistemimizde kaydedilmez. Ödeme servis sağlayıcımız tarafından şifrelenerek saklanır."
                           data-balloon-pos="up">
                        <img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="18"/>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="!$store.state.isMobile" class="card-flip-body">
                  <div class="card-image-section">
                    <div class="card-numbers">
                      <span class="card-number-item" v-for="item in cardNumber.split('-')" v-if="cardNumber.length">{{item}}</span>
                      <span v-if="cardNumber" class="card-number-item" v-for="i in 1 - Math.ceil([...cardNumber.replace(/-/g, '')].length / 1)">****</span>
                    </div>

                    <div class="card-image-type-body" v-if="cardImageType.length">
                      <img :src="cardImageType" class="card-image-type">
                    </div>

                    <div class="card-numbers cvc2">
                      <span class="card-number-item" v-if="!cvc">CVC2</span>
                      <span class="card-number-item" v-if="!!cvc">{{cvc}}</span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="iyzico-area" v-if="activeTab === 3">

                <p class="iyzico-header">iyzico ile ödeme nasıl çalışır?</p>
                <p class="iyzico-text">
                  Sevgili <strong>{{user.firstname}}</strong>, alttaki butona bastiktan sonra ödeme işlemini iyzico ile yapabilmek için <strong>iyzico Güvenli Ödeme</strong> sayfasına yönleneceksin.
                </p>
                <custom-button buttonType="red"  :style="$store.state.isMobile ? 'width: 260px; margin-top: 20px;' : 'width: 260px; margin-top: 50px;'" @click="makePayment()" >
                  iyzico ile Ödeme Yap ({{total}} TL)
                </custom-button>
              </div>

              <div v-if="walletAmount && walletAmount > 0 && walletAmount < total" style="text-align: center; margin-bottom: 25px; margin-top: 10px; font-size: 16px; font-weight: 400; color: #fa4359 ">
                Sipariş tutarının <span style="font-weight: 600;">{{walletAmount}} TL</span>'si Bionluk bakiyenden, <span style="font-weight: 600;">{{parseFloat(total - walletAmount).toFixed(2)}} TL</span>'si ise kartından alınacak.
              </div>

              <div class="tabs-separator" v-if="activeTab !== 3"></div>

              <custom-button v-if="activeTab !== 3" :buttonType="activeTab === 2 ? 'green' : 'red'" :disabled="disableToPay || waitUntilPaymentRequestDone" @click="makePayment()" style="align-self: center; margin-top: 25px;">
                Ödeme Yap ({{total}}TL)
              </custom-button>
            </template>


          </div>
          <div v-if="!paymentSucceeded && showAdBlockModal" class="blocked-body" :style="$store.state.isMobile ? 'padding: 94px 35px 50px 35px;': ''">

            <img class="blocked-img" src="https://gcdn.bionluk.com/site/general/adblock-logo.png" alt="blocked image">
            <h3 class="blocked-header">Upsss! 😱</h3>
            <p class="blocked-text">
              Sevgili
              <span style="text-transform: capitalize;">{{user.firstname}}</span>,
              Tarayıcında yüklü olan
              <span>AdBlock</span>
              eklentisi, seni bankanın
              <span>3DS Güvenli Ödeme</span>
              adımına yönlendirmemizi engelliyor. Güvenli ödeme işlemine
              <span>AdBlock eklentisini pasif hale getirerek</span>
              devam edebilirsin.
            </p>
            <custom-button buttonType="red" @click="showAdBlockModal = false" style="width: 260px; margin-top: 50px;">
              Ödeme İşlemine Devam Et
            </custom-button>
          </div>

          <div v-if="paymentSucceeded && !isSinglePay" :class="$store.state.isMobile ? 'payment-succeeded-container_mobile' : 'payment-succeeded-container'">
            <img src="https://gcdn.bionluk.com/site/icon/c-success-round.png" alt="success-round" width="120" height="120">
            <p class="payment-succeeded-header">Ödeme <span>Başarılı</span></p>
            <p class="payment-succeeded-text">Teşekkürler <span style="text-transform: capitalize;">{{user.firstname}}</span>, ödeme işlemin başarıyla gerçekleşti. Siparişini hemen başlatmak için <span>sipariş detayı</span> sayfasına gidebilirsin.</p>

            <custom-button class="payment-succeeded-button" buttonType="dark" @click="$router.push('/orders/' + orderID)">
              Sipariş Detayına Git
            </custom-button>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
  import CustomSwitch from '../../components/CustomSwitch'
  import CustomInput from '../../components/CustomInput'
  import CustomButton from '../../components/CustomButton'
  import CustomSelect from '../../components/CustomSelect'
  import range from 'lodash/range'

  import { getCurrentMonth, getCurrentYear } from '@/utils/dateFns'

  export default {
    name: "src-pages-modals-checkout-v4",
    components: {
      CustomSwitch,
      CustomInput,
      CustomButton,
      CustomSelect
    },
    data() {
      return {
        cardOwner: '',
        cardNumber: '',
        cvc: '',
        year: null,
        month: null,
        total: 30,
        checkOutData: null,
        paymentSucceeded: false,
        waitUntilPaymentRequestDone: false,
        orderID: null,
        showIframe: false,
        isSinglePay: false,
        processing: false,
        modalTitle: "Siparişini Tamamla!",
        type: 'fast_application',
        itemId: null,

        cardFlipped: false,
        visibleToolTip: false,
        activeTab: 1,
        cards: [],
        selectedCard: null,
        rememberCard: false,
        disableCardDelete: false,
        monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        cardImage: '',
        cardImageType: '',
        validationObj: {
          cardOwner: null,
          cardNumber: null,
          cvc: null,
          year: null,
          month: null,
        },

        openedModal: false,
        forgetCardAlertID: null,

        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          slidesPerView: 1,
          observeParents: false,
          speed: 500
        },

        showAdBlockModal: false,

        walletAmount:0
      }
    },
    methods: {
      goLink(link) {
        this.$router.push(link)
        this.hide();
      },
      openFreelancerLineFaqModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FREELANCER_LING_FAQ});
      },
      activateIframe(iframeURL, htmlContent) {
        this.showIframe = true;

        if(htmlContent){
	        this.$nextTick(() => {
		        let iframe = document.getElementById('checkoutIframeV2');
		        iframe = iframe.contentWindow || (iframe.contentDocument.document || iframe.contentDocument);

		        iframe.document.open();
		        iframe.document.write(htmlContent);
		        iframe.document.close();

		        //iframe.setAttribute('src', iframeURL)

		        //iframe.src = iframeURL;
	        });
        } else {
	        this.$nextTick(() => {

		        let iframe = document.getElementById('checkoutIframeV2');
		        iframe.src = iframeURL;
		        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';


		        //iframe.setAttribute('src', iframeURL)

		        //iframe.src = iframeURL;
	        });
        }

      },
      checkIframe(event) {

        this.waitUntilPaymentRequestDone = false;
        let iframe = document.getElementById('checkoutIframeV2');
        try {


          let currentURL = iframe.contentWindow.location.href;
          currentURL = decodeURIComponent(currentURL);


	        if(iframe.contentWindow.location.href === 'https://bionluk.com/' || iframe.contentWindow.location.href === 'https://bionluk.com' || iframe.contentWindow.location.href === 'https://bionluk.com/bugun'){
		       // this.paymentSucceeded = false;
		       // this.showIframe = false;
		        //this.$toasted.global.errorToast({description: info.msg});
          } else if (currentURL.indexOf('biyzibApplication') > -1) {

            let info = currentURL.split('biyzibApplication?info=')[1];

            info = JSON.parse(info);

            if (info.success) {
             // this.paymentSucceeded = true;
              this.showIframe = false;
              this.orderID = info.payment_id;

              //this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkOutData.data));

              this.trackConversionForPayedOrders(this.checkOutData.data.total, "TRY", "freelancer");
              this.EventBus.$emit('isSinglePayed', {isSinglePayed: true, paymentId: this.orderID});
              this.hide();
            } else {
              this.paymentSucceeded = false;
              this.showIframe = false;
              this.$toasted.global.errorToast({description: info.msg});
            }

          } else if (currentURL.indexOf('biyzib') > -1) {
            let info = currentURL.split('biyzib?info=')[1];
            info = JSON.parse(info);
            if (info.success) {
              this.paymentSucceeded = true;
              this.showIframe = false;
              this.orderID = info.order_id;

              //this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkOutData.data));

              this.trackConversionForPayedOrders(this.checkOutData.data.total, "TRY");
            } else {
              this.paymentSucceeded = false;
              this.showIframe = false;
              this.$toasted.global.errorToast({description: info.msg});
            }
          }

        } catch (err) {
          console.log(err);
          // farklı source geldigi zaman iframe hata verecek, hatayı gereksiz yere loglamamak icin try/catch 'e aldık
        }
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      makePayment() {
        if (this.isSinglePay) {
          this.singlePayment();
        } else {
          this.orderPayment();
        }

      },
      singlePayment() {
        this.waitUntilPaymentRequestDone = true;
        let payment = null;
        if (this.activeTab === 1) {
          payment = {
            total: this.total,
            payment_type: "saved_card",
            type: this.type,
            itemId: this.itemId,
            card_id: this.selectedCard
          };
        } else if (this.activeTab === 2) {
          payment = {
            total: this.total,
            payment_type: "cc",
            cc_name: this.cardOwner,
            cc_no: this.cardNumber.replace(/-/g, ""),
            cc_month: this.month,
            cc_year: this.year,
	          cc_cvc2: this.cvc,
            type: this.type,
            itemId: this.itemId,
            remember_card: this.rememberCard
          };
        } else if (this.activeTab === 3) {
	        payment = {
		        total: this.total,
		        payment_type: "with_iyzico",
		        type: this.type,
		        itemId: this.itemId,
	        };
        }


        this.api.orders.makeItemPayment(payment, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data.iframeURL) {
                this.waitUntilPaymentRequestDone = false;
                this.activateIframe(result.data.iframeURL, result.data.htmlContent);

              } else if(result.data.payWithIyzicoPageUrl){
                this.waitUntilPaymentRequestDone = false;
	              this.activateIframe(result.data.payWithIyzicoPageUrl, null);

              } else {

                //this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkOutData.data));


                this.trackConversionForPayedOrders(this.checkOutData.data.total, "TRY", "freelancer");
                this.EventBus.$emit('isSinglePayed', {isSinglePayed: true, paymentId: result.data.payment_id});
               // this.waitUntilPaymentRequestDone = false;
               this.hide();

              }
            } else {
              let validationObj = {
                cardOwner: null,
                cardNumber: null,
                cvc: null,
                year: null,
                month: null,
              };

              this.validationObj = validationObj;
              this.$toasted.global.errorToast({description: result.message});
              this.waitUntilPaymentRequestDone = false;
            }
          })
          .catch(err => {
            this.waitUntilPaymentRequestDone = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      orderPayment() {

        this.waitUntilPaymentRequestDone = true;
        let payment = null;
        if (this.activeTab === 1) {
          payment = {
            order_id: this.orderID,
            total: this.total,
            payment_type: "saved_card",
            card_id: this.selectedCard
          };
        } else if (this.activeTab === 2) {
          payment = {
            order_id: this.orderID,
            total: this.total,
            payment_type: "cc",
            cc_name: this.cardOwner,
            cc_no: this.cardNumber.replace(/-/g, ""),
            cc_month: this.month,
            cc_year: this.year,
            cc_cvc2: this.cvc,
            remember_card: this.rememberCard
          };
        } else if (this.activeTab === 3) {
	        payment = {
		        order_id: this.orderID,
		        total: this.total,
		        payment_type: "with_iyzico",
	        };
        }
        this.api.orders.makePayment(payment, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.waitUntilPaymentRequestDone = false;
            if (result.success) {
              if (result.data.iframeURL) {
                this.activateIframe(result.data.iframeURL, result.data.htmlContent);

              } else if(result.data.payWithIyzicoPageUrl){
	              this.activateIframe(result.data.payWithIyzicoPageUrl, null);

              } else {

                //this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkOutData.data));

                this.trackConversionForPayedOrders(this.checkOutData.data.total, "TRY");
                this.orderID = result.data.order_id;
                this.paymentSucceeded = true;

              }
            } else {
              let validationObj = {
                cardOwner: null,
                cardNumber: null,
                cvc: null,
                year: null,
                month: null,
              };

              this.validationObj = validationObj;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.waitUntilPaymentRequestDone = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      selectTab(tab) {
        this.activeTab = tab;
        this.selectedCard = null;
      },
      selectCard(card) {
        this.selectedCard = card.card_id;
        this.forgetCardAlertID = null;
      },
      deleteCard(event, cardId) {
        event.stopPropagation();
        if (this.disableCardDelete) {
          return false;
        }
        this.disableCardDelete = true;
        this.api.orders.forgetCard(cardId, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.cards = this.cards.filter(({ card_id }) => card_id !== cardId)

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.disableCardDelete = false;
          })
          .catch(err => {
            this.disableCardDelete = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      assignForgetCardAlertId(event, cardId) {
        event.stopPropagation();
        this.forgetCardAlertID = cardId;
        this.selectedCard = null;
      },
      flippingCard(e, bool) {
        this.cardFlipped = bool;
        this.inputOnBlur('cvc');
      },
      inputOnBlur(type) {
        let validationObj = {
          ...this.validationObj
        };
        switch (type) {
          case 'cardOwner':
            const strArr = this.cardOwner.trim().split(' ');
            validationObj = {
              ...this.validationObj,
              cardOwner: strArr.length > 1 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
          case 'cardNumber':
            this.cardNumber = this.cardNumber.replace(/\s/g,'-');
            validationObj = {
              ...this.validationObj,
              cardNumber: this.cardNumber.replace(/-/g, "").length === 16 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
          case 'cvc':
            validationObj = {
              ...this.validationObj,
              cvc: this.cvc.length === 3 || this.cvc.length === 4 ? true : null
            };
            this.validationObj = {...validationObj};
            break;
        }
      }
    },

    watch: {
      year(newValue) {
        const validationObj = {
          ...this.validationObj,
          year: !!newValue
        };
        this.validationObj = {...validationObj};
        if (newValue && getCurrentYear() === newValue) {
          if (getCurrentMonth() >= this.month) {
            this.month = getCurrentMonth() + 1;
          }
          const month = [];
          for (let i = getCurrentMonth() + 1; i < 13; i++) {
            month.push(i);
          }
          this.monthOptions = month;
        } else if (newValue) {
          this.monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }
      },

      month(newVal) {
        const validationObj = {
          ...this.validationObj,
          month: !!newVal
        };
        this.validationObj = {...validationObj};
      },

      cardFlipped(newVal) {
        const ele = document.getElementsByClassName('card-image-section')[0];
        if (newVal) {
          ele.classList.add('card-flipped')
        } else {
          ele.className = ele.className.replace(/\bcard-flipped\b/g, "");
        }
      },

      rememberCard(newVal) {
        this.visibleToolTip = !!newVal;
      },

      cardNumber(newVal, oldVal) {
        const pureNewVal = newVal.replace(/-/g, "");
        const pureOldVal = oldVal.replace(/-/g, "");

        if (pureNewVal.length > 5 && pureNewVal.slice(0, 6) !== pureOldVal.slice(0, 6)) {
          this.api.orders.cardBin(pureNewVal)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.cardImage = result.data.card_image_url;
                this.cardImageType = result.data.card_type_image_url;
              }
            });
        } else if (pureNewVal.length < 6) {
          this.cardImage = '';
          this.cardImageType = '';
        }
      }
    },

    computed: {
      cardRange() {
        return range(0, this.cards.length, 3)
      },
      disableToPay() {
        if (this.activeTab === 1) {
          return !this.selectedCard;
        } else if (this.activeTab === 2) {
          return this.cardOwner.trim().split(' ').length <= 1 ||
            !this.cardNumber.replace(/-/g, "").length ||
            !this.month ||
            !this.year ||
            this.cvc.length < 3
        }
      },

      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
          this.checkOutData = info.checkOutData;
          this.orderID = this.checkOutData.data.order_id;
          this.total = this.checkOutData.data.total;
          this.type = this.checkOutData.data.type;
          this.walletAmount = this.checkOutData.data.walletAmount;
          this.isSinglePay = info.isSinglePay;

          this.cards = this.checkOutData.data.cards || [];
          this.activeTab = this.cards.length ? 1 : 2;
          if(this.walletAmount && this.walletAmount > 0 && this.walletAmount >= this.total){
            this.activeTab = 2;
          }
          if (info.checkOutData.data.itemId) {
            this.itemId = info.checkOutData.data.itemId;
          }
          if (info.isSinglePay) {
            this.modalTitle = '';
            this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(info.checkOutData.data), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
          }
        }
        return true;
      }
    },

    created() {
      this.showAdBlockModal = window.isAdBlocked;
    }
  }

</script>

<style scoped lang="scss">
  @font-face {
    font-family: 'OCRAExtendedRegular';
    src: url('https://gcdn.bionluk.com/site/general/OCRAEXT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 660px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 0px 25px 0px;
  }

  .modal-container_mobile {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 0px 25px 0px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .checkout-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: 30px;
    span {
      font-weight: 600;
    }

    color: #2d3640;
  }

  .expire-body {
    margin-top: 30px;
    width: 343px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .year {
    margin-left: 20px;
  }

  .card-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .checkout-body {
    display: flex;
    flex-direction: column;
  }

  .checkout-new-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 38px;
    padding-right: 38px;
  }

  .checkout-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab {
    box-sizing: border-box;
    z-index: 1;
    margin-bottom: -1px;
    cursor: pointer;
    width: 100%;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #8b95a1;
    font-weight: 400;
    background-color: #f3f5f7;
    border-left: 1px solid #bfc8d2;

    &:first-child {
      border-left: none;
    }

    span {
      display: contents;
      font-weight: 600;
    }
  }

  .tab_mobile {
    box-sizing: border-box;
    z-index: 1;
    margin-bottom: -1px;
    cursor: pointer;
    width: 100%;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #8b95a1;

    background-color: #f3f5f7;
    border-left: 1px solid #bfc8d2;
    font-weight: 500;
    &:first-child {
      border-left: none;
    }

    span {
      display: contents;
      font-weight: 600;
    }
  }

  .active-tab {
    z-index: 3;
    background-color: #fff;
    color: #2d3640;
    border: 1px solid #bfc8d2;
    border-bottom: none;
  }

  .tabs-separator {
    background-color: #bfc8d2;
    width: 100%;
    align-self: center;
    height: 1px;
    z-index: 2
  }


  .checkout-cards-container_mobile {
    padding-top: 56px;
    padding-bottom: 51px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    .checkout-cards-inner {
      box-sizing: border-box;
      width:200px!important;
      display: flex;
      padding-left: 25px;
      padding-right: 25px;
    }

    .cards {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      padding-top: 25px;
      padding-bottom: 15px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 180px;
      min-width: 180px;
      height: 190px;
      border-radius: 8px;
      border: 1px solid #bfc8d2;
      margin-left: 10px;
      margin-right: 10px;
      color: #8b95a1;

      &:hover {
        border: 1px solid #67cc64;
      }
    }

    .selected-card {
      color: #2d3640;
      border: 1px solid #67cc64;
    }

    .selected-card-check-body {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #67cc64;
      overflow: hidden;
      position: absolute;
      top: -1px;
      right: -1px;
      border-top-right-radius: 10px;
    }

    .card-name {
      font-weight: 400;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.5px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .card-number {
      font-weight: 800;
      margin-top: 5px;
      text-align: center;
    }

    .delete-card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: -5px;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;

      &:hover {
        .delete-card-img {
          opacity: 1;
        }

        .delete-card-text {
          color: #2d3640;
        }
      }
    }

    .delete-card-text {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.38px;
      margin-left: 5px;
      color: #8b95a1;
    }

    .delete-card-img {
      opacity: 0.5;
    }

    .forget-card-alert {
      cursor: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 5;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .forget-card-alert-button {
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 2px;
      width: 68px;
      height: 24px;
      text-align: center;
      letter-spacing: -0.38px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #fff;

      &:hover {
        border: 1px solid #67cc64;
      }
    }

    .forget-card-alert-text {
      margin-bottom: 10px;
      font-size: 14px;
      color: #fff;
      letter-spacing: -0.44px;
    }


  }






  .checkout-cards-container {
    padding-top: 56px;
    padding-bottom: 51px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    .checkout-cards-inner {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      padding-left: 55px;
      padding-right: 55px;
    }

    .cards {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      padding-top: 25px;
      padding-bottom: 15px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 180px;
      min-width: 180px;
      height: 190px;
      border-radius: 8px;
      border: 1px solid #bfc8d2;
      margin-left: 20px;
      margin-right: 20px;
      color: #8b95a1;

      &:hover {
        border: 1px solid #67cc64;
      }
    }

    .selected-card {
      color: #2d3640;
      border: 1px solid #67cc64;
    }

    .selected-card-check-body {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #67cc64;
      overflow: hidden;
      position: absolute;
      top: -1px;
      right: -1px;
      border-top-right-radius: 10px;
    }

    .card-name {
      font-weight: 400;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.5px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .card-number {
      font-weight: 800;
      margin-top: 5px;
      text-align: center;
    }

    .delete-card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: -5px;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;

      &:hover {
        .delete-card-img {
          opacity: 1;
        }

        .delete-card-text {
          color: #2d3640;
        }
      }
    }

    .delete-card-text {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.38px;
      margin-left: 5px;
      color: #8b95a1;
    }

    .delete-card-img {
      opacity: 0.5;
    }

    .forget-card-alert {
      cursor: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 5;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .forget-card-alert-button {
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 2px;
      width: 68px;
      height: 24px;
      text-align: center;
      letter-spacing: -0.38px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #fff;

      &:hover {
        border: 1px solid #67cc64;
      }
    }

    .forget-card-alert-text {
      margin-bottom: 10px;
      font-size: 14px;
      color: #fff;
      letter-spacing: -0.44px;
    }



  }

  .card-flip-body {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    width: 305px;
    height: 192px;
    margin-top: 7px;
    position: relative;
  }

  .card-image-section {
    position: relative;
    width: 305px;
    height: 192px;
    background-image: url(https://gcdn.bionluk.com/site/b/img/credit-card-front.png);
    background-size: cover;
    background-repeat: no-repeat;

    animation-direction: normal;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-flipped {

    background-image: url(https://gcdn.bionluk.com/site/b/img/credit-card-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .card-numbers {
    display: flex;
    position: absolute;
    left: 30px;
    top: 80px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-number-item {
    font-family: 'OCRAExtendedRegular';
    min-width: 48px;
    font-size: 13px;
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
    margin-left: 17px;
    letter-spacing: 4px;

    span {
      margin: 0;
      padding: 0;
    }

    &:first-child {
      margin-left: 0px;
    }
  }

  .card-image-type-body {
    position: absolute;
    width: 59px;
    height: 35px;
    right: 32px;
    bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: -webkit-transform 400ms linear;
    -o-transition: -o-transform 400ms linear;
    -moz-transition: -moz-transform 400ms linear;
    transition: transform 400ms linear;
  }

  .card-image-type {
    max-width: calc(100% - 7px);
    max-height: calc(100% - 7px);
  }

  .cvc2 {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .remember-body {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .remember-text {
    color: #2d3640;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-left: 10px;
    font-weight: 400;
  }

  .remember-tooltip {
    line-height: 16px;
    margin-left: 7px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    cursor: help;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .super-button {
    min-width: 181px;
    height: 54px;
    width: 240px;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 25px;
    align-self: center;
  }

  /*swiper*/

  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/c-left-swipe.png);
    background-size: cover;
    width: 40px;
    height: 40px;
    left: 0;
    opacity: 1;

    &:hover {
      background-image: url(https://gcdn.bionluk.com/site/icon/c-left-swipe-hover.png);
    }
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/c-right-swipe.png);
    background-size: cover;
    width: 40px;
    height: 40px;
    right: 0;
    opacity: 1;

    &:hover {
      background-image: url(https://gcdn.bionluk.com/site/icon/c-right-swipe-hover.png);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: all !important;
  }

  /*payment success*/


  .payment-succeeded-container_mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
  }

  .payment-succeeded-container {
    height: 546px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 135px;
    padding-right: 135px;
  }

  .payment-succeeded-header {
    color: #2d3640;
    font-size: 32px;
    text-align: center;
    font-weight: 400;
    margin-top: 15px;

    span {
      font-weight: 600;
    }
  }

  .payment-succeeded-text {
    line-height: 24px;
    font-size: 16px;
    color: #2d3640;
    margin-top: 20px;
    text-align: center;

    span {
      font-weight: 800;
    }
  }

  .payment-succeeded-button {
    margin-top: 60px;
    width: 170px !important;
  }

  /*adblock modal*/

  .blocked-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 94px 135px 50px 135px;
  }

  .blocked-img {
    height: 90px;
    width: 90px;
  }

  .blocked-header {
    font-size: 32px;
    text-align: center;
    color: #2d3640;
    font-weight: 800;
    margin-top: 20px;
  }

  .blocked-text {
    font-size: 16px;
    text-align: center;
    line-height: 26px;
    color: #2d3640;
    font-weight: 400;
    margin-top: 9px;

    span {
      font-weight: 800;
    }
  }


  .iyzico-area {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 35px;
  }

  .iyzico-header {
    font-size: 32px;
    text-align: center;
    color: #2d3640;
    margin-top: 20px;
    span {
      font-weight: 600;
    }
  }
  .iyzico-text {
    font-size: 16px;
    line-height: 1.5;
    color: #2d3640;
    max-width: 500px;
    padding:20px 20px 0;
  }



  .modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }


  .modal-text-header {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin-top: 15px;
    color: #2d3640;
  }

  .modal-text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 21px;
    color: #5e6b79;
  }

  .button-body {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .confetti {
    position: absolute;
    width: 679px;
    height: 326px;
    top: 5px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .modal-avatar {
    width: 84px;
    height: 84px;
    border: 2px solid #eaeaea;
    border-radius: 100%;
  }

  .content-balance{
    width: 200px; height: 100px;  color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba(101, 196, 219, 0.3); background-color: rgba(101, 196, 219, 1);

    .balance{
      font-size: 36px;
      font-weight: bold;
      .tl{
        font-size: 28px;
        font-weight: 600;
        padding-left: 3px;
      }


    }
  }
</style>
