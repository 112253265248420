<template>
  <div class="skill">
    <label class="container" :style="containerStyle" v-show="!loading">
      <input :id="id" :style="inputStyle" :name="id" :value="checked" :checked="checked" :disabled="disabled" type="checkbox" @input="handleInput">
      <span class="checkMark" :style="checkMarkStyle" />
    </label>
    <loader v-show="loading" style="width: 16px; height: 16px"/>
    <template v-if="label">
      <div v-if="calledFrom === 'singlegig'" style=" margin-left: 10px;">
        <label :for="id" style="cursor: pointer">
          <p class="setting-box-options-title">{{ label }}</p>
          <p class="setting-box-options-sub-title">{{ singleGig.freelancer }} Freelancer • {{singleGig.gig}} İş ilanı</p>
        </label>
      </div>
      <div v-else-if="calledFrom === 'categoryFilter'">

      </div>
      <p v-else class="skill-label" :style="labelStyle">
        <label :for="id" :style="checked ? 'color: #2d3640; cursor:pointer' : 'cursor:pointer'" v-html="label" />
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CustomCheck',
  props: {
    inputStyle: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: String,
      default: ''
    },
    checkMarkStyle : {
      type: String,
      default: ''
    },
    labelStyle: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading:{
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    calledFrom: {
      type: String,
      default: ''
    },
    singleGig: {
      type: Object,
      default: {freelancer:0, gig:0}
    },
    value: {
      type: [String, Boolean, Number],
      default: ''
    }

  },
  data() {
    return {}
  },
  methods: {
    handleInput() {
      this.$emit('input', this.value)
    }
  }
}

</script>

<style scoped lang="scss">

// Variable overrides
$imgBase: 'https://storage.googleapis.com/superlancer.appspot.com/public/';
$jade: #00a575;
$kelly_green: #5beb17;
$radical_red: #fd4056;
$shamrock: #26ca9a;
$amber: #ffbf00;
$blue_bayoux: #5e6b79;
$hawkaye_blue: #eff3f8;
$link_water: #bfc8d2;
$manatee: #8b95a1;
$oxford_blue: #2d3640;
$solitude: #eaedf2;
$trout: #4b4f52;
$french_pass: #a3e0e1;
$malibu: #65c4db;
$tacao: #f2b46d;
$yellow_sea: #f29933;
$summer_sky: #2bb5d7;
$mantis: #67CC64;
$zircon: #DCE1E1;
$athens_gray: #F4F5F7;
$charismatic_gray: #fdfdfd;

.setting-box-options-title{
  font-size: 16px!important;
  color: #5e6b79!important;
  text-overflow: ellipsis;
  width: 180px;
  white-space: pre;
  overflow: hidden;
}

.setting-box-options-sub-title{
  font-size: 11px!important;
  margin-top: 4px;
  font-style: italic!important;
  color: #8b95a1!important;

}

.container {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkMark {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #00a575;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkMark {
  background-color: $jade;
  border: 1px solid $jade;
}

/*disabled */
.container input:disabled ~ .checkMark {
  border: 1px solid $link_water;
  opacity: 0.5;
}

/* disabled checkedd */
.container input:disabled:checked ~ .checkMark {
  background-color: $link_water;
  border: 1px solid $link_water;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkMark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkMark:after {
  background-image: url($imgBase + 'ic-check.svg');
  background-repeat: no-repeat;
  -webkit-background-size: 14px 14px;
  background-size: 14px 14px;
  background-position: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  filter: brightness(0) invert(1);
}

.skill {
  display: flex;

  p {
    font-size: 16px;
    color: #a1a9a9;
    margin-left: 10px;
  }
}

.selectedSkill {
  box-sizing: border-box;
  width: 545px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: solid 1px $link_water;
  margin-bottom: 60px;
  padding-left: 15px;
  padding-right: 60px;

  &:last-child {
    margin-bottom: 0;
  }
}

.selectedSkillLabel {
  font-size: 14px;
  color: $oxford_blue;
  border-radius: 5px;
  background-color: $link_water;
  padding: 2px 20px;
}

.selectedSkillLabelActive {
  font-size: 14px;
  color: white;
  border-radius: 5px;
  background-color: $mantis;
  padding: 2px 20px;
}

</style>
