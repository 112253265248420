<template>
  <div class="settings">
    <div class="pageContainer">



      <div class="fixed-div">
        <!--mesaj liste-->
        <div class="chat-list-body">
          <div style="position:relative;">
            <input class="chat-list-search"
                   v-model="searchTerm"
                   type="text"
                   @keyup="inputEvent"
                   placeholder="Kullanıcı Ara"/>
            <button v-if="searchTerm.length" @click="searchingMode = true; getConversationsV2()" class="super-button" style="min-width:43px; width: 43px; font-size: 13px;  height:27px;position: absolute; top:24px; right: 24px; z-index: 3333">Ara</button>

          </div>

          <div class="new-titles">
            <div @click="makeActive('all')" class="new-titles-box" :style="$store.state.chatUserActiveTitle ==='all' ? 'background-color: #fd4056' : ''">
              <p class="new-titles-text" :style="$store.state.chatUserActiveTitle ==='all' ? 'color: #fff' : 'color:#5e6b79'">Gelen Kutusu</p>
            </div>
            <div  @click="makeActive('unread')" class="new-titles-box" :style="$store.state.chatUserActiveTitle ==='unread' ? 'background-color: #fd4056;margin-left: 5px;' : 'margin-left: 5px;'">
              <p class="new-titles-text"  :style="$store.state.chatUserActiveTitle ==='unread' ? 'color: #fff' : 'color:#5e6b79'">Okunmamış</p>
              <div v-if="user.unreadMessageCount" style="margin-left: 4px;" class="button-badge badge-for-title">{{ user.unreadMessageCount}}</div>
            </div>
            <div @click="makeActive('deleted')" class="new-titles-box" :style="$store.state.chatUserActiveTitle ==='deleted' ? 'background-color: #fd4056' : ''">
              <p class="new-titles-text" :style="$store.state.chatUserActiveTitle ==='deleted' ? 'color: #fff' : 'color:#5e6b79'">Arşiv</p>
            </div>
          </div>




          <div id="chat-row-b" class="chat-row-body" v-disable-body-scroll>
            <div v-if="userOpenLoading" class="loader" style="margin-top: 50px"></div>

            <div v-else-if="chatUsers.length === 0" style="display: flex; flex-direction: column; align-items: center; margin-top: 70px; ">
              <img style="width: 200px; opacity: 0.8" src="https://gcdn.bionluk.com/site/cicons/ilMessages%402x.png"/>
              <p style="max-width: 140px; text-align: center; margin: 20px auto; width: 200px; line-height: 1.4;  color: rgb(139, 149, 161);  font-weight: 300;">Bu bölümde hiç mesaj bulunamadı.</p>
            </div>
            <div v-else-if="rerender" class="chat-row" v-for="(chatUser,index) in chatUsers"
                 :style="returnCorrectStyle(chatUser,'background')" :key="'cu'+index"
                 @click="getConversation(chatUser)">
              <div v-show="!chatUser.isseen" class="isseen-div"></div>
              <div v-show="chatUser.isseen" class="isseen-empty-div"></div>
              <div class="avatar-main">
                <img class="avatar-image" :src="chatUser.r_avatar_url"/>
              </div>
              <div class="user-name-main">
                <p class="user-name" :style="returnCorrectStyle(chatUser, 'username')">{{chatUser.r_username}}</p>
                <div style="display: flex; align-items: center; height: 16px; margin-top: 5px;">
                  <p v-show="chatUser.last_message_user_id == meId && chatUser.is_receiver_seen && !chatUser.is_writing"  class="icon-seen-container">
                    <img class="icon-seen-green" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                  </p>
                  <p v-show="chatUser.last_message_user_id == meId && !chatUser.is_receiver_seen && !chatUser.is_writing"  class="icon-seen-container">
                    <img v-show="!chatUser.is_receiver_seen" class="icon-seen" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                  </p>
                  <p v-if="!chatUser.is_writing" class="user-last-message"
                     :style="returnCorrectStyle(user,'lastmessage')">
                    {{chatUser.message_m}}
                  </p>
                  <p v-else style="color: #00a575; font-weight: 600; font-size: 14px;">
                    {{chatUser.is_writing === 'uploading' ? 'dosya gönderiyor... %'+chatUser.is_writing_percentage : 'yazıyor...'}}
                  </p>
                </div>
              </div>
              <div class="last-seen-div">
                {{ chatUser.last_message_created_at | calculateElapsedTime }} önce
              </div>
            </div>


          </div>
          <div class="seemore-div"
               @click="getConversationsV2(null, true)"
               v-if="!searchingMode && !userOpenLoading && $store.state.chatUserActiveTitle === 'all' &&  $store.state.chatUsersSeemoreDiv && !waitSeemore">
            Daha fazla +
          </div>
          <div
            v-else-if="!searchingMode && !userOpenLoading && $store.state.chatUserActiveTitle === 'deleted' && deletedSeemore && !waitSeemore"
          class="seemore-div"
               @click="getConversationsV2('deleted', true)">
            Daha fazla +
          </div>
          <loader v-if="!userOpenLoading && $store.state.chatUsersSeemoreDiv && waitSeemore" style="width: 20px; height: 20px;"></loader>
          <loader v-if="!userOpenLoading && $store.state.chatUserActiveTitle === 'deleted' && waitSeemore" style="width: 20px; height: 20px;"></loader>
        </div>

        <div class="horizontal-seperator"></div>

        <!--mesaj detay loader-->
        <div class="message-detail-body" v-if="loadingUser || !channelID">
          <div class="message-detail-header">

            <router-link :to="'/'+ receiver.username" class="message-detail-username">
              {{ receiver.username }}
            </router-link>
          </div>
          <div style="display: flex; flex-direction: row; height: 100%;">

            <div id="messages-body-cover-mock" style="width: 100%; position: relative;">
              <div class="messages-body"
                   :style="`height: calc(100vh - ${messageBodyMinHeight + (infoBar.showOrderInfoBar ? 0 : 0) + (firstMessage ? 120 : 0)}px)`">

                <div v-for="x in 5" style="display: flex;  align-items: center; margin-bottom: 40px;">
                  <div class="messages-body-avatar-loader chat-loader"></div>
                  <div>
                    <div class="messages-body-message-loader chat-loader"></div>
                    <div class="messages-body-time-loader chat-loader"></div>
                  </div>
                </div>

                <div v-for="x in 4" style="display: flex; justify-content: flex-end;  align-items: center; margin-bottom: 40px;">
                  <div>
                    <div class="messages-body-message-loader chat-loader"></div>
                    <div class="messages-body-time-loader chat-loader" style="margin-left: auto;"></div>
                  </div>
                  <div class="messages-body-avatar-loader chat-loader" style="margin-right: 0; margin-left: 10px;"></div>
                </div>
              </div>

              <!--input-->
              <div style="height: 100%; max-height: 159px;">
                <div v-if="!closedChatBoxText" style="position:relative;max-width: 100%; border-top: solid 1px #eaedf2;">
                  <div v-show="!hide && !message" class="input-placeholder" style="visibility: visible;">Bir mesaj yazın...</div>
                  <div @focus="focusEvent($event)"
                       v-disable-body-scroll
                       @blur="blurEvent($event)"
                       :value="message"
                       @input="setInputMessage($event)"
                       @keypress="keyPressed($event)"
                       id="message-input-mock"
                       class="input"
                       :contenteditable="this.loadingUser ? false: true"
                       data-tab="1"
                       dir="auto">
                  </div>

                </div>
                <div v-else class="input" style="padding-right: 30px">
                  <input style="width: 100%" :disabled="closedChatBoxText" :placeholder="closedChatBoxText">
                </div>
                <div style="clear: both"></div>
              </div>
            </div>

            <!--user-action detay-->
            <div  class="chat-action-detail-body">
              <div class="action-user-information">
                <div class="action-user-information-avatar-loader chat-loader"></div>
                <div class="action-user-information-lastseen-username">
                  <div class="action-user-information-username-loader chat-loader"></div>
                  <div class="action-user-information-lastseen-loader chat-loader"></div>
                </div>
              </div>
              <div class="action-user-attachment">
                <p style="font-size: 12px; font-weight: bold;	color: #242424; margin-bottom: 10px;">PAYLAŞILAN DOSYALAR</p>
                <div class="action-user-attachment-list-body">
                  <div v-for="file in 5" class="action-user-attachment-list">
                    <div class="action-user-attachment-list-file-loader chat-loader"></div>
                  </div>
                </div>
              </div>
              <div class="action-user-tabbars">
                <div class="title-body">

                </div>
              </div>
              <div class="action-user-tab-content">
                <div class="tab-content-array-div">
                  <div v-for="tabItem in 3" class="tab-content-array-item">
                    <div class="content-name-price">
                      <div class="content-name-loader chat-loader"></div>
                      <div class="content-price-loader chat-loader"></div>
                    </div>
                    <div class="content-status-loader chat-loader"></div>
                    <div class="content-time-loader chat-loader"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!--mesaj detay-->
        <div class="message-detail-body"
             v-if="channelID && !loadingUser">

          <div class="message-detail-header">

            <div>
              <router-link :to="'/'+ receiver.username" class="message-detail-username">
                {{ receiver.username }}
              </router-link>
              <div class="action-user-information-lastseen-username">
                <p v-if="receiver.onlineStatus && diffFromNow(receiver.lastseen, 'seconds') < 90" class="action-user-information-lastseen" style="color: #00a575;font-weight: 600;">Çevrimiçi</p>
                <p v-else class="action-user-information-lastseen">{{ receiver.lastseen | lastSeen }} önce görüldü.</p>
              </div>
            </div>

            <div class="icon-div">
              <!--<img class="chatbox-icons"-->
              <!--@click="makeVoiceCall(receiver)"-->
              <!--src="https://gcdn.bionluk.com/site/icon/tansel_chat_head_video_call.svg">-->
              <div v-if="f_t_info.isActive">
                <img class="chatbox-icons"
                     @click="openFreelancerChatLineModal(f_t_info,receiver)"
                     title="Sesli Arama Yap"
                     src="https://gcdn.bionluk.com/site/icon/mm.svg">
              </div>
              <div v-else>
                <img class="chatbox-icons"
                     @click="openFreelancerChatLineModal(f_t_info,receiver)"
                     title="Sesli Arama Yap"
                     src="https://gcdn.bionluk.com/site/icon/nn.svg">
              </div>

              <div v-if="1===2" style="position: relative;">
                <img class="chatbox-icons"
                     title="Ayarlar"
                     @click="openSettingBox=!openSettingBox"
                     src="https://gcdn.bionluk.com/site/icon/tansel_chat_head_options.svg">
                <div class="setting-box"
                     v-if="openSettingBox"
                     v-click-outside="closeEvent">
                  <p class="setting-box-options"
                     @click="report('conversation',channelID, 16)">Şikayet Et..</p>
                  <p class="setting-box-options"
                     @click="changeArchiveStatus(receiver.username)">{{ archiveStatus ? 'Arşivle' : 'Arşivden Çıkar' }}</p>
                  <p class="setting-box-options"
                     @click="blockUser(receiver)">{{ blocked ? 'Engeli Kaldır' : 'Engelle' }}</p>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; height: 100%;">

            <div id="messages-body-cover" style="width: 100%; position: relative;">
              <div id="drag-drop"
                   draggable="true"
                   @drop="dropHandler($event)"
                   @dragover="dragOverHandler($event)">
                {{dragDropText}}
              </div>
              <div class="messages-body"
                   @mouseup="chatBoxClick($event)"
                   @scroll="scrollEvent"
                   :style="`height: calc(100vh - ${messageBodyMinHeight + (infoBar.showOrderInfoBar ? 0 : 0) + (firstMessage ? 120 : 0)}px)`"
                   v-disable-body-scroll>
                <div v-show="messageSeemorediv && !chatOpenLoading" class="seemorediv">
                  <a @click="chatOpen(selectedUser, messageOffset, true)" class="seemore"> Daha fazla + </a>
                </div>
                <div v-show="!messageSeemorediv && !chatOpenLoading" style="height: 0px;"></div>
                <div v-show="chatOpenLoading" class="loader" style="width:26px; height: 26px; text-align: center; margin: 0 auto;"></div>
                <div id="message-array-div">
                  <div class="chat-where-from-info" v-if="receiver && receiver.whereFromInfo && receiver.whereFromInfo.type">
                    <p class="up-text">{{receiver.whereFromInfo.props.help_text}}</p>

                      <div class="where-from-box">
                        <div class="icon-request-container">
                          <img class="icon-request" :src="receiver.whereFromInfo.props.icon_svg" onload="SVGInject(this)"/>
                        </div>
                        <div class="where-from-title">
                          {{receiver.whereFromInfo.props.title}}
                        </div>
                        <router-link
                          @contextmenu.native="handleGlobalClick('chat','chatBox')"
                          @click.native="handleGlobalClick('chat','chatBox')"
                          style="text-decoration: none;" :to="receiver.whereFromInfo.props.url">
                          <div class="order-button" style="text-align: center">
                            <p>Görüntüle</p>
                          </div>
                        </router-link>
                      </div>



                  </div>


                  <div class="chat-welcome-message" v-if="receiver && receiver.showWelcomeMessage">
                    <div class="chat-welcome-message-user-info">
                      <img :src="receiver.avatar_url"/>
                      <p class="welcome-message-user-info-username">{{receiver.username}}</p>
                      <star-rating v-if="receiver.rating.commentCount" :rating="receiver.rating.commentRating"
                                   :max-rating="5"
                                   :star-size="12"
                                   :show-rating="false"
                                   inactive-color="#dce1e1"
                                   active-color="#ffb700"
                                   :read-only="true"></star-rating>
                    </div>
                    <div class="chat-welcome-message-content">
                      <p>{{receiver.welcomeMessage}}</p>
                    </div>
                    <div class="chat-welcome-message-time-info">
                      <div v-if="receiver.responseTime !== 0" class="chat-welcome-message-time-info-inner">
                        <img src="https://gcdn.bionluk.com/site/icon/tanse_chatinfo_responsetime_v2.svg"/>
                        <p>Ort. Yanıt Süresi</p>
                        <span v-if="receiver.responseTime !== 0">{{convertAverageTime(receiver.responseTime)}}</span>
                      </div>
                      <div v-if="receiver.responseTime !== 0 && receiver.lastseen !== 0" class="welcome-seperator"></div>
                      <div class="chat-welcome-message-time-info-inner">
                        <img src="https://gcdn.bionluk.com/site/icon/tanse_chatinfo_lastseen_v2.svg"/>
                        <p>Son Görülme</p>
                        <p v-if="receiver.onlineStatus && diffFromNow(receiver.lastseen, 'seconds') < 90" style="color: #00a575;font-weight: 600;">Çevrimiçi</p>
                        <span v-else> {{ receiver.lastseen | lastSeen }} önce</span>
                      </div>
                    </div>
                  </div>
                  <template v-for="(message, index) in messages">
                    <div
                      v-if="message.createdDay" class="chattarih">
                      <span>{{message.createdDay}}</span>
                    </div>

                    <div class="messages-main">

                      <div v-if="message.type === Constants.MESSAGE_TYPES.OFFER">
                        <div class="typeOrder"
                             :style="`background-color:${message.info.offer.bgcolor}; border: solid 1px ${message.info.offer.bordercolor};`">
                          <div style="display: flex;">
                            <img style="height: 20px; margin-left: 20px;" :src="message.info.offer.icon">
                            <p style="color: #2d3640; width: 150px; font-weight: 500; font-size: 14px; text-align: left; margin-left: 25px;">
                              {{ message.message }}
                            </p>
                          </div>
                          <div @click="activateCustomOfferDetailModal(message.info.offer)" class="order-button">
                            <p>{{message.info.offer.buttontext}}</p>
                          </div>
                        </div>
                        <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                             :style="message.u_uuid === user.uuid ? '' : 'margin-left: 0;'">{{ message.created_at | customDateFormat('HH:mm') }}
                        </div>
                      </div>

                      <div v-else-if="message.type === 'order'">
                        <div class="typeOrder"
                             :style="`background-color:${message.info.order.bgcolor}; border: solid 1px ${message.info.order.bordercolor};`">
                          <div style="display: flex;">
                            <img style="height: 20px; margin-left: 20px;" :src="message.info.order.icon">
                            <p style="color: #2d3640; width: 150px; font-weight: 500; font-size: 14px; text-align: left; margin-left: 25px;">
                              {{ message.message }}
                            </p>
                          </div>
                          <router-link style="text-decoration: none;" :to="'/orders/'+message.info.order.order_id">
                            <div class="order-button">
                              <p>{{message.info.order.buttontext}}</p>
                            </div>
                          </router-link>
                        </div>
                        <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                             :style="message.u_uuid === user.uuid ? '' : 'margin-left: 0;'">{{ message.created_at | customDateFormat('HH:mm') }}
                        </div>
                      </div>

                      <div v-else class="chatbubble-main" style=" margin-top: 5px;">
                        <div v-if="message.u_uuid !== user.uuid">
                          <img style="width: 36px; height: 36px; border-radius: 50%; float: left; margin-right: 10px; margin-top: 5px;" :src="receiver.avatar_url"/>
                        </div>

                        <!--hide message-->
                        <div
                          v-show="message.hide_message"
                          style="max-width: 280px; display: flex;"
                          :class="message.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'">
                          <div style="font-size: 12px; height: 18px; font-style: italic; font-weight: 300; display: flex; align-items: center">
                            <div class="info-icon-container"
                                 :data-balloon="message.hide_message_description"
                                 data-balloon-pos="up"
                                 data-balloon-length="small">
                              <img class="info-icon" src="https://gcdn.bionluk.com/site/cicons/ic-info.svg"
                                   onload="SVGInject(this)"/>
                            </div>
                            <p>{{message.hide_message_text}}</p>
                          </div>
                        </div>


                        <div v-if="message.hide_message">

                        </div>



                        <div v-else-if="message.info && message.info.iscall"
                          style="max-width: 280px; display: flex;"
                          :class="message.u_uuid === user.uuid ? 'chatbubble right-iscall' : 'chatbubble left-iscall'">
                          <div class="iscall-body">
                            <img class="iscall-phone-icon"
                                 src="https://gcdn.bionluk.com/site/icon/tansel_missed_call.svg"/>
                            <div>
                              <p class="iscall-username">{{receiver.username}} {{message.u_uuid === user.uuid ? 'arandı' : ' seni aradı'}}</p>
                              <p class="iscall-created-date">{{ message.created_at | customDateFormat('HH:mm') }}</p>
                            </div>
                          </div>
                          <div class="iscall-callback-div">
                            Tekrar Ara
                          </div>
                        </div>


                        <!--normal message-->
                        <div v-else
                             :style="message.type === Constants.MESSAGE_TYPES.UPLOAD ? 'padding:0; border: solid 1px rgba(43, 181, 215, 0.2); background-color: rgba(101, 196, 219, 0.2); ' :'max-width: 280px;'"
                             :class="message.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'"
                          >
                          <span v-if="message.type !== Constants.MESSAGE_TYPES.UPLOAD" class="chatbuble-text" v-html="message.message"></span>
                          <div data-balloon="Dosyayı Sil"
                               data-balloon-pos="left"
                               class="delete-uploaded-file"
                               v-if="message.u_uuid === user.uuid && message.type === 'upload'" @click="deleteUploadedFile(message)">
                            <img  src="https://gcdn.bionluk.com/site/c/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img"/>
                          </div>
                          <div class="partial-loader" v-if="deleteFileLoader && message.u_uuid === user.uuid && message.type === 'upload'">
                            <loader style="width: 30px; height: 30px; "/>
                          </div>
                          <div v-if="message.type === Constants.MESSAGE_TYPES.UPLOAD" >
                            <div v-for="(uploadChat, uindex) in message.uploads" :key="uindex">
                              <div v-if="uploadChat.image_url" style="width: 226px; height:130px; display:flex; align-items:center; justify-content:center; ">
                                <a target="_blank" :href="uploadChat.url" :download="uploadChat.filename" class="image-u-div">
                                  <div class="playIcon"
                                       v-show="( uploadChat.file_type === 'video')">
                                    <img class="hoveropa"
                                         :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
                                  </div>
                                  <img style="width: 220px; height: 124px; border-radius: 6px; background: white" :src="uploadChat.image_url">
                                  <div v-show="uploadChat.file_type === 'image'" class="si-icon-container">
                                    <img class="si-icon" src="https://gcdn.bionluk.com/site/cicons/ic-search.svg"
                                         onload="SVGInject(this)"/>
                                  </div>
                                </a>
                              </div>

                              <div v-else-if="uploadChat.file_type === 'audio'"  style="width: 420px; height:125px; ">
                                <wave-surfer :is-mini="false" :sound-url="uploadChat.url" :file-name="uploadChat.filename" style="width: 420px;"/>
                              </div>
                              <div v-else style="min-width: 264px; height: 70px; display:flex; align-items:center;">
                                <img :src="uploadChat.icon_png" style="width: 29px; height: 38px; margin-left: 15px;">
                                <div style="margin-left: 14px; margin-right: 15px;">
                                  <p style="max-width: 270px;word-break: break-word;text-overflow: ellipsis;overflow: hidden; white-space: pre;line-height: 1.29;color: #8b95a1; font-size: 13px; margin-bottom: 8px;">{{ uploadChat.filename }}</p>
                                  <a target="_blank" :href="uploadChat.url" :download="uploadChat.filename" class="download-icon-container">
                                    <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon">
                                    Dosyayı İndir
                                  </a>
                                </div>
                              </div>


                              <template v-if="1===2">
                                <img style=" height: 16px; margin-top: 3px; position: absolute; display: inline-block;"
                                     src="https://gcdn.bionluk.com/site/icon/tansel_chatin_attachment.svg">
                                <a target="_blank" :href="uploadChat.url" style="color: #56a5ff; padding-left: 22px;" >{{ uploadChat.filename }}</a>
                              </template>

                            </div>
                          </div>
                        </div>
                        <div style="clear: both"></div>

                        <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'" style="display: flex;">
                          {{ message.created_at | customDateFormat('HH:mm') }}
                          <template v-if="message.u_uuid === user.uuid">
                            <p v-if="message.read_at" class="icon-message-seen-container"
                               data-balloon="Görüldü"
                               data-balloon-pos="left"

                            >
                              <img class="icon-message-seen-green" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                            </p>
                            <p v-show="!message.read_at"  class="icon-message-seen-container"
                               data-balloon="İletildi"
                               data-balloon-pos="left"
                               data-balloon-length="small"
                            >
                              <img class="icon-message-seen" src="https://gcdn.bionluk.com/site/cicons/ic-seen.svg" onload="SVGInject(this)"/>
                            </p>
                          </template>
                        </div>

                      </div>


                    </div>

                    <div style="clear: both"></div>
                  </template>

                </div>
                <template v-if="$store.state.percentCompleted[channelID]" v-for="(value, key) in $store.state.percentCompleted[channelID]">


                  <loader v-if=" value < 0.01 && value > 0"></loader>

                  <div class="uploading-div" v-if=" value > 0.01">


                    <img :src="tempIcons[key]" style="width: 39px; height: 50px;">

                    <div style="margin-left:15px; display: flex; align-items: flex-end">
                      <div>
                        <p class="file-name-p" >{{ fileNames[key] }}</p>

                        <progress style="margin-top:10px; height: 12px; border-radius: 7px; margin-left: 0; width: 310px" class="progressbar-purple" :value='value' max="100"></progress>
                      </div>
                      <div>
                        <a style="margin-left: 20px; margin-top: 20px;" @click="cancelUploadRequest(parseInt(key))"> <img
                          src="https://gcdn.bionluk.com/site/icon/tansel_chat_attachmentcancel.svg"/> </a>
                      </div>
                    </div>

                  </div>
                </template>

                <div v-if="onWriting" class="loading-div">
                  {{receiver.username}} {{onWritingType === 'uploading' ? 'size dosya gönderiyor %' + parseInt(uploadingPercentage) : 'yazıyor'}}
                </div>
              </div>

              <div class="first-message-body" v-if="firstMessage">
                <p class="first-message-header">Bu kullanıcı listende bulunmuyor</p>
                <img @click="firstMessage = false" :src="$root.CDN_SITE + 'icon/tansel_light_gray_close.svg'"/>
                <div class="first-message-button-div">
                  <div class="first-message-box" @click="firstMessageActionButtons(channelID, 1)"
                       data-balloon-length="large"
                       data-balloon="Gelen mesaj içerik olarak seni rahatsız ediyorsa ya da bu mesajın spam/reklam içerikli
                              olduğunu düşünüyorsan bu alanı kullanabilirsin. Böylece ’Yapılacaklar’ listenden
                              mesaja cevap ver uyarısı kalkacaktır. Bu alana tıkladığında karşı tarafa herhangi bir
                              bildirim gitmemektedir."
                       data-balloon-pos="up">Rahatsız oldum
                  </div>
                  <div class="first-message-box" @click="firstMessageActionButtons(channelID, 2)"
                       data-balloon-length="large"
                       data-balloon="Bir kullanıcıdan artık mesaj almak istemiyorsan engelle seçeneğini kullanabilirsin.
                               Bu durumda kullanıcıya sen de mesaj gönderemezsin. Kendisini engellediğin bilgisi
                                ilgili kullanıcıya iletilir. Sana mesaj attıktan sonra engelle seçeneğini kullanırsan
                                ’Yapılacaklar’ listenden mesaja cevap ver uyarısı kalkacaktır.
                 " data-balloon-pos="up">Engelle
                  </div>
                  <div class="first-message-box" @click="firstMessageActionButtons(channelID, 3)"
                       data-balloon-length="large"
                       data-balloon="Gelen mesajda rahatsız edici bir içerik yoksa bu mesaj önemli diyerek bize kullanıcı
                                hakkında olumlu bilgi verebilirsin. Bu alana tıkladığında karşı tarafa herhangi
                                bir bildirim gitmemektedir."
                       data-balloon-pos="up">Bu mesaj önemli
                  </div>
                </div>
              </div>
              <!--input-->
              <div style="height: 100%; max-height: 159px;">
                <div v-if="!closedChatBoxText" style="position:relative;max-width: 100%; border-top: solid 1px #eaedf2;">

                  <div :class="{'emoji-img': true, 'emoji-img-active': openEmojiPicker}" @click="openEmojiPicker = !openEmojiPicker"></div>
                  <emoji-picker class="emoji-picker"
                                v-click-outside="pageClickHandler"
                                v-if="openEmojiPicker"
                                :emojiSize="32"
                                :emojisByRow="7"
                                :showSearch="true"
                                :i18n="{ search: 'Ara...'}"
                                @select="addEmoji"/>

                  <div v-show="!hide && !message" class="input-placeholder" style="visibility: visible;">Bir mesaj yazın...</div>
                  <div @focus="focusEvent($event)"
                       v-disable-body-scroll
                       @blur="blurEvent($event)"
                       :value="message"
                       @input="setInputMessage($event)"
                       @keypress="keyPressed($event)"
                       id="message-input"
                       class="input"
                       :contenteditable="this.loadingUser ? false: true"
                       data-tab="1"
                       dir="auto">
                  </div>
                  <div v-if="!closedChatBoxText" style="display: flex; align-items: center; flex-direction: row; padding:5px 10px;">
                    <label for="files_chat_workstation" class="attachment-container">
                      <div class="attachment-icon"></div>
                      <span class="attachment-text">Dosya Ekle</span>
                    </label>
                    <input id="files_chat_workstation" style="display:none;" type="file" @change="onFileChange" multiple>
                    <div class="offer-icon-container" v-if="user.seller.isSeller" @click="activateBidCustomOfferModal()">
                      <div class="offer-icon"></div>
                      <span class="attachment-text">Özel Teklif Gönder</span>
                    </div>

                  </div>
                </div>
                <div v-else class="input" style="padding-right: 30px">
                  <input style="width: 100%" :disabled="closedChatBoxText" :placeholder="closedChatBoxText">
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="scroll-button" v-if="scrollButton" @click="scrollDown('message', true)" :style="'bottom:' + (240 + defaultlistenHeight) + 'px;'">
                <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
                <div class="scroll-button-message-count" v-if="messageCount">
                  {{messageCount}}
                </div>
              </div>
            </div>

            <!--user-action detay-->
            <div class="chat-action-detail-body">




              <div style="width: 100%;  display: flex; flex-direction: column; align-items: center; margin-bottom: 10px; margin-top: 20px;">
                <img  style="background:white;width: 70px; height: 70px; border-radius: 50%; border: 1px solid #e1e1e1;" :src="receiver.avatar_url"/>
                <router-link :to="'/'+ receiver.username" class="message-detail-username" style="margin-top: 5px; font-size: 16px;">
                  {{ receiver.username }}
                </router-link>
              </div>


              <div style="width: 100%;">
                <div v-if="receiver.is_seller" class="freelancer-line-wrapper">
                  <div class="info" style="margin-left:10px; margin-right:10px;">
                    <div class="text-wrapper" style="line-height: 1.71;">
                      <img src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg" style="width: 20px; height: 20px; margin-top: 2px"/>
                      <div v-if="f_t_info.isActive" style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal">
                        <span style="color: #00a575; font-weight: bold">Freelancer Telefon Hattı</span> üzerinden ulaş.
                      </div>
                      <div v-else style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal;">
                        <span style="color: #00a575; font-weight: bold">Bionluk Freelancer Hattı</span>
                        üzerinden telefonla ulaşmak için freelancer’dan hattını aktif etmesini iste.
                        <span v-if="!f_t_info.isActive" class="question-mark-wrapper" style="margin-left:5px;margin-top: 3px; cursor: pointer" @click="openFreelancerChatLineModal(f_t_info,receiver)">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
                        </span>
                      </div>
                    </div>
                    <div v-if="f_t_info.isActive" class="question-mark-wrapper" style="margin-left:5px;margin-top: 3px;" :data-balloon="reachLineText" data-balloon-pos="left" data-balloon-length="medium">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
                    </div>
                  </div>
                  <div  v-if="f_t_info.isActive" style="margin-left: 27px; margin-right: 10px; margin-top: 16px;">
                    <div style="display: flex; justify-content: space-between">
                      <span class="info-label" style="color:#8b95a1; font-weight: 300; font-size:14px;">Telefon:</span>
                      <span style="color: #2d3640; font-weight: 600!important; font-size: 16px">0 (212) 909 - 23 - 10</span>
                    </div>
                    <div style="margin-top: 16px; display: flex; justify-content: space-between">
                      <span class="info-label" style="color:#8b95a1; font-weight: 300; font-size:14px;">Dahili Numara:</span>
                      <div style="display:flex;color: #2d3640; font-weight: 600; font-size: 16px;">{{ f_t_info.isPublic ? f_t_info.line : 'Gizli ' }}

                        <div v-if="!f_t_info.isPublic" class="question-mark-wrapper" style="margin-left: 5px;" data-balloon="Freelancer dahili numarasını gizlemeyi tercih ediyor. Kendisine mesaj göndererek telefonla görüşme talebini iletebilirsin." data-balloon-pos="left" data-balloon-length="medium">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <transition name="slideM">

              <ul v-if="!showMenuDetail" class="nav-list">
                <li @click="$router.push('/'+receiver.username)">
                  <div class="li-inside" >
                    <div>
                      <p class="li-text">Profile Git </p>
                      <img :src="'https://gcdn.bionluk.com/site/c/ic-myprofile.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                    <div style="display: flex; align-items: center">
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-right.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow">
                    </div>
                  </div>
                </li>



                <li v-if="files.length">
                  <div class="li-inside" @click="menuToggle('files')" >
                    <div>
                      <p class="li-text">Paylaşılan Dosyalar </p>
                      <img :src="$root.CDN_SITE + 'cicons/ic-categories.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                    <div style="display: flex; align-items: center">
                      <span style="display: inline-block; margin-right:4px;" v-if="files.length" class="li-number">{{files.length}}</span>
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-right.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow">
                    </div>
                  </div>
                </li>

                <li v-if="buyings.length">
                  <div class="li-inside"  @click="menuToggle('buyings')" >
                    <div>
                      <p class="li-text">Aldıklarım </p>
                      <img :src="$root.CDN_SITE + 'cicons/ic-basket.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                    <div style="display: flex; align-items: center">
                      <span style="display: inline-block; margin-right:4px;" v-if="buyings.length" class="li-number">{{buyings.length}}</span>
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-right.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow">
                    </div>
                  </div>
                </li>

                <li v-if="offers.length">
                  <div class="li-inside" @click="menuToggle('offers')">
                    <div>
                      <p class="li-text">Teklifler </p>
                      <img :src="$root.CDN_SITE + 'cicons/ic-special-offer.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                    <div style="display: flex; align-items: center">
                      <span style="display: inline-block; margin-right:4px;" v-if="offers.length" class="li-number">{{offers.length}}</span>
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-right.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow">
                    </div>
                  </div>
                </li>



                <li v-if="sales.length">
                  <div class="li-inside" @click="menuToggle('sales')">
                    <div>
                      <p class="li-text">Satışlarım </p>
                      <img :src="$root.CDN_SITE + 'cicons/ic-my-sales.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                    <div style="display: flex; align-items: center">
                      <span style="display: inline-block; margin-right:4px;" v-if="sales.length" class="li-number">{{sales.length}}</span>
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-right.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow">
                    </div>
                  </div>
                </li>


                <li v-if="messages.length" @click="changeArchiveStatus(receiver.username)">
                  <div class="li-inside" >
                    <div>
                      <p class="li-text">{{archiveStatus ? 'Mesajlaşmayı Arşivle' : 'Arşivden Çıkar'}} </p>
                      <img :src="'https://gcdn.bionluk.com/site/cicons/ic-archive.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                  </div>
                </li>


                <li @click="report('conversation',channelID, 16)">
                  <div class="li-inside" >
                    <div>
                      <p class="li-text">Şikayet Et </p>
                      <img :src="'https://gcdn.bionluk.com/site/cicons/ic-warning.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>
                  </div>
                </li>

                <li v-show="messages.length" @click="blockUser(receiver)" style="border-bottom:none">
                  <div class="li-inside"  >
                    <div >
                      <p class="li-text"  :class="blocked && 'activet'">{{blocked ? 'Engeli Kaldır' : 'Engelle'}} </p>
                      <img v-show="blocked" :src="'https://gcdn.bionluk.com/site/cicons/ic-block.svg'" onload="SVGInject(this)" class="side-nav-icons-active">
                      <img v-show="!blocked" :src="'https://gcdn.bionluk.com/site/cicons/ic-block.svg'" onload="SVGInject(this)" class="side-nav-icons">
                    </div>

                  </div>
                </li>


              </ul>
              </transition>

              <transition name="slide">
              <div v-if="showMenuDetail">
                <ul class="nav-list">
                  <li>
                    <div class="li-inside" @click="showMenuDetail = null" >
                      <img :src="$root.CDN_SITE + 'cicons/ic-arrows-left.svg'" onload="SVGInject(this)" class="side-nav-icons-arrow-back">
                      <p class="li-text" style="margin-top: -3px; margin-left: 17px;"> Geri </p>
                    </div>
                  </li>
                </ul>

                <div v-if="showMenuDetail === 'files'">
                  <div v-for="(file, fii) in files">
                    <div class="file-row">
                      <a target="_blank" :href="file.url" :download="file.filename" >
                        <img :src="file.image_url || file.icon_png" />
                      </a>
                      <div class="file-name-container">
                        <a target="_blank" :href="file.url" :download="file.filename" class="file-name">{{file.filename}}</a>
                        <p class="file-info">{{ file.created_at | customDateFormat('dd.MM.yyyy') }} • {{ file.user_id == receiver.id ? receiver.username : user.username }}</p>
                      </div>
                      <div>
                        <a target="_blank" :href="file.url" :download="file.filename" class="download-icon-container-files">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon-files">
                        </a>
                      </div>
                    </div>

                  </div>

                </div>

                <div v-if="showMenuDetail === 'offers'" >
                  <div style="margin-left: 16px;">
                    <div v-for="tabItem in offers" class="tab-content-array-item" style="margin-bottom: 20px;" @click="goToContentItem(tabItem)">
                      <div class="content-name-price">
                        <p class="content-name">{{tabItem.title}}</p>
                        <p class="content-price">{{tabItem.total}}</p>
                      </div>
                      <p class="content-status" :style="'color:' + tabItem.status_text_color">{{tabItem.status_text}}</p>
                      <p class="content-time">{{ tabItem.created_at | customDateFormat }}</p>
                    </div>
                  </div>
                </div>


                <div v-if="showMenuDetail === 'sales'" >
                  <div style="margin-left: 16px;">
                    <div v-for="tabItem in sales" class="tab-content-array-item" style="margin-bottom: 20px;" @click="goToContentItem(tabItem)">
                      <div class="content-name-price">
                        <p class="content-name">{{tabItem.cat_title}}</p>
                        <p class="content-price">{{tabItem.total}}TL</p>
                      </div>
                      <p class="content-status" :style="'color:' + tabItem.statusTextColor">{{tabItem.statusText}}</p>
                      <p class="content-time">{{ tabItem.payed_at | customDateFormat }}</p>
                    </div>
                  </div>
                </div>

                <div v-if="showMenuDetail === 'buyings'" >
                  <div style="margin-left: 16px;">
                    <div v-for="tabItem in buyings" class="tab-content-array-item" style="margin-bottom: 20px;" @click="goToContentItem(tabItem)">
                      <div class="content-name-price">
                        <p class="content-name">{{tabItem.cat_title}}</p>
                        <p class="content-price">{{tabItem.total}}TL</p>
                      </div>
                      <p class="content-status" :style="'color:' + tabItem.statusTextColor">{{tabItem.statusText}}</p>
                      <p class="content-time">{{ tabItem.payed_at | customDateFormat }}</p>
                    </div>
                  </div>
                </div>


              </div>
              </transition>


            </div>

          </div>


        </div>


      </div>


    </div>
  </div>
</template>

<script>
  import WorkstationNav from "../../navComponent.vue"
  import WaveSurfer from "@/pages/components/WaveSurfer"
  import { diffFromNow } from '@/utils/dateFns'
  import StarRating from 'vue-star-rating'
  import { VEmojiPicker } from "v-emoji-picker-bi"

  export default {
    name: "src-pages-body-workstation-messages-chat-chat_v2",
    components: { WorkstationNav, StarRating, emojiPicker: VEmojiPicker, WaveSurfer },
    data() {
      return {
        reachLineText: "Alttaki telefon numarası ve dahili numara ile ilgili freelancer'a telefonla ulaşabilirsin. Operatörünün yurt içi sabit hat tarifesinden ücretlendirilirsin. Bu hattın ekstra bir ücreti yoktur.",
        userOpenLoading: false,
        searchTerm: '',
        searchingMode: false,
        activeTitle: "all",
        selectedUser: "",
        showMenuDetail: null,
        meId: null,
        messageOffset: 0,
        messageIsseen: false,
        messageSeemorediv: false,
        messages: [],
        chatOpenLoading: false,
        receiver: {},
        openSettingBox: false,
        channelID: null,
        message: null,
        onWriting: null,
        onWritingType: null,
        uploadingPercentage: 0,
        hide: null,
        listenedWritingTimeout: null,
        triggeredWritingTimeout: null,
        sendWritingEvent: true,
        blocked: null,
        openedMessageMenu: false,
        closedChatBoxText: null,
        archiveStatus: null,
        messageBodyMinHeight: 317,
        defaultlistenHeight: 60,
        waitSeemore: false,
        infoBar: {},
        f_t_info: {},
        firstMessage: false,
        files: [],
        offers: [],
        sales: [],
        buyings: [],
        loadingUser: false,
        scrollButton: false,
        messageCount: 0,
        dragDropText: 'Dosyaları bu alana sürükle.',
        seenMessageIsUpdated: true,
        force_bicoin: false,
        openEmojiPicker: false,
        deleteFileLoader: false,
        searchedConversations: [],
        archivedConversations: [],
        deletedSeemore: false,
        deletedOffset: 0,
        tempIcons: [],
        fileNames: [],
        rerender: true
      }
    },
    methods: {
      diffFromNow,
      menuToggle(type) {
       // this.Helper.initLogrocketManual({chatPage:true})
        this.showMenuDetail = type;
      },
      openFreelancerChatLineModal(info, receiver) {
        let go = {
          f_t_info: info,
          receiver: receiver,
          props: {
            userID: receiver.id,
            username: receiver.username
          }
        };
        this.$store.commit(this.types.ACTIVE_MODAL,
          {
            modalType: this.Modals.FREELANCER_CHAT_LINE,
            info: go
          }
        );
      },
      dragStartEvent(event) {
        let dragDrop = document.getElementById('drag-drop');
        if (dragDrop && dragDrop.style){
          dragDrop.style.top = '0';
        }
        if (event.target.id === "drag-drop") {
          this.dragDropText = 'Dosyaları bu alana bırakın.';
        }
      },
      dragLeaveEvent(event) {
        let dragDrop = document.getElementById('drag-drop');

        if (dragDrop && dragDrop.style && dragDrop.style.top && event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          if(dragDrop && dragDrop.style){
            dragDrop.style.top = '100vh';
          }
        } else if (dragDrop && dragDrop.style && dragDrop.style.top && (!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          if(dragDrop && dragDrop.style){
            dragDrop.style.top = '100vh';
          }
        } else if (event.target.id === "drag-drop") {
          this.dragDropText = 'Dosyaları bu alana sürükle.';
        }
      },
      dropHandler(event) {
        event.preventDefault()
        event.stopPropagation()

        this.onFileChange(event)
        let dragDrop = document.getElementById('drag-drop')
        if (dragDrop)
          dragDrop.style.top = '100vh'
      },
      dragOverHandler(event) {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'  // See the section on the DataTransfer object.
      },
      makeActive(activeTitle) {
        this.searchingMode = false;
        this.searchTerm = '';
        this.deletedOffset = 0;
        this.deletedSeemore = false;
        this.archivedConversations = [];
        if(this.$store.state.chatUserActiveTitle !== activeTitle){
          this.$store.state.chatUsersOffset = 0;
          this.getConversationsV2(activeTitle, false);
        }
      },
      getConversationsV2(messageType = null, comingFromSeeMore = false){
        if (this.userOpenLoading) return false;

        this.waitSeemore = true;

        if(!messageType){
          messageType = this.$store.state.chatUserActiveTitle;
        }

        let searchTerm = this.searchTerm.trim().replace(/ +/g, "");
        let offset = this.searchingMode && searchTerm ? 0 : messageType === 'deleted' ? this.deletedOffset :this.$store.state.chatUsersOffset;
        let limit  = this.searchingMode && searchTerm ? 10 : messageType === 'deleted' ? 10 : 10;

        if(comingFromSeeMore || this.searchingMode || this.$store.state.chatUserActiveTitle !== messageType || this.chatUsers.length === 0){
          this.$store.state.chatUserActiveTitle = messageType;

          if (!offset) {
            this.userOpenLoading = true;
          }
          this.api.workstation.getConversations(messageType, searchTerm , limit, offset)
            .then(({data}) => {
              let result = data;

              if (result.success) {

                if (this.searchingMode){
                  this.searchedConversations = result.data.conversations;
                } else if (messageType === 'deleted'){
                  this.archivedConversations = this.archivedConversations.concat(result.data.conversations);
                  this.deletedOffset = this.deletedOffset + 20;
                  this.deletedSeemore = result.data.seemorediv;
                } else {
                  if (this.$store.state.chatUsersOffset === 0) {
                    this.Helper.setChatUsers(result.data.conversations);
                  } else {
                    this.$store.state.chatUsers = this.$store.state.chatUsers.concat(result.data.conversations)
                  }

                  this.$store.state.chatUsersOffset = this.$store.state.chatUsersOffset + 10;
                  this.$store.state.chatUsersSeemoreDiv = result.data.seemorediv;
                }

                this.waitSeemore = false;
                this.userOpenLoading = false;

                if(comingFromSeeMore){
                  this.$nextTick(() => {
                    let el = document.getElementsByClassName("chat-row-body");
                    if (el[0]) {
                      el[0].scrollTop = el[0].scrollTop+40;
                    }
                  });
                }
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        } else {
          this.waitSeemore = false;
        }
      },
      chatOpen(username, offset = 0, comingFromSeemore = false) {
        let globalScrollMessage, messageArrayDiv, oldHeight;
        if (comingFromSeemore) {
          globalScrollMessage = document.getElementsByClassName('messages-body')[0];
          messageArrayDiv = document.getElementById("message-array-div"); // message-array-div
          oldHeight = messageArrayDiv.getBoundingClientRect().height;
        } else {
          this.loadingUser = true;
        }
        this.chatOpenLoading = true;
        if (offset === 0) {
          this.messages = [];
        }
        this.api.workstation.chatOpen(username, offset, 20)
          .then(({data}) => {
            this.chatOpenLoading = false;
            this.openedMessageMenu = false;
            let result = data;
            if (result.success && this.selectedUser === result.data.receiver.username) {
              this.channelID = result.data.c_uuid;
              this.force_bicoin = result.data.force_bicoin;

              if (result.data.force_bicoin) {
                this.$store.commit(this.types.ACTIVE_MODAL, {
                  modalType: this.Modals.UNLOCK_CHAT,
                  info: {
                    chatID: result.data.c_uuid,
                    comingFrom: "chatDetay",
                    disintermediation: result.data.disintermediationModal,
                    totalBicoin: result.data.total_bicoin
                  }
                });
              } else if (result.data.disintermediationModal) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.DISINTERMEDIATION, info: {comingFrom: "chatDetay"}});
              }

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              if ((result.data.ban_type === "mutual") || (result.data.ban_type === "user")) {
                this.blocked = true;
                this.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCK_MESSAGE;
              } else {
                if (result.data.ban_type === "sender") {
                  this.blocked = false;
                  this.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
                } else {
                  // false durumu
                  this.blocked = false;
                  this.closedChatBoxText = null;
                }
              }

              this.messages = result.data.messages.concat(this.messages);
              this.messages.forEach(function (message, index) {
                message.created_at = Number(message.created_at);
              });
              this.messageSeemorediv = result.data.seemorediv;
              this.messageIsseen = result.data.isseen;
              this.loadingUser = false;

              if (comingFromSeemore) {
                this.$nextTick(() => {
                  if(globalScrollMessage){

                   // globalScrollMessage.scrollTo(0, messageArrayDiv.getBoundingClientRect().height - oldHeight + 38); //38 = loader'ların height'ları
	                  globalScrollMessage.scrollTop = messageArrayDiv.getBoundingClientRect().height - oldHeight + 38;
                  }
                })
              }
              if (!offset) {
                this.scrollDown("message");
                this.messageBodyMinHeight = 317;
                this.defaultlistenHeight = 60;
                if (!this.closedChatBoxText) {
                  this.$nextTick(() => {
                    if (document.getElementById("message-input"))
                      this.setCursorToEnd(document.getElementById("message-input"));
                  });
                }
              }
              this.$nextTick(() => {
                //alttaki kodlar copy paste yaparken html ve css silip yapistiriyor
                const element = document.querySelector("#message-input")
                if (element) {
                  element.removeEventListener("paste", this.pasteEventListener);
                  element.addEventListener("paste", this.pasteEventListener);
                }

                // chatbuble renk degisimi
                this.messages.forEach(function (message, index) {
                  if (document.getElementsByClassName("chatbuble-text")[index]) {
                    let element = document.getElementsByClassName("chatbuble-text")[index].getElementsByTagName("a");
                    if (element.length > 0) {
                      element[0].style.color = "#2d3740";
                    }
                  }
                });

                // drag and drop eventler
                window.removeEventListener('dragleave', this.dragLeaveEvent, false);
                window.removeEventListener('dragenter', this.dragStartEvent, false);
                window.addEventListener('dragleave', this.dragLeaveEvent, false);
                window.addEventListener('dragenter', this.dragStartEvent, false);
              });
              this.archiveStatus = result.data.status;
              this.messageOffset += 20;
              this.receiver = result.data.receiver;
              this.infoBar = result.data.infoBar;
              this.f_t_info = result.data.f_t_info;
              this.firstMessage = result.data.firstMessage;
              this.files = result.data.files;

              if (offset === 0) {
                this.Helper.trackEvents.pageView(this.Helper.chatPageProps(result.data.receiver));
                this.offers = result.data.offers;
                this.buyings = result.data.buyings;
                this.sales = result.data.sales;
              }

            } else {
              this.$toasted.global.errorToast({description: result.message});
              // this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      getConversation(user) {
      	if(user.r_username !== this.receiver.username){
          this.selectedUser = user.r_username;
          this.receiver.username = user.r_username
		      this.channelID = null;
          this.showMenuDetail = null;
		      this.messageOffset = 0;
		      this.$router.push("/chat/" + user.r_username);
		      this.onWriting = false;
          this.chatOpen(this.selectedUser, 0);
        } else {
          if (document.getElementById('message-input')){
            document.getElementById('message-input').focus();
          }
          this.openEmojiPicker = false;
        }
      },
      returnCorrectStyle(user, type) {
        if (type === "background") {
          if (this.selectedUser === user.r_username) {
            return 'background-color: #f4f5f7; border-right: solid 3px #fd4056'
          } else {
            return user.isseen ? 'background-color: #fff; ' : 'background-color: #ffffff;';
          }
        }
        if (type === "username") {
          if (this.selectedUser === user.r_username) {
            return 'color: #2d3640; font-weight: 500'
          } else {
              return user.isseen ? 'color:#2d3640;font-weight: 500' : 'color:#2d3640;font-weight: 600;';
          }
        }
        if (type === "lastmessage") {
          if (this.selectedUser === user.r_username) {
            return 'color: #5e6b79;'
          } else {
            return user.isseen ? 'color:#5e6b79;' : 'color:#2d3640;font-weight:400';
          }
        }
      },
      activateCustomOfferDetailModal(offer) {
        this.api.seller.getOfferDetail(offer.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: result.data.offerDetail}});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      activateBidCustomOfferModal() {
        this.api.seller.retrieveGigs(null, 9999, 0, 1, 1, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {
                modalType: this.Modals.CUSTOM_OFFER,
                info: {
                  data: {
                    type: 'customOffer',
                    gigs: result.data.gigs,
                    receiver: this.receiver,
                    openedChannelID: this.channelID
                  }
                }
              })
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      keyPressed(event) {
        if (event.keyCode === 13 && (event.shiftKey || event.altKey || event.ctrlKey)) {

          if (event.altKey || event.ctrlKey) {
            document.getElementById('message-input').innerHTML = document.getElementById('message-input').innerHTML ? document.getElementById('message-input').innerHTML : '';
            if (!document.getElementById('message-input').childElementCount) {
              let createdBr = document.createElement('br');
              document.getElementById('message-input').appendChild(createdBr);
            }
            document.getElementById('message-input').appendChild(document.createElement('br'));
            this.placeCaretAtEnd(document.getElementById('message-input'));
          }


        } else if (event.keyCode === 13) {
          event.preventDefault();
          event.returnValue = false;
          let checkMessage = this.message ? this.message.split('<br>').join('') : null;
          checkMessage = this.message ? checkMessage.trim() : null;
          if (this.message && this.message.length && checkMessage && checkMessage.length && !this.closedChatBoxText) {
            this.sendMessage('message', this.message, null, this.channelID);
          }

        }

        if (event.keyCode !== 13 && !event.altKey && !event.shiftKey && !event.ctrlKey) {
          if (this.sendWritingEvent) {
            this.sendWritingEvent = false;
            this.triggeredWritingTimeout = setTimeout(() => {
              this.sendWritingEvent = true
              this.rerender = false;
              this.rerender = true;
            }, 5000);
            this.api.workstation.sendWriting(this.channelID, null, null, this.receiver.id, this.receiver.uuid);
            this.api.workstation.seenMessage(this.channelID, this.receiver.username)
              .then(({data}) => {
                this.seenMessageIsUpdated = true;
              });
          }
        }
      },
      async onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const loadVideo = file => new Promise((resolve, reject) => {
          try {
            let video = document.createElement('video')
            video.preload = 'metadata'

            video.onloadedmetadata = function () {
              resolve({is_video:true, video:this})
            }

            video.onerror = function () {
              resolve({is_video:false, video:{}})
            }

            video.src = window.URL.createObjectURL(file)
          } catch (e) {
            resolve({is_video:false, video:{}})
          }
        })



        let timeStamp = +new Date();


        for (let i = 0; i < files.length; i++) {
          let rand = i +  Math.floor(Math.random() * 1000) + 1;
          timeStamp = timeStamp + rand;
          setTimeout(() => {
            this.scrollDown("message");
          }, 100);

          let video = await loadVideo(files[i])


          this.uploadFileToGoogle(files[i], this.Constants.UPLOAD_TYPES.MESSAGE, this.channelID, timeStamp, this.receiver?.id, this.receiver?.uuid, video.is_video ? video.video.duration : 0)
            .then(result => {
              this.sendMessage('upload', null, result.externalID, result.c_uuid);
              e.target.value = '';
            }).catch(err => {
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            e.target.value = '';

          });
        }

      },
      sendMessage(type, message = null, externalID = null, c_uuid = this.channelID) {
        this.messageBodyMinHeight = 317;
        this.defaultlistenHeight = 60;


        this.firstMessage = false;
        this.openEmojiPicker = false;
        let payload_id = +new Date() + Math.floor(Math.random() * 1000) + 1;
        payload_id = payload_id.toString();

        // asagidaki islemi this.channelChat ' te yapiyoruz
        if (c_uuid === this.channelID)
          this.messages.push({message, u_uuid: this.user.uuid, payload_id});

        this.scrollDown("message");

	      let lastSeenIdForRemove = '';

        this.messageIsseen = null;

        let postProp = {userID:this.receiver.id}

        this.api.workstation.sendMessageV2(type, c_uuid, message, externalID, this.force_bicoin, payload_id,lastSeenIdForRemove, JSON.stringify(postProp))
          .then(({data}) => {
            let result = data;

            if (result.success) {


              if (this.channelID && c_uuid === this.channelID) {
                let temp = [];
                this.messages.forEach(function (message) {
                  if (message.payload_id === result.data.payload_id) {
                   // temp.push(result.data);
                  } else {
                    //  temp.push(message);
                  }
                });

                //   this.messages = temp;
              }



              this.Helper.trackEvents.sendMessage(this.Helper.sendMessageProps("chatDetail", this.receiver));
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.arrangeMessages(payload_id);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.arrangeMessages(payload_id);
          });
        if (type === "upload") {
          this.setCursorToEnd(document.getElementById("message-input"));
        } else {
          document.getElementById('message-input').innerHTML = '';
          this.message = null;
        }
      },
      arrangeMessages(payload_id) {
        let temp = [];
        this.messages.forEach(function (message) {
          if (message.payload_id !== payload_id) {
            temp.push(message);
          }
        });
        this.messages = temp;
      },
      channelChat(data) {

        if(this.channelID === data.c_uuid){
          if(data.type === 'upload' && data.uploads && data.uploads.length > 0){
            let upload = data.uploads[0];
            let newFile = {
              created_at:Date.now(),
              file_type:upload.file_type,
              filename:upload.filename,
              icon_png: upload.icon_png,
              image_url: upload.image_url,
              url: upload.url,
              user_id: data.u_uuid !== this.user.uuid ? parseInt(data.r_id) : parseInt(this.meId)
            };
            this.files.unshift(newFile);
          }

          if(data.type === 'offer' && data.info && data.info.offer && data.info.offer.info){
            let newOffer = {
              created_at:Date.now(),
              id:data.info.offer.id,
              status_text: 'Ödeme Bekleniyor',
              status_text_color: 'rgb(139,149,161)',
              title:' Yeni Teklif',
              total: data.info.offer.info.price + 'TL',
              type: 'offer'
            }
            this.offers.unshift(newOffer);
          }


          if (data.u_uuid !== this.user.uuid) {
            this.onlineStatusAction();
            this.messageIsseen = null;
            this.onWriting = false;
            this.seenMessageIsUpdated = false;

            if (this.hide) { //kullanici focus ise seenmessage yapsin
              this.api.workstation.seenMessage(this.channelID, this.receiver.username)
                .then(({data}) => {
                  this.seenMessageIsUpdated = true;
                });
            }

            const foundMessageIndexFromPayloadId = this.messages.findIndex(x => x.payload_id === data.payload_id);
            if(foundMessageIndexFromPayloadId > -1) { //payload_id'den bulduk o zaman güncelle
              this.messages[foundMessageIndexFromPayloadId] = data;
            } else {
              const foundMessageIndexFromMessageId = this.messages.findIndex(x => x.id === data.id);
              if(foundMessageIndexFromMessageId > -1){ //id'den bulduk o zaman yine güncelle
                this.messages[foundMessageIndexFromMessageId] = data;
              } else { // hiç bulamadık o zaman yaz
                this.messages.push(data);
                this.messageCount = this.messageCount + 1;
                this.playReceivedMessageSound();
              }
            }

            if (data.type === this.Constants.MESSAGE_TYPES.OFFER || data.type === this.Constants.MESSAGE_TYPES.UPLOAD) {
            }
          } else {
            if (data.type === this.Constants.MESSAGE_TYPES.OFFER) {
              this.messages.push(data);
              this.playSentMessageSound();
            } else {

              const foundMessageIndexFromPayloadId = this.messages.findIndex(x => x.payload_id === data.payload_id);
              if(foundMessageIndexFromPayloadId > -1){
                this.messages[foundMessageIndexFromPayloadId] = data;
                this.playSentMessageSound();
              } else {
                const foundMessageIndexFromMessageId = this.messages.findIndex(x => x.id === data.id);
                if(foundMessageIndexFromMessageId > -1){
                  this.messages[foundMessageIndexFromMessageId] = data;
                  this.playSentMessageSound();
                } else {
                  this.messages.push(data);
                }
              }
            }
          }
          if (!this.scrollButton) {
            this.scrollDown("message");
          }
        }

      },
      focusEvent() {
        this.hide = true;
      },
      blurEvent() {
        this.hide = false
      },
      channelChatSeen(data) {
        if (data.u_uuid !== this.user.uuid && this.channelID === data.c_uuid) {
          this.messages.forEach( (message) => {
            message.read_at = 1;
          })
          this.messageIsseen = data.created_at;
          this.onlineStatusAction();
        }
      },
      channelChatWriting(data) {
        if (data.u_uuid !== this.user.uuid && this.channelID === data.c_uuid) {
          this.onWritingType = data.type;
          this.uploadingPercentage = data.percentage;
          this.onlineStatusAction();
          if (!this.onWriting) {
            this.onWriting = true;
            this.listenedWritingTimeout = setTimeout(() => {
              this.onWriting = false;
            }, 6000);
          } else {
            clearTimeout(this.listenedWritingTimeout);
            this.listenedWritingTimeout = setTimeout(() => {
              this.onWriting = false;
              this.onWritingType = null;
              this.uploadingPercentage = 0;
            }, 6000);
          }
        }
      },
      channelChatBlockActions(data) {
        if (data.u_uuid !== this.user.uuid && this.channelID === data.c_uuid) {
          if (data.type === "block") {
            this.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
          } else {
            this.closedChatBoxText = null;
          }
          this.$toasted.global.infoToast({description: data.message});
        }
      },
      channelChatDeleteFile(data){
        if (data.u_uuid !== this.user.uuid && this.channelID === data.c_uuid) {
          this.deleteFileProcess(data.id, data);
        }
      },
      blockUser(receiver) {
        let alert = `${receiver.username} isimli ${this.blocked ? 'kullanıcının' : 'kullanıcıyı'} ${this.blocked ? 'engelini kaldırmak' : 'engellemek'} istediğine emin misin?`;
        if (confirm(alert)) {
          if (this.blocked) {
            this.api.workstation.unblockUser(receiver.uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.blocked = false;
                  this.openSettingBox = false;
                  this.$toasted.global.infoToast({description: result.message});
                  this.chatOpen(this.selectedUser, 0);
                }
              })
          } else {
            this.api.workstation.blockUser(receiver.uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.blocked = true;
                  this.openSettingBox = false;
                  this.$toasted.global.infoToast({description: result.message});
                  this.chatOpen(this.selectedUser, 0);
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
              })
          }
        }
      },
      changeArchiveStatus(username) {
        let status = this.archiveStatus ? 0 : 1;
        this.api.chat.changeArchiveStatus(username, status)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.openSettingBox = false;
              this.$toasted.global.infoToast({description: result.message});
              this.archiveStatus = status;
              if(this.$store.state.chatUserActiveTitle !== 'deleted'){
                this.$store.state.chatUsers = this.$store.state.chatUsers.filter(({ r_username }) => r_username !== username)
              } else {
                this.archivedConversations = this.archivedConversations.filter(({ r_username }) => r_username !== username)
              }

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
      },
      inputEvent(event) {
        if (event.which === 13 && this.searchTerm) {
          this.searchingMode = true;
          this.getConversationsV2();
        }
        if(this.searchTerm.length === 0){
          this.searchingMode = false;
          this.getConversationsV2();
        }
      },
      closeEvent() {
        this.openSettingBox = false;
      },
      initializeMethods() {
        this.selectedUser = this.$store.state.routerParams[0];
        this.$store.state.openConversations = [];
        if(this.$store.state.chatUserActiveTitle !== 'all'){
          this.$store.state.chatUserActiveTitle = 'all';
          this.$store.state.chatUsersOffset = 0;
          this.$store.state.chatUsersSeemoreDiv = false
          this.getConversationsV2('all', false);
        }
        this.chatOpen(this.selectedUser);
      },
      setInputMessage(event) {
        this.message = event.target.innerHTML;
        if (event.target.clientHeight > this.defaultlistenHeight) {
          if (this.messageBodyMinHeight < 410) {
            this.messageBodyMinHeight += (event.target.clientHeight - this.defaultlistenHeight);
          }
        } else {
          this.messageBodyMinHeight -= (this.defaultlistenHeight - event.target.clientHeight);
        }
        this.defaultlistenHeight = event.target.clientHeight;
      },
      scrollDown(type, scrollButton = false) {
        if (type === "message") {
          this.$nextTick(function () {
            let element = document.getElementsByClassName("messages-body");
            if (element && element.length)
              element[0].scrollTop = element[0].scrollHeight;
            if (scrollButton) {
              this.messageCount = 0;
            }
          });
        }
      },
      scrollEvent(e) {
        let height = e.target.scrollHeight - e.target.offsetHeight;
        let scrollPosition = e.target.scrollTop;
        if (height - scrollPosition > 100) {
          this.scrollButton = true;
        } else {
          this.scrollButton = false;
          this.messageCount = 0;
        }
      },
      firstMessageActionButtons(uuid, type, message = null) {
        this.api.chat.reportConversation(uuid, type, message)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if (type === 1 || type === 2) {
                this.routeBack('/', false)
              }
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
        this.firstMessage = false;
      },
      report(type, external_id, report_type_id) {
        this.$store.commit(this.types.ACTIVE_MODAL, {
          modalType: this.Modals.COMPLAINT,
          info: {type: type, externalId: external_id, reportTypeId: report_type_id}
        });
      },
      convertAverageTime(time) {
        let timeStamp = time;
        let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
        if (years >= 1) {
          return years.toFixed(0) + " yıl"
        } else {
          let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
          if (months >= 1) {
            return months.toFixed(0) + " ay";
          } else {
            let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
            if (weeks >= 1) {
              return weeks.toFixed(0) + " hafta";
            } else {
              let days = timeStamp / 1000 / 60 / 60 / 24;
              if (days >= 1) {
                return days.toFixed(0) + " gün";
              } else {
                let hours = timeStamp / 1000 / 60 / 60;
                if (hours >= 1) {
                  return hours.toFixed(0) + " saat"
                } else {
                  let minutes = timeStamp / 1000 / 60;
                  if (minutes >= 1) {
                    return minutes.toFixed(0) + " dakika";
                  } else {
                    let seconds = timeStamp / 1000;
                    return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
                  }
                }
              }
            }
          }
        }
      },
      goToContentItem(item) {
        let id = item.id;
        let type = item.type;

        if (type === "order") {
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ORDER_INFO, info: id});
        } else if (type === "offer") {
          this.api.seller.getOfferDetail(id)
            .then(({data}) => {
              let result = data;

              if (result.success) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: result.data.offerDetail}});

              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },
      pasteEventListener(e) {
        e.preventDefault();
        let text;
        if (e.clipboardData || e.originalEvent.clipboardData) {
          text = (e.originalEvent || e).clipboardData.getData('text/plain');
        } else if (window.clipboardData) {
          text = window.clipboardData.getData('Text');
        }
        if (document.queryCommandSupported('insertHTML')) {

          document.execCommand('insertHTML', false, text);
        } else {
          document.execCommand('paste', false, text);
        }
      },
      addEmoji(emoji) {
        this.message = (this.message === null ? '' : this.message) + emoji.data;
        const ele = document.getElementById('message-input');
        ele.innerHTML = ele.innerHTML + emoji.data;
        this.setCursorToEnd(ele);
      },
      pageClickHandler(event) {
        if (this.openEmojiPicker && !event.target.className.includes('emoji-img') && !event.target.parentNode.className.includes('emoji')) {
          if (document.getElementById('message-input'))
            this.setCursorToEnd(document.getElementById('message-input'));
          this.openEmojiPicker = false;
        }
      },
      chatBoxClick(event) {
        this.rerender = false;
        this.rerender = true;
        //mouse click birakildiginda secilmis text var ise focus yapmayacak
        let highlightedText = "";
        if (window.getSelection) {
          highlightedText = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
          highlightedText = document.selection.createRange().text;
        }

        let hasClassEmojiImg = Array.from(event.target.classList).indexOf('emoji-img') > -1;
        let hasClassEmoji = Array.from(event.target.classList).indexOf('emoji') > -1;
        if (highlightedText.length === 0 && (!hasClassEmojiImg && !hasClassEmoji && event.target.className !== 'input') ) {

          if (document.getElementById('message-input')){
            document.getElementById('message-input').focus();
          }
          this.openEmojiPicker = false;
        }
      },
      setCursorToEnd(ele) {
        let range = document.createRange();
        let sel = window.getSelection();
        if (ele.innerHTML.length > 0) range.setStart(ele, 1);
        else range.setStart(ele, 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        ele.focus();
      },
      onlineStatusAction() {
        this.receiver.onlineStatus = true;
        this.receiver.lastseen = Date.now();
      },
      deleteFileProcess(id, data){
        const index = this.messages.findIndex(m => m.id === id);
        if (index !== -1) {
          this.messages[index].hide_message = data.hide_message;
          this.messages[index].hide_message_description = data.hide_message_description;
          this.messages[index].hide_message_text = data.hide_message_text;
        }

        let fileurl = this.messages[index].uploads[0].url;
        const fileIndex = this.files.findIndex(m => m.url === fileurl);
        this.files.splice(fileIndex, 1);
      },
      deleteUploadedFile(message) {
        this.deleteFileLoader = true;
        const id = message.id;
        this.api.chat.deleteMessage(id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.deleteFileProcess(id, result.data);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.deleteFileLoader = false;
          })
          .catch(err => {
            console.log(err);
            this.deleteFileLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
    },
    watch: {
      hide(newValue) {
        if (newValue && !this.seenMessageIsUpdated) {
	        if(this.receiver && this.receiver.username){
            this.api.workstation.seenMessage(this.channelID, this.receiver.username)
              .then(({data}) => {
                this.seenMessageIsUpdated = true;
              });
	        }
        }
      },
      'percentCompleted.temp_icon_png': function (newValue, oldValue) {
        this.tempIcons = newValue;
      },
      'percentCompleted.fileNames': function (newValue, oldValue) {
        this.fileNames = newValue;
      }
    },
    computed: {
      chatUsers() {
        let dummy = this.rerender;
        let dummy2 = this.$store.state.chatUsers;
        return this.searchingMode ? this.searchedConversations : this.$store.state.chatUserActiveTitle === 'deleted' ? this.archivedConversations : this.$store.state.chatUsers;
      },
    },
    created() {
      this.meId = this.user.id;
      this.$store.state.activeConversationId = null;
      this.receiver.username = this.$store.state.routerParams[0];
      this.initializeMethods();

      this.EventBus.$off('bindPusherChatDetail');

      this.EventBus.$on('bindPusherChatDetail', payload => {
        if(payload.payloadType === 'new'){
          this.channelChat(payload);
        } else if(payload.payloadType === 'writing'){
          this.channelChatWriting(payload)
        } else if(payload.payloadType === 'chat_seen'){
          this.channelChatSeen(payload)
        } else if(payload.payloadType === 'blockActions'){
          this.channelChatBlockActions(payload)
        } else if(payload.payloadType === 'delete'){
          this.channelChatDeleteFile(payload)
        }

        this.rerender = false;
        this.rerender = true;
      });

    },
    destroyed() {
      let chatuser_index = this.$store.state.chatUsers.findIndex(m => m.r_username === this.selectedUser);
      if(chatuser_index > -1){
        let conversationSendData = this.$store.state.chatUsers[chatuser_index]
        conversationSendData.fromWhere = 'Chat Detail Exit Auto'
        this.EventBus.$emit('externalOpenChat', conversationSendData);
      }
    },
    beforeDestroy() {
      const element = document.querySelector("#global-message-input");
      if (element) {
        element.removeEventListener("paste", this.pasteEventListener);
      }
    }
  }

</script>

<style scoped lang="scss">


.file-row{
  padding:15px 10px 15px 15px;
  border-bottom: 1px solid rgba(234, 237, 242, 0.9);
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 41px;
  }

  .file-name-container{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    justify-content: center;
    width: 180px;
    .file-name{
      font-size: 13px;
      font-weight: 500;
      color: #2d3640;
      height: 20px;
      text-overflow: ellipsis;
      white-space: pre;
      overflow: hidden;

      &:hover{
        text-decoration: underline;
      }

    }
    .file-info{
      font-size: 10px;
      color: #969fac;
    }
  }


  .download-icon-container-files{
    margin-left: 15px;

    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }
  .download-icon-files /deep/ {

    width: 18px;
    height: 16px;
    path {
      fill: #8b95a1;
    }
  }
}

.info-icon-container {
  display: inline-block;
  cursor: help;

  margin-right: 8px;
  margin-top: 4px;


  .info-icon /deep/{
    width: 16px!important;
    height: 16px;
    path {
      fill: #8b95a1;
    }
  }
}


.nav-list{
  width: 100%;
  margin-top: 5px;


  .side-nav-icons /deep/ {
    width: 18px!important;
    height: 18px;
    path {
      opacity: 0.8;
      fill: #8b95a1;
    }
  }
  .side-nav-icons-active /deep/ {
    width: 18px!important;
    height: 18px;
    path {
      fill: #fd4056;
    }
  }

  .side-nav-icons-arrow /deep/ {
    width: 14px!important;
    height: 14px;
    path {
      fill: #BFC8D2;
      opacity: 0.8;
    }
  }


  .side-nav-icons-arrow-back /deep/ {
    width: 14px!important;
    height: 14px;
    path {
      fill: #5e6b79;
      opacity: 0.8;
    }
  }



  li{
    cursor: pointer;
    height: 50px;
    border-bottom: 1px solid rgba(234, 237, 242, 0.9);
    .li-inside{
      height:50px;position:relative; display: flex;
      justify-content: space-between; align-items: center;  margin-left: 15px;margin-right: 10px;
    }
    .li-text{
      font-size:14px;
      position: absolute; margin-top: 1px;
      margin-left: 30px;
      &.activet{
        color: #fd4056;
        &:hover{
          color: #fd4056!important;
        }
      }
    }

    .li-number{
      text-align: center;
      background-color: #fd4056;
      padding:2px 3px 3px;
      min-width: 10px;
      border-radius:3px;font-size: 12px; font-weight: 600;
      color: #fff!important;  margin-right: 4px;
    }
  }

}



.playIcon {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  border: 1px solid white;
  background-color: white;
  border-radius: 50%;
  img {
    height: 35px;
    width: 35px;
  }
  &:hover{
    opacity: 0.9;
  }
}

.image-u-div{
  display: flex; align-items: center; justify-content: center; cursor: pointer;

  .si-icon-container{
    position: absolute;
    background-color: #2d3640;
    width: 32px;
    height: 32px;
    top:3px;
    right:3px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .si-icon /deep/ {

      width: 18px;
      height: 18px;

      path {
        fill: #fff;
      }
    }
  }

  &:hover{
    .si-icon-container{
      background-color: #f2b46d;
    }
  }
}


.download-icon-container{

  display: flex;
  align-items: center;
  height: 13px;
  cursor: pointer;
  color: #2d3640;
  letter-spacing: -0.5px;

}
.download-icon /deep/ {

  width: 13px;
  height: 13px;
  margin-right: 5px;
  path {
    fill: #2d3640;
  }
}

.uploading-div{
  width: 430px;
  margin: 0 0 20px;
  padding: 25px 20px 25px 25px;
  border-radius: 10px 10px 0 10px;
  border: solid 1px rgba(43, 181, 215, 0.2);
  background-color: rgba(101, 196, 219, 0.2);

  display: flex;
  align-items: center;

  .file-name-p{

    line-height: 1.29;
    letter-spacing: -0.5px;
    color: #2d3640;
    width: 270px;
    font-size: 14px;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden; white-space: pre;


  }
}


.freelancer-line-wrapper{
  margin-top: 26px;
  text-align: left;
  //border-bottom: solid 1px #eaedf2;
  padding-bottom: 15px;
  .info{
    display: flex;
    justify-content: space-between;
    .text-wrapper{
      font-size: 14px;
      display: flex;
      .text-general{
        margin: 0 10px;
        color: #8b95a1;
        font-weight: 300;
      }
    }
  }
}

.question-mark-wrapper {
  cursor: help;
  font-size: 12px;
  color: #2d3640;
  font-weight: 300;
  .question-mark-icon /deep/ {
    width: 16px;
    height: 16px;
    path {
      fill: #8b95a1;
    }
  }
}

.info-icons /deep/{
  height: 18px;
  width: 18px;
  path {
    fill: #bfc8d2;
  }
}

.new-titles {
  background: #fff;
  align-items: center;
  justify-content: center;
  display:flex;
  width: 100%;
  height: 46px;
  .new-titles-box {
    cursor: pointer;
    height: 30px;
    width: 31%;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    .new-titles-text{
      font-weight: 500; font-size:13px;
    }
  }
}


  .chat-where-from-info{

    margin-bottom: 10px;
    margin-left: -22px;
    margin-right: -15px;

    .up-text{
      color: #939c9c;
      font-size: 13px;
    }

    .where-from-box{
      margin-top: 8px;
      border: solid 1px rgba(184, 234, 187, 0.4);
      background-color: rgba(38, 202, 154, 0.2);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      &:hover{
        border: solid 1px rgba(184, 234, 187, 0.8);
        background-color: rgba(38, 202, 154, 0.3);
      }
      .icon-request-container{
        margin-left: 10px;
        .icon-request /deep/{
          width: 20px;
          height: 20px;
          path {
            fill: #26ca9a;
          }
        }
      }

      .where-from-title{
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        color: #2d3640;
        margin-left: 10px;
        margin-right: 10px;

      }
    }
  }


  .settings {
    overflow-x: hidden;

  }

  [draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }

  /*drag and drop div*/
  #drag-drop {
    position: absolute;
    max-height: calc(100vh - 176px);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    top: 100vh;
    font-size: 18px;
    color: rgb(230, 50, 98);
    font-weight: 500;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 100;
      text-transform: uppercase;
      width: 80%;
      height: 60%;
      border: 2px dashed rgb(230, 50, 98);
    }
  }

  .pageContainer {
    background-color: #ffffff !important;
    display: flex;
    max-width: 1170px;
    width: 100% !important;
  }

  .fixed-div {
    position: fixed;
    display: flex;
    flex-direction: row;
    max-width: 1170px;
    width: 100vw;
    height: 100%;
    margin: auto;
  }

  .chat-list-body {
    left: 200px;
    flex: 1;
    border-left: 1px solid #eaedf2;
    background: #f4f5f7;
  }

  .chat-list-search {
    width: calc(100% - 79px);
    height: 20px;
    border: solid 1px #eaedf2;
    margin: 17px 13px 14px 10px;
    padding-left: 45px;
    background: #ffffff url('https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg') no-repeat;
    background-position-x: 14px;
    background-position-y: 12px;
  }

  .title-body {
    display: flex;
    justify-content: center;
    padding-top: 17px;
  }

  .active {
    margin-bottom: -3px;
    border-bottom: 3px solid #242424;
    color: #242424;
  }

  .chat-row-body {
    margin-top: 0px;
    max-height: calc(100vh - 290px);
    overflow-y: auto;
    //padding-right: 1px;
    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dde3eb;
    }

    .loader {
      text-align: center;
      width: 20px;
      height: 20px;
    }

    .chat-row {
      position: relative;
      cursor: pointer;
      height: 72px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #eaedf2;


      .isseen-div {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e63262;
        margin-left: 13px;
      }

      .isseen-empty-div {
        width: 23px;
      }
    }
  }

  .avatar-main {
    position: relative;
    width: 46px;
    height: 46px;
    margin-left: 7px;

    .avatar-image {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
  }


.icon-message-seen-container{
  margin-left: 4px;
  .icon-message-seen /deep/{
    width: 15px;
    height: 15px;
    path{
      fill: #8b95a1;
    }
  }

  .icon-message-seen-green /deep/{
    width: 15px;
    height: 15px;
    path{
      fill: #26ca9a;
      stroke: #26ca9a;
    }
  }
}


.icon-seen-container{
  margin-top: 2px;
  .icon-seen /deep/{
    width: 14px;
    height: 14px;
    path{
      fill: #8b95a1;
    }
  }

  .icon-seen-green /deep/{
    width: 14px;
    height: 14px;
    path{
      fill: #26ca9a;
      stroke: #26ca9a;
    }
  }
}

.user-name-main {
    margin-left: 10px;

    .user-name {
      font-size: 14px;
      color: #2d3640;
    }


    .user-last-message {
      font-size: 13px;
      color: #8b95a1;

      width: 215px;
      overflow: hidden;
      height: 16px;

      text-overflow: ellipsis;
      white-space: pre;
      font-weight: 300
    }
  }

.last-seen-div {
    font-size: 11px;
    text-align: right;
    color: #8b95a1;
    top:10px;
    right: 10px;
    position: absolute;
  }

.seemore-div {
    cursor: pointer;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

.seemore-div:hover {
  text-decoration: underline;
}

  /*mesaj detay*/

  .chattarih {
    text-align: left;
    border-bottom: solid 1px #dde3eb;
    line-height: 0.1em;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .chattarih span {
    background: #fff;
    padding: 0 15px;
    font-size: 14px;

    color: #8b95a1;
  }

  .seemore {
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    color: #2d3640;
    font-size: 13px;
  }

  .seen {
    margin-top: 15px;
    float: right;
    font-size: 12px;
    color: #242424;
  }

  .chatsend {
    position: absolute;
    z-index: 11;
    margin-left: 555px;
    height: 36px;
    margin-top: 5px;
    border-radius: 2px;
    margin-bottom: 0;
  }

  .horizontal-seperator {
    width: 0;
    border-left: 1px solid #eaedf2;
  }

  .message-detail-body {
    width: 528px;
    flex: 2.4;

    .message-detail-header {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 27px 17px 27px;
      border-right: solid 1px #dfe4ec;
      border-bottom: solid 1px #dfe4ec;
    }
  }

  .icon-div {
    display: flex;
    align-items: center;

    .chatbox-icons {
      cursor: pointer;
      margin-left: 25px;
    }
  }

  .setting-box {
    position: absolute;
    width: 112px;
    padding: 18px 10px 10px 19px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
    right: -19px;
    top: 43px;
    z-index: 10;

    .setting-box-options {
      cursor: pointer;
      font-size: 14px;
      line-height: 2.29;
      color: #737878;

      &:hover {
        color: #2d3740;
      }
    }
  }

  .setting-box::after {
    content: '';
    position: absolute;
    right: 17px;
    top: -10.8px;
    width: 20px;
    height: 20px;
    border: 1px solid #bfc8d2;
    border-bottom: 0;
    border-right: 0;
    border-radius: 3px;
    transform: rotate(45deg);
    background-color: #fff;
  }

  .message-detail-username {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #242424;
  }

  .seemorediv {
    text-align: center;
    padding-bottom: 14px;
  }

  .messages-body {
    padding: 18px 21px 18px 27px;
    max-height: calc(100vh - 317px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eaedf2;
    }
  }

  .messages-main {
    position: relative;
    margin-top: 10px;
  }

  .typeOrder {
    height: 90px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    background-color: #d7f8f5;
  }

  .order-button {
    width: 120px;
    height: 36px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    line-height: 36px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #2d3640;
    margin-right: 20px;
  }

  .chatbubble.right {
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: rgba(163, 224, 225,0.5);
    float: right;
    border-radius: 7.5px;
    border-bottom-right-radius: 0;
  }

  .chatbubble.left {
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #e4e8ed;
    float: left;
    border-radius: 7.5px;
    border-bottom-left-radius: 0;
  }

  .chatbubble {
    position: relative;
    max-width: 420px;
    width: auto;
    display: inline-block;
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.29;
    padding: 7px 17px 7px;
  }

  .chatbuble-text {
    font-size: 13px;
    color: #2d3740;

    a {
      color: #2d3740;
    }
  }

  .chattime {
    margin-bottom: 10px;
  }

  .chattime.right {
    position: relative;
    float: right;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-right: 5px;
  }

  .chattime.left {
    position: relative;
    float: left;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-left: 50px;
  }

  .loading-div {
    font-size: 15px;
    margin-bottom: -17px;
  }

  .loading-div:after {
    display: inline-block;
    animation: dotty steps(1, end) 1.5s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }

  /*input*/

.input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    margin-left: 21px;
    margin-top: 22px;

    color: #afb8b8;
    pointer-events: none;
    transition: opacity .08s linear;
    z-index: 100;
    font-size: 14px;
    line-height: 20px;

  }

div.input {
    word-wrap: break-word;
    white-space: pre-wrap;
    min-height: 34px;
    max-height: 122px;
    background-color: #FFF;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    resize: none;
    padding: 16px 41px 10px 21px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #2d3640;
    position: relative;
    z-index: 1;
    max-width: 470px
  }

div.input:focus {
    outline: none;
  }

.attachment-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .attachment-text {
        color: #2d3640;

      }

      .attachment-icon {
        background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment_hover.svg') no-repeat;
        background-size: contain;
      }
    }

  }

.attachment-icon {
    position: relative;
    width: 20px;
    height: 22px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment.svg') no-repeat;
    background-size: contain;

  }

.attachment-text {
    line-height: 17px;
    padding-left: 13px;
    font-size: 12px;
    font-weight: 600;
    color: #8b95a1;
  }

.offer-icon-container {
    display: flex;

    cursor: pointer;
    margin-left: 20px;

    &:hover {
      .attachment-text {
        color: #2d3640;

      }

      .offer-icon {
        background-image: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer_hover.svg');
      }
    }

  }

.offer-icon {
    position: relative;
    cursor: pointer;
    width: 18px;
    height: 20px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer.svg') no-repeat;
    background-size: contain;
  }

/*info css*/
.info-bar {
    height: 32px;
    color: #2d3640;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffefda;
  }

  /*firstmessage css*/

.first-message-body {
    border-top: 1px solid #bfc8d2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;

    p {

    }

    img {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

.first-message-button-div {
    display: flex;
    margin-top: 15px;

    .first-message-box {
      padding: 10px;
      background-color: #fff;
      cursor: pointer;
      margin-right: 10px;
      border: 1px solid #bfc8d2;
      border-radius: 2px;

      font-size: 14px;
      font-weight: 500;
      color: #2d3640;
    }
  }

.first-message-header {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

.chat-welcome-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 156px;
    background-color: #f4f5f7;
    padding: 20px 30px;
    margin-left: -30px;
    margin-right: -21px;
    margin-top: -20px;
  }

.chat-welcome-message-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 34px;
      height: 34px;
      border-radius: 100px;
    }

    .welcome-message-user-info-username {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #242424;
      margin-bottom: 2px;
      margin-top: 5px;
    }
  }

.chat-welcome-message-content {
    font-size: 14px;
    line-height: 1.29;

    color: #8b95a1;
    margin-top: 15px;
    margin-bottom: 15px;

    background-color: white;
    text-align: center;
    padding: 12px;
    border-radius: 20px;
  }

.chat-welcome-message-time-info {
    display: flex;
    align-items: center;

    .welcome-seperator {
      height: 17px;
      border-left: solid 1px #bfc8d2;
      margin-right: 10px;
      margin-left: 10px;
    }

    .chat-welcome-message-time-info-inner {
      display: flex;
      align-items: center;

      p {
        font-size: 14px;
        line-height: 1;
        color: #8b95a1;
        margin-left: 6px;
      }

      span {
        font-size: 14px;
        line-height: 22px !important;
        font-weight: 500;
        color: #333333;
        margin-left: 6px;
      }
    }
  }

  /*user-action */
.chat-action-detail-body {
    width: 290px;
    max-width: 290px;
    min-width: 290px;
    //background-color: #f4f5f7;
    background-color: #FBFCFC;

    border-right: solid 1px #eaedf2;
    border-left: solid 1px #eaedf2;

    max-height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eaedf2;
    }
  }

.action-user-information {
    padding: 9px 0 9px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-bottom: solid 1px #e0e4e9;
  }

.action-user-information-avatar {
    position: relative;
    width: 44px;

    img {
      height: 44px;
      width: 44px;
      border-radius: 50%;
    }
  }

.action-user-information-lastseen-username {
   margin-top:2px;

    .action-user-information-username {
      font-size: 14px;
      font-weight: 500;
      color: #242424;
    }

    .action-user-information-lastseen {
      font-size: 12px;
      color: #8b95a1;

      margin-top: 2px;
    }
  }

.action-user-attachment {
    padding: 20px 10px 20px 20px;
    height: 150px;
    max-height: 150px;
    border-bottom: solid 1px #eaedf2;
    background-color: #fff;

    .action-user-attachment-list-body {
      height: 137px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
    }
  }

.action-user-attachment-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;

    .attachment-list-filename {
      overflow: hidden;
      width: 150px;
      height: 18px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .attachment-list-createdAt {
      font-size: 12px;
      text-align: right;
      color: #939c9c;
    }

  }

.action-user-tabbars {
    padding-bottom: 3px;
    border-bottom: solid 1px #eaedf2;
    background-color: #fff;
  }

.action-user-tab-content {
    padding: 10px 10px 10px 5px;
    background-color: #FBFCFC;

  }

.tab-content-array-div {

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    min-height: 110px;
    height: calc(100vh - 530px);

    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }
  }

.tab-content-array-item {
    padding: 17px 8px 12px 16px;
    width: 231px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    margin-top: 5px;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background-color: #f2f2f2;

    }

    .content-name-price {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: #2d3640;
      }

      .content-price {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.12;
        color: #242424;
      }

    }

    .content-status {
      font-size: 13px;
      font-style: italic;
      line-height: 1.38;
      margin-top: 1px;
    }

    .content-time {
      font-size: 13px;
      line-height: 1.38;
      color: #8b95a1;
      margin-top: 6px;
    }
  }

.right-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: #fff;
    float: right;
    border-radius: 5px;
    border: solid 1px #e4e8ed;
  }

.left-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #fff;
    float: left;
    border: solid 1px #e4e8ed;
    border-radius: 5px;
  }

.iscall-callback-div {
    background-color: rgb(230, 50, 98);
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    margin: 0 -15px -8px -15px;
    margin-top: 10px;
    cursor: pointer;
  }

.iscall-body {
    display: flex;
    flex-direction: row;

    .iscall-phone-icon {
      margin-right: 8px;
    }

    .iscall-username {
      margin-bottom: 5px;
    }

    .iscall-created-date {
      color: rgb(147, 156, 156);
    }
  }

.scroll-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: solid 1px #bfc8d2;
    position: absolute;
    right: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    z-index: 10;

    .scroll-button-message-count {
      position: absolute;
      top: -5px;
      left: -3px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: rgb(230, 50, 98);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 15px;
      font-weight: 600;
    }
  }

/*loader part*/
@keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

.chat-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

.action-user-information-avatar-loader {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #dfe4ec;
  }

.action-user-information-username-loader {
    width: 60px;
    height: 14px;
    background-color: #dfe4ec;
  }

.action-user-information-lastseen-loader {
    width: 100px;
    height: 12px;
    margin-top: 2px;
    background-color: #dfe4ec;
  }

.action-user-attachment-list-file-loader {
    width: 150px;
    height: 14px;
    margin-bottom: 12px;
    background-color: #dfe4ec;
  }

.content-name-loader {
    width: 140px;
    height: 14px;
    margin-bottom: 12px;
    background-color: #dfe4ec;
  }

.content-price-loader {
  width: 40px;
  height: 16px;
  margin-bottom: 12px;
  background-color: #b8bdc5;
}

.content-status-loader {
  width: 60px;
  height: 16px;
  margin-bottom: 12px;
  background-color: #dfe4ec;
}

.content-time-loader {
  width: 100px;
  height: 10px;
  margin-bottom: 12px;
  background-color: #dfe4ec;
}

.messages-body-avatar-loader {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #dfe4ec;
  margin-right: 10px;
}

.messages-body-message-loader {
  width: 200px;
  height: 16px;
  margin-bottom: 10px;
  background-color: #dfe4ec;
}

.messages-body-time-loader {
  width: 60px;
  height: 10px;
  background-color: #dfe4ec;
}

.emoji-img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  z-index: 2;
  background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon.svg');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;

  &:hover {
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg');
    width: 22px;
    height: 22px;
    right: 14px;
    top: 9px;
  }
}

.emoji-img-active {
  background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg') !important;
}

.emoji-picker {
  position: absolute;
  left: 228px;
  top: -272px;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
}

.button-badge {
  background: #fd4056;
  border-radius: 50%;
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  width: 17px;
  height: 17px;
  line-height: 14px;
  text-align: center;
  margin-left: 8px;
}

.delete-uploaded-file {
  z-index: 3;
  box-sizing: border-box;
  position: absolute;
  background-color: #2D3640;
  border: 2px solid #d7ebff;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -12px;
  right: -12px;
  cursor: pointer;

  .delete-uploaded-file-img /deep/ {
    width: 9px;
    height: 12px;

    path {
      fill: #fff;
    }
  }
}
</style>
