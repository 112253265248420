<template>
  <div class="fixed-content">
    <div style="display: flex; justify-content: end">
      <img src="https://gcdn.bionluk.com/site/cicons/close_circle.svg" style="cursor: pointer" @click.stop="handleClose" />
    </div>
    <div class="mt-3" style="text-align: center;">
      <img style="width: 90px; height: 60px" src="https://gcdn.bionluk.com/site/images/buyer_service_cover.png" />
      <div class="title">
       Son siparişinden memnun kaldın mı?
      </div>
      <div class="description" style="width: 312px; margin-top: 14px">
        Hey! Görüşlerini paylaşman için kısa bir form hazırladık. Hadi <span style="font-weight: 600; color: #00a575">tık tık!</span>
      </div>
    </div>
  </div>
</template>

<script>

import Api from "@/utils/request";

export default {
  name: 'SurveyModal',
  props: {
    uuid: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleClose() {
      Api.get(`/users/surveys/${this.uuid}`)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-content {
  width: 312px;
  min-height: 168px;
  right: 24px;
  bottom: 24px;
  position:fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(45, 54, 64, 0.05);
  border-radius: 12px;
  padding: 24px;
  color: #353C48;
  cursor: pointer;
}
.title {
  width: 240px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 12px auto;
}
.description {
  width: 256px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto;
}
</style>
