<template>
  <div style="background: white; z-index: 0">
    <div :class="$store.state.route.fullPath === '/bugun' ? 'feed-sidebar-u' : 'feed-sidebar-p'">
      <div class="nav">
        <div class="nav-menutext"></div>
        <div class="nav-items" v-if="isLoggedIn">
          <ul class="nav-list">
            <li>
              <router-link :to = "'/bugun'" @click.native="scrollTopOfToPage()" active-class="active" exact style="position:relative" >
                <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Bionluk</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-bia.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>

            <li>
              <router-link :to = "'/panel/siparisler'" active-class="active">
                <p style="position: absolute;  margin-top: 1px; margin-left: 35px;">Siparişlerim</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-basket.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>


            <li>
              <router-link :to = "'/panel/istekler'" active-class="active">
                <p style="position: absolute; margin-top: 1px;  margin-left: 35px;">Özel Alıcı İsteklerim</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-special-offer-filled.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>

            <li>
              <router-link :to = "'/panel/listelerim'" active-class="active">
                <p style="position: absolute; margin-top: 2px; margin-left: 35px;">Listelerim <span style="display: inline-block;"><span class="is-new"><span class="is-new-t">Yeni</span></span></span></p>
                <img src="https://gcdn.bionluk.com/uploads/message/24766577-5746-4a3e-bdfb-24fa75a63c7c.svg" onload="SVGInject(this)" class="side-nav-icons">
                <span v-show="user.likeCount" class="nav_likeCount oval-number">{{ user.likeCount }}</span>
              </router-link>
            </li>
            <li v-if="user.seller">
              <router-link :to = "'/panel/cuzdan'" active-class="active">
                <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Bakiyem</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-finance.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>
            <li v-else>
              <router-link :to = "'/panel/cuzdan'" active-class="active">
                <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Finans</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-finance.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>
          </ul>
          <div class="hiddendiv20"></div>

          <div class="nav-menutext">FREELANCER</div>
          <div style="height: 5px"></div>
          <template v-if="user.seller.isSeller">
            <ul class="nav-list">

              <li>
                <router-link :to = "'/destek/topluluk-kurallari-105'" active-class="active">
                  <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Topluluk Kuralları</p>
                  <img src="https://gcdn.bionluk.com/site/c/ic-myprofile.svg" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>


              <li v-if="user.seller.gigCount">
                <router-link :to = "'/panel/alici-istekleri'" active-class="active">
                  <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Bana Uygun Alıcı İstekleri</p>
                  <img :src="$root.CDN_SITE + 'cicons/ic-special-offer-empty.svg'" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>




              <li>
                <router-link :to = "'/panel/satislar'" active-class="active">
                  <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Satışlarım</p>
                  <img :src="$root.CDN_SITE + 'cicons/ic-my-sales.svg'" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>

              <li>
                <router-link :to = "'/panel/ilanlar'" active-class="active">
                  <p style="position: absolute; margin-top: 1px; margin-left: 35px;">İlanlarım</p>
                  <img :src="$root.CDN_SITE + 'cicons/ic-my-gigs.svg'" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>

              <li>
                <router-link :to = "'/panel/portfolyo'" active-class="active">
                  <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Portfolyom</p>
                  <img :src="$root.CDN_SITE + 'cicons/ic-my-portfolio.svg'" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>

              <li>
                <router-link :to = "'/panel/bicoin'" active-class="active" style="position:relative">
                  <p style="position: absolute;  margin-left: 35px;">bicoin Hesabım</p>
                  <img :src="$root.CDN_SITE + 'cicons/ic-bicoinn.svg'" onload="SVGInject(this)" class="side-nav-icons">
                </router-link>
              </li>

            </ul>
          </template>

          <ul v-else class="nav-list">
            <li>
              <router-link :to = "'/freelancer-olmak'" active-class="active" style="position:relative">
                <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Freelancer Ol</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-freelancer.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>
          </ul>

        </div>
        <div v-else>
          <ul class="nav-list">
            <li>
              <router-link :to = "'/bugun'" @click.native="scrollTopOfToPage()" active-class="active" exact style="position:relative" >
                <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Bionluk</p>
                <img :src="$root.CDN_SITE + 'cicons/ic-bia.svg'" onload="SVGInject(this)" class="side-nav-icons">
              </router-link>
            </li>
          </ul>
        </div>
        <div  class="nav-left-footer" style="margin-top: 30px;">
          <footer-nav></footer-nav>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import FooterNav from "../../components/shared/navFooter";
  import WorkstationNav from "../../body/workstation/navComponent";

  export default {
    name: "src-pages-body-feed-navComponent",
    components: {FooterNav,WorkstationNav},
    props: ["activeLink"],
    data() {
      return {
        feedFollowing: {
          path: "/feed/following",
          activeClass: (this.activeLink === "feed_following" ? "active" : null)
        },
        feedNotifications: {
          path: "/feed/notifications",
          activeClass: (this.activeLink === "feed_notifications" ? "active" : null)
        },
        messageRedirectUserName: "/panel/mesajlar"
      };
    },
    methods: {
      scrollTopOfToPage() {
        clearInterval(this.interval);
        let pos = window.scrollY;
        let temp = window.scrollY;
        this.interval = setInterval(() => {

          if (pos <= 0) {
            clearInterval(this.interval);
          } else {
            if(window.scrollY > pos || window.scrollY < pos) {
              clearInterval(this.interval);
            } else {
              if (temp - pos > 5000) {
                window.scrollTo(0, 0);
              } else {
                pos = pos - 100; // bu sayı artarsa daha hızlı scroll olacaktır.
                window.scrollTo(0, pos);
              }
            }
          }
        }, 10);
      },

      goToMessages() {
        this.api.workstation.getConversations('all', '', 1, 0, "navComponent")
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data.conversations.length !== 0) {
                this.messageRedirectUserName = "/chat/" +result.data.conversations[0].r_username;
              } else {
                this.messageRedirectUserName = "/panel/mesajlar"
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    created() {
     // this.goToMessages();
    }
  }

</script>

<style scoped lang="scss">

.is-new{
  width: 35px;
  height: 16px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #fd4056;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  display: flex;
  .is-new-t{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;
    color: #fff;
  }
}

  .oval-number{
    &:hover{
      color: #FFF;
    }
  }


  .nav-list{
    padding-left: 0px;
    svg{
      width: 20px;
      height: 20px;
    }
    .side-nav-icons /deep/ {

      path {
        width: 14px!important;
        height: 14px;
        fill: #BFC8D2;
      }
    }
    .active{
      .side-nav-icons /deep/ {

        path {
          width: 14px!important;
          height: 14px;
          fill: #fd4056;
        }
      }
    }


    li{
      height: 50px;
    }
  }

  .nav-list-item-detail{
    display: flex;
    align-items: center;
  }

  .nav-list-item-detail-text{
    font-size: 14px;
    font-weight: 500;
    color: #2d3640;
  }

  .nav-list-item-detail-icon{
    padding-left: 15px;
  }

  .nav-list-item-detail{
    display: flex;
    margin-right: 20px;
    height: 50px;
    border: solid 1px #eaedf2;
  }

  .active-bia{
    border-right: 3px solid #fd4056;
    .nav-list-item-detail{
      border-color: #fd4056;
    }
    .nav-list-item-detail-text{
      color: #fd4056;
    }
  }



  .bia-button{
    padding: 0!important;
    margin: 10px 0 10px 0px;
  }


  .feed-sidebar-p {
    height: 740px;
    background-color: #FFF;
    position: sticky;
    top: calc(100vh - 100%);
    transition: .2s all;
  }

  @media only screen and (min-width: 1440px) {
    .feed-sidebar-p {
      top: calc(100vh - 100%);
      height: 100vh;
    }
  }

  @media only screen and (min-width: 1440px) and (max-height: 800px) {
    .feed-sidebar-p {
      top: calc(100vh - 100%);
      height: 740px;
    }
  }

  @media only screen and (max-height: 800px) {
    .feed-sidebar-p {
      height: 740px;
    }
  }





  .feed-sidebar-u {
    height: 740px;
    background-color: #FFF;
    position: sticky;
    top: calc(100vh - 100% + 110px );
    transition: .2s all;
  }

  @media only screen and (min-width: 1440px) {
    .feed-sidebar-u {
      top: calc(100vh - 100% + 110px );
      height: 740px;
    }
  }

  @media only screen and (min-width: 1440px) and (max-height: 800px) {
    .feed-sidebar-u {
      top: calc(100vh - 720px );
     height: 740px;
    }
  }

  @media only screen and (max-height: 800px) {
    .feed-sidebar-u {
      top: calc(100vh - 680px );
      height: 740px;
    }
  }


</style>
