<template>
  <div class="settings" style=" ">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">
      <div class="content" :style="{ 'min-height': `${windowHeight - 110}px` }">
        <p class="page-header"> Mesajlar </p>

        <div class="content-ustband" style=" padding-left:26px;display: flex;align-content: center; align-items: center;">
          <div :class="{ 'content-ustband-text': true, active: disableAllMessages }"
               style="flex:1; text-align: left;"
               @click = "getConversations('all', '', null, null, true)">
            <span class="messagenavlink">Tüm Mesajlar</span>
          </div>

          <div :class="{ 'content-ustband-text': true, active: !disableAllMessages }"
               style="flex:1;text-align: center;"
               @click = "getConversations('deleted', '', null, null, true)"
          ><span class="messagenavlink">Arşiv</span></div>
          <div class="content-ustband-text" style="flex:5; float: right;text-align: right; ">
            <input v-model="searchTerm" placeholder="Kişi ara..." type="text" class="chatSearch" @keyup="inputEvent">
          </div>
        </div>


        <p v-show="!chatOpenLoading" style="margin-top: 20px; margin-left: 40px; color: #a1a9a9;	font-size: 14px;">
          {{ conversationCount }} adet konuşma bulundu. <span v-if="tempSearchTerm" class="showifsearchterm">(Arama Sözcüğü: <strong style="color: #5e6b79">{{ tempSearchTerm }}</strong> )</span>
        </p>

        <div v-if="conversationCount" class="content-white" style=" margin-top: 10px">
          <template v-for="conversation in messages">
            <div @click="openChat(conversation.r_username)" :class="{'chatrow': true,  'unseen': !conversation.isseen }">
              <div style="flex: 66;padding-top:5px; padding-left:12px; ">
                <img style=" border-radius:50%;height: 44px; width: 44px;" :src="conversation.r_avatar_url">
              </div>
              <div style="flex: 130;" class="title">
                {{ conversation.r_username }}
              </div>
              <div style="flex: 430;" class="title"><div style="width: 350px;">
                {{ ((conversation.message.length > 82) ? conversation.message.slice(0,80) + '...' : conversation.message) }}
              </div>
              </div>
              <div style="flex: 130;" class="title">
                {{ new Date(conversation.last_message_created_at) | customDateFormat  }}
              </div>
            </div>
          </template>

          <div style="cursor: pointer; margin-top:20px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;"
               @click="getConversations(disableAllMessages ? 'all' : 'deleted', '', null, offset)"
               v-show="!chatOpenLoading && seemorediv">
            Daha fazla gör +
          </div>
          <div v-show="chatOpenLoading" class="loader" style="text-align: center; margin:20px auto;"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import WorkstationNav from "../../navComponent";

  export default {
    name: "src-pages-body-workstation-messages-list-v1",
    components: { WorkstationNav },
    data () {
      return {
      	disableAllMessages : true,
        chatOpenLoading: false,
        messages: [],
        searchTerm: '',
        tempSearchTerm: null,
        seemorediv: false,
        offset: 0,
        conversationCount: 0,
        changeStr: 2
      }
    },

    methods: {
      getConversations(messageType, searchTerm, limit, offset) {
      	if (!searchTerm) {
      		this.searchTerm = '';
        }
        this.chatOpenLoading = true;
      	if (!offset) {
          this.messages = [];
          this.offset = 0;
        }
        this.api.workstation.getConversations(messageType, searchTerm, limit, offset)
        .then(({data}) => {
          this.chatOpenLoading = false;
          let result = data;
          if(result.success) {
            this.tempSearchTerm = ((searchTerm && searchTerm.length) ? searchTerm : null);
            this.conversationCount = result.data.conversation_count;
            this.disableAllMessages = messageType === "all";
            this.messages = this.messages.concat(result.data.conversations);
            this.seemorediv = result.data.seemorediv;
            this.offset += 5;
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
      },

    	inputEvent(event) {
        if(event.which === 13) {
          this.getConversations(this.disableAllMessages ? 'all' : 'deleted', this.searchTerm, null, null, true);
          this.searchTerm = '';
        }
      },

    	openChat(username) {
      	this.$router.push('/chat/'+ username);
      }
    },

    mounted() {
      this.getConversations('all');
    }
  }
</script>

<style scoped>



  .chatSearch{
    width: 175px;
    background: #ffffff url(https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg) no-repeat 2.5% center;
    text-align: left;
    border: 0;
    border-bottom: 1px solid #bfc8d2;

    padding: 12px 0 4px 28px;
    font-size: 16px;
    box-sizing: border-box;
  }


  .chatrow{
    cursor: pointer;
    height: 60px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .chatrow.unseen{
    height: 60px; background-color: rgba(245, 245, 245,0.7);  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }


  .chatrow.unseen > .title{
    font-weight: 600;
    color: #2d3640;
    font-weight: 600;
  }

  .chatrow:hover{
    background-color: #F5F6F8;
  }

  .title{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    color: #8b95a1;
  }


  .messagenavlink{
    cursor: pointer;
  }
  .messagenavlink:hover{
   color: #2d3640;
  }

</style>

