<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div  v-if="step === 1" class="create-area">
          <p class="title">Ne tür bir işe <span>ihtiyacın var?</span></p>
          <p class="sub-title">Hadi, başlayalım. 😎 İhtiyacın olan iş hangi ana kategoriye giriyor? Seçimini yap ve devam et!</p>

          <div class="main-cats-container">
            <div class="main-cats" v-for="cat in parentCategories">

              <div @click="selectCat(cat)" class="main-cats-bg" :style="cat.id === selectedCat.id ? cat.id === 3 ? 'color:#2d3640;background-color:'+cat.cat_color : 'color:white;background-color:'+cat.cat_color : 'color:#5e6b79; background-color: white'">
                <div class="bottom-cat-img-container" :style="'background:'+ categoryBackgrounds.find(({ id }) => id === cat.id).bg">
                  <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+cat.id+'.png'">
                </div>
                <div style="margin-left:28px;display: flex; flex-direction: column">
                  <p class="cat-title"  :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''">{{cat.name}}</p>
                  <p class="cat-sub-title"  :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.user_count | number_format}}</span> Freelancer</p>
                  <p class="cat-sub-title"  :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.gig_count | number_format}}</span> Aktif İş İlanı</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="step === 2" class="create-area">
          <p class="title">Biraz daha detay alalım!</p>
          <p class="sub-title">Peki, ihtiyacın olan hizmet <span>{{selectedCat.name}}</span> kategorisinin hangi alanına giriyor?</p>

          <div class="step2-bg" :style="selectedCat.id === 3 ? 'color:#2d3640;background-color:'+selectedCat.cat_color : 'color:white;background-color:'+selectedCat.cat_color">
            <div class="bottom-cat-img-container" :style="'background:'+ categoryBackgrounds.find(({ id }) => id === selectedCat.id).bg">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCat.id+'.png'">
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
              <div class="texts" :style="selectedCat.id !== 3 ? 'color:white' : 'color:#2d3640'">
                <p class="texts-title">{{selectedCat.name}}</p>
                <p class="texts-sub-title">Bu kategoride hizmet veren <span>{{selectedCat.user_count | number_format}} Freelancer</span> var.</p>
              </div>
              <div  v-if="selectedCat.cat_users.length" style="display: flex; margin-right: 30px;">
                <div v-for="onlineUser in selectedCat.cat_users ">
                  <div>
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'bia up small box')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,'bia up small box')"
                      :to="'/'+onlineUser.username">
                      <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                    </router-link>
                  </div>
                </div>
                <div style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                  +{{selectedCat.user_count - 5 }}
                </div>
              </div>
            </div>
          </div>

          <div class="sub-cat-box-container">
            <div @click="selectSubCat(subCategory)" :class="selectedSubCat.id === subCategory.id ? 'sub-cat-box-active' : 'sub-cat-box'" v-for="(subCategory, ii) in selectedCat.subCategory">
              <span>{{subCategory.name}}</span>
            </div>
          </div>

        </div>

        <div  v-if="step === 3" class="create-area">
          <p class="title">{{selectedSubCat.category_service_type_title}}</p>
          <p class="sub-title" >Şimdi sıra iş detaylarında. Lütfen <span>{{selectedSubCat.name}}</span> kategorisinden bir alt başlık seç?</p>

          <div style="margin: 50px auto; width: 100%; display: flex; align-items: center; justify-content: center">
            <select class="super-drop-c"
                    v-model="selectedServiceType"
                    :style="selectedServiceType ? 'border: solid 1px #2d3640;color:#2d3640' : ''"
            >
              <option :value="null" disabled="true">Seç...</option>
              <option v-for="option in selectedSubCat.category_service_types" :value="option.id" @onchange="">{{option.name}}</option>
            </select>
          </div>

        </div>

        <div  v-if="step === 4" class="create-area">
          <p class="title">{{selectedSubCat.category_service_kinds.title}}</p>
          <p v-if="selectedSubCat.id === 38" class="sub-title"> Şimdi sıra işin detaylarında. Hangi dile çeviri istediğini seçersen sana en uygun freelancer’ları ve iş ilanlarını bulabiliriz.</p>
          <p v-else-if="selectedSubCat.id === 61" class="sub-title"> Şimdi sıra işin detaylarında. Hangi dilde seslendirmen istediğini seçersen sana en uygun freelancer’ları ve iş ilanlarını bulabiliriz.</p>
          <p v-else class="sub-title" >Şimdi sıra iş detaylarında. Lütfen <span>{{selectedSubCat.name}}</span> kategorisinden bir alt başlık seç?</p>
          <div style="margin: 50px auto; width: 100%; display: flex; align-items: center; justify-content: center">
            <select class="super-drop-c"
                    v-model="selectedServiceKind"
                    :style="selectedServiceKind ? 'border: solid 1px #2d3640;color:#2d3640' : ''"
            >
              <option :value="null" disabled="true">Seç...</option>
              <option v-for="option in selectedSubCat.category_service_kinds.options" :value="option.id" @onchange="">{{option.name}}</option>
            </select>
          </div>

        </div>



      </div>
      <div class="foot">
        <custom-button v-if="calledFrom !== 'bia' || step > 1" buttonType="border" style="width: 130px; margin-left: 30px;" :disabled="buttonLoading" @click="prevStep()">Geri Dön</custom-button>
        <span v-else></span>
        <custom-button buttonType="green" style="width: 220px; margin-right: 30px;" :disabled="!enableButton || buttonLoading" @click="nextStep()">Devam Et</custom-button>

      </div>
    </div>
  </transition>
</template>

<script>
  import CustomButton from "../../components/CustomButton";

  export default {
    name: "src-pages-modals-addNewCar-v1",
    components: {
      CustomButton,
    },
    data() {
      return {
        openedModal: false,
        waitUntilRequestDone: false,

        buttonLoading:false,
        calledFrom:'bia',
        step:1,
        selectedCat:{},
        selectedSubCat:{},
        selectedServiceKind:null,
        selectedServiceType:null,
        wordCount:null,

        categoryBackgrounds:[
          {
          'id':1,
          'bg':'rgba(58, 89, 233,0.1)'
          },
          {
            'id':2,
            'bg':'rgba(88, 187, 211,0.2)'
          },
          {
            'id':3,
            'bg':'rgba(255, 191, 0,0.2)'
          },
          {
            'id':4,
            'bg':'rgba(63, 170, 64,0.2)'
          },
          {
            'id':5,
            'bg':'rgba(255, 76, 84,0.1)'
          },
          {
            'id':6,
            'bg':'rgba(75, 79, 82,0.2)'
          },
          {
            'id':8,
            'bg':'rgba(84, 63, 170,0.2)'
          }
        ],
      }
    },
    methods: {
      hide() {
        if(!this.waitUntilRequestDone) this.$store.commit(this.types.DISABLE_MODAL);
      },

      nextStep(){
        if(this.enableButton){

          if(this.step === 2){
            if(this.selectedSubCat && this.selectedSubCat.category_service_types && this.selectedSubCat.category_service_types.length > 0){
              this.step = 3;
            } else {
              if(this.selectedSubCat && this.selectedSubCat.category_service_kinds && this.selectedSubCat.category_service_kinds.options &&  this.selectedSubCat.category_service_kinds.options.length > 0){
                this.step = 4
              } else {
                this.pushRoute();
              }
            }
          } else if(this.step === 3){
            if(this.selectedSubCat && this.selectedSubCat.category_service_kinds && this.selectedSubCat.category_service_kinds.options &&  this.selectedSubCat.category_service_kinds.options.length > 0){
              this.step = 4
            } else {
              this.pushRoute();
            }
          } else {
            if(this.step + 1 === 5){
              this.pushRoute();
            } else {
              this.step++;
            }

          }
        }
      },

      prevStep(){
        if(this.step === 1){
          this.hide();
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ONBOARDING});
        } else {
          if(this.step === 4){
            if(this.selectedSubCat && this.selectedSubCat.category_service_types && this.selectedSubCat.category_service_types.length > 0){
              this.step = 3;
            } else {
              this.step = 2;
            }
          } else {
            this.step--;
          }

        }

      },

      pushRoute(){

        let url = '/benim-isim-acil?category_id='+this.selectedCat.id+'&category_sub_id='+this.selectedSubCat.id;
        if(this.selectedServiceType){
          url += '&service_type_id='+this.selectedServiceType;
        }
        if(this.selectedServiceKind){
          url += '&service_kind_id='+this.selectedServiceKind;
        }

        if(this.calledFrom === 'bia'){
          this.buttonLoading = true;
          this.$router.push(url);
        } else {
          this.$store.state.redirectPreviousURL.path = url;
          this.hide();
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ONBOARDING, info:{url}});
        }

      },

      selectSubCat(subCat){

        this.wordCount = null;
        this.selectedSubCat = subCat;
      },

      selectCat(cat){
        this.selectedSubCat = {};
        this.selectedServiceKind = null;
        this.selectedServiceType = null;
        this.wordCount = null;
        this.selectedCat = cat;
      },

    },
    computed: {
      enableButton() {
        let ret = false;
        if(this.step === 1 && this.selectedCat.id){
          ret = true;
        }
        if(this.step === 2 && this.selectedCat.id && this.selectedSubCat.id){
          ret = true;
        }
        if(this.step === 3 && this.selectedServiceType){
          ret = true;
        }
        if(this.step === 4 && this.selectedServiceKind){
          ret = true;
        }
        return ret;

      },
      getModalInfo() {
        let info =  this.$store.state.activeModal.info;
        this.calledFrom = info.calledFrom;
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">


  .sub-cat-box-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-top: 25px;
      width: 300px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-top: 25px;
      width: 300px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }


  .step2-bg{
    margin-top: 30px;
    width: 625px;
    height: 103px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;

    .texts{
      max-width: 205px;
      margin-left:33px;display: flex;
      flex-direction: column;

      .texts-title{
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;

      }

      .texts-sub-title{
        margin-top: 5px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;

        span{
          font-weight: bold;
        }
      }
    }
  }

  .foot{
    height: 110px;
    width: 770px;
    border-top: 1px solid rgba(191, 200, 210,0.2);
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }



  .bottom-cat-img-container{
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 55px;
    height: 55px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    .bottom-cat-img{
      position: absolute;
      margin-top: 15px;
      margin-left: 10px;
      width: 43px!important;
      height: 43px!important;
    }
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    box-sizing: border-box;
    width: 770px;
    max-height: 650px;
    max-width: 770px;
    overflow-y: scroll;
    background-color: #ffffff;
    position: relative;
    padding: 0;
    box-shadow: none;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }



  .create-area{
    padding: 30px 73px;

    .title{
      font-size: 32px;
      font-weight: normal;
      text-align: center;
      color: #2d3640;
      span{
        font-weight: 600;
      }
    }
    .sub-title{
      margin-top: 18px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      text-align: center;
      color: #8b95a1;
      span{
        font-weight: 600;
      }
    }

    .main-cats-container{
      margin-top: 27px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .main-cats{
        margin-bottom: 30px;
      }

      .main-cats-bg{
        cursor: pointer;

        background-repeat: no-repeat;


        width: 300px;
        height: 103px;
        border-radius: 10px;
        border: solid 1px rgba(191, 200, 210, 0.2);
        background-color: #ffffff;
        display: flex;
        align-items: center;

        .cat-title{
          font-size: 16px;
          font-weight: 600;
          line-height: 1.18;


        }
        .cat-sub-title{
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;

          span{
            font-weight: bold;
          }
        }
      }

      img{
        width: 190px;
        height: 190px;
      }
    }
  }



</style>
