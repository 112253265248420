<template>
    <div style="width: 100%; margin: 0 auto; text-align: center; display: flex; justify-content: center">
      <div class="version-warning">
        <div v-if="!$store.state.isMobile" style=" width: 60px; display: flex; align-items: center; justify-content: center;
  height: 60px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: rgb(255, 255, 255); margin-right: 30px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="bi-icon" data-inject-url="https://gcdn.bionluk.com/site/cicons/ic-bi.svg" data-v-b1da18d4="">
            <path fill="#000" fill-rule="evenodd" d="M9.857 8.7c-1.306 0-2.369.263-3.188.788l1.492-8.48H3.188l-3.1 17.54c-.057.317-.087.639-.088.961 0 1.186.449 2.109 1.346 2.769 1.13.835 2.753 1.253 4.867 1.253 2.115 0 3.87-.5 5.267-1.5 1.394-1.001 2.257-2.434 2.589-4.299l.588-3.262c.094-.46.143-.929.146-1.398 0-1.088-.292-2.01-.875-2.769-.78-1.068-2.136-1.602-4.066-1.602m9.323-.007c1.786 0 2.945.418 3.474 1.251.345.528.53 1.144.53 1.774.005.553-.035 1.106-.12 1.653l-1.766 10.072h-5.005l1.649-9.386c.019-.108.028-.218.03-.328.001-.536-.293-.804-.882-.804a2.418 2.418 0 0 0-1.207.297l.147-3.367c.923-.774 1.973-1.162 3.15-1.162zM7.683 12.868c.81 0 1.336.16 1.58.481.243.32.365.622.365.903a5.67 5.67 0 0 1-.03.656l-.38 2.185c-.273 1.497-1.16 2.245-2.661 2.244-.741 0-1.229-.291-1.463-.874l.849-4.866c.35-.486.931-.728 1.74-.729zM21.545 1C23.182 1 24 1.72 24 3.156c0 1.225-.278 2.118-.833 2.681-.555.564-1.427.845-2.616.845-1.618 0-2.426-.7-2.426-2.097 0-1.244.277-2.152.833-2.725.555-.573 1.417-.86 2.587-.86z"></path>
          </svg>
        </div>
        <p class="refresh-text">Heyyyy! 👋 Daha iyi bir Bionluk deneyimi için yeni bir sürüm geliştirdik.</p>
        <div class="refresh-button" @click="forceRefresh()">Sayfayı Yenile</div>
        <!--
        <a class="close-warning" @click="$store.state.openedVersion = false">
          <img src="https://gcdn.bionluk.com/site/icon/ic_close_white.svg" alt="">
        </a>
        -->
      </div>
    </div>
</template>

<script>

	export default {
	  name: "src-pages-version-v1",
		data() {
			return {}
		},

    methods: {
			forceRefresh() {
				location.reload();
      }
    }
	}

</script>

<style scoped lang="scss">
  .bi-icon /deep/ {
    width: 26px;
    height: 24px;

    path {
      fill:rgb(0, 165, 117)
    }
  }

  .version-warning{
    animation: opening 2s;
    color: #fff;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    bottom: 10px;
    justify-content: center;
    text-align: center;
    position: fixed;
    z-index: 10;
    margin: 0 auto;

    width: 100vw;
    max-width: 953px;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px rgb(1, 158, 112);
    background-color: rgb(0, 165, 117);

    img{
      margin-right: 20px;
    }
  }

  .refresh-text {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-right: 20px;
    span {
      font-weight: bold;

    }
  }

  .refresh-button {
    cursor: pointer;




    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 3px;



    font-size: 16px;
    font-weight: 600;

    line-height: normal;


    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;


    width: 188px;
    height: 49px;
    border-radius: 2px;
    border: solid 1px rgb(255, 255, 255);
    &:hover{
     opacity: 0.8;
    }
  }
  @keyframes opening {
    from {
      bottom: -100px;
    }
    to {
      bottom: 10px;
    }
  }
  .close-warning{
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }

</style>
