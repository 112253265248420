<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div @click="slide('back')" class="backBox" :style="slideNo > 0 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg">
      </div>
      <div @click="slide('next')" class="nextBox" :style="orderItems.length - slideNo > 1 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg">
      </div>
      <div style="position: relative!important; overflow: hidden; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">

        <div class="cardBoxContainer" :style="'height:426px; position:relative; transform: translate3d(-'+(895*slideNo)+'px, 0px, 0px);'">

          <div v-for="item in orderItems" class="open-order-area" :style="calledFrom === 'order' ? 'width: 841px'  :''">
            <div class="open-order-box" :style="calledFrom === 'order' ? 'width: 841px'  :''">
              <div class="open-order-header" :style="item.head_info.style">
                <div style="margin-top: 18px; margin-left: 40px; margin-right: 40px; display: flex; justify-content: space-between; width: 100%;">
                  <div>
                    <p style="font-size: 12px; color: #2d3640; text-align: left">Sipariş Durumu</p>
                    <div style="display: flex; margin-top: 8px;">
                      <img :src="item.head_info.icon" onload="SVGInject(this)" :class="item.head_info.icon_class">
                      <p style="color: #2d3640; font-size: 18px; margin-left: 10px;line-height: 1.32;">{{item.head_info.headertext}}</p>
                    </div>
                  </div>
                  <div v-if="calledFrom !== 'order'">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'open orders box')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,'open orders box')"
                      :to="'/orders/'+item.order_id">
                      <button class="order-detail-button" >
                        {{item.head_info.buttontext}}
                      </button>
                    </router-link>

                  </div>
                  <div v-else-if="item.completed_at" style="margin-right: -10px; margin-top: 12px;text-align: right">
                    <p style="color:#5e6b79;font-size: 12px;font-weight: 500; ">Teslim Tarihi</p>
                    <p style="color: #2d3640; font-size: 14px; font-weight: bold; margin-top: 3px;">{{ item.completed_at | customDateFormat('dd MMMM, yyyy') }}</p>
                  </div>


                </div>
              </div>

              <div style="display: flex; margin-top: 26px;">
                <div style="margin-left: 30px; display: flex; align-items: center; height: 170px;">
                  <img :src="item.image_url" class="order-gig-img">
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 25px; height:170px; justify-content: space-around">
                  <div class="order-gig-title">
                    Ben, {{item.title}}
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <div class="order-info-mini-box">
                      <div style="padding:10px 15px">
                        <p class="mini-box-up-title" style="text-transform: uppercase">{{item.kim.kim}}</p>
                        <div style="display: flex; margin-top: 7px">
                          <div style="display: flex; align-items: center; justify-content: center; height: 35px">
                            <img :src="item.kim.avatar_url" class="mini-box-img">
                          </div>
                          <div style="margin-left: 10px; height: 32px; display: flex; flex-direction: column; justify-content: center">
                            <router-link
                              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'open orders box')"
                              @click.native="handleGlobalClick($store.state.previousComponentPage,'open orders box')"
                              :to="'/'+item.kim.username" class="mini-box-username">{{item.kim.username}}</router-link>
                            <p class="mini-box-user-title">{{item.kim.title}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="order-info-mini-box" style="margin-left: 30px">
                      <div style="padding:10px 15px">
                        <p class="mini-box-up-title">SİPARİŞ <span v-if="calledFrom !== 'order' && item.kim.kim !== 'Alıcı'">ve ÖDEME </span>TARİHİ</p>
                        <div style="display: flex; margin-top: 7px">
                          <div v-if="item.payment_info.image_url && calledFrom !== 'order' && item.kim.kim !== 'Alıcı'" :style="'display: flex; align-items: center; justify-content: center; height: 35px; width: 35px; border-radius: 50%; background: white;  border: solid 1px #eaeaea; background-repeat:no-repeat; background-position:center; background-size:contain; background-image: url('+item.payment_info.image_url+');'">
                          </div>
                          <div v-else-if="calledFrom === 'order' || item.kim.kim === 'Alıcı'">
                            <div style="width: 36px; border-radius: 50%; display: flex; align-items: center;
 justify-content: center;  height: 36px;
  background-color: rgb(45, 54, 64);
">
                              <img src="https://gcdn.bionluk.com/site/cicons/ic-basket.svg" onload="SVGInject(this)" class="basket-icon">
                            </div>
                          </div>
                          <div style="margin-left: 10px; height: 34px; display: flex; flex-direction: column; justify-content: center">
                            <p class="mini-box-username">{{ item.payed_at | customDateFormat('dd MMM yyyy') }}</p>
                            <p v-if="calledFrom !== 'order' && item.kim.kim !== 'Alıcı'" class="mini-box-user-title">{{item.payment_info.name}}</p>
                            <p v-else class="mini-box-user-title">{{ item.payed_at | customDateFormat('HH:mm') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div style="width: 100%; margin-top: 25px;
  height: 50px;
  box-shadow: 0 1px 0 0 #eaedf2, 0 -1px 0 0 #eaedf2;
  background-color: #f9fafb; display: flex; align-items: center;color: #8b95a1; font-size: 12px;">
                <p style="width: 490px; text-align: left"><span style="padding-left:40px;"></span>DETAYLAR</p>
                <p style="width: 100px;text-align: center">PAKET</p>
                <p style="width: 100px;text-align: center">ADET</p>
                <p style="width: 100px;text-align: center">SÜRE</p>
                <p style="width: 100px;text-align: center">TUTAR</p>
              </div>
              <div style="border-bottom-left-radius:5px; border-bottom-right-radius:5px;width: 100%;height: 65px;display: flex; align-items: center;color: #2d3640; font-size: 14px;">
                <div v-if="item.up_info.offer_id"  style="width: 490px; position: relative">
                  <div style="flex:1;display: flex; align-items: center; height: 18px;">
                    <p style="padding-left:40px;height: 18px;display: flex; align-items: center">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg" onload="SVGInject(this)" class="custom_offer">
                    </p>
                    <p @click="activateCustomOfferDetailModal(item.up_info.offer_id)" class="special_offer_text">Özel Teklifi Göster</p>
                  </div>
                </div>
                <div v-else style="width: 490px; position: relative; text-align: left">
                  <p style="padding-left:40px;">{{item.up_info.title}}</p>
                  <div v-if="item.up_info.is_package">
                    <p :class="showPopover ? 'show-details-text-active' : 'show-details-text'" @click="showPopover = !showPopover">[Paket Detaylarını Göster]</p>
                    <button style="max-height: 380px; overflow-y: scroll" id="info-pop" class="info-pop" v-if="showPopover" @focusout="handleFocusOut">
                      <div class="info-pop-inside" style="">
                        <p style="font-weight: bold; font-size: 14px; color:#8b95a1">[{{item.up_info.package}} paket]</p>
                        <p style="margin-top: 20px; color:#343d4a; font-weight: 600; font-size: 16px; line-height: 1.5;">{{item.up_info.title}}</p>
                        <p style="color:#5e6b79; line-height: 1.57; font-weight: 300; font-size: 14px; margin-top: 5px;">{{item.package_info.description}}</p>
                        <div style="display: flex; margin-top: 10px;">
                          <div style="flex:1;display: flex; align-items: center; height: 18px;">
                            <p style="height: 18px;display: flex; align-items: center">
                              <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg" onload="SVGInject(this)" class="timer-icon">
                            </p>
                            <p style="height:18px;padding-left:5px; color:#2d3640; font-size: 14px; font-weight: 500;">{{item.package_info.duration}} günde teslim</p>
                          </div>
                          <div style="flex:1;display: flex; align-items: center; height: 18px;">
                            <p style="height: 18px;display: flex; align-items: center">
                              <img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="timer-icon">
                            </p>
                            <p style="height:18px;padding-left:5px; color:#2d3640; font-size: 14px; font-weight: 500;">{{item.package_info.revisions}} revizyon hakkı</p>
                          </div>
                        </div>

                        <div style="border-top:1px solid #eaedf2; margin-top: 15px;"></div>

                        <div v-if="item.order_extras" v-for="order_extra in item.order_extras" class="package-item-row">
                          <p class="package-item-active">{{order_extra.info.title}}

                            <span v-if="order_extra.info && order_extra.info.id === 'superfast'"> - {{order_extra.info.duration}} günde teslim</span>
                            <span v-else>({{order_extra.info.quantity}} adet)</span>
                          </p>
                          <p><img src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)" :class="'ic-green-icon'">  </p>
                        </div>

                        <div v-for="package_item in item.package_info.info.items" class="package-item-row">
                          <p :class="package_item.value ? 'package-item-active' : 'package-item'">{{package_item.title}} <span v-if="package_item.value && package_item.value !== true">({{package_item.value}})</span></p>
                          <p><img src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)" :class="package_item.value ? 'ic-green-icon' : 'ic-grey-icon'">  </p>
                        </div>

                      </div>
                    </button>
                    <!--
                    <div v-if="showPopover" class="info-pop" style="height: 0;" >
                      <div style="padding-left: 27px; height: 0;">
                        <span class="info-pop__arrow"></span>
                      </div>
                    </div>
                    -->
                  </div>
                </div>

                <p style="width: 100px;text-align: center"><span style="font-weight: bold; text-align: center">{{item.up_info.package}}</span></p>
                <p style="width: 100px;text-align: center"><span style="font-weight: bold;text-align: center">{{item.up_info.quantity}}</span></p>
                <p style="width: 100px;text-align: center"><span style="font-weight: bold;text-align: center">{{item.up_info.duration}} Gün</span></p>
                <p style="width: 100px;text-align: center"><span style="font-weight: bold;">{{item.total}} TL</span></p>
              </div>
              </div>
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";

  export default {
    props: ["list", "from"],
	  components:{Loader},
    data() {
      return {
      	showPopover:false,
      	pageLoaded:true,
	      slideNo: 0,
	      sliding:false,

        items: this.list || [],
        calledFrom: this.from || null

      };
    },

    methods:{

	    handleFocusOut(){
		    this.showPopover = false;
	    },
	    activateCustomOfferDetailModal(offer_id) {
		    this.api.seller.getOfferDetail(offer_id)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {
					    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: result.data.offerDetail}});
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },

	    retrieveItems() {
		    this.pageLoaded = false;
		    this.api.orders.retrieveOrders('buying', 'open', '20', '0', 'null')
			    .then(({data}) => {
				    let result = data;
				    this.pageLoaded = true;

				    if (result.success) {
					    this.items = result.data.orders;
				    } else {
					    this.pageLoaded = true;
				    }

			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },

	    slide(what){
		    this.sliding = true;
		    setTimeout(function(){
			    this.sliding = false;
		    }, 500);

		    if(what === 'next'){
			    this.slideNo++;
		    } else{
			    this.slideNo--;
		    }
	    },
    },
    computed:{
      orderItems() {
        return this.items
      }
    },
    watch: {
    	showPopover(newVal) {
        if(newVal){
          this.$nextTick(() => {
            document.getElementById('info-pop').focus()
          })
        }
	    }
    }
  }
</script>

<style scoped lang="scss">



  .basket-icon /deep/ {
    width: 18px;
    height: 16px;
    path {
      fill: #fff;
    }
  }



  .special_offer_text{
    cursor: pointer;
    height:18px;padding-left:5px; color: rgb(90, 38, 164); font-size: 14px; font-weight: bold;
    &:hover{
      text-decoration: underline;
    }
  }

  .payment_control /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(94, 107, 121);
    }
  }


  .initial_payment /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(101, 196, 219);
    }
  }

  .order_started /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(255, 191, 0);
    }
  }

  .order_delivered /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(38, 202, 154);
    }
  }

  .revision_requested /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(253, 64, 86);
    }
  }
  .order_approved /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(109, 206, 106);
    }
  }

  .order_cancelled /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(94, 107, 121);
    }
  }

  .custom_offer /deep/ {
    width: 24px;
    height: 25px;
    path {
      fill: rgb(90, 38, 164);
    }
  }






  .show-details-text{
    cursor: pointer;
    padding-left:40px; margin-top:5px; font-size: 14px; font-weight: bold; color:#00a575;
    &:hover{
      text-decoration: underline;
    }
  }

  .show-details-text-active{
    padding-left:40px; margin-top:5px; font-size: 14px; font-weight: bold; color:#2d3640; text-decoration: underline;
  }

  .info-pop {
text-align: left;
    &:hover{
      background-color: white!important;


    }

    .package-item-row{
      display: flex; justify-content: space-between; align-items: center; margin-top: 15px;
    }
    .package-item{
      font-size: 13px;
      font-weight: 500;
      color: #8b95a1;
    }

    .package-item-active{
      font-size: 13px;
      font-weight: 500;
      color: #2d3640;
    }

    .ic-green-icon /deep/ {
    width: 20px;
    height: 15px;
    path {
      fill: #00a575;
    }
  }

    .ic-grey-icon /deep/ {
      width: 20px;
      height: 15px;
      path {
        fill: #8b95a1;
      }
    }

    .timer-icon /deep/ {
      width: 14px;
      height: 14px;
      path {
        fill: #fd4056;
      }
    }

    .info-pop-inside{
      padding: 25px 27px;
      position:relative;
    }

    position:absolute;bottom: 20px;
    width: 350px;
    margin-left: 80px;

    border-radius: 2px;
    box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.2);
    border: solid 1px #eff3f8;

    background: #fff;



    &__content {

      color: #1C1C1C;
    }

    $arrowSize: 20px;

    &__arrow {
      position: absolute;
      font-size: 0;
      display: block;

      width:  $arrowSize;
      height:  $arrowSize;
      bottom: -$arrowSize;


      overflow: hidden;

      &:after {
        position: absolute;
        content: "";
        background: #fff;

        width: 100%;
        height: 100%;
        top: -95%;
        left: 0;


        border-radius: $arrowSize / 4;

        transform: matrix(1.00,1.00,0.00,1.50,0,0);  // Magic
      }
    }
  }



  .nextBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    right: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }


  .backBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }







  .open-order-area{
    width: 893px;

    height: 441px;
    border-radius: 10px;
    box-shadow: 0 20px 20px 0 #e9ebf0;
    border: solid 1px #eff3f8;
    background-color: #ffffff;

    .open-order-box{
      border-radius: 10px;
      width: 894px;


      .open-order-header{
        width: 100%;
        height: 86px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        //box-shadow: 0 1px 0 0 rgba(254, 179, 116, 0.5);
        //background-color: rgba(254, 179, 116, 0.2);
        display: flex;

      }
    }


    .order-detail-button{
      width: 170px;
      height: 50px;
      border-radius: 5px;
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      color: #2d3640;

      &:hover{
        background-color: #2d3640;
        color: white;
      }
    }


    .order-gig-img{
      width: 300px;
      height: 170px;
      border-radius: 10px;
      border: solid 1px #eaedf2;
    }


    .order-gig-title{

      font-size: 22px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #4b4f52;
    }

    .order-info-mini-box{
      width: 200px;
      height: 77px;
      border-radius: 2px;
      background-color: #f4f5f7;
      .mini-box-up-title{
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8b95a1;
      }

      .mini-box-username{
        height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3640;
        max-width: 124px;
      }

      .mini-box-user-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #5e6b79;
        max-width: 124px;
      }

      .mini-box-img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: solid 1px #eaeaea;
      }

      .mini-box-img-card{

        width: 35px;

        //border-radius: 50%;

      }
    }
  }






  .cardBoxContainer{

    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;
    height: 426px;
    background: transparent;
  }


</style>
