/**
 * Created by haluk on 4/17/17.
 */


const v3 = resolve => require(['./v3.vue'], resolve);
const v1_mobile = resolve => require(['./v1_mobile.vue'], resolve);

export default {
  v3,
  v1_mobile
}
