
<template>
  <div :class="calledFrom && calledFrom === 'article' ? 'default': 'centered'">
    <div class="inner">
      <div v-if="!isMobileFaq" class="inner-headers">{{contentData.title}}</div>
      <div class="inner-header-description">{{contentData.text}}</div>
    </div>
    <div class="faqs">
      <Accordion
        :impression-u-u-i-d="impressionUUID"
        v-for="(acc, i) in contentData.accordionData"
        :accordion="acc"
        :index="i"
        :key="i"
        :open="acc.open"
        @toggleOpen="toggleOpen"
      />
    </div>
  </div>
</template>

<script>
import Accordion from "./Accordion";

export default {
  name: "Faqs",
  components: { Accordion },
  props: ["contentData", "calledFrom", "catId", "subCatId", "impressionUUID", "articleId", "isMobileFaq"],
  methods: {
    toggleOpen(index) {
      this.contentData.accordionData = this.contentData.accordionData.map(
        (item, i) => {
          if (index === i) {
            if(!item.open){
              let posiobj = {
                impression_id:this.impressionUUID,
                page:this.$store.state.componentMap.page,
                title:item.title,
                cat_id:this.catId,
                sub_cat_id:this.subCatId,
                faq_id:item.id,
                article_id:this.articleId || ''
              };
              this.Helper.trackEvents.customEvent("toggle_faq", posiobj);
            }
            item.open = !item.open;
          } else {
            item.open = false;
          }
          return item;
        }
      );
    }
  },
  created() {
    if(this.impressionUUID && this.contentData && this.contentData.accordionData && this.contentData.accordionData.length){
      this.contentData.accordionData.forEach(faq =>{
        let postObjImp = {
          impression_id:this.impressionUUID,
          page:this.$store.state.componentMap.page,
          title:faq.title,
          cat_id:this.catId || '',
          sub_cat_id:this.subCatId || '',
          faq_id:faq.id,
          article_id:this.articleId || ''
        }
        this.Helper.trackEvents.customEvent("faq_impressions", postObjImp);
      })
    }
  }
};
</script>
<style scoped lang="scss">

  .centered{
    display:flex;flex-direction:column;justify-content:center;
    .inner{
      display:flex; justify-content:center; align-items:center; margin-top:90px; flex-direction:column;
    }

    .inner-headers {
      font-size: 34px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
    }
    .inner-header-description {
      margin-top: 10px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #5e6b79;
    }
    .faqs {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      align-self: center;
    }
  }

  .default{
    display:flex;flex-direction:column;
    .inner{
      display:flex; justify-content:center; align-items:center; margin-top:90px; flex-direction:column;
    }

    .inner-headers {
      font-size: 26px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
    }
    .inner-header-description {
      margin-top: 10px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #5e6b79;
    }
    .faqs {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 780px;
      align-self: center;
    }
  }
</style>
