<template>
  <div>
    <transition name="modal" style="width: 400px;">
      <div class="modal-mask"  style="overflow-y: scroll; " @click="hide()">
        <div class="modal-container" style="width: 500px !important;overflow: scroll;max-height: 100%" @click.stop>
          <div style="float: right; padding-right: 10px;">
            <img @click="hide()" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div>
            <div class="hiddendiv"></div>
            <h2 style="font-size: 32px;font-weight: 600;color: #2d3640;text-align: center;">
              bicoin nedir?
            </h2>
            <div class="hiddendiv"></div>
            <div class="hiddendiv"></div>
            <p style="color:#2d3640; font-size: 17px; line-height: 1.3;">
              bicoin; bionluk.com üyesi freelancer’ların Bionluk içerisinde gerçekleştirdiği satışlar ile site
              içerisinde kullanılabilen bakiye kazandıkları özel bir kullanıcı programıdır.
            </p>
            <div class="hiddendiv"></div>
            <div>
              <h3 style="font-size: 24px;font-weight: 500;color: #2d3640;padding-bottom: 10px">
                Sık Sorulan Sorular
              </h3>
              <div class="faq-list">
                <div v-for="item in faqList" class="faq-list-item" @click="changeActiveStatus(item)">
                  <span class="faq-list-item-question">{{ item.question }}</span>
                  <transition name="fade">
                    <p v-if="item.isActive" class="faq-list-item-answer">
                    <span v-html="item.answer"></span>
                    <ol style="margin-top: 10px; margin-left: 25px;">
                      <li v-for="listItem in item.listItems" style="list-style-type: disc">
                        {{ listItem }}
                      </li>
                    </ol></p>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
  export default {
    name: "src-pages-modal-bicoinFaqModalf",
    data() {
      return {
        faqList: [
          {
            isActive: false,
            question: "bicoin parayla satılır mı?",
            answer: "Hayır. bicoin sadece Bionluk'taki satış performansına bağlı olarak hesabına yüklenir. Parayla satılmaz," +
            " transfer edilemez."
          },
          {
            isActive: false,
            question: "Nasıl bicoin kazanırım?",
            answer: "1 TL tutarında yapmış olduğun satış onayladığı takdirde 1 bicoin kazanırsın. Örneğin " +
            "100TL tuturında bir satış yaptın ve teslim ettin. Alıcı onayladığı anda 100 bicoin hesabına " +
            "otomatik olarak yüklenir. Kazandığın bicoin'leri yükleme tarihinden itibaren bir yıl içinde " +
            "kullanmalısın."
          },
          {
            isActive: false,
            question: "bicoin'lerini nasıl harcarsın?",
            answer: "bicoin'lerinle bionluk.com içerisinde, satışlarını artıracak birçok farklı özellikten " +
            "yararlanabilirsin.",
            listItems: [
              "Benim İşim Acil! bölümünde ekstra dakika satın alabilirsin",
              "Kategori sayfalarında ilanını öne çıkanlar bölümünde sergiyebilirsin",
              "Henüz aranızda hiç konuşma bulunmayan bir alıcıya mesaj atmak isterken bicoin’lerini kullanabilirsin."
            ]
          },
          {
            isActive: false,
            question: "BİA’da bicoin işlemleri nasıl yapılır?",
            answer: "600 bicoin ile 1 sene boyunca kullanabileceğin 60 dakika satın alabilirsin. Almış olduğun " +
            "dakikalar 1 sene sonra sıfırlanır ve kalan bicoin’ler iade edilemez. Bu alanda kullanmış olduğun " +
            "bicoin’lerini farklı bir özellik almak için geri dönüştüremezsin."
          },
          {
            isActive: false,
            question: "Kategori listeleme sayfalarında öne çıkma özelliği nasıl çalışır?",
            answer: "500 bicoin ile 1 adet ilanının listeleme sayfalarında en üst sırada çıkmasını sağlayabilirsin. " +
            "Aynı anda gösterilen maksimum ilan sayısı 3'tür. Bu alandaki ilan sıralamaları gün içinde değişmekte ve ilanın 24 " +
            "saat süre ile sabit olarak öne çıkan ilanlar arasında sergilenmektedir." +
              "<br/>" +
            "Bu alanda kullanmış olduğun bicoin'lerini farklı bir özellik almak için geri dönüştüremezsin."
          },
          {
            isActive: false,
            question: "Alıcılarla mesajlaşırken uygulanan bicoin kuralları nelerdir? ",
            answer: "Daha önce aranızda mesaj geçmişi olan alıcılarla dilediğin kadar mesajlaşmaya devam " +
            "edebilirsin. Aranızda henüz hiç konuşma bulunmayan bir alıcıya mesaj atmak istersen 20 " +
            "bicon kullanman gerekiyor."
          },
        ]
      }
    },
    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      changeActiveStatus(item) {
        this.faqList.forEach(value => {
          if (value !== item) {
            value.isActive = false;
          }
        });
        item.isActive = !item.isActive;
      }
    },
    computed: {},
  }
</script>

<style scoped lang="scss">
  .faq-list-item{
    padding: 10px 0;
  }
  .faq-list-item-question{
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #2d3640;
    padding: 10px 0px;
  }
  .faq-list-item-answer{
    font-size: 16px;
    color: #2d3640;
    padding: 10px 0px;
  }

  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .faq-list{
    padding-bottom: 100px;
  }

  .modal-container{
    padding-bottom: 0;
  }
</style>
