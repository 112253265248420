/**
 * Created by haluk on 6/5/17.
 */

const v4 = resolve => require(['./desktop/v4/index.vue'], resolve)
const v1_mobile = resolve => require(['./mobile/v2_mobile.vue'], resolve);
const v2_mobile = resolve => require(['./mobile/v2_mobile.vue'], resolve);

export default {
  v4,
  v1_mobile,
  v2_mobile
}
