/**
 * Created by haluk on 4/17/17.
 */



import v1 from './list/v1.vue';
import v2_mobile from './list/v2_mobile.vue';
import chat_v2_mobile from './chat/chat_v2_mobile.vue';
import chat_v2 from './chat/chat_v2.vue';


export default {
  v1,
  v2_mobile,
  chat_v2,
  chat_v2_mobile
}
