<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div style="position: relative!important; margin-left: -27px">

        <div class="cardBoxContainer">
          <div v-for="portfolio in items" :key="portfolio.uuid" class="portfolio-box">
            <div class="head-section">
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'lastDonePortfolios')"
                @click.native="handleGlobalClick($store.state.previousComponentPage,'lastDonePortfolios')"
                :to="'/'+portfolio.username">
                <img class="avatar" :src="portfolio.avatar_url">
              </router-link>
              <div style=" margin-left: 6px; display: flex; height:40px; flex-direction: column; justify-items: center">
                <p class="title">{{portfolio.name}}</p>
                <p class="sub-title" v-if="portfolio.rating_user.id">{{portfolio.username}}, <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'lastDonePortfolios')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'lastDonePortfolios')"
                  :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
                <p v-else class="sub-title">{{portfolio.category_name}}</p>
              </div>
            </div>
            <div class="portfolio-img" @click="showPortfolio(portfolio.uuid)">
              <div v-if="portfolio.media.file_type === 'audio'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <div v-if="portfolio.media.file_type === 'video'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <img :src="portfolio.media.image_url">
            </div>
            <div class="foot-section">
              <div style="display: flex">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.fav_count}}</span>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.comment_count}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";

  export default {
    props: ["list"],
	  components:{Loader},
    data() {
      return {
	      pageLoaded:false,
	      slideNo: 0,
	      sliding:false,

        items: this.list || [],

      };
    },

    methods:{

	    showPortfolio(uuid) {
	    	this.$store.state.clickedFrom = 'lastDonePortfolios';
		    // this.$store.state.showCase.data = portfolio;
		    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid, calledFrom:"today_box", allItems:this.items}, description: 'keepOldModal'});
	    },
	    slide(what){

		    this.sliding = true;
		    setTimeout(function(){
			    this.sliding = false;
		    }, 500);

		    if(what === 'next'){
			    this.slideNo++;
		    } else{
			    this.slideNo--;
		    }

	    },


	    retrieveItems() {

		    if(!this.list || this.list.length === 0){
			    this.pageLoaded = false;
			    this.api.feeds.newMainPage('portfolio')
				    .then(({data}) => {
					    let result = data;
					    this.pageLoaded = true;

					    if (result.success) {
						    this.items = result.data;
						    this.$store.state.lastDonePortfolios = this.items;
					    } else {
						    this.pageLoaded = true;
					    }
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: err});
				    });
		    } else {
			    this.pageLoaded = true;
        }

	    },
    },


    beforeDestroy () {
      this.EventBus.$off('updatePortfolio');
    },
    created() {
	    this.retrieveItems();

      this.EventBus.$on('updatePortfolio', payload => {

        let uuid = payload.uuid;
        let what = payload.what;
        let type = payload.type;

        if(what==='comment' || what==='fav_count'){
          this.items.forEach(function (portfolio) {
            if (portfolio.uuid === uuid) {
              if(what==='comment'){
                if(type === 'plus') portfolio.comment_count += 1;
                else  portfolio.comment_count -= 1;
              }


              if(what==='fav_count'){
                if(type === 'plus') portfolio.fav_count += 1;
                else  portfolio.fav_count -= 1;
              }

            }
          });
        }


      });


    }
  }
</script>

<style scoped lang="scss">
  .portfolio-box{
    margin-left: 26px;
    margin-bottom: 30px;
    width: 277px;
    height: 274px;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .head-section{
      display: flex;
      align-items: center;
      height: 66px;
      margin-left: 11px;
      .title{
        height: 18px;
        font-size: 14px;
        font-weight: 600;
        color: #2d3640;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;
        display: block;
      }
      .avatar{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
      .sub-title{
        margin-top: 1px;
        height: 14px;
        font-size: 12px;
        font-weight: 300;
        color: #8b95a1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;
        a{
          font-size: 12px;
          font-weight: 600;
          color: #fd4056;
          &:hover{
            text-decoration: underline;
            color: #fd4056!important;
          }
        }
      }
    }

    .portfolio-img{

      position: relative;
      cursor: pointer;
      height: 158px;
      img{
        width: 100%;
      }
    }

    .foot-section{

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      height: 48px;

      font-size: 16px;
      font-weight: 600;
      color: #8b95a1;

      span{
        font-size: 16px;
        font-weight: 600;
        color: #8b95a1;
        padding-left: 5px;
        padding-right: 15px;
        line-height: 0.9;

      }
      .like-icon /deep/ {
        cursor: pointer;
        width: 16px;
        height: 16px;
        path {
          fill: #bfc8d2!important;
        }
      }

      .trash-icon /deep/ {
        cursor: pointer;
        width: 20px;
        height: 20px;
        path {
          fill: #bfc8d2!important;
          &:hover{
            fill: #2d3640!important;
          }
        }
      }

      .trash-icon:hover{
        path {
          fill: #2d3640!important;
        }
      }

      .edit-icon /deep/ {
        cursor: pointer;
        width: 20px;
        height: 20px;
        path {
          fill: #bfc8d2!important;
          &:hover{
            fill: #2d3640!important;
          }
        }
      }
      .edit-icon:hover{
        path {
          fill: #2d3640!important;
        }
      }

    }
  }

  .cardBoxContainer{

    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;
    height:593px;
    flex-wrap: wrap;
    overflow-y:hidden;
    .cardBoxFlexC{
      display: flex;

    }


    .pBox{
      width: 277px;
      height: 274px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

      position: relative;
      display: flex;


      flex-direction: column;
      align-items: center;
      margin-left: 28px;
      margin-bottom: 30px;

      .header{

        display: flex;
        align-items: center;
        height: 67px;
        width: 100%;

      }

      .media{
        width: 277px; height: 158px;
        cursor: pointer;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .avatar{
        margin-left: 11px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
      .username-p{

        font-size: 11px;
        font-weight: 300;
        color: #5e6b79;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 14px;
        max-width: 200px;

        a{
          font-size: 11px;
          font-weight: 600;
          color: #fa4a5e;
          &:hover{
            text-decoration: underline;
          }
        }
      }

      .title{
        font-weight: 600;
        color: #2d3640;
        font-size: 14px;
      }


    }

    .playIcon {
      cursor: pointer;
      z-index: 9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -130%);
      height: 42px;
      width: 42px;
      border: 2px solid white;
      background-color: white;
      border-radius: 50%;
      img {
        height: 42px;
        width: 42px;
      }
    }

  }


</style>
