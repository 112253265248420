<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="modal-inner">
          <p class="modal-header">Aramıza <span>Hoş Geldin!</span> 🎉 </p>
          <img src="https://gcdn.bionluk.com/site/c/confetti.svg" class="confetti"/>
          <img :src="user.avatar_url" class="modal-avatar"/>
          <p class="modal-text-header">Sevgili <span style="text-transform: capitalize;">{{user.firstname}}</span>, artık Bionluk freelancer’ısın!</p>
          <p class="modal-text">
            Bionluk ailesi olarak aramıza katılmandan ötürü mutluluk duyduk. 🥰 Hemen ilk iş ilanını oluşturabilir veya anasayfaya dönerek Bionluk’u keşfe çıkabilirsin!
          </p>

          <div class="button-body">
            <custom-button button-type="red" style="width: 220px;" @click="goLink('/bugun')">Anasayfa</custom-button>
            <!--<custom-button button-type="dark" style="width: 300px; margin-top: 30px;" :disabled="waitUntilRequestDone" @click="goLink('/bugun')">Anasayfaya Dön</custom-button>-->
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton'

  export default {
    name: "src-pages-modals-welcomeSeller-v1",
    components: {
      CustomButton,
    },
    data() {
      return {
        openedModal: false,
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      goLink(link) {
        this.hide();
        this.$router.push(link)
      }
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 85px 50px 85px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }


  .modal-text-header {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin-top: 15px;
    color: #2d3640;
  }

  .modal-text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 21px;
    color: #5e6b79;
  }

  .button-body {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .confetti {
    position: absolute;
    width: 679px;
    height: 326px;
    top: 5px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .modal-avatar {
    width: 84px;
    height: 84px;
    border: 2px solid #eaeaea;
    border-radius: 100%;
  }

</style>
