<template>
  <transition name="modal" >
    <div class="modal-mask">

      <div v-if="getModalInfo" class="modal-container" @click.stop :style="buttonAction === 'approve' ? 'width: 543px!important;' : ' padding: 50px 50px 0px 50px;'">


        <div class="send-custom-offer-main">
          <div class="close-div">
            <img v-if="!buttonLoading" @click="hide()" style="opacity: 1; cursor:pointer;width: 24px; height:24px;"
                 src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg">
          </div>

          <p class="header" v-if="buttonAction === 'start_order'">Siparişi <span>Başlat</span></p>
          <p class="header" v-else-if="buttonAction === 'deliver'">Siparişi <span>Teslim et</span></p>
          <p class="header" v-else-if="buttonAction === 'revision'">Revizyon <span>Talebi</span></p>
          <p class="header" v-else-if="buttonAction === 'approve'">Siparişi <span>Onayla</span></p>
          <p class="header" v-else-if="buttonAction === 'add_portfolio'">Çalışmayı <span>Portfolyona Ekle</span></p>
          <p class="sub-header" v-if="buttonAction === 'start_order'">Siparişi başlatmadan önce freelancer’ın ihtiyacı olan dosyaları yükleyebilir ve varsa notlarını iletebilirsin.</p>
          <p class="sub-header" v-else-if="buttonAction === 'revision'">Dilersen revizelerini freelancer’a anlık mesaj göndererek de iletebilirsin.</p>
          <p class="sub-header" v-else-if="buttonAction === 'approve'" style="margin-top: 10px;">Siparişini freelancer ile anlaştığın şekilde, sorunsuz teslim aldığını ve ödemenin freelancer’a aktarılmasını onaylıyor musun?</p>
          <p class="sub-header" v-else-if="buttonAction === 'add_portfolio'" style="margin-top: 10px;">Alıcı aşağıdaki siparişle ilgili çalışmayı portfolyona <span style="color: #2d3640">“gerçek iş örneği”</span> olarak eklemene onay verdi.</p>


          <div class="sum-box"  v-if="buttonAction === 'add_portfolio'">
            <div style="padding: 20px; display: flex">
              <div class="">
                <img :src="summaryBox.image_url" style="border: solid 1px #eaedf2;border-radius: 10px;height: 120px;">
              </div>
              <div style="margin-left: 15px;">
                <div class="gig-title">
                  Ben, {{summaryBox.title}}.
                </div>
                <div style="display: flex; flex-direction: row">
                  <div class="order-info-mini-box">
                    <div style="padding:10px 15px">
                      <p class="mini-box-up-title" style="text-transform: uppercase">{{summaryBox.kim.kim}}</p>
                      <div style="display: flex; margin-top: 4px">
                        <div style="display: flex; align-items: center; justify-content: center; height: 30px">
                          <img :src="summaryBox.kim.avatar_url" class="mini-box-img">
                        </div>
                        <div style="margin-left: 5px; height: 29px; display: flex; flex-direction: column; justify-content: center">
                          <p class="mini-box-username">{{summaryBox.kim.username}}</p>
                          <p class="mini-box-user-title">{{summaryBox.kim.title}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="order-info-mini-box" style="margin-left: 20px; width: 140px;">
                    <div style="padding:10px 15px">
                      <p class="mini-box-up-title">TESLİM TARİHİ</p>
                      <div style="display: flex; margin-top: 4px">
                        <div>
                          <div style="width: 30px; border-radius: 50%; display: flex; align-items: center;
 justify-content: center;  height: 30px;
  background-color: rgb(45, 54, 64);
">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-basket.svg" onload="SVGInject(this)" class="basket-icon">
                          </div>
                        </div>
                        <div style="margin-left: 5px; height: 27px; display: flex; flex-direction: column; justify-content: center">
                          <p class="mini-box-username">{{ summaryBox.payed_at | customDateFormat('dd MMM yyyy') }}</p>
                          <p class="mini-box-user-title">{{ summaryBox.completed_at | customDateFormat('HH:mm') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>


          <div v-if="buttonAction === 'add_portfolio'" style="margin-top: 30px; margin-bottom: 30px;">
            <label style="font-size:16px;">Portfolyo Başlığın</label>
            <input v-model="portfolioTitle" maxlength="60" style="width: 610px; margin-top: 5px;" placeholder="Örnek: Tesla - Konsept Logo Tasarımı">
            <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
              {{portfolioTitle.length}} / <span style="color: #2d3640">60</span>
            </div>
            <div class="hiddendiv20"></div>
            <label style="font-size:16px;">Açıklama</label>
            <textarea maxlength="250" v-model="portfolioDescription" style="width: 590px; padding: 20px; height: 110px;margin-top: 5px;" placeholder="Mesela, kimin için, nelerden esinlenerek veya hangi araçlarla yaptığını açıklayabilirsin."></textarea>
            <div style="color: #8b95a1; font-size: 13px; font-weight: 500; display: flex; justify-content: flex-end; margin-top: 10px;">
              {{portfolioDescription.length}} / <span style="color: #2d3640">250</span>
            </div>
          </div>

          <div v-if="buttonAction === 'add_portfolio'" class="res-center" style="margin-top: 15px;">
            <div style="color:#5e6b79;font-size: 12px; line-height: 1.5; padding: 15px;width: 530px;">Portfolyona eklemek istediğin ses, görsel veya videoyu daha önce yüklediğin dosyalar arasından seçebilir veya yenisini yükleyebilirsin. Sadece <span style="font-weight: 600; color: #2d3640;">png, jpg, mp3 veya mp4</span> dosyaları yükleyebilirsin. Mp4 dosyaları için video süresi en fazla <span style="font-weight: 600; color: #2d3640">4:59(4 dakike 59 saniye)</span> olabilir. </div>
          </div>

          <div v-if="buttonAction === 'add_portfolio'" style="display: flex; flex-wrap: wrap; ">
            <label v-for="(currentFile, cin) in currentFiles.concat(files)" :key="cin" class="radio-container">
              <template v-if="currentFile.url">
                <img :src="currentFile.icon_png" style="width: 19px; height: 24px; margin-right: 5px;"><a target="_blank" :href="currentFile.url">{{currentFile.filename}}</a>
                <input type="radio" :value="currentFile.upload_id" v-model="portfolioCheckIndex" name="radio" >
                <span class="checkmark"></span>
              </template>
              <template v-else>
                <div v-if="$store.state.percentCompleted.temp_icon_png[currentFile.stamp]" @click="cancelUpload(currentFile.stamp)">
                  <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                </div>
                <img v-if="currentFile.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="currentFile.icon_png">
                <img v-else-if="$store.state.percentCompleted.temp_icon_png[currentFile.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[currentFile.stamp]">
                <div style="display: flex; flex-direction: column; margin-left: 5px;">
                  <a target="_blank" :href="currentFile.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ currentFile.filename }}</a>
                  <progress v-if="$store.state.percentCompleted.order[currentFile.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.order[currentFile.stamp]' max="100"></progress>
                </div>
              </template>
            </label>
          </div>

          <p v-if="buttonAction === 'approve'" class="red-text">
            İş tamamen bitmeden kesinlikle siparişe onay vermemelisin. <br>Bu oldukça <span>GÜVENSİZ</span> bir işlemdir.
          </p>

          <div  v-if="buttonAction !== 'approve' && buttonAction !== 'add_portfolio'" style="width: 630px; height: 160px; margin: 30px auto 70px ">
            <textarea maxlength="600" v-model="message" class="c-text-area" :placeholder="buttonAction !== 'revision' ? 'Varsa, siparişle ilgili notunu buraya yazabilirsin.' : 'Revizyon taleplerini buraya yazabilirsin...'" style="width: 580px; height: 160px;"></textarea>
          </div>

          <div v-if="buttonAction !== 'approve' && buttonAction !== 'add_portfolio'" style="display: flex; justify-content: space-between; width: 100%; margin: 15px auto;">
            <div style="text-align: left">
              <div style="display: flex; align-items: center; height: 18px; margin-bottom: 20px;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                <p @click="chooseFiles('portfolio_action_upload')" class="start-upload-text">Dosya Ekle</p>
                <input id="portfolio_action_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER)">
              </div>
              <div v-for="file in files" style="margin-bottom: 15px;">
                <div style="display: flex; align-items: center">
                  <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>
                  <div v-else @click="deleteFile(file.externalID, 'order')">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>

                  <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                  <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                  <div style="display: flex; flex-direction: column; margin-left: 5px;">
                    <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                    <progress v-if="$store.state.percentCompleted.order[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.order[file.stamp]' max="100"></progress>
                  </div>
                </div>
              </div>

            </div>

            <div v-if="buttonAction !== 'add_portfolio'" style="color: #8b95a1; font-size: 13px; font-weight: 500">
              {{message.length}} / <span style="color: #2d3640">600</span>
            </div>
          </div>





          <div v-if="buttonAction === 'add_portfolio'" style=" display:flex; justify-content:center;margin: 0 auto; width: 600px; text-align: center">
            <div style="height: 18px; margin: 24px auto 0; width: 180px;">
              <img style="display: inline-block" src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
              <p @click="chooseFiles('order_action_upload')" class="start-upload-text" style="display:inline-block;color: #2d3640; font-size: 14px; font-weight: 300; margin-left: 0; vertical-align: top;"><span v-if="currentFiles.concat(files).length > 0">farklı bir içerik kullan</span><span v-else>içerik ekle</span></p>
              <input id="order_action_upload" style="visibility: hidden;display:inline-block;" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.ORDER)">
            </div>
          </div>


          <button :disabled="buttonLoading || !portfolioSelected" @click="handleButtonAction()" class="cuper-green-button" style="margin:30px auto">
            <span v-if="buttonAction === 'start_order'">Siparişi Başlat</span>
            <span v-else-if="buttonAction === 'deliver'">Siparişi Teslim Et</span>
            <span v-else-if="buttonAction === 'revision'">Revizyon Taleplerimi İlet</span>
            <span v-else-if="buttonAction === 'approve'">Siparişi Onayla ({{total}} TL)</span>
            <span v-else-if="buttonAction === 'add_portfolio'" style=" font-size: 14px;">Portfolyoma Ekle</span>
          </button>

          <div v-if="buttonAction === 'approve'" class="res-center">
            <div>
              Eğer freelancer iş bitmeden siparişi onaylamanı istiyorsa veya başka bir problem yaşıyorsan lütfen Çözüm Merkezi üzerinden bizi bilgilendir.
              <button :disabled="buttonLoading" @click="handleButtonAction('resolution_center')" class="cuper-white-button" style="width: 185px; height: 40px; margin:15px auto 0px">Çözüm Merkezine Git</button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-customOffer-v1",
    data() {
      return {
        portfolioTitle:'',
        portfolioDescription:'',
      	orderID:null,
        buttonLoading:false,
        buttonAction:null,
        currentFiles:[],
	      portfolioSelected:true,
        summaryBox:{},
        total: 0,

        //v-model
        message: '',
        files:[],
        portfolioCheckIndex:null,


      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      handleButtonAction(what=null){
      	if(what){
      		if(what === 'resolution_center'){
			      this.EventBus.$emit("goSupportPage");
			      this.hide();
          }
        } else {
		      if(this.buttonAction && this.buttonAction === 'start_order'){
			      this.startOrder();
		      }
		      if(this.buttonAction && this.buttonAction === 'deliver'){
			      this.deliver();
		      }
		      if(this.buttonAction && this.buttonAction === 'revision'){
			      this.revision();
		      }
		      if(this.buttonAction && this.buttonAction === 'approve'){
			      this.approve();
		      }
		      if(this.buttonAction && this.buttonAction === 'add_portfolio'){
			      this.addPortfolio();
		      }
        }

      },

      addPortfolio(){
	      this.buttonLoading = true;
	      let externalList = [];
	      this.files.forEach(function (file) {
		      externalList.push(file.externalID);
	      });

	      this.api.orders.addToPortfolio(this.portfolioCheckIndex, externalList, this.orderID, this.portfolioTitle, this.portfolioDescription, this.$Progress)
		      .then(({data}) => {
			      let result = data;
			      if (result.success) {
				      this.$toasted.global.infoToast({description: result.message});
				      this.buttonLoading = false;
				      this.hide()
			      } else {
				      this.$toasted.global.errorToast({description: result.message});
				      this.buttonLoading = false;
			      }
		      })
		      .catch(err => {
			      this.buttonLoading = false;
			      this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
		      });
      },

	    revision() {
		    this.buttonLoading = true;
		    let externalList = [];
		    this.files.forEach(function (file) {
			    externalList.push(file.externalID);
		    });
		    this.api.orders.revisionOrder(this.message, externalList, this.orderID, this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.$toasted.global.infoToast({description: result.message});
					    this.buttonLoading = false;
					    this.hide()
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
					    this.buttonLoading = false;
				    }
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },


	    approve() {
		    this.buttonLoading = true;
        this.api.orders.approveOrder(this.orderID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
	            this.$toasted.global.infoToast({description: result.message});
	            this.buttonLoading = false;
	            this.hide()
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
	          this.buttonLoading = false;
          })
          .catch(err => {
	          this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

          })

	    },


	    startOrder() {
      	this.buttonLoading = true;
		    let externalList = [];
		    this.files.forEach(function (file) {
			    externalList.push(file.externalID);
		    });
		    this.api.orders.startOrder(this.message, externalList, this.orderID)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.$toasted.global.infoToast({description: result.message});
					    this.buttonLoading = false;
					    this.hide()
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
					    this.buttonLoading = false;
				    }

			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    })
	    },

	    deliver() {

		    this.buttonLoading = true;
		    let externalList = [];
		    this.files.forEach(function (file) {
			    externalList.push(file.externalID);
		    });
        this.api.orders.deliverOrder(this.message, externalList, this.orderID, null, this.$Progress)
          .then(({data}) => {
            let result = data;
	          if (result.success) {
		          this.$toasted.global.infoToast({description: result.message});
		          this.buttonLoading = false;
		          this.hide()
	          } else {
		          this.$toasted.global.errorToast({description: result.message});
		          this.buttonLoading = false;
	          }
          })
          .catch(err => {
	          this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

	    },

	    chooseFiles(id) {
		    document.getElementById(id).click()
	    },

	    cancelUpload(timeStamp){
		    this.cancelUploadRequest(parseInt(timeStamp));

		    let fileIndex = this.files.findIndex(f => f.stamp === timeStamp);
		    if(fileIndex !== -1){
			    this.files.splice(fileIndex, 1);
		    }
	    },

	    deleteFile(uploadID, type) {
		    this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {
					    if (type === this.Constants.UPLOAD_TYPES.ORDER) {
						    this.files = this.files.filter(function (file) {
							    return file.externalID !== uploadID
						    });
					    } else {
						    this.files = this.files.filter(function (file) {
							    return file.externalID !== uploadID
						    });
					    }

					    this.$toasted.global.infoToast({description: result.message});
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
			    })
			    .catch(err => {
				    console.log(err);
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },

	    onFileChange(e, type) {
		    let files = e.target.files || e.dataTransfer.files;
		    if (!files.length)
			    return;
		    if(this.buttonAction === 'add_portfolio'){
			    let ext = files[0].name.split('.').pop();
			    if(ext !== 'jpg' && ext !== 'png' && ext !== 'jpeg' && ext !== 'mp3' && ext !== 'mp4'){
				    this.$toasted.global.errorToast({description: "Sadece png, jpg, mp3 veya mp4 dosyaları yükleyebilirsin."});
				    return;
          }
        }
		    this.buttonLoading = true;
		    let timeStamp = +new Date();
		    let rand = Math.floor(Math.random() * 1000) + 1;
		    timeStamp = timeStamp + rand;
		    this.files.push({
			    url: null,
			    externalID: this.orderID,
			    filename: files[0].name,
			    stamp: timeStamp
		    });


		    this.uploadFileToGoogle(files[0], type, this.orderID, timeStamp)
			    .then(result => {
				    let fileIndex = this.files.findIndex(f => f.stamp === timeStamp);
				    let file = this.files[fileIndex];
				    file.externalID = result.externalID;
				    file.upload_id = result.externalID;
				    file.url = this.Constants.CDN_UPLOADS_G + result.uploadName,
		        file.icon_png = result.icon_png;
				    this.files[fileIndex] = file;
				    this.buttonLoading = false;

			    }).catch(err => {
			    	console.log(err);
			    this.buttonLoading = false;

		    });

		    e.target.value = ''

	    },

    },

    watch:{
	    portfolioCheckIndex: function (newVal) {
        if(newVal && this.buttonAction === 'add_portfolio'){
        	this.buttonLoading = false;
        	this.portfolioSelected = true;
        }
	    }
    },
    computed: {
      getModalInfo() {
          let data = this.$store.state.activeModal.info;
          this.summaryBox = data.summaryBox;

          this.orderID = data.orderID
          this.buttonAction = data.buttonAction;
          let comFiles = this.currentFiles.length ? this.currentFiles : data.files;
          if(comFiles){
	          comFiles.forEach(  (file) => {
              let extension = file.filename.split('.').pop();
              if(extension === 'png' || extension === 'jpg' || extension === 'mp4' || extension === 'mp3' || extension === 'PNG' || extension === 'JPG' || extension === 'MP4' || extension === 'MP3'){
                this.currentFiles.push(file);
              }
            });
          }
	      if(this.buttonAction === 'add_portfolio'){
		      this.portfolioSelected = false;
	      }
        if(this.buttonAction === 'approve'){
          this.total = data.total;
        }
          return true;
      },

    },
    created () {

    	this.files = [];
    	this.description = '';
	    this.buttonAction = null;
	    this.currentFiles = [];
    }
  }

</script>

<style scoped lang="scss">






  .sum-box{

    .basket-icon /deep/ {
      width: 16px;
      height: 14px;
      path {
        fill: #fff;
      }
    }


    margin-top: 25px;
    width: 630px;
    height: 160px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .gig-title{
      height: 53px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #4b4f52;
    }


    .order-info-mini-box{
      width: 190px;
      height: 68px;
      border-radius: 2px;
      background-color: #f4f5f7;
      .mini-box-up-title{
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8b95a1;
      }

      .mini-box-username{
        height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3640;
        max-width: 100px;
      }

      .mini-box-user-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #5e6b79;
        max-width: 100px;
      }

      .mini-box-img{
        width: 29px;
        height: 29px;
        border-radius: 50%;
        border: solid 1px #eaeaea;
      }

      .mini-box-img-card{

        width: 29px;

        //border-radius: 50%;

      }
    }
  }

  .res-center{
    margin-top: 50px;
    margin-bottom:20px;
    width:100%;
    border-radius: 5px;
    border: solid 1px rgba(191, 200, 210, 0.3);
    background-color: rgba(191, 200, 210, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #2d3640;
    div{
      padding: 20px;
      width: 440px;
      margin: 0 auto;
    }

  }

  .red-text{


    font-weight: 500;
    line-height: 2;
    text-align: center;
    margin:25px auto 30px;
    color: #fd4056;
    span{
      font-weight: bold;
    }
  }

  .header{
    font-size: 32px;
    font-weight: normal;
    text-align: center;
    color: #2d3640;

    span{
      font-weight: 600;
    }
  }


  .sub-header{
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #8b95a1;
    max-width: 600px;
    margin:5px auto 0;
  }

  .send-custom-offer-main{
    height:100%;
    margin-bottom: 20px;
  }

  .modal-mask {
    overflow-y: scroll;
    display: block;
  }


  .modal-container {

    margin-top: 60px;
    width: 630px !important;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
    margin-bottom: 60px;

  }

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
  }


  .delete-icon /deep/ {
    cursor: pointer;
    width: 14px;
    height: 14px;
    path {
      fill: #8b95a1;
    }
  }

  .attach-icon /deep/ {
    width: 14px;
    height: 14px;
    path {
      fill: #2d3640;
    }
  }


  /* Customize the label (the container) */
  .radio-container {

    position: relative;
    padding-left: 30px;
    margin-right: 50px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: flex;
    align-items: center;

    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    color: #2d3640;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: solid 2px #bfc8d2;
    background-color: white;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
   // background-color: #bfc8d2;
    border: solid 2px #e1e1e1;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {


  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;

    background-color: #fd4056;

  }


</style>
