<template>
  <div class="gig-detail-user-information">
    <div style="position: relative; display: flex; flex-direction: column; justify-content: center; margin-top: 15px;">
      <div v-if="isSelfUser" style="position: absolute; right: 0; top: -18px; width: 52px">
        <router-link to="/settings" style="color: #00a575; font-size: 14px;">Düzenle</router-link>
      </div>
      <div style="margin-top: 10px; text-align: center">
        <div class="avatar-wrapper">
          <div class="status-icon" :style="{backgroundColor: userProfile.onlineStatus ? '#5beb17' : '#8b95a1'}"/>
          <router-link :to="'/'+userProfile.username">
            <img class="avatar" :src="userProfile.avatar_url"/>
          </router-link>
        </div>
      </div>
      <div class="username" style="text-align: center">
        <router-link :to="'/'+userProfile.username">
          {{ userProfile.username }}
        </router-link>
      </div>
      <div class="userTitle" style="text-align: center">{{ userProfile.title }}</div>
      <div v-if="userProfile.sellerRating.commentCount > 0" class="user-info-rating-div" style="text-align: center">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-rating"/>

        <p class="rating-number">{{ userProfile.sellerRating.commentRating | convertNumberToFraction }}
          <span class="comment-count"> ({{ userProfile.sellerRating.commentCount }})</span>
        </p>
      </div>
      <div class="send-message-btn" :style="buttonLoading ? 'opacity:0.4': 'opacity:1'" v-if="!isSelfUser" @click="$emit('sendMessage')">
        Mesaj Gönder
      </div>
    </div>
    <div v-if="userProfile.f_t_info.isActive" class="or-wrapper">
      <div class="dash"/>
      <div style="height: 16px">veya</div>
      <div class="dash"/>
    </div>
    <div v-if="userProfile.f_t_info.isActive" class="freelancer-line-wrapper">
      <div class="info">
        <div class="text-wrapper" style="line-height: 1.71;">
          <img src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg" style="width: 20px; height: 20px; margin-top: 2px"/>
          <div style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal">
            <span style="color: #00a575; font-weight: 500">Freelancer Telefon Hattı</span> üzerinden ulaş.
          </div>
        </div>
        <div v-if="userProfile.f_t_info.isActive" class="question-mark-wrapper" style="margin-top: 3px;" :data-balloon="reachLineText" data-balloon-pos="left" data-balloon-length="medium">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
        </div>
      </div>
      <div style="margin-left: 27px; margin-top: 26px;">
        <div style="display: flex; justify-content: space-between">
          <span class="info-label">Ulaşılacak Telefon</span>
          <span style="color: #2d3640; font-weight: bold; font-size: 16px">0 (212) 909 - 23 - 10</span>
        </div>
        <div style="margin-top: 16px; display: flex; justify-content: space-between">
          <span class="info-label">Dahili Numarası</span>
          <span v-if="!isLoggedIn" style="font-weight: bold; font-size: 12px; color: #2d3640; margin-top: 2px">
            <span>Görmek için</span>
            <router-link to="/login" style="color: #fd4056;">üye girişi yapmalısın.</router-link>
          </span>
          <div v-else style="display:flex;color: #2d3640; font-weight: bold; font-size: 16px;">{{ userProfile.f_t_info.isPublic ? userProfile.f_t_info.line : 'Gizli ' }}

            <div v-if="!userProfile.f_t_info.isPublic" class="question-mark-wrapper" style="margin-left: 5px;" data-balloon="Freelancer dahili numarasını gizlemeyi tercih ediyor. Kendisine mesaj göndererek telefonla görüşme talebini iletebilirsin." data-balloon-pos="left" data-balloon-length="medium">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"/>
    <div>
      <div v-for="info in userProfile.generalInfo" style="margin-top: 30px; color: #8b95a1; font-size:14px; width: 100%">
        <div v-if="info.value" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
          <div style="display: flex">
            <img :src="info.icon_svg" onload="SVGInject(this)" class="info-icons">
            <div style="padding-left: 30px;" class="info-label">{{ info.name }}</div>
          </div>
          <p style=" color: #2d3640; font-size: 16px; font-weight:500;" :style="info.value === 'Çevrimiçi' ? 'color: #5beb17' : null">
            {{ convertTime(info) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { customDateFormat, diffFromNow }  from '@/utils/dateFns'

  export default {
    name: "ProfileInfoGig",
    props: {
      userProfile: {
        type: Object,
        default: () => {}
      },
      isPageLoaded: {
        type: Boolean,
        default: () => false
      },
      buttonLoading: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        enabledFreelancerStatistics: true,
        disableFollowButton: false,
        repetitiveText: "Bu sayı daha önce sipariş vermiş kullanıcıların, sonraki farklı günlerde tekrar verdikleri sipariş sayısını belirtir. Müşteri memnuniyeti ve hizmet kalitesinin en önemli göstergelerinden biridir.",
        reachLineText: "Yanda görülen telefon numarası ve dahili numara ile ilgili freelancer'a telefonla ulaşabilirsin. Operatörünün yurt içi sabit hat tarifesinden ücretlendirilirsin. Bu hattın ekstra bir ücreti yoktur.",
        followStatus: {
          action: null,
          actionClass: null,
          actionText: null
        }
      }
    },
    computed: {
      endingDateDiff() {
        const endingTimeStamp = this.$store.state.user.info.f_t_ending_at_timestamp
        return endingTimeStamp ? diffFromNow(endingTimeStamp, 'days') : null
      },
      endingDate() {
        const endingTimeStamp = this.$store.state.user.info.f_t_ending_at_timestamp
        return endingTimeStamp ? customDateFormat(endingTimeStamp, 'dd MMMM yyyy HH:mm') : null
      },
      isSelfUser() {
        return this.user.username === this.userProfile.username
      }
    },
    methods: {
      openFreelancerLineFaqModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FREELANCER_LING_FAQ});
      },
      extend(clickedFrom = null) {
        this.$store.state.clickedFrom = clickedFrom;
        this.api.user.useItem(2, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.api.user.extendLine(this.$Progress)
                .then(({data}) => {
                  let result = data;

                  if (result.success) {
                    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
                    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
                    this.$store.state.user.info.f_t_status = 3;
                    this.$toasted.global.errorToast({description: result.message});
                  } else {
                    this.$toasted.global.errorToast({description: result.message});
                  }
                });
            } else {
              if (result.data && result.data.ok && result.data.ok === 'wait') {
                this.$toasted.global.errorToast({description: result.message});
              } else if (result.data && result.data.cards) {
                const data = {
                  data: {
                    type: "item",
                    total: 12.90,
                    itemId: 2,
                    cards: result.data.cards,
                    walletAmount:result.data.walletAmount
                  }
                };
                this.$store.state.clickedFrom = clickedFrom;
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            }
          });
      },
      convertTime(info) {
        if (info.name === "Ort. Yanıt Süresi") {
          let timeStamp = info.value;
          let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
          if (years >= 1) {
            return years.toFixed(0) + " yıl"
          } else {
            let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
            if (months >= 1) {
              return months.toFixed(0) + " ay";
            } else {
              let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
              if (weeks >= 1) {
                return weeks.toFixed(0) + " hafta";
              } else {
                let days = timeStamp / 1000 / 60 / 60 / 24;
                if (days >= 1) {
                  return days.toFixed(0) + " gün";
                } else {
                  let hours = timeStamp / 1000 / 60 / 60;
                  if (hours >= 1) {
                    return hours.toFixed(0) + " saat"
                  } else {
                    let minutes = timeStamp / 1000 / 60;
                    if (minutes >= 1) {
                      return minutes.toFixed(0) + " dakika";
                    } else {
                      let seconds = timeStamp / 1000;
                      return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
                    }
                  }
                }
              }
            }
          }
        } else {
          return info.value;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gig-detail-user-information {
    width: 353px;
    padding: 20px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
  }
  .info-label{
    font-weight: 300;
    color: #8b95a1;
    font-size: 14px
  }

  .avatar-wrapper {
    position: relative;
    display: inline-flex;
    .status-icon {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: solid 2px #ffffff;
      background-color: #8b95a1;
      position: absolute;
      right: 11px;

    }
    .avatar {
      box-shadow: 0 0 0 30px rgba(127, 173, 244, 0.05), 0 0 0 20px rgba(127, 173, 244, 0.08), 0 0 0 10px rgba(127, 173, 244, 0.1);
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .username {
    font-size: 18px;
    color: #2d3640;
    font-weight: bold;
    margin-top: 40px
  }

  .userTitle {
    color: #8b95a1;
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
  }

  .star-rating /deep/{
    width: 18px;
    height: 18px;
    path {
      fill: #ffbf00
    }
  }

  .rating-number {
    font-weight: bold;
    font-size: 16px;
    color: #ffbe00;
    margin-left: 5px;
  }

  .comment-count {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

  .or-wrapper{
    display: flex;
    font-size: 12px;
    font-style: italic;
    color: #8b95a1;
    justify-content: center;
    margin-top: 25px;
    .dash{
      width: 20px;
      height: 1px;
      background-color: #8b95a1;
      margin: auto 3px;
    }
  }

  .freelancer-line-wrapper{
    margin-top: 26px;
    text-align: left;
    .info{
      display: flex;
      justify-content: space-between;
      .text-wrapper{
        font-size: 14px;
        display: flex;
        .text-general{
          margin: 0 10px;
          color: #8b95a1;
          font-weight: 300;
        }
      }
    }
  }

  .question-mark-wrapper {
    cursor: help;
    font-size: 12px;
    color: #2d3640;
    font-weight: 300;
    .question-mark-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #2d3640
      }
    }
  }

  .info-icons /deep/{
    height: 18px;
    width: 18px;
    path {
      fill: #bfc8d2;
    }
  }

  .user-info-rating-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
  }

  /* buttons*/
  .send-message-btn {
    font-size: 18px;
    line-height: 55px;
    font-weight: 600;
    color: #2d3640;
    text-align: center;
    margin: 25px auto 0;
    width: 290px;
    height: 56px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    cursor: pointer;
  }

  .line {
    border-top: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    margin: 30px 0;
  }

</style>
