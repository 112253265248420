<template>
  <div>
    <div v-loader-screen="waitUntilPaymentRequestDone" style="background-color: #fff; height: 100vh; margin: 0 auto; ">
      <div  v-if="!paymentSucceeded" :class="'mobileNavBar ' + deviceType">
        <div class="appBar-header">
          <div @click="routeBack('/',false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
            <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          </div>
          <div class="header-title-container">
            <p class="header-title">
              Siparişini Tamamla!
            </p>
          </div>
        </div>
      </div>
      <div style="padding-top: 56px"></div>

      <div v-if="!paymentSucceeded"  class="section-2">
        <div class="product-summary-body">
          <h4 class="card-header">Özet</h4>
          <div class="summary-items">
            <p class="item-title">Toplam Fiyat</p>
            <p class="item-value">{{checkoutApiResultData.total}} TL</p>
          </div>

          <div class="summary-items">
            <p class="item-title">Toplam Teslim Süresi</p>
            <p class="item-value">{{checkoutApiResultData.duration}} gün</p>
          </div>

          <button :disabled="waitingResponse" class="super-button summary-button" @click="buyNow()">
            <span>Siparişi Tamamla</span>
          </button>
          <span class="tamamla-help-text">Henüz sizden bir ödeme alınmayacak.</span>
        </div>
        <div style="flex-direction: row; align-items: center; margin-top: 16px;width: 100%; text-align: center ">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_visa.svg">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mastercard.svg">
        </div>
        <div style="text-align: center; margin-top: 16px;">
          <p style="font-weight: 600; color: #2d3640"><img style="width: 11px;" src="https://gcdn.bionluk.com/site/icon/ic_lock_2.svg"> <span style="padding-left:4px;padding-right:4px;color: #00a575">SSL</span> GÜVENLİ ÖDEME</p>
          <p style="font-weight: 400; font-size: 11px; margin-top: 6px;">Bilgilerin 256-bit SSL sertifikası ile şifrelenmektedir</p>
        </div>

      </div>


      <div v-if="paymentSucceeded" style="display: flex; justify-content: center; align-items: center; flex-direction: column; height: 350px;">
        <div class="image-circle">
            <span>
              <img style="width: 60px; height: 60px;" :src="$root.CDN_SITE + 'icon/ic_done_gray.svg'">
            </span>
        </div>
        <div style=" padding: 30px">
          <p class="h1_2" style=" font-size:16px; font-weight:400!important;color: #8b95a1!important;">
            Ödeme Başarılı! Çalışmaya başlamak için <router-link :to="'/orders/' + orderID">Sipariş Detayı</router-link> sayfasına git.
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { getCurrentYear, getCurrentMonth } from '@/utils/dateFns'

  export default {
    name: "src-pages-body-checkout-v4_mobile",
    data() {
      return {
	      waitingResponse:true,
        mobilePopSound: null,
        cardOwner: null,
        cardNumber: null,
        month: null,
        year: null,
        cvc: null,
        orderID: null,
        total: null,
        waitUntilPaymentRequestDone: false,
        paymentSucceeded: false,
        showIframe: false,
        checkoutApiResultData: {},
        cards: [],
        cardListEnable: false,
        selectedCard: null,
        rememberCard: false,
        disableCardDelete: false
      }
    },
    methods: {
    	buyNow(){

    		let modalObj = {
    			data:this.checkoutApiResultData
        }

		    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: modalObj}});
      },
      activateIframe(iframeURL, htmlContent) {
        this.showIframe = true;
        this.$nextTick(()=> {
          let iframe = document.getElementById('checkoutIframeV2');
          iframe = iframe.contentWindow || ( iframe.contentDocument.document || iframe.contentDocument);

          iframe.document.open();
          iframe.document.write(htmlContent);
          iframe.document.close();

          //iframe.setAttribute('src', iframeURL)

          //iframe.src = iframeURL;
        });
      },
      checkIframe(event) {
        this.waitUntilPaymentRequestDone = false;
        let iframe = document.getElementById('checkoutIframeV2');
        try {
          let currentURL = iframe.contentWindow.location.href;

          currentURL = decodeURIComponent(currentURL);
          if (currentURL.indexOf('biyzib') > -1) {
            let info = currentURL.split('biyzib?info=')[1];
            info = JSON.parse(info);
            if (info.success) {
              this.paymentSucceeded = true;
              this.showIframe = false;
              this.orderID = info.orderID;

	           // this.checkoutApiResultData = result;
              //this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkoutApiResultData));

              this.trackConversionForPayedOrders(this.checkOutData.data.total, "TRY");
            } else {
              this.paymentSucceeded = false;
              this.showIframe = false;
              this.$toasted.global.errorToast({description: info.msg});
            }
          }

        } catch(err) {
          // farklı source geldigi zaman iframe hata verecek, hatayı gereksiz yere loglamamak icin try/catch 'e aldık
        }
      },
      makePayment() {
        this.waitUntilPaymentRequestDone = true;

        let payment = null;
        if (this.cardListEnable) {
          payment = {
            order_id: this.orderID,
            total: this.total,
            payment_type: "saved_card",
            card_id: this.selectedCard
          };
        } else {
          payment = {
            order_id: this.orderID,
            total: this.total,
            payment_type: "cc",
            cc_name: this.cardOwner,
            cc_no: this.cardNumber,
            cc_month: this.month,
            cc_year: this.year,
            cc_cvc2: this.cvc,
            remember_card: this.rememberCard
          };
        }

        this.api.orders.makePayment(payment, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data.iframeURL) {
                this.activateIframe(result.data.iframeURL, result.data.htmlContent);
              } else {


               // this.Helper.trackEvents.customEvent("orderpayed",this.Helper.checkoutPageProps(this.checkoutApiResultData));

                this.trackConversionForPayedOrders(this.total, "TRY");
                this.orderID = result.data.order_id;
                this.waitUntilPaymentRequestDone = false;
                this.paymentSucceeded = true;
              }

            } else {
              this.waitUntilPaymentRequestDone = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.waitUntilPaymentRequestDone = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      checkoutMobile(orderID) {

	      this.waitingResponse = false;

        this.orderID = orderID;
        this.api.orders.checkout(this.orderID)
          .then(({data}) => {
            let result = data;

            if (result.success) {


              this.total = result.data.total;
              this.cards = result.data.cards;
              this.cardListEnable = !!this.cards.length;
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

	            this.checkoutApiResultData = result.data;

              this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data));
              this.trackConversionForCheckoutedOrders(this.total, "TRY");
            } else {
            	if(result && result.message === 'paymentsucceed'){
            		this.paymentSucceeded = true;
              } else {
		            this.$toasted.global.errorToast({description: result.message});
              }

            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      selectCard(card) {
        this.selectedCard = card.card_id;
      },
      deleteCard(cardId) {
        if(this.disableCardDelete) {
          return false;
        }
        this.disableCardDelete = true;
        this.api.orders.forgetCard(cardId, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.cards = this.cards.filter(card => card.card_id === cardId)
              if(!this.cards.length) {
                this.cardListEnable = false;
              }
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.disableCardDelete = false;
          })
          .catch(err => {
            this.disableCardDelete = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },
    watch: {
      year(newValue) {
        if (newValue && getCurrentYear() === newValue && getCurrentMonth() >= this.month) {
          this.month = getCurrentMonth()+1
        }
      }
    },
    computed: {
      disableToPay() {
        if (this.cardListEnable) {
          return !this.selectedCard;
        } else {
          return !this.cardOwner ||
            !this.cardNumber ||
            !this.month ||
            !this.year ||
            !this.cvc ||
            !this.orderID
        }

      }
    },
    created() {
      this.checkoutMobile(this.$store.state.routerParams[0]);
    }
  }
</script>

<style scoped lang="scss">

  /*summary*/
  .product-summary-body {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 20px;
    background-color: #fff;
    border: 1px solid #eaedf2;
  }

  .summary-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .item-title {
    font-weight: 300;
    color: #2d3640;
    font-size: 14px;
  }

  .item-value {
    font-weight: 400;
    color: #2d3640;
    font-size: 16px;
  }

  .summary-button {
    margin-top: 48px;
    width: 200px;
    min-width: 200px;
    height: 48px;
    border-radius: 2px;
    background-color: #26ca9a!important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    align-self: center;
    display: flex;
    align-items: center;
    padding-bottom: 6px;

    &:hover {
      background-color: #00a575 !important;
    }


  }

  .tamamla-help-text {
    text-align: center;
    width: 100%;
    margin-top: 12px;
    font-size: 13px;
    font-weight: 300;
    color: #a8a8a8;
  }


  .header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }

  .header-title-container {
    padding-right: 18px;
  }

  .buy-credit-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 1px;
    background-color: #ffffff;
  }

  .ccinput {
    width: 265px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 9px;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }

  .numberCircle span {

    text-align: center;
    line-height: 30px;
    display: block;
    color: #5e6b79;
    height: 36px;
  }

  .super-drop {
    width: 126px;
    height: 50px;
    border-radius: 2px;
    background-color: #ffffff !important;
    border: solid 1px #2d3640;
    color: #2d3640;
  }

  .super-drop-option {
    font-size: 22px;
    font-weight: 600;
    color: #2d3640;
  }

  .buy-credit-content-header {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    padding: 20px 0;
  }

  .input-header {
    font-size: 14px;
    color: #2d3640;
    padding-bottom: 10px;
    margin: 0;
  }

  .super-button {
    min-width: 181px;
    height: 50px;
    border-radius: 2px;
    background-color: #c5cdd6;

    display: flex;
    justify-content: space-around;

    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }

  .select-year, .select-month {
    background: url("https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg") right center no-repeat;
    background-position-x: 60px;
    background-position-y: 18px;

    width: 80px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;

    font-size: 14px;
    text-align: left;
    color: #bfc8d2;

    line-height: 17px;
    padding: 8px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #dfe4ec;
    border: none;
  }

  .image-circle {
    border-radius: 50%;
    font-size: 28px;
    border: 6px solid #dde3eb;
    width: 100px;
    height: 100px;
    span {
      text-align: center;
      display: block;
      color: #5e6b79;
      line-height: 60px;
      width: 100px;
      height: 100px;
      padding-top: 20px;
    }
  }

  #checkoutIframeV2 {
    border: none;
    width: 100vw;
    margin-top: 30px;
  }

  /*card list*/

  .card-item-container {
    width: 100%;
    max-height: 250px;
    padding-top: 10px;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
  }

  .card-item-inner {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .card-item {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: solid 1px #2d3640;
    }
  }


  input[type=radio] {
    display: none;
  }

  input[type=radio] + .radio-label {
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    cursor: pointer;
    background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg) no-repeat;
  }

  input[type=radio]:checked + .radio-label {
    display: inline-block;
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
  }

  label {
    margin-bottom: 0;
  }

  .card-item-label {
    font-size: 16px;
    color: #a1a9a9;
    margin-left: 15px;
    display: unset;
    cursor: pointer;
  }

  .back-to-new-card {
    margin-right: auto;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 15px;
    color: #fd4056;
  }
  .back-to-new-card:hover {
    text-decoration: underline;
  }

  .bottom-card-list-action-container {
    width: 285px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

  }

  .back-to-card-list {
    cursor: pointer;
    color: #fd4056;
    font-size: 14px;
    text-decoration: underline;
  }

  .checkbox-box {
    cursor: pointer;
    position: relative;
    border: 1px solid #bfc8d2;
    height: 16px;
    width: 16px;
    background-color: #fff;
    margin-right: 10px;

    .checkbox-img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 16px;
      width: 16px;
    }
  }

  .remember-me-label {
    cursor: pointer;
    font-size: 13px;
    color: #2d3640;
  }

  .card-delete {
    cursor: pointer;
    margin-left: 10px;
  }
</style>
