<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div style="display: flex;justify-content: space-between">
        <a
          @click.stop.prevent="setWhereFrom"
          @contextmenu="setWhereFrom"
          :href="'/blog/'+items.avarel.slug" @click="openBlogDetailModal(items.avarel)" class="avarel-container">
          <p class="sub-title">{{items.avarel.subheader}}</p>
          <p class="title">{{items.avarel.header}}</p>
          <div>
            <div v-for="avarel_user in items.avarel.renderedLists.slice(0,5)" class="row">
              <div style="margin-top:30px;display: flex;">
                <img :src="avarel_user.image_url">
                <div class="right">
                  <p class="row-username">{{avarel_user.title}}</p>
                  <p class="row-title">{{avarel_user.subtitle}}</p>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; width: 100%; justify-content: center; margin-top: 15px;">
            <button class="black-button">{{items.avarel.button_text}}</button>
          </div>

        </a>

        <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
          <a
            @click.stop.prevent="setWhereFrom"
            @contextmenu="setWhereFrom"
            :href="'/blog/'+items.up_box.slug"
            @click="openBlogDetailModal(items.up_box)"  :class="items.up_box.style_type === 'long_title' ? ' freelancer-box_long_title': ' freelancer-box'" :style="'background-image: url('+items.up_box.image_url_new_today+')'">
            <template v-if="items.up_box.button_text">
              <p class="sub-title" :style="'color:'+items.up_box.color_mobile + '; backgroundColor:' + computedBG(items.up_box.header_bg_color)">{{items.up_box.header}}</p>
              <p class="title" :style="'color:'+items.up_box.color_mobile">{{items.up_box.subheader}}</p>
              <p class="sub-title2" :style="'color:'+items.up_box.color_mobile">{{items.up_box.subheader2}}</p>
              <p :class="items.up_box.color === 'black' ? 'red-button' : 'white-button'" >{{items.up_box.button_text}}</p>
            </template>
            <template v-else>
              <p class="sub-title" :style="'color:'+items.up_box.color_mobile + '; backgroundColor:' + computedBG(items.up_box.header_bg_color)">{{items.up_box.header}}</p>
            </template>
          </a>


          <a
            @click.stop.prevent="setWhereFrom"
            @contextmenu="setWhereFrom"
            :href="'/blog/'+items.down_box.slug"
            @click="openBlogDetailModal(items.down_box)" :class="items.down_box.style_type === 'long_title' ? ' freelancer-box_long_title': ' freelancer-box'" :style="'background-image: url('+items.down_box.image_url_new_today+')'">
            <template v-if="items.down_box.button_text">
              <p class="sub-title"  :style="'color:'+items.down_box.color_mobile + '; backgroundColor:' + computedBG(items.down_box.header_bg_color)">{{items.down_box.header}}</p>
              <p class="title" :style="'color:'+items.down_box.color_mobile">{{items.down_box.subheader}}</p>
              <p class="sub-title2" :style="'color:'+items.down_box.color_mobile">{{items.down_box.subheader2}}</p>
              <p :class="items.down_box.color === 'black' ? 'red-button' : 'white-button'" >{{items.down_box.button_text}}</p>
            </template>
            <template v-else>
              <p class="sub-title" :style="'color:'+items.down_box.color_mobile + '; backgroundColor:' + computedBG(items.down_box.header_bg_color)">{{items.down_box.header}}</p>
            </template>
          </a>


        </div>
      </div>
    </div>
  </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";

  export default {
    props: ["list"],
	  components:{Loader},
    data() {
      return {
	      pageLoaded:true,
        items: this.list || [],

      };
    },

    methods:{
      computedBG(hex){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ?
          'rgba('+ parseInt(result[1], 16) + ','+ parseInt(result[2], 16) + ',' +  parseInt(result[3], 16) + ',0.5)'
          : null;
      },
      setWhereFrom(){
        window.localStorage.setItem("whereFrom",  this.$store.state.componentMap.page);
        window.localStorage.setItem("clickedFrom", 'Today Generic');
        this.$store.state.clickedFrom = 'Today Generic';
      },
	    openBlogDetailModal(block) {
	    	//console.log(block);
        if(this.user.info){
          this.$store.state.clickedFrom = this.user.info.dynamic_segment_id ? 'Today Segment ' + this.user.info.dynamic_segment_id : 'Today Generic';
        }
		    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.BLOG_DETAIL, info: {block}});
	    },

	    retrieveItems() {

		    if(!this.list || this.list.length === 0){
			    this.pageLoaded = false;
			    let segment_id = this.user.info ? this.user.info.dynamic_segment_id : null;
			    this.api.feeds.newMainPage('latestTodayBox', segment_id)
				    .then(({data}) => {
					    let result = data;
					    this.pageLoaded = true;

					    //console.log(result.data);
					    if (result.success) {
						    this.items = result.data;
						    this.$store.state.todayBoxItems = result.data;
					    } else {
						    this.pageLoaded = true;
					    }
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: err});
				    });
		    } else {
			    this.pageLoaded = true;
        }

	    },
    },

    created() {

	    this.retrieveItems();
    }
  }
</script>

<style scoped lang="scss">


  .ufak-box{
    cursor: pointer;
    width: 241px;
    height: 270px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    &:hover {
      box-shadow: 0 15px 20px 0 rgba(45, 54, 64,.1);
      text-decoration: none!important;
    }

    .title-area{
      height: 96px; margin-left: 20px; margin-top: 15px;
    }

    .bg{
      border-top-right-radius: 10px;border-top-left-radius: 10px;width:241px; height:174px; background-size: cover; background-repeat: no-repeat;
    }

    .sub-title{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8b95a1;
    }

    .title{
      margin-top: 3px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #00a575;
    }
  }

  .freelancer-box{
    position: relative;
    cursor: pointer;
    width: 528px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    background-size:cover;

    &:hover {
      box-shadow: 0 15px 20px 0 rgba(40,60,98,.1);
      text-decoration: none!important;
    }

    .title{
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #2d3640; margin-top: 5px;
      padding-left: 40px;
    }

    .sub-title{
      font-size: 23px;
      font-weight: normal;
      padding-left: 40px;
      letter-spacing: .34px;
      text-align: left;
      line-height: 1.25;
      color: #2d3640; margin-top: 40px;

    }

    .red-button {
      position: absolute;
      bottom: 60px;
      width: 190px;
      height: 50px;
      padding-bottom: 3px;
      border-radius: 2px;
      background-color: #fd4056;
      border-color: #fd4056;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin-left: 40px;
      color: #ffffff;

      &:hover {
        background-color: #fd4056 !important;
      }
    }

      .white-button{
        position: absolute;
        bottom: 60px;
        width: 190px;
        height: 50px;
        padding-bottom: 3px;
        border-radius: 2px;
        background-color: transparent;
        border-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-left: 40px;
        color: #ffffff;
        &:hover{
          background-color: transparent!important;
        }
    }



    .sub-title2{

      font-weight: normal;
      padding-left: 40px;

      text-align: left;

      color: #2d3640; margin-top: 4px;

      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;


    }
  }


  .freelancer-box_long_title{
    position: relative;
    cursor: pointer;
    width: 528px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    background-size:cover;

    &:hover {
      box-shadow: 0 15px 20px 0 rgba(40,60,98,.1);
      text-decoration: none!important;
    }

    .title{
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #2d3640; margin-top: 5px;
      padding-left: 40px;

    }

    .sub-title{

      padding: 6px 10px 8px;
      border-radius: 6px;
      margin-left: 10px;
      margin-right: 10px;



      bottom:15px;

      text-align: left;
      position: absolute;

      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      color: #2d3640;

    }

    .red-button {
      position: absolute;
      bottom: 60px;
      width: 190px;
      height: 50px;
      padding-bottom: 3px;
      border-radius: 2px;
      background-color: #fd4056;
      border-color: #fd4056;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin-left: 40px;
      color: #ffffff;

      &:hover {
        background-color: #fd4056 !important;
      }
    }

    .white-button{
      position: absolute;
      bottom: 60px;
      width: 190px;
      height: 50px;
      padding-bottom: 3px;
      border-radius: 2px;
      background-color: transparent;
      border-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin-left: 40px;
      color: #ffffff;
      &:hover{
        background-color: transparent!important;
      }
    }



    .sub-title2{

      font-weight: normal;
      padding-left: 40px;

      text-align: left;

      color: #2d3640; margin-top: 4px;

      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;


    }
  }

  .avarel-container{

    cursor: pointer;
    width: 326px;
    height: 640px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    &:hover {
      box-shadow: 0 15px 20px 0 rgba(40,60,98,.1);
      text-decoration: none!important;
    }
    .black-button{

      margin-top: 40px;

      width: 268px;
      height: 47px;
      padding-bottom: 3px;
      border-radius: 2px;
      background-color: #2d3640;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      border: none !important;
    }
    .row{
      position: relative;
      width: 268px; margin: 0 auto; text-align: center;

      &::after{
        content:" ";
        background: #eff3f8;
        width: 268px;
        left: 0px;
        margin-top:15px;
        position: absolute;
        height: 1px;
      }

      &:last-child{
        &::after{
          background: #fff!important;
        }
      }
      img{
      width: 49px; height: 49px; border: 1px solid #eaeaea; border-radius: 50%;
     }

      .right{
        margin-left: 10px; display: flex; justify-content: center; flex-direction: column; height: 50px;
      }

      .row-title{
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 210px;

        font-size: 14px;
        text-align: left;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8b95a1;
      }
      .row-username{
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 210px;
        line-height: 1.2;
        height: 20px;

        text-align: left;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.5px;
        color: #2d3640;
      }
    }
    .sub-title{
      margin-left: 30px;
      margin-top: 22px; font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.8;
      letter-spacing: normal;
      color: #00a575;
    }
    .title{
      margin-left: 30px;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: #2d3640;
    }
  }




</style>
