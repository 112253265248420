<template>
  <div>
    <!-- MODAL -->
    <component v-activate-outer-modal-scroll
               :is="getModalComponentMap && modal[getActiveModal] ? modal[getActiveModal][getModalComponentMap[getActiveModal]] : null"/>
    <component v-activate-outer-modal-scroll v-if="oldModal"
               :is="getModalComponentMap && modal[getActiveSecondModal] ? modal[getActiveSecondModal][getModalComponentMap[getActiveSecondModal]] : null"/>
    <!-- HEADER -->
    <component
      :is="getComponentMap.header && header[getComponentMap.header.pageType] ? header[getComponentMap.header.pageType][getComponentMap.header.version] : null"/>

    <!-- BODY -->
    <div class="bodyContainer">
      <template v-if="!isMobile && getComponentMap.body.version !== 'chat_v2' && workstationRoutes.indexOf(getComponentMap.body.pageType) > -1">
        <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
        <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
        <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">
          <workstation-nav/>
          <component
            :is="getComponentMap.body && body[getComponentMap.body.pageType] ? body[getComponentMap.body.pageType][getComponentMap.body.version] : null"
            :style="{ 'min-height': windowHeight - 110 + 'px' }"
          />
        </div>
      </template>
      <component v-else
        :is="getComponentMap.body && body[getComponentMap.body.pageType] ? body[getComponentMap.body.pageType][getComponentMap.body.version] : null"/>
    </div>

    <!-- FOOTER -->
    <component
               :is="getComponentMap.footer && footer[getComponentMap.footer.pageType] ? footer[getComponentMap.footer.pageType][getComponentMap.footer.version] : null"/>

    <!-- MESSENGER -->
    <component
               v-if="isLoggedIn && !isMessengerActive" :is="message[getActiveMessageComponent]"/>

    <!-- VERSION -->
    <component v-if="!$store.state.isDevelopmentEnv && $store.state.newVersion && ($store.state.currentVersion < $store.state.newVersion) && $store.state.openedVersion"
               :is="version['v1']"/>

    <!-- OFF -->
    <component v-if="isOffline && !isOnline" :is="version['offline']" />

    <!-- ON -->
    <component v-if="isOffline && isOnline" :is="version['online']" />
    <survey-modal v-if="showSurvey && !isMobile" :uuid="showSurvey" style="z-index: 10000" @close="onSurveyModalClose" @click.native="$router.push(`/surveys/${showSurvey}`); showSurvey = false" />
    <buyer-service-info
      v-if="showBuyerServiceInfo && isLoggedIn && !isMobile && !user.seller.isSeller &&  user.info.persona === 'buyer'"
      :industries="industries"
      :employee-numbers="employeeNumbers"
      :organization-types="organizationTypes"
      :business-stages="businessStages"
      style="z-index: 10000"
      @close="onBuyerServiceInfoClose"
    />
    <nps />
  </div>
</template>

<script>
  import header from './header'
  import body from './body'
  import footer from './footer'

  import modal from './modals'
  import message from './message'
  import version from './version'

  import nps from './body/nps/nps'
  import WorkstationNav from './components/shared/globalSideNav'
  import { diffFromNow }  from '@/utils/dateFns'
  import Api from '@/utils/request'
  import BuyerServiceInfo from '@/pages/modals/buyerServiceInfo/v1'
  import SurveyModal from "@/pages/modals/survey/v1";

  export default {
    name: "main-file", // do not use reserved words like 'main'
    components: {SurveyModal, BuyerServiceInfo, nps, WorkstationNav},
    data() {
      return {
        header,
        body,
        footer,
        modal,
        message,
        version,
        BuyerServiceInfo,
        interval: null,
        oldModal: null,
        isOffline: false,
        isOnline: false,
        showBuyerServiceInfo: false,
        workstationRoutes: [
          'bicoin',
          'workstationPortfolio',
          'portfolioCreate',
          'portfolioEdit',
          'gigsList',
          'gigsEdit',
          'gigsCreate',
          'sales',
          'workstation_finance',
          'workstation_favorites',
          'workstation_favorites_detail',
          'workstation_requests',
          'workstation_request_edit',
          'workstation_request_offers',
          'workstation_orders',
          'workstationOrdersDetail',
          'workstationBuyerRequests',
          'workstation_messages',
          'basvuru',
          'workstation_wallet'
        ],
        industries: [],
        employeeNumbers: [],
        organizationTypes: [],
        businessStages: [],
        showSurvey: false
      }
    },
    methods: {
      keyPressEvent(event) {
        if (event.keyCode === 27) {
          const modalType = this.$store.state.activeModal.modalType
          if (modalType !== 'uploadGalery' && modalType !== 'unlockChat' && modalType !== 'orderAction' && modalType !== 'orderContactInfo') {
            this.$store.state.activeModal.modalType = null;
          }
        }
      },
      onBuyerServiceInfoClose() {
        this.showBuyerServiceInfo = false
        localStorage.setItem(`buyerInfoServiceDate_${this.user.id}`, (new Date()).toISOString())
      },
      onSurveyModalClose() {
        this.showSurvey = false
        this.checkBuyerServiceInfo()
      },
      checkBuyerServiceInfo() {
        const buyerInfoServiceDate = localStorage.getItem(`buyerInfoServiceDate_${this.user.id}`)
        const isEligibleToShowInfoService = this.isLoggedIn && !this.isMobile && !this.user.seller.isSeller
        if ((!buyerInfoServiceDate || diffFromNow(new Date(buyerInfoServiceDate), 'days') > 4) && isEligibleToShowInfoService) {
          Api.get('/users/business-info')
            .then(({ data }) => {
              this.industries = data.industries
              this.employeeNumbers = data.numberOfEmployees
              this.organizationTypes = data.organizationTypes
              this.businessStages = data.businessStages
              if (!data.userBusiness) {
                this.showBuyerServiceInfo = true
              } else {
                this.onBuyerServiceInfoClose()
              }
            })
        }
      }
    },
    computed: {
      getComponentMap() {
        return this.$store.state.componentMap;
      },

      getModalComponentMap() {
        return this.$store.state.modalComponentMap;
      },

      getActiveMessageComponent() {
        return this.$store.state.activeMessageComponent.split('/')[1]
      },

      getActiveModal() {
        if (this.oldModal) {
          return this.oldModal;
        } else {
          return this.$store.state.activeModal.modalType;
        }
      },

      getActiveSecondModal() {
        if (this.oldModal) {
          return this.$store.state.activeModal.modalType;
        } else {
          return null;
        }
      },

      isMessengerActive() {
        let fullPath = this.$store.state.route.fullPath;
        let firstSegmentOfPath = fullPath.split('/')[1];
        return fullPath === "/panel/portfolyo/yeni" || fullPath === "/panel/ilanlar/yeni" || (fullPath.split('/') && fullPath.split('/')[3] &&  fullPath.split('/')[3] === 'duzenle') || (fullPath.split('/') && fullPath.split('/')[4] &&  fullPath.split('/')[4] === 'duzenle') || firstSegmentOfPath === "/panel/ilanlar/duzenle" || fullPath === "call";
      }
    },
    watch: {
      "$store.state.route.fullPath": function () {
        this.cancelUploadRequest('all')
        if (!this.isMobile) {
          window.scrollTo(0, 0)
        }
      },

      "$store.state.activeModal": function (newValue, oldValue) {
        if (newValue.description === 'keepOldModal' && oldValue.modalType && newValue.modalType) {
          this.oldModal = oldValue.modalType;
        } else if (newValue.description !== 'keepOldModal' && oldValue.description !== 'keepOldModal') {
          this.oldModal = null;
        }
      },

      "$store.state.activeModal.modalType": function (newValue) {
        if (newValue) {
          document.addEventListener("keyup", this.keyPressEvent, false);
          if (newValue !== 'blogDetail' && newValue !== 'checkout'  && newValue !== 'portfolio'){
	          //openedModal eventinin gonderilmesini istemedigimiz modallar buraya yaziliyor
	          this.Helper.trackEvents.modalView();
          }
        } else {
          document.removeEventListener("keyup", this.keyPressEvent, false);
        }
      },

      // deep: true işe yaramıyor hack yolla yapıldı bakılacak
      '$store.state.uploadStatus': {
        handler: function (newValue, oldValue) {
          let isUploadingActive = false;
          for (let key in newValue) {
            if (newValue[key] !== null) {
              for (let subKey in newValue[key]) {
                if (newValue[key][subKey] !== null) {
                  isUploadingActive = true;
                }
              }
            }
          }
          if (isUploadingActive) {
            window.onbeforeunload = function () {
              return false;
            };
          } else {
            window.onbeforeunload = null;
          }
        }, deep: true
      },
      "$store.state.isIdle": function (newValue, oldValue) {
        if (oldValue === false && newValue) {
          this.isOffline = true;
          this.isOnline = true;
        }
      }
    },
    created() {
	    window.addEventListener('online',  result => {
		    this.isOnline = true;
		    if(this.isOffline) {
          this.$store.state.isIdle = true;
        }
	    });
	    window.addEventListener('offline', result => {
		    this.isOffline = true;
		    this.isOnline = false;
	    })
      this.EventBus.$on('closeSurveyModal', this.onSurveyModalClose)

      this.$store.state.calledInit = true;
      if (this.route.fullPath === "/") {
        this.Helper.trackEvents.pageView();
      }
      this.$nextTick(() => {
        window.prerenderReady = true;
      })
      if (this.isLoggedIn && !this.isMobile) {
        Api.get('/users/surveys/check')
          .then(({ data }) => {
            // data --> uuid or false

            if (!this.$route.fullPath.includes(`/surveys/${data}`)) {
              this.showSurvey = data
            }
          })
          .finally(() => {
            if (!this.showSurvey) {
              this.checkBuyerServiceInfo()
            }
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .bodyContainer {
    position: relative;
  }

  .modal-mask {
    position: fixed;
    z-index: 9997;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-container::-webkit-scrollbar {
    display: none;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modalA-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modalA-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .modalA-enter {
    opacity: 0;
  }

  .modalA-leave-active {
    opacity: 0;
  }

  .modalA-enter .modalA-container,
  .modalA-leave-active .modalA-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modalLikes-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modalLikes-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .modalLikes-enter {
    opacity: 0;
  }

  .modalLikes-leave-active {
    opacity: 0;
  }

  .modalLikes-enter .modalLikes-container,
  .modalLikes-leave-active .modalLikes-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  /*pagination*/
  .pagination {
    border-radius: 0;
    padding: 10px 0 20px;
    margin: 0;
    display: flex;
    justify-content: center;

    li {
      padding: 0 10px;
      font-size: 18px;

      a {
        color: #4b4f52;
      }
    }

    .active {
      text-decoration: underline;

      a {
        padding-bottom: 10px;
        color: #fd4056;
      }
    }
  }

  .pagination-child {
    color: #337ab7;
    padding: 7px 12px;
    border: 1px solid;
    border-color: #ddd;
    cursor: pointer;
    font-size: 16px;
  }

</style>
