<template>
  <div class="register" style="margin-bottom: 100px;">
    <div style="margin: 0 auto; width: 1170px; text-align: center">
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>

      <facebook-login v-if="false"></facebook-login>
      <div class="hiddendiv20"></div>
      <google-login></google-login>


      <div class="hiddendiv"></div>
      <div class="or">
        <span>veya</span>
      </div>
      <div class="hiddendiv10"></div>

      <div class="social-button">
        <button class="super-stroked-button" @click="registerWithEmail()">e-posta ile kayıt ol</button>
      </div>

      <div class="hiddendiv"></div><div class="hiddendiv"></div>
      <div style="font-size: 13px; width: 600px; margin: 0 auto;">
        Kaydol veya Devam et'e basarak
        <a target="_blank" :href="`${prefixPath}pages/kullanici-sozlesmesi`">kullanıcı sözleşmesi</a> ve <a target="_blank" :href="`${prefixPath}pages/gizlilik`">gizlilik sözleşmesi</a>ni kabul etmiş sayılırsın.
      </div>

      <div class="hiddendiv20"></div>
      <p class="subheading2-text" style="font-weight: 500"> Zaten bir hesabın var mı? <router-link :to="`/login`">Giriş Yap</router-link></p>
    </div>
  </div>
</template>

<script>
  import mixin from '../mixin';

  export default {
    name: "src-pages-body-auth-register-desktop-v1",
  	mixins: [mixin],
    components: { },
    data() {
      return { }
    },

    methods: { }
  }
</script>

<style scoped>

  .super-stroked-button{
    width: 344px;
    height: 60px;
    margin-top: 12px;
    border-radius: 2px;

    border: none;
    background-color: white;

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #fd4056;

  }

  .super-stroked-button:hover{background: white!important;}


  form {
    text-align: center;
  }

  input {
    width: 440px;
    height: 20px;
    background-color: #ffffff;
    border: 1px solid #ccd3d3;
    border-radius: 2px;
    padding: 20px;
    margin: 30px;

    color: #2d3640;
  }


</style>
