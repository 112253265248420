/**
 * Created by haluk on 8/7/17.
 */


import v1_mobile from './v1_mobile.vue';

export default {
  v1_mobile
}
