/**
 * Created by haluk on 4/14/17.
 */


const v4 = resolve => require(['./v4.vue'], resolve);

const v4_mobile = resolve => require(['./v4_mobile.vue'], resolve);
const v_logged_in = resolve => require(['./v_logged_in.vue'], resolve);
const for_freelancers = resolve => require(['./for_freelancers/for_freelancers.vue'], resolve);
const for_freelancers_mobile = resolve => require(['./for_freelancers/for_freelancers_mobile.vue'], resolve);

const pro = resolve => require(['./proLanding/proLanding.vue'], resolve);
const pro_mobile = resolve => require(['./proLanding/pro_mobile.vue'], resolve);
const bicoin = resolve => require(['./bicoin/bicoin.vue'], resolve);
const bicoin_mobile = resolve => require(['./bicoin/bicoin_mobile.vue'], resolve);
const token_redirect = resolve => require(['./TokenRedirect.vue'], resolve);
const token_redirect_mobile = resolve => require(['./TokenRedirect.vue'], resolve);




export default {


  v4_mobile,
  v_logged_in,
  for_freelancers,
  for_freelancers_mobile,
  v4,
  pro,
  pro_mobile,
  bicoin,
  bicoin_mobile,
  token_redirect,
  token_redirect_mobile
}

