/**
 * Created by haluk on 7/26/17.
 */


import v1 from './v1.vue';
import offline from './offline.vue';
import online from './online.vue';

export default {
  v1,offline,online
}
