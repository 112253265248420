<template>

  <!--  <div :ref="uuid" @click="waveSurfer.isPlaying() ? waveSurfer.pause() : waveSurfer.play()"></div>
-->
  <div style="padding: 10px 0">
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <div>
          <p v-show="isPlaying" class="icon-play-container" @click="waveSurfer.playPause()">
            <img  class="icon-play" src="https://gcdn.bionluk.com/uploads/message/aeebe165-cce3-4b6b-8b2c-1f6db51e7a98.svg" onload="SVGInject(this)"/>
          </p>
          <p v-show="!isPlaying" class="icon-play-container" @click="waveSurfer.playPause()">
            <img class="icon-play" src="https://gcdn.bionluk.com/site/cicons/ic-play.svg" onload="SVGInject(this)"/>
          </p>
        </div>
        <div style="margin-left: 14px; margin-right: 15px;">
          <p :style="isMini ? 'max-width: 140px; ' : 'max-width: 240px; '" style="word-break: break-word;text-overflow: ellipsis;overflow: hidden; white-space: pre;line-height: 1.29;color: #8b95a1; font-size: 13px; margin-bottom: 8px;">{{ this.fileName }}</p>
          <a target="_blank" :href="this.soundUrl" :download="this.fileName" class="download-icon-container-wave">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon-wave">
            Dosyayı İndir
          </a>
        </div>

      </div>
      <div v-if="!isMini" style="color: #8B95A1; font-size: 13px;  display: flex; justify-content: flex-end; padding-right: 10px;">
          <span v-if="currentTime">{{formatSeconds(currentTime)}}/</span>{{formatSeconds(totalTime)}}
      </div>
    </div>
    <div style="padding: 20px 20px 0 20px">
      <div  :ref="uuid"  @click="waveSurfer.playPause()">
      </div>

    </div>

  </div>


</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import axios from 'axios'

export default {
  name: "WaveSurfer",
  props: ["soundUrl", "helperUrl", "fileName","isMini"],
  data() {
    return {
      uuid: null,
      waveSurfer: {},

      isPlaying:false,
      totalTime:0,
      currentTime:0,
      remainingTime:0,
    }
  },
  mounted() {
    let wsOptions = Object.assign({ container: this.$refs[this.uuid] }, {

      waveColor: '#5e6b79',
      progressColor: '#34516f',
      cursorColor:'#34516f',

      height:40,
      barGap: 1.1,
      barWidth: 1.8,
      barRadius: 2,
      cursorWidth: 2,

      pixelRatio:2,

      isPlaying:false,
    })
    this.waveSurfer = new WaveSurfer.create(wsOptions)

    if (this.helperUrl) {

      axios.get(this.helperUrl)
        .then(({data}) => {
          this.waveSurfer.load(this.soundUrl)

        })
        .catch(() => {
          this.waveSurfer.load(this.soundUrl)
        })
    } else {
      this.waveSurfer.load(this.soundUrl)
    }

    this.waveSurfer.on('ready', () => {
      this.totalTime = this.waveSurfer.getDuration().toFixed(0);
    })

    this.waveSurfer.on('pause', () => {
      this.waveSurfer.params.container.style.opacity = 1
      this.isPlaying = false;
    })


    this.waveSurfer.on('play', () => {
      this.waveSurfer.params.container.style.opacity = 1
      this.isPlaying = true;
    })

    this.waveSurfer.on('audioprocess', () => {
      if(this.waveSurfer.isPlaying()) {
        this.currentTime = this.waveSurfer.getCurrentTime().toFixed(0);
        this.remainingTime = this.totalTime - this.currentTime;
      }
    });
  },
  methods: {
    formatSeconds(seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
      return [minutes +( hours*60), seconds % 60].map(format).join(':')
  },
    normalize(list) {
      return list
    }
  },
  created() {
    this.uuid = this.Helper.generateUUID();
  },
  beforeDestroy() {
    this.waveSurfer.unAll()
    this.waveSurfer.destroy()
  }
}
</script>
<style lang="scss" scoped>


.download-icon-container-wave{

  display: flex;
  align-items: center;
  height: 13px;
  cursor: pointer;
  color: #2d3640;
  letter-spacing: -0.5px;

}
.download-icon-wave /deep/ {

  width: 13px;
  height: 13px;
  margin-right: 5px;
  path {
    fill: #2d3640;
  }
}


.icon-play-container{
  margin-left: 10px;

  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #65c4db;
  background: #65c4db;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    opacity: 0.8;
  }
  .icon-play /deep/{
    width: 16px;
    height: 16px;
    path{
      fill: #fff;
    }
  }
}


</style>
