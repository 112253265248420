/**
 * Created by haluk on 4/14/17.
 */

const v1 = resolve => require(['./v1.vue'], resolve);
const v1_mobile = resolve => require(['./v1.vue'], resolve);

export default {
  v1,
  v1_mobile

}

