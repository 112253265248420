<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container" @click.stop v-if="getModalInfo">
        <div class="close-div">
          <img v-if="title !== 'IBAN Bilgisi'" @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>

        <div style="text-align: center; width: 550px; margin: 60px auto 60px;">
          <p v-if="type === 'create'" class="content-header-text">
            Yeni Liste <span style="font-weight: 600">Oluştur</span>
          </p>
          <p v-if="type === 'update'" class="content-header-text">
            Listeyi <span style="font-weight: 600">Güncelle</span>
          </p>
          <p style="margin: 20px auto; max-width: 400px;  font-weight: 300; font-size: 16px; line-height: 1.44">İlgini çeken freelancerlara ve onların iş ilanlarına kolayca ulaşabileceğin listeler oluştur.</p>

          <div style="margin-top:50px; display: flex; flex-direction: column; align-items: center; justify-content: center">
            <custom-input label="Liste Adı"
                          place-holder="Örn: SEO Uzmanları"
                          inputType="text"
                          v-model="title"
                          maxlength="30"
                          style="width: 366px;"/>

            <custom-button @click="updateList" :disabled="buttonLoading" :button-type="'green'" style="margin-top: 50px; width: 164px; height: 44px; padding-bottom: 2px">Kaydet</custom-button>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

import CustomButton from "@/pages/components/CustomButton";
import CustomInput from "@/pages/components/CustomInput";
export default {
  name: "src-pages-modals-addList-v1",
  components:{CustomInput, CustomButton},
  data () {
    return {
      loadedPage: null,
      type:null,
      id:null,
      title:'',
      buttonLoading:false
    }
  },
  methods: {

    updateList(){
      if(this.buttonLoading) {return false}
      this.buttonLoading = true;
      this.api.general.crudList(this.type, this.title, this.id, null, this.$Progress)
        .then(({data}) => {
          let result = data;
          if(result.success){
            this.EventBus.$emit('newList', result.data);
            this.$toasted.global.infoToast({description: result.message});
            this.hide();
          } else {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.buttonLoading = false;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        }).finally(() => {

      })

    },
    hide() {
      this.$store.commit(this.types.DISABLE_MODAL);
    }
  },

  computed: {
    getModalInfo() {

      let info  = this.$store.state.activeModal.info;
      console.log(info)
      this.type = info.data.type;
      this.loadedPage = true;

      return true;
    }
  }
}

</script>

<style lang="scss" scoped>



.modal-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, .8);
}

.modal-container {
  font-family: 'sofia-pro';
  margin: auto;
  padding: 0;
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px);
  max-width: 600px;
  max-height: 450px;
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #3a4653;
  /*overflow-y: scroll;*/
  position: relative;
  border-radius: 5px;
}

.close-div {
  position: absolute;
  top: 16px;
  right: 16px;
}

.content-header-text{
  font-size: 34px;
  font-weight: normal;
  color: #3c4654;
}
.content-header{
  margin-top: 31px;
  margin-bottom: 13px;
  width: 849px;
  align-items: center;
}

</style>
