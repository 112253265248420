<template>
  <div class="fixed-content">
    <div style="display: flex;" :style="activeStep > 0 && activeStep < 5 ? 'justify-content: space-between': 'justify-content: end'">
      <div v-if="activeStep > 0 && activeStep < 5" style="display: flex">
        <img src="https://gcdn.bionluk.com/site/cicons/left_arrow_f.svg" style="width: 16px; height: 16px; cursor: pointer" @click="activeStep--" />
        <div class="step-watcher" style="margin-left: 16px">{{activeStep}} / 4 Soru</div>
      </div>
      <img src="https://gcdn.bionluk.com/site/cicons/close_circle.svg" style="cursor: pointer" @click="$emit('close')" />
    </div>
    <div v-if="activeStep === 0" style="text-align: center; margin-top: 4px">
      <img style="width: 90px; height: 60px" src="https://gcdn.bionluk.com/site/images/buyer_service_cover.png" />
      <div class="title">
        Ne tür bir işle ilgileniyorsun, hadi bize anlat!
      </div>
      <div class="description">
        Sana uygun bir deneyim oluşturmamıza yardımcı ol.
      </div>
      <custom-button :button-type="'red'" style="margin-top: 12px; width: 312px;" @click="activeStep = 1">Devam Et</custom-button>
    </div>
    <div v-if="activeStep === 1" class="mt-2">
      <div style="margin: 12px 0" class="title">
        Harika! İşletmende kaç kişi çalışıyor?
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div
          v-for="employeeNumber in employeeNumbers"
          :key="employeeNumber.id"
          class="button-box"
          :class="employeeNumber.id === businessInfo.numberOfEmployeesId ? 'active' : null"
          @click="businessInfo.numberOfEmployeesId = employeeNumber.id; activeStep = 2"
        >
          {{ employeeNumber.name }}
        </div>
      </div>
    </div>
    <div v-if="activeStep === 2" class="mt-2">
      <div style="margin: 12px 0" class="title">
        Ne tür bir organizasyona sahipsiniz?
      </div>
      <div style="display: flex; flex-wrap: wrap; width: 280px">
        <div
          v-for="organizationType in organizationTypes"
          :key="organizationType.value"
          class="button-box organization"
          :class="organizationType.value === businessInfo.organizationType ? 'active' : null"
          @click="businessInfo.organizationType = organizationType.value; activeStep = 3"
        >
          {{ organizationType.name }}
        </div>
      </div>
    </div>
    <div v-if="activeStep === 3" class="mt-2">
      <div style="margin: 12px 0" class="title">
        İşletmen hangi aşamada?
      </div>
      <div style="display: flex; flex-wrap: wrap; width: 280px">
        <div
          v-for="businessStage in businessStages"
          :key="businessStage.value"
          class="button-box organization"
          :class="businessStage.value === businessInfo.stage ? 'active' : null"
          @click="businessInfo.stage = businessStage.value; activeStep = 4"
        >
          {{ businessStage.name }}
        </div>
      </div>
    </div>
    <div v-if="activeStep === 4" class="mt-2">
      <div style="margin: 12px 0" class="title">
        Hangi sektörlerde hizmet veriyorsun?
      </div>
      <div style="margin: unset" class="description">
        En fazla 2 seçim yapabilirsin.
      </div>
      <div style="margin-top: 12px">
        <custom-select
          id="industries-service-info"
          label="Sektör"
          placeHolder="Sektör seçmek için bu alanı doldurmaya başla..."
          inputType="search"
          :disabled="disableIndustryInput"
          :options="industrySearchResults"
          :exact-match-exists="exactIndustryExists"
          :customOption="{ name: 'name', value: 'id' }"
          v-model="industrySearchTerm"
          @change="selectBoxOnChange"
          @focus="openSkillTooltip = false"
          style="width: 312px;"
        >
          <li class="not-founded-select-box-item suggestion-text">
            Böyle bir sonuç bulamadık. 😔
          </li>
        </custom-select>
      </div>
      <div class="editable-industry-body" style="margin-top: 12px">
        <div v-for="industry in businessInfo.industries" :key="industry.id">
          <div class="editable-industry-item" @click="deleteIndustry(industry)">
            {{industry.name}}
            <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-industry-icon"/>
          </div>
        </div>
      </div>
      <custom-button :button-type="'green'" :disabled="businessInfo.industries.length < 1" class="mt-2" style="width: 312px;" @click="updateBasicInfo">Tamamla</custom-button>
    </div>
    <div v-if="activeStep === 5" class="mt-3" style="text-align: center;">
      <img style="width: 40px; height: 40px" src="https://gcdn.bionluk.com/site/cicons/success_done.svg" />
      <div class="title" style="width: 312px; margin-top: 28px">
        Zaman ayırdığın için teşekkürler!
      </div>
      <div class="description" style="width: 312px; margin-top: 24px">
        Değişiklik yapmak istersen, bu bilgilere "Hesabım"ı seçerek "İşin Hakkında" bölümünden <router-link style="color: #ff5364" to="/settings/business">ulaşabilirsin.</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/pages/components/CustomButton'
import Api from '@/utils/request'
import CustomSelect from '@/pages/components/CustomSelect'
import Fuse from 'fuse.js'
import store from "@/store";

export default {
  name: 'BuyerServiceInfo',
  props: {
    industries: {
      type: Array,
      default: () => []
    },
    employeeNumbers: {
      type: Array,
      default: () => []
    },
    organizationTypes: {
      type: Array,
      default: () => []
    },
    businessStages: {
      type: Array,
      default: () => []
    }
  },
  components: { CustomSelect, CustomButton },
  data() {
    return {
      activeStep: 0,
      disableIndustryInput: false,
      industrySearchResults: [],
      industrySearchTerm: '',
      exactIndustryExists: 0,
      businessInfo: {
        organizationType: null,
        numberOfEmployeesId: null,
        stage: null,
        industries: []
      }
    }
  },
  methods: {
    selectBoxOnChange(e) {
      this.industrySearchTerm = ''
      this.businessInfo.industries.push({ name: e.name, id: e.id })
    },
    deleteIndustry(receivedIndustry) {
      const foundIndex = this.businessInfo.industries.findIndex(industry => industry.id === receivedIndustry.id )
      this.businessInfo.industries.splice(foundIndex, 1)
    },
    async updateBasicInfo() {
      this.loader = true
      try {
        const payload = {
          organizationType: this.businessInfo.organizationType,
          stage: this.businessInfo.stage,
          numberOfEmployeesId: this.businessInfo.numberOfEmployeesId,
          industryIds: this.businessInfo.industries.map(industry => industry.id)
        }
        await Api.post('/users/business-info', payload)
        this.activeStep = 5
        localStorage.setItem(`buyerInfoServiceDate_${this.user.id}`, (new Date()).toISOString())
        if (this.$route.path === '/settings/business') {
          this.EventBus.$emit('refreshBusinessInfo')
        }
        this.$toasted.global.infoToast({description: "İşlem tamam!"})
      } catch(e) {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
      } finally {
        this.loader = false
      }
    }
  },
  watch: {
    'businessInfo.industries'(newVal) {
      this.disableIndustryInput = newVal && newVal.length >= 2
    },
    industrySearchTerm(newValue) {
      if (newValue.length < 20) {
        this.industrySearchResults = this.fuse.search(newValue.trim()).map(result => result.item).filter(result => !this.businessInfo.industries.find(industry => industry.id === result.id))
        this.exactIndustryExists = !!this.industries.find(skill => skill.name.toLocaleLowerCase('tr') === newValue.trim().toLocaleLowerCase('tr'))
      } else {
        this.industrySearchResults = []
      }
    }
  },
  created() {
    const options = { keys: ['name'], includeMatches: true, ignoreLocation: true, threshold: 0.4, findAllMatches: true }
    const index = Fuse.createIndex(options.keys, this.industries)
    this.fuse = new Fuse(this.industries, options, index)

    let trackingProps = {
      whereFrom: store.state.componentMap.page
    };
    this.Helper.trackEvents.customEvent("openedModal", trackingProps, null, 'buyerServiceInfo');


  }
}
</script>

<style lang="scss" scoped>
.fixed-content {
  width: 312px;
  min-height: 278px;
  right: 24px;
  bottom: 24px;
  position:fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(45, 54, 64, 0.05);
  border-radius: 12px;
  padding: 24px;
  color: #353C48;
}
.title {
  width: 240px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 12px auto;
}
.description {
  width: 256px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto;
}
.button-box {
  min-width: 80px;
  max-width: 132px;
  padding: 10px;
  height: 45px;
  border: 1px solid #EFF3F8;
  color: rgba(53, 60, 72, 0.6);
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  &:hover {
    opacity: 1;
    color: rgb(53, 60, 72);
    border: 1px solid #353C48;
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
  }
  &:not(:nth-child(3n)) {
    margin-right: 12px;
  }
  &:nth-child(n + 4) {
    margin-top: 12px;
  }
}

.button-box.active {
  color: rgb(53, 60, 72);
  border: 1px solid #353C48;
  padding: 9px;
  font-weight: 500;
  cursor: pointer;
}

.button-box.organization {
  &:not(:nth-child(2n)) {
    margin-right: 12px;
  }
  &:nth-child(n + 3) {
    margin-top: 12px;
  }
}
.editable-industry-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.editable-industry-item {
  cursor: pointer;
  position: relative;
  background-color: #eaedf2;
  border-radius: 4px;
  padding: 5px 28px 5px 10px;
  line-height: 24px;
  font-size: 14px;
  color: #2d3640;
  font-weight: 400;
  margin-right: 25px;
  margin-top: 10px;
  border: 1px solid #eaedf2;


  &:hover {
    background-color: #26ca9a;
    color: #fff;
    border: 1px solid #26ca9a;

    .delete-industry-icon /deep/ {
      #ic_close_black {
        fill: #fff;
      }
    }
  }
}

.delete-industry-icon /deep/ {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 3px;
  bottom: 0;
  margin: auto;

  #ic_close_black {
    fill: #bfc8d2;
  }
}

.step-watcher {
  font-size: 14px;
  opacity: 0.4;
  line-height: 13px;
}
</style>
