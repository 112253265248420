<template>
  <div :class="open ? 'accordion open' : 'accordion'">
    <div @click="$emit('toggleOpen', index) " class="title" >{{ accordion.title }}</div>
    <div v-if="open && accordion.asset" class="flex-centered-100 mt-3 mb-3">
      <video v-if="accordion.asset.type === 'video'" @play="sendPlayEvent(accordion.asset.id)" width="70%" :poster="accordion.asset.info.thumbnailUrl" controls>
        <source :src="accordion.asset.info.videoUrl" type="video/mp4">
        Tarayacınız video oynatımını desteklemiyor.
      </video>
      <a  v-else :href="accordion.asset.info.imageUrl" @click="sendPlayEvent(accordion.asset.id)" target="_blank">
        <img :src="accordion.asset.info.imageUrl" width="70%" />
      </a>
    </div>
    <div class="description mt-1" v-html="accordion.text" />
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: ["accordion", "index", "open", "impressionUUID"],
  data() {
    return {
      isVideoEventSend: false,
    }
  },
  methods: {
    sendPlayEvent(id) {
      console.log('dsad', this.impressionUUID)
      if(!this.isVideoEventSend){
        this.Helper.trackEvents.customEvent("custom_video_view", {
          title: this.accordion.title,
          id,
          impression_id: this.impressionUUID,
          page: this.$store.state.componentMap.page
        });
        this.isVideoEventSend = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.accordion {
  width: 100%;
    margin-bottom: 20px;
  display: block;
  padding: 30px 30px 15px 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;


  .title {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e6b79;
      cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%) rotate(0deg);

      width: 24px;
      height: 24px;
        background-image: url("https://gcdn.bionluk.com/site/cicons/y/ic-arrows-right.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      transition: all 0.2s linear;
    }
  }


  .description {

    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
    margin-top: 15px;
      cursor: default;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #5e6b79;
  }


}

  .accordion.open {
    .title {
      color: #00a575;
      margin-bottom: 20px;
      &:after {
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    .description {
      opacity: 1;
      max-height: 1000px;
    }
  }

</style>
