
const v4 = resolve => require(['./v5.vue'], resolve)
const v5 = resolve => require(['./v5.vue'], resolve)
const v4_mobile = resolve => require(['./v4_mobile.vue'], resolve);

export default {
  v4,
  v5,
  v4_mobile
}
