<template>
  <div class="package-pricing-user">
    <div v-if="packageCount > 1" class="package-segment-container">
      <p :class="{active: packageSegment === 'premium', segment: true}" @click="packageSegment = 'premium'">Pro</p>
      <p :class="{active: packageSegment === 'standard', segment: true}" @click="packageSegment = 'standard'">Standart</p>
      <p :class="{active: packageSegment === 'basic', segment: true}" @click="packageSegment = 'basic'">Temel</p>
    </div>
    <div class="package-content-container">
      <div v-if="packages[packageSegment].is_preferred_package" class="preferred-label">
        {{ packages[packageSegment].preferred_package_text }}
      </div>
      <p class="package-content-title">{{packages[packageSegment].title}}
        <!--<span style=" font-size: 24px; font-weight: 500; letter-spacing: -1px; color: rgb(45, 54, 64);">{{packages[packageSegment].price}}<span class="package-price-tl" style="font-size: 18px">TL</span></span>-->
      </p>
      <div class="package-description" style="margin-top: 9px">{{packages[packageSegment].description}}</div>
      <div class="content-info">
        <div class="content-delivery">
          <img class="content-info-img" src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg" onload="SVGInject(this)"/>
          <p class="content-title-big">{{packages[packageSegment].duration}} günde teslim</p>
        </div>
        <div class="content-revisions" >
          <img class="content-info-img" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)"/>
          <p class="content-title-big">{{packages[packageSegment].revisions}} revizyon hakkı
          <span style="cursor: help" :data-balloon="'Daha fazla revizyon hakkı talebin olursa, ekstralar bölümünden satın alabilirsin.'"  data-balloon-length="medium" data-balloon-pos="left"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="15"></span>
          </p>
        </div>
      </div>
      <div class="line"/>
      <div style="margin-top: 12px;">
        <!-- key is too important always use key on v-for-->
        <div class="content-scope" v-for="(option, index) in packages[packageSegment].options" :key="packageSegment + index">
          <img v-if="option.value" class="content-img-green" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
          <img v-else class="content-img-gray" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
          <p :class="{'content-title':true, passive: !option.value}">
            <span v-if="typeof option.value !== 'boolean'">
              <span v-if="option.id === 466">İlk </span>
              {{option.value}}<span v-if="option.selectboxHelpText"> {{option.selectboxHelpText}}</span>
            </span>
            {{option.title}}
          </p>
        </div>
      </div>
      <div class="content-button-div">
        <button class="super-button bottom-buy-button" v-if="user.username === seller.username"
                disabled
          :data-balloon="'Kendi ilanını satın alamazsın 🤓'"
          data-balloon-pos="up"
        ><span>Devam Et</span>
          <span>
            <span style="padding-left: 10px">[{{ packages[packageSegment].price | specifyCurrency(units.currency) }}]</span>
          </span></button>
        <button v-else class="super-button bottom-buy-button" :style="(gigStatus !== 1 || buttonLoading || user.username === seller.username) ? 'background-color: #8b95a1 !important;': null" :disabled="gigStatus !== 1 || buttonLoading || user.username === seller.username" @click="buyPackage()">
          <span>Devam Et</span>
          <span>
            <span style="padding-left: 10px">[{{ packages[packageSegment].price | specifyCurrency(units.currency) }}]</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PackagePricing",
    props: {
      packages: {
        type: Object,
        default: () => ({
          packages: {
            extras: []
          }
        })
      },
      seller: {
        type: Object,
        default: ()=> ({
          seller: {
            username: null,
            onlineStatus: false
          }
        })
      },
      gigStatus: {
        type: Number,
        default: () => 0
      },
      packageCount: {
        type: Number,
        default: () => 1
      },
      buttonLoading: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        packageSegment: 'basic'
      }
    },
    watch: {
      packages() {
        this.findActivePackageSegment()
      }
    },
    methods: {
      buyPackage(){
        this.$emit('packageClickAction', this.packages[this.packageSegment])
      },
      findActivePackageSegment() {
        // should be found two active packages so choose the first one. It's only defensive programming (isFound)
        let isFound = false
        for (let key in this.packages) {
          if (this.packages[key].is_preferred_package && !isFound) {
            isFound = true
            this.packageSegment = key
          }
        }
      }
    },
    created() {
     // console.log(this.seller);
      this.findActivePackageSegment()
    }
  }
</script>

<style lang="scss" scoped>
  button {
    &:disabled{
      background: #8b95a1!important;
      background-color: #8b95a1!important;
    }
  }

  .package-pricing-user {
    width: 393px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 16px;
    .package-segment-container {
      display: flex;
      .segment {
        cursor: pointer;
        padding: 15px 0;
        font-size: 18px;
        font-weight: 500;
        color: #2d3640;
        background-color: rgba(244, 245, 247, 0.5);
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        width: calc(33.33%);
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        z-index: 1;

        &:nth-child(3) {
          border-right: none;
        }
      }
      .active {
        box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, .3);
        border-bottom: none;
        background-color: #fff;
        z-index: 2;
      }
    }
    .package-content-container {
      padding: 20px;
      .preferred-label{
        max-width: 130px;
        height: 24px;
        overflow: hidden;
        border-radius: 10px;
        background-color: #f29933;
        line-height: 23px;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        letter-spacing: -0.22px;
        margin-bottom: 10px;
      }
      .package-content-title {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #4b4f52;
        font-size: 18px;
        span {
          font-weight: 300;
          letter-spacing: -2;
          font-size: 20px;
        }
        .package-price-tl {
          font-weight: normal;
          font-size: 22px;
          margin-left: 2px;
          color: #2d3640;
        }
      }
      .package-description {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 300;
        color: #4b4f52;
      }
      .content-info {
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
        .content-info-img /deep/ {
          width: 14px;
          height: 14px;
          margin: auto;
          path {
            fill: #fd4056;
          }
        }
        .content-delivery {
          display: flex;
        }
        .content-revisions {
          display: flex;
          margin-left: 24px;
        }
        .content-title-big {
          font-weight: 500;
          font-size: 16px;
          color: #2d3640;
          margin-left: 5px;
        }
      }
      .content-scope {
        display: flex;
        margin-bottom: 25px;
        .passive {
          opacity: .6;
        }
        .content-title {
          color: #2d3640;
          font-size: 16px;
          font-weight: 400;
          margin-left: 10px;
          line-height: 1;
        }
        .content-img-green /deep/{
          width: 16px;
          height: 16px;
          margin: auto 0;
          path {
            fill: #6dce6a;
          }
        }
        .content-img-gray /deep/{
          width: 16px;
          height: 16px;
          margin: auto 0;
          path {
            fill: #bfc8d2;
          }
        }
      }
      .content-button-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        .bottom-buy-button {
          width: 290px;
          height: 50px;
          text-align: center;
          border-radius: 2px;
          background-color: #26ca9a;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;

          padding-bottom: 5px;
          &:hover{
            background-color: #00a575 !important;
          }
        }
      }
    }
  }

  .line {
    border-top: 1px solid #f4f5f7;
    background-color: #f4f5f7;
  }

</style>
