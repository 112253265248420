<template>
  <div id="summary" style="background-color: #f4f5f7">
    <div v-if="!pageLoaded" class="deed-detail-loader-container">
      <div class="deed-detail-loader-container-header">
        <div class="deed-detail-breadcrumb-loader">
          <div class="deed-detail-breadcrumb"></div>
        </div>
        <div class="deed-detail-content-header-container">
          <div class="deed-detail-content-header-detail">
            <div class="deed-detail-content-header-text-large deed-loader">
            </div>
            <div class="deed-detail-content-header-text-small deed-loader">
            </div>
            <div class="deed-detail-content-header-text-xsmall-container">
              <div class="deed-detail-content-header-text-xsmall deed-loader"></div>
              <div class="deed-detail-content-header-text-xsmall deed-loader"></div>
              <div class="deed-detail-content-header-text-xsmall deed-loader"></div>
            </div>
          </div>
          <div class="deed-detail-content-header-button-container deed-loader">
            <div class="deed-detail-content-header-button-text-container">
              <div class="deed-detail-content-header-button-text deed-loader"></div>
            </div>
            <div class="deed-detail-content-header-button deed-loader">
            </div>
          </div>
        </div>
      </div>
      <div class="deed-detail-loader-container-bottom">
        <div class="deed-detail-content-container">
          <div class="deed-detail-content-portfolios">
            <div class="deed-detail-content-portfolio-image deed-loader"></div>
            <div class="deed-detail-content-portfolio-small-image-container">
              <div class="deed-detail-content-portfolio-small-image deed-loader"></div>
              <div class="deed-detail-content-portfolio-small-image deed-loader"></div>
              <div class="deed-detail-content-portfolio-small-image deed-loader"></div>
              <div class="deed-detail-content-portfolio-small-image deed-loader"></div>
              <div class="deed-detail-content-portfolio-small-image deed-loader"></div>
            </div>
          </div>
          <div class="deed-detail-content-seperator deed-loader"></div>
          <div class="deed-detail-content-detail-header-text">
            <div class="deed-detail-content-detail-header-text-large deed-loader"></div>
            <div class="deed-detail-content-detail-header-text-small deed-loader"></div>
          </div>
          <div class="deed-detail-content-header-text-xlarge">
            <div class="deed-detail-content-header-text-xlarge-item deed-loader"></div>
            <div class="deed-detail-content-header-text-xlarge-item deed-loader"></div>
            <div class="deed-detail-content-header-text-xlarge-item deed-loader"></div>
          </div>
          <div class="deed-detail-content-detail-price-button-container">
            <div class="deed-detail-content-detail-price-button deed-loader">

            </div>
          </div>
        </div>
        <div class="deed-detail-profile-container">
          <div class="deed-detail-profile-header">
            <div class="deed-detail-profile-header-image deed-loader"></div>
            <div class="deed-detail-profile-header-name deed-loader"></div>
            <div class="deed-detail-profile-header-title deed-loader"></div>
            <div class="deed-detail-profile-header-text-container">
              <div class="deed-detail-profile-header-title-text-small deed-loader"></div>
              <div class="deed-detail-profile-header-title-text-small deed-loader"></div>
            </div>
            <div class="deed-detail-profile-header-button deed-loader"></div>
          </div>
          <div class="deed-detail-profile-social-container">
            <div class="deed-detail-profile-social-button deed-loader">
            </div>
            <div class="deed-detail-profile-social-links">
              <div class="deed-detail-profile-social-link deed-loader"></div>
              <div class="deed-detail-profile-social-link deed-loader"></div>
              <div class="deed-detail-profile-social-link deed-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div id="navigation-wrapper" :class="navClasses">
        <div id="navigation" :class="navClasses">
          <div class="navigation-child" :class="navClasses" style="display: flex">
            <template>
              <a v-for="(section, index) in filteredSections" :key="index" @click="routeToSection(section)" class="section-navs" :id="`sc-${section.name}`" :class="activeSection === section.name ? 'selected' : null">
                {{ section.label }}
              </a>
            </template>
          </div>

          <div v-if="seller.username !== user.username" style="display: flex; justify-content: flex-end;  align-items: center; height: 62px; width: 450px; margin-right: 20px">
            <div v-if="user.username !== seller.username && navClasses === 'fixed'" class="contact-freelancer-wrapper" @click="sendMessage('nav fixed message button')" :style="buttonLoading ? 'opacity:0.4': 'opacity:1'">
              <div style="padding: 8px 7px;  display: flex; justify-content: space-between;">
                <div class="avatar-wrapper">
                  <div class="status-icon" :style="{backgroundColor: seller.onlineStatus ? '#5beb17' : '#8b95a1'}"/>
                  <img class="avatar" :src="seller.avatar_url" :style="{border: seller.onlineStatus ? '1px solid #5beb17' : '1px solid #8b95a1'}"/>
                </div>
                <div style="margin-left: 10px; margin-top: 2px">
                  <p style="font-size: 13px; font-weight: bold;">Freelancer'a</p>
                  <p style="font-size: 12px">Danış</p>
                </div>
              </div>
            </div>

            <div class="add-list-area">
              <div v-show="likeInfo.likeStatus.action !== -1" class="add-list-icon-container" @click="addListOpen = true">
                <img class="add-list-icon" src="https://gcdn.bionluk.com/uploads/message/00f6fa53-091c-4f3e-a086-2c499c2f7fce.svg" onload="SVGInject(this)"/>
              </div>
              <div v-show="likeInfo.likeStatus.action === -1" class="add-list-icon-container" @click="addListOpen = true">
                <img class="add-list-icon-red" src="https://gcdn.bionluk.com/uploads/message/205977ee-c82d-426d-93f7-ec98970c9fe3.svg" onload="SVGInject(this)"/>
              </div>

            <p v-if="likeInfo.likeStatus.action === 1" @click="addListOpen = true" class="add-list-text">Listeye Ekle</p>
              <p v-if="likeInfo.likeStatus.action === -1" @click="addListOpen = true" class="add-list-text-red">Listemde</p>
              <p v-if="likeInfo.likeCount" class="xbubble">
              <span style="cursor: help" :data-balloon="'Bu ilanı '+ likeInfo.likeCount +' kişi listesine ekledi'"
                   data-balloon-pos="down">{{ likeInfo.likeCount | number_format }}</span>
            </p>

            <div  v-click-away="() => addListOpen = false" v-if="addListOpen" class="setting-box">
              <loader style="width: 30px; height: 30px" v-if="buttonLoading"/>
              <template v-else>
                <div class="setting-box-options-overflow">
                  <div class="setting-box-options" v-for="(liste, lin) in lists" :key="lin" :style="lin + 1 !== lists.length ? ' border-bottom: solid 1px #eaedf2;' : ''">
                    <custom-check
                      :loading="checkLoading"
                      called-from="singlegig"
                      :label="liste.title"
                      :single-gig="liste.counts"
                      :id="'list_'+uuid+'_'+liste.id"
                      :checked="liste.liked"
                      :value="liste.liked"
                      @input="likeChecked(liste,lin)"
                    />



                  </div>


                  <div  class="settings-box-add-new mt-3" @click="openAddListModal">
                    <div class="ml-2">Yeni Liste Oluştur</div>
                    <div class="rounded-plus-button-gray">

                      <img class="icon" src="https://storage.googleapis.com/superlancer.appspot.com/public/ic-plus.svg" onload="SVGInject(this)">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="gig-detail-header-wrapper">
        <div class="gig-detail-header">

          <div class="breadcrumb">
            <router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'breadcrumb')"
              @click.native="handleGlobalClick($store.state.previousComponentPage,'breadcrumb')"
              style=" color: #2D3640;" :to="`/freelancer-bul/${gigCategory.slug}`">{{ gigCategory.name }}</router-link>
            <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
            <router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'breadcrumb')"
              @click.native="handleGlobalClick($store.state.previousComponentPage,'breadcrumb')"
              style="color: #8b95a1;  color: #2D3640;" :to="`/freelancer-bul/${gigSubCategory.slug}`">{{ gigSubCategory.name }}</router-link>
          </div>
          <div style="margin-top: 10px" v-if="adminoLink">
            <a class="admino-link" :href="adminoLink" target="_blank">Admino'da göster</a>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="deed-warning" v-if="warning.displayWarning" :style="`background-color:${warning.warningBoxBGColor};border:solid 1px ${warning.warningBoxBorderColor}; width:${seller.username === user.username ? 1193 : 820}px`">
              <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                <div v-if="displayWarningIcon" style="display: flex; margin-left: 25px; align-items: center">
                  <img :src="warning.warningIconSVG" onload="SVGInject(this)" :class="warning.warningIconSVGClass"/>
                  <div style="height: 50px; margin-left: 15px; display: flex; flex-direction: column; justify-content: space-between">
                    <p class="warning-header" :style="'color:' + warning.warningTextColor">{{ warning.warningHeader }}</p>
                    <p class="warning-text" :style="'color:' + warning.warningTextColor"> {{ warning.warningText }} </p>
                  </div>
                </div>
                <div v-if="seller.username === user.username" style="border-radius: 5px; margin-right: 30px" :style="{'background-color': warning.actionButtonContainerBGColor}">
                  <div style="display: flex; align-items: center; padding: 10px 20px">
                  <span v-show="gigStatus === 1" style="margin-right: 25px" @click="activateUseBicoinModal"
                        data-balloon="İlanı Öne Çıkar"
                        data-balloon-pos="up"
                  >
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-bicoinn.svg" class="action-button-svg" onload="SVGInject(this)"/>
                  </span>
                    <span
                      data-balloon="İlanı Yayından Kaldır"
                      data-balloon-pos="up"
                      v-show="gigStatus === 1" @click="changeGigStatus(2)" style="margin-right: 25px">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg" class="action-button-svg" onload="SVGInject(this)"/>
                  </span>
                    <span
                      data-balloon="Tekrar Yayına Al"
                      data-balloon-pos="up"
                      v-show="gigStatus === 2" @click="changeGigStatus(1)" style="margin-right: 25px">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" class="action-button-svg" onload="SVGInject(this)"/>
                  </span>
                    <span
                      data-balloon="Düzenle"
                      data-balloon-pos="up"
                      @click="$router.push(`/panel/ilanlar/duzenle/${uuid}`)">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" class="action-button-svg" onload="SVGInject(this)"/>
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="seller.username !== user.username && gigStatus === 2 " class="price-button-container" style="margin-top: 32px">
              <div style="width: 120px">
                <div style="margin-left: 30px; align-items: center; text-align: right">
                  <p style="font-size: 26px; font-weight: bold; letter-spacing: -1.4px; color: #2d3640;">
                    {{ (priceInfo.price).toLocaleString('tr') }}<span
                    style="font-weight: normal;   font-size: 24px; margin-left: 2px;">{{priceInfo.priceIcon}}</span>
                  </p>
                  <p style="font-size: 11px; font-weight: 300; color: #8b95a1; align-self: flex-end;margin-bottom: 2px" v-if="priceInfo.priceHelpText && priceInfo.priceHelpText.search('İşin Tamamı') < 0">
                    {{priceInfo.priceHelpText}}
                  </p>
                  <p style="font-size: 11px; font-weight: 300; color: #8b95a1; align-self: flex-end;margin-bottom: 2px" v-else-if="packageCount === 3">
                    Başlangıç
                  </p>
                </div>
              </div>

              <button class="super-button" :disabled="buttonLoading" id="message-button" @click="sendMessage">
                Mesaj Gönder
              </button>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; margin-top: 25px; margin-bottom: 50px">
            <div :style="packageCount !== 3 ? 'margin: auto 0;': null">
              <h1 class="header-title">
                Ben, {{ title }}
              </h1>
            </div>

            <div v-if="navClasses !== 'fixed' && user.username !== seller.username" class="contact-freelancer-wrapper-g" @click="sendMessage('big message button')" :style="buttonLoading ? 'opacity:0.4': 'opacity:1'">
              <div style="padding: 8px 7px;  display: flex; justify-content: space-between;">
                <div class="avatar-wrapper">
                  <div class="status-icon" :style="{backgroundColor: seller.onlineStatus ? '#5beb17' : '#8b95a1'}"/>
                  <img class="avatar" :src="seller.avatar_url" :style="{border: seller.onlineStatus ? '1px solid #5beb17' : '1px solid #8b95a1'}"/>
                </div>
                <div style="line-height: 1.4; width: 200px; margin-left: 20px">
                  <p>Soruların varsa hemen</p>
                  <p style="font-size: 14px; font-weight: bold;">Freelancer'a</p>
                  <p>danışabilirsin.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="gig-detail-body" :class="navClasses" style="display: flex; justify-content: space-between">
        <!--profil-->
        <div>
          <div class="gig-detail-body-inner">
            <div class="gig-detail-swiper" style="position:relative;">
              <img v-if="!activatedIframe" :alt="title" :style="'width:100%;object-fit:cover'" :src="content.cover.image_url">
              <div class="playIcon"
                   v-if="!activatedIframe && content.video_url && (content.file_type === 'audio' || content.file_type === 'video')">
                <img class="hoveropa" @click="activateIframe"
                     :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
              </div>

              <div v-if="videoLoader && content.video_url && (content.file_type === 'audio' || content.file_type === 'video')" >
                <img :style="'width:100%;object-fit:cover'" :src="content.cover.image_url">
                <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%); z-index: 44;">
                  <loader style="height: 30px;width: 30px;"></loader>
                </div>
              </div>


              <div v-if="activatedIframe">
                <iframe
                  @load="deactivateIframeLoader()"
                  v-show="!videoLoader"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  width="700" height="394"
                  frameborder="0"
                  scrolling="no"
                  :src="'https://api.bionluk.com/general/video/' + content.upload_id+'x'+content.cover.upload_id +'/gig_content/'"
                >
                </iframe>
              </div>
            </div>

            <div v-if="user.username === seller.username || receivedPortfolios.length" class="new-portfolio" style="margin-top: 10px; margin-bottom: 50px;">
              <div class="new-p-header">
                <div style="display: flex; align-items: center">
                  <p class="new-p-title">Portfolyo</p> <p class="new-badge" style="margin-left: 10px">Yeni</p>
                </div>
                <router-link :to="'/portfolyo/'+seller.username+'?gig_id='+this.gigID" class="green-show-all">{{user.username === seller.username ? 'Portfolyoyu' : 'Portfolyosunu'}} Görüntüle</router-link>
              </div>
              <div class="new-p-img-container">
                <div class="portfolio-item" @click="showPortfolio(portfolio.uuid)" v-for="(portfolio, index) in receivedPortfolios.slice(0,4)" :key="portfolio.uuid">
                  <div v-if="portfolio.fileType === 'audio'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                  <div v-if="portfolio.fileType === 'video'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                  <img class="new-p-img" :src="portfolio.imageURLSmall">
                </div>
              </div>
              <div v-if="user.username === seller.username" class="new-p-edit-container">
                <div class="inner-edit">
                  <img class="portfolio-icon" onload="SVGInject(this)" :src="$root.CDN_SITE + 'cicons/ic-my-portfolio.svg'">
                  <div v-if="receivedPortfolios.length > 0" style="margin-left: 15px" >
                    <p class="title-e">Buradaki Portfolyo İçeriklerini Değiştir</p>
                    <p class="sub-title-e">Yukarıda yer alan 4 portfolyo içeriğini değiştirebilir ve sıralayabilirsin.</p>
                  </div>
                  <div v-else-if="seller_sub_category_portfolio_count === 0" style="margin-left: 15px" >
                    <p class="title-e">Bu kategoride bir portfolyon yok 😞</p>
                    <p class="sub-title-e">Neler yapabildiğini alıcılara göstermek için etkileyici bir portfolyo oluştur.</p>
                  </div>
                  <div v-else style="margin-left: 15px" >
                    <p class="title-e">Bu ilanına henüz bir portfolyo eklemedin. 😞</p>
                    <p class="sub-title-e">Burada gözükmesini istediğin mevcut portfolyolarından seçim yap.</p>
                  </div>
                  <button @click="openEditFeaturedPortfolios" v-if="receivedPortfolios.length > 0" class="cuper-black-button"  style="width: 140px; height: 44px;border-radius: 2px; margin-left: auto; margin-right:20px; ">Düzenle</button>
                  <router-link v-else-if="seller_sub_category_portfolio_count === 0" to="/panel/portfolyo" class="cuper-black-button"  style="width: 140px; height: 44px;border-radius: 2px; margin-left: auto; margin-right:20px;">Oluştur</router-link>
                  <button @click="openEditFeaturedPortfolios" v-else class="cuper-black-button"  style="width: 140px; height: 44px;border-radius: 2px; margin-left: auto; margin-right:20px; ">Ekle</button>
                </div>
              </div>
            </div>

            <div v-if="commentInfo.comments.length" class="review-satisfy" style="margin-bottom: 50px;">
              <div class="new-p-header">
                <div style="display: flex; align-items: center">
                  <p class="new-p-title">
                    <template v-if="seller.username !== user.username">
                      <span style="color: #fd4056;">{{ formattedSellerUsername }}</span> çalışıp memnun kalanlar
                    </template>
                    <template v-else>
                      Memnun Müşteriler 🥳
                    </template>
                  </p>
                  <p v-if="seller.username !== user.username" class="new-badge" style="margin-left: 10px">Yeni</p>
                </div>
                <span @click="routeToSection({name: 'review-container'})" class="green-show-all">Tüm Yorumlarını Göster</span>
              </div>
              <div v-if="commentInfo.comments[selectedCommentIndex]" class="review-message-box" style="margin-top: 16px; position: relative;">
                <div style="margin: 20px; display: flex">
                  <router-link style="text-decoration: none;" :to="'/' + commentInfo.comments[selectedCommentIndex].username">
                    <img style="float: left; width: 48px; height: 48px; border-radius: 50%;border:1px solid #bfc8d2;" :src="commentInfo.comments[selectedCommentIndex].avatar_url"/>
                  </router-link>
                  <div style="margin-left: 11px; margin-top: 4px; display: flex">
                    <router-link style="text-decoration: none;" :to="'/' + commentInfo.comments[selectedCommentIndex].username">
                      <p style="text-align: left; font-size: 16px; font-weight: 600; color: #242424;">{{commentInfo.comments[selectedCommentIndex].username}}</p>
                    </router-link>
                    <div style="display: flex; margin-left: 5px">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-rating"/>
                      <p class="rating-number">
                        {{ commentInfo.comments[selectedCommentIndex].grade | convertNumberToFraction }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="commentInfo.comments.length > 1" class="icon-wrapper" style="left: -12px; top: 41px" :class="selectedCommentIndex===0 ? 'disabled-icon' : null" @click="prevComment">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg" onload="SVGInject(this)" class="inner-arrow" style="margin-left: 5px"/>
                </div>
                <div style="margin: -42px 0 0 80px;">
                  <p class="comment" v-html="filterComment(commentInfo.comments[selectedCommentIndex].comment_text_only)"/>
                </div>
                <div v-if="commentInfo.comments.length > 1" class="icon-wrapper" style="right: -12px; top: 41px" :class="selectedCommentIndex === commentInfo.comments.length-1 ? 'disabled-icon' : null" @click="nextComment">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg" onload="SVGInject(this)" class="inner-arrow" style="margin-right: 5px"/>
                </div>
              </div>
            </div>


            <div id="about" class="gig-description-container" v-if="description.length >0">
              <p>
                İş İlanı Hakkında
              </p>
              <div v-html="description" class="gig-description">

              </div>
            </div>

            <template  v-if="packageCount !== 3 && packages && !isEmpty(packages[packageSegment])">
              <div style="margin-top: 20px;" class="separator-big"/>
              <div id="package-content-limit-1" class="package-include">

                <div style="display: flex; justify-content: space-between; align-items: center">
                  <div>
                    <p class="gig-extras-title">Paket İçeriği</p>
                    <p class="gig-paket-sub-title">Bu hizmetle ilgili soruların varsa freelancer’a danışabilirsin.</p>

                  </div>
                  <div v-if="user.username !== seller.username" class="contact-freelancer-wrapper-m" @click="sendMessage('small message button')" :style="buttonLoading ? 'opacity:0.4': 'opacity:1'">
                    <div style="padding: 8px 7px;  display: flex; justify-content: space-between;">
                      <div class="avatar-wrapper">
                        <div class="status-icon" :style="{backgroundColor: seller.onlineStatus ? '#5beb17' : '#8b95a1'}"/>
                        <img class="avatar" :src="seller.avatar_url" :style="{border: seller.onlineStatus ? '1px solid #5beb17' : '1px solid #8b95a1'}"/>
                      </div>
                      <div>
                        <p style="font-size: 14px; font-weight: bold;">Freelancer'a</p>
                        <p>Danış</p>
                      </div>
                    </div>
                  </div>

                </div>



                <div style="margin-left:-25px; margin-right:-25px; padding: 25px 25px 10px; box-shadow: inset 0 1px 0 0 #eaedf2, inset 0 -1px 0 0 #eaedf2;
  background-color: rgba(244, 245, 247, 0.5);">
                  <div class="content-info">
                    <div class="content-delivery">
                      <img class="content-info-img" src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg" onload="SVGInject(this)"/>
                      <p class="content-title-big">{{packages[packageSegment].duration}} günde teslim</p>
                    </div>
                    <div class="content-revisions">
                      <img class="content-info-img" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)"/>
                      <p class="content-title-big">{{packages[packageSegment].revisions}} revizyon hakkı
                        <span style="cursor: help" :data-balloon="'Daha fazla revizyon hakkı talebin olursa, ekstralar bölümünden satın alabilirsin.'"  data-balloon-length="medium" data-balloon-pos="up"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="15"></span>
                      </p>
                    </div>
                  </div>
                  <div class="content-scope" v-for="option in packages[packageSegment].options">
                    <img class="content-img" src="https://gcdn.bionluk.com/site/cicons/checkbox_active.svg" v-if="option.value"/>
                    <img class="content-img" style="opacity: 0.7" src="https://gcdn.bionluk.com/site/cicons/checkbox_empty.svg" v-if="!option.value"/>
                    <p :class="{'content-title':true, passive: !option.value}"><span v-if="typeof option.value !== 'boolean'"><span v-if="option.id === 466">İlk </span>{{option.value}}</span><span v-if="option.selectboxHelpText"> {{option.selectboxHelpText}}</span> {{option.title}}
                      <span :data-balloon="option.description" data-balloon-length="medium" data-balloon-pos="up" style="cursor: help;"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="14"></span>
                    </p>
                  </div>
                </div>


              </div>
            </template>

            <div class="gig-extras-container" v-if="packageCount !== 3 && packages.extras.length">
              <p class="gig-extras-title">Ekstralar</p>

              <div class="extras-main" v-for="extra in packages.extras">
                <div style="display: flex; flex-direction: row;">
                  <input @change="selectExtra(extra, $event)" :value="`extra${extra.id}`" :id="`extra${extra.id}`"
                         type="checkbox"/>
                  <label class="input-labels" :value="`extra${extra.id}`" :for="`extra${extra.id}`"></label>
                  <label v-if="extra.value" class="extras-name"  :for="`extra${extra.id}`">{{ extra.value }}</label>
                  <label class="extras-name" :style="extra.value && 'margin-left: 5px'" :for="`extra${extra.id}`">{{ extra.name }}</label>
                  <div :data-balloon="extra.description" data-balloon-length="medium" data-balloon-pos="up" style="margin-left: 10px; cursor: help;"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="18"></div>
                </div>
                <div>
                <span style="font-size: 14px; font-weight: 500; color: #8b95a1;">
                  <span v-if="extra.duration && extra.id !== 'superfast'">
                    +{{ extra.duration | specifyPeriod(units.period, 'day') }}
                  </span>
                  <select v-if="extra.id !== 'superfast'" @change="selectPieceOfSelectedExtra(extra, $event)" class="super-drop">
                  <option v-for="x in 20" :value="x">{{x}} ({{ x * extra.price | specifyCurrency(units.currency) }})</option>
                  </select>
                  <span v-else style="font-size: 16px; color: #4b4f52 ">
                    +{{extra.price | specifyCurrency(units.currency)}}
                  </span>
                </span>
                </div>
              </div>
            </div>


            <div class="separator-big" v-if="priceInfo.buttonAction === 'buy' && packageCount !== 3 &&  packages.extras.length"></div>
            <div class="quantity-container" v-if="priceInfo.buttonAction === 'buy' && packageCount !== 3">
              <div v-if="isEmpty(packages.basic.extra_words)">
                <span style="font-size: 14px; font-weight: 500; color: #2d3640; margin-right: 13px;">İş Adedi</span>
                <select v-model="quantity" class="super-drop">
                  <option v-for="x in 20" :value="x">
                    <span style="font-size: 14px; font-weight: 600; text-align: left;color: #4b4f52;">{{x}}</span>
                    <span style="font-size: 14px; text-align: left; color: #a1a9a9;">({{x * priceInfo.price | specifyCurrency(units.currency) }})</span></option>
                </select>
              </div>

              <div v-else>
                <span style="font-size: 14px; font-weight: 500; color: #2d3640; margin-right: 13px;">Kelime sayısı: </span>
                <input maxlength="5" v-model="words" type="number" style="width: 80px">
              </div>

              <div class="separator" style="margin-right: 20px; margin-left: 20px; height: 50px"></div>

              <!--<button @click="openBuyModal()" class="super-button bottom-buy-button">-->
              <template v-if=" user.username === seller.username">
                <button disabled
                        :data-balloon="'Kendi ilanını satın alamazsın 🤓'"
                        data-balloon-pos="up"
                        class="super-button bottom-buy-button" style="font-weight: 500; padding-bottom: 6px">
                  <span>Devam Et</span>
                  <span style="float: right;">
                <span>{{ totalPayment | specifyCurrency(units.currency) }}</span>
              </span>
                </button>
              </template>
              <template v-else>
                <button :disabled="waitingResponse || user.username === seller.username || gigStatus !== 1" @click="packageClickAction(packages.basic)" class="super-button bottom-buy-button" style="font-weight: 500; padding-bottom: 6px">
                  <span>Devam Et</span>
                  <span style="float: right;">
                <span>{{ totalPayment | specifyCurrency(units.currency) }}</span>
              </span>
                </button>
              </template>

            </div>
          </div>
          <ComparePackages
            style="margin-top: 50px;"
            id="compare"
            v-if="!isEmpty(packages) && packageCount === 3"
            :packages="packages"
            :seller="seller"
            :gigStatus="gigStatus"
            :button-loading="buttonLoading"
            @sendMessage="sendMessage('small message button')"
            @packageClickAction="packageClickAction"
          />
          <GigReviewSection style="margin-top: 50px;" v-if="commentInfo.comments.length" id="review-container" :user-profile="seller" :gigId="gigID" :comment-grades="commentInfo.commentGrades"/>
        </div>
        <div>
          <PackagePricing
            style="margin-bottom: 30px"
            :packageCount="packageCount"
            :button-loading="buttonLoading" :seller="seller" :packages="packages" :gig-status="gigStatus" @packageClickAction="packageClickAction"/>

          <ProfileInfoGig
            :is-page-loaded="pageLoaded"
            :user-profile="seller"
            :admino-link="adminoLink"
            :button-loading="buttonLoading"
            @sendMessage="sendMessage('below avatar')"
          />

          <FavAndShare :gigId="gigID" :user-profile="seller" :like-info="likeInfo" :uuid="uuid"/>

          <div v-if="user.username !== seller.username" class="report-div">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-report.svg" onload="SVGInject(this)" class="report-svg"/>
            <p @click="report('gig', gigID, 16)"> Bu ilanı bildir</p>
          </div>
        </div>
      </div>
      <div v-if="gigs.length" class="gig-detail-footer-wrapper">
        <div id="other-gigs" class="gig-detail-footer">
          <div style="align-items: center">
            <div style="font-size: 20px;font-weight: 500;color: #2d3640; display: flex; justify-content: space-between">
              <p v-if="gigs.length > 3">
                <span style="color: #fd4056;">
              {{ seller.username }}</span> Kullanıcısının Diğer İlanları
              </p>
              <router-link :to="'/'+seller.username" class="tumu">Tüm İlanları</router-link>
            </div>
            <div style="display: flex;">
              <single-gig :impression-u-u-i-d="impressionUUID" v-for="(gig, index) in gigs" :key="index" :item="gig" class="gig-content" style="margin-top: 30px; margin-right: 10px"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating';
  import smoothscroll from 'smoothscroll-polyfill'
  import ComparePackages from "./components/ComparePackages";
  import PackagePricing from "./components/PackagePricing";
  import ProfileInfoGig from "./components/ProfileInfoGig";
  import FavAndShare from "./components/FavAndShare";
  import GigReviewSection from "./components/GigReviewSection";
  import SingleGig from "../../../../../components/singleGig";
  import CustomCheck from "@/pages/components/CustomCheck";
  import isEmpty from 'lodash/isEmpty'
  import { generateCodeForGigDetail, bindCodeToHtml } from '@/utils/schema'

  export default {
    name: "src-pages-body-workstation-gigs-detail-v4",
    components: {
      SingleGig,
      GigReviewSection,
      FavAndShare,
      ProfileInfoGig,
      PackagePricing,
      ComparePackages,
      StarRating,
      CustomCheck
    },
    data() {
      return {
        impressionUUID:null,
        displayWarningIcon: true,
        selectedCommentIndex: 0,
        scrollingDirection: 'down',
        navClasses: '',
        lockActiveSection: null,
        activeSection: 'summary',
        sections: [
          {
            isSelected: true,
            label: 'İlan Özeti',
            name: 'summary',
            packageCountLimits: [1, 3],
            calculateCondition: () => true
          },
          {
            isSelected: false,
            label: 'İş İlanı Hakkında',
            name: 'about',
            packageCountLimits: [1, 3],
            calculateCondition: () => true
          },
          {
            isSelected: false,
            label: 'Paketleri Karşılaştır',
            name: 'compare',
            packageCountLimits: [3],
            calculateCondition: () => true
          },
          {
            isSelected: false,
            label: 'Paket İçeriği',
            name: 'package-content-limit-1',
            packageCountLimits: [1],
            calculateCondition: () => true
          },
          {
            isSelected: false,
            label: 'Beğeni ve Yorumlar',
            name: 'review-container',
            packageCountLimits: [1, 3],
            calculateCondition: () => {
              return this.commentInfo.comments.length > 0
            }
          },
          {
            isSelected: false,
            label: 'Diğer İlanları',
            name: 'other-gigs',
            packageCountLimits: [1, 3],
            calculateCondition: () => {
              return this.gigs.length > 0
            }
          }
        ],
        content: {},
        buttonLoading: false,
        waitingResponse: false,
        url: window.location.href,
        quantity: 1,
        selectedExtras: [],
        receivedPortfolios: [],
        allPortfolioUUIDs: [],
        pageLoaded: false,
        uuid: null,
        title: null,
        description: null,
        priceInfo: null,
        duration: null,
        commentInfo: {
          comments: [],
          commentGrades: []
        },
        allCommentCount: null,
        likeInfo: {likeStatus:{action:null}},
        gigCategory: null,
        gigSubCategory: null,
        gigStatus: null,
        seller: {
          username: null,
          onlineStatus: false
        },
        extras: [],
        warning: {},
        activatedIframe: false,
        videoLoader: false,
        gigID: null,
        adminoLink: false,
        packages: {
          extras: []
        },
        packageCount: 1,
        packageSegment: 'basic',
        seller_sub_category_portfolio_count: 0,
        words: null,
        extraWord: {},
        gigs: [],

        addListOpen:false,
        lists:[],
        checkLoading:false,
      }
    },
    watch:{
      addListOpen:function (newVal, oldVal){
        if(newVal){
          this.getLists()
        }
      },

      '$route.params' : function (newval, oldval) {
        if(newval && oldval && newval !== oldval){
          this.pageLoaded = false;
          this.getGigDetail(true);
        }
      }
    },
    computed: {
      filteredSections() {
        return this.sections.filter(section => {
          return section.packageCountLimits.indexOf(this.packageCount) > -1 && section.calculateCondition()
        })
      },
      formattedSellerUsername() {
        let formattedUsername = this.seller.username[0].toLocaleUpperCase('TR') + this.seller.username.slice(1)
        let isLastCharVowel = ['a', 'e', 'i', 'o', 'u'].indexOf(formattedUsername[formattedUsername.length-1].toLowerCase()) !== -1
        return isLastCharVowel ? `${formattedUsername}'yla` : `${formattedUsername}'la`
      },
      totalPayment() {
        let total = 0;

        if(isEmpty(this.extraWord)) {
          total = (total + this.priceInfo.price) * this.quantity;
        } else {
          const word = !parseInt(this.words) ? 0 :  parseInt(this.words);
          const wordMultiplier = Math.ceil( (word - this.extraWord.initial_words) / this.extraWord.words);
          total = (total + this.priceInfo.price);
          total = wordMultiplier > 0 ? total + (wordMultiplier  * this.extraWord.price) : total;
        }

        this.selectedExtras.forEach(function (extra) {
          total += (extra.price * extra.quantity);
        });
        return total.toLocaleString('tr');
      }
    },
    methods: {
      isEmpty,
      getLists(){
        if(!this.pageLoaded) return false
        if(this.buttonLoading) return false;

        this.buttonLoading = true;
        this.api.general.crudList('get_all', '', null, this.gigID, this.$Progress)
          .then(({data: result}) => {
            this.lists = result.data.lists
            this.buttonLoading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          })

      },

      likeChecked(l,index){
        if(l.liked){
          this.lists[index].liked = false;
          this.lists[index].counts.gig--;
          this.removeFromList(l.id);
          this.likeInfo.likeStatus.action = 1;
          this.lists.forEach((ll) => {
            if(ll.liked){
              this.likeInfo.likeStatus.action = -1;
            }
          })
        } else {
          this.lists[index].liked = true;
          this.lists[index].counts.gig++;
          this.addToList(l.id);
          this.likeInfo.likeStatus.action = -1;
        }
      },
      openAddListModal(){
        this.addListOpen = false;
        let data = {
          type: 'create',
        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_LIST, info: {data: data}});

      },



      addToList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        let page = this.$store.state.componentMap.page;
        this.api.general.crudList('like_item', 'gig', listId, this.gigID, this.$Progress, 0, page)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },
      removeFromList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        this.api.general.crudList('delete_item', 'gig', listId, this.gigID, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      activateUseBicoinModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.USE_BICOIN, info: {uuid: this.uuid}})
      },
      changeGigStatus(status) {
        if (status === -1) {
          if (confirm(this.Constants.TEXTS.GIGS_DELETE_WARNING_DESC) === true){
            //
          }
        } else {
          this.pageLoaded = false;
          this.EventBus.$emit("activateProgressBar")
          this.api.seller.changeGigStatus(this.uuid, status)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                return this.getGigDetail()
                .then(() => {
                  this.displayWarningIcon = false
                  this.$nextTick(() => {
                    this.displayWarningIcon = true
                  })
                })
              } else {
                this.$Progress.fail()
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(() => {
              this.$Progress.fail()
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
          .finally(() => {
            this.EventBus.$emit("deactivateProgressBar")
          })
        }
      },
      prevComment() {
        if (this.selectedCommentIndex !== 0) {
          this.selectedCommentIndex -= 1
        }
      },
      nextComment() {
        if (this.selectedCommentIndex !== this.commentInfo.comments.length-1) {
          this.selectedCommentIndex += 1
        }
      },
      filterComment(comment) {
        const maxLength = 170;
        return comment && comment.length > maxLength ? comment.slice(0, maxLength) + '...' : comment
      },
      anchorHashCheck() {
        if (window.location.hash === this.$route.hash) {
          const el = document.getElementById(this.$route.hash.slice(1))
          if (el) {
            window.scrollTo(0, el.offsetTop)
          }
        }
      },
      async routeToSection({ name }) {
        const findEl = (hash, x) => {
          return (
            Promise.resolve(document.getElementById(hash)) ||
            new Promise((resolve, reject) => {
              if (x > 50) {
                return resolve()
              }
              setTimeout(() => {
                resolve(findEl(hash, ++x || 1))
              }, 100)
            })
          )
        }
        const el = await findEl(name)
        this.activeSection = name
        this.lockActiveSection = name
        window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
      },
      handleScroll(e) {
        if (window.scrollY > 113) {
          this.navClasses = 'fixed'
        } else {
          this.navClasses = ''
        }
        if (this.lockActiveSection) {
          const el = document.getElementById(this.lockActiveSection)

          if (el && this.elementInViewport(el)) {
            this.lockActiveSection = null
          }
          return
        }

        this.sections.map(section => section.name).find(block => {
          const el = document.getElementById(block)
          try {
            if (document.querySelector('#sc-' + block)) {
              if (el && this.elementInViewport(el)) {
                this.activeSection = block
              }
            }
          } catch (e) {
            console.log(e)
          }
        })
      },
      elementInViewport(el) {
        const bounding = el.getBoundingClientRect()
        if (this.scrollingDirection === 'up') {
          const bottom = bounding.bottom - 200
          return bottom > 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight)
        } else {
          return bounding.top > 0 && bounding.top + 300 <= (window.innerHeight || document.documentElement.clientHeight)
        }
      },
      openEditFeaturedPortfolios(){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.EDIT_GIG_FEATURED_PORTFOLIO, info: {gig_id: this.gigID, gig_uuid:this.uuid, selectedPortfolios:this.receivedPortfolios}, description: 'keepOldModal'});
      },
      showPortfolio(uuid) {
        this.activatedIframe = false;
        this.$store.state.clickedFrom = 'gig';
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid, gigId:this.gigID, calledFrom:"gig",allItems:this.allPortfolioUUIDs}, description: 'keepOldModal'});
      },
      sendMessage(clickedFrom=null) {
        if(clickedFrom){
          this.$store.state.clickedFrom = clickedFrom;
        }

        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {
          if(this.buttonLoading === false){
            this.buttonLoading = true;
            this.api.workstation.chatOpen(this.seller.username, 0,1)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  let conversationSendData = {
                    r_username: this.seller.username,
                    r_avatar_url: this.seller.avatar_url,
                    r_id: this.seller.id,
                    r_uuid: this.seller.uuid,
                    fromWhere: 'deeddetail',
                    gigID: this.gigID,
                    c_uuid: result.data.c_uuid,
                  };
                  this.EventBus.$emit('externalOpenChat', conversationSendData);
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
                this.buttonLoading = false;
              })
              .catch(err => {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });
          }
        }
      },
      openBuyModal() {
        this.$store.state.clickedFrom = 'gig buy now'
        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {
          let extras = [];
          this.selectedExtras.forEach(function ({id, quantity}) {
            extras.push({id, quantity});
          });
          this.api.orders.giveOrder(this.Constants.ORDER_TYPES.GIG, this.uuid, this.quantity,this.words, JSON.stringify(extras), null, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: result}});
                this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
                this.trackConversionForCheckoutedOrders(result.data.total, "TRY");
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },
      getGigDetail(comingFromCreated) {
        return this.api.general.getGigDetail(this.$store.state.routerParams[1], this.$store.state.routerParams[0], this.route.query.package_id || '', this.route.query.super_fast || '')
          .then(({data}) => {
            let result = data;
            if (result.success) {

              this.seller_sub_category_portfolio_count = result.data.seller_sub_category_portfolio_count;
              let extraWord = result.data.packages.basic.extra_words;

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.setFacebookMetaProperties(result.data.meta.facebookMeta);
              this.pageLoaded = true;
              this.gigStatus = result.data.status
              this.seller = result.data.seller;
              this.seller.f_t_info = result.data.f_t_info
              this.seller.recurringOrders = result.data.recurring_orders;
              this.commentInfo = result.data.commentInfo;
              this.allCommentCount = result.data.commentInfo.commentCount;
              this.likeInfo = result.data.likeInfo;
              this.duration = result.data.duration;
              this.title = result.data.title;
              if (result.data.isFeatured) {
                this.getFeaturedGigAnalytics(result.data.featured_gigs_id);
              }
              this.description = result.data.description;
              result.data.gig_extras.forEach(function (extra) {
                extra.quantity = 1;
              });
              this.extras = result.data.gig_extras;
              this.priceInfo = result.data.priceInfo;
              this.quantity = 1;
              this.gigCategory = result.data.gig_category;
              this.gigSubCategory = result.data.gig_category_sub;

              this.uuid = result.data.uuid;
              this.gigID = result.data.gig_id;
              this.adminoLink = result.data.alk;
              this.packages = result.data.packages;
              this.extraWord = extraWord;
              this.content = result.data.content;
              this.words = isEmpty(extraWord) ? null : extraWord.initial_words;
              this.$nextTick(() => {
                this.packages.extras.forEach(e => {
                  if(e.id === 'superfast' && this.$route.query.super_fast){
                    if( document.getElementById(`extrasuperfast`)){
                      document.getElementById(`extrasuperfast`).checked = true;
                    }
                    this.selectedExtras.push(e)
                  }
                  return e.quantity = 1;
                });
              })

              this.packageCount = result.data.packageCount;

              if(result.data.portfolios && result.data.portfolios.length > 1){
                this.receivedPortfolios = result.data.portfolios.slice(1, (result.data.portfolios.length));
                this.allPortfolioUUIDs = this.receivedPortfolios.concat(result.data.all_other_sub_category_portfolios)
              }

              this.warning = result.data.warning;

              this.Helper.trackEvents.pageView(this.Helper.gigDetailPagePropsV2(result.data));

              if (this.$store.state.route.query.checkout && comingFromCreated) {
                this.openBuyModal();
              }

              if (comingFromCreated) {
                this.retrieveGigs()
              }
              const schema = {
                id: result.data.gig_id,
                price: result.data.packages.basic.price,
                slugUrl: result.data.gig_slug_url,
                imageUrl: result.data.content.image_url,
                title: result.data.title,
                username: result.data.seller.username,
                brand: result.data.gig_category_sub.name,
                ratingValue: result.data.commentInfo.commentRating,
                ratingCount: result.data.commentInfo.commentCount
              }
              if (result.data.packageCount > 1) {
                schema.maxPrice = result.data.packages.premium.price
              }
              bindCodeToHtml(JSON.stringify(generateCodeForGigDetail(schema, result.data.packageCount === 1)))
            } else {

              this.sendPrerender302(result.data.redirect_url,"302");
              if(!result.data.soft_redirect){
                this.$toasted.global.errorToast({description: "İlan veya kullanıcı artık aktif değil."});
              }
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      getFeaturedGigAnalytics(featuredGigID) {
        this.api.feeds.featuredGigAnalytics(featuredGigID)
          .then()
          .catch(err => {
            this.$toasted.error(err);
          })
      },
      actionButton(endpoint, parameters) {
        this.api.general.requestToEndPoint(endpoint, parameters, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              if (result.data.new_status === 1) {
                this.$router.go(`/panel/ilanlar/duzenle/${this.uuid}`);
              } else {
                this.$router.push(result.data.redirect_url);
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      selectExtra(receivedExtra, event) {
        if (event.target.checked) {
          this.selectedExtras.push(receivedExtra);
        } else {
          if (this.selectedExtras.length) {
            this.selectedExtras = this.selectedExtras.filter(function (extra) {
              return receivedExtra.id !== extra.id;
            });
          }
        }
      },
      selectPieceOfSelectedExtra(receivedExtra, {target}) {
        if (!document.getElementById(`extra${receivedExtra.id}`).checked) {
          document.getElementById(`extra${receivedExtra.id}`).checked = true;
          this.selectedExtras.push(receivedExtra);
        }

        this.selectedExtras.forEach(function (extra) {
          if (receivedExtra.id === extra.id) {
            extra.quantity = Number(target.value);
          }
        });

        this.extras.forEach(function (extra) {
          if (receivedExtra.id === extra.id) {
            extra.quantity = Number(target.value);
          }
        });

        // vue, obje icindeki degisiklikleri gormuyor diye cloning yapiliyor
        this.selectedExtras = [...this.selectedExtras];
        this.extras = [...this.extras];
      },
      deactivateIframeLoader() {
        this.videoLoader = false;
      },
      activateIframe() {
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'video play', gig_id:this.gigID});
        this.videoLoader = true;
        this.activatedIframe = true;
      },
      deactivateIframe() {
        this.receivedPortfolios.forEach(portfolio => {
          portfolio.activatedIframe = false;
        });
      },
      report(type, external_id, report_type_id) {
        if (this.user.uuid) {
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.COMPLAINT, info: {type: type, externalId: external_id, reportTypeId: report_type_id}});
        } else {
          this.$router.push('/login');
        }
      },
      packageClickAction(pack) {

        if(this.packageCount ===3 && pack.extra_fast?.selected){
          this.selectedExtras.push({
            id: 'superfast',
            quantity: 1,
            title: 'Süper Hızlı Teslimat',
            description: '"Siparişin, seçtiğin pakette belirtilen süreden daha hızlı teslim edilir.',
            price: pack.extra_fast.price,
            duration: pack.extra_fast.duration
          });
        }
        this.buttonLoading = true;
        this.$store.state.clickedFrom = 'gig buy now'
        if(this.packageCount > 1 && pack.extra_words && pack.extra_words.initial_words && pack.extra_words.initial_words > 0){
          this.words = pack.extra_words.initial_words;

        }
        this.waitingResponse = true;
        this.api.orders.giveOrder(this.Constants.ORDER_TYPES.GIG, this.uuid, this.quantity, this.words, JSON.stringify(this.selectedExtras), pack.package_id, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$router.push(result.data.redirect_url);
            } else {
              if(result.data && result.data.redirect_url){
                this.$router.push(result.data.redirect_url)
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }

            }
            this.buttonLoading = false;
            this.waitingResponse = false;
          })
          .catch(err => {

            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.waitingResponse = false;
            this.buttonLoading = false;
          });
      },
      handleWheel(event) {
        if (event.deltaY < 0) {
          this.scrollingDirection = 'up'
        } else if (event.deltaY > 0) {
          this.scrollingDirection = 'down'
        }
      },
      retrieveGigs() {
        this.api.general.getAllGigsByUser(this.$store.state.routerParams[0], 4, 0, this.$store.state.routerParams[1].split('-').slice(-1)[0])
          .then(({data}) => {
            let result = data;
            if (result.success) {


              if(result.data.gigs.length > 0) {
                let trackGigObj = [];
                result.data.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'deeddetail',
                  placement:'default',
                  info:trackGigObj,
                  title: this.impressionUUID
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              }

              this.gigs = result.data.gigs
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
          })
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('wheel', this.handleWheel)
      smoothscroll.polyfill()
      this.anchorHashCheck()
    },
    beforeDestroy () {
      this.EventBus.$off("updateGigPortfolios");
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('wheel', this.handleWheel)
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.EventBus.$on('updateGigPortfolios', payload => {
        this.receivedPortfolios = payload;
      });
      this.getGigDetail(true)
    }
  }

</script>

<style scoped lang="scss">

.contact-freelancer-wrapper-m{
  width: 140px;
  height: 50px;
  border-radius: 5px;
  background-color: #2d3640;
  color: #ffffff;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
  .avatar-wrapper {
    position: relative;
    display: inline-flex;
    .status-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #8b95a1;
      position: absolute;
      right: -1px;
      bottom: 0;
    }
    .avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }
}

.content-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .content-info-img /deep/ {
    width: 14px;
    height: 14px;
    margin: auto;
    path {
      fill: #fd4056;
    }
  }
  .content-delivery {
    display: flex;
  }
  .content-revisions {
    display: flex;
    margin-left: 24px;
  }
  .content-title-big {
    font-weight: 500;
    font-size: 16px;
    color: #2d3640;
    margin-left: 5px;
  }
}
.content-scope {
  display: flex;
  margin-bottom: 12px;
  .passive {
    opacity: .6;
  }
  .content-title {
    color: #2d3640;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    line-height: 1;
  }
  .content-img-green /deep/{
    width: 16px;
    height: 16px;
    margin: auto 0;
    path {
      fill: #6dce6a;
    }
  }
  .content-img-gray /deep/{
    width: 16px;
    height: 16px;
    margin: auto 0;
    path {
      fill: #bfc8d2;
    }
  }
}

.contact-freelancer-wrapper{
  margin-right: 30px;
  width: 135px;
  height: 45px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  color: #2d3640;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
  .avatar-wrapper {
    position: relative;
    display: inline-flex;
    .status-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #8b95a1;
      position: absolute;
      right: -1px;
      top: 3px;
    }
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.contact-freelancer-wrapper-g{
  width: 240px;
  height: 70px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #dce1e1;
  color: #2D3640;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
  .avatar-wrapper {
    margin-left: 10px;
    margin-top: 2px;
    position: relative;
    display: inline-flex;
    .status-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #8b95a1;
      position: absolute;
      right: -1px;
      top: 5px;
    }
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #fff;
    }
  }
}

.xbubble {
  font-size: 13px;
  color: #5e6b79;
  font-weight: 500;

  padding:4px 7px 5px 6px;
  display: flex;
  align-items: center;
  justify-content: center;


  margin-left: 10px;
  position: relative;
  border: 1px solid #bfc8d2;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.xbubble:before {
  z-index: 2;
  border-width: 6px 6px 6px 0;
  border-color: transparent #bfc8d2 transparent transparent;
  top: 6px;
  left: -6px;
  content: "";
  position: absolute;
  border-style: solid;
}

.xbubble:after{
  z-index: 3;
  border-width: 4px 7px 4px 0;
  border-color: transparent #fff transparent transparent;
  top: 8px;
  left: -6px;
  content: "";
  position: absolute;
  border-style: solid;
}


.rounded-plus-button{
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/superlancer.appspot.com/public/rounded-border-bg.svg);
  background-size: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon /deep/ {
    height:14px;
    width: 14px;
    path {
      fill: #65c4db
    }
  }
}

.rounded-plus-button-gray{
  width: 24px;
  height: 24px;
  background: #eff3f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  .icon /deep/ {
    height:11px;
    width: 11px;
    path {
      fill: #8b95a1
    }
  }
}

.setting-box {
  position: absolute;
  width: 240px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 0 20px 20px;


  //    border: solid 1px #bfc8d2;
  left: -215px;
  top: 30px;
  z-index: 99910;
  box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.2);
  border: solid 1px rgba(94, 107, 121, 0.2);


  .setting-box-options-overflow{
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    //padding-right: 1px;
    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dde3eb;
    }
  }
  .setting-box-options {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: #2d3740;
    }
    .setting-box-options-title{
      font-size: 16px;
      color: #5e6b79;
      text-overflow: ellipsis;
      width: 180px;
      white-space: pre;
      overflow: hidden;
    }

    .setting-box-options-sub-title{
      font-size: 11px;
      margin-top: 4px;
      font-style: italic;
      color: #8b95a1;

    }
  }

  .settings-box-add-new {
    width: 218px;
    height: 36px;
    border-radius: 5px;
    margin-top: 10px;

    display: flex;
    align-items: center;


    justify-content: space-around;

    cursor:pointer;color:#5e6b79;font-size:14px;
    border: dashed 2px #bfc8d2;
    &:hover{
      border-color: #5e6b79;
    }
    .te{
      font-size: 14px;
      color: #5e6b79;
    }
  }
}

.setting-box::after {
  content: '';
  position: absolute;
  right: 15px;
  top: -7.8px;
  width: 15px;
  height: 15px;
  border-top: solid 1px rgba(94, 107, 121, 0.2);
  border-left: solid 1px rgba(94, 107, 121, 0.2);
  box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.2);
  border-bottom: 0;
  border-right: 0;
  border-radius: 20% 0 0 0 / 25%;
  transform: rotate(45deg);
  background-color: #fff;
}

.add-list-area{
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;

  .add-list-text{
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #2d3640;
    margin-left: 5px;
  }
  .add-list-text-red{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fd4056;
    margin-left: 5px;
  }
  .add-list-icon-container{
    cursor: pointer;
    .add-list-icon /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #8b95a1;
      }
    }
    .add-list-icon-red /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #fd4056;
      }
    }
  }

  &:hover{
    .add-list-icon-container{
      .add-list-icon /deep/{
        width: 20px;
        height: 20px;
        path{
          fill: #5e6b79;
        }
      }
    }
  }
}


.followers-count{
  padding-left: 30px;
  padding-right: 30px;
  height: 34px;
  border-radius: 2px;
  border: dashed 1px #bfc8d2;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  .inner-text {
    height: 18px;
    font-size: 14px;
    text-align: center;
    color: #5e6b79;
  }
  &:hover{
    border: solid 1px #2d3740;
  }
}

  button {
    &:disabled{
      background: #8b95a1!important;
      background-color: #8b95a1!important;
    }
  }
  .action-button-svg /deep/ {
    cursor: pointer;
    width: 22px;
    height: 22px;
    path {
      fill: #ffffff!important;
    }
  }
  .warning-icon-6dce6a /deep/ {

    width: 36px;
    height: 36px;
    path {
      fill: #6dce6a!important;
    }
  }

  .warning-icon-fd4056 /deep/ {

    width: 36px;
    height: 36px;
    path {
      fill: #fd4056!important;
    }
  }

  .warning-icon-f29933 /deep/ {

    width: 36px;
    height: 36px;
    path {
      fill: #f29933!important;
    }
  }
  .star-rating /deep/{
    width: 18px;
    height: 18px;
    path {
      fill: #ffbf00
    }
  }

  .rating-number{
    font-size: 16px;
    font-weight: 500;
    margin-left: 2px;
    color: #ffbf00;
  }

  #navigation-wrapper{
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    background-color: #ffffff;
    height: 58px;
    &.fixed{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      border: none;
      border-radius: 0;
      margin: 0;
      border-bottom: 1px solid #E4E4E4;
      height: 57px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
  #navigation{
    display: flex;
    height: 58px;
    margin: 0 auto;
    background-color: #ffffff;
    width: 1193px;
    align-items: flex-end;
    justify-content: space-between;

    .navigation-child{
      &.fixed {
        width: 1193px;
        margin: 0 auto;
      }
    }
    &.fixdsed{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      border: none;
      border-radius: 0;
      margin: 0;
      border-bottom: 1px solid #E4E4E4;
      height: 57px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
    .section-navs{
      &:nth-child(n+2) {
        margin-left: 25px;
      }
      &:hover{
        text-decoration: none;
      }
      cursor: pointer;
      font-size: 15px;
      height: 32px;
      font-weight: 500;
      color: #8b95a1;
      position: relative;
      &.selected {
        border-bottom: #fd4056 solid 4px;
        color: #2d3640;
        font-weight: 500;
      }
    }
  }

  .sound-icon-container{
    width: 30px;
    height: 30px;
    .sound-icon /deep/ {
      width: 18px;
      height: 18px;
    }
  }

  .new-badge{
    width: 40px;
    height: 16px;
    padding-bottom: 2px;
    border-radius: 10px;
    background-color: #fd4056;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .green-show-all{
    font-size: 14px;
    font-weight: 500;
    color: #00a575;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .new-portfolio{
    .new-p-header{
      display: flex;
      justify-content: space-between;
      .new-p-title{
        font-size: 20px;
        font-weight: 500;
        color: #2d3640;
      }
    }

    .new-p-img-container{
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .portfolio-item{
        cursor: pointer;
        position: relative;
        &:nth-child(n+2) {
          margin-left: 7px;
        }
      }
      .new-p-img{
        width: 168px;
        height: 94px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
        border: solid 1px #eaedf2;
        background-color: #ffffff;
      }
    }

    .new-p-edit-container{
      margin-top: 20px;
      width: 100%;
      height: 90px;
      border-radius: 10px;
      border: solid 1px #eaedf2;
      background-color: rgba(239, 243, 248, 0.5);
      position: relative;
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-radius: 4px;
        border-bottom-color:#F7F9FB;

      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 13px solid transparent;

        border-bottom-color: rgba(239, 243, 248, 0.5);
      }
      .inner-edit{
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        .portfolio-icon /deep/{
          margin-left: 15px;
          width: 33px;
          height: 36px;
          path {
            fill: #fd4056;
          }
        }
        .title-e{
          font-size: 20px;
          font-weight: bold;
          color: #2d3640;
        }
        .sub-title-e{
          margin-top: 10px;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.14;
          color: #2d3640;
        }
      }
    }
  }

  .review-satisfy{
    .new-p-header{
      display: flex;
      justify-content: space-between;
      .new-p-title{
        font-size: 20px;
        font-weight: 500;
        color: #2d3640;
      }
    }
    .review-message-box{
      width: 100%;
      height: 106px;
      border-radius: 2px;
      border: solid 1px #eaedf2;
      background-color: rgba(239, 243, 248, 0.5);
    }
    .comment {
      display: block;
      display: -webkit-box;
      font-weight: 300;
      width: 612px;
      height: 43px;
      font-size: 15px;
      line-height: 1.57;
      color: #5e6b79;
    }
    .disabled-icon{
      cursor: default !important;
      opacity: 0.5;
    }
    .icon-wrapper{
      position: absolute;
      cursor: pointer;
      background-color: #2d3640;
      width: 26px;
      height: 26px;
      border-radius: 25px;
      display: flex;
      .inner-arrow /deep/{
        margin: auto;
        display: block;
        width: 14px;
        height: 14px;
        path {
          fill: #ffffff;
        }
      }
    }
    .new-p-edit-container{
      margin-top: 20px;
      width: 100%;
      height: 90px;
      border-radius: 10px;
      border: solid 1px #eaedf2;
      background-color: rgba(239, 243, 248, 0.5);
      position: relative;
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-radius: 4px;
        border-bottom-color:#F7F9FB;

      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 13px solid transparent;

        border-bottom-color: rgba(239, 243, 248, 0.5);
      }




      .inner-edit{
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        .portfolio-icon /deep/{
          margin-left: 15px;
          width: 33px;
          height: 36px;
          path {
            fill: #fd4056;
          }
        }
        .title-e{
          font-size: 20px;
          font-weight: bold;
          color: #2d3640;
        }
        .sub-title-e{
          margin-top: 10px;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.14;
          color: #2d3640;
        }
      }



    }
  }

  .send-message-but{
    margin-top: 18px; cursor: pointer; width: 260px; height: 44px; border-radius: 2px; border: solid 1px rgba(75,79,82,0.2);
  }
  .deed-warning {
    width: 1193px;
    height: 90px;
    margin-top: 27px;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  .warning-header {
    font-size: 20px;
    font-weight: bold;
    color: #2d3640;
  }

  .warning-text {
    font-size: 15px;
    font-weight: 300;
    color: #2d3640;
  }

  .warning-action-button {
    width: 134px;
    height: 44px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c6c8ca;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    margin-left: 20px;
  }

  .warning-action-button:hover {
    background-color: #fbfbfb !important;
  }

  .gig-detail-header-wrapper {
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid #eaedf2;

    .gig-detail-header {
      width: 1193px;
      margin: 0 auto;
      padding-top: 24px;
      background-color: #ffffff;
    }
  }

  .gig-detail-footer-wrapper{
    background-color: #ffffff;
    width: 100%;
    padding-bottom: 50px;
    border-top:1px solid #eaedf2;
    margin-top: 20px;

    .gig-detail-footer {
      width: 1193px;
      margin: 0 auto;
      padding-top: 24px;
      background-color: #ffffff;
    }
  }

  .breadcrumb {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .breadcrumb span {
    color: #bfc8d2;
    font-size: 11px;
    font-weight: 700;
    text-decoration: none !important;;
  }

  .header-title {
    display: flex;
    color: #2d3640;
    width: 750px;
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }

  .editButton {
    width: 50px;
    margin-left: 10px;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 3px 7px 9px;
    font-weight: 500 !important;
    height: 20px;
    border-radius: 100px;
    background-color: #8b95a1;
    border: none;
  }

  .price-button-container {
    float: right;
    width: 324px;
    height: 78px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #dce1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #buy-button {
    background-color: #26ca9a;
  }

  #message-button {
    background-color: #2d3640
  }

  #buy-button:hover {
    background-color: #00a575 !important;
  }


  .bottom-buy-button:hover {
    background-color: #00a575 !important;
  }

  .price-button-container button {
    min-width: 171px !important;
    height: 56px !important;
    border-radius: 2px;
    margin: 12px 30px;
    font-size: 18px;
    font-weight: 600;
  }

  .separator {
    height: 30px;
    width: 0;
    margin-left: 19px;
    border-left: solid 1px #e6eaea;
  }

  /*Body*/
  .gig-detail-body {
    width: 1193px;
    margin: 0 auto;
    background-color: #f4f5f7;
    padding-top: 21px;
    padding-bottom: 50px;
    &.fixed{
      padding-top: 21px;
      padding-bottom: 50px;
    }
  }

  /*Swiper ve Carusel*/
  .gig-detail-body-inner {
    width: 700px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    padding: 25px 25px 20px;
  }

  .gig-detail-swiper {
    width: 700px;
    height: 394px;
    padding-bottom: 20px;
  }

  .separator-big {
    width: 701px;
    margin-top: 15px;
    height: 0;
    border-top: solid 1px #dfe4ec;
  }

  .gig-description-container {
    text-align: left;
  }

  .gig-description-container p {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #2d3640;
    padding-bottom: 16px;
  }

  .gig-description {
    font-size: 16px;
    line-height: 1.63;
    text-align: left;
    color: #8b95a1;
    width: 700px;
    word-wrap: break-word;
    word-break: normal;


    font-weight: 500;

    letter-spacing: normal;

  }

  /*iframe*/
  .playIcon {
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 75px;
    width: 75px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
  }

  .hoveropa {
    height: 75px;
    width: 75px;
  }

  /*Extras*/
  .gig-extras-container {
    text-align: left;
  }

  .gig-extras-title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #2d3640;
    padding-top: 25px;
    padding-bottom: 7px;
  }

  .gig-paket-sub-title{
    padding-bottom: 20px;
    font-size: 12px;
    color: #5e6b79;
  }

  .bottom-buy-button {
    width: 211px;
    height: 50px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 5px;
    float: right;
  }

  .quantity-container {
    width: 700px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;
  }

  .super-drop {
    font-weight: 600;
    background-position-x: 82px;
    width: 100px;
    height: 40px;
    min-width: 100px;
    font-size: 14px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] + .input-labels {
    display: inline-block;
    cursor: pointer;
    padding: 0 0 0 0px;
    background: url("https://gcdn.bionluk.com/site/icon/checkbox_empty.svg") no-repeat;
    height: 16px;
    width: 16px;;
  }

  input[type=checkbox]:checked + .input-labels {
    background: url("https://gcdn.bionluk.com/site/icon/checkbox_active.svg") no-repeat;
    height: 16px;
    width: 16px;
    display: inline-block;
    cursor: pointer;
  }

  .extras-name {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
    margin-left: 14px;
    cursor: pointer;
  }

  label {
    margin-bottom: 0;
  }

  .extras-main {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /*Review */

  #review-container {
    width: 750px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    margin-top: 19px;
  }

  .admino-link {
    color: #FFF;
    background-color: #fd4056;
    border: 0;
    padding: 4px 6px;
    margin-top: 10px;
    font-size: 12px;
  }

  .report-div {

    margin-top: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    img {
      position: absolute;
      margin-left: -66px;
    }
    p {
      color: #8b95a1;
      font-weight: normal;
      font-size: 18px;
      margin-left: 8px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .report-svg /deep/{
    width: 16px;
    height: 18px;
    path {
      fill: #8b95a1
    }
  }

  .deed-detail-content-header-container {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-content-header-detail {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .deed-detail-content-header-text-large {
    width: 638px;
    height: 20px;
    background-color: #e1e6ed;
  }

  .deed-detail-content-header-text-small {
    margin-top: 12px;
    width: 140px;
    height: 20px;
    background-color: #e1e6ed;
  }


  .deed-detail-content-header-text-xsmall-container {
    display: flex;
    margin-top: 34px;
    margin-bottom: 29px;
  }

  .deed-detail-content-header-text-xsmall {
    width: 80px;
    height: 20px;
    background-color: #e1e6ed;
    margin-right: 24.5px;
  }

  .deed-detail-content-header-button-container {
    width: 372px;
    height: 60px;
    padding: 9px;
    border-radius: 6px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 111px;
  }

  .deed-detail-content-header-button-text {
    width: 80px;
    height: 34px;
    background-color: #bdc5d0;
    margin-left: 54px;
  }

  .deed-detail-content-header-button {
    width: 171px;
    height: 60px;
    border-radius: 2px;
    background-color: #e1e6ed;
    margin-left: 76px;
  }

  .deed-detail-content-header-button-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deed-detail-content-container {
    padding: 27px 25.3px 35px 26px;
    width: 688px;
    height: 851.7px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
  }

  .deed-detail-content-portfolios {
    display: flex;
    flex-direction: column;
  }

  .deed-detail-content-portfolio-image {
    width: 698.7px;
    height: 392.7px;
    background-color: #f4f5f7;
  }

  .deed-detail-content-portfolio-small-image {
    width: 130px;
    height: 73px;
    background-color: #f4f5f7;
  }

  .deed-detail-content-portfolio-small-image-container {
    display: flex;
    justify-content: space-between;
    width: 698.7px;
    margin-top: 20.3px;
  }

  .deed-detail-content-seperator {
    width: 701px;
    height: 1px;
    background-color: #dfe4ec;
    margin: 55px 0 28px
  }


  .deed-detail-content-detail-header-text {
    display: flex;
    flex-direction: column;
  }

  .deed-detail-content-detail-header-text-large {
    width: 354px;
    height: 13px;
    background-color: #e1e6ed;
  }

  .deed-detail-content-detail-header-text-small {
    margin-top: 10px;
    width: 117px;
    height: 13px;
    background-color: #e1e6ed;
  }

  .deed-detail-content-header-text-xlarge {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
  }

  .deed-detail-content-header-text-xlarge-item {
    width: 692px;
    height: 13px;
    background-color: #e1e6ed;
    margin-bottom: 18px;
  }

  .deed-detail-content-detail-price-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 49px;
  }

  .deed-detail-content-detail-price-button {
    width: 260px;
    height: 44px;
    border-radius: 2px;
    background-color: #e1e6ed;
  }

  .deed-detail-profile-container {
    display: flex;
    flex-direction: column;
  }

  .deed-detail-profile-header {
    width: 360px;
    height: 330px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .deed-detail-profile-header-image {
    margin-top: 40px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: #dfe4ec;
  }

  .deed-detail-profile-header-name {
    width: 117px;
    height: 16px;
    background-color: #e1e6ed;
    margin-top: 14px;
  }

  .deed-detail-profile-header-title {
    width: 81px;
    height: 10px;
    background-color: #e1e6ed;
    margin-top: 9px;
  }

  .deed-detail-profile-header-text-container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    width: 181px;
  }

  .deed-detail-profile-header-title-text-small {
    width: 81px;
    height: 10px;
    background-color: #e1e6ed;
  }

  .deed-detail-profile-header-button {
    margin-top: 29px;
    width: 183px;
    height: 30px;
    background-color: #e1e6ed;
  }

  .deed-detail-profile-social-container {
    margin-top: 5px;
    width: 360px;
    height: 159px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .deed-detail-profile-social-button {
    margin-top: 24px;
    width: 260px;
    height: 44px;
    border-radius: 2px;
    background-color: #e1e6ed;
  }

  .deed-detail-profile-social-links {
    display: flex;
    width: 133px;
    justify-content: space-between;
    margin-top: 42px;
  }

  .deed-detail-profile-social-link {
    width: 33px;
    height: 33px;
    border-radius: 2px;
    background-color: #e1e6ed;
  }

  .deed-detail-loader-container {
    display: flex;
    flex-direction: column;
  }

  .deed-detail-loader-container-header {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1170px;
  }

  .deed-detail-breadcrumb-loader {
    display: inline-flex;
    margin: 23px 0 28px;
    min-width: 1140px;
  }

  .deed-detail-breadcrumb {
    width: 281px;
    height: 18px;
    background-color: #e1e6ed;
  }

  .deed-detail-loader-container-bottom {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    min-height: 1170px;
    background-color: #f4f5f7;
  }

  .deed-detail-content-container {
    margin-right: 28px;
  }

  .deed-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  /*package pricing user*/
  .active {
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, .3);
    border-bottom: none;
    background-color: #fff;
    z-index: 2;
  }




  .content-img {
    width: 16px;
    height: 13px;
    margin-right: 9px;
    padding-top: 2px;
  }

  .content-title {
    color: #2d3640;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .gig-content {
    cursor: pointer;
    &:nth-child(n+2) {
      margin-left: 16px;
    }
  }

  .tumu{
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a575;
    padding-right: 10px;
    padding-top: 12px;
    &:hover{
      text-decoration: underline!important;
      color: #00a575;
    }
  }
</style>
