/**
 * Created by haluk on 4/14/17.
 */

const regular = resolve => require(['./regular.vue'], resolve);
const regular_mobile = resolve => require(['./regular_mobile.vue'], resolve);
const simple = resolve => require(['./simple.vue'], resolve);
const simple_mobile = resolve => require(['./simple_mobile.vue'], resolve);
const simple_error = resolve => require(['./simple_error.vue'], resolve);

export default {
  simple,
  regular,
  regular_mobile,
  simple_mobile,
  simple_error
}
