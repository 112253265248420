<template>
  <div>
    <transition name="modal" style="width: 400px;">
      <div class="modal-mask"  style="overflow-y: scroll; " @click="hide()">
        <div class="modal-container" style="width: 500px !important;overflow: scroll;max-height: 100%" @click.stop>
          <div style="float: right; padding-right: 10px;">
            <img @click="hide()" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div>
            <div class="hiddendiv"></div>
            <h2 style="font-size: 32px;font-weight: 600;color: #2d3640;text-align: center;">
              Freelancer Hattı
            </h2>

            <div class="hiddendiv"></div>
            <p style="color:#2d3640; font-size: 17px; line-height: 1.3;">
              Bionluk Freelancer Hattı'nı aktive ederek, cep telefonun üzerinden tüm Bionluk kullanıcılarıyla görüşmeye başlayabilirsin.
              Ayrıntılı bilgi için <a href="/destek/freelancer-telefon-hatti-27" target="_blank">destek sayfamızı</a> ziyaret edebilirsin.
            </p>
            <div class="hiddendiv"></div>
            <div>
              <h3 style="font-size: 24px;font-weight: 500;color: #2d3640;padding-bottom: 10px">
                Sık Sorulan Sorular
              </h3>
              <div class="faq-list">
                <div v-for="item in faqList" class="faq-list-item" @click="changeActiveStatus(item)">
                  <span class="faq-list-item-question">{{ item.question }}</span>
                  <transition name="fade">
                    <p v-if="item.isActive" class="faq-list-item-answer">
                    <span v-html="item.answer"></span>
                    <ol style="margin-top: 10px; margin-left: 25px;">
                      <li v-for="listItem in item.listItems" style="list-style-type: disc">
                        {{ listItem }}
                      </li>
                    </ol>
                    </p>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
  export default {
    name: "src-pages-modal-freelancerLineFaqModal",
    data() {
      return {
        faqList: [
          {
            isActive: false,
            question: "Freelancer Hattı nedir?",
            answer: "Freelancer Hattı, Bionluk kullanıcılarıyla iletişim kurmanın en hızlı yoludur.<br/><br/>0 (212) 909 23 10 numaralı Freelancer Hattı’nı arayarak görüşmek istediğin Bionluk kullanıcısının dâhili numarasını girer ve o kullanıcının Bionluk sisteminde kayıtlı olan cep telefonuna yönlendirilirsin. \n"
          },
          {
            isActive: false,
            question: "Nasıl Freelancer Hattı'nı kullanmaya başlayabilirim?",
            answer: "Önce Ayarlar sayfasındaki cep numarası bölümüne, cep telefonuna SMS olarak iletilecek aktivasyon kodunu girerek cep telefonunu onaylarsın ve dâhili numaranı oluşturursun. <br/><br/>Ardından kullanıcılar 0 (212) 909 23 10 numaralı telefonu arayarak dâhili numaranı tuşlar ve sistemde onaylamış olduğun cep telefonuna yönlendirilerek seninle görüşmeye başlar."
          },
          {
            isActive: false,
            question: "Freelancer Hattı bilgilerim nerede gözükür?",
            answer: "Freelancer Hattı dahili numaran profil sayfanda, tüm ilanlarında ve mesajlaşma ekranlarında diğer kullanıcılarla paylaşılır. Şayet paylaşılmasını istemiyorsan, “Ayarlar” bölümünden paylaşım seçeneklerini değiştirebilirsin.<br/><br/>" +
            "Freelancer Hattı sayesinde seni aramak isteyen kullanıcılara dâhili hattını verebilirsin.<br/><br/>"+
            "Cep telefonu numaran hiçbir kullanıcıyla paylaşılmaz."

          },
          {
            isActive: false,
            question: "Bu hat ücretli midir?",
            answer: "Bu servisi aylık 12.90 TL ücret ödeyerek aktif hale getirebilir, seni arayan kullanıcılarla 7 gün 24 saat sınırsız konuşabilirsin.<br/><br/>"+
                    "Aylık ücretin dışında herhangi bir ücret bulunmamaktadır.<br/><br/>"+
                    "Seni arayan kullanıcılarla sınırsız konuşabilirsin.<br/><br/>"+
                    "İlk 30 gün ücretsizdir."
          },
          {
            isActive: false,
            question: "Görüştüğüm kullanıcılar numaramı görür mü?",
            answer: "Görüştüğün kullanıcılar cep telefonu numaranı görmez.<br/><br/>Sadece dâhili numaranı bilen kullanıcılar seninle iletişime geçebilir."

          },
          {
            isActive: false,
            question: "Cep telefonuma hangi numaradan çağrı gelecek?",
            answer: "Cep telefonuna tüm çağrılar 0 (212) 909 23 10 numaralı telefondan gelir.<br/><br/>Seni arayan kullanıcının telefon numarasını göremezsin."
          },
          {
            isActive: false,
            question: "Bu hat üzerinden kişisel iletişim bilgilerimi paylaşırsam ne olur?",
            answer: "Bu hat üzerinden hiçbir şekilde iletişim bilgisi paylaşılmamaktadır. Site dışında iletişime geçmek oldukça güvensiz bir yoldur. Bu hat üzerinden iletişim bilgilerini paylaşman durumunda Bionluk’taki tüm işlemlerin sonlandırılır. Bu hat üzerinden yapılan tüm görüşmeler hizmet kalitesini artırmak ve kullanıcı güvenliği sağlamak adına kaydedilmekte ve botlarımız tarafından kontrol edilmektedir.<br/><br/>Bionluk Freelancer Hattı’nı kullanmaya başlamadan önce bu şartları kabul etmiş sayılırsın."
          }
        ]
      }
    },
    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      changeActiveStatus(item) {
        this.faqList.forEach(value => {
          if (value !== item) {
            value.isActive = false;
          }
        });
        item.isActive = !item.isActive;
      }
    },
    computed: {},
  }
</script>

<style scoped lang="scss">
  .faq-list-item{
    padding: 10px 0;
  }
  .faq-list-item-question{
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #2d3640;
    padding: 10px 0px;
  }
  .faq-list-item-answer{
    font-size: 16px;
    color: #2d3640;
    padding: 10px 0px;
  }

  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .faq-list{
    padding-bottom: 100px;
  }

  .modal-container{
    padding-bottom: 0;
  }
</style>
