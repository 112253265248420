/**
 * Created by haluk on 6/9/17.
 */
const v2 = resolve => require(['./v2.vue'], resolve);
const v1_mobile = resolve => require(['./v1_mobile.vue'], resolve);

export default {
  v2,
  v1_mobile
}
