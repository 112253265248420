/**
 * Created by haluk on 4/17/17.
 */


const v1_mobile = resolve => require(['./v1_mobile.vue'], resolve);
const v1 = resolve => require(['./v1.vue'], resolve);

export default {
  v1_mobile,
  v1
}
