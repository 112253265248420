<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div @click="slide('back')" class="backBox" :style="slideNo > 0 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg">
      </div>
      <div @click="slide('next')" class="nextBox" :style="items.length - slideNo > 4 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg">
      </div>
      <div style="position: relative!important; overflow: hidden">

        <div class="cardBoxContainer" :style="'height:364px; transform: translate3d(-'+(228*slideNo)+'px, 0px, 0px);'">

          <div class="cardBoxFlexC" v-for="freelancer in items" :key="freelancer.username">
            <div class="cardBox">
              <div class="rainDropcontainer">
                <div class="firstLayer"></div>
                <div class="secondLayer"></div>
                <div class="thirdLayer"></div>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                  :to="'/'+freelancer.username">
                  <img :src="freelancer.avatar" class="freelancerAvatar">
                </router-link>
              </div>
              <p class="username">{{freelancer.username}}</p>
              <p class="title">{{freelancer.title}}</p>
              <div class="rating">
                <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                <div v-if="freelancer.rating && freelancer.rating.commentCount">
                  <span class="rate">{{freelancer.rating.commentRating === 5 ? '5.0' : freelancer.rating.commentRating }}</span>
                  <span class="count">({{freelancer.rating.commentCount}})</span>
                </div>
              </div>
              <div class="reText">
                <template v-if="freelancer.recurring_orders">
                  <img class="reTextIcon" style="width: 14px; height: 14px" src="https://gcdn.bionluk.com/site/cicons/ic-change-path.svg" onload="SVGInject(this)">
                  <p><span>{{freelancer.recurring_orders}}</span> Tekrarlanan Sipariş</p>
                </template>

              </div>
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type)"
                @click.native="handleGlobalClick($store.state.previousComponentPage,type)"
                :to="'/'+freelancer.username">
                <button class="profileButton" style="margin-top: 28px;">Profile Git</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import Loader from "../toolbox/loader.vue"

  export default {
    props: ["list","type"],
	  components:{ Loader },
    data() {
      return {
      	pageLoaded:true,
	      slideNo: 0,
	      sliding:false,
        items: this.list || [],
      }
    },

    methods:{
	    retrieveItems() {

	    	if(!this.list || this.list.length === 0){
			    this.pageLoaded = false;
			    this.api.feeds.newMainPage(this.type)
				    .then(({data}) => {
					    let result = data;
					    this.pageLoaded = true;

					    if (result.success) {
						    this.items = result.data;
						    if(this.type === 'lovedFreelancers'){
							    this.$store.state.lovedFreelancers = result.data;
                }
					    } else {
						    this.pageLoaded = true;
					    }
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: err});
				    });
        } else {
			    this.pageLoaded = true;
		    }
	    },
	    slide(what){

		    this.sliding = true;
		    setTimeout(function(){
			    this.sliding = false;
		    }, 500);

		    if(what === 'next'){
			    this.slideNo++;
		    } else{
			    this.slideNo--;
		    }

	    }
    },
    created() {
    	this.retrieveItems()
    }
  }
</script>

<style scoped lang="scss">
  .freelancerAvatar {
    width: 91px;
    height: 91px;
    border-radius: 100%;
    border: 2px solid #eaeaea;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 4;
  }

  .rainDropcontainer {
    position: relative;
    display: block;
    width: 132px;
    height: 132px;
    margin-top: 15px;
  }


  .firstLayer {
    width: 108px;
    height: 108px;
    border-radius: 100%;
    opacity: 0.1;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 3;
  }

  .secondLayer {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    opacity: 0.08;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2;
  }

  .thirdLayer {
    width: 132px;
    height: 132px;
    border-radius: 100%;
    opacity: 0.05;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }

  .nextBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    right: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }

  .backBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }

  .cardBoxContainer{
    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;
    height: 368px;
    .cardBoxFlexC{
      display: flex;
    }
    .cardBox{

      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 17px;
      width: 209px;
      height: 361px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;


      .profileButton{
        background-color: white;
        width: 140px;
        height: 34px;
        padding-bottom: 2px;
        border-radius: 2px;
        border: solid 1px rgba(45, 54, 64, 0.2);

        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2d3640;
        transition: .2s;
        &:hover{
          background-color: #2d3640!important;
          color:#fff
        }

      }
      .username{
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: center;
        color: #2d3640;
      }

      .title{
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 16px;
        margin-top: 4px;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.8;
        letter-spacing: -0.5px;
        text-align: center;
        color: #8b95a1;
      }


      .rating{

        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        .rate{
          margin-left: 2px;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffbe00;
        }

        .count{
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d3640;
        }
      }

      .reText{


        text-align: center;
        margin-top: 28px;
        height: 14px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;

        display: flex;
        span{
          font-weight: bold;
        }
        p{
          line-height: 1;
          margin-left: 5px;
        }
        .reTextIcon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #8b95a1;
          }
        }
      }
    }
  }

</style>
