<template>
    <button :class="getButtonTypeClass()" :style="buttonStyle" :disabled="disabled" @click="$emit('click')">
      <div v-if="buttonType === 'greenPlus'" class="plus-icon-container">
        <img class="plus-icon" src="https://gcdn.bionluk.com/uploads/message/4dbe980d-b144-4b46-bfba-de04462455e5.svg" onload="SVGInject(this)"/>
      </div>
      <slot>
        Button Name
      </slot>
    </button>
</template>

<script>

  export default {
    name: 'CustomButton',
    props: {
      buttonStyle: {
        type: String,
        default: ''
      },

      buttonType: {
        type: String,
        default: ''
      },

      disabled: {
        type: Boolean,
        default: false
      }

    },
    data() {
      return {}
    },
    methods: {
      getButtonTypeClass() {
        return 'bionluk-button ' + this.buttonType;
      }
    }
  }

</script>

<style scoped lang="scss">


  .bionluk-button {
    outline: none;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    text-align: center;
    width: 240px;
    height: 54px;
    background-color: #2d3640;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.30px;
    color: #fff;
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

  .green {
    background-color: #00a575;
    &:hover {
      background-color: rgba(0, 165, 117, 0.99) !important;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

  .greenPlus {
    display: flex;
    align-items: center;
    background-color: #00a575;
    &:hover {
      background-color: rgba(0, 165, 117, 0.99) !important;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }

    .plus-icon-container{
      padding-right: 5px;
      padding-top: 5px;
      .plus-icon /deep/{
        path{
          fill: white;
        }
      }
    }
  }



  .red {
    background-color: #fd4056;
    &:hover {
      background-color: rgba(253, 64, 86, 0.95) !important;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

  .dark {
    background-color: #2d3640;
    &:hover {
      background-color: rgba(45, 54, 64, 0.95) !important;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

  .border {
    color: #2d3640;
    border: 1px solid rgba(45, 54, 64, 0.2);
    background-color: #fff;
    &:hover {
      background-color: #2d3640 !important;
      color: #fff !important;
      border: none !important;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

  .borderNoHover {
    color: #2d3640;
    border: 1px solid rgba(45, 54, 64, 0.2);
    background-color: #fff;
    &:hover {
      border: none !important;
      background-color: #fff!important;
      color: #2d3640!important;
      opacity: 0.8;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
      color: #fff !important;
    }
  }

</style>
