<template>
  <div v-if="type && type === 'bicoin'" class="gigBox">
    <div style="display: flex; margin-top: 35px;">
      <img src="https://gcdn.bionluk.com/site/cicons/bicoin.svg">
      <div style="margin-left: 15px;">
        <p style="color: #fd4056; font-size: 40px; font-weight: bold;">{{user.info.total_bicoin}}</p>
        <p style="color: #8b95a1; font-weight: 300; font-size: 12px;">Toplam bicoin</p>
      </div>
    </div>
    <p style="margin-top:38px;color: #2d3640; font-size: 16px; font-weight: bold">İlanlarını Öne Çıkar</p>
    <p style="margin-top:10px;width:220px;color: #2d3640; line-height: 1.43; font-size:14px; text-align: center">bicoin’lerini kullanarak iş ilanını kategori sayfalarında öne çıkar.</p>
    <router-link to="/panel/bicoin" class="bia-button-gig-c">bicoin'lerini Kullan</router-link>
  </div>
  <div v-else-if="!item || !item.id" class="gigBox">
    <router-link
      @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null)"
      @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null)"
      to="/panel/ilanlar/yeni">
      <div class="bg" style="background-image: url(https://gcdn.bionluk.com/site/cicons/gi-g-image%402x.png)">
      </div>
    </router-link>

    <div style="width: 100%;">
      <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
        <div style="width: 40px; padding-left: 16px;">
          <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'profile', impressionUUID || null)"
            @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'profile', impressionUUID || null)"
            :to="'/'+user.username">
            <img class="avatar" :src="user.avatar_url">
          </router-link>
        </div>
        <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
          <div style="height: 40px;">
            <p class="username"><router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'profile', impressionUUID || null)"
              @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'profile', impressionUUID || null)"
              :to="'/'+user.username">{{user.username}}</router-link></p>
            <p class="title">{{user.info.title}}</p>
          </div>
          <div style="height: 40px; padding-right: 16px;">
            <p class="price">500<span>TL</span></p>
            <p class="helpText">Başlangıç</p>
          </div>
        </div>
      </div>
    </div>

    <router-link
      @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null)"
      @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null)"
      class="gigTitleLink" to="/panel/ilanlar/yeni">
      <p class="gigTitle">Sevgili {{this.user.firstname ? this.user.firstname : this.user.username}}, bu senin Bionluk Freelancer’ı olarak ilk iş ilanın olabilir</p>
    </router-link>
  </div>
  <div v-else class="gigBox" :style="boxSize && boxSize === 'big' ? 'height: 385px;' : 'height: 333px;' ">
    <router-link
      v-if="item.sellerUsername === user.username"
      data-balloon="İlanı Düzenle"
      data-balloon-pos="up"
      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,type, clickedFrom || null)"
      @click.native="handleGlobalClick($store.state.previousComponentPage,type, clickedFrom || null)"
      :to="'/panel/ilanlar/duzenle/'+item.uuid" class="likeAreaWhite">
      <img class="likeIconWhite" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)">
    </router-link>
    <div v-if="item.isFeatured" class="featuredArea">
      Öne Çıkan
    </div>
    <div v-if="item.commentCount" class="starArea">
      <img style="width: 16px; height: 16px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
      <p class="rate">{{item.commentRating | convertNumberToFraction}}</p>
      <p class="count">({{item.commentCount}})</p>
    </div>
    <div v-if="item.portfolios[0] && item.portfolios[0].fileType === 'audio'" class="sound-icon-container">
      <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
    </div>
    <div v-if="item.portfolios[0] && item.portfolios[0].fileType === 'video'" class="sound-icon-container">
      <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
    </div>
    <router-link
      @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'gig', impressionUUID || null, {gig_id:item.id, seller_id:item.seller_id})"
      @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'gig', impressionUUID || null, {gig_id:item.id, seller_id:item.seller_id})"
      :to="item.gigURL ? item.gigURL + addedQuery : item.slug + addedQuery">
      <div class="bg" :style="'background-image: url('+ (item.imageNameBu || item.portfolios[0] && item.portfolios[0].imageURLSmall || item.portfolios[0] && item.portfolios[0].imageURL) +')'">
      </div>
    </router-link>

    <div style="width: 100%;">
      <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
        <div style="width: 40px; padding-left: 16px;">
          <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'profile', impressionUUID || null)"
            @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'profile', impressionUUID || null)"
            :to="'/'+item.sellerUsername">
            <img class="avatar" :src="item.sellerAvatarURL">
          </router-link>
        </div>
        <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
          <div style="height: 40px;">
            <p class="username"><router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'profile', impressionUUID || null)"
              @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'profile', impressionUUID || null)"
              :to="'/'+item.sellerUsername">{{item.sellerUsername}}</router-link></p>
            <p class="title">{{item.sellerTitle}}</p>
          </div>
          <div style="height: 40px; padding-right: 16px;">
            <p class="price" :style="String(computedPrice).length > 4 ? 'letter-spacing: -3.8px' : null">{{computedPrice | number_format }}<span>TL</span></p>
            <p class="helpText">{{computedPriceHelpText}}</p>
          </div>
        </div>
      </div>
    </div>

    <router-link
      @contextmenu.native="handleGlobalClick($store.state.componentMap.page, clickedFrom || null, 'gig', impressionUUID || null,{gig_id:item.id, seller_id:item.seller_id})"
      @click.native="handleGlobalClick($store.state.previousComponentPage, clickedFrom || null, 'gig', impressionUUID || null,{gig_id:item.id, seller_id:item.seller_id})"
      class="gigTitleLink" :to="item.gigURL ? item.gigURL + addedQuery : item.slug + addedQuery">
      <p class="gigTitle" style="height: 48px;">Ben, <span>{{item.title}}</span></p>
    </router-link>
    <div v-if="(listId || item.sellerUsername !== user.username) && showAddToList" style="width: 90%;">

      <p style="border-bottom: 1px solid #eff3f8; height: 10px"></p>
      <div class="add-list-area">
        <div v-show="!listId && item.likeStatus.action !== -1" class="add-list-icon-container" @click="addListOpen = true">
          <img class="add-list-icon" src="https://gcdn.bionluk.com/uploads/message/00f6fa53-091c-4f3e-a086-2c499c2f7fce.svg" onload="SVGInject(this)"/>
        </div>
        <div v-show="listId" class="add-list-icon-container" @click="removeFromList(listId)">
          <img class="add-list-icon" src="https://gcdn.bionluk.com/uploads/message/4d7e2597-424e-4251-a27c-cd0a17071b24.svg" onload="SVGInject(this)"/>
        </div>
        <div v-show="!listId && item.likeStatus.action === -1" class="add-list-icon-container" @click="addListOpen = true">
          <img class="add-list-icon-red" src="https://gcdn.bionluk.com/uploads/message/205977ee-c82d-426d-93f7-ec98970c9fe3.svg" onload="SVGInject(this)">
        </div>
        <p v-if="!listId && item.likeStatus.action !== -1" @click="addListOpen = true" class="add-list-text">Listeye Ekle</p>
        <p v-else-if="!listId && item.likeStatus.action === -1" @click="addListOpen = true" class="add-list-text-red">Listemde</p>

        <p v-if="listId" @click="removeFromList(listId)" class="add-list-text">Listeden Çıkar</p>
        <div  v-click-away="() => addListOpen = false" v-if="addListOpen" class="setting-box">
          <loader style="width: 30px; height: 30px" v-if="buttonLoading"/>
          <template v-else>
            <div class="setting-box-options-overflow">
              <div class="setting-box-options" v-for="(liste, lin) in lists" :key="lin" :style="lin + 1 !== lists.length ? ' border-bottom: solid 1px #eaedf2;' : ''">
                <custom-check
                  :loading="checkLoading"
                  called-from="singlegig"
                  :label="liste.title"
                  :single-gig="liste.counts"
                  :id="'list_'+item.uuid+'_'+liste.id"
                  :checked="liste.liked"
                  :value="liste.liked"
                  @input="likeChecked(liste,lin)"
                />



              </div>


              <div  class="settings-box-add-new mt-3" @click="openAddListModal">
                <div class="ml-2">Yeni Liste Oluştur</div>
                <div class="rounded-plus-button-gray">

                  <img class="icon" src="https://storage.googleapis.com/superlancer.appspot.com/public/ic-plus.svg" onload="SVGInject(this)">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

    </div>
    <div v-if="boxSize && boxSize === 'big' && item.recurring_orders" style=" align-self: flex-start; margin-left: 15px;">
      <div class="bigBoxLabel" style="background-color: #65c4db;">
        <img class="recurring-icon" src="https://gcdn.bionluk.com/site/cicons/ic-recurring.svg" onload="SVGInject(this)"/>
        <div style="margin-left: 5px;">
          <span>{{ item.recurring_orders }}</span> Tekrarlanan sipariş
        </div>
      </div>
    </div>
    <div v-if="boxSize && boxSize === 'big' && item.commentSuccess" style=" align-self: flex-start; margin-left: 15px;">
      <div class="bigBoxLabel" style="background-color: #f29933;">
        <img class="recurring-icon" src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)"/>
        <div style="margin-left: 5px;">
          <span>%{{ item.commentSuccess }}</span> Müşteri memnuniyeti
        </div>
      </div>

    </div>


  </div>
</template>

<script>

import CustomCheck from "@/pages/components/CustomCheck";
import minBy from 'lodash/minBy'

  export default {
    components: { CustomCheck },
    props: {
      item: {
        type: Object
      },
      type: {
        type: String
      },
      filters: {
        type: Object
      },
      clickedFrom: {
        type: String
      },
      impressionUUID: {
        type: String
      },
      boxSize: {
        type: String
      },
      listId: {
        type: Number
      },
      showAddToList: {
        type: Boolean,
        default: () => true
      }
    },
    data() {
      return {
        computedPrice: '',
        compuetedPriceHelpText: '',
        addedQuery: '',

        buttonLoading:false,
        checkLoading:false,
        addListOpen:false,
        lists:[]
      };
    },

    methods:{
      getLists(){
        if(this.buttonLoading) return false;
        this.buttonLoading = true;
        this.api.general.crudList('get_all', '', null, this.item.id, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.lists = result.data.lists
            this.buttonLoading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          })

      },
      likeChecked(l,index){
        if(l.liked){
          this.lists[index].liked = false;
          this.lists[index].counts.gig--;
          this.removeFromList(l.id);
          this.item.likeStatus = {action:1}
          this.lists.forEach((ll) => {
            if(ll.liked){
              this.item.likeStatus = {action:-1}
            }
          })
        } else {
          this.lists[index].liked = true;
          this.lists[index].counts.gig++;
          this.addToList(l.id);
          this.item.likeStatus = {action:-1}
        }
      },
      openAddListModal(){
        this.addListOpen = false;
        let data = {
          type: 'create',

        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_LIST, info: {data: data}});

      },



      addToList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        let page = this.$store.state.componentMap.page;
        this.api.general.crudList('like_item', 'gig', listId, this.item.id, this.$Progress,0,page)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },
      removeFromList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        this.api.general.crudList('delete_item', 'gig', listId, this.item.id, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.EventBus.$emit('removeItemFromList', {id:this.item.id});
              this.$toasted.global.errorToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

	    like(receivedGig) {
	    	let willPostLikeStatusAction = receivedGig.likeStatus.action;
		    this.item.likeStatus.action = -(this.item.likeStatus.action);
		    this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, willPostLikeStatusAction, receivedGig.uuid, null, this.$store.state.componentMap.page)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {

				    } else {
					    this.$router.push(result.data.redirect_url);
				    }
			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },



    },

    watch:{
      addListOpen:function (newVal, oldVal){
        if(newVal){
          if (!this.isLoggedIn) {
            this.$router.push('/login');
          } else {
            this.getLists()
          }
        }
      },

    },

    created() {

      if(this.type === 'fromList' && this.filters && this.item.service_includes){

        let its = this.item.service_includes.filter(service => {
          return (
            (!this.filters.service_includes.length || this.filters.service_includes.indexOf(service.id) > -1) &&
            (!this.filters.minBudget || service.price >= parseInt(this.filters.minBudget)) &&
            (!this.filters.maxBudget || service.price <= parseInt(this.filters.maxBudget)) &&
            (!this.filters.duration || service.duration <= parseInt(this.filters.duration))
          )
        })

        if (its && minBy(its, 'price')) {
          let minPricePackage = minBy(its, 'price');
            this.computedPrice = minPricePackage.price;
            this.computedPriceHelpText = 'Başlangıç';
            const serviceIncludesMinPrice = minBy(this.item.service_includes, 'price')
            if(serviceIncludesMinPrice && minPricePackage.price !== serviceIncludesMinPrice.price){
              let addQuery = '?package_id=' + minPricePackage.package_id;

              this.addedQuery = minPricePackage.is_super_fast ? addQuery + '&super_fast=1' : addQuery;
            }

        } else {
          this.computedPrice = this.item ? this.item.price : '';
          this.computedPriceHelpText = this.item ? this.item.priceHelpText : ''
        }

      } else {
        this.computedPrice = this.item ? this.item.price : ''
        this.computedPriceHelpText = this.item ? this.item.priceHelpText  :'';
      }
    }
  }
</script>

<style scoped lang="scss">



.rounded-plus-button{
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/superlancer.appspot.com/public/rounded-border-bg.svg);
  background-size: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon /deep/ {
    height:14px;
    width: 14px;
    path {
      fill: #65c4db
    }
  }
}

.rounded-plus-button-gray{
  width: 24px;
  height: 24px;
  background: #eff3f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  .icon /deep/ {
    height:11px;
    width: 11px;
    path {
      fill: #8b95a1
    }
  }
}

.setting-box {
  position: absolute;
  width: 240px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 0 20px 20px;


  //    border: solid 1px #bfc8d2;
  left: -15px;
  bottom: 30px;
  z-index: 99910;
  box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.2);
  border: solid 1px rgba(94, 107, 121, 0.2);


  .setting-box-options-overflow{
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    //padding-right: 1px;
    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dde3eb;
    }
  }
  .setting-box-options {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: #2d3740;
    }
    .setting-box-options-title{
      font-size: 16px;
      color: #5e6b79;
      text-overflow: ellipsis;
      width: 180px;
      white-space: pre;
      overflow: hidden;
    }

    .setting-box-options-sub-title{
      font-size: 11px;
      margin-top: 4px;
      font-style: italic;
      color: #8b95a1;

    }
  }

  .settings-box-add-new {
    width: 218px;
    height: 36px;
    border-radius: 5px;
    margin-top: 10px;

    display: flex;
    align-items: center;


    justify-content: space-around;

    cursor:pointer;color:#5e6b79;font-size:14px;
    border: dashed 2px #bfc8d2;
    &:hover{
      border-color: #5e6b79;
    }
    .te{
      font-size: 14px;
      color: #5e6b79;
    }
  }
}

.setting-box::after {
  content: '';
  position: absolute;
  left: 15px;
  bottom: -7.8px;
  width: 15px;
  height: 15px;
  border-bottom: solid 1px rgba(94, 107, 121, 0.2);
  border-right: solid 1px rgba(94, 107, 121, 0.2);
  box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.2);
  border-top: 0;
  border-left: 0;
  border-radius: 20% 0 0 0 / 25%;
  transform: rotate(45deg);
  background-color: #fff;
}

.add-list-area{
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;

  .add-list-text{
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #2d3640;
    margin-left: 5px;
  }
  .add-list-text-red{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fd4056;
    margin-left: 5px;
  }
  .add-list-icon-container{
    cursor: pointer;
    .add-list-icon /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #8b95a1;
      }
    }
    .add-list-icon-red /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #fd4056;
      }
    }
  }

  &:hover{
    .add-list-icon-container{
      .add-list-icon /deep/{
        width: 20px;
        height: 20px;
        path{
          fill: #5e6b79;
        }
      }
    }
  }
}

  .bia-button-gig-c{

    margin-top: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 180px;
    height: 42px;
    padding-bottom: 2px;
    border-radius: 2px;
    border: solid 1px rgba(45, 54, 64, 0.2);

    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3640;
    transition: .2s;
    &:hover{
      background-color: #fd4056!important;
      color:#fff;
      text-decoration: none;
    }
    a{
      text-decoration: none;
      &:hover{
        color:#fff
      }
    }
  }
  .gigBox{

    position: relative;
    display: flex;


    flex-direction: column;
    align-items: center;

    width: 278px;
    height: 318px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;



    .bg{

      position:relative;width:280px; border-top-left-radius: 10px; border-top-right-radius: 10px; height:158px; background-size: contain; background-repeat: no-repeat;
    }



    .featuredArea{
      z-index: 33;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: -1px;
      top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 3px;
      height: 22px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      background: #fd4056;

    }

    .starArea{
      z-index: 33;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -1px;
      top: 113px;
      width: 100px;
      height: 30px;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      img{

        width: 16px;
        height: 16px;
      }

      .rate{

        margin-left: 4px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffbe00;

      }

      .count{

        margin-left: 3px;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }

    .likeAreaWhite{
      cursor: pointer;
      position: absolute;
      z-index: 33;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: #2d3640;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .likeIconWhite /deep/ {
        width: 18px;
        height: 18px;
        path {
          fill: #fff;
        }
      }
      &:hover{
        .likeIconWhite /deep/ {
          width: 18px;
          height: 18px;
          path {
            fill: #fd4056;
          }
        }
      }


    }
    .likeAreaRed{
      cursor: pointer;
      position: absolute;
      z-index: 1;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: #fd4056;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .likeIconRed /deep/ {
        width: 18px;
        height: 18px;
        path {
          fill: #fff;
        }
      }
    }
    .avatar{
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username{
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #2d3640;
    }

    .title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
      font-size: 13px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: #8b95a1;
    }
    .price{
      font-size: 26px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;

      letter-spacing: -1.5px;
      margin-top: -8px;
      text-align: right;
      color: #26ca9a;
      span{
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;

        letter-spacing: normal;
        text-align: center;
        color: #26ca9a;
        padding-left: 3px;
      }
    }

    .helpText{
      font-size: 11px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #8b95a1;
    }

    .gigTitle{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;


      width: 250px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2d3640;
      span{
        text-transform: lowercase;
      }
    }

    .gigTitleLink{
      &:hover{
        text-decoration: none!important;
      }
    }


    .bigBoxLabel{
      margin-top: 10px;
      display: flex;
      align-items: center;

      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 1px;
      border-radius: 6px;

      height: 25px;

      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      span{
        font-weight: bold;
      }


      .recurring-icon /deep/ {

        width: 14px;
        height: 14px;
        path {
          fill: #fff;
        }
      }

    }
  }



</style>
