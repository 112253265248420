<template>
  <div class="settings" style=" ">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; right: 0; width: 33%; height: 100%; background-color: #eff3f8; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #eff3f8!important;">
      <workstation-nav></workstation-nav>
      <div class="content" :style="{ 'min-height': windowHeight - 110 + 'px' }">
        <div style="display: flex;justify-content: space-between;width: 849px;align-items: center;height: 88px;margin-left: 30px">
          <div>
            <p class="page-header">Yapılacaklar </p>
          </div>
          <div v-if="showFlushLink">
            <a @click="flush()" style="color: #fd4056">Yapılanları Temizle</a>
          </div>
        </div>

        <div v-if="todoList.length && show" class="todoitems content-white">
          <div v-show="openedMenu" class="todoMenu" style="float: right;">
            <img class="closeTodoMenu" @click="openedMenu = false" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
            <span class="todoMenuItem" style="padding-right: 15px;" @click="flush()">
             Yapılanları Temizle
            </span>
          </div>
          <loader v-if="flushTodoLoader"></loader>
          <div v-else class="todo-table">
            <div class="todo-table-item header" style="height: 53px;">
              <div class="todo-table-item-detail number" style="justify-content: center">
                #
              </div>
              <div class="todo-table-item-detail category">
                Kategori
              </div>
              <div class="todo-table-item-detail subject">
                Konu
              </div>
              <div class="todo-table-item-detail date">
                Tarih
              </div>
              <div class="todo-table-item-detail from">
                Kim
              </div>
              <div class="todo-table-item-detail button">

              </div>
            </div>
            <div v-for="(todo, index) in todoList" class="todo-table-item" :style="{'background-color': (todo.done_at === null) ? '#FFF' : '#f3fcf3' }">
              <div class="todo-table-item-detail number">
                        <span v-if="todo.done_at === null" class="todo-list-item-detail-number-text">
                    {{ index + 1 }}
                  </span>
                <span class="todo-list-item-detail-number-text" v-else style="border-color: #6dce6a">
                    <img :src="$root.CDN_SITE + 'icon/tansel_yesil_tick.svg'">
                  </span>
              </div>
              <div class="todo-table-item-detail category" :style="{'color': todo.done_at === null ? '#8b95a1' : '#6dce6a'}">
                <span>{{ todo.title }}</span>
              </div>
              <div class="todo-table-item-detail subject" :style="{'color': todo.done_at === null ? '#242424' : '#6dce6a', 'text-decoration': todo.done_at === null ? 'none' : 'line-through'}">
                <span>{{ todo.description }}</span>
              </div>
              <div class="todo-table-item-detail date">
                <span v-show="todo.deadline_at">
                  {{ todo.deadline_at | customDateFormat }}
                </span>
              </div>
              <div class="todo-table-item-detail from">
                <router-link v-show="todo.who" :to="'/' + todo.who" style="text-decoration: none">
                    <span class="todo-list-item-detail-from">
                      {{ todo.who }}
                    </span>
                </router-link>
              </div>
              <div class="todo-table-item-detail button">
                <router-link v-show="todo.done_at === null" :to="'/'+todo.target_url" style="text-decoration: none">
                  <button class="todo-list-item-detail-button-link">{{ todo.button_text }}</button>
                </router-link>
              </div>
              <button class="todo-list-item-ignore-button" v-if="todo.dismiss && !todo.done_at" @click="dismissTodo(todo)">Yoksay</button>
            </div>
          </div>
          <div v-if="!openedLoading && seemorediv" style="display: flex;justify-content: center;padding: 20px 0px;background-color: #FFF;width: 849px">
            <a class="see-more-text" @click="seeMore()">Daha fazla göster +</a>
          </div>
          <div v-show="openedLoading" style="width: 849px;background-color: #FFF">
            <div class="loader" style="text-align: center; margin:20px auto;"></div>
          </div>
        </div>

        <div v-if="!todoList.length && show" class="notodoitems content-white" style="text-align: center;padding: 30px 0;width: 849px">
          <div class="numberCircle" style="border:6px solid #dde3eb;width: 100px; height: 100px; margin: 0 auto; text-align: center">
              <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
              <img style="width: 60px; height: 60px;" :src="$root.CDN_SITE + 'icon/ic_done_gray.svg'">
                </span>
          </div>
          <div class="hiddendiv20"></div>
          <p class="h1_2" style=" font-size:16px; font-weight:400!important;color: #8b95a1!important;">Harika, yapılacaklar listen boş.</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

	import WorkstationNav from "../../../components/shared/globalSideNav";

  export default {
    name: "src-pages-body-workstation-todo-v2",
    components: {WorkstationNav},
    data () {
      return {
        todoList: [],
        todoCount: 0,
        openedLoading: false,
        flushTodoLoader: false,
        openedMenu: false,
        show: false,
        showFlushLink: false,
        limit: 10,
        offset: 0,
        seemorediv: true
      }
    },

    methods: {
      retrieveTodos(receivedMessage,limit,offset) {
        if (receivedMessage) {
          this.flushTodoLoader = true;
        } else {
          this.openedLoading = true;
        }
        this.api.user.retrieveTodos(limit, offset)
          .then(({data}) => {
            let result = data;
            this.show = true;
            this.openedLoading = false;
            if (result.success) {
              if (receivedMessage) {
                this.flushTodoLoader = false;
                this.$toasted.global.infoToast({description: receivedMessage});
                this.seemorediv = result.data.seemorediv;
                this.todoCount = result.data.todo_count;
                this.todoList = result.data.todos;
              } else {
                this.offset += this.limit;
                this.seemorediv = result.data.seemorediv;
                this.showFlushLink = result.data.show_flush_link;
                this.todoCount = result.data.todo_count;
                this.todoList = this.todoList.concat(result.data.todos);
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      seeMore() {
        this.retrieveTodos(null, this.limit, this.offset);
      },

      flush() {
        this.openedMenu = false;
        this.openedLoading = true;
        this.api.user.flushTodos()
          .then(({data}) => {
            let result = data;
            this.openedLoading = false;
            if (result.success) {
              this.showFlushLink = false;
              this.offset = 0;
              this.retrieveTodos(result.message, this.limit, this.offset);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      dismissTodo(todo) {
        this.api.user.dismissTodo(todo.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              todo.done_at = true;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    mounted() {
      this.retrieveTodos(null, this.limit, this.offset);
    }
  }
</script>

<style scoped lang="scss">
  .chatrow{
    height: 80px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #fd4056;

  }
  .numberCircle span {

    text-align: center;
    line-height: 30px;
    display: block;
    color: #5e6b79;
    height: 36px;
  }


  .todoTitle{
    padding-left:12px;
    color: #8b95a1;
    font-size: 14px;
    line-height: 1.29;
  }

  .todoDescription{
    width: 350px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    font-style: normal;
    color: #fd4056;
  }

  .todoWho{ color: #fd4056; text-decoration: underline;}

  .todoDeadline{
    padding-left:12px;
    font-size: 14px;
    line-height: 1.29;
    color: #8b95a1;
  }

  .todoDone{
    text-decoration: line-through!important;
    background-color: #f3fcf3;
  }

  .todoMenuItem{
    cursor: pointer;
    color: #8b95a1;
    font-weight: 500;
  }
  .todoMenuItem:hover{
    color: #fd4056;
  }

  .todoMenu{
    margin-left: 633px; z-index: 2; margin-top: 12px; position:absolute; width:150px; padding:20px;background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); height:auto
  }

  .closeTodoMenu{
    cursor: pointer;
    position: absolute; margin-left: 155px; margin-top: -10px; height: 10px;
  }

  .openTodoMenu{cursor:pointer;height: 6px;}

  .todonumber{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .todoButtonTitle{
    width: 153px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }

  .todo-list{
    display: flex;
    flex-direction: column;
  }

  .todo-list-header,
  .todo-list-item-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .todo-list-header-number,
  .todo-list-item-detail-number {
    display: flex;
    flex: 2;
  }

  .todo-list-header-category,
  .todo-list-item-detail-category {
    display: flex;
    flex: 4;
  }

  .todo-list-header-subject,
  .todo-list-item-detail-subject {
    display: flex;
    flex: 6;
  }

  .todo-list-item-detail-from {
    color: #fd4056;
    text-decoration: none;
    line-height: 1.29;
  }

  .todo-list-header-date,
  .todo-list-item-detail-date {
    display: flex;
    flex: 1.8;
  }

  .todo-list-header-button,
  .todo-list-item-detail-button {
    display: flex;
    flex: 4;
  }

  .todo-list-item-detail-button-link {
    font-size: 14px;
  }



  .todo-list-header-number,
  .todo-list-item-detail-number {
    justify-content: center;
  }

  .todo-list-item-detail-number-text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border: solid 2px #242424;
    border-radius: 50%;
    color: #242424;
  }

  .todo-list-header-category,
  .todo-list-item-detail-category,
  .todo-list-header-date,
  .todo-list-item-detail-date{
    color: #8b95a1;
    font-size: 14px;
  }

  .todo-list-item-detail-category {
    line-height: 1.29;
  }

  .todo-list-item-detail-subject{
    color: #fd4056;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
  }

  .todo-list-item-detail-button-link {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    border: 0;
  }

  .todo-list-item-ignore-button {
    width: 70px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: #2D3640;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    border: 0;
  }
  .todo-list-header,
  .todo-list-item-detail {
    height: 70px;
    width: 849px;
  }

  .todo-list-header{
    color: #8b95a1;
    font-size: 14px;
    height: 54px;
    background-color: #FFF;
  }

  .todo-list-item{
    border-bottom: 1px solid #eaedf2;
  }

  .content-white{
    padding: 0;
    margin-bottom: 100px;
  }

  .page-header{
    margin: 0;
  }

  .see-more-text{
    font-size: 14px;
    font-weight: 600;
    color: #fd4056;
  }



  .header {
    display: none;
    background-color: #f2f2f2;
    color: #8b95a1;
  }

  .header > .todo-table-item-detail {
    white-space: normal;
    justify-content: flex-start;
  }

  .todo-table {
    display: flex;
    font-size: 14px;
    flex-flow: column;
    flex: 1 1 auto;
    width: 849px;
  }

  .todo-table-item {
    border-bottom: solid 1px #eaedf2;
    &:last-child{
      border-bottom: 0;
    }
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: row;
    height: 70px;
    background-color: #FFF;
  }

  .todo-table-item-detail {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    word-break: break-word;
    text-overflow: ellipsis;
    min-width: 0px;
  }

  .from{
    flex: 2;
    margin-left: 20px;
  }
  .category{
    flex: 1.5;
  }
  .subject{
    flex: 3;
    font-weight: 500;
    line-height: 1.29;
  }
  .date{
    flex: 1;
    line-height: 1.29;
  }
  .button{
    flex: 2;
  }
  .number{
    flex: 1;
    justify-content: center;
  }



</style>
