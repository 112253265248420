<template>
  <div>

  </div>
</template>

<script>
  export default {
    name: "src-pages-message-v1_mobile",
    data () {
      return { }
    },
  }
</script>
