/**
 * Created by bugra on 4/19/17.
 */
const v1 = resolve => require(['./v4.vue'], resolve);
const v2 = resolve => require(['./v4.vue'], resolve);
const v2_mobile = resolve => require(['./v3_mobile.vue'], resolve);
const v3 = resolve => require(['./v4.vue'], resolve);
const v4 = resolve => require(['./v4.vue'], resolve);
const v3_mobile = resolve => require(['./v3_mobile.vue'], resolve);

export default {
  v1,
  v2,
  v3,
  v4,
  v2_mobile,
  v3_mobile
}
