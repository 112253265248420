/**
 * Created by haluk on 6/18/17.
 */


const v3 = resolve => require(['./v3.vue'], resolve);
const v3_mobile = resolve => require(['./v3_mobile.vue'], resolve);
export default {
  v3,
  v3_mobile
}
