<template>
  <div>
    <div class="login" style=" padding: 0 20px;"  v-loader-screen="disableForLogin">
      <div class="hiddendiv"></div>

      <facebook-login v-if="false" :activateLink="false"></facebook-login>
      <div class="hiddendiv20"></div>

      <google-login :activateLink="false"></google-login>
      <div class="hiddendiv20"></div>

      <div class="or">
        <span>veya</span>
      </div>
      <div class="hiddendiv"></div>

      <div style="margin:0 auto; width: 100%; text-align: center">
        <form @submit.prevent="login(email, password)">
          <input placeholder="E-posta veya kullanıcı adı" v-model="email" style="height: 24px; padding-top: 10px; font-size: 16px;" >
          <div class="hiddendiv20"></div>
          <input type="password" placeholder="Şifre" v-model="password"  style="height: 24px; padding-top: 10px; font-size: 16px;">

          <div style="margin-top: 10px; margin-right: 20px; text-align: right">
            <router-link :to="'/forgot_password'" style="font-size: 14px;">
              <span>Şifremi unuttum</span>
            </router-link>
          </div>
          <div class="hiddendiv"></div>
          <button :disabled="disableForLogin" type="submit"  class="super-button-green">Giriş Yap</button>
        </form>

        <div class="hiddendiv20"></div>

        <p class="subheading2-text" style="font-weight: 500">
          Hesabınız Yok mu?
          <router-link :to="'/register'" style="font-weight: 600">Kaydol</router-link>
        </p>

      </div>
      <div class="hiddendiv"></div>
    </div>
  </div>
</template>

<script>
  import mixin from '../mixin'

  export default {
    name: "src-pages-body-auth-login-mobile-v1_mobile",
    mixins: [mixin]
  }
</script>

<style scoped>

  input {
    width: 270px;

  }

  .social-button{width: 270px!important;}
  form{
    text-align: center;
  }

</style>
