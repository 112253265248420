<template>
    <div style="width: 100%; margin: 0 auto; text-align: center; display: flex; justify-content: center">
      <div v-if="!$store.state.isMobile" class="version-warning">
        <div style=" width: 60px; display: flex; align-items: center; justify-content: center; margin-left: 50px;
  height: 60px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: rgb(255, 255, 255); margin-right: 30px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="bi-icon-on" data-inject-url="https://gcdn.bionluk.com/site/cicons/ic-ihaveinternet.svg" data-v-13376ec4="">
            <path fill="#000" fill-rule="nonzero" d="M16.366 14.367a7.525 7.525 0 0 0-8.723 0 1.499 1.499 0 1 0 1.728 2.448 4.547 4.547 0 0 1 5.266 0 1.499 1.499 0 0 0 1.73-2.448zM12 17.975a1.499 1.499 0 1 0 0 2.997 1.499 1.499 0 0 0 0-2.997zM23.481 7.342c-6.567-5.79-16.416-5.79-22.983 0a1.509 1.509 0 0 0-.12 2.119 1.509 1.509 0 0 0 2.118.13 14.4 14.4 0 0 1 18.987 0c.276.242.632.374.999.37a1.489 1.489 0 0 0 1-2.619zM4.085 10.87a1.489 1.489 0 0 0-.19 2.108 1.489 1.489 0 0 0 2.109.19 9.443 9.443 0 0 1 12.071 0 1.499 1.499 0 1 0 1.919-2.298 12.431 12.431 0 0 0-15.909 0z"></path>
          </svg>
        </div>
        <p class="refresh-text">İnternet bağlantın tekrar sağlandı. Bağlantın yokken yeni mesaj veya bildirim almış olabilirsin. Bu yüzden lütfen sayfayı yenile.</p>
        <div class="refresh-button" @click="forceRefresh()">Sayfayı Yenile</div>
        <!--
        <a class="close-warning" @click="$store.state.openedVersion = false">
          <img src="https://gcdn.bionluk.com/site/icon/ic_close_white.svg" alt="">
        </a>
        -->
      </div>
    </div>
</template>

<script>

	export default {
	  name: "src-pages-version-v1",
		data() {
			return {}
		},

    methods: {
			forceRefresh() {
				location.reload();
      }
    },
  }

</script>

<style scoped lang="scss">
  .bi-icon-on /deep/ {
    width: 26px;
    height: 24px;

    path {
      fill:#00a575
    }
  }

  .version-warning{
    animation: opening 1s;
    color: #fff;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    bottom: 10px;
    justify-content: space-between;

    position: fixed;
    z-index: 9999999999;
    margin: 0 auto;


    width: 953px;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #019e70;
    background-color: #00a575;

    img{
      margin-right: 20px;
    }
  }

  .refresh-text {
    margin-right: 50px;
    width: 548px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
   text-align: left;
    color: #ffffff;

    span {
      font-weight: bold;

    }
  }

  .refresh-button {
    cursor: pointer;




    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 3px;
    margin-right: 50px;



    font-size: 16px;
    font-weight: 600;

    line-height: normal;


    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;


    width: 188px;
    height: 49px;
    border-radius: 2px;
    border: solid 1px rgb(255, 255, 255);
    &:hover{
     opacity: 0.8;
    }
  }
  @keyframes opening {
    from {
      bottom: -100px;
    }
    to {
      bottom: 10px;
    }
  }
  .close-warning{
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }

</style>
