<template>
  <div>
    <transition name="modal" style="width: 400px;">
      <div class="modal-mask"  style="overflow-y: scroll; " @click="hide()">
        <div class="modal-container" style="margin-top:100px; background: #f4f5f7; width: 700px !important;overflow: scroll;" @click.stop>
          <div style="float: right; padding-right: 10px;">
            <img @click="hide()" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div>
            <div class="hiddendiv"></div>

            <div style="width: 100%;margin: 20px auto; text-align: center">
              <img style="width: 110px;" src="https://gcdn.bionluk.com/site/cicons/ilBestFreelancers%402x.png"/>
            </div>
            <p class="main-text" style="width: 450px;"> Karar vermekte zorlanıyorsan, editörlerimizin seçtiği ilanlara mutlaka göz atmalısın. </p>
            <p class="main-text" style="font-size: 16px;">
             Bu alanda gösterilen ilanlar editörlerimiz tarafından tek tek elle seçilmektedir. Bu alandaki freelancerlar genellikle hiç uyarı almamış, site dışı iletişim bilgisi paylaşmamış, önceki siparişlerinde hiç sorun yaşamamış, müşteri memnuniyet oranı çok yüksek olan freelancer'lardır.
            </p>

            <div class="hiddendiv"></div>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
  export default {
    name: "src-pages-modal-bicoinFaqModal",
    data() {
      return {

      }
    },
    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },


    },
    computed: {},
  }
</script>

<style scoped lang="scss">

.main-text{
  font-size: 18px;
  line-height: 1.67;

  color: #5e6b79;
  width: 600px;
  margin: 20px auto;
  text-align: center;
}


  .modal-container{
    padding-bottom: 0;
  }
</style>
