<template>
  <div style="background-color: #fff" @click="onPageClick">
    <div v-if="!selectedCategory.parent_id" style=" background: white; height: auto;  padding: 0 0 30px;width: 100%; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);">
      <div class="category-title">
        <div v-if="categoryName">
          <h1 style="font-size: 34px;font-weight: 800;color: #2d3640; margin-bottom: 10px;">
            {{ categoryName }}
          </h1>
          <p style="font-size: 16px; line-height:1.63; margin-top:15px;color: #5e6b79">{{selectedCategory.description}}</p>
          <div v-if="!selectedCategory.parent_id" :style="'display:flex; align-items:center; justify-content: space-between; margin-top:30px; border-radius: 10px;height: 103px; width: 1193px; background-image: url(https://gcdn.bionluk.com/site/cicons/catImg/wide_band/wide_cat_'+selectedCategory.id+'.png); background-repeat: no-repeat!important; background-size: cover!important;'">
            <div style="margin-left: 140px;">
              <p class="main-cat-title" :style="selectedCategory.id === 3  ? 'color:#2d3640' : 'color:white'">{{categoryName}}</p>
              <p class="main-cat-sub-title"  :style="selectedCategory.id === 3  ? 'color:#2d3640' : 'color:white'" >Bu kategoride hizmet sunan <span style="font-weight: bold;">{{ selectedCategory.user_count | number_format }} freelancer</span> var.</p>
            </div>
            <div v-if="selectedCategory.cat_users && selectedCategory.cat_users.length" style="display: flex; margin-right: 30px;">
              <div v-for="onlineUser in selectedCategory.cat_users ">
                <div>
                  <router-link
                    :to="'/'+onlineUser.username">
                    <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                  </router-link>
                </div>
              </div>
              <div v-if="(selectedCategory.user_count - 5) > 0" style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                +{{selectedCategory.user_count - 5 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="category-sub-title"  :style="'background-color:'+headerInfo.bgColor">
        <div v-if="categoryName" class="category-sub-title-inside">
          <div style="width: 900px;">
            <h1  class="cat-title" :style="selectedCategory.parent_id === 3  ? 'color:#2d3640' : 'color:white'">
              <span @click="resetAllSettings('getDeeds')" style="cursor: pointer">{{ categoryName }}</span>
            </h1>
            <p class="cat-description" :style="selectedCategory.parent_id === 3  ? 'color:#2d3640' : 'color:white'" v-html="catDescription"/>
          </div>
          <div style="margin-left: 10px">
            <div v-if="showHelpBubble" style="position: relative">
              <div class="helpbubble">
                <div class="help-title"><p>Çevrimiçi freelancerlar</p> <p class="new">Yeni</p></div>
                <div style="font-size: 14px; line-height: 1.44; margin-left: 15px; margin-top: 2px">Sadece çevrimiçi olan freelancerların ilanlarını görüntüleyin.</div>
                <div style="display: flex; justify-content: flex-end; margin-right: 15px; font-size: 16px; font-weight: 600;"><p class="gotit" @click="gotIt('help')">Anladım</p></div>
              </div>
            </div>

            <p v-if="selectedCategory.online_count" class="online-text" :style="selectedCategory.parent_id === 3  ? 'color:#2d3640' : 'color:white'">Şu an <span style="font-weight: 600">{{ selectedCategory.online_count }} freelancer</span> çevrimiçi</p>
            <div v-if="headerInfo.cat_users" style="display: flex; width: 100%; margin-left: 15px;">
              <div v-for="onlineUser in headerInfo.cat_users ">
                <div>
                  <router-link
                    :to="'/'+onlineUser.username">
                    <img :alt="onlineUser.username" style="background:white;border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                  </router-link>
                </div>
              </div>
              <div v-if="(selectedCategory.online_count - 5) > 0" style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                +{{selectedCategory.online_count - 5 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="selectedCategory && selectedCategory.parent_id">
      <div style="z-index:99;width: 100%;" :class="{catFilterFixed: selectedCategory.parent_id && $store.state.windowScrollTop > 288 }">
        <div style="z-index:1;display: flex; align-items:center;margin: 0 auto;" :class="{catFilter: true}">
          <div class="catFilter-container">

            <div style="width: 100%; background-color: #ffffff; display: flex; align-items: center;justify-content: space-between;">
              <div style="display: flex;height: 100%; align-items: center;">

                <div v-if="categoryTypes && categoryTypes.length > 0" class="filter-box">
                  <div :class="selectedType || selectedType ? 'the-box active' : 'the-box'" @click="showFilterBox('types')">
                    Hizmet Türü
                    <div class="icon-container">
                      <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                    </div>
                  </div>
                  <div v-if="showTypesFilter" id="types-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('types')">
                    <div class="filter-options-container"  style="margin-top: 20px;">
                      <div @click="removeFilter('type')" :class="selectedType ? 'filter-category-item' : 'filter-category-item selected'" >
                        <p>Tüm Hizmet Türleri</p>
                        <p class="cunts">({{gigsCount}})</p>
                      </div>
                      <div @click="addTypeFilter(service_type)" v-for="service_type in categoryTypes.filter(({ count }) => count > 0)" :class="selectedType !== service_type.id ? 'filter-category-item' : 'filter-category-item selected'">
                        <p>{{service_type.name}}</p>
                        <p class="cunts">({{service_type.count}})</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div v-if="showAllServiceOptions" class="filter-box">
                  <div :class="computedSelectedAnyOptions ? 'the-box active' : 'the-box'" @click="showFilterBox('options')">
                    Hizmet Seçenekleri
                    <div class="icon-container">
                      <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                    </div>
                  </div>
                  <div v-if="showOptionsFilter" id="option-filter-box" class="filter-options"  v-click-away="() => handleFocusOut('options')">
                    <div class="long-options" v-if="reRender">

                      <template v-if="serviceMetaOptions.length > 0">
                        <div class="filter-options-container" v-for="(metaOption, moi) in serviceMetaOptions" :key="moi">
                          <p class="filter-options-container-title">{{metaOption.title_for_buyer}}</p>
                          <div style="padding: 25px 0 0; display: flex; flex-wrap: wrap; align-content: flex-start;">
                            <div v-if="moii < metaOption.sliceCount" @click="addMetaOptions(moi, moii)" v-for="(singleOption,moii) in metaOption.options"  class="filter-checkbox-item" >
                              <custom-check
                                :container-style="'width:20px; height:20px;'"
                                :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                                called-from=""
                                :single-gig="{}"
                                :id="singleOption.id + '__' + singleOption.title"
                                :checked="singleOption.selected"
                                :value="singleOption.selected"
                                @input="addMetaOptions(moi, moii)"
                              />

                              <div :class="singleOption.selected ? 'filter-checkbox-label-active' : 'filter-checkbox-label'">{{singleOption.title_for_buyer}}</div>
                              <div v-if="singleOption.count" style="font-weight: 500; font-size: 14px; color: #b8bdc5; margin-left: 5px; margin-right: 10px;">({{singleOption.count}})</div>
                            </div>
                          </div>
                          <div v-if="metaOption.options.length > 4" style="margin-bottom: 30px; margin-top: 8px;">
                            <p v-if="metaOption.options.length > 4 && metaOption.sliceCount === 4" @click="metaOption.sliceCount = 999; reRender = false; reRender = true;" class="show-more">+{{metaOption.options.length - 4}} daha</p>
                            <p v-if="metaOption.options.length > 4 && metaOption.sliceCount !== 4" @click="metaOption.sliceCount = 4; reRender = false; reRender = true;" class="show-more">Daha az göster</p>
                          </div>
                        </div>
                      </template>
                      <template v-if="this.serviceIncludes.length > 0">
                        <div class="filter-options-container">
                          <p class="filter-options-container-title">Dahil Olan Hizmetler</p>
                          <div style="padding: 25px 0 0;display: flex;  flex-wrap: wrap;align-content: flex-start;">
                            <div @click="addOptions(service)" v-for="service in computedOptions.slice(0, optionsSliceCount)"  class="filter-checkbox-item" >
                              <custom-check
                                :container-style="'width:20px; height:20px;'"
                                :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                                called-from=""
                                :single-gig="{}"
                                :id="service.id + '__' + service.title"
                                :checked="service.selected"
                                :value="service.selected"
                                @input="addOptions(service)"
                              />

                              <div :class="service.selected ? 'filter-checkbox-label-active' : 'filter-checkbox-label'">{{service.title}}</div>
                              <div v-if="service.count" style="font-weight: 500; font-size: 14px; color: #b8bdc5; margin-left: 5px; margin-right: 10px;">({{service.count}})</div>
                            </div>
                          </div>
                          <div style="margin-top: 8px;">
                            <p v-if="computedOptions.length > 6 && optionsSliceCount === 6" @click="optionsSliceCount = 999" class="show-more">+{{computedOptions.length - 6}} daha</p>
                            <p v-if="computedOptions.length > 6 && optionsSliceCount !== 6" @click="optionsSliceCount = 6" class="show-more">Daha az göster</p>
                          </div>
                        </div>
                      </template>

                    </div>
                    <div style="border-top:1px solid #e0e1e1;">
                      <div class="filter-options-container" style="margin-top:10px;display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                        <p @click="removeFilter('option')" class="temizle">Hepsini Temizle</p>
                        <p @click="addOptionFilter" class="super-button uygula">Uygula</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="filter-box">
                  <div :class="minBudget || maxBudget ? 'the-box active' : 'the-box'" @click="showFilterBox('budget')">
                    Bütçe
                    <div class="icon-container">
                      <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                    </div>
                  </div>
                  <div v-if="showBudgetFilter" id="budget-filter-box" class="filter-options"  v-click-away="() =>  handleFocusOut('budget')" >
                    <div class="filter-options-container" style="margin-bottom: 40px;">
                      <div style="display: flex; margin-top: 20px">
                        <div>
                          <p style="margin-bottom: 10px; font-size: 14px; font-weight: 500;">Min. Fiyat</p>
                          <input autocomplete="off" v-model="minBudgetModel"  id="input_one"  placeholder="hepsi (TL)" style="width: 95px;"   >
                        </div>
                        <div style="margin-left: 25px;">
                          <p style="margin-bottom: 10px; font-size: 14px; font-weight: 500;">Maks. Fiyat</p>
                          <input  autocomplete="off" v-model="maxBudgetModel"  id="input_two" placeholder="hepsi (TL)" style="width: 95px; " >
                        </div>
                      </div>
                    </div>
                    <div style="border-top:1px solid #e0e1e1;">
                      <div  class="filter-options-container" style="display: flex; align-items: center; justify-content: space-between; margin-top: 10px; margin-bottom: 10px;">
                        <p @click="removeFilter('budget')" class="temizle">Temizle</p>
                        <p @click="addBudgetFilter" class="super-button uygula">Uygula</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-box">
                  <div :class="selectedDelivery ? 'the-box active' : 'the-box'" @click="showFilterBox('delivery')">
                    Teslim Süresi
                    <div class="icon-container">
                      <img class="icon" src="https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg" onload="SVGInject(this)"/>
                    </div>
                  </div>
                  <div v-if="showDeliveryFilter" id="delivery-filter-box" class="filter-options"  tabindex="0" @focusout="handleFocusOut('delivery')">
                    <div class="filter-options-container"  style="margin-top: 20px;">
                      <div @click="changeDeliveryFilter(1)" class="filter-radio-item">
                        <div :class="selectedDelivery !== 1 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                        <p :class="selectedDelivery !== 1 ? 'filter-radio-label' : 'filter-radio-label-active' ">24 saat içinde</p>
                      </div>
                      <div @click="changeDeliveryFilter(3)" class="filter-radio-item">
                        <div :class="selectedDelivery !== 3 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                        <p :class="selectedDelivery !== 3 ? 'filter-radio-label' : 'filter-radio-label-active' ">3 gün içinde</p>
                      </div>
                      <div @click="changeDeliveryFilter(7)" class="filter-radio-item">
                        <div :class="selectedDelivery !== 7 ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                        <p :class="selectedDelivery !== 7 ? 'filter-radio-label' : 'filter-radio-label-active' ">7 gün içinde</p>
                      </div>
                      <div @click="changeDeliveryFilter(null)" class="filter-radio-item">
                        <div :class="selectedDelivery ? 'filter-radio-empty' : 'filter-radio-full'"></div>
                        <p :class="selectedDelivery ? 'filter-radio-label' : 'filter-radio-label-active' ">Herhangi bir zaman</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div style="display: flex; font-size: 16px;  color: #2d3640; align-items: center">
                Çevrimiçi freelancer’ların ilanlarını göster


                <custom-switch style="margin-left: 10px;"  v-model="showOnlineOnly" />

              </div>

            </div>
          </div>
        </div>
      </div>
    </template>
    <div style="background-color: #f4f5f7; padding-bottom: 50px; min-width: 1170px;">
      <div class="gigContainer">
        <div v-if="!selectedCategory.parent_id" style="display: flex; flex-direction: column;">
          <div class="category-select-container">
            <p v-for="(category, index) in categorySelect" :key="index" class="categories-name" :style="returnCategoryStyle(category)" @click="selectedCategorySlug = category.slug">
              <router-link
                v-if="category.parentId"
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'category side bar')"
                @click.native="handleGlobalClick($store.state.previousComponentPage,'category side bar')"
                :to="category.slug" @click.stop.prevent="preventClicking($event)" :style="returnCategoryStyle(category)">{{ category.name }}
              </router-link>
              <span v-if="category.is_new" class="is-new"><span class="is-new-t">Yeni</span></span>
            </p>
          </div>
        </div>
        <div v-if="selectedCategory && selectedCategory.parent_id" style="display: flex; flex-direction: column;">
          <div>
            <div v-show="showListOnly && reRender && filterLabels && filterLabels.length > 0" class="filter-labels">
              <div v-for="filterLabel in filterLabels" class="filter-label">
                {{filterLabel.title}}
                <p  @click="removeFilter(filterLabel)" class="close-icon-container">
                  <img class="close-icon" src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)"/>
                </p>
              </div>

            </div>
            <div v-show="showListOnly && gigs.length" style="display: flex; align-items: flex-end; justify-content: space-between">
              <div v-if="!showOnlineOnly">
                <p v-if="allGigsCount" style="font-weight: 500">{{allGigsCount | number_format}} İş ilanı bulundu</p>
              </div>
              <div v-else style=" margin-bottom: 20px">
                <p class="cat-story-title" style="margin-top:20px;">Çevrimiçi Freelancer’ların <span>ilanları</span> 👁  </p>
                <p class="cat-story-sub-title">İşim acil diyorsan, şu an Bionluk’ta çevrimiçi olan freelancer’ların ilanlarına göz atabilirsin.</p>

              </div>
              <div v-if="!showOnlineOnly" class="select-type-container">
                <p style="color: #2D3640; font-weight: 300; font-size: 16px;">Sıralama</p>

                <select @change="orderChange" v-model="orderBy" class="super-drop-tek-cizgi-cats">
                  <option :value="1">Tavsiye Edilen</option>
                  <option :value="2">Çok Satanlar</option>
                  <option :value="3">Yeni Gelenler</option>
                </select>
              </div>
            </div>
            <div v-if="!pageLoaded" style="margin-left: auto; margin-right: auto;">
              <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                <div v-for="x in 15" class="card-container">
                  <div class="card-image-container card">
                  </div>
                  <div class="card-detail">
                    <div class="card-detail-container">
                      <div class="card-detail-header-large card">

                      </div>
                      <div class="card-detail-header-small card">

                      </div>
                    </div>
                    <div class="card-detail-user-container ">
                      <div class="card-detail-user">
                        <div class="card-detail-user-image card"></div>
                        <div class="card-detail-user-name card"></div>
                      </div>
                      <div class="card-detail-currency card">
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div class="card-detail-seperator card">

                  </div>
                  <div class="card-like-currency">
                    <div class="card-like-icon card"></div>
                    <div class="card-currency-icon card"></div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="!showListOnly && filterLabels.length === 0">
              <div v-if="selectedCategory.id == 197" style=" display: flex; justify-content: space-between; margin-top: 20px; margin-bottom: 60px;">
                <div>
                  <img @click="addManualMetaOption(0,0)" style="width: 570px; height: 249px; cursor: pointer" src="https://gcdn.bionluk.com/uploads/message/5d8b2baf-1364-43ce-82f2-5a000e41f61b.png"/>
                </div>
                <div>
                  <img  @click="addManualMetaOption(0,1)" style="width: 570px; height: 249px; cursor: pointer" src="https://gcdn.bionluk.com/uploads/message/2fba0616-4f47-4fbb-ba11-b9ce80d2c5bc.png"/>
                </div>

              </div>

              <div v-if="softwareCategoryIds.includes(selectedCategory.id)" style="margin: 40px auto;">

                <p class="cat-story-title" style="margin-top:20px;">Parlayan Yıldızlar<span> 🕺💫</span></p>
                <div style=" display: flex" class="cat-story-sub-title">
                  <p>{{ selectedCategory.name }} kategorisinin en parlak freelancerları !</p>
                  <div style="position: relative; margin-left: 5px;">
                    <span @click.stop.prevent="preventClick" @click="showShiningStarsDescription = !showShiningStarsDescription"> [Neye göre listeleniyor?]</span>
                    <div v-if="showShiningStarsDescription" @click="showShiningStarsDescription = false" class="tooltiptext" style="visibility: visible;">
                      <helpBox style="width: 358px" :description="shiningStarsDescription"></helpBox>
                    </div>
                  </div>


                </div>

                <div v-if="!shiningStarsLoaded" style="margin-left: auto; margin-right: auto;">

                  <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                    <div v-for="x in 4" class="card-container">
                      <div class="card-image-container card">
                      </div>
                      <div class="card-detail">
                        <div class="card-detail-container">
                          <div class="card-detail-header-large card">

                          </div>
                          <div class="card-detail-header-small card">

                          </div>
                        </div>
                        <div class="card-detail-user-container ">
                          <div class="card-detail-user">
                            <div class="card-detail-user-image card"></div>
                            <div class="card-detail-user-name card"></div>
                          </div>
                          <div class="card-detail-currency card">
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="card-detail-seperator card">

                      </div>
                      <div class="card-like-currency">
                        <div class="card-like-icon card"></div>
                        <div class="card-currency-icon card"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cardBoxContainer" style="margin-top: 40px;">
                  <div class="cardBoxFlexC" v-for="freelancer in shiningStars" :key="freelancer.username">
                    <div class="cardBox">
                      <div
                        :data-balloon="freelancer.online_status ? 'Çevrimiçi' : freelancer.last_seen | lastSeen() + ' önce görüldü.'"
                        data-balloon-pos="left"
                        class="cardOnline" :style="freelancer.online_status ? ' background-color: #5beb17;': ' background-color: #bfc8d2;'"></div>
                      <div class="rainDropcontainer">
                        <div class="firstLayer"></div>
                        <div class="secondLayer"></div>
                        <div class="thirdLayer"></div>
                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'shiningFreelancersCat')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'shiningFreelancersCat')"
                          :to="'/'+freelancer.username">
                          <img :src="freelancer.avatar" class="freelancerAvatar">
                        </router-link>
                      </div>
                      <p class="username">{{freelancer.username}}</p>
                      <p class="title">{{freelancer.title}}</p>
                      <div class="rating">
                        <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                        <div v-if="freelancer.rating && freelancer.rating.commentCount">
                          <span class="rate">{{freelancer.rating.commentRating === 5 ? '5.0' : freelancer.rating.commentRating }}</span>
                          <span class="count"> ({{freelancer.rating.commentCount}})</span>
                        </div>
                      </div>
                      <div class="reText">
                        <template v-if="freelancer.recurring_orders">
                          <img class="reTextIcon" style="width: 14px; height: 14px" src="https://gcdn.bionluk.com/site/cicons/ic-change-path.svg" onload="SVGInject(this)">
                          <p><span>{{freelancer.recurring_orders}}</span> Tekrarlanan Sipariş</p>
                        </template>

                      </div>
                      <router-link
                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'shiningFreelancersCat')"
                        @click.native="handleGlobalClick($store.state.previousComponentPage,'shiningFreelancersCat')"
                        :to="'/'+freelancer.username">
                        <button class="profileButton" style="margin-top: 28px;">Profile Git</button>
                      </router-link>
                    </div>

                  </div>
                </div>
              </div>

              <div v-if="!featuredGigsLoaded" style="margin-left: auto; margin-right: auto;">

                <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                  <div v-for="x in 4" class="card-container">
                    <div class="card-image-container card">
                    </div>
                    <div class="card-detail">
                      <div class="card-detail-container">
                        <div class="card-detail-header-large card">

                        </div>
                        <div class="card-detail-header-small card">

                        </div>
                      </div>
                      <div class="card-detail-user-container ">
                        <div class="card-detail-user">
                          <div class="card-detail-user-image card"></div>
                          <div class="card-detail-user-name card"></div>
                        </div>
                        <div class="card-detail-currency card">
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div class="card-detail-seperator card">

                    </div>
                    <div class="card-like-currency">
                      <div class="card-like-icon card"></div>
                      <div class="card-currency-icon card"></div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="featuredGigsLoaded && featuredGigs.length">
                <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-right: 7px;">
                  <div>
                    <p class="cat-story-title" style="margin-top:20px;">Öne çıkan <span>ilanlar 🚀 </span></p>
                    <div class="cat-story-sub-title" style=" display: flex" >{{selectedCategory.name}} ilanları arasında öne çıkanlar. <div style="position: relative;">
                      <div @click.stop.prevent="preventClick" @click="showBicoinDescription = !showBicoinDescription" class="question-mark-wrapper" style="margin-left: 5px; margin-top: 1px; opacity: 0.8">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
                      </div>

                      <div v-if="showBicoinDescription" @click="showBicoinDescription = false" class="tooltiptext" style="visibility: visible">
                        <helpBox style="width: 358px" :description="bicoinDescription"></helpBox>
                      </div>
                    </div>

                    </div>


                  </div>
                  <div v-if="user.seller && user.seller.isSeller && user.info && !user.info.clicked_bicoin" class="top-right-box" @click="clicked('bicoin')" style=" cursor: pointer; width: 268px;height: 74px;">
                    <div>
                      <img style="width: 46px; height: 46px;" src="https://gcdn.bionluk.com/site/cicons/bicoin.svg">
                    </div>
                    <div style="display: flex; flex-direction: column; margin-left: 10px;">
                      <p style="color:#2d3640; font-size: 16px; font-weight: bold; margin-top: 5px;">İlanını öne çıkar 🚀</p>
                      <p style="color:#2d3640; font-size: 10px;line-height: 1.4; margin-top: 5px; margin-bottom: 5px;">Öncelik, Zaman ve Görünürlük Kazan</p>
                    </div>
                  </div>
                  <div v-else-if="!showOnlineOnly" class="select-type-container">
                    <p style="color: #2D3640; font-weight: 300; font-size: 16px;">Sıralama</p>

                    <select @change="orderChange" v-model="orderBy" class="super-drop-tek-cizgi-cats">
                      <option :value="1">Tavsiye Edilen</option>
                      <option :value="2">Çok Satanlar</option>
                      <option :value="3">Yeni Gelenler</option>
                    </select>
                  </div>
                </div>



                <div class="gig-list-container" style="margin-top: 40px;">
                  <single-gig :impression-u-u-i-d="impressionUUIDFeatured" :item="gig" v-for="(gig, ggiindex) in featuredGigs.slice(0,featuredGigsShownCount)" :key="ggiindex" class="gig-list-gig"></single-gig>
                  <div style="width: 200px; margin-left: auto; margin-right: auto;" @click="showAllFeaturedGigs" class="see-more-c" v-if="featuredGigsShownCount === 4 && featuredGigs.length > 4">
                    <p class="plus">+</p>
                    <p class="text">Daha fazla göster</p>
                  </div>
                </div>
              </template>

              <div v-if="specialTags.options.length > 0" style="margin-left: auto; margin-right: auto;margin-top: 40px; ">
                <p class="cat-story-title" style="margin-top:20px;">Nasıl bir <span>{{ specialTags.title }}</span> ihtiyacın var?</p>
                <p class="cat-story-sub-title">{{ specialTags.description}}</p>

                <div class="tags">
                  <p v-for="(tag, tagIndex) in specialTags.options" @click="selectTag(tagIndex)" :key="tagIndex+specialTags.options[selectedTagIndex]" :class="tagIndex === selectedTagIndex ? 'tag-selected' : 'tag'" >{{tag.title}}</p>
                </div>

                <div v-if="!specialGigsLoaded" style="margin-left: auto; margin-right: auto;">

                  <div style="height:419px;display: flex;flex-wrap: wrap; margin-top: 30px">
                    <div v-for="x in 4" class="card-container">
                      <div class="card-image-container card">
                      </div>
                      <div class="card-detail">
                        <div class="card-detail-container">
                          <div class="card-detail-header-large card">

                          </div>
                          <div class="card-detail-header-small card">

                          </div>
                        </div>
                        <div class="card-detail-user-container ">
                          <div class="card-detail-user">
                            <div class="card-detail-user-image card"></div>
                            <div class="card-detail-user-name card"></div>
                          </div>
                          <div class="card-detail-currency card">
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="card-detail-seperator card">

                      </div>
                      <div class="card-like-currency">
                        <div class="card-like-icon card"></div>
                        <div class="card-currency-icon card"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="gig-list-container" style="margin-top: 30px;">
                  <single-gig :impression-u-u-i-d="impressionUUIDSpecial"  :item="gig" v-for="(gig, ggiindex) in specialGigs" :key="ggiindex" class="gig-list-gig"></single-gig>
                  <div v-if="specialTags.showSeeMore" @click="specialSeeMore" style="width: 200px; margin-left: auto; margin-right: auto;"  class="see-more-c">
                    <p class="plus">+</p>
                    <p class="text">Tümünü Göster</p>
                  </div>
                </div>
              </div>

              <div style="margin-left: auto; margin-right: auto;margin-top: 40px; ">

                <p class="cat-story-title" style="margin-top:20px;"> En sevilen <span>freelancerlar ☕️</span></p>
                <div style=" display: flex" class="cat-story-sub-title">
                  <p>{{ selectedCategory.name }} kategorisinin en sevilen freelancerları.</p>
                  <div style="position: relative; margin-left: 5px;">
                    <span @click.stop.prevent="preventClick" @click="showLovedFreelancerDescription = !showLovedFreelancerDescription"> [Neye göre listeleniyor?]</span>
                    <div v-if="showLovedFreelancerDescription" @click="showLovedFreelancerDescription = false" class="tooltiptext" style="visibility: visible;">
                      <helpBox style="width: 358px" :description="lovedFreelancerDescription"></helpBox>
                    </div>
                  </div>


                </div>

                <div v-if="!lovedFreelancersCatLoaded" style="margin-left: auto; margin-right: auto;">

                  <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                    <div v-for="x in 4" class="card-container">
                      <div class="card-image-container card">
                      </div>
                      <div class="card-detail">
                        <div class="card-detail-container">
                          <div class="card-detail-header-large card">

                          </div>
                          <div class="card-detail-header-small card">

                          </div>
                        </div>
                        <div class="card-detail-user-container ">
                          <div class="card-detail-user">
                            <div class="card-detail-user-image card"></div>
                            <div class="card-detail-user-name card"></div>
                          </div>
                          <div class="card-detail-currency card">
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="card-detail-seperator card">

                      </div>
                      <div class="card-like-currency">
                        <div class="card-like-icon card"></div>
                        <div class="card-currency-icon card"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cardBoxContainer" style="margin-top: 40px;">
                  <div class="cardBoxFlexC" v-for="freelancer in lovedFreelancersCat" :key="freelancer.username">
                    <div class="cardBox">
                      <div
                        :data-balloon="freelancer.online_status ? 'Çevrimiçi' : freelancer.last_seen | lastSeen() + ' önce görüldü.'"
                        data-balloon-pos="left"
                        class="cardOnline" :style="freelancer.online_status ? ' background-color: #5beb17;': ' background-color: #bfc8d2;'"></div>
                      <div class="rainDropcontainer">
                        <div class="firstLayer"></div>
                        <div class="secondLayer"></div>
                        <div class="thirdLayer"></div>
                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'lovedFreelancersCat')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'lovedFreelancersCat')"
                          :to="'/'+freelancer.username">
                          <img :src="freelancer.avatar" class="freelancerAvatar">
                        </router-link>
                      </div>
                      <p class="username">{{freelancer.username}}</p>
                      <p class="title">{{freelancer.title}}</p>
                      <div class="rating">
                        <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                        <div v-if="freelancer.rating && freelancer.rating.commentCount">
                          <span class="rate">{{freelancer.rating.commentRating === 5 ? '5.0' : freelancer.rating.commentRating }}</span>
                          <span class="count"> ({{freelancer.rating.commentCount}})</span>
                        </div>
                      </div>
                      <div class="reText">
                        <template v-if="freelancer.recurring_orders">
                          <img class="reTextIcon" style="width: 14px; height: 14px" src="https://gcdn.bionluk.com/site/cicons/ic-change-path.svg" onload="SVGInject(this)">
                          <p><span>{{freelancer.recurring_orders}}</span> Tekrarlanan Sipariş</p>
                        </template>

                      </div>
                      <router-link
                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'lovedFreelancersCat')"
                        @click.native="handleGlobalClick($store.state.previousComponentPage,'lovedFreelancersCat')"
                        :to="'/'+freelancer.username">
                        <button class="profileButton" style="margin-top: 28px;">Profile Git</button>
                      </router-link>
                    </div>

                  </div>
                </div>
              </div>
              <!--
                <div v-if="1===2" style="margin-left: auto; margin-right: auto;margin-top: 70px; ">
                  <p class="cat-story-title" style="margin-top:20px;">Editörlerimizin seçtiği <span>ilanlar 👌️</span></p>
                  <p class="cat-story-sub-title">Karar vermekte zorlananlar için, editörlerimizin seçtiği ilanlara bakabilirsin. <span @click="openEditorGigsModal">[Neye göre seçiliyorlar?]</span></p>
                  <div v-if="!lovedFreelancersCatLoaded" style="margin-left: auto; margin-right: auto;">
                    <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                      <div v-for="x in 4" class="card-container">
                        <div class="card-image-container card">
                        </div>
                        <div class="card-detail">
                          <div class="card-detail-container">
                            <div class="card-detail-header-large card">

                            </div>
                            <div class="card-detail-header-small card">

                            </div>
                          </div>
                          <div class="card-detail-user-container ">
                            <div class="card-detail-user">
                              <div class="card-detail-user-image card"></div>
                              <div class="card-detail-user-name card"></div>
                            </div>
                            <div class="card-detail-currency card">
                            </div>
                          </div>
                          <div>
                          </div>
                        </div>
                        <div class="card-detail-seperator card">

                        </div>
                        <div class="card-like-currency">
                          <div class="card-like-icon card"></div>
                          <div class="card-currency-icon card"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gig-list-container" style="margin-top: 40px;">
                    <single-gig :box-size="'big'" :impression-u-u-i-d="impressionUUIDEditorGigs"  :item="gig" v-for="(gig, ggiindex) in editorGigs" :key="ggiindex" class="gig-list-gig"></single-gig>

                  </div>
                </div>
               -->
              <div class="go-request" v-if="pageLoaded">
                <div class="left">
                  <p class="title">Hayalindeki <span>"{{selectedCategory.name}}"</span> hizmeti için aradığın ilanı veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>
                  <button @click="$router.push('/panel/istekler')" class="cuper-red-button" style="margin-top: 20px">Alıcı İsteği Oluştur</button>
                </div>
                <img class="right" src="https://gcdn.bionluk.com/site/cicons/il-special-requests.svg">
              </div>

              <div v-if="!portfolioItemsLoaded" style="margin-left: auto; margin-right: auto;margin-top: 70px;">

                <div style="display: flex;flex-wrap: wrap; margin-top: 30px">
                  <div v-for="x in 4" class="card-container">
                    <div class="card-image-container card">
                    </div>
                    <div class="card-detail">
                      <div class="card-detail-container">
                        <div class="card-detail-header-large card">

                        </div>
                        <div class="card-detail-header-small card">

                        </div>
                      </div>
                      <div class="card-detail-user-container ">
                        <div class="card-detail-user">
                          <div class="card-detail-user-image card"></div>
                          <div class="card-detail-user-name card"></div>
                        </div>
                        <div class="card-detail-currency card">
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div class="card-detail-seperator card">

                    </div>
                    <div class="card-like-currency">
                      <div class="card-like-icon card"></div>
                      <div class="card-currency-icon card"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="portfolioItemsLoaded && portfolioItems.length" style="margin-top: 70px;">
                <p class="cat-story-title">Bionluk'ta <span>Yapıldı 🧙‍♀️</span></p>
                <p class="cat-story-sub-title">Yetenekli Bionluk Freelancer’larının <strong style="font-weight: 600">{{ categoryName }}</strong> kategorisinde yaptığı işler</p>

                <div style="margin-left: -27px; margin-top:40px;">
                  <div class="cardBoxContainer">
                    <div v-for="portfolio in portfolioItems" :key="portfolio.uuid" class="portfolio-box">
                      <div class="head-section">
                        <router-link
                          :to="'/'+portfolio.username">
                          <img class="avatar" :src="portfolio.avatar_url">
                        </router-link>
                        <div style=" margin-left: 6px; display: flex; height:40px; flex-direction: column; justify-items: center">
                          <p class="title">
                            <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" @click="showPortfolio(portfolio.uuid)">
                              {{portfolio.name}}
                            </a>
                          </p>
                          <p class="sub-title" v-if="portfolio.rating_user.id">{{portfolio.username}}, <router-link
                            :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
                          <p v-else class="sub-title">{{portfolio.category_name}}</p>
                        </div>
                      </div>
                      <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" style="position: relative; display: block" class="portfolio-img" @click="showPortfolio(portfolio.uuid)">
                        <img :src="portfolio.media.image_url">
                        <div v-show="portfolio.media.file_type === 'audio'" class="sound-icon-container">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
                        </div>
                        <div v-show="portfolio.media.file_type === 'video'" class="sound-icon-container">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
                        </div>
                      </a>
                      <div class="foot-section">
                        <div style="display: flex">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
                          <span>{{portfolio.fav_count}}</span>
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
                          <span>{{portfolio.comment_count}}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

               <p class="cat-story-title" style="margin-top: 60px;">Diğer  <span>tüm ilanlar</span></p>
               <p class="cat-story-sub-title">{{ selectedCategory.name }} kategorisinde yer alan tüm ilanlara buradan göz atabilirsin.</p>


              <div class="gig-list-container" style="margin-top:40px">
                <single-gig :impression-u-u-i-d="impressionUUID" :clicked-from="'default'" :type="'fromList'" :filters="computedAllFilters" :item="gig" v-for="(gig, gin) in gigs.slice(0,8)" :key="gin" class="gig-list-gig"></single-gig>
              </div>

              <div @click="showAllGigs" class="see-more-c">
                <p class="plus">+</p>
                <p class="text">Tümünü Göster</p>
              </div>

            </template>

            <template v-else>
              <div v-if="pageLoaded" >



                <div class="gig-list-container">
                  <single-gig :impression-u-u-i-d="mixedImpressionUUID(gig)" :clicked-from="'default'" :type="'fromList'" :filters="computedAllFilters" :item="gig" v-for="(gig, gin) in gigs" :key="gin" class="gig-list-gig"></single-gig>

                  <div v-if="!gigs.length && pageLoaded" style="width: 1170px; margin: 0 auto; text-align: center; padding-bottom: 100px;">
                    <div class="notodoitems content-white" style=" margin-top:0!important;margin-left: 200px; width:770px;text-align: center;">
                      <p style="color: #2cad87; font-size: 15px; font-weight: 500; margin-bottom: 15px; cursor: pointer;" @click="resetAllSettings('getDeeds')">Tüm Filtreleri Kaldır</p>
                      <h4>Sonuç bulunamadı.</h4>
                      <div class="hiddendiv"></div>
                      <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
                        <img style="width: 75px; height: 75px;" src="https://gcdn.bionluk.com/site/general/no-foun-gig.png">
                      </span>
                      <p class="h1_2" style=" width: 400px; margin: 0 auto; line-height: 1.3; font-size:14px; font-weight:400!important; color: #5e6b79">
                     Hayalindeki <span>"{{selectedCategory.name}}"</span> hizmeti için aradığın ilanı veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!

                      <div class="hiddendiv"></div>

                      <router-link to="/panel/istekler/">
                        <button class="super-button">Alıcı İsteği Oluştur</button>
                      </router-link>

                      <div class="hiddendiv10"></div>
                    </div>
                  </div>

                </div>


              </div>


              <div class="pagination-bar">
                <paginate v-if="pageLoaded && gigs.length && totalCount > 1"
                          v-model="pageNumber"
                          :page-count="totalCount"
                          :page-range="5"
                          :margin-pages="1"
                          :click-handler="(pageNum) => clickPagination(pageNum)"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :containerClass="'pagination'">
                </paginate>
              </div>
              <div class="hiddendiv"></div>

              <div class="go-request" v-if="pageLoaded">
                <div class="left">
                  <p class="title">Hayalindeki <span>"{{selectedCategory.name}}"</span> hizmeti için aradığın ilanı veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>
                  <button @click="$router.push('/panel/istekler')" class="cuper-red-button" style="margin-top: 20px">Alıcı İsteği Oluştur</button>
                </div>
                <img class="right" src="https://gcdn.bionluk.com/site/cicons/il-special-requests.svg">
              </div>

            </template>
          </div>
        </div>

        <div v-else  style="display: flex; flex-direction: column; padding-left: 36px;">
          <div  style="margin-left: auto; margin-right: auto;">
            <div style="display: flex;flex-wrap: wrap">
              <template v-for="catImage in categorySelect.filter(cat => cat.parentId)"   >
                <div @mouseleave="catImage.hover = false" class="sub-image-container">
                  <div v-if="catImage.is_new" style="position:absolute; margin-top: 15px; margin-left: 15px" >
                    <span class="is-new"><span class="is-new-t">Yeni</span></span>
                  </div>
                  <div v-if="catImage.imageInfo.is_portfolio" style="position:absolute; width: 40px">
                    <img @mouseover="catImage.hover = true" :src="catImage.imageInfo.portfolio_info.seller_avatar_url" style="background:white;margin-left:224px; margin-top:10px;border:2px solid #bfc8d2; width: 34px; height: 34px; border-radius: 50%; cursor: help" />
                  </div>
                  <div v-if="catImage.imageInfo.is_gig" style="position:absolute; width: 40px">
                    <img @mouseover="catImage.hover = true" :src="catImage.imageInfo.portfolio_info.seller_avatar_url" style="background:white;margin-left:224px; margin-top:10px;border:2px solid #bfc8d2; width: 34px; height: 34px; border-radius: 50%; cursor: help" />
                  </div>
                  <div v-if="catImage.hover" @mouseleave="catImage.hover = false" class="sub-portfolio-hover">
                    <img :src="catImage.imageInfo.portfolio_info.seller_avatar_url" style="margin-left:16px;border:2px solid #bfc8d2; width: 34px; height: 34px; border-radius: 50%;" />
                    <div style="margin-left: 6px;">
                      <p class="portfolio-title" @click="showPortfolio(catImage.imageInfo.portfolio_info)">{{catImage.imageInfo.portfolio_info.title}}</p>
                      <p v-if="catImage.imageInfo.is_gig" class="portfolio-text" style="margin-top: 1px;">
                        <router-link :to="'/'+catImage.imageInfo.portfolio_info.seller_username" class="portfolio-link">{{ catImage.imageInfo.portfolio_info.seller_username }}</router-link>
                      </p>
                      <p v-else-if="catImage.imageInfo.portfolio_info.buyer_username" class="portfolio-text" style="margin-top: 1px;">
                        <router-link :to="'/'+catImage.imageInfo.portfolio_info.seller_username" class="portfolio-link">{{ catImage.imageInfo.portfolio_info.seller_username }}</router-link>, <router-link style="font-weight:400 " :to="'/'+catImage.imageInfo.portfolio_info.buyer_username">{{catImage.imageInfo.portfolio_info.buyer_username}}</router-link> için yaptı.
                      </p>
                      <p v-else class="portfolio-text" style="margin-top: 1px;">
                        <router-link :to="'/'+catImage.imageInfo.portfolio_info.seller_username" class="portfolio-link">{{ catImage.imageInfo.portfolio_info.seller_username }}</router-link>
                        yaptı.
                      </p>
                    </div>
                  </div>
                  <router-link :to="'/freelancer-bul/'+catImage.slug" style="height: 152px;">
                    <img :src="catImage.imageInfo.image_url" style="width: 270px; height: 152px;border-top-left-radius: 10px; border-top-right-radius: 10px;"/>
                  </router-link>
                  <div style="background: #eff3f8; padding: 15px; height: 100%;border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                    <router-link :to="'/freelancer-bul/'+catImage.slug" style="font-size: 18px; color: #2d3640; font-weight: bold;margin-bottom: 5px; display: block ">{{catImage.name}}</router-link>
                    <p style="color: #5e6b79; font-size: 14px; opacity: 0.7;line-height: 1.43; text-decoration: none!important;"><span style="font-weight: bold">{{catImage.userCount | number_format}}</span> Freelancer</p>
                    <p style="color: #5e6b79; font-size: 14px; opacity: 0.7;line-height: 1.43; text-decoration: none!important;"><span style="font-weight: bold">{{catImage.gigCount | number_format}}</span> Aktif İş İlanı</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>
      <div v-if="blogViews.length" style="width: 1170px; margin: 0 auto 60px;">
        <div style="display: flex; justify-content: space-between;">
          <p class="section-header-today" style="margin-bottom: 20px">Bi'<span>Blog</span> 📰</p>
          <router-link to="/blog" class="tumu">Tüm Yazılar</router-link>
        </div>
        <div style="display: flex; justify-content: space-between">
          <BlogView v-for="(blogView, index) in blogViews" :blog-view="blogView" :key="blogView.pathToGo + index" />
        </div>
      </div>
      <faqs v-if="faqs[selectedCategory.slug]"  :content-data="{ title: 'Sıkça Sorulan Sorular', accordionData: faqs[selectedCategory.slug] }" />
    </div>
  </div>
</template>

<script>
  import Paginate from 'vuejs-paginate'
  import singleGig from "@/pages/components/singleGig"
  import CustomSwitch from "@/pages/components/CustomSwitch"
  import helpBox from "../../../components/HelpBox"
  import CustomCheck from "@/pages/components/CustomCheck"
  import isEmpty from 'lodash/isEmpty'
  import sortBy from 'lodash/sortBy'
  import { bindCodeToHtml, generateCodeForFaq, generateCodeForParentCategory, generateCodeForChildCategory } from '@/utils/schema'
  import Accordion from "@/pages/components/Accordion";
  import Faqs from "@/pages/components/Faqs";
  import BlogView from "@/pages/components/BlogView";
  import Api from "@/utils/request"

  export default {
    name: "src-pages-body-marketplace-categories-v2",
    components: {
      BlogView,
      Faqs,
      Accordion,
      Paginate,
      singleGig,
      CustomSwitch,
      helpBox,
      CustomCheck
    },
    data() {
      return {
        blogViews: [],
        showOnlineOnly:null,
        featuredGigs:[],
        featuredGigsShownCount: 4,
        featuredGigsLoaded: false,
        portfolioItems:[],
        portfolioItemsLoaded: false,
        shiningStars: [],
        shiningStarsLoaded: false,
        lovedFreelancersCat:[],
        lovedFreelancersCatLoaded:false,
        editorGigs: [],
        showListOnly: true,
        showDeliveryFilter:false,
        selectedDelivery:null,
        deliveryLabels:[{
          id:1,
          title: '24 saatte teslim'
        },{
          id:3,
          title: '3 günde teslim'
        },{
          id:7,
          title: '7 günde teslim'
        }],
        showBudgetFilter:false,
        minBudget: null,
        maxBudget:null,
        minBudgetModel: null,
        maxBudgetModel:null,
        showOptionsFilter: false,
        selectedOptions:[],
        optionsSliceCount:6,
        selectedMetaOptions:[],
        showTypesFilter: false,
        selectedType:null,
        offset: 0,
        limit: 32,
        seemorediv: null,
        pageLoaded: false,
        totalCount: null,
        gigs: [],
        categorySelect: [],
        selectedCategorySlug: null,
        selectedCategory: { id:null, parent_id: 0 },
        slug: null,
        headerInfo: {},
        duration: null,
        orderBy: 1,
        categoryTypes: [],
        serviceIncludes: [],
        serviceMetaOptions: [],
        categoryName: null,
        categoryId:null,
        pageNumber: 1,
        gigsCount: 0,
        allGigsCount:0,
        reRender:true,
        impressionUUID:null,
        impressionUUIDFeatured:null,
        impressionUUIDSpecial:null,
        impressionUUIDEditorGigs:null,
        selectedTagIndex:0,
        specialGigs: [],
        specialGigsLoaded: false,
        isSpecialCategory:false,
        specialTags:{
          showSeeMore:false,
          gigs:[],
          title:'',
          description:'',
          options:[],
        },
        softwareCategoryIds: [205, 77, 125],
        showBicoinDescription:false,
        bicoinDescription:"<ul style='margin-top:5px;list-style:none'><li>Bionluk'ta freelancerlar hesaplarındaki bicoin'lerini kullanarak ilanlarını öne çıkarabilir. Öne çıkan ilanlar <span style='font-weight:bold'>24 saat boyunca, bu bölümde</span> sabit olarak gösterilir. Sıralama ortalama her 30 dakikada değişir. </li></ul>",
        showLovedFreelancerDescription:false,
        lovedFreelancerDescription:"<ul style='margin-top:5px;list-style:none'><li>Son 3 ayda farklı kullanıcılardan en fazla olumlu yorum almış, müşteri memnuniyet ve tekrarlanan sipariş oranı yüksek olan freelancer bu alanda gösterilir. Sıralama her 10 dakikada bir otomatik olarak güncellenir. </li></ul>",
        showHelpBubble:false,
        showShiningStarsDescription: false,
        shiningStarsDescription: 'Son 30 gün içerisindeki <b>onaylanmış</b> siparişlerinin toplamı 3 bin TL ve üzeri olan, bu siparişlerden aldığı puan ortalaması 4.5 ve üzeri olan freelancerlar bu alanda gösterilir. Sıralama ortalama saatte bir güncellenir.',
        faqs: {
          'yazi-ceviri': [
            {
              open: false,
              title: 'İçerik yazarı seçerken nelere dikkat etmeliyim?',
              text: 'Freelance içerik yazarı ile çalışmadan önce,  hizmetin türü ve kapsamını netleştirmelisin. Örneğin; web sitesi içeriği ile blog içeriği için ihtiyaç duyacağın hizmet farklı olabilir. Bu nedenle, hangi alanda hizete ihtiyacın  varsa, o alanda uzman içerik yazarı ile  çalışmalısı. İkinci önemli nokta ise SEO uyumlu içerik üretebilen yazarlar ile çalışman.'
            },
            {
              open: false,
              title: 'İçerik türleri  nelerdir?',
              text: 'Dijital pazarlama çalışmaların için ihtiyaç duyabileceğin en popüler içerik türleri:\n' +
                '\n' +
                'Web Sitesi İçeriği\n' +
                'Blog İçeriği \n' +
                'Ürün Tanıtım İçeriği\n' +
                'Ürün karşılaştırması içerikleri\n' +
                'Video senaryosu\n' +
                'Sosyal medya gönderi metni\n' +
                'Liste içerikleri\n' +
                'Marka sloganı & marka ismi\n' +
                'Kampanya sloganı\n' +
                'Reklam kampanya metinleri\n' +
                'Çeviri & Yerelleştirme\n' +
                'Yaratıcı senaryo'
            },
            {
              open: false,
              title: 'İçerik yazarı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Freelance içerik yazarı ile çalışırken en önemli adım net brief vermek. Bu hizmeti hangi platform için alıyorsun,  dikkat edilmesini istediğin  şeyler nedir, markanın  hedef kitlesi kimdir gibi  kilit soruların cevabını içerik yazarına vermekten çekinmemelisin. İçerikler tamamlandıktan  sonra içerik özgünlüğünü ve dilbilgisi kurallarına uygunluğunu da mutlaka kontrol etmelisin.'
            },
            {
              open: false,
              title: 'Web sitesi içeriği oluşturulurken nelere dikkat edilmeli?',
              text: 'Web sitesi içeriği oluşturulurken dikkat edilmesi gereken ana noktalar:\n' +
                '\n' +
                'İçeriklerin web sitesinin amacına yönelik hazırlanması (satış için farklı, ürün açıklamaları için farklı  içeriklerin derlenmesi)\n' +
                'Tüm web sitesi içeriğinin SEO uyumlu olması\n' +
                'Metinlerde ürün/hizmete link verilmesi\n' +
                'Kullanıcıların ihtiyaçlarının adım adım hazırlanarak her adıma uygun içerik sunulması\n' +
                'İçeriğin kolay anlaşılır ve çok uzun olmaması\n' +
                '\n'
            }
          ],
          'internet-reklamciligi': [
            {
              open: false,
              title: 'Dijital pazarlama nedir?',
              text: 'Ürün veya hizmetlerin, tamamen dijital kanallar aracılığıyla ve bu kanalların dinamiklerine uygun olarak gerçekleştiilen pazarlama yöntemlerinin bütününe deniyor. Dijital pazarlama kapsamında; içerik pazarlama, sosyal medya pazarlama, arama motoru optimizasyonu (SEO), sosyal medya reklamları, e-posta pazarlama  gibi birçok alt başlık yer alıyor.'
            },
            {
              open: false,
              title: 'Dijital pazarlamanın  faydaları nedir?',
              text: 'Markalar, dijital pazarlama sayesinde; bilinirlik artırma, satış yapma veya satış artırma, müşteri memnuniyeti sağlama gibi farklı birçok amaca hizmet eden çalışmalar yapabiliyorlar. Dijital  pazarlama aynı zamanda; daha net hedef kitle analizi yapabilmek, bütçeyi verimli kullanmak, müşteri davranışlarını analiz ederek pazarlama faaliyetlerinde / üründe geliştirme sağlamak gibi birçok avantaj sunuyor.'
            },
            {
              open: false,
              title: 'Sosyal medya  uzmanı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Sosyal medya uzmanı ile çalışırken; daha önce içinde yer aldığı çalışmaları incelemeli ve sana,hangi kapsamda ve seviyede, ne tür çözümler sunabileceğini anlatmasını istemelisin.'
            },
            {
              open: false,
              title: 'SEO danışmanı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Bir SEO Danışmanı ile çalışırken; varsa başarıya ulaştığı çalışmaları talep etmeli, oluşturduğu içerikleri kontrol etmeli ve raporlama konusunda nasıl davrandığını bilmelisin.'
            },
            {
              open: false,
              title: 'Nasıl dijital pazarlama hizmeti alabilirim?',
              text: 'Dijital pazarlama hizmeti almak için dijital ajanslarla çalışabilir veya işinde uzman freelancerlar ile çalışabilirsin. Ancak, ajansların çoğu zaman maliyetlerinin yüksek olduğunu, birden çok müşteriyle çalıştıklarını hesaba katmallı ve bu nedenle  dönemsel ihtiyaçların için freelancer\'larla çalışmayı tercih etmelisin.'
            }
          ],
          'grafik-tasarim': [
            {
              open: false,
              title: 'Tasarım brief\'i nasıl verilir?',
              text: 'Tasarım brief\'i verirken; markayı ve projenin temel hedefini net ve detaylı aktarmalısın. Buna ek olarak, talep ettiğin hizmete benzer örnek çalışmala iletmen de süreci kolaylaştırabilir.'
            },
            {
              open: false,
              title: 'Tasarım türleri nelerdir?',
              text: 'Başlıca tasarım türleri; grafik tasarım, moda tasarımı, mühendislik tasarımı, peyzaj ve mimari tasarımdır.'
            },
            {
              open: false,
              title: 'Kurumsal web sitesi tasarımında nelere dikkat edilmeli?',
              text: 'Kurumsal web sitesi tasarımında; web sitesinin yaptığın işi yansıttığına, kişilerin sorularına cevap bulabildiğine, rahatlıkla sayfalar arasında dolaşabildiğine ve seninle kolayca iletişim kurabildiğine dikkat etmelisin.'
            },
            {
              open: false,
              title: 'Kurumsal kimlik ve kartvizit tasarımında kapsam nedir?',
              text: 'Kurumsal kimlik ve kartvizit tasarımında ana unsurlar; logo, slogan ve iletişim bilgilerini içeriyor. Bunların uygulandığı materyaller ihtiyaca göre çeşitlendirilebiliyor.'
            },
            {
              open: false,
              title: 'Ambalaj tasarımında nelere dikkat edilmeli?',
              text: 'Ambalaj tasarımı; kullanıcıların ilk görüşte ürünü anlamalarını sağlamalı, ürünü korumalı ve satın almayı teşvik edici özellikte olmalı.'
            }
          ]
        }
      }
    },
    methods: {
      isEmpty,
      addManualMetaOption(mi,moi){
        this.addMetaOptions(mi,moi);
        this.addOptionFilter();
      },
      gotIt(){
        window.localStorage.setItem("show_category_online_help_buble", 'no');
        this.showHelpBubble = false;
      },
      clicked(what){

        if(what === 'bicoin'){
          this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'bicoin up small box'});
          this.user.info.clicked_bicoin = true;
        }

        this.api.feeds.clicked(what)
          .then(({data}) => {
          })
          .catch(err => {
            this.$toasted.error(err);
          })

        if(what === 'bicoin'){
          this.$store.state.clickedFrom = 'up small box'
          this.$router.push("/bicoin");
        }
      },
      mixedImpressionUUID(gig){
        if(this.isSpecialCategory && Number(this.orderBy) === 1 && gig && gig.featured_gigs_id){
          return this.impressionUUIDFeatured;
        } else {
          return this.impressionUUID;
        }
      },
      showAllFeaturedGigs(){
        this.featuredGigsShownCount = 99
        if(this.featuredGigs.length > 4){
          let trackGigObjFeatured = [];
          this.featuredGigs.slice(5,99).forEach((gig, gigdex) => {
            trackGigObjFeatured.push({
              gig_id:gig.id,
              seller_id:gig.seller_id,
              order_id: gigdex + 4 + 1
            })
          });
          let trackingProps = {
            page:'categorylist',
            placement:'featured',
            info:trackGigObjFeatured,
            cat_id:this.selectedCategory.parent_id,
            sub_cat_id:this.selectedCategory.id,
            selectedTypes: this.selectedType || null,
            title: this.impressionUUIDFeatured
          };
          this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
        }

      },
      specialSeeMore(){
        let option = this.specialTags.options[this.selectedTagIndex];

        if(option.search_term){


          let q1 = this.addQueryForCategories('/search', 'slug', this.selectedCategorySlug, false, true);
          let q2 = this.addQueryForCategories('/search', 'term', option.search_term, true, true, q1);
          this.addQueryForCategories('/search', 'meta_options', option.meta_option[0].options[0], true, false, q2);


        } else {


          this.showListOnly = true;
          let foundMasterMetaOptionIndex = this.serviceMetaOptions.findIndex(({ id }) => id === option.meta_option[0].id)
          let foundMetaOptionIndex = this.serviceMetaOptions[foundMasterMetaOptionIndex].options.findIndex(({ id }) => id === option.meta_option[0].options[0])
          this.addMetaOptions(foundMasterMetaOptionIndex, foundMetaOptionIndex)
          this.addOptionFilter();
        }
      },
      preventClick(){
        return false
      },
      openEditorGigsModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.EDITOR_GIGS});
      },
      selectTag(tagIndex){

        if(!this.specialGigsLoaded) return false;
        if(this.selectedTagIndex === tagIndex) return  false;

        this.specialTags.showSeeMore = true;
        this.selectedTagIndex = tagIndex;
        if(this.specialTags?.options?.length > 0){
          this.getSpecialGigs(this.specialTags.options[tagIndex])
        }

      },
      showAllGigs(){
        this.showListOnly = true
        if (this.showOnlineOnly) {
          this.pageNumber = 1
          this.getDeeds(1)
        } else {
          let trackGigObj = []
          this.gigs.slice(8, (this.limit - 8)).forEach((gig, gigdex) => {
            trackGigObj.push({
              gig_id:gig.id,
              seller_id:gig.seller_id,
              order_id: gigdex + (8) + 1
            })
          });
          let trackingProps = {
            page:'categorylist',
            placement:'default',
            info:trackGigObj,
            cat_id:this.selectedCategory.parent_id,
            sub_cat_id:this.selectedCategory.id,
            selectedTypes: this.selectedType || null,
            title: this.impressionUUID
          };
          this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);

        }

        window.scrollTo(0, 0);
      },
      addMetaOptions(mi,moi){
        if(this.serviceMetaOptions[mi]?.options[moi]){
          if(this.serviceMetaOptions[mi]?.info?.type === 'selectbox'){
            if(this.serviceMetaOptions[mi].options[moi].selected){
              this.serviceMetaOptions[mi].options[moi].selected = false;
            } else {
              this.serviceMetaOptions[mi].options.forEach((option, oii) => {
                if(oii === moi){
                  option.selected = true;
                } else if(option.selected) {
                  option.selected = false;
                }
              });
            }
          } else {
            this.serviceMetaOptions[mi].options[moi].selected = !this.serviceMetaOptions[mi].options[moi].selected;
          }
          this.reRender = false;
          this.reRender = true;
        }
      },
      addOptions(service){
        const foundIndex = this.serviceIncludes.findIndex(serviceInclude => serviceInclude.id === service.id)
        if (foundIndex > -1) {
          let arr = this.serviceIncludes;
          arr[foundIndex].selected = !this.serviceIncludes[foundIndex].selected;
          this.serviceIncludes = arr;
        }

      },
      addOptionFilter(isFromCreated = false){
        if(this.serviceIncludes.length > 0 || this.serviceMetaOptions.length > 0){
          let tempOptions = [];
          this.serviceIncludes.forEach(service => {
            if (service.selected) {
              tempOptions.push(service.id)
            }
          })
          let kOptions = tempOptions.sort();

          let tempMetaOptions = [];
          this.serviceMetaOptions.forEach(metaOption => {
            metaOption.options.forEach(option => {
              if (option.selected) {
                tempMetaOptions.push(option.id)
              }
            })
          })
          let kMetaOptions = tempMetaOptions.sort();

          let hasOptionsChanged = JSON.stringify(this.selectedOptions) !== JSON.stringify(kOptions);
          let hasMetaOptionsChanged =  JSON.stringify(this.selectedMetaOptions) !== JSON.stringify(kMetaOptions);



          if(hasOptionsChanged || hasMetaOptionsChanged){
              this.selectedOptions = kOptions;
              this.selectedMetaOptions = kMetaOptions;

              let q_init = this.route.query;
              let qqq = this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'service_options', this.selectedOptions, isFromCreated ? false: true, true, q_init);
              this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'meta_options', this.selectedMetaOptions, isFromCreated ? false: true, false, qqq);

              if(!isFromCreated){
                this.pageNumber = 1;
              }
              this.getDeeds(this.pageNumber);
          }
          this.showOptionsFilter = false;
        }
      },
      format_tl (number, decimals = 0, decPoint = ',', thousandsSep = '.') {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
        var n = !isFinite(+number) ? 0 : +number
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        var s = ''

        var toFixedFix = function (n, prec) {
          if (('' + n).indexOf('e') === -1) {
            return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
          } else {
            var arr = ('' + n).split('e')
            var sig = ''
            if (+arr[1] + prec > 0) {
              sig = '+'
            }
            return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
          }
        }

        // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
        if (s[0].length > 3) {
          s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
        }
        if ((s[1] || '').length < prec) {
          s[1] = s[1] || ''
          s[1] += new Array(prec - s[1].length + 1).join('0')
        }

        return s.join(dec)
      },
      addTypeFilter(service_type) {
        if (service_type.id !== this.selectedType) {
          this.selectedType = service_type.id
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'service_type', service_type.id, true);
          this.pageNumber = 1
          this.getDeeds(this.pageNumber, false, 'service_type')
        }
        this.showTypesFilter = false
      },
      addBudgetFilter() {
        let minBudgetInitial = this.minBudget;
        let maxBudgetInitial = this.maxBudget;
        if(isNaN(this.minBudgetModel)){
          this.minBudgetModel = null;
          this.minBudget = null;
        } else {
          this.minBudget = parseInt(this.minBudgetModel);
        }
        if(isNaN(this.maxBudgetModel)){
          this.maxBudgetModel = null;
          this.maxBudget = null;
        } else {
          this.maxBudget = parseInt(this.maxBudgetModel);
        }

        if(this.minBudget && this.maxBudget && this.minBudget > this.maxBudget){
          let max = this.minBudget;
          let min = this.maxBudget;
          this.maxBudget = max;
          this.minBudget = min;
        }

        if(this.minBudget < 1){
          this.minBudget = null;
        }
        if(this.maxBudget < 1){
          this.maxBudget = null;
        }
        if(this.maxBudget > 99999){
          this.maxBudget = 99999;
        }
        if(this.minBudget > 99999){
          this.minBudget = 99999;
        }


        if(this.minBudget !== minBudgetInitial || this.maxBudget !== maxBudgetInitial){
          let q_init = this.route.query;
          let qqq = this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'minBudget', this.minBudget, true, true, q_init);
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'maxBudget', this.maxBudget, true, false, qqq);
          this.pageNumber = 1
          this.getDeeds(this.pageNumber)
        }
        this.showBudgetFilter = false
      },
      removeFilter(filter){
        if(filter && filter.type === 'delivery'){
          this.selectedDelivery = null;
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'delivery', null, true);
          this.pageNumber = 1;
          this.getDeeds(this.pageNumber);
        }
        if (filter === 'budget' || filter.type === 'budget') {
          this.showBudgetFilter = null;
          this.maxBudgetModel = null;
          this.maxBudget = null;
          this.minBudgetModel = null;
          this.minBudget = null;
          let q_init = this.route.query;
          let qqq = this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'minBudget', null, true, true, q_init);
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'maxBudget', null, true, false, qqq);
          this.pageNumber = 1;
          this.getDeeds(this.pageNumber);
        }
        if(filter === 'type' || filter.type === 'type'){
          this.selectedType = null;
          this.showTypesFilter = false;
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'service_type', null, true);
          this.pageNumber = 1;
          this.getDeeds(this.pageNumber);
        }
        if(filter.type === 'option'){
          if(filter.id){
            let tempOptions = [];
            this.serviceIncludes.forEach(item => {
              if(item.id === filter.id){
                item.selected = false
              } else if(item.selected) {
                tempOptions.push(item.id)
              }
            })
            let kOptions = tempOptions.sort();
            let pOptions = this.selectedOptions;
            this.selectedOptions = kOptions;
            if(JSON.stringify(pOptions) !== JSON.stringify(kOptions)){
              this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'service_options', kOptions.length ? kOptions : null, true);
              this.pageNumber = 1;
              this.getDeeds(this.pageNumber);
            }
          }

          this.showOptionsFilter = false;
        }
        if(filter.type === 'metaOption'){

          if(filter.id){
            let tempOptions = [];
            this.serviceMetaOptions.forEach(metaOption => {
              metaOption.options.forEach(option => {
                if(option.id === filter.id){
                  option.selected = false;
                } else if(option.selected) {
                  tempOptions.push(option.id)
                }
              });
            })
            let kOptions = tempOptions.sort();
            let pOptions = this.selectedMetaOptions;
            this.selectedMetaOptions = kOptions;
            if(JSON.stringify(pOptions) !== JSON.stringify(kOptions)){
              this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'meta_options', kOptions.length ? kOptions : null, true);
              this.pageNumber = 1;
              this.getDeeds(this.pageNumber);
              this.reRender = false;
              this.reRender = true;
            }
          }

          this.showOptionsFilter = false;
        }
        if(filter === 'option'){

          this.selectedOptions = [];
          this.selectedMetaOptions = [];

          this.serviceIncludes.forEach(service => {
              service.selected = false;
            })

          this.serviceMetaOptions.forEach(metaOption => {
              metaOption.options.forEach(option => {
                option.selected = false
              })
            })


          let q_init = this.route.query;
          let qqq = this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'service_options', null, true, true, q_init);
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'meta_options', null, false, false, qqq);
          this.pageNumber = 1;
          this.getDeeds(this.pageNumber);


          this.showOptionsFilter = false;
        }
      },
      changeDeliveryFilter(day) {
        this.selectedDelivery = day;
        this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'delivery', day, true);
        this.showDeliveryFilter = false;
        this.pageNumber = 1;
        this.getDeeds(this.pageNumber);
      },
      orderTypesCount() {
        let temp = [...this.categoryTypes];
        return  sortBy(temp, "count").reverse();
      },
      handleFocusOut(what='delivery') {
        if(what === 'delivery'){
          this.showDeliveryFilter = false;
        }
        if(what === 'budget'){
         this.addBudgetFilter();
         this.maxBudgetModel = null;
         this.minBudgetModel = null;
        }
        if(what === 'types'){
          //this.addBudgetFilter();
          //this.maxBudgetModel = null;
          //this.minBudgetModel = null;
          this.showTypesFilter = false;
        }
        if(what === 'options'){
          this.addOptionFilter();
          this.showOptionsFilter = false;
        }
      },
      showFilterBox(what = 'delivery') {
        if(this.pageLoaded){
          if(what === 'delivery'){
            this.showDeliveryFilter = true;
            this.showBudgetFilter = false;
            this.$nextTick(() => {
              document.getElementById("delivery-filter-box").focus()
            })
          }

          if(what === 'budget'){
            this.minBudgetModel = isNaN(this.minBudget) ? null : this.minBudget;
            this.maxBudgetModel = isNaN(this.maxBudget) ? null : this.maxBudget;
            this.showDeliveryFilter = false;
            this.showBudgetFilter = true;
          }

          if(what === 'options'){
            this.showOptionsFilter = true;
          }

          if(what === 'types'){
            this.showTypesFilter = true;
          }

        }
      },
      specifyPostUrl(ID) {
        return /freelancer-vitrin/ + ID;
      },
      onPageClick() {
        this.showBicoinDescription = false;
        this.showLovedFreelancerDescription = false;
        this.categorySelect.forEach(category => {
          category.hover = false
        });
      },
      showPortfolio(uuid) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid, calledFrom:"category_list", allItems:this.portfolioItems}, description: 'keepOldModal'});
      },
      prepareHtmlTitleAndDescription(category) {
        this.$store.state.indexHTMLTitle = `${category.name} Freelancerlar | Bionluk`;
        if(category && category.user_count && category.description && this.catDescriptionForMeta){
          this.$store.state.indexHTMLMetaDescription = this.catDescriptionForMeta;
        } else {
          this.$store.state.indexHTMLMetaDescription = `${category.name} kategorisinde özenle seçilmiş binlerce freelancer ihtiyaçların için seni bekliyor. Hemen iletişime geçin, işlerini yaptır ve zamanın sana kalsın.`;
        }
      },
      async prepareHeaderInfo(category) {
        let headerInfo = {};
        if (!category.parent_id) {
          headerInfo.title = category.name;
          headerInfo.links = [{slug: category.slug, name: category.name}];
          headerInfo.bgColor =  '';
          headerInfo.cat_users = [];
          headerInfo.user_count = 0;
        } else {
          let foundParentCategory = await this.findCategoryByID(category.parent_id)
          headerInfo.title = category.name;
          headerInfo.links = [{slug: foundParentCategory.slug, name: foundParentCategory.name,}, {slug: category.slug, name: category.name}];
          headerInfo.bgColor =  foundParentCategory.cat_color;
          headerInfo.cat_users = foundParentCategory.cat_users || [];
          headerInfo.user_count = foundParentCategory.user_count;
        }

        this.headerInfo = headerInfo;
      },
      async prepareCategorySelect(category) {
        let categorySelect = [];
        if (!category.parent_id) {
          const categories = await this.$store.state.categoriesPromise
          const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.id)
          categorySelect.push({
            name: category.name,
            selected: true,
            slug: category.slug,
            parentId: category.parent_id,
            count: category.count,
            imageInfo:category.sub_cat_info,
            gigCount: category.gig_count,
            userCount: category.user_count,
            hover:false,
            is_new:category.is_new
          });

          foundChildCategories.forEach(childCategory => {
            categorySelect.push({
              name: childCategory.name,
              selected: false,
              slug: childCategory.slug,
              parentId: childCategory.parent_id,
              count: childCategory.count,
              imageInfo:childCategory.sub_cat_info,
              gigCount: childCategory.gig_count,
              userCount: childCategory.user_count,
              hover:false,
              is_new:childCategory.is_new
            });
          })
        } else {
          const categories = await this.$store.state.categoriesPromise
          const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.parent_id)
          let foundParentCategory = await this.findCategoryByID(category.parent_id)

          categorySelect.push({
            name: foundParentCategory.name,
            selected: false,
            slug: foundParentCategory.slug,
            parentId: foundParentCategory.parent_id,
            count: foundParentCategory.count,
            imageInfo:foundParentCategory.sub_cat_info,
            gigCount: foundParentCategory.gig_count,
            userCount: foundParentCategory.user_count,
            hover:false,
            is_new:foundParentCategory.is_new
          });

          foundChildCategories.forEach(childCategory => {
            categorySelect.push({
              name: childCategory.name,
              selected: childCategory.id === category.id,
              slug: childCategory.slug,
              parentId: childCategory.parent_id,
              count: childCategory.count,
              imageInfo:childCategory.sub_cat_info,
              gigCount: childCategory.gig_count,
              userCount: childCategory.user_count,
              hover:false,
              is_new:childCategory.is_new
            });
          })
        }

        this.categorySelect = categorySelect;
      },
      getMetaOptionCounts() {
        let selectedMetaOptions = [];
        this.serviceMetaOptions.forEach(metaOption =>{
          metaOption.options.forEach(option => {
            if(option.selected){
              const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
              if(foundIndex > -1){
                selectedMetaOptions[foundIndex].options.push(option.id)
              } else {
                selectedMetaOptions.push({
                  id:metaOption.id,
                  options:[option.id]
                })
              }
            }
          });
        });

        let postObj = {
          onlineOnly:this.showOnlineOnly,
          slug: this.selectedCategorySlug,
          duration:this.selectedDelivery,
          minBudget: this.minBudget,
          maxBudget: this.maxBudget,
          service_type_id: this.selectedType,
          limit: 1,
          count: true,
          facets: ["meta_options,count:999"],
          meta_options: selectedMetaOptions,
          service_includes: this.selectedOptions,
        }


        this.serviceMetaOptions.forEach(metaOption => {
          postObj['excluded_meta_id'] = metaOption.id;
          this.searchDeeds2(postObj)
            .then(result => {
              metaOption.options.forEach(option => {
                option.count = 0;
                result.facets.meta_options.forEach(item => {
                  if (item.value === option.id) {
                    option.count = item.count;
                  }
                })
              });
              metaOption.options = sortBy(metaOption.options, "count").reverse();
            })
        })
      },
      getServiceIncludesCounts() {
        let selectedMetaOptions = [];
        this.serviceMetaOptions.forEach(metaOption =>{
          metaOption.options.forEach(option => {
            if(option.selected){
              const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
              if(foundIndex > -1){
                selectedMetaOptions[foundIndex].options.push(option.id)
              } else {
                selectedMetaOptions.push({
                  id:metaOption.id,
                  options:[option.id]
                })
              }
            }
          });
        });
        let postObj = {
          onlineOnly:this.showOnlineOnly,
          slug: this.selectedCategorySlug,
          duration:this.selectedDelivery,
          minBudget: this.minBudget,
          maxBudget: this.maxBudget,
          service_type_id: this.selectedType,
          limit: 1,
          count: true,
          facets: ["service_includes_array,count:999"],
          meta_options:selectedMetaOptions
        }
        this.searchDeeds2(postObj)
          .then(result => {
            let temp = [...this.serviceIncludes];
            temp.forEach(service => {
              service.count = 0;

              result.facets.service_includes_array.forEach(item => {
                if (item.value === service.id) {
                  service.count = item.count;
                }
              })
            })
            this.serviceIncludes = sortBy(temp, "count").reverse();


          })
      },
      getServiceTypeCounts() {
        let selectedMetaOptions = [];
        this.serviceMetaOptions.forEach(metaOption =>{
          metaOption.options.forEach(option => {
            if(option.selected){
              const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
              if(foundIndex > -1){
                selectedMetaOptions[foundIndex].options.push(option.id)
              } else {
                selectedMetaOptions.push({
                  id:metaOption.id,
                  options:[option.id]
                })
              }
            }
          });
        });
        let postObj = {
          onlineOnly: this.showOnlineOnly,
          slug: this.selectedCategorySlug,
          duration: this.selectedDelivery,
          minBudget: this.minBudget,
          maxBudget: this.maxBudget,
          service_includes: this.selectedOptions,
          limit: 1,
          count: true,
          facets: ["service_type_id,count:999"],
          meta_options: selectedMetaOptions,
        }
        this.searchDeeds2(postObj)
          .then(result => {
            let gigCount = 0;
            let temp = [...this.categoryTypes];
            temp.forEach(service_type => {
              service_type.count = 0;
              result.facets.service_type_id.forEach(item => {
                if (item.value === service_type.id) {
                  gigCount += item.count;
                  service_type.count = item.count;
                }
              })
            })
            this.categoryTypes = sortBy(temp, "count").reverse();
            this.gigsCount = gigCount;
          })
      },
      getShiningStars() {
        this.shiningStarsLoaded = false
        Api.get(`/categories/${this.selectedCategory.id}/stars`)
          .then(({ data }) => {
            this.shiningStars = data
          })
          .finally(() => {
            this.shiningStarsLoaded = true
          })
      },
      getLovedFreelancers() {
        this.impressionUUIDEditorGigs = this.Helper.generateUUID();
        this.lovedFreelancersCatLoaded = false;
        this.api.feeds.newMainPage('category_loved_freelancers', this.selectedCategory.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.lovedFreelancersCat = result.data.freelancers;
              this.editorGigs = result.data.editor_gigs;

              if(1===2 && this.editorGigs.length > 0){ //editor gigs devre dışı
                let trackGigObjFeatured = [];
                this.editorGigs.forEach((gig, gigdex) => {
                  trackGigObjFeatured.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'categorylist',
                  placement:'editor_gigs',
                  info:trackGigObjFeatured,
                  cat_id:this.selectedCategory.parent_id,
                  sub_cat_id:this.selectedCategory.id,
                  selectedTypes: this.selectedType || null,
                  title: this.impressionUUIDEditorGigs
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              }
            }
            this.lovedFreelancersCatLoaded = true;
          })
          .catch(err => {
            this.lovedFreelancersCatLoaded = true;
            this.$toasted.global.errorToast({description: err});
          });
      },
      getItems() {
        if (this.selectedCategory.id != 38){
          this.portfolioItemsLoaded = false;
          let postObj = {
            limit:4,
            offset:0,
            type:'cat_select',
            sub_category_id:this.selectedCategory.id
          };
          this.api.feeds.getShowCaseItems(postObj, this.$Progress)
            .then(({data}) => {
              this.portfolioItemsLoaded = true;
              let result = data;
              if (result.success) {
                this.portfolioItems = result.data
              }
            })
        } else {
          this.portfolioItemsLoaded = true;
        }
      },
      getFeaturedGigs(){
        this.featuredGigsLoaded = false;
        this.impressionUUIDFeatured = this.Helper.generateUUID();
        this.searchDeeds2({
          slug: this.selectedCategorySlug,
          limit: 99,
          count: false,
          offset: 0,
          orderBy: 1,
          isReallyFeatured: true
        })
          .then(result => {

            this.featuredGigsLoaded = true;

            result.results.forEach(gig => {
              gig.portfolios = JSON.parse(gig.portfolios)
              gig.likeStatus = {
                action: 1,
                actionIcon: "icon/tansel_bionluk_like_bos_s.svg",
                actionText: "Beğen"
              }

            });

            this.featuredGigs = [...result.results];
            this.api.general.getGigLikesV2(this.featuredGigs.map(gig => gig.id), 'null', '',  '')
              .then(({data}) => {
                let likeResult = data;
                  this.featuredGigs.forEach(gig => {
                    let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === gig.id)
                    if (foundLike) {
                      gig.likeStatus = foundLike.likeStatus;
                    }
                  });

              });

            if(this.featuredGigs.length > 0){
              let trackGigObjFeatured = [];
              this.featuredGigs.slice(0,4).forEach((gig, gigdex) => {
                trackGigObjFeatured.push({
                  gig_id:gig.id,
                  seller_id:gig.seller_id,
                  order_id: gigdex + 1
                })
              });
              let trackingProps = {
                page:'categorylist',
                placement:'featured',
                info:trackGigObjFeatured,
                cat_id:this.selectedCategory.parent_id,
                sub_cat_id:this.selectedCategory.id,
                selectedTypes: this.selectedType || null,
                title: this.impressionUUIDFeatured
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
            }
          })
      },
      getSpecialGigs(option){
        this.impressionUUIDSpecial = this.Helper.generateUUID();
        this.specialGigsLoaded = false;
        this.searchDeeds2({
          //isReallyFeatured: isFeatured,
          scoringProfile:'sub_slug_ignore_featured',
          searchTitleOnly:option.searchTitleOnly,
          slug: this.selectedCategorySlug,
          meta_options: option.meta_option,
          term: option.search_term,
          limit: 4,
          count: false,
          offset: 0,
        })
          .then(result => {

            this.specialGigsLoaded = true;
            result.results.forEach(gig => {
              gig.portfolios = JSON.parse(gig.portfolios)
              gig.likeStatus = {
                action: 1,
                actionIcon: "icon/tansel_bionluk_like_bos_s.svg",
                actionText: "Beğen"
              }

            });
            this.specialGigs = result.results;
            this.api.general.getGigLikesV2(this.specialGigs.map(gig => gig.id), 'null', '',  '')
              .then(({data}) => {
                let likeResult = data;
                this.specialGigs.forEach(gig => {
                  let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === gig.id)
                  if(foundLike){
                    gig.likeStatus = foundLike.likeStatus;
                  }
                });
              })

            if(this.specialGigs.length > 0){
              let trackGigObjFeatured = [];
              this.specialGigs.forEach((gig, gigdex) => {
                trackGigObjFeatured.push({
                  gig_id:gig.id,
                  seller_id:gig.seller_id,
                  order_id: gigdex + 1
                })
              });
              let trackingProps = {
                page:'categorylist',
                placement:'special_tag',
                info:trackGigObjFeatured,
                cat_id:this.selectedCategory.parent_id,
                sub_cat_id:this.selectedCategory.id,
                selectedTypes: this.selectedType || null,
                title: this.impressionUUIDSpecial
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
            }
          })
      },
      getDeeds(pageNum, isFeatured=false, calledFrom = '') {
        this.pageLoaded = false;
        let selectedMetaOptions = [];
        this.serviceMetaOptions.forEach(metaOption =>{
          metaOption.options.forEach(option => {
            if(option.selected){
              const foundIndex = selectedMetaOptions.findIndex(selectedMetaOption => selectedMetaOption.id === metaOption.id)
              if(foundIndex > -1){
                selectedMetaOptions[foundIndex].options.push(option.id)
              } else {
                selectedMetaOptions.push({
                  id:metaOption.id,
                  options:[option.id]
                })
              }
            }
          });
        });
        this.searchDeeds2({
          onlineOnly: this.showOnlineOnly,
          slug: this.selectedCategorySlug,
          limit: this.limit,
          count: true,
          offset: (pageNum - 1) * this.limit,
          orderBy: Number(this.orderBy),
          scoringProfile: this.isSpecialCategory ? 'sub_slug_ignore_featured' : 'sub_slug',
          duration: this.selectedDelivery,
          minBudget: this.minBudget,
          maxBudget: this.maxBudget,
          service_type_id: this.selectedType,
          service_includes: this.selectedOptions,
          meta_options: selectedMetaOptions,
        })
          .then(async result => {

            if(Number(this.orderBy) === 1 && pageNum === 1){
              this.impressionUUID = this.Helper.generateUUID();
              if(this.isSpecialCategory){
                this.impressionUUIDFeatured = this.Helper.generateUUID();
              }
            } else {
              if(!this.impressionUUID){
                this.impressionUUID = this.Helper.generateUUID();
              }
            }

          	let firstGigImage = '';
          	if(result.results && result.results[0]){
          		let firstGig = result.results[0];
		          firstGigImage = JSON.parse(firstGig.portfolios)[0].imageRetinaURL
            }

            let foundCategory = await this.findCategoryBySlug(this.selectedCategorySlug);

            if (foundCategory) {
              if ((pageNum - 1) * this.limit === 0) {
                this.Helper.trackEvents.pageView(this.Helper.categoryListPageProps(foundCategory, 1));
              }
            }

            if(this.pageNumber === 1 && calledFrom !== 'service_type'){
              this.getServiceTypeCounts();
            }

            this.getServiceIncludesCounts();
            this.getMetaOptionCounts();

	          if (result.count) {
		          this.totalCount = (Math.ceil(result.count / this.limit));
	          }
	          if (result.results.length === 0) {
		          this.totalCount = 0;
	          }
	          this.allGigsCount = result.count;

	          result.results.forEach(gig => {
              gig.portfolios = JSON.parse(gig.portfolios)
              gig.likeStatus = {
	              action: 1,
	              actionIcon: "icon/tansel_bionluk_like_bos_s.svg",
	              actionText: "Beğen"
              }
	          });



	          this.gigs = [...result.results];

	          if(this.isSpecialCategory){

	            if(Number(this.orderBy) !== 1){
                this.impressionUUID = this.Helper.generateUUID();
              }
              let trackLimit = this.showListOnly ? this.limit : 8;
              let trackGigObj = [];
              result.results.slice(0,trackLimit).forEach((gig, gigdex) => {
                trackGigObj.push({
                  gig_id:gig.id,
                  seller_id:gig.seller_id,
                  order_id: gigdex + 1
                })
              });
              let trackingProps = {
                page:'categorylist',
                placement: this.showOnlineOnly ? 'online' :  Number(this.orderBy) === 1 ? 'default' : Number(this.orderBy) === 2 ? 'sort_bestseller' : 'sort_new',
                info:trackGigObj,
                cat_id:this.selectedCategory.parent_id,
                sub_cat_id:this.selectedCategory.id,
                selectedTypes: this.selectedType || null,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);

            } else {


              if(Number(this.orderBy) === 1){

                let nonFeaturedGigs = result.results.filter(x => !x.featured_gigs_id);
                let featuredGigs = result.results.filter(x => !!x.featured_gigs_id);

                if(featuredGigs.length > 0){
                  let trackGigObjFeatured = [];
                  featuredGigs.forEach((gig, gigdex) => {
                    trackGigObjFeatured.push({
                      gig_id:gig.id,
                      seller_id:gig.seller_id,
                      order_id: gigdex + 1
                    })
                  });
                  let trackingProps = {
                    page:'categorylist',
                    placement:'featured',
                    info:trackGigObjFeatured,
                    cat_id:this.selectedCategory.parent_id,
                    sub_cat_id:this.selectedCategory.id,
                    selectedTypes: this.selectedType || null,
                    title: this.impressionUUIDFeatured
                  };
                  this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
                }

                if(nonFeaturedGigs.length > 0){
                  let trackGigObj = [];
                  nonFeaturedGigs.forEach((gig, gigdex) => {
                    trackGigObj.push({
                      gig_id:gig.id,
                      seller_id:gig.seller_id,
                      order_id: gigdex + 1
                    })
                  });
                  let trackingPropsnonFeatured = {
                    page:'categorylist',
                    placement: this.showOnlineOnly ? 'online' : 'default',
                    info:trackGigObj,
                    cat_id:this.selectedCategory.parent_id,
                    sub_cat_id:this.selectedCategory.id,
                    selectedTypes: this.selectedType || null,
                    title: this.impressionUUID
                  };
                  this.Helper.trackEvents.customEvent("gig_impressions", trackingPropsnonFeatured);
                }

              } else {

                this.impressionUUID = this.Helper.generateUUID();
                let trackGigObj = [];
                result.results.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'categorylist',
                  placement: this.showOnlineOnly ? 'online' : Number(this.orderBy) === 2 ? 'sort_bestseller' : 'sort_new',
                  info:trackGigObj,
                  cat_id:this.selectedCategory.parent_id,
                  sub_cat_id:this.selectedCategory.id,
                  selectedTypes: this.selectedType || null,
                  title: this.impressionUUID
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              }
            }


            this.pageLoaded = true;


            this.api.general.getGigLikesV2(this.gigs.map(gig => gig.id), this.selectedCategorySlug, firstGigImage,  '')
              .then(({data}) => {
                let likeResult = data;
                this.gigs.forEach(gig => {
                  let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === gig.id)
                  if (foundLike) {
                    gig.likeStatus = foundLike.likeStatus;
                  }
                });
              })
              .catch(err => {
              	console.log(err);
                this.$toasted.global.errorToast({description: "Bir hata oluştu"});
              });
          })
          .catch(err => {
	          console.log(err);
            this.$toasted.global.errorToast({description: "Bir hata oluştu"});
          });

      },
      goToGig(slug) {
        this.$router.push(slug);
      },
      returnCategoryStyle(category) {
        if (category.parentId === 0 && category.selected) {
          return 'font-size: 20px; font-weight: bold; color: #2d3640; ';
        } else if (category.parentId === 0) {
          return 'font-size: 20px; font-weight: bold; color: #8b95a1; ';
        }
        if (category.selected) {
          return 'color: #2d3640; font-weight:600; ';
        }
      },
      preventClicking(event) {
        event.returnValue = false;
        return false;
      },
      orderChange(e) {
	      this.$store.state.clickedFrom = 'orderby dropdown'
        const newVal = e.target.value;
        this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'order_by', newVal, true);
        this.pageNumber = 1;
        this.showListOnly = true;
        this.getDeeds(this.pageNumber);

      },
      clickPagination(pageNum) {
        this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'page', pageNum === 1 ? null : pageNum);
        this.getDeeds(pageNum);
      },
      addQueryForCategories(initialUrl, queryKey, queryValue, resetPage = false, returnOnly = false, init_query = null) {
        let query = init_query ? init_query : this.route.query;
        if(returnOnly){
          let queryOnly = {};
          if (!isEmpty(query)) {
            for (const key in query) {
              if (key === queryKey) {
                if(queryValue && (!Array.isArray(queryValue) || queryValue.length)){
                  queryOnly[queryKey] = queryValue;
                }
              } else {
                if (query[key]){
                  if(!(key === 'page' && resetPage)){
                    queryOnly[key] = query[key];
                  }

                }
              }
            }
          }

          if(queryValue && (!Array.isArray(queryValue) || queryValue.length)){
            queryOnly[queryKey] = queryValue;
          }


          return queryOnly;
        } else {

          let urlStr = initialUrl + '?';
          if (!isEmpty(query)){
            for (const key in query) {
              if (key === queryKey) {
                if (queryValue && (!Array.isArray(queryValue) || queryValue.length)) {
                  queryValue = decodeURIComponent(queryValue);
                  urlStr += '&' + queryKey + '=' + encodeURIComponent(queryValue);
                }

              } else {
                if (!(key === 'page' && resetPage)){
                  query[key] = decodeURIComponent(query[key]);
                  urlStr += '&' + key + '=' + encodeURIComponent(query[key]);
                }
              }
            }
          }


          if (!urlStr.includes(queryKey) && queryValue && (!Array.isArray(queryValue) || queryValue.length)) {
            queryValue = decodeURIComponent(queryValue);
            urlStr += '&' + queryKey + '=' + encodeURIComponent(queryValue);
          }


          this.$router.push(encodeURI(urlStr));
        }
      },
      resetAllSettings(action = '') {
        this.featuredGigsShownCount = 4;
        this.showOnlineOnly = false; //bu null'dı false yaptın bakalım bişi ol
        this.offset = 0;
        this.selectedDelivery = null;
        this.selectedType = null;
        this.selectedOptions = [];
        this.selectedMetaOptions = [];
        this.minBudget = null;
        this.maxBudget = null;
        this.minBudgetModel = null;
        this.maxBudgetModel = null;
        this.allGigsCount = 0;
        this.optionsSliceCount = 6;

        this.showTypesFilter = false;
        this.showOptionsFilter = false;
        this.showBudgetFilter = false;
        this.showDeliveryFilter = false;

        if(action !== 'getDeeds'){
          this.categoryTypes = [];
          this.serviceIncludes = [];
          this.serviceMetaOptions = [];
        } else {
          this.serviceIncludes.forEach(service => {
            service.selected = false;
          })
          this.serviceMetaOptions.forEach(metaOption => {
            metaOption.sliceCount = 4;
            metaOption.options.forEach((mo,mi) => {
              mo.selected = false;
              mo.count = 0;
            })
          });
          if(this.isSpecialCategory){
            this.showListOnly = false;
            this.getFeaturedGigs();
            this.getItems();
            this.getLovedFreelancers();
            this.getShiningStars()
            if(this.specialTags?.options?.length > 0){
              this.getSpecialGigs(this.specialTags.options[0]);
            }
          }
          this.$router.push('/freelancer-bul/'+this.selectedCategorySlug);
          this.getDeeds(this.pageNumber);
        }
      }
    },
    computed: {
      computedSelectedAnyOptions() {
        let dummyForRender = this.reRender;
        let selectedCount = this.selectedOptions && this.selectedOptions.length > 0 ? 1 : 0;
        if(selectedCount === 0){
          this.serviceMetaOptions.forEach(metaOption => {
            metaOption.options.forEach((mo,mi) => {
              if(mo.selected){
                selectedCount++
              }
            })
          });

          return selectedCount > 0;
        } else {

          return  selectedCount > 0
        }

      },
      catDescription() {
        if(this.selectedCategory && this.selectedCategory.description){
          let arr =  this.selectedCategory.description.split('xxxfreelancer');
          if(arr[0] && arr[1]){
            return arr[0] + '<strong>'+this.format_tl(this.selectedCategory.user_count)+' freelancer</strong>' + arr[1];
          } else {
            return this.selectedCategory.description;
          }
        }
      },
      catDescriptionForMeta() {
        if(this.selectedCategory && this.selectedCategory.description){
          let arr =  this.selectedCategory.description.split('xxxfreelancer');
          if(arr[0] && arr[1]){
            return arr[0] + this.format_tl(this.selectedCategory.user_count)+' freelancer' + arr[1];
          } else {
            return this.selectedCategory.description;
          }
        }
      },
      filterLabels() {
        let dummyForRender = this.reRender;
        let arr = [];
        if(this.selectedType){
          arr.push({type:'type', title: this.categoryTypes.find(({ id }) => id === this.selectedType).name})
        }
        if(this.selectedOptions.length > 0){
          this.serviceIncludes.forEach(service => {
            let index = this.selectedOptions.indexOf(service.id);
            if (service.selected && index > -1) {
              arr.push({type:'option', title: service.title, id:service.id})
            }
          })
        }
        this.serviceMetaOptions.forEach(metaOption => {
          metaOption.options.forEach((option,ii) => {
            if (option.selected) {
              arr.push({type:'metaOption', title: option.title_for_buyer, id:option.id})
            }
          })

        })
        if(this.selectedDelivery){
          arr.push({type:'delivery', title: this.deliveryLabels.find(({ id }) => id === this.selectedDelivery).title})
        }
        if(this.minBudget || this.maxBudget){
          let budgetTitle = '';
          if(this.minBudget && this.maxBudget){
            budgetTitle = this.format_tl(this.minBudget) + 'TL - ' + this.format_tl(this.maxBudget) + 'TL';
          } else if(this.minBudget){
            budgetTitle = this.format_tl(this.minBudget) + 'TL Üstü';
          } else if(this.maxBudget){
            budgetTitle = this.format_tl(this.maxBudget) + 'TL Altı';
          }
          arr.push({type:'budget', title:budgetTitle});
        }

        if (arr.length) {
          this.showListOnly = true
        }
        return arr
      },
      computedOptions() {
        return this.serviceIncludes.filter(({ count }) => count > 0)
      },
      computedAllFilters() {
        return {
          duration: this.selectedDelivery,
          minBudget: this.minBudget,
          maxBudget: this.maxBudget,
          service_type_id: this.selectedType,
          service_includes: this.selectedOptions
        }
      },
      showAllServiceOptions() {
        return this.serviceIncludes?.length > 0 || this.serviceMetaOptions?.length > 0
      }
    },
    watch: {
      showOnlineOnly(newVal, oldVal) {
        if(oldVal === false && newVal === true) {
          this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'online', 1, false, false)
          this.showAllGigs()
          this.showHelpBubble = false
        }

        if(oldVal === true && newVal === false) {
          if(this.route.query.online) {
            this.addQueryForCategories('/freelancer-bul/' + this.selectedCategorySlug, 'online', null, true, false)
            this.pageNumber = 1
            this.getDeeds(1)
          }
        }
      },
      showOptionsFilter(newValue) {
        if (newValue){
          this.$nextTick(() => {
            let ele = document.getElementsByClassName("long-options")[0];
            let rect = ele.getBoundingClientRect();
            ele.style.maxHeight = (window.innerHeight - rect.top - 125) + "px";
          })
        }
      },
      async selectedCategorySlug(newValue, oldValue) {
        this.blogViews = []
        this.selectedCategorySlug = this.routerParams[0];

        this.showOnlineOnly = null;

        let category = await this.findCategoryBySlug(newValue);
        this.selectedCategory = category || { id: null, parent_id: 0 }
        if (category) {

          this.featuredGigs = [];
          this.featuredGigsLoaded = false;
          this.specialGigs = [];
          this.specialGigsLoaded = false;
          this.portfolioItems = [];
          this.portfolioItemsLoaded = false;
          this.lovedFreelancersCat = [];
          this.lovedFreelancersCatLoaded = false;

          this.editorGigs = [];


          this.serviceIncludes = [];
          this.serviceMetaOptions = [];
          this.resetAllSettings();
          this.categoryName = category.name;
          this.categoryId = category.id;
          await this.prepareCategorySelect(category);
          this.prepareHtmlTitleAndDescription(category);
          await this.prepareHeaderInfo(category);

          this.categoryName = category.name;
          this.categoryId = category.id;


          if(this.selectedCategory.parent_id){
            this.categoryTypes = category.category_service_types;
            this.serviceIncludes = category.service_includes;
            this.serviceIncludes.forEach(service => {
              service.selected = false;
            });

            this.serviceMetaOptions = category.meta_options;
            this.serviceMetaOptions.forEach(metaOption => {
              metaOption.sliceCount = 4;
              metaOption.options.forEach((mo,mi) => {
                mo.selected = false;
                mo.count = 0;
              })
            });

            this.pageNumber = (this.route.query.page && this.route.query.page !== 'undefined') ? Number(this.route.query.page) : 1;
            this.selectedDelivery = (this.route.query.delivery && this.route.query.delivery !== 'undefined') ? Number(this.route.query.delivery) : null;
            this.minBudget = (this.route.query.minBudget && this.route.query.minBudget !== 'undefined') ? Number(this.route.query.minBudget) : null;
            this.maxBudget = (this.route.query.maxBudget && this.route.query.maxBudget !== 'undefined') ? Number(this.route.query.maxBudget) : null;

            this.orderBy = this.Helper.isIndexerBotOrDevelopmentEnv() ? 1 : (this.route.query.order_by && this.route.query.order_by !== 'undefined') ? Number(this.route.query.order_by) : 1;

            this.showOnlineOnly = (this.route.query.online && this.route.query.online !== 'undefined' && Number(this.route.query.online)) === 1

            let selectedType = (this.route.query.service_type && this.route.query.service_type !== 'undefined') ? Number(this.route.query.service_type) : null;
            if(selectedType && this.categoryTypes.length > 0) {
              const foundIndex = this.selectedCategory.category_service_types.findIndex(serviceType => serviceType.id === parseInt(selectedType))
              if (foundIndex > -1) {
                this.selectedType = selectedType;
              }
            }


            this.isSpecialCategory = category.special_category_options.is_special_category;
            this.specialTags = category.special_category_options.special_tags;


            let selectedOptions = (this.route.query.service_options && this.route.query.service_options !== 'undefined') ? this.route.query.service_options : null;
            let selectedMetaOptions = (this.route.query.meta_options && this.route.query.meta_options !== 'undefined') ? this.route.query.meta_options : null;

            if(selectedOptions || selectedMetaOptions){
              if(selectedOptions){
                selectedOptions = decodeURIComponent(selectedOptions)
                let arr = selectedOptions.split(',');
                if(arr && arr.length > 0){
                  this.serviceIncludes.forEach(service => {
                    arr.forEach(item => {
                      if(parseInt(item) === service.id){
                        service.selected = true;
                      }
                    })
                  })
                }
              }


              if(selectedMetaOptions){
                selectedMetaOptions = decodeURIComponent(selectedMetaOptions)
                let arrMeta = selectedMetaOptions.split(',');
                if(arrMeta && arrMeta.length > 0){
                  this.serviceMetaOptions.forEach(metaOption => {
                    metaOption.options.forEach(option=> {
                      arrMeta.forEach(item => {
                        if(parseInt(item) === option.id){
                          option.selected = true;
                        }
                      })
                    });
                  })
                }
              }


              this.addOptionFilter(true);
            } else {


              if(this.isSpecialCategory
                && this.pageNumber === 1
                && this.selectedDelivery === null
                && this.minBudget === null
                && this.maxBudget === null
                && this.orderBy === 1
                && this.selectedType === null
              ){
                this.showListOnly = false;

                this.getFeaturedGigs();
                this.getItems();
                this.getLovedFreelancers();
                this.getShiningStars()
                if(this.specialTags?.options?.length > 0){
                  this.getSpecialGigs(this.specialTags.options[0]);
                }
                this.getDeeds(this.pageNumber);
              } else {
                this.showListOnly = true;
                this.getDeeds(this.pageNumber);
              }
            }



          } else {
            if (this.selectedCategorySlug) {
              Api.get(`/blog-views/${this.selectedCategorySlug}`)
                .then(({ data }) => {
                  this.blogViews = data
                })
            }
            this.Helper.trackEvents.pageView(this.Helper.categoryListPageProps(category, 1));
          }

          if (newValue && oldValue && newValue !== oldValue) {
            this.$router.push(`/freelancer-bul/${newValue}`);
          }


        } else {
          this.$router.push(`/freelancer-bul`);
        }

      },
      "routerParams": function (newValue, oldValue) {
        if (newValue && oldValue && newValue[0] && oldValue[0] && (newValue[0] !== oldValue[0])) {
          this.selectedCategorySlug = newValue[0];
        }
      }
    },
    async created() {
      this.selectedCategorySlug = this.routerParams[0]
      if (this.selectedCategorySlug) {
        this.selectedCategory = await this.findCategoryBySlug(this.selectedCategorySlug) || { id: null, parent_id: 0 }
        if (!this.selectedCategory.parent_id) {
          await this.prepareCategorySelect(this.selectedCategory)
          bindCodeToHtml(JSON.stringify(generateCodeForParentCategory(this.categorySelect.filter(cat => cat.parentId))))
        } else {
          bindCodeToHtml(JSON.stringify(generateCodeForChildCategory({ ...this.selectedCategory, description: this.catDescriptionForMeta })))
        }
        if (this.faqs[this.selectedCategory.slug]) {
          const faqs = this.faqs[this.selectedCategory.slug].map(faq => ({ question: faq.title, answer: faq.text }))
          bindCodeToHtml(JSON.stringify(generateCodeForFaq(faqs)))
        }
      } else {
        this.selectedCategory = { id: null, parent_id: 0 }
      }

      let showHelpB = window.localStorage.getItem("show_category_online_help_buble");
      if (showHelpB === 'no') {
        this.showHelpBubble = false;
      } else {
        this.showHelpBubble = true;
        if (!showHelpB) {
          window.localStorage.setItem("show_category_online_help_buble", 'yes');
        }
      }
      if (this.selectedCategorySlug) {
        Api.get(`/blog-views/${this.selectedCategorySlug}`)
          .then(({ data }) => {
            this.blogViews = data
          })
      }
    }
  }

</script>

<style scoped lang="scss">

.is-new{
  width: 35px;
  height: 16px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #fd4056;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  display: flex;
  .is-new-t{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;
    color: #fff;
  }
}

.helpbubble {

  z-index: 99;
  position: absolute;
  background: #00a575;
  color: #FFFFFF;
  top:210px;
  margin-left: 30px;
  width: 250px;
  height: 130px;
  border-radius: 10px;
  padding: 0px;
  .help-title{
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    .new{
      margin-left: 5px;
      padding:4px 10px 5px;
      border-radius: 5px;
      background: white;
      font-size: 12px;
      color: #00a575;

    }
  }

  .gotit{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
.helpbubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #00a575 transparent;
  border-width: 0 12px 12px;
  top: -12px;
  left: 88%;
  margin-left: -12px;
}

.top-right-box{
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff; display: flex; align-items: center; justify-content: center;


  .small-pink-button{
    width: 130px;
    height: 28px;
    border-radius: 2px;
    background-color: #fd4056;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    &:hover{
      opacity: 0.9;
    }
  }
  svg{
    width: 16px!important;
    height: 16px!important;
  }


  .right-info-icon /deep/ {
    margin-left: 5px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    path {
      fill: #2d3640;
      &:hover{
        opacity: 0.8;
      }
    }


  }

  .right-settings-icon /deep/ {
    cursor: pointer;
    width: 16px;
    height: 16px;
    path {
      fill: #bfc8d2;
      &:hover{
        opacity: 0.8;
      }
    }


  }

  .green-link{
    cursor: pointer;
    font-size: 12px; color:#00a575;
    &:hover{
      text-decoration: underline!important;
    }


  }

  .green-small-area{
    min-width: 80px;
    height: 26px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-weight: bold;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    span{
      font-weight: normal;
    }
  }
}

.question-mark-wrapper {
  cursor: help;
  font-size: 12px;
  color: #2d3640;
  font-weight: 300;
  .question-mark-icon /deep/ {
    width: 15px;
    height: 15px;
    path {
      fill: #2d3640
    }
  }
}

.tooltiptext {
  margin-top: -144px;
  display: block;
  visibility: hidden;
  position: absolute;
  margin-left: -16px;
  width: 372px;
  height: 100px;
  background-color: #00a575;
  color: #fff;
  text-align: center;
  padding: 0 3px;
  border-radius: 4px;
  z-index: 34;
  left: 0;
  top: 36px;
  font-size: 13px;
  line-height: 1.38;
  font-weight: 400;

}

.tooltiptext::after {
  content: " ";
  position: absolute;
  left: 15px;
  top: 100%;
  margin-left: 7px;
  border-width: 7px;
  border-style: solid;
  border-color: #00a575 transparent transparent  transparent;
}

.go-request{
  background: url("https://gcdn.bionluk.com/site/general/sl_bg%402x.png");
  background-size: cover;
  width: 100%;
  height: 224px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    margin-left: 60px;
    .title{
      width: 520px;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.67;
      color: #ffffff;
      span{
        font-weight: bold;
      }
    }
    .cuper-red-button{
      width: 200px;
      height: 44px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }

  }

  .right{
    margin-right: 60px;
    width: 335px;
    height: 203px;
  }

}

.freelancerAvatar {
  width: 91px;
  height: 91px;
  border-radius: 100%;
  border: 2px solid #eaeaea;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 4;
}

.rainDropcontainer {
  position: relative;
  display: block;
  width: 132px;
  height: 132px;
  margin-top: 15px;
}


.firstLayer {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  opacity: 0.1;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 3;
}

.secondLayer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  opacity: 0.08;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
}

.thirdLayer {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  opacity: 0.05;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}

.filter-labels{
  display: flex;
  width: 1190px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .filter-label{
    margin-right: 16px;
    padding-left: 15px;
    padding-right: 15px;
    height: 37px;
    border-radius: 5px;
    background-color: #2d3640;
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    margin-bottom: 10px;

    .close-icon-container{
      &:hover{
        opacity: 0.9;
      }
      cursor: pointer;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      .close-icon /deep/ {
        width: 10px;
        height: 10px;
        path {
          fill: #00a575;
        }
      }
    }
  }
}


#option-filter-box{
  width: 560px
}
#types-filter-box{
  width: 330px;
}
#budget-filter-box{
  width: 330px;
}

#delivery-filter-box{
  width: 290px;
}
.filter-options{
  margin-top: 5px;
  outline: 0;
  position: absolute;


  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.15);

  background-color: #ffffff;
  z-index: 99;
  border: solid 1px rgba(75, 79, 82, 0.2);


  .long-options{
    max-height: 100px;
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-y: scroll;
  }

  .filter-options-container{
    margin: 10px 24px 0;

    .filter-options-container-title{
      color: #2D3640; font-weight: 600; font-size: 18px;
    }
    .show-more{
      margin-top: -10px;
      color: #00a575;
      font-weight: 600;
      font-size: 15px;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }

    .filter-radio-item{
      display: flex; align-items: center; cursor: pointer;
      margin-bottom: 20px;

      .filter-radio-empty{
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: solid 1px #8b95a1;
      }

      .filter-radio-label{
        font-size: 16px;  color: #2d3640; margin-left: 8px; line-height: 1.4
      }

      .filter-radio-label-active{
        font-size: 16px; color: #2d3640; font-weight: 500; margin-left: 8px; line-height: 1.4
      }

      .filter-radio-full{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: solid 4px #00a575;
      }


    }


    .filter-checkbox-item{
      cursor: pointer;
      display: flex;
      width: 50%;
      margin-bottom: 20px;

      .filter-checkbox-empty{
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: solid 1px #8b95a1;
      }


      .filter-checkbox-full{
        width: 12px;
        height: 12px;
        border-radius: 5px;
        border: solid 4px #00a575;

      }



      .filter-checkbox-label{
        padding-left: 9px;
        font-size: 15px;
        opacity: 0.85;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2D3640;

      }

      .filter-checkbox-label-active{
        padding-left: 9px;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2d3640;

      }

    }



    .filter-category-item{
      font-size: 15px;
      font-weight: 500;
      color: #2D3640;
      display: flex; align-items: center; cursor: pointer;
      margin-bottom: 20px;
      justify-content: space-between;
      line-height: 19px;

      &.selected{
        font-weight: 600;
        color: #39d1a2;
      }
      &:hover{
        color: #39d1a2;
      }

      .cunts{
        margin-left: 10px;
        color: #b8bdc5;
      }

    }

    .temizle{
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      color: #2d3640;
      &:hover{
        opacity: 0.8;
      }
    }

    .uygula{
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: #2d3640;
      padding: 3px auto 5px;
      color: #ffffff;
      letter-spacing: -0.3px; font-weight: 600; font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 28px;
    }
  }
}


.filter-box{
  position: relative;
  margin-right: 16px;
  .the-box{
    cursor: pointer;
    display:flex;
    border-radius: 5px;
    padding: 9px 20px 10px;
    font-size: 17px;
    font-weight: 600;

    color:#2D3640;
    border: 1px solid #bfc8d2;
    .icon-container{

      margin-left: 15px;

      .icon /deep/ {
        width: 10px;
        height: 10px;
        path {
          fill: #5e6b79;
        }
      }
    }
    &.active{
      color: #00a575;
      font-weight: 500;
      background: rgba(0, 165, 117,0.2);
      border: solid 1px #00a575!important;
      .icon-container{

        margin-left: 15px;

        .icon /deep/ {
          width: 10px;
          height: 10px;
          path {
            fill: rgba(0, 165, 117,1);
          }
        }
      }
    }


  }


}
.sub-portfolio-hover{

  position: absolute;
  width: 296px;
  height: 80px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  margin-left: -82px;
  margin-top: -10px;
  display: flex;
  align-items: center;

  .portfolio-title{
    font-size: 14px;
    height: 20px;
    font-weight: 600;
    color: #2d3640;
    text-overflow:ellipsis;

    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .portfolio-text{
    font-size: 12px;
    font-weight: 300;
    color: #5e6b79;
  }

  .portfolio-link{
    font-size: 12px;
    font-weight: 600;
    color: #fa4a5e;
    &:hover{
      text-decoration: underline;
    }
  }
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-left-color: #ffffff;
    border-right: 0;
    margin-top: -7px;
    margin-right: -7px;
  }
}
.main-cat-title{
  font-size: 18px; font-weight: 600;
}

.main-cat-sub-title{
  margin-top:4px; font-size: 16px;line-height: 1.25;
}



  .category-title {
    background-color: #fff;
    min-width: 1193px;
    display: flex;
    width: 1193px;
    margin: 0 auto;
    text-align: left;
    padding-top: 10px;
    position: relative;

  }

.category-sub-title {
  background-color: #fff;
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding-top: 10px;
  position: relative;
  height: 141px;

  .category-sub-title-inside{
    max-width: 1193px;
    width: 1193px;
    margin: 0 auto;
    text-align: left;
    display: flex;

    .online-text{
      color: white; margin-top: 30px; margin-bottom: 20px; font-weight: 400; font-size: 14px;
    }

    .cat-title{
      font-size: 34px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 10px;
    }



    .cat-description{
      font-size: 16px;
      line-height: 1.5;
      color: #ffffff;
    }
  }

}



  .super-drop-tek-cizgi-cats {
    background: url(https://gcdn.bionluk.com/site/cicons/ic-mini-arrow-down.svg) no-repeat right;

    font-family: 'sofia-pro';
    position: relative;
    color: #2d3640;
    border: none;
    cursor: pointer;

    margin-left: 5px;
    background:transparent;
    font-weight: 600;
    font-size: 16px;
  }

  .super-drop-tek-cizgi:hover {
    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }

  input.super-drop-tek-cizgi:focus {
    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }

  .cat_label {
    font-size: 14px;
    color: #8b95a1;
  }

  .basic-button:hover {
    border-color: inherit !important;
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.3) inset;
  }

  .catFilter {
    height: 70px;
    background-color: white;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    justify-content: center;
    top: 105px;
  }


  .catFilterFixed {
    position: fixed;
    margin-top: -262px;

  }

  .gigContainer {
    display: flex;
    padding-bottom: 10px;
    width: 1193px;
    margin: 0 auto;
    padding-top: 30px;
  }

  .catFilter-order-by {
    display: inline-block;
    width: 200px;
  }

  .cat_label {
    font-weight: 300 !important;
    font-size: 14px;
    color: #8b95a1;
  }

  .gig-list-container {

    position: relative;
    display: flex;
    width: 1193px;
    flex-wrap: wrap;
    margin-top: 30px;


    .gig-list-gig{
      margin-bottom: 40px;
      margin-left: 24px;
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }

    &:hover {
      .swiper-button-prev, .swiper-button-next {
        visibility: visible;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    visibility: hidden;

  }

  .categories-name {

    font-size: 15px;
    display: flex;
    align-items: center;


    color: #5e6b79;
    cursor: pointer;
    line-height: normal;

    margin-bottom: 16px;
    margin-top: 0;

    a {
      line-height: normal;
      color: #5e6b79;
      text-decoration: none;
      &:hover {
        color: #2D3640;
      }
    }

  }

  .catFilter-container {
    min-width: 1193px;
    display: flex;
    justify-content: space-between;
  }

  .select-type-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;


  }



  .sub-image-container{
    width: 270px;
    height: 247px;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-bottom: 40px;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none!important;

    &:nth-child(3n+1) {
      margin-left: 0;
    }

  }



  .cat-story-title{
    font-size: 32px;
    font-weight: normal;
    line-height: 1.25;
    color: #2d3640;
    span{
      font-weight: bold;
    }
  }

  .cat-story-sub-title{
    margin-top: 15px;
    font-size: 16px;
    font-weight: normal;
    color: #5e6b79;

    span {
      font-weight: bold;
      color: #26ca9a;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }


.tags{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  .tag{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    height: 37px;
    padding-bottom: 1px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
    font-size: 13px;
    font-weight: normal;
    color: #8b95a1;
    &:hover{
      border: solid 1px #26ca9a;
      color: #26ca9a;
    }
  }

  .tag-selected{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    height: 37px;
    padding-bottom: 1px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 4px;
    background-color: #26ca9a;

    border: solid 1px #26ca9a;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;

  }
}


  .see-more-c{
    text-align: center; width: 100%;
    display: flex;
    align-items: center;justify-content: center;
    color: #2d3640;
    cursor: pointer;
    margin-top: -15px;
    margin-bottom: 30px;


    .plus{
      font-size: 40px;
      font-weight: 300;
      padding-right: 10px;
    }
    .text{
      font-size: 18px;
      font-weight: 500;
      padding-top: 9px;
      &:hover{
        text-decoration: underline;
      }

    }
  }


.portfolio-box{

  margin-left: 26px;
  margin-bottom: 30px;
  width: 277px;
  height: 274px;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;

  .head-section{
    display: flex;
    align-items: center;
    height: 66px;
    margin-left: 11px;
    .title{
      margin-top: 1px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px;
      height: 18px;
      display: block;
    }
    .avatar{
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .sub-title{
      margin-top: 2px;
      font-size: 12px;
      font-weight: 300;
      color: #8b95a1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px;
      height: 14px;
      a{
        font-size: 12px;
        font-weight: 600;
        color: #fd4056;
        &:hover{
          text-decoration: underline;
          color: #fd4056!important;
        }
      }
    }
  }

  .portfolio-img{

    position: relative;
    cursor: pointer;
    height: 158px;
    img{
      width: 100%;
    }
  }

  .foot-section{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    height: 48px;

    font-size: 16px;
    font-weight: 600;
    color: #8b95a1;

    span{
      font-size: 16px;
      font-weight: 600;
      color: #8b95a1;
      padding-left: 5px;
      padding-right: 15px;
      line-height: 0.9;

    }
    .like-icon /deep/ {
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #bfc8d2!important;
      }
    }

    .trash-icon /deep/ {
      cursor: pointer;
      width: 20px;
      height: 20px;
      path {
        fill: #bfc8d2!important;
        &:hover{
          fill: #2d3640!important;
        }
      }
    }

    .trash-icon:hover{
      path {
        fill: #2d3640!important;
      }
    }

    .edit-icon /deep/ {
      cursor: pointer;
      width: 20px;
      height: 20px;
      path {
        fill: #bfc8d2!important;
        &:hover{
          fill: #2d3640!important;
        }
      }
    }
    .edit-icon:hover{
      path {
        fill: #2d3640!important;
      }
    }

  }
}
.cardBoxContainer{

  transition: transform 0.5s ease 0s;
  visibility: visible;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y:hidden;
  .cardBoxFlexC{
    display: flex;
    margin-right: 34px;
    &:last-of-type {
      margin-right: 0px;
    }
  }

  .cardBox{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 209px;
    height: 361px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .cardOnline{
      position: absolute;
      margin-left: 170px;
      margin-top:15px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .profileButton{
      background-color: white;
      width: 140px;
      height: 34px;
      padding-bottom: 2px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);

      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
      transition: .2s;
      &:hover{
        background-color: #2d3640!important;
        color:#fff
      }

    }
    .username{
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #2d3640;
    }

    .title{
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 16px;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: -0.5px;
      text-align: center;
      color: #8b95a1;
    }


    .rating{

      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rate{
        margin-left: 2px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffbe00;
      }

      .count{
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }


    .reText{


      text-align: center;
      margin-top: 28px;
      height: 14px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2d3640;

      display: flex;
      span{
        font-weight: bold;
      }
      p{
        line-height: 1;
        margin-left: 5px;
      }
      .reTextIcon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #8b95a1;
        }
      }
    }
  }

  .pBox{
    width: 277px;
    height: 274px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    position: relative;
    display: flex;


    flex-direction: column;
    align-items: center;
    margin-left: 28px;
    margin-bottom: 30px;

    .header{

      display: flex;
      align-items: center;
      height: 67px;
      width: 100%;

    }

    .media{
      width: 277px; height: 158px;
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .avatar{
      margin-left: 11px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username-p{

      font-size: 11px;
      font-weight: 300;
      color: #5e6b79;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 14px;
      max-width: 200px;

      a{
        font-size: 11px;
        font-weight: 600;
        color: #fa4a5e;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .title{
      font-weight: 600;
      color: #2d3640;
      font-size: 14px;
    }


  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -130%);
    height: 42px;
    width: 42px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 42px;
      width: 42px;
    }
  }

}


.card-container {
  width: 278px;
  height: 298px;
  background-color: #ffffff;
  border: solid 1px #dfe4ec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;

  &:nth-child(4n+1) {
    margin-left: 0;
  }
}

.card-image-container {
  height: 148px;
  background-color: #fafbfc;
  border-bottom: solid 1px #dfe4ec;
}

.card-detail-container {
  padding: 15px;
}

.card-detail-header-large {
  width: 234px;
  height: 16px;
  background-color: #e1e6ed;
  margin-bottom: 4px;
}

.card-detail-header-small {
  width: 117px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-image {
  width: 28px;
  height: 28px;
  background-color: #dfe4ec;
  border-radius: 50%;
  margin-right: 8px;
}

.card-detail-user-name {
  width: 50px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-currency {
  width: 63px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 15px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4ec;
}

.card-detail-user {
  display: flex;
  align-items: center;
}

.card-like-icon {
  width: 16px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-currency-icon {
  width: 42px;
  height: 20px;
  background-color: #bdc5d0;
}

.card-like-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 15px;
}

.card {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.service-label {
  padding-left: 10px;
}

@keyframes loading {
  10% {
    background-color: #dfe4ec;
  }
  2% {
    background-color: #fafbfc;
  }
}

.section-header-today{

  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
  span{
    font-weight: bold;
  }
}
.tumu{
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00a575;
  padding-right: 10px;
  padding-top: 12px;
  &:hover{
    text-decoration: underline!important;
    color: #00a575;
  }
}

.category-select-container {
  width: 220px;
  border-radius: 10px;
  box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
}
</style>
