const v2 = resolve => require(['./v2.vue'], resolve)
const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);
const create_v1 = resolve => require(['./create_v1.vue'], resolve);
const edit_v1 = resolve => require(['./edit_v1.vue'], resolve);

export default {
  v2_mobile,
  v2,
  create_v1,
  edit_v1
}
