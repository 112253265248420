<template>
  <div style="width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center;">
    <loader></loader>
  </div>
</template>

<script>
  export default {
    name: "src-pages-body-iyzi3ds-v1",
    data() {
      return {}
    }
  }
</script>

<style scoped>

</style>
