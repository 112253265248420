import { render, staticRenderFns } from "./portfoliov2.vue?vue&type=template&id=7de44869&scoped=true&"
import script from "./portfoliov2.vue?vue&type=script&lang=js&"
export * from "./portfoliov2.vue?vue&type=script&lang=js&"
import style0 from "./portfoliov2.vue?vue&type=style&index=0&id=7de44869&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de44869",
  null
  
)

export default component.exports