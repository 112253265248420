/**
 * Created by haluk on 4/17/17.
 */

import v1 from './desktop/v1.vue'
import v1_mobile from './mobile/v1_mobile.vue'

export default {
  v1,
  v1_mobile
}
