<template>
  <div class="nps-container" v-if="openNps" :style="step === 2 ? 'height:230px' : 'height:180px'">
    <img class="close" @click="closeNps()" src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg"/>
    <img src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png" style="display:none;height: 24px; margin-bottom: 20px; "/>


      <div class="step-container" v-if="step === 1">
        <p class="nps-title" style=" text-align: center; line-height: 1.4">{{title}}</p>
        <div v-if="id !== 3" style="text-align: left; width: 100%">
          <p style="float: left; font-size: 14px;color: #8b95a1">Çok fazla önermem</p>
          <p style="float: right; font-size: 14px; color: #8b95a1">Kesinlikle öneririm</p>
        </div>
        <div  v-if="id !== 3" class="radio-button-container">
          <div class="radio-buttons" v-for="index in 10" :key="'category' + index" @click="score = index">
            <label class="radio-label-title" :for="'radio' + index">{{index}}</label>
            <input type="radio" :checked="index === score" :id="'radio' + index" name="radio-group" :value="index">
            <label class="radio-label" :for="'radio' + index" :value="index"></label>
          </div>
        </div>
        <div v-else style="display: flex; align-items: center; height: 80px; margin-top: 25px;">
          <div @click="emojiClick(1)" @mouseover="activeImage = 1" @mouseleave="activeImage = null" :style="activeImage === null || activeImage === 1 ? 'opacity:1;' : 'opacity:0.3'" class="img-div">
            <img class="img" src="https://gcdn.bionluk.com/site/cicons/emojis/1_.png">
            <p v-if="activeImage === 1" style="padding-top: 10px; color: #8b95a1">Çok Kötü</p>
          </div>
          <div  @click="emojiClick(2)" @mouseover="activeImage = 2" @mouseleave="activeImage = null" :style="activeImage === null || activeImage === 2 ? 'opacity:1;' : 'opacity:0.3'" class="img-div">
            <img class="img" src="https://gcdn.bionluk.com/site/cicons/emojis/2_.png">
            <p v-if="activeImage === 2" style="padding-top: 10px; color: #8b95a1">Beğenmedim</p>
          </div>
          <div  @click="emojiClick(3)" @mouseover="activeImage = 3" @mouseleave="activeImage = null" :style="activeImage === null || activeImage === 3 ? 'opacity:1;' : 'opacity:0.3'" class="img-div">
            <img class="img" src="https://gcdn.bionluk.com/site/cicons/emojis/3_.png">
            <p v-if="activeImage === 3" style="padding-top: 10px; color: #8b95a1">Fikrim Yok</p>
          </div>
          <div  @click="emojiClick(4)" @mouseover="activeImage = 4" @mouseleave="activeImage = null" :style="activeImage === null || activeImage === 4 ? 'opacity:1;' : 'opacity:0.3'" class="img-div">
            <img class="img" src="https://gcdn.bionluk.com/site/cicons/emojis/4_.png">
            <p v-if="activeImage === 4" style="padding-top: 10px; color: #8b95a1">Beğendim</p>
          </div>
          <div  @click="emojiClick(5)" @mouseover="activeImage = 5" @mouseleave="activeImage = null" :style="activeImage === null || activeImage === 5 ? 'opacity:1;' : 'opacity:0.3'" class="img-div">
            <img class="img" src="https://gcdn.bionluk.com/site/cicons/emojis/5_.png">
            <p v-if="activeImage === 5" style="padding-top: 10px; color: #8b95a1">Çok iyi</p>
          </div>
        </div>

        <div class="button-container" v-if="step !== 1">
          <button @click="step += 1" class="next-button">Devam</button>
        </div>
      </div>

      <div class="step-container" v-if="step === 2">
        <p class="nps-title" style="margin-bottom: 20px; ">Bu değerlendirmendeki en önemli neden neydi?</p>

        <textarea v-model="description" class="description-textarea"></textarea>
        <div class="button-container">
          <button @click="step -= 1" class="back-button">Geri</button>
          <button @click="newNpsCreate" class="next-button" :disabled="loading">Devam</button>
        </div>
      </div>

      <div class="step-container" style="justify-content: flex-start; align-items: flex-start" v-if="step === 3">
        <p class="nps-title" style="margin-top: 20px; font-size: 26px; font-weight: 600">Teşekkürler!</p>

        <p class="summarize-text">Freelancer topluluğumuzu daha da iyi hale getirmemize yardım ettiğin için, tüm kalbimizle tekrar tekrar teşekkürler.</p>
      </div>

  </div>
</template>

<script>

  export default {
    name: 'pages-body-nps-nps',
    data() {
      return {
      	activeImage:null,
        loading:false,
        openNps: false,
        title: "Bionluk'un yeni anasayfa deneyimini nasıl buldun?",
        id: 3,
        score: null,
        step: 1,
        description:  ''
      }
    },

    methods: {
	    emojiClick(what){
	    	this.score = 2 * what;
	    	if(what > 3){
			    this.step = 3;
			    this.loading = true;
			    this.api.feeds.newNps(this.id, this.score, this.description)
				    .then(({data}) => {
					    let result = data;
					    if (result.success) {
						    console.log('s');
						    this.loading = true;
						    console.log('s');
					    } else {
						    this.$toasted.global.errorToast({description: result.message});
						    this.loading = true;
					    }
				    }).catch(err => {
				    console.log(err);
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				    this.loading = true;
			    })
        } else {
			    this.step = 2;
        }
      },
      closeNps() {
        this.openNps = false;
        if(this.id === 3 && this.step !== 3){
	        this.api.feeds.newNps(this.id, this.score, this.description)
		        .then(({data}) => {
			        let result = data;
			        if (result.success) {

				        this.step = 3;
				        this.loading = true;

			        } else {
				        this.$toasted.global.errorToast({description: result.message});
				        this.loading = true;
			        }
		        }).catch(err => {
		        console.log(err);
		        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
		        this.loading = true;
	        })
        }

      },

      newNpsCreate() {
        this.loading = true;
        this.api.feeds.newNps(this.id, this.score, this.description)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              console.log('s');
              this.step = 3;
              this.loading = true;
              console.log('s');
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.loading = true;
            }
          }).catch(err => {
            console.log(err);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.loading = true;
        })
      }
    },

    created() {
      this.EventBus.$on('npsPusher', payload => {
        this.title = payload.title;
        this.id = payload.id;
        this.openNps = true;
        this.loading = false;
        this.score =  null;
        this.step = 1;
        this.description = '';
      });
    }

  }

</script>

<style scoped lang="scss">


  .img-div{
    cursor: pointer;
    display:flex; flex-direction:column; align-items:center; justify-content:center; width:60px; text-align:center; font-size:12px;

  }

  .img{
   width: 40px; height: 40px;

    transition: 0.5s all;
  }

  .img2{
    width: 40px; height: 40px; margin-left:18px;

    transition: 0.5s all;
  }
  .emoji{
    &:hover{
      opacity: 1;
    }
  }
  .nps-container {
    position: fixed;
    background-color: #fff;
    width: 300px;
    height: 180px;
    z-index: 11;
    bottom: 10px;
    right: 20px;
    border: solid 1px #d7dde3;
    padding: 40px 20px 20px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.20);
    border-radius: 10px;
  }


  .step-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .close {
    opacity: 0.4;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  .close:hover{
    opacity: 1;
  }

  .nps-title {
    font-weight:500;
    font-size: 18px;
    word-wrap: break-word;
    width: 100%;
    color:#2d3640;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px
  }

  .back-button {
    width: 80px;
    height: 40px;
    border-radius: 2px;
    background-color: #eaedf2;
    border: 1px solid #bfc8d2;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    &:hover {
      background-color: #bfc8d2 !important;
    }
  }

  .next-button {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    margin-left: auto;
    &:hover {
      background-color: #f33e53 !important;
    }

    &:disabled {
      background-color: #999 !important;
    }
  }

  .radio-button-container {
    display: flex;
    margin-top: 10px;
  }

  .radio-buttons {
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    color: #2d3640;
    display: flex;
    flex-direction: column;
  }

  .radio-label-title {
    font-size: 16px;
    color: #5e6b79;
    display: unset;
    cursor: pointer;
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 0;
  }

  input[type=radio] {
    display: none;

  }

  input[type=radio] + .radio-label {
    height: 26px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    cursor: pointer;
    margin: 0px;
    background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg) no-repeat;
  }

  input[type=radio]:checked + .radio-label {
    display: inline-block;
    cursor: pointer;
    height: 26px;
    width: 16px;
    margin: 0px;
    background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
  }

  .description-textarea {
    width: -webkit-fill-available;
    height: 90px;

  }

  .summarize-text {
    text-align: left;
    word-wrap: break-word;
    width: 75%;
    margin-top: 16px;
    font-size: 17px;
    font-weight: 300;
    color: #2d3640;
    opacity: 0.8;
  }
</style>
