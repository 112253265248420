export function generateCodeForGigDetail(gig, isSingle) {
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": `Ben, ${gig.title}`,
    "image": [
      gig.imageUrl
    ],
    "description": `Ben, ${gig.title}`,
    "sku": String(gig.id),
    "brand": {
      "@type": "Brand",
      "name": gig.brand
    },
    "review": !gig.ratingCount ? undefined : {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": String(gig.ratingValue),
        "bestRating": "5",
        "worstRating": "0"
      },
      "author": {
        "@type": "Person",
        "name": gig.username
      }
    },
    "aggregateRating": !gig.ratingCount ? undefined : {
      "@type": "AggregateRating",
      "ratingValue": String(gig.ratingValue),
      "reviewCount": String(gig.ratingCount),
      "bestRating": "5",
      "worstRating": "0"
    },
    "offers": {
      "@type": "Offer",
      "url": `https://bionluk.com/${gig.slugUrl}`,
      "priceCurrency": "TRY",
      "price": String(gig.price),
      "lowPrice": isSingle ? undefined : String(gig.price),
      "highPrice": isSingle ? undefined : String(gig.maxPrice),
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Bionluk.com"
      }
    }
  }
}

export function generateCodeForParentCategory(categories) {
  return {
    "@context":"https://schema.org",
    "@type": "ItemList",
    "itemListElement": categories.map((category, index) => {
      return {
        "@type": "ListItem",
        "position": index + 1,
        "url": `https://bionluk.com/freelancer-bul/${category.slug}`,
        "name": category.name,
        "image": category.imageInfo.image_url
      }
    })
  }
}

export function generateCodeForChildCategory(category) {
  return {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": category.name,
    "image": category.sub_cat_info.image_url,
    "description": category.description,
    "sku": String(category.id),
    "brand": {
      "@type": "Brand",
      "name": category.name
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": String(category.user_count * 5)
    },
    "offers": {
      "@type": "AggregateOffer",
      "category": category.name,
      "priceCurrency": "TRY",
      "lowPrice": String(category.lowPrice),
      "highPrice": String(category.highPrice),
      "url": `https://bionluk.com/freelancer-bul/${category.slug}`,
      "offerCount": String(category.gig_count)
    }
  }
}

export function generateCodeForFaq(faqs) {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => {
      return {
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer
        }
      }
    })
  }
}

export function bindCodeToHtml(code) {
  const script = document.createElement('script')
  script.setAttribute('type', 'application/ld+json')
  script.textContent = code
  document.head.appendChild(script)
}
