<template>
  <div>
    <p>Bionluk © 2024</p>
  </div>
</template>

<script>
  export default {
    name: "src-pages-components-shared-navFooter",
    data() {
      return {

      };
    }
  }
</script>

<style>

</style>
