<template>
  <div style="display: flex;justify-content: center">
    <div id="google-signin" :class="activateLink ? 'black' : 'social-button google-button'" @click="openPopupWindow">
      <div v-if="!activateLink">
        <img style="height: 24px; width: 24px; " :src="$root.CDN_SITE + 'icon/Google_-G-_Logo.svg.png'">
      </div>
      <div style=" margin-left: 10px; width: auto; ">
        {{ activateLink ? 'Google' : 'Google ile devam et' }}
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'google-login',
    props: ["activateLink", "calledFrom"],
    data() {
      return {
        window: null,
        test: null,
        called_from: this.calledFrom
      };
    },

    methods: {
      openPopupWindow() {
        const leftPosition = (window.screen.width / 2) - ((700 / 2) + 10)
        const topPosition = (window.screen.height / 2) - ((682 / 2))
        this.window = window.open('https://bionluk.com/napi/auth/google/auth_redirect', '_blank', `width=700,height=682,top=${topPosition},left=${leftPosition}`)
      },
      onSignInSuccess({ data, origin }) {
        if (data.type === 'callback' && origin === 'https://bionluk.com') {
          if (this.window) {
            this.window.close()
          }
          this.loginWithGoogle(data.id_token, data.g_access_token, this.$Progress, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query, 'desktop', this.called_from);
        }
      },
      loginWithGoogle(id_token, access_token, progress, path, query, exandroid, called_from) {
        this.EventBus.$emit("loader" , true);
        this.api.user.loginWithGoogle(id_token, access_token, progress, path, JSON.stringify(query), exandroid, called_from)
        .then(({data})=> {
          let result = data;
          if (result.success) {
            this.EventBus.$emit("loader" , false);

            if (result.visitor_id) {
              window.localStorage.setItem("visitor_id", result.visitor_id);
              this.$store.commit(this.types.SET_VISITOR_ID, result.visitor_id);
            }

            let trackingProps = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
              type: 'google',
	            page: this.$store.state.componentMap.page
            };

            let forceMerge = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
            };
            this.Helper.trackEvents.customEvent("loggedin", trackingProps, forceMerge);

            this.$store.commit(this.types.SET_MESSAGE_COMPONENT, result.data.messageComponent);
            this.$store.state.routerMap = result.data.routerMap;
            this.$store.commit(this.types.SET_MODAL_COMPONENT_MAP, result.data.modalComponents);
            this.$store.commit(this.types.SET_USER, result.data.user);
            this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
            this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
            this.$router.push({ path: result.data.redirect_url, query: result.data.redirect_query });
          } else {
            this.EventBus.$emit("loader" , false);
            if (result.data && result.data.redirect_url) {
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'firstname', value: result.data.firstname});
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'lastname', value: result.data.lastname});
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'email', value: result.data.email});
              this.$router.push(result.data.redirect_url);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }
        })
        .catch(err => {
          console.log(err, '<<<<')

          this.EventBus.$emit("loader" , false);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }
    },
    mounted() {
      window.addEventListener("message", this.onSignInSuccess, false)
    },
    beforeDestroy() {
      window.onSignInSuccess = undefined
      window.removeEventListener("message", this.onSignInSuccess, false)
    }
  }

</script>

<style scoped>
  .social-button {
    border: 1px solid #242424;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;
    max-width: 320px;
    height: 54px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }

  .google-button{
    color: #242424;
  }

  @media only screen and (min-width: 780px) {
    .social-button {
      width: 340px;
      height: 56px;
    }
  }
</style>
