<template>
  <div class="input-body">

    <template v-if="inputType === null">
      <input :ref="id"
             :id="id"
             :value="value"
             :type="type"
             :autocomplete="autocomplete"
             :maxlength="maxlength"
             :placeholder="placeHolder"
             class="input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             :required="required"
      />
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <p class="helpText" v-if="helpText">{{ helpText }}</p>
    </template>

    <template v-if="inputType === 'text'">
      <input :ref="id"
             :id="id"
             :value="value"
             :readonly="readonly"
             :autocomplete="autocomplete"
             :maxlength="maxlength"
             :placeholder="placeHolder"
             class="input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             @keypress="onKeyPressForOnlyText"
             title="Bu alanı doldurmayı unutmayın"
             required
      />
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">
        {{label}} <span v-if="required" :style="getInputStyle('required')">*</span>
      </label>
      <p class="text-area-length" style="margin-top: 5px;" v-if="maxlength"> <span>{{value ? value.length : '0'}}</span> / {{maxlength}}</p>
      <p class="helpText" v-if="helpText">{{ helpText }}</p>
    </template>

    <template v-if="inputType === 'number'">
      <input :ref="id"
             :id="id"
             :value="value"
             :autocomplete="autocomplete"
             :maxlength="maxlength"
             :placeholder="placeHolder"
             class="input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             @keypress="onKeyPressForOnlyNumber"
             title="Bu alanı doldurmayı unutmayın"
             required/>
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
    </template>

    <template v-if="inputType === 'card'">
      <input :ref="id"
             :id="id"
             :value="value"
             :placeholder="placeHolder"
             maxlength="19"
             autocomplete="cc-number"
             class="input card-input"
             :style="getInputStyle('input')"
             @input="handleCardInput"
             @keypress="onKeyPressForCard"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             required/>
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <img class="card-image" v-if="inputType === 'card' && cardImage.length" :src="cardImage" alt="card-image" @click="$refs[id].focus()">
    </template>

    <template v-if="inputType === 'gsm'">
      <input :ref="id"
             :id="id"
             :value="value"
             :placeholder="placeHolder"
             maxlength="13"
             class="input"
             :style="getInputStyle('input')"
             @input="handleGsmInput"
             @keypress="onKeyPressForGsm"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             required/>
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
    </template>

    <template v-if="inputType === 'cvc'">
      <input :ref="id"
             :id="id"
             :value="value"
             :placeholder="placeHolder"
             autocomplete="cc-csc"
             name="cc-csc"
             class="input cvc-input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @keypress="onKeyPressForCvc"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             required/>
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
    </template>

    <template v-if="inputType === 'textArea'">
      <textarea :label="label"
                :value="value"
                :maxlength="maxlength"
                :placeholder="placeHolder"
                class="input text-area"
                :style="getInputStyle('input')"
                @input="handleInput"
                @focus="inputFocus($event, true)"
                @blur="inputFocus($event, false)"
                title="Bu alanı doldurmayı unutmayın"
                required>
      </textarea>
      <label v-if="label" class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <p class="text-area-length" v-if="maxlength"> <span>{{value ? value.length : '0'}}</span> / {{maxlength}}</p>
    </template>

    <template v-if="inputType === 'password'">
      <input :ref="id"
             :id="id"
             :type="passwordType"
             :name="name"
             :value="value"
             :maxlength="maxlength"
             :autocomplete="autocomplete"
             class="input"
             :style="getInputStyle('input')"
             @input="handleInput"
             @focus="inputFocus($event, true)"
             @blur="inputFocus($event, false)"
             title="Bu alanı doldurmayı unutmayın"
             required/>
      <label v-if="label"  class="input-label" :style="getInputStyle('label')">{{label}}</label>
      <img class="show-password close" v-if="passwordType === 'text' && value" src="https://gcdn.bionluk.com/site/c/ic-eye-close.svg" @click="passwordType = 'password'"/>
      <img class="show-password open" v-if="passwordType === 'password' && value" src="https://gcdn.bionluk.com/site/c/ic-eye-open.svg"  @click="passwordType = 'text'"/>
      <p class="valid-text" v-if="!!validText && valid === false">{{validText}}</p>
    </template>
  </div>
</template>

<script>
/**
 * Daha stabil calismasi icin id verilmeli.
 */
import _isNil from 'lodash/isNil'

export default {
  name: 'CustomInput',
  props: {
    name: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    inputType: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      default: null
    },
    label: {
      type: String,
      default: null,
    },
    placeHolder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: null
    },
    readonly: {
      type: Boolean,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    validText: {
      type: String,
      default: ''
    },
    cardImage: {
      type: String,
      default: ''
    },
    maxlength: {
      type: String,
      default: undefined
    },
    inputStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasFocus: false,
      passwordType: 'password'
    }
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
      this.$emit('change', e);
    },

    handleCardInput(e) {
      const cursorPositionIndex = e.target.selectionStart;
      let str = e.target.value;
      const regStr = str.replace(/-/g, "");
      if (e.inputType !== 'deleteContentBackward' && regStr.length <= 16 && (cursorPositionIndex === str.length || regStr.length === 16)) {
        let newval = '';
        regStr.split('').forEach((reg, i) => {
          if (i > 0 && i % 4 === 0) {
            newval = newval.concat('-');
          }
          newval = newval.concat(regStr[i]);
          str = newval;
        })
      } else if (e.inputType === 'deleteContentBackward' && str[str.length - 1] === '-') {
        str = str.slice(0, str.length - 1);
      }
      this.$emit('input', str);
    },

    onKeyPressForCard(e) {
      const regStr = this.value.replace(/-/g, "");

      if ((isNaN(e.key) && !e.metaKey && !e.ctrlKey) || e.key === ' ' || regStr.length >= 16) {
        e.preventDefault();
        return false;
      }
    },

    handleGsmInput(e) {
      console.log('im here')
      const cursorPositionIndex = e.target.selectionStart;
      let str = e.target.value;
      const regStr = str.replace(/ /g, "");
      if (e.inputType !== 'deleteContentBackward' && regStr.length <= 10 && (cursorPositionIndex === str.length || regStr.length === 10)) {
        let newval = '';
        regStr.split('').forEach((reg, i) => {
          if (i > 0 && i < 7 && i % 3 === 0) {
            newval = newval.concat(' ');
          } else if(i > 7 && i % 2 === 0) {
            newval = newval.concat(' ');
          }
          newval = newval.concat(regStr[i]);
          str = newval;
        })
      } else if (e.inputType === 'deleteContentBackward' && str[str.length - 1] === ' ') {
        str = str.slice(0, str.length - 1);
      }
      this.$emit('input', str);
    },

    onKeyPressForGsm(e) {
      const cursorPositionIndex = e.target.selectionStart;

      const regStr = this.value ? this.value.replace(/ /g, "") : '';

      if ((isNaN(e.key) && !e.metaKey && !e.ctrlKey) || e.key === ' ' || (cursorPositionIndex === 0 && e.key !== '5')) {
        e.preventDefault();
        return false;
      }
    },

    inputFocus(event, focus) {
      if (focus) {
        this.hasFocus = focus;
        this.$emit('focus', event)
      } else {
        this.hasFocus = focus;
        this.$emit('blur', event)
      }
    },

    onKeyPressForOnlyText(e) {
      if ((!isNaN(e.key) && !e.metaKey && !e.ctrlKey) && e.key !== ' ') {
        e.preventDefault();
        return false;
      }
    },

    onKeyPressForOnlyNumber(e) {
      this.$emit('keypress', e);


      if(e.key === ','){

      } else {
        if ((isNaN(e.key) && !e.metaKey && !e.ctrlKey) || e.key === ' ') {
          e.preventDefault();
          return false;
        }
      }
    },

    onKeyPressForCvc(e) {
      if ((isNaN(e.key) && !e.metaKey && !e.ctrlKey) || e.key === ' ' || e.target.value.length >= 4) {
        e.preventDefault();
        return false;
      }
    },

    getInputStyle(type) {
      let style = '';
      switch (type) {
        case 'input':
          style = this.cardImage.length ? 'padding-right: 84px; ' : this.inputStyle;
          if (this.readonly) {
            style = style + ' opacity:0.8; '
          } else if ((_isNil(this.valid) && !!this.value) || this.hasFocus) {
            style = style + 'borderColor: #2d3640;'
          } else if (this.valid === true && !!this.value) {
            style = style + 'borderColor: #49A278;'
          } else if (this.valid === false && !!this.value) {
            style = style + 'borderColor: #FD4056;'
          } else {
            style = style + 'borderColor: #CACACA'
          }

          return style;
        case 'label':
          if ((_isNil(this.valid) && !!this.value) || this.hasFocus) {
            style = style + 'color: #2d3640;'
          } else if (this.valid === true && !!this.value) {
            style = style + 'color: #00a575;'
          } else if (this.valid === false && !!this.value) {
            style = style + 'color: #FD4056;'
          }  else {
            style = style + 'color: #8b95a1;'
          }
          return style
        case 'required':
          if ((_isNil(this.valid) && !!this.value) || this.hasFocus) {
            style = style + 'color: #FB2424;'
          } else if (this.valid === true && !!this.value) {
            style = style + 'color: #00a575;'
          } else if (this.valid === false && !!this.value) {
            style = style + 'color: #FB2424;'
          }  else {
            style = style + 'color: #FB2424;'
          }
          return style
      }

    },
  }

}

</script>

<style scoped lang="scss">

.input-body {
  position: relative;
  width: fit-content;
}

.input {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 270px !important;
  -ms-background-position-x: 270px !important;
  appearance: none;
  padding: 0 30px 0 20px;
  max-width: 100%;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #CACACA;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 500;
  color: #2d3640;
}

.text-area {
  background-color: #fff;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 18px 10px 20px;
  max-width: 100%;
  min-height: 100%;
  line-height: 26px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-input {
  font-weight: 800;
}

.cvc-input {
  font-weight: 800;
  text-align: center;
}

.select-box-button {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 270px !important;
  -ms-background-position-x: 270px !important;
  appearance: none;
  padding: 0 10px 0 0;
  width: 100%;
  height: 45px;
  border-radius: 2px;
  border: 1px solid rgba(75, 79, 82, 0.2);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #2d3640;
  background-color: #fff !important;

  &:hover {
    background-color: #fff !important;
  }
}

.input-label {
  font-weight: 300;
  font-size: 16px;
  pointer-events: none;
  background-color: #fff;
  position: absolute;
  top: 14px;
  left: 17px;
  color: #808896;
  padding: 0 3px;
  margin-bottom: 0;

  transform-origin: 0 0;

  -ms-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.input:focus + .input-label, .input:valid + .input-label, .hasValue + .input-label {
  transform-origin: 0 0;
  -ms-transform: translate3d(-7px, -21px, 0) scale(0.75);
  -webkit-transform: translate3d(-7px, -21px, 0) scale(0.75);
  -moz-transform: translate3d(-7px, -21px, 0) scale(0.75);
  -o-transform: translate3d(-7px, -21px, 0) scale(0.75);
  transform: translate3d(-7px, -21px, 0) scale(0.75);
}

.input::placeholder {
  color: #808896;
  font-size: 16px;
  font-weight: 300;
  opacity: 0;
}

.input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.input:focus::-moz-placeholder {
  opacity: 1;
}

.input:focus:-ms-input-placeholder {
  opacity: 1;
}

.input:focus:-moz-placeholder {
  opacity: 1;
}

.valid-text {
  position: absolute;
  bottom: -18px;
  left: 2px;
  max-width: 100%;
  color: #fd4056;
  font-size: 12px;
  font-weight: 500;
}

.card-image {
  pointer-events: none;
  position: absolute;
  max-width: 60px;
  max-height: 35px;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.text-area-length {
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
  font-size: 13px;
  font-weight: 800;
  color: #2d3640;
  span {
    color: #8b95a1;
    font-weight: 500;
  }
}

.show-password {
  width: 24px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  max-width: 60px;
  max-height: 35px;
  right: 12px;
  top: 13.5px;
}

.close {
  opacity: 0.9;
}
.open {
  opacity: .4;
}
.helpText {
  position: absolute;
  font-size: 12px;
  color: #808896;
}
</style>
