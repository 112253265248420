<template>
  <div style="display: flex;justify-content: center">
    <fb-signin-button
      id="facebookButton"
      :class="activateLink ? 'black' : 'social-button facebook-button'"
      :params="fbSignInParams"
      @success="onSignInSuccessFacebook"
      @error="onSignInErrorFacebook">
      <div  v-if="!activateLink">
        <img src="https://gcdn.bionluk.com/site/icon/facebook_landing_icon.svg">
      </div>
      <div style="margin-left: 10px;">
        {{ activateLink ? 'Facebook' : 'Facebook ile devam et' }}
      </div>
    </fb-signin-button>
  </div>
</template>

<script>

  export default {
    name: 'facebook-login',
    props: ["activateLink","calledFrom"],
    data() {
      return {
        fbSignInParams: {
          scope: null,
          return_scopes: true
        },
        called_from: this.calledFrom
      };
    },

    methods: {

      onSignInSuccessFacebook({authResponse}) {
        this.loginWithFacebook(authResponse.accessToken, this.$Progress, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query, this.called_from);
      },

      loginWithFacebook(access_token, progress, path, query, called_from) {
        this.EventBus.$emit("loader" , true);
        this.api.user.loginWithFacebook(access_token, progress, path, JSON.stringify(query),called_from)
        .then(({data}) => {
          let result = data;
          if (result.success) {
            this.EventBus.$emit("loader" , false);
            if (result.visitor_id) {
              window.localStorage.setItem("visitor_id", result.visitor_id);
              this.$store.commit(this.types.SET_VISITOR_ID, result.visitor_id);
            }


            let trackingProps = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
              type: 'facebook',
	            page: this.$store.state.componentMap.page
            };

            let forceMerge = {
              userID: result.data.user.id,
              userUUID: result.data.user.uuid,
              username: result.data.user.username,
            };
            this.Helper.trackEvents.customEvent("loggedin", trackingProps, forceMerge);

            this.$store.commit(this.types.SET_MESSAGE_COMPONENT, result.data.messageComponent);
            this.$store.state.routerMap = result.data.routerMap;
            this.$store.commit(this.types.SET_MODAL_COMPONENT_MAP, result.data.modalComponents);
            this.$store.commit(this.types.SET_USER, result.data.user);
            this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
            this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
            this.$router.push(result.data.redirect_url);
          } else {
            this.EventBus.$emit("loader" , false);
            if (result.data.redirect_url) {
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'firstname', value: result.data.firstname});
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'lastname', value: result.data.lastname});
              this.$store.commit(this.types.SET_USER_CHANGES, {key: 'email', value: result.data.email});
              this.$router.push(result.data.redirect_url);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }
        })
        .catch(err => {
          this.EventBus.$emit("loader" , false);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      onSignInErrorFacebook(error) {
        // `error` contains any error occurred.
      }
    },
    created() {
    	this.fbSignInParams.scope = "public_profile, email";
    }
  }

</script>

<style scoped>

  .social-button {
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;
    max-width: 320px;
    height: 54px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }

  .facebook-button{
    color: #39579a;
  }


  @media only screen and (min-width: 780px) {
    .social-button {
     width: 344px;
    }
  }


</style>
