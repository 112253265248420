<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-loader-screen="openLoader" style="height: 100%; overflow:scroll; ">

        <div v-if="getModalInfo" class="modal-container" @click.stop>

          <div style="float: right; padding-right: 10px;">
            <img @click="hide()" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div style="clear: both"></div>

          <div class="create-area">

            <div  v-if="step === 1">
              <p class="title">Neye <span>ihtiyacın var?</span></p>
              <p class="sub-title">Freelancer olarak iş mi arıyorsun yoksa yetenekli, tecrübeli ve yaratıcı freelancer’lara mı ihtiyacın var?</p>

              <div class="main-select-container">
                <div class="select-img-container" @click="persona = 'buyer'" :style="persona === 'buyer' ? 'border: solid 1px rgb(38, 202, 154);' : ''">
                  <img class="select-img" src="https://gcdn.bionluk.com/site/general/onboarding_buyer.png">
                  <p class="select-text"  :style="persona === 'buyer' ? 'color:#2d3640' : ''">Hizmet satın almak istiyorum.</p>
                </div>
                <div class="select-img-container" @click="persona = 'freelancer'" :style="persona === 'freelancer' ? 'border: solid 1px rgb(38, 202, 154);' : ''">
                  <img class="select-img" src="https://gcdn.bionluk.com/site/general/onboarding_seller.png">
                  <p class="select-text" :style="persona === 'freelancer' ? 'color:#2d3640' : ''">Freelancer olmak istiyorum.</p>
                </div>

              </div>
            </div>





            <!--register-->
            <div v-if="step === 2 && !isUserHaveAccount && !isUserLogin">

              <p class="title">Profil <span>Oluştur</span></p>
              <p v-if="persona === 'freelancer'" class="sub-title">Freelancer olmadan önce profilini oluşturarak Bionluk’a kayıt olmalısın.</p>
              <p v-else class="sub-title">Sana özel olarak seçtiğimiz freelancerlara göz atmadan önce profilini oluşturarak Bionluk’a kayıt olmalısın.</p>
              <div class="hiddendiv"></div>

              <template v-if="socialButtons">
                <facebook-login v-if="false" :activateLink="false" :calledFrom="calledFrom"></facebook-login>
                <div class="hiddendiv20"></div>
                <google-login :activateLink="false"  :calledFrom="calledFrom"></google-login>
                <div class="hiddendiv"></div>
                <div class="or">
                  <span>veya</span>
                </div>
                <div class="social-button" style="margin: 0 auto">
                  <p class="super-stroked-button" @click="socialButtons = false">E-posta ile devam et</p>
                </div>
              </template>

              <form v-else @submit.prevent="register(firstname, lastname, username, email, password)">
                <div style="width: 400px; margin: 30px auto 0; text-align: left">
                  <div style="display: flex; text-align: left; ">
                    <div style="">
                      <input :class="{ error: errorInfo.firstname }" type="text" placeholder="Ad"
                             v-model="computedFirstname"
                             style="width: 180px; font-size: 16px; ">
                      <span v-if="errorInfo.firstname" class="errorMessage">{{ errorInfo.firstname.message }}</span>
                    </div>
                    <div style="margin-left: 20px;">
                      <input :class="{ error: errorInfo.lastname }" type="text" placeholder="Soyad" v-model="computedLastname"
                             style="width: 180px; font-size: 16px; ">
                      <span v-if="errorInfo.lastname" class="errorMessage"> {{ errorInfo.lastname.message }} </span>
                    </div>
                  </div>

                  <div class="hiddendiv"></div>

                  <input :class="{ error: errorInfo.username }" @focus="createUsername()" type="text" placeholder="Kullanıcı adı"
                         v-model="username" style=" width:100%;font-size: 16px;">
                  <span v-if="errorInfo.username" class="errorMessage">{{ errorInfo.username.message }}</span>
                  <label style="font-size: 12px;  font-weight:500;text-align: left; margin-top: 8px; color: #bfc8d2;">bionluk.com/<span
                    style="color: #2d3640;">{{username}}</span></label>

                  <div class="hiddendiv"></div>
                  <input :class="{ error: errorInfo.email }" type="text" placeholder="E-posta adresin" v-model="computedEmail"
                         style="width:100%;font-size: 16px;">
                  <span v-if="errorInfo.email" class="errorMessage">{{ errorInfo.email.message }}</span>

                  <div class="hiddendiv"></div>

                  <input :class="{ error: errorInfo.password }" type="password" placeholder="Şifre" v-model="password"
                         style="width:100%;font-size: 16px;">
                  <span v-if="errorInfo.password" class="errorMessage">{{ errorInfo.password.message }}</span>

                </div>

                <p class="help-text">Bionluk’a kayıt olarak <a href="https://bionluk.com/pages/gizlilik" target="_blank">Gizlilik Sözleşmesi</a> ve <a href="https://bionluk.com/pages/kullanici-sozlesmesi" target="_blank">Kullanıcı Sözleşmesini</a> kabul etmiş olursun.</p>
                <p class="login-text" @click="isUserHaveAccount = true">Üye Girişi</p>

              </form>


            </div>
            <!--login-->
            <div class="request-steps request-step6" v-if="step === 2 && isUserHaveAccount && !isUserLogin">
              <p class="title">Üye <span>Girişi</span></p>
              <p class="sub-title">Daha önce Bionluk profili oluşturduysan lütfen üye girişi yaparak devam edebilirsin.</p>

              <div class="hiddendiv"></div>


              <facebook-login :activateLink="false" :calledFrom="calledFrom"></facebook-login>
              <div class="hiddendiv20"></div>
              <google-login :activateLink="false"  :calledFrom="calledFrom"></google-login>
              <div class="hiddendiv"></div>
              <div class="or">
                <span>veya</span>
              </div>
              <div class="hiddendiv"></div>

              <div style="margin:0 auto; width: 400px; text-align: center">
                <form @submit.prevent="login(loginEmail, loginPassword)">
                  <input style="width: 320px;" placeholder="E-posta adresi ve ya kullanıcı adı" v-model="loginEmail">
                  <div class="hiddendiv"></div>
                  <input style="width: 320px;" type="password" placeholder="Şifre" v-model="loginPassword">

                  <div class="hiddendiv10"></div>

                  <p class="help-text">
                   Hesabın yok mu? <span @click="isUserHaveAccount = false">Hemen kaydolun</span>
                  </p>
                </form>

              </div>


            </div>

            <div class="request-step0" v-if="step === 3">
              <img style="width: 678px; height: 269px;" src="https://gcdn.bionluk.com/site/general/illustration_req_wizard.png"/>
              <p class="step0-header">Özel Alıcı İstediğin Kaydedildi!</p>
              <p class="step0-paragraph">Şimdi son düzenlemeleri yaparak yayına alabilirsin.</p>

              <router-link :to="redirectedUrl">
                <button class="step0-button request-next-button">İstek Sayfasına Git</button>
              </router-link>
            </div>

          </div>

          <div class="foot">
            <template v-if="step === 1">
              <custom-button :disabled="!enableButton" button-type="red" style="width: 220px;" @click="nextStep()">Devam Et</custom-button>
            </template>
            <template v-if="step === 2 && !isUserHaveAccount && !isUserLogin">
              <custom-button button-type="border" :style="!socialButtons ? 'width: 120px; margin-left: 50px;' : 'width:120px;' " @click="prevStep()">Geri Dön</custom-button>
              <custom-button v-if="!socialButtons" :disabled="!enableButton" button-type="green" style="width: 220px;margin-right: 50px;" @click="register(firstname, lastname, username, email, password)">Kaydı Tamamla</custom-button>
            </template>
            <template v-if="step === 2 && isUserHaveAccount && !isUserLogin">
              <custom-button button-type="border" style="width: 120px; margin-left: 50px;" @click="prevStep()">Geri Dön</custom-button>
              <custom-button :disabled="!enableButton" button-type="green" style="width: 200px;margin-right: 50px;" @click="login(loginEmail, loginPassword)">Giriş Yap</custom-button>
            </template>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton';
  import FacebookLogin from '../../body/auth/components/facebookLogin'
  import GoogleLogin from '../../body/auth/components/googleLogin'

  export default {
    name: "src-pages-modals-onboarding-v1",
    components: {
      CustomButton,
      FacebookLogin,
      GoogleLogin
    },
    data() {
      return {
        step:1,
        openLoader: false,
        persona:null,
        calledFrom:null,
        socialButtons:true,
        url:null,

        firstname: null,
        lastname: null,
        username: null,
        email: null,
        password: null,
        disableRegister: false,
        errorInfo: {},

        isUserLogin: false,
        isUserHaveAccount: false,

        loginPassword: "",
        loginEmail: null,
        disableForLogin: false,
        disableForFacebook: false,

        redirectedUrl: null
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      nextStep(){

        if(this.persona && this.step === 1){
          if(this.persona === 'freelancer'){
            this.url = null;
            this.calledFrom = 'onboarding_seller';
            this.$store.state.redirectPreviousURL.path = '/freelancer-olmak'
            this.step++
          } else {
            this.hide();
            this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.BIA_START, info: {calledFrom:'onboarding'}});
          }

        }

        console.log(this.url);

      },

      prevStep(){
        if(this.persona && this.step === 2){
          if(this.persona === 'freelancer'){
            this.socialButtons = true;
            this.step--;
          }
          if(this.persona === 'buyer'){
            this.socialButtons = true;
            this.step--;
          }
        }
      },



      register(firstname, lastname, username, email, password) {
        this.disableRegister = true;
        this.openLoader = true;
        this.api.user.register(firstname, lastname, username, email, password, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query, this.$Progress, this.calledFrom)
          .then(({data}) => {
            this.disableRegister = false;
            let result = data;
            if (result.success) {
              this.$store.commit(this.types.SET_MESSAGE_COMPONENT, result.data.messageComponent);
              this.$store.state.routerMap = result.data.routerMap;
              this.$store.commit(this.types.SET_MODAL_COMPONENT_MAP, result.data.modalComponents);
              this.$store.commit(this.types.SET_USER, result.data.user);
              this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
              this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
              this.trackConversionForRegisteredUsers();

              if (result.visitor_id) {
                window.localStorage.setItem("visitor_id", result.visitor_id);
              }

              if(this.url){
                this.$router.push(this.url);
              } else {
                this.$router.push({ path: result.data.redirect_url, query: result.data.redirect_query });
              }
            } else {
              this.openLoader = false;
              let errorInfo = {};
              result.data.errorInfo.forEach(function (info) {
                errorInfo[info.errorClass] = {
                  message: info.errorMessage
                }
              });
              this.errorInfo = errorInfo;

              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.openLoader = false;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      createUsername() {
        this.username = this.escapeTurkishCharacters((this.firstname ? this.firstname : "") + (this.lastname ? this.lastname : "")).toLowerCase();
      },

      login(email, password) {
        this.disableForLogin = true;
        this.openLoader = true;
        this.api.user.login(email, password, this.$Progress, this.$store.state.redirectPreviousURL.path, this.$store.state.redirectPreviousURL.query, this.calledFrom)
          .then(({data}) => {
            this.disableForLogin = false;
            let result = data;
            if (result.success) {

              if (result.visitor_id) {
                window.localStorage.setItem("visitor_id", result.visitor_id);
                this.$store.commit(this.types.SET_VISITOR_ID, result.visitor_id);
              }

              if(result.data && result.data.user && result.data.user.slog){
                this.$store.state.slog = true;
              }

              let trackingProps = {
                userID: result.data.user.id,
                userUUID: result.data.user.uuid,
                username: result.data.user.username,
                type: 'email',
                page: this.$store.state.componentMap.page
              };

              let forceMerge = {
                userID: result.data.user.id,
                userUUID: result.data.user.uuid,
                username: result.data.user.username,
              };
              this.Helper.trackEvents.customEvent("loggedin", trackingProps, forceMerge);

              this.$store.commit(this.types.SET_USER, result.data.user);
              this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);
              this.$store.state.routerMap = result.data.routerMap;
              this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
              this.$router.push({ path: result.data.redirect_url, query: result.data.redirect_query });
            } else {
              this.openLoader = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(() => {
            this.openLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    computed: {

      getModalInfo() {
        let info =  this.$store.state.activeModal.info;
        if(info && info.url){
          this.url = info.url;
          if(this.url){
            this.step = 2;
            this.persona = 'buyer';
            this.calledFrom = 'onboarding_buyer';
            this.$store.state.redirectPreviousURL.calledFrom = 'onboarding_buyer';
          }
        }
        return true;
      },

      enableButton(){
        return !!this.persona;
      },

      computedFirstname: {
        get() {
          return this.firstname
        },
        set(firstname) {
          this.firstname = firstname;
          this.user.firstname = this.firstname;
          this.errorInfo.firstname = null;
        }
      },

      computedLastname: {
        get() {
          return this.lastname;
        },
        set(lastname) {
          this.lastname = lastname;
          this.user.lastname = this.lastname;
          this.errorInfo.lastname = null;
        }
      },

      computedEmail: {
        get() {
          return this.email;
        },
        set(email) {
          this.email = email;
          this.user.email = this.email;
          this.errorInfo.email = null;
        }
      }
    },

    watch: {
      username() {
        this.errorInfo.username = null;
      },

      password() {
        this.errorInfo.password = null;
      },
    },

    beforeDestroy() {
      this.EventBus.$off("loader")
    },
    created() {
      this.isUserLogin = !!this.user.uuid;

      this.EventBus.$on("loader", payload => {
        this.openLoader = payload;
      });
    }
  }

</script>

<style scoped lang="scss">
  .super-stroked-button{
    margin: 30px auto;
    width: 344px;
    border-radius: 2px;

    border: none;
    background-color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #fd4056;

  }

  .super-stroked-button:hover{text-decoration: underline}

  .modal-container {
    width: 770px !important;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #eaedf2;
    font-family: 'sofia-pro';
    padding: 20px 0 0;
    margin-top: 50px;
    overflow-y: scroll;
    position: relative;
  }

  .modal-mask {
    z-index: 11 !important;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .4);
  }

  .create-area{
    padding: 10px 73px;

    .login-text{
      cursor:pointer;color: #fa4359; text-align: center; font-size: 14px; font-weight: 600; margin-top: 20px;
      &:hover{
        text-decoration: underline;
      }
    }

    .help-text{
      margin-top: 30px;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.85;
      letter-spacing: normal;
      text-align: center;
      color: #8b95a1;
      a{
        font-weight: bold;
        color: #2d3640;
        &:hover{
          text-decoration: underline;
        }
      }
      span{
        cursor: pointer;
        font-weight: bold;
        color: #2d3640;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .title{
      font-size: 32px;
      font-weight: normal;
      text-align: center;
      color: #2d3640;
      span{
        font-weight: 600;
      }
    }
    .sub-title{
      margin: 18px auto 0;
      max-width: 600px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      text-align: center;
      color: #8b95a1;
      span{
        font-weight: 600;
      }
    }


    .main-select-container{
      display: flex;
      margin-top: 60px;
      justify-content: space-between;
      height: 280px;
      .select-img-container{
        transition: all ease-in 0.1s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 260px;
        height: 260px;
        border-radius: 20px;
        border: solid 1px rgba(191, 200, 210, 0.2);
        background-color: #ffffff;
        .select-img{
          width: 160px;
        }
        .select-text{
          font-size: 18px;
          font-weight: normal;
          text-align: center;
          color: #8b95a1;
          margin-top: 24px;
          width: 200px;
        }
        &:hover{
          width: 270px;
          height: 270px;
          border-radius: 20px;
          box-shadow: 0 20px 20px 0 #e9ebf0;
          border: solid 1px #26ca9a;
          background-color: #ffffff;
          .select-text{
            color: #2d3640;
          }
        }
      }

    }

  }

  .foot{
    margin-top: 20px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid rgba(191, 200, 210,0.2);
  }

  label {
    margin-bottom: 0;
  }

  .errorMessage {
    color: #d0011b;
    font-size: 15px;
  }

  .error {
    border: 2px solid #d0011b !important;
  }
</style>
