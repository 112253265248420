<template>
  <div>
    <loader v-if="!pageLoaded" style="margin-top: 40vh;"></loader>
    <template v-else>
      <template v-if="!activatedFilterPage">
        <div class="appBar">

          <img @click="routeBack(computeGoBackLink(), true)"
               style="height: 18px; width: 18px; margin-left: 18px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_mobile_black_back.svg">

          <p class="category-header-title">{{headerInfo.title}}</p>

          <p></p>
        </div>

        <div style="margin: 0 auto; padding-top: 50px;"></div>

        <div style="background-color: #f4f5f7; padding-top: 40px;  padding-bottom: 20px; ">
          <div class="gigContainer">

            <singleGig :impression-u-u-i-d="impressionUUID" :clicked-from="'default'" v-for="(gig, gigi) in gigs" :item="gig" :key="gigi" style="margin-bottom: 25px;"  />

          </div>

          <div v-if="totalCount === 0" style=" margin: 0 auto; text-align: center; padding-bottom: 100px;margin-top:0!important;">
            <div class="notodoitems content-white" style=" margin: 0 auto!important; width:80%;text-align: center;">
              <h4>Sonuç bulunamadı.</h4>
              <div class="hiddendiv"></div>
              <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
                <img style="width: 75px; height: 75px;" src="https://gcdn.bionluk.com/site/general/no-foun-gig.png">
              </span>
              <p class="h1_2" style=" width: 80%; margin: 0 auto; font-size:14px; font-weight:400!important;color: #8b95a1!important;">
                Aradığın hizmeti bulmak için, hızlıca bir istek oluşturabilirsin ve freelancer topluluğumuzdan teklifler alın.
              </p>
            </div>
          </div>

          <div v-if="seemorediv && !seemoreLoading" @click="getAllGigs(offset)"
               style="cursor: pointer;padding-bottom:10px;font-size: 16px; font-weight: 600;text-align: center; color: #2d3640;">
            Daha Fazla
          </div>
          <loader v-if="seemoreLoading"></loader>

        </div>
      </template>

      <mobile-category-filter v-if="activatedFilterPage" @status="activatedFilterPage" @update:status="val => activatedFilterPage = val"
                              :title="headerInfo.title" :slug="headerInfo.links[1].slug"></mobile-category-filter>
      <faqs v-if="faqs[selectedCategory.slug]"  :content-data="{ title: 'Sıkça Sorulan Sorular', accordionData: faqs[selectedCategory.slug] }" />
    </template>
  </div>
</template>

<script>
  import MobileCategoryFilter from "../../../components/mobile/categoryFilter";
  import Loader from "../../../components/toolbox/loader";
  import singleGig from "@/pages/components/singleGig";
  import {
    bindCodeToHtml,
    generateCodeForChildCategory,
    generateCodeForFaq,
    generateCodeForParentCategory
  } from "@/utils/schema";
  import Faqs from "@/pages/components/Faqs";

  export default {
    name: "src-pages-body-marketplace-categories-v2_mobile",
    components: {
      Faqs,
      Loader,
      MobileCategoryFilter,
      singleGig
    },
    data() {
      return {
        gigs: [],
        selectedCategorySlug: null,
        slug: null,
        headerInfo: null,
        duration: null,
        searchTerm: null,
        offset: 0,
        limit: 16,
        seemorediv: null,
        pageLoaded: false,
        seemoreLoading: false,
        totalCount: null,
        text: null,
        font: null,
        screenWidth: window.innerWidth - 32,
        screenHeight: (window.innerWidth - 32) / 1.77,
        activatedFilterPage: null,
        categoryInfo: [],
        orderBy: 1,
        impressionUUID:null,
        selectedCategory: {
          id: null,
          parent_id: 0,
          slug: null
        },
        faqs: {
          'yazi-ceviri': [
            {
              open: false,
              title: 'İçerik yazarı seçerken nelere dikkat etmeliyim?',
              text: 'Freelance içerik yazarı ile çalışmadan önce,  hizmetin türü ve kapsamını netleştirmelisin. Örneğin; web sitesi içeriği ile blog içeriği için ihtiyaç duyacağın hizmet farklı olabilir. Bu nedenle, hangi alanda hizete ihtiyacın  varsa, o alanda uzman içerik yazarı ile  çalışmalısı. İkinci önemli nokta ise SEO uyumlu içerik üretebilen yazarlar ile çalışman.'
            },
            {
              open: false,
              title: 'İçerik türleri  nelerdir?',
              text: 'Dijital pazarlama çalışmaların için ihtiyaç duyabileceğin en popüler içerik türleri:\n' +
                '\n' +
                'Web Sitesi İçeriği\n' +
                'Blog İçeriği \n' +
                'Ürün Tanıtım İçeriği\n' +
                'Ürün karşılaştırması içerikleri\n' +
                'Video senaryosu\n' +
                'Sosyal medya gönderi metni\n' +
                'Liste içerikleri\n' +
                'Marka sloganı & marka ismi\n' +
                'Kampanya sloganı\n' +
                'Reklam kampanya metinleri\n' +
                'Çeviri & Yerelleştirme\n' +
                'Yaratıcı senaryo'
            },
            {
              open: false,
              title: 'İçerik yazarı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Freelance içerik yazarı ile çalışırken en önemli adım net brief vermek. Bu hizmeti hangi platform için alıyorsun,  dikkat edilmesini istediğin  şeyler nedir, markanın  hedef kitlesi kimdir gibi  kilit soruların cevabını içerik yazarına vermekten çekinmemelisin. İçerikler tamamlandıktan  sonra içerik özgünlüğünü ve dilbilgisi kurallarına uygunluğunu da mutlaka kontrol etmelisin.'
            },
            {
              open: false,
              title: 'Web sitesi içeriği oluşturulurken nelere dikkat edilmeli?',
              text: 'Web sitesi içeriği oluşturulurken dikkat edilmesi gereken ana noktalar:\n' +
                '\n' +
                'İçeriklerin web sitesinin amacına yönelik hazırlanması (satış için farklı, ürün açıklamaları için farklı  içeriklerin derlenmesi)\n' +
                'Tüm web sitesi içeriğinin SEO uyumlu olması\n' +
                'Metinlerde ürün/hizmete link verilmesi\n' +
                'Kullanıcıların ihtiyaçlarının adım adım hazırlanarak her adıma uygun içerik sunulması\n' +
                'İçeriğin kolay anlaşılır ve çok uzun olmaması\n' +
                '\n'
            }
          ],
          'internet-reklamciligi': [
            {
              open: false,
              title: 'Dijital pazarlama nedir?',
              text: 'Ürün veya hizmetlerin, tamamen dijital kanallar aracılığıyla ve bu kanalların dinamiklerine uygun olarak gerçekleştiilen pazarlama yöntemlerinin bütününe deniyor. Dijital pazarlama kapsamında; içerik pazarlama, sosyal medya pazarlama, arama motoru optimizasyonu (SEO), sosyal medya reklamları, e-posta pazarlama  gibi birçok alt başlık yer alıyor.'
            },
            {
              open: false,
              title: 'Dijital pazarlamanın  faydaları nedir?',
              text: 'Markalar, dijital pazarlama sayesinde; bilinirlik artırma, satış yapma veya satış artırma, müşteri memnuniyeti sağlama gibi farklı birçok amaca hizmet eden çalışmalar yapabiliyorlar. Dijital  pazarlama aynı zamanda; daha net hedef kitle analizi yapabilmek, bütçeyi verimli kullanmak, müşteri davranışlarını analiz ederek pazarlama faaliyetlerinde / üründe geliştirme sağlamak gibi birçok avantaj sunuyor.'
            },
            {
              open: false,
              title: 'Sosyal medya  uzmanı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Sosyal medya uzmanı ile çalışırken; daha önce içinde yer aldığı çalışmaları incelemeli ve sana,hangi kapsamda ve seviyede, ne tür çözümler sunabileceğini anlatmasını istemelisin.'
            },
            {
              open: false,
              title: 'SEO danışmanı ile çalışırken nelere dikkat etmeliyim?',
              text: 'Bir SEO Danışmanı ile çalışırken; varsa başarıya ulaştığı çalışmaları talep etmeli, oluşturduğu içerikleri kontrol etmeli ve raporlama konusunda nasıl davrandığını bilmelisin.'
            },
            {
              open: false,
              title: 'Nasıl dijital pazarlama hizmeti alabilirim?',
              text: 'Dijital pazarlama hizmeti almak için dijital ajanslarla çalışabilir veya işinde uzman freelancerlar ile çalışabilirsin. Ancak, ajansların çoğu zaman maliyetlerinin yüksek olduğunu, birden çok müşteriyle çalıştıklarını hesaba katmallı ve bu nedenle  dönemsel ihtiyaçların için freelancer\'larla çalışmayı tercih etmelisin.'
            }
          ],
          'grafik-tasarim': [
            {
              open: false,
              title: 'Tasarım brief\'i nasıl verilir?',
              text: 'Tasarım brief\'i verirken; markayı ve projenin temel hedefini net ve detaylı aktarmalısın. Buna ek olarak, talep ettiğin hizmete benzer örnek çalışmala iletmen de süreci kolaylaştırabilir.'
            },
            {
              open: false,
              title: 'Tasarım türleri nelerdir?',
              text: 'Başlıca tasarım türleri; grafik tasarım, moda tasarımı, mühendislik tasarımı, peyzaj ve mimari tasarımdır.'
            },
            {
              open: false,
              title: 'Kurumsal web sitesi tasarımında nelere dikkat edilmeli?',
              text: 'Kurumsal web sitesi tasarımında; web sitesinin yaptığın işi yansıttığına, kişilerin sorularına cevap bulabildiğine, rahatlıkla sayfalar arasında dolaşabildiğine ve seninle kolayca iletişim kurabildiğine dikkat etmelisin.'
            },
            {
              open: false,
              title: 'Kurumsal kimlik ve kartvizit tasarımında kapsam nedir?',
              text: 'Kurumsal kimlik ve kartvizit tasarımında ana unsurlar; logo, slogan ve iletişim bilgilerini içeriyor. Bunların uygulandığı materyaller ihtiyaca göre çeşitlendirilebiliyor.'
            },
            {
              open: false,
              title: 'Ambalaj tasarımında nelere dikkat edilmeli?',
              text: 'Ambalaj tasarımı; kullanıcıların ilk görüşte ürünü anlamalarını sağlamalı, ürünü korumalı ve satın almayı teşvik edici özellikte olmalı.'
            }
          ]
        }
      }
    },
    computed: {
      catDescriptionForMeta() {
        if(this.selectedCategory && this.selectedCategory.description){
          let arr =  this.selectedCategory.description.split('xxxfreelancer');
          if(arr[0] && arr[1]){
            return arr[0] + this.format_tl(this.selectedCategory.user_count)+' freelancer' + arr[1];
          } else {
            return this.selectedCategory.description;
          }
        } else {
          this.selectedCategory.description
        }
      },
    },
    watch: {
      selectedCategorySlug(newValue, oldValue) {
        let category = this.findCategoryBySlugSync(this.selectedCategorySlug)
        this.selectedCategory = category
        if (category) {
          this.categoryName = category.name
          this.prepareCategorySelect(category)

          if (newValue && oldValue && newValue !== oldValue) {
            this.$router.push(`/freelancer-bul/${newValue}`)
          }
        } else {
          this.$router.push(`/freelancer-bul`)
        }
      },
      "routerParams": function (newValue) {
        if (newValue && newValue[0]  && newValue[0] !== "undefined") {
          this.offset = 0;
          this.pageLoaded = false;
          this.seemorediv = false;
          this.selectedCategorySlug = newValue[0]
          let category = this.findCategoryBySlugSync(this.selectedCategorySlug)
          if (!category) {
            return this.$router.push(`/freelancer-bul`)
          } else {
            this.getAllGigs(0)
          }
        }
      }
    },
    methods: {
      format_tl (number, decimals = 0, decPoint = ',', thousandsSep = '.') {

        number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
        var n = !isFinite(+number) ? 0 : +number
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        var s = ''

        var toFixedFix = function (n, prec) {
          if (('' + n).indexOf('e') === -1) {
            return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
          } else {
            var arr = ('' + n).split('e')
            var sig = ''
            if (+arr[1] + prec > 0) {
              sig = '+'
            }
            return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
          }
        }

        // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
        if (s[0].length > 3) {
          s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
        }
        if ((s[1] || '').length < prec) {
          s[1] = s[1] || ''
          s[1] += new Array(prec - s[1].length + 1).join('0')
        }

        return s.join(dec)
      },
      getAllGigs(offset) {
        this.offset = offset;
        if (offset === 0) {
          this.gigs = [];
        } else {
          this.seemoreLoading = true;
        }
        const searchOption = {
          slug: this.selectedCategorySlug,
          limit: this.limit,
          count: true,
          offset,
          orderBy: Number(this.orderBy),
          scoringProfile:'sub_slug',
        }
        this.searchDeeds2(searchOption)
          .then(result => {
            let firstGigImage = '';
            if (result.results && result.results[0]) {
              let firstGig = result.results[0];

              firstGigImage = JSON.parse(firstGig.portfolios)[0].imageRetinaURL
            }
            return this.api.general.getGigLikesV2(result.results.map(res => res.id), this.selectedCategorySlug, firstGigImage,  '')
              .then(async ({ data }) => {
                let likeResult = data;

                result.results.forEach(gig => {
                  let foundLike = likeResult.data.likeStatuses.find(likeStatus => likeStatus.id === gig.id);
                  gig.portfolios = JSON.parse(gig.portfolios)
                  gig.likeStatus = foundLike.likeStatus;
                })

                if (result.count) {
                  this.totalCount = result.count;
                }
                if (result.results.length === 0) {
                  this.totalCount = 0;
                }

                let foundCategory = this.findCategoryBySlugSync(this.selectedCategorySlug);


                this.gigs = [...this.gigs, ...result.results];

                let trackGigObj = [];
                result.results.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'categorylist',
                  placement:'default',
                  info:trackGigObj,
                  cat_id: foundCategory.parent_id ? foundCategory.parent_id : foundCategory.id,
                  sub_cat_id: foundCategory.parent_id ? foundCategory.id : null,
                  selectedTypes: null,
                  title: this.impressionUUID
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);


                this.offset += this.limit;
                this.seemorediv = this.offset < this.totalCount;



                this.prepareHtmlTitleAndDescription(foundCategory);
                await this.prepareHeaderInfo(foundCategory);
                await this.prepareCategorySelect(foundCategory);

                if (offset === 0) {
                  this.Helper.trackEvents.pageView(this.Helper.categoryListPageProps(foundCategory, this.totalCount));
                }

                this.pageLoaded = true;
                this.seemoreLoading = false;
              })
          })
          .catch(() => {
            this.$toasted.global.errorToast({description:  "Bir hata oluştu"})
          })
      },
      computeGoBackLink() {
        if (this.headerInfo.links[0].name === this.headerInfo.title) {
          return "/freelancer-bul/";
        } else {
          return "/freelancer-bul/" + this.headerInfo.links[0].slug + "/";
        }
      },
      prepareHtmlTitleAndDescription(category) {
        this.$store.state.indexHTMLTitle = `${category.name} Freelancer'lar | Bionluk`;
        if(category && category.user_count && category.description && this.catDescriptionForMeta){
          this.$store.state.indexHTMLMetaDescription = this.catDescriptionForMeta;
        } else {
          this.$store.state.indexHTMLMetaDescription = `${category.name} kategorisinde özenle seçilmiş binlerce freelancer ihtiyaçların için seni bekliyor. Hemen iletişime geç, işlerini yaptır ve zamanın sana kalsın.`;
        }
      },

      async prepareHeaderInfo(category) {
        let headerInfo = {};
        if (!category.parent_id) {
          headerInfo.title = category.name;
          headerInfo.links = [{slug: category.slug, name: category.name}];
        } else {
          let foundParentCategory = this.findCategoryByIDSync(category.parent_id)
          headerInfo.title = category.name;
          headerInfo.links = [{slug: foundParentCategory.slug, name: foundParentCategory.name}, {slug: category.slug, name: category.name}];
        }

        this.headerInfo = headerInfo;
      },

      prepareCategorySelect(category) {
        let categorySelect = [];
        if (!category.parent_id) {
          const foundChildCategories = this.categories.filter(({ parent_id }) => parent_id === category.id)
          categorySelect.push({
            name: category.name,
            selected: true,
            slug: category.slug,
            parentId: category.parent_id,
            count: category.count,
            imageInfo:category.sub_cat_info,
            gigCount: category.gig_count,
            userCount: category.user_count,
            hover:false,
            is_new:category.is_new
          });

          foundChildCategories.forEach(category => {
            categorySelect.push({
              name: category.name,
              selected: false,
              slug: category.slug,
              parentId: category.parent_id,
              count: category.count,
              imageInfo:category.sub_cat_info,
              gigCount: category.gig_count,
              userCount: category.user_count,
              hover:false,
              is_new:category.is_new
            })
          })
        } else {
          const foundChildCategories = this.categories.filter(({ parent_id }) => parent_id === category.parent_id)
          let foundParentCategory = this.findCategoryByIDSync(category.parent_id)

          categorySelect.push({
            name: foundParentCategory.name,
            selected: false,
            slug: foundParentCategory.slug,
            parentId: category.parent_id,
            count: category.count,
            imageInfo:category.sub_cat_info,
            gigCount: category.gig_count,
            userCount: category.user_count,
            hover:false,
            is_new:category.is_new
          });

          foundChildCategories.forEach(category => {
            categorySelect.push({
              name: category.name,
              selected: category.id === category.id,
              slug: category.slug,
              parentId: category.parent_id,
              count: category.count,
              imageInfo:category.sub_cat_info,
              gigCount: category.gig_count,
              userCount: category.user_count,
              hover:false,
              is_new:category.is_new
            });
          })
        }

        this.categoryInfo = categorySelect;
      },

      updateLikeStatusForGig(receivedGig) {

        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid, null, this.$store.state.componentMap.page )
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description:  "Bir hata oluştu"});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },

      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

      openFilterModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.GIG_CATEGORY_FILTER, info: {categoryInfo: this.categoryInfo, duration: this.duration  }});
      }
    },
    async created() {
      await this.$store.state.categoriesPromise
      this.impressionUUID = this.Helper.generateUUID();
      this.orderBy = this.Helper.isIndexerBotOrDevelopmentEnv() ? 3 : 1;
      this.selectedCategorySlug = this.routerParams[0];
      let category = this.findCategoryBySlugSync(this.selectedCategorySlug)
      this.selectedCategory = category
      this.EventBus.$on('gigCategoryFilter', payload => {
        this.duration = payload.duration;
        this.searchTerm = payload.searchTerm;
        this.orderBy = payload.orderBy;
        if (payload.searchTerm ? payload.searchTerm.trim().length : false) {
          this.$router.push(`/search?term=${this.searchTerm}&slug=${payload.selectedCategoryInfo}${this.duration ? '&duration=' + this.duration : ''}${this.orderBy ? '&orderBy=' + this.orderBy : ''}`);
        } else {
          if (this.selectedCategorySlug === payload.selectedCategoryInfo) {
            this.offset = 0;
            this.pageLoaded = false;
            this.seemorediv = false;
            this.getAllGigs(0)
          } else {
            this.selectedCategorySlug = payload.selectedCategoryInfo;
          }
        }
      });
      if (!category) {
        return this.$router.push(`/freelancer-bul`)
      } else {
        this.getAllGigs(0)
      }
      if (!category.parent_id) {
        this.prepareCategorySelect(category)
        bindCodeToHtml(JSON.stringify(generateCodeForParentCategory(this.categoryInfo.filter(cat => cat.parentId))))
      } else {
        bindCodeToHtml(JSON.stringify(generateCodeForChildCategory({ ...category, description: this.catDescriptionForMeta })))
      }
      if (this.faqs[category.slug]) {
        const faqs = this.faqs[category.slug].map(faq => ({ question: faq.title, answer: faq.text }))
        bindCodeToHtml(JSON.stringify(generateCodeForFaq(faqs)))
      }
    },
    beforeDestroy() {
      this.EventBus.$off('gigCategoryFilter')
    }
  }
</script>

<style scoped>
  .appBar {
    z-index: 99;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .category-header-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b4f52;
  }

  .gigContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
  }

  .gig-list-container {
    width: 100%;
    height: 173px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    margin-bottom: 30px;
    position: relative;
  }

  .gig-cover-photo-div {
    width: 128px;
    height: 72px;
    position: relative;
    border: solid 1px #dfe4ec;
    margin-right: 11px;
  }

  .gig-like-photo {
    width: 20px;
    height: 19px;
  }


  .gig-title {
    margin-bottom: 5px;
    height: 60px;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: #4b4f52;
    word-break: break-word;
  }

  .gig-info-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  .gig-like-star-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 11px;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .featured-gigs-header{
    position: absolute;
    display: flex;
    align-items: center;
    height: 26px;
    top: -26px;
    left: 0;
    font-size: 12px;
    font-weight: 400;
    color: #FFF;
    background-image: linear-gradient(45deg, #e63262, #fd4056);
    padding: 0 5px;
  }
</style>
