/**
 * Created by haluk on 8/7/17.
 */



const v3 = resolve => require(['./v3.vue'], resolve);
const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);

export default {
  v3,
  v2_mobile
}
