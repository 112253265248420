import axios from 'axios'
import qs from 'qs'
import vue from '../main'

// create an axios instance
const service = axios.create({
  baseURL: '/napi', // url = base url + request url
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
  timeout: 2 * 60 * 1000 // request timeout
})

service.interceptors.request.use(request => {
  request.headers = {
    ...request.headers,
    'SUPER-TOKEN': localStorage.getItem('token') || null
  }

  let visitorId = localStorage.getItem('visitor_id')
  if (visitorId) {
    request.headers['SUPER-VISITOR'] = visitorId
  }

  if (!sessionStorage.getItem('referrer')) {
    sessionStorage.setItem("referrer", document.referrer)
    sessionStorage.setItem("temp_referrer", document.referrer)
    request.headers['SUPER-REFERRER'] = document.referrer
  }
  return request
})

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data
  },
  async error => {
    const message = error.response.data && error.response.data.message ? error.response.data.message : error.message
    console.log('err ' + message, error.response) // for debug
    if (!error.response) {
      // maybe we would like to track server errors
      // pageHelper.trackEvents.pageView({err: JSON.stringify(err), to: to.path, from: from.path}, "errorpage", "v1");
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      vue.$toasted.global.errorToast({ description: 'Oturumun sona erdi' })
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('visitor_id')
      window.location.reload(true)
    } else if (error.response.status >= 500) {
      // maybe we would like to track server errors
      // pageHelper.trackEvents.pageView({err: JSON.stringify(err), to: to.path, from: from.path}, "errorpage", "v1");
      // vue.$toasted.global.errorToast({ description: vue.Constants.TEXTS.SERVER_ERROR_MESSAGE })
      // await vue.$router.push('/500')
    }
    return Promise.reject(error.response)
  }
)

export default service
