<template>

  <div v-if="size && size === 'modal'" class="app-box-modal">
    <div style="padding: 20px 10px 10px; text-align: center">
      <template v-if="action && action === 'afterSendMessage'">
        <p class="varv" style="color: #2d3640;font-size:24px;">Canlı Mesajlaşma Bionluk Mobil Uygulaması'ında!</p>
        <p class="varv-text" style="font-size: 14px;"> Mesajın gönderildi ancak <span style="font-weight: 600; text-decoration: underline">anlık olarak</span> sana gelen mesajları <span style="font-weight: 600">sadece mobil uygulamamız üzerinden </span>görebilirsin.</p>
      </template>
      <template v-if="action && action === 'initMessageList'">
        <p class="varv" style="color: #2d3640;font-size:24px;">Canlı Mesajlaşma Bionluk Mobil Uygulaması'ında!</p>
        <p class="varv-text" style="font-size: 14px;"><span style="font-weight: 600; text-decoration: underline">Anlık olarak</span> sana gelen mesajları <span style="font-weight: 600"> mobil uygulamamız üzerinden </span>görebilirsin.</p>
      </template>
    </div>

    <a :href="appUrl">
      <img style="width: 100px; " src="https://gcdn.bionluk.com/site/cicons/png/mockup-4%402x.png"/>
    </a>

    <a :href="appUrl">
      <img v-if="deviceType === 'ios'" style="width: 182px; margin-top: 20px;" src="https://gcdn.bionluk.com/site/cicons/png/app-store-badge%403x.png"/>
      <img v-else style="width: 182px; margin-top: 20px;" src="https://gcdn.bionluk.com/site/cicons/png/play-store-badge%402x.png"/>
    </a>
  </div>
  <div v-else class="app-box">
    <div style=" padding-left: 20px; padding-right: 20px; text-align: center">
      <p class="varv" style="color: #2d3640;font-size:36px;">Bu işlem sadece Bionluk Mobil Uygulaması'nda!</p>
      <p class="varv-text">Sana daha iyi bir mobil deneyim sunmak için mobil uygulamalarımızı sürekli iyileştiriyoruz. Bu işlemi sadece mobil uygulamaların üzerinden yapabilirsin. </p>
    </div>

    <a :href="appUrl">
      <img style="width: 120px; margin-top: 20px;" src="https://gcdn.bionluk.com/site/cicons/png/mockup-4%402x.png"/>
    </a>

    <a :href="appUrl">
      <img v-if="deviceType === 'ios'" style="width: 182px; margin-top: 20px;" src="https://gcdn.bionluk.com/site/cicons/png/app-store-badge%403x.png"/>
      <img v-else style="width: 182px; margin-top: 20px;" src="https://gcdn.bionluk.com/site/cicons/png/play-store-badge%402x.png"/>
    </a>
  </div>

</template>

<script>


  export default {
    props: ["size","action"],
    data() {
      return {
        appUrl: '',


      };
    },

    created() {
      if(this.deviceType === 'ios'){
        this.appUrl = 'https://apps.apple.com/tr/app/bionluk/id1330144140?l=tr'
      } else {
        this.appUrl = 'https://play.google.com/store/apps/details?id=com.bionluk.androidapp&hl=en'
      }

    }
  }
</script>

<style scoped lang="scss">

  @import url("https://use.typekit.net/nnl3sxv.css");

  .varv{

    font-family: verveine, sans-serif;

    font-weight: 400;

    font-style: normal;
  }

  .varv-text {
    margin-top:20px;
    font-size: 15px;
    line-height: 1.64;
    color: #2d3640;
  }

  .app-box{
    height: 100vh;
    display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: #f4f5f7 !important;
  }

  .app-box-modal{
    height: 100%;
    margin-top: 30px;
    display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: #f4f5f7 !important;
  }

</style>
