<template>
  <div style=" margin:0 auto; width:90%;">
    <div class="hiddendiv"></div>
    <facebook-login v-if="false" :activateLink="false"></facebook-login>
    <div class="hiddendiv20"></div>
    <google-login :activateLink="false"></google-login>


    <div class="hiddendiv20"></div>

    <div class="or">
      <span>veya</span>
    </div>
    <div class="hiddendiv"></div>

      <div class="social-button" style="width: 90%; margin: 0 auto; text-align: center;">
        <button class="super-stroked-button" @click="registerWithEmail()">e-posta ile kayıt ol</button>
      </div>

      <div class="hiddendiv"></div><div class="hiddendiv10"></div>
      <div style="width: 100%; margin: 0 auto; text-align: center">
      <div style="font-size: 13px;  margin: 0 auto;">
        Kaydol'a basarak
        <router-link :to="`${prefixPath}pages/kullanici-sozlesmesi`">kullanıcı sözleşmesi</router-link> ve <router-link :to="`${prefixPath}pages/gizlilik`">gizlilik sözleşmesi</router-link>ni kabul etmiş sayılırsın.
      </div>

      <div class="hiddendiv20"></div>
      <p class="subheading2-text" style="font-weight: 500"> Zaten bir hesabın var mı? <router-link :to="`/login`">Giriş Yap</router-link></p>
      </div>
    </div>
</template>

<script>

  import mixin from '../mixin';

  export default {
    name: "src-pages-body-auth-register-mobile-v1_mobile",
    mixins: [mixin],
    data() {
      return { }
    },

    methods: { }
  }
</script>

<style scoped>

  .super-stroked-button{

    border: none;
    background-color: white;

    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #fd4056;

  }

  .super-stroked-button:hover{background: white!important;}

  input{
    width: 270px;

  }

  .social-button{width: 270px!important;}
  form{
    text-align: center;
  }


</style>
