/**
 * Created by haluk on 4/17/17.
 */



import v3 from './v3.vue';


export default {

  v3
}
