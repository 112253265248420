/**
 * Created by haluk on 6/15/17.
 */
const v2 = resolve => require(['./v2.vue'], resolve);
import v4_mobile from './v4_mobile.vue';

export default {
  v2,
  v4_mobile
}
