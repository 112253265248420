<template>
    <div class="helpbox-body">
      <img class="helpbox-image" src="https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg"/>
      <p class="helpbox-header" v-if="header">{{header}}</p>
      <p class="helpbox-title" v-if="title">{{title}}</p>
      <div class="helpbox-description" v-html="description"></div>
    </div>
</template>

<script>

    export default {
      name: 'HelpBox',
      props: {

        header: {
          type: String,
          default: '',
        },
        title: {
          type: String,
          default: '',
        },
        description: {
          type: String,
          default: '',
        }
      },
        data() {
            return {}
        }

    }

</script>

<style scoped lang="scss">

  .helpbox-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
  }

  .helpbox-image {
      display: none;
    position: absolute;
    top: -20px;
    left: 50%;
    right: 50%;
    height: 30px;
    width: 20px;
  }

  .helpbox-header {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
  }

  .helpbox-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;

  }

  .helpbox-description {
    text-align: left;

  }
</style>
