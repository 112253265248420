/**
 * Created by haluk on 4/17/17.
 */

const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);
const v2 = resolve => require(['./v2.vue'], resolve);

export default {
  v2_mobile,
  v2
}
