<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="modal-inner">
          <p class="modal-header">Dahili Numarayı <span>Değiştir</span></p>
          <p class="modal-text">
            <span>{{$store.state.user.info.f_t_line}} olan</span> dahili numaranı değiştirmek üzeresin. Sistem otomatik olarak yeni bir dahili numara atayacak.
            <br/>Bu işlemi<span> 24 saat içinde </span>sadece bir defa yapabilirsin.
          </p>

          <div class="italic-warning">
            <div class="dash"></div>
            <p class="italic-warning-text">devam etmek istiyor musun?</p>
            <div class="dash"></div>
          </div>
          <custom-button button-type="red" style="width: 220px; margin-top: 30px;" :disabled="waitUntilRequestDone" @click="changeLine()">Dahili Numaranı Değiştir</custom-button>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton'

  export default {
    name: "src-pages-modals-changeLine-v1",
    components: {
      CustomButton,
    },
    data() {
      return {
        openedModal: false,
        waitUntilRequestDone: false,
      }
    },

    methods: {
      hide() {
        if (!this.waitUntilRequestDone) this.$store.commit(this.types.DISABLE_MODAL);
      },

      changeLine() {
        this.waitUntilRequestDone = true;
        this.api.user.changeLine(this.$store.state.user.info.f_t_line, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.user.info.f_t_line = result.data.f_t_line;
              this.$toasted.global.infoToast({description: result.message});
              this.waitUntilRequestDone = false;
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.waitUntilRequestDone = false;
            }
          });
      }

    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 140px 50px 140px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }

  .modal-text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 20px;
    color: #2d3640;

    span {
      font-weight: 800;
    }
  }

  .italic-warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    .italic-warning-text {
      font-weight: 400;
      font-style: italic;
      font-size: 12px;
      color: #8b95a1;
      margin-left: 12px;
      margin-right: 12px;
    }
    .dash {
      width: 20px;
      border-bottom: 1px solid #8b95a1;
    }
  }


</style>
