/**
 * Created by haluk on 4/17/17.
 */

import main from './main';
import error from './error/page-not-found';
import serverError from './error/server-error';
import login from './auth/login';
import register from './auth/register';
import register_with_email from './auth/registerWithEmail';
import non_activated_user from './auth/nonactivateduser';
import activation from './auth/activation';
import forgotpasswordpage from './auth/forgotpasswordpage';
import settings from './settings/mainpage';
import settings_notification from './settings/notification';
import settings_business from './settings/business';
import settings_finance from './settings/finance';
import settings_security from './settings/security';
import feedNotifications from './feed/notifications';
import feedToday from './feed/today';
import workstation from './workstation/dashboard';
import workstationMessages from './workstation/messages';
import workstationMessageDetails from './workstation/messages/detail';
import workstationOrders from './workstation/orders/list';
import workstationOrdersDetail from './workstation/orders/detail';
import workstationRequests from './workstation/requests/list';
import workstationFavorites from './workstation/favorites';
import workstationFavoritesDetail from './workstation/favorites/detail';
import workstationFinance from './workstation/finance';
import workstationWallet from './workstation/wallet';
import workstationTodo from './workstation/todo';
import bicoin from './workstation/bicoin';
import editor from './workstation/editor';
import basvuru from './workstation/basvuru';
import gigsList from './workstation/gigs/list';
import gigsCreate from './workstation/gigs/create';
import portfolioCreate from './workstation/portfolio';
import portfolioEdit from './workstation/portfolio';
import gigsDetail from './workstation/gigs/detail';
import gigsEdit from './workstation/gigs/edit';

import workstationBuyerRequests from './workstation/buyerRequests';
import workstationPortfolio from './workstation/portfolio';
import workstationRequestEdit from "./workstation/requests/edit";
import marketplace from './marketplace/dashboard/index';

import changeEmail from './auth/change_email';
import staticPage from './static-contents/staticpages';
import blogList from './static-contents/blog/list';
import blogDetail from './static-contents/blog/detail';

import supportCategoryList from './static-contents/support/categoryList';
import supportList from './static-contents/support/list';
import supportDetail from './static-contents/support/detail';


import changePassword from './auth/changePassword';
import tickets from './tickets/ticketList';
import ticketDetail from './tickets/ticketDetail';
import profile from './profile';
import checkout from './checkout';

import categories from './marketplace/categories';
import search from './search';

import showcaseList from './showCase/list';
import showcaseDetail from './showCase/detail';
import showcaseAttachment from './showCase/attachment';


import hirefreelancerMain from './bia/detail';


import onboardingFreelancer from './onboarding/freelancer';
import onboardingMain from './onboarding/main';
import workstationRequestOffers from './workstation/requests/offers';



import usersearch from './user-search';


import sales from './workstation/sales/list';
import pro from './main';
import pro_mobile from './main';
import for_freelancers_mobile from './main';
import mainBicoin from './main';
import mainBicoin_mobile from './main';
import portfolio from './portfolio';
import go from './marketplace/goLanding';
import sl from './marketplace/specialLanding';
import liste from './marketplace/liste';
import collection from './marketplace/collection';

import blogImage  from './static-contents/blog/image';
import contest from './contest'
import survey from './survey'


//mobile components
import mprofile from './mprofile';
import iyzi3ds from './iyzi3ds';

export default {
  mainpage: main,
  errorpage: error,
  serverError,
  login,
  register,
  register_with_email,
  non_activated_user,
  activation,
  change_email: changeEmail,
  forgot_password: forgotpasswordpage,
  settings,
  settings_notifications: settings_notification,
  settings_business,
  settings_finance,
  settings_security,
  workstation,
  workstation_messages: workstationMessages,
  workstationMessageDetails,
  workstation_orders: workstationOrders,
  workstationOrdersDetail,
  workstation_requests: workstationRequests,
  workstation_favorites: workstationFavorites,
  workstation_favorites_detail: workstationFavoritesDetail,
  workstation_finance: workstationFinance,
  workstation_wallet: workstationWallet,
  workstation_todo: workstationTodo,
  workstationBuyerRequests,
  workstationPortfolio,
  bicoin,
  editor,
  basvuru,
  gigsCreate,
  portfolioCreate,
  portfolioEdit,
  gigsList,
  gigsEdit,
  gigsDetail,
  feed_notifications: feedNotifications,
  feedToday: feedToday,
  marketplace,
  chat: workstationMessages,
  staticpages: staticPage,
  blogList,
  blogDetail,
  supportList,
  supportCategoryList,
  supportDetail,
  change_password: changePassword,
  tickets,
  ticketDetail,
  profile,
  checkout,
  categories,
  search,
  showcaseList,
  showcaseDetail,
  showcaseAttachment,
  hirefreelancerMain,
  mprofile,
  onboardingFreelancer,
  onboardingMain,
  workstation_request_offers: workstationRequestOffers,
  workstation_request_edit: workstationRequestEdit,
  usersearch,
  iyzi3ds,
  sales,
  pro,
  pro_mobile,
  for_freelancers_mobile,
  mainBicoin,
  mainBicoin_mobile,
  portfolio,
  go,
  sl,
  blogImage,
  liste,
  collection,
  contest,
  survey
}
