<template>
  <div @click="changeInput" :class="checked ? 'slider active-slider' : 'slider'">
    <div :class="disabled ? 'slider-button disabled-button ' : checked ? 'slider-button active-button' : 'slider-button'"></div>
  </div>
</template>

<script>

  export default {
    name: 'CustomSwitch',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
	    disabled: {
		    type: Boolean,
		    default: false,
	    }
    },
    data() {
      return {
        checked: this.value
      }
    },

    methods: {
      changeInput(e) {
      	if(!this.disabled){
		      this.checked = !this.checked;
		      this.$emit('input',this.checked);
		      this.$emit('change', this.checked)
        }
      }
    },

    watch: {
      value(newVal , oldVal) {
        this.checked = newVal;
      }
    }

  }

</script>

<style scoped lang="scss">

  .slider {
    position: relative;
    box-sizing: border-box;
    width: 50px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 40px;
    border: 2px solid #ebebeb;
    background-color: #fff;
  }

  .slider-button {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #8b95a1;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .active-slider {
    border: none;
    background-color: #00a575;
  }

  .active-button {
    background-color: #fff;
  }

  .disabled-button {
    cursor: crosshair;
    background-color: #ccc;
  }

  .active-slider  .slider-button {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .active-slider:hover {
    box-shadow: 0 0 1px #00a575;
  }

</style>
