/**
 * Created by haluk on 8/3/17.
 */

const v1 = resolve => require(['./v2.vue'], resolve);
const v2 = resolve => require(['./v2.vue'], resolve);
const v1_mobile = resolve => require(['./v1_mobile.vue'], resolve);

export default {
  v1_mobile,
  v2,
  v1
}
