/**
 * Created by haluk on 4/17/17.
 */


import v2 from './v2.vue';
import v2_mobile from './v2_mobile.vue';

export default {
  v2,
  v2_mobile
}
