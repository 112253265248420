<template>
  <div>
    <div class="register"  v-loader-screen="disableRegister">
      <div style="margin: 0 auto; width: 1170px; text-align: center">
        <div class="hiddendiv"></div>
        <div class="hiddendiv"></div>

        <template v-if="!user.email">
          <div class="subheading1-text">
            <facebook-login v-if="false" style="display: inline-block!important; margin-left: -18px; cursor: pointer;" :activateLink="true"></facebook-login>
            veya
            <google-login style="display: inline-block!important; margin-left: -10px; cursor: pointer;" :activateLink="true"></google-login>
            ile kaydolun.
          </div>
        </template>
        <template v-if="user.email">
          <span class="subheading2-text">Son bir adım daha kaldı. Kullanıcı adı ve şifre belirleyerek kaydı tamamlayabilirsin.</span>
        </template>

        <div class="hiddendiv"></div>
        <div class="form-area">

          <div class="hiddendiv"></div>
          <form @submit.prevent="register(firstname, lastname, username, email, password)">
            <div style="width: 400px; margin: 0 auto; text-align: left">
              <div style="display: flex; text-align: left;">
                <div style="flex: 1">
                  <input :class="{ error: errorInfo.firstname }" type="text" placeholder="İsim" v-model="computedFirstname"
                         style="width: 155px; font-size: 16px; padding-left: 10px;">
                  <p v-if="errorInfo.firstname" class="errorMessage" style="width: 155px;">{{ errorInfo.firstname.message }}</p>
                </div>
                <div style="flex: 1">
                  <input :class="{ error: errorInfo.lastname }" type="text" placeholder="Soy İsim" v-model="computedLastname"
                         style="width: 155px; font-size: 16px; padding-left: 10px;">
                  <p v-if="errorInfo.lastname" class="errorMessage" style="width: 155px;"> {{ errorInfo.lastname.message }} </p>
                </div>
              </div>

              <div class="hiddendiv10"></div>
              <label style="font-size: 12px; text-align: left; padding-left: 5px; margin-bottom: 5px; color: #8b95a1;">bionluk.com/<span
                style="color: #2d3640;font-weight: 600;">{{username}}</span>
              </label>
              <div style="display: flex; align-items: center">
                <input :class="{ error: errorInfo.username }" @focus="createUsername()" type="text" placeholder="Kullanıcı Adı"
                       v-model="username" style=" width:350px;font-size: 16px; padding-left: 10px;">
                <div :data-balloon="'Bionluk\'taki kullanıcı adını istisnai durumlar haricinde değiştiremiyoruz. Bu nedenle sıkılmayacağın bir kullanıcı adı belirlemeni öneriyoruz. 😉'"
                     data-balloon-pos="up"
                     :data-balloon-visible="openBankTooltip"
                     @mouseover="openBankTooltip = false"
                     data-balloon-length="medium" class="seller-type-text-body">
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>

                </div>
              </div>

              <p v-if="errorInfo.username" class="errorMessage" style="width:350px;">{{ errorInfo.username.message }}</p>

              <div class="hiddendiv20"></div>
              <input :class="{ error: errorInfo.email }" type="text" placeholder="E-mail" v-model="computedEmail"
                     style="width:350px;font-size: 16px; padding-left: 10px;">
              <p v-if="errorInfo.email" class="errorMessage" style="width:350px;">{{ errorInfo.email.message }}</p>

              <div class="hiddendiv20"></div>

              <input :class="{ error: errorInfo.password }" type="password" maxlength="16" placeholder="Şifre" v-model="password"
                     style="width:350px;font-size: 16px;  padding-left: 10px;">
              <p v-if="errorInfo.password" class="errorMessage" style="width:350px;">{{ errorInfo.password.message }}</p>

              <div class="hiddendiv20"></div>
            </div>
            <div class="hiddendiv10"></div>

            <div style="font-size: 13px; line-height: 16px; width: 500px; margin-left: 0;  padding: 0">
              Kaydol'a basarak
              <a target="_blank" :href="`${prefixPath}pages/kullanici-sozlesmesi`">Kullanıcı Sözleşmesi</a> ve <a target="_blank" :href="`${prefixPath}pages/gizlilik`">Gizlilik
              Sözleşmesini</a> kabul ediyorum.
            </div>
            <div class="hiddendiv"></div>
            <button :disabled="disableRegister" type="submit" class="super-button-green">Kayıt Ol</button>
          </form>

        </div>


        <div class="hiddendiv20"></div>


        <div class="hiddendiv20"></div>
        <p class="subheading2-text" style="font-weight: 500">Zaten bir hesabın var mı?
          <router-link :to="'/login'">Giriş Yap</router-link>
        </p>
      </div>

    </div>
  </div>
</template>

<script>

  import mixin from '../mixin';

  export default {
    name: "src-pages-body-auth-registerWithEmail-desktop-v1",
    mixins: [mixin],

    data() {
      return {
        openBankTooltip:true
      }
    },

    methods: {},

    computed: {}
  }

</script>

<style lang="scss" scoped>

.hint-skill-icon /deep/ {
  width: 16px;
  height: 16px;
  margin-left: 5px;

g {
  fill: #5e6b79;
}
}

  .hiddendiv {
    height: 30px;
    visibility: hidden;
  }

  .hiddendiv20 {
    height: 20px;
    visibility: hidden;
  }

  .form-area {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
  }



  .form-area a {
    text-decoration: none;
    color: #37414b;
  }

  form {
    text-align: center;
  }

  button.signUp {

    width: 158.7px;
    height: 44px;
    margin-top: 12px;
    border-radius: 2px;
    border: none;
    background-color: #2d3640;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;

  }

  .error {
    border: 2px solid #d0011b !important;
  }

  .errorMessage {

    color: #d0011b;
    font-size: 15px;
  }


</style>
