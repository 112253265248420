<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll; background-color: rgba(0, 0, 0, .8);">
      <div class="modal-container" v-if="getModalInfo" @click.stop>
        <div class="close-div">
          <img  @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
                src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>
        <div class="use-bicoin-modal-container">
          <div class="use-bicoin-modal-header">
            bicoin'lerini kullan!
          </div>

          <div class="hiddendiv"></div>


          <div class="use-bicoin-modal-items" >
            <div class="use-bicoin-modal-item-detail" v-if="!opennedGigUUID" style="display: none">
              <div class="use-bicoin-modal-item-header">
                <label style="font-size: 14px;">Benim İşim Acil! ekstra dakika
                  <div data-balloon="Benim İşim Acil! bölümündeki haftalık dakikana 1 sene boyunca ekstra dakika
yüklenir. Örneğin 60 dakikalık paket satın alırsan, 1 sene boyunca, her hafta,
Benim İşim Acil! bölümünde 300 dakika yerine, 360 dakikan olur."
                       data-balloon-pos="down"
                       data-balloon-length="medium"
                       data-balloon-break
                       style="display: inline-flex">
                    <img :src="$root.CDN_SITE + 'icon/tansel_info_gray.svg'">
                  </div>
                </label>
                <select v-model="currency" class="super-drop" style="background-position-x: 312px; width: 330px">
                  <option :value="600"> 1 sene boyunca ekstra 60 dakika (600 bicoin)</option>
                  <option :value="300"> 1 sene boyunca ekstra 30 dakika (300 bicoin)</option>
                </select>
              </div>
              <div class="use-bicoin-modal-item-button-container">
                <label style="font-size: 14px; height: 13px;"></label>
                <button :disabled="disableButton" class="super-button-green" style="width: 120px;" @click="useBicoin(4)">
                  Satın Al ( {{ currency }} bicoin )
                </button>
              </div>
            </div>

            <div class="use-bicoin-modal-item-detail">
              <div class="use-bicoin-modal-item-header">
                <label style="font-size: 14px;">Kategori sayfalarında iş ilanını öne çıkar
                  <div data-balloon="Kategori ve alt kategori sayfalarında seçeceğin iş ilanı 24 saat boyunca
'Öne Çıkanlar' bölümünde sabit olarak gözükür.
Sadece aktif durumdaki iş ilanlarını öne çıkarabilirsin."
                       data-balloon-pos="left"
                       data-balloon-length="medium"
                       data-balloon-break
                       style="display: inline-flex">
                    <img :src="$root.CDN_SITE + 'icon/tansel_info_gray.svg'">
                  </div>
                </label>

                <select :disabled="!userGigs.length" v-model="selectedGigID" class="super-drop" style="background-position-x: 312px; width: 330px">
                  <option v-if="!userGigs">Bekleyin</option>
                  <option v-else v-for="gig in userGigs" :value="gig.id">
                    {{ gig.title }}
                  </option>
                </select>
              </div>
              <div class="use-bicoin-modal-item-button-container">
                <label style="font-size: 14px; height: 13px;"></label>
                <button :disabled="!userGigs.length" class="super-button-green" style="width: 120px;" @click="useBicoin(7, selectedGigID)">
                  Kullan ( 500 bicoin )
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
  import { getHours } from 'date-fns'

  export default {
    name: "src-pages-modals-useBicoin-v1",
    data () {
      return {
        disableButton: false,
        currency: 600,
        userGigs: [],
        selectedGigID: null,
        nowHour: null,
        reservationSelectedGigID: null,
        selectedReservationTime: null,
        opennedGigUUID: null
      }
    },
    methods: {
      useBicoin(type, value) {
        this.disableButton = true;
        const payload = {}
        let newvalue;
        if (type === 4) {
          newvalue = this.currency / 10;
        }
        if (type === 7) {
          newvalue = value;
          payload.gigId = value
        }
        this.api.seller.useBicoin(type, newvalue, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.disableButton = false;

            if (result.success) {
              this.$toasted.show(result.message);
	            this.user.info.total_bicoin = result.data.totalBicoin;
              payload.response = result.data
              this.EventBus.$emit("successBicoinTransaction", payload);
              this.hide();
            } else {
              this.$toasted.global.errorToast({description:result.message});
            }
          })
          .catch(err => {

            this.$toasted.error(err);
          })
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      }
    },

    created() {
      this.nowHour = getHours(new Date())
      this.selectedReservationTime = this.nowHour + 1;
      this.api.seller.getBicoinGigs(this.$Progress)
        .then(({data}) => {
          let result = data;
          if(result.data.gigs.length > 0 ){
            this.userGigs = result.data.gigs;
            this.userGigs.map( g => {
              if(g.uuid === this.opennedGigUUID) {
                this.selectedGigID = g.id;
              }
            });
            if (!this.selectedGigID) {
              this.selectedGigID = result.data.gigs[0].id;
            }
          }
        })
        .catch(err => {
          this.$toasted.error(err);
        })
    },

    computed: {
      getModalInfo() {
        let info =  this.$store.state.activeModal.info;
        if (info) {
          this.opennedGigUUID = info.uuid;
        }
        return true;
      },
    }
  }

</script>

<style scoped lang="scss">
  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }


  .modal-container{
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 640px;
    max-height: 400px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    overflow-y: scroll;
    position: relative;
  }

  .use-bicoin-modal-container {
    padding: 30px;
  }

  .use-bicoin-modal-header{
    color: #000;
    font-size: 20px;
  }

  .use-bicoin-modal-items{
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }

  .use-bicoin-modal-item-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .use-bicoin-modal-item-header{
    color: #000;
  }

  .use-bicoin-modal-items-sub-header{
    color: #a1a9a9;
    padding-left: 10px;
  }

  .use-bicoin-modal-item-button{
    border-radius: 2px;
    background-color: #26ca9a;
    color: #FFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height:50px;
    font-size: 14px;
  }
</style>
