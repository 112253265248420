<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="modal-inner">
          <p class="modal-header">E-Oturumu <span>Kapat</span></p>
          <img src="https://gcdn.bionluk.com/site/c/ic-logout.svg" onload="SVGInject(this)" class="logout-img" style="margin-bottom: -2px;"/>
          <p class="modal-text-header">Tüm Cihazlarda çıkış yapmak üzeresin</p>
          <p class="modal-text">
            Aşağıdaki alana şifreni girerek Bionluk’a giriş yapmış olduğun tüm cihazlarda oturumu sonlandırmış olacaksın.
          </p>
          <custom-input label="Geçerli Şifren" inputType="password" id="sessionPassword" name="sessionPassword" v-model="sessionPassword" style="width: 393px; margin-top: 45px;" autocomplete="new-password"/>

          <div class="button-body">
            <custom-button button-type="border" style="width: 120px;" @click="hide()">İptal</custom-button>
            <custom-button button-type="green" style="width: 200px; margin-left: 30px;" :disabled="waitUntilRequestDone || sessionPassword.length < 6" @click="closeSession()">Devam Et</custom-button>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton'
  import CustomInput from '../../components/CustomInput'

  export default {
    name: "src-pages-modals-closeSession-v1",
    components: {
      CustomButton,
      CustomInput
    },
    data() {
      return {
        openedModal: false,
        waitUntilRequestDone: false,
        sessionPassword: ''
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      closeSession() {
        this.waitUntilRequestDone = true;
          this.api.user.deleteSession('all', this.sessionPassword)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.EventBus.$emit('closeAllSessions', true);
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.waitUntilRequestDone = false;
          }).catch(err => {
            this.waitUntilRequestDone = false;
          if (err.code !== "ECONNABORTED") {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        });
      },


    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 140px 50px 140px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }

  .modal-text-header {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
    color: #2d3640;
  }

  .modal-text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
    color: #5e6b79;
  }

  .button-body {
    display: flex;
    margin-top: 70px;
  }

  .logout-img /deep/{
    width: 44px;
    height: 50px;
    path {
      fill: #fd4056;
    }
  }

</style>
