/**
 * Created by haluk on 7/31/17.
 */


import GoogleLogin from "../components/googleLogin.vue";
import FacebookLogin from "../components/facebookLogin.vue";


export default {
  components: {
    FacebookLogin,
    GoogleLogin
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      password: ""
    }
  },

  methods: {
    registerWithEmail() {
      this.$store.commit(this.types.SET_USER_CHANGES, {key: 'firstname', value: null});
      this.$store.commit(this.types.SET_USER_CHANGES, {key: 'lastname', value: null});
      this.$store.commit(this.types.SET_USER_CHANGES, {key: 'email', value: null});
      this.$router.push('/register_with_email');
    }
  }
}
