<template>
  <div class="nav">
    <div class="nav-menutext"></div>
    <div v-if="isLoggedIn" class="nav-items" style="">

      <ul class="nav-list">
        <li class="nav-list-item">
          <router-link :to="'/benim-isim-acil'" class="bia-button" active-class="active-bia">
            <div class="nav-list-item-detail">
              <img class="nav-list-item-detail-icon" :src="$root.CDN_SITE+ 'icon/tansel_bia_active.svg'">
              <span class="nav-list-item-detail-text">Benim İşim Acil!</span>
            </div>
          </router-link>
        </li>

        <li>
          <router-link :to="'/bugun'" active-class="active" exact style="position:relative" >
            <p style="position: absolute; margin-top: 4px; margin-left: 35px;">Bugün</p>
            <!--<span class="nav-list-new">Yeni</span>-->
            <img style="height: 22px; padding-left: 3px;" :src="$root.CDN_SITE + 'icon/icon_today_gfg.svg'">
          </router-link>
        </li>

        <li>
          <router-link :to="'/feed'" active-class="active" exact>
            <p style="position: absolute; margin-top: 4px; margin-left: 35px;">Akış</p>
            <img style="height: 18px;" :src="$root.CDN_SITE + 'icon/ic_home_gray.svg'">
          </router-link>
        </li>


        <li>
          <router-link :to="'/panel/hatirlatma'" active-class="active">
            <p style="position: absolute; margin-top: 3px; margin-left: 35px;">Yapılacaklar</p>
            <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_assignment_turned_in_gray.svg">
            <span v-show="user.todoCount" class="nav_todoCount oval-number">{{ user.todoCount }}</span>
          </router-link>
        </li>

        <li>
          <router-link :to="messageRedirectUserName" active-class="active">
            <p style="position: absolute; margin-top: 1px; margin-left: 35px;">Mesajlarım</p>
            <img style="height: 20px;" :src="$root.CDN_SITE + 'icon/ic_commentt_gray.svg'">
            <span v-show="user.unreadMessageCount" class="nav_unreadMessages oval-number">{{ user.unreadMessageCount }}</span>
          </router-link>
        </li>

        <li>
          <router-link :to="'/panel/siparisler'" active-class="active">
            <p style="position: absolute; margin-top: 4px; margin-left: 35px;">Aldıklarım</p>
            <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_shopping_basket_gray.svg">
          </router-link>
        </li>


        <li>
          <router-link :to="'/panel/istekler'" active-class="active">
            <p style="position: absolute; margin-top: 2px; margin-left: 35px;">Özel Alıcı İsteklerim</p>
            <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_add_circle_outline_gray.svg">
            <span v-show="user.requestCount" class="navrequestCount oval-number">{{ user.requestCount }}</span>
          </router-link>
        </li>


        <li>
          <router-link :to="'/panel/listelerim'" active-class="active">
            <p style="position: absolute; margin-top: 2px; margin-left: 35px;">Favorilerim</p>
            <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_favorite_gray.svg">
            <span v-show="user.likeCount" class="nav_likeCount oval-number">{{ user.likeCount }}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="'/panel/finans'" active-class="active">
            <p style="position: absolute; margin-top: 2px; margin-left: 35px;">Finans</p>
            <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_account_balance_gray.svg">
          </router-link>
        </li>
      </ul>
      <div class="hiddendiv20"></div>


      <template v-if="user.seller.isSeller">
        <div class="nav-menutext">FREELANCER</div>
        <ul class="nav-list">


          <li>
            <router-link :to = "'/panel/bicoin'" active-class="active" style="position: relative">
              <p style="position: absolute; margin-top: 3px; margin-left: 35px;">bicoin Hesabım</p>
              <span class="nav-list-new">Yeni</span>
              <img style="height: 22px;" src="https://gcdn.bionluk.com/site/icon/ic_settings_input_svideo_gray.svg">
            </router-link>
          </li>
          <li>
            <router-link :to="'/panel/satislar'" active-class="active">
              <p style="position: absolute; margin-top: 3px; margin-left: 35px;">Satışlarım</p>
              <img style="height: 22px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_shopping_basket_gray.svg">
            </router-link>
          </li>

          <li>
            <router-link :to="'/panel/portfolyo'" active-class="active">
              <p style="position: absolute; margin-top: 3px; margin-left: 35px;">Portfolyom</p>
              <img style="height: 22px;" :src="$root.CDN_SITE + 'icon/ic_filter_frames_gray.svg'">
            </router-link>
          </li>

          <li>
            <router-link :to="'/panel/ilanlar'" active-class="active">
              <p style="position: absolute; margin-top: 2px; margin-left: 35px;">İş İlanlarım</p>
              <img style="height: 18px;" :src="$root.CDN_SITE + 'icon/ic_store_gray.svg'">

            </router-link>
          </li>

          <li>
            <router-link :to="'/panel/alici-istekleri'" active-class="active">
              <p style="position: absolute; margin-top: 2px; margin-left: 35px;">Özel Alıcı İstekleri</p>
              <img style="height: 20px;" :src="$root.CDN_SITE + 'icon/ic_add_alarm_gray.svg'">
            </router-link>
          </li>

        </ul>
      </template>
      <div v-if="user.seller.isSeller" class="hiddendiv20"></div>

      <div v-if="!user.seller.isSeller" class="nav-menutext">
        <router-link to="/freelancer-olmak/"><span style="color:#fd4056">Freelancer Ol!</span></router-link>
      </div>
      <div v-if="!user.seller.isSeller" class="hiddendiv20"></div>


    </div>
    <div v-else>
      <ul class="nav-list">

        <li class="nav-list-item">
          <router-link :to="'/benim-isim-acil'" class="bia-button" active-class="active-bia">
            <div class="nav-list-item-detail">
              <img class="nav-list-item-detail-icon" :src="$root.CDN_SITE+ 'icon/tansel_bia_active.svg'">
              <span class="nav-list-item-detail-text">Benim İşim Acil!</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="nav-left-footer" style="margin-top: 30px;">
      <footer-nav></footer-nav>
    </div>

  </div>

</template>

<script>
  import FooterNav from "../../components/shared/navFooter";

  export default {
    name: "src-pages-body-workstation-navComponent",
    components: {FooterNav},
    props: ["activeLink"],
    data() {
      return {
        feedNotifications: {
          path: "/feed/notifications",
          activeClass: (this.activeLink === "feed_notifications" ? "active" : null)
        },
        messageRedirectUserName: "/panel/mesajlar"
      };
    },

    methods: {
      goToMessages() {
        this.api.workstation.getConversations('all', '', 1, 0, "navComponent")
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data.conversations.length !== 0) {
                this.messageRedirectUserName = "/chat/" +result.data.conversations[0].r_username;
              } else {
                this.messageRedirectUserName = "/panel/mesajlar"
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    created() {
      this.goToMessages();
    }
  }

</script>

<style lang="scss">
  .oval-number {
    &:hover {
      color: #FFF;
    }
  }

  .nav-list{
    padding-left: 0px;
  }

  .nav-list-item-detail{
    display: flex;
    align-items: center;
  }

  .nav-list-item-detail-text{
    font-size: 14px;
    font-weight: 500;
    color: #2d3640;
  }

  .nav-list-item-detail-icon{
    width: 24px;
    height: 24px;
    padding-left: 15px;
  }

  .nav-list-item-detail{
    display: flex;
    margin-right: 20px;
    height: 50px;
    border: solid 1px #eaedf2;
  }

  .active-bia{
    border-right: 3px solid #fd4056;
    .nav-list-item-detail{
      border-color: #fd4056;
    }
    .nav-list-item-detail-text{
      color: #fd4056;
    }
  }

  .bia-button{
    padding: 0!important;
    margin: 10px 0 10px 0px;
  }

</style>
