/**
 * Created by haluk on 5/23/17.
 */


import v4 from './v4.vue';
import v4_mobile from './v4_mobile.vue';

export default {
  v4_mobile,
  v4
}
