<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-loader-screen="openLoader" style="height: 100%; overflow:scroll; ">

        <div v-if="getModalInfo" class="modal-container" @click.stop>

          <div style="float: right; padding-right: 20px;">
            <img @click="hide()" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div style="clear: both"></div>

          <div style="text-align: center; margin: 0 auto; width: 695px">
            <p v-if="from === 'portfolio'" class="title">Galeri <span v-if="this.calledFrom === 'edit'">Düzenle</span><span v-else>Oluştur</span></p>
            <p v-else class="title">Vitrin <span v-if="this.calledFrom === 'edit'">Düzenle</span><span v-else>Oluştur</span></p>
            <div
              v-if="!uploading && !uploadedMedia.upload_id"
              :style="dragAndDropArea ? 'border:2px dashed #ff5364; margin-bottom:30px;' : 'margin-bottom:30px;'"
              draggable="true"
              @drop="dropHandler($event, 'media')"
              @dragover="dragOverHandler($event)"
              class="upload-area" id="portfolio-drag-drop-media">
              <p class="title">Dosyayı buraya sürükle & bırak</p>
              <p class="sub-title">Bu alana eklemek istediğin dosyayı sürükleyip bırakabilirsin.</p>
              <p class="yada">YA DA</p>

                <label for="filesPortfolio4" @click="" style="cursor:pointer;width: 220px; height: 54px; margin-top: 33px;" class="cuper-black-button">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
                    <span style="padding-left: 14px;">Dosya Seç</span>
                </label>


              <p class="help-text">Ekleyebileceğin dosya tipleri: <span style=" padding-left: 5px;">.JPG, .PNG, .MP3, .MP4</span></p>
              <p class="help-text" style="margin-top: 15px;">Maksimum: <span style=" padding-left: 5px;">Görseller için <span style="font-weight: bold">2 mb</span>, videolar için <span style="font-weight: bold">300 mb</span> ve uzunluğu en fazla <span style="font-weight: bold">4 dakika 59 saniye</span> olabilir.</span></p>
              <p class="help-text" style="margin-top: 15px;">Yüksek kaliteli bir görüntü kullanın: <span style=" padding-left: 5px;">1280x720 piksel (16:9 oran)</span></p>
            </div>
            <div v-if="uploading" class="upload-area" style="margin-bottom: 30px;">
              <div style="display: flex; align-items: center">
                <img v-if="uploadedMedia.icon_png"  class="upload-png" :src="uploadedMedia.icon_png">
                <img v-else-if="percentCompleted.temp_icon_png[timeStamp]" class="upload-png" :src="percentCompleted.temp_icon_png[timeStamp]">
                <div style="display: flex; flex-direction: column; margin-left: 17px; text-align: left">
                  <div style="display: flex; justify-content: space-between; align-items: center">
                    <div style="display: flex; flex-direction: column">
                      <p class="filename">{{ uploadedMedia.filename }}</p>
                      <div class="percent"><p style="width: 33px;">%{{parseInt(percentCompleted.portfolio[timeStamp]) || 100}} </p> •<span style="margin-left: 9px;">{{uploadRemainingTime}}</span></div>
                    </div>
                    <div @click="cancelUploadMedia" style="cursor: pointer; align-self: flex-start; margin-top: 1px;">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg"  onload="SVGInject(this)" class="cancel-upload-icon">
                    </div>
                  </div>
                  <progress v-if="percentCompleted.portfolio[timeStamp]" style="margin-top:10px; margin-left: 0; width: 310px" class="progressbar-purple" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
                </div>
              </div>
            </div>

            <div v-if="!uploading && uploadedMedia.upload_id" class="uploaded-area">
              <div class="green" v-if="uploadedMedia.file_type === 'video'">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg"  onload="SVGInject(this)" class="ok-icon">
                <p class="text">
                  Video işleniyor... Ekleme işlemine devam edebilirsin. Yüklediğin video işlendikten sonra otomatik olarak gösterilecek.
                </p>
              </div>
              <div class="up">
                <div class="main-img" :style="uploadedMedia.image_url || uploadedCover.image_url ? 'border:none' : ''">
                  <div @click="deleteUploadedMedia" class="delete-area">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg"  onload="SVGInject(this)" class="delete-icon">
                  </div>

                    <img v-if="(uploadedMedia.file_type === 'video' || uploadedMedia.file_type === 'audio') && !uploadedCover.image_url" class="uploaded-media-icon-png" :src="uploadedMedia.icon_png">
                    <img v-else-if="(uploadedMedia.file_type === 'video' || uploadedMedia.file_type === 'audio') && uploadedCover.image_url" class="uploaded-media-cover" :src="uploadedCover.image_url">
                    <img v-else-if="uploadedMedia.file_type === 'image'" class="uploaded-media-cover" :src="uploadedMedia.image_url">


                    <a target="_blank" :href="uploadedMedia.video_url ? uploadedMedia.video_url : uploadedMedia.url" class="show-original-area" v-if="uploadedMedia.url">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-search.svg"  onload="SVGInject(this)" class="show-original-icon">
                    </a>
                </div>
                <div class="right-area">
                  <p v-if="uploadedMedia.file_type === 'video'" class="title">Video için küçük resim</p>
                  <p v-else-if="uploadedMedia.file_type === 'audio'" class="title">Ses için küçük resim</p>
                  <p v-else class="title">Kapak için küçük resim</p>
                  <div class="help-area">
                    <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                    <p v-if="uploadedMedia.file_type === 'video'" class="help-text3">Videonun içeriğini gösteren bir kapak görseli yükle. İyi kapak görselleri öne çıkar ve alıcıların dikkatini çeker.</p>
                    <p v-else-if="uploadedMedia.file_type === 'audio'" class="help-text3">Ses içeriğini gösteren bir resim yükle. İyi küçük resimler öne çıkar ve alıcıların dikkatini çeker.</p>
                    <p v-else class="help-text3">İçeriğini iyi gösteren bir resim yükle. İyi küçük resimler öne çıkar ve alıcıların dikkatini çeker.</p>
                  </div>
                  <div class="small-img-area"
                       id="portfolio-drag-drop-cover"
                       :style="dragAndDropArea ? 'border:2px dashed #ff5364' : ''"
                       v-if="!uploadedCover.image_url && dragAndDropArea"
                       draggable="true"
                       @drop="dropHandler($event, 'cover')"
                       @dragover="dragOverHandler($event)">
                    <img class="small-img-upload" src="https://gcdn.bionluk.com/site/cicons/sm-im.png">
                    <p>Küçük resim yükle</p>
                  </div>
                  <div v-else-if="uploadedCover.image_url" class="small-img-area" style="border:none">
                    <img class="cover-img" :src="uploadedCover.image_url">
                  </div>
                  <div v-else>
                    <div v-if="uploadingCover" class="small-img-area-label">
                      <loader></loader>
                    </div>
                    <label class="small-img-area" for="filesPortfolioCover" v-else>
                      <img class="small-img-upload" src="https://gcdn.bionluk.com/site/cicons/sm-im.png">
                      <p>Küçük resim yükle</p>
                    </label>
                  </div>
                  <p @click="removeCover" v-if="uploadedCover.image_url" class="remove-cover-text">[küçük resmi kaldır]</p>
                  <input id="filesPortfolioCover" style="visibility:hidden;" type="file" @change="onFileChangeToUploadCover">
                </div>
              </div>

              <div class="bottom">
                <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                <p class="text">Belirleyeceğin kapak görseli için önerilen boyut 16:9’dur ve tavsiye edilen maksimum genişlik 1400px'dir. Kapak görseli yüklenmediği takdirde otomatik olarak oluşturulacaktır.</p>
              </div>
            </div>

          </div>

          <input id="filesPortfolio4" style="visibility:hidden; height: 0; margin: 0; padding: 0; border: none" type="file" @change="onFileChange">
          <div class="foot" v-if="uploadedMedia.upload_id">
            <custom-button v-if="from === 'portfolio'" :disabled="!enableButton" button-type="red" style="width: 220px;" @click="addContent()"><span v-if="calledFrom==='edit'">Güncelle</span><span v-else>Portfolyoma Ekle</span></custom-button>

            <custom-button  v-if="from === 'gig'" :disabled="!enableButton" button-type="red" style="width: 220px;" @click="addContent()"><span v-if="calledFrom==='edit'">İçeriği Güncelle</span><span v-else>İçeriği İlanıma Ekle</span></custom-button>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton';

  export default {
    name: "src-pages-modals-uploadGalery-v1",
    components: {
      CustomButton
    },
    data() {
      return {
        step:1,
        openLoader: false,
        calledFrom:null,
        from:'portfolio',
        fileIndex:null,

        uploading: false,
        uploadingCover: false,
        dragAndDropArea:false,
        dragDropText: 'Dosyaları bu alana sürükleyin.',
        dragDropTextCover: '',
        dragDropTextMedia: '',
        timeStamp: null,
        uploadedMedia:{upload_id:null, filename:''},
        uploadedCover:{upload_id:null},
        uploadRemainingTime: '...'


      }
    },

    methods: {

      addContent(){
        let emitObj = this.uploadedMedia;
        emitObj.cover = {
          upload_id: this.uploadedCover.upload_id || this.uploadedMedia.upload_id,
          image_url: this.uploadedCover.image_url ? this.uploadedCover.image_url : this.uploadedMedia.file_type === 'video' ? 'https://gcdn.bionluk.com/site/general/videoprogress.png' :  this.uploadedMedia.file_type === 'audio' ? 'https://gcdn.bionluk.com/uploads/portfolio/audiofile.jpg' : this.uploadedMedia.image_url,
          image_url_small: this.uploadedCover.image_url_small ? this.uploadedCover.image_url_small : this.uploadedMedia.file_type === 'video' ? 'https://gcdn.bionluk.com/site/general/videoprogress.png' :  this.uploadedMedia.file_type === 'audio' ? 'https://gcdn.bionluk.com/uploads/portfolio/audiofile.jpg' : this.uploadedMedia.image_url_small,

        }


        this.EventBus.$emit('addPortfolioItem', {calledFrom:this.calledFrom, fileIndex:this.fileIndex, file:emitObj});
        this.hide();
      },

      removeCover() {
        this.uploadedCover = {upload_id:null, filename:''}
      },
      deleteUploadedMedia(){
        this.uploadedMedia = {upload_id:null, filename:'', cover:{upload_id:null}};
        this.uploadedCover = {upload_id:null}
        this.uploading = false;
      },
      cancelUploadMedia(){
        this.cancelUploadRequest(this.timeStamp);
        this.uploadedMedia = {upload_id:null, filename:''};
        this.uploadedCover = {upload_id:null}
        this.uploading = false;
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        if (event.target.id === "portfolio-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana bırakın.';
        }

        else if (event.target.id === "portfolio-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "portfolio-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana sürükleyin.';
        }

        else if (event.target.id === "portfolio-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana sürükleyin.';
        }
      },

      dropHandler(event, type) {
        this.EventBus.$emit('dragAndDropFile', null);
        event.preventDefault();
        event.stopPropagation();

        if (type === 'cover') {
          this.onFileChangeToUploadCover(event);
        }
        else if (type === 'media') {
          this.onFileChange(event);
        }
        this.dragAndDropArea = false;
      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },

      onFileChangeToUploadCover(e) {
        let files = e.target.files || e.dataTransfer.files;

        if(files && files[0] && files[0].name){
          this.api.general.checkCoverIsImage(files[0].type)
            .then(({data}) => {
              let result = data;
              this.activeProgress = null;
              if (result.success) {
                this.$store.commit(this.types.ACTIVE_MODAL, {
                  modalType: this.Modals.CROPPER,
                  info: {file: files[0], canvasPixel: {width: 1400, height: 788}, divider: 2.5, viewMode: 1, fileType: "onFileChangeToUploadCover"},
                  description: 'keepOldModal'
                });
                e.target.value = '';

              } else {
                e.target.value = '';
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              e.target.value = '';
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }


      },

      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if(files && files[0] && files[0].name){
          this.uploading = true;
          this.timeStamp = +new Date();
          // this.timeStamp = '_' + Math.random().toString(36).substr(2, 9);

          this.iterateUploadFileToGoogle(files[0], e);
        }
      },

      eventBusOnFileChangeToUploadCover(payload) {
        this.uploadingCover = true;
       this.timeStamp = +new Date();

        //Stamp = '_' + Math.random().toString(36).substr(2, 9);

        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.PORTFOLIO, null, this.timeStamp)
          .then(result => {
            this.uploadedCover = {
              upload_id: result.externalID,
              image_url: result.image_url,
              image_url_small: result.image_url_small,
            };
            this.uploadingCover = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadingCover = false;
          });
      },

      iterateUploadFileToGoogle(file, e) {
        this.uploadedMedia.filename = file.name;
        this.uploadedMedia.filesize = file.size;
        this.uploadFileToGoogle(file, this.Constants.UPLOAD_TYPES.PORTFOLIO, null, this.timeStamp)
          .then(result => {

            this.uploadedMedia = {
              uuid:result.uuid,
              file_type: result.file_type,
              upload_id: result.externalID,
              image_url: result.image_url,
              image_url_small: result.image_url_small,
              url:result.url,
              image_url_original:result.image_url_original,
              video_url: result.video_url,
              icon_png:result.icon_png,
              filename:result.filename,
              uploadName: result.uploadName,
              width: 0,
              height: 0,
              loader: true
            };

            if(result.file_type === 'image'){
              this.$store.commit(this.types.ACTIVE_MODAL, {
                modalType: this.Modals.CROPPER,
                info: {file: file, canvasPixel: {width: 1400, height: 788}, divider: 2.5, viewMode: 1, fileType: "onFileChangeToUploadCover"},
                description: 'keepOldModal'
              });
            }

            this.uploading = false;
          })
          .catch(err => {
            console.log(err);
            this.cancelUploadRequest(this.timeStamp);
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploading = false;
          });
      },

      hide() {
        if(this.uploading || this.uploadingCover){
          if (confirm("Dosya yüklemesi devam ediyor.Yine de kapatmak istiyor musun?")) {
            this.cancelUploadRequest(this.timeStamp);
            this.$store.commit(this.types.DISABLE_MODAL);
          }
        } else {
          this.$store.commit(this.types.DISABLE_MODAL);
        }

      },

    },

    computed: {


      getModalInfo() {
        let info =  this.$store.state.activeModal.info;
        if(info &&  info.sendFile && info.sendFile.upload_id){
          let sendFile = info.sendFile
          this.uploadedMedia = sendFile;
          if(sendFile.cover.upload_id !== sendFile.upload_id){
            this.uploadedCover = sendFile.cover;
          }
          this.calledFrom = 'edit';
          this.fileIndex = info.fileIndex;
        }

        if(info && info.from){
        	this.from = info.from;
        }
        return true;
      },

      enableButton(){
        if(this.uploadedMedia.upload_id){
          if(this.uploadingCover || this.uploadingCover){
            return false;
          } else {
            return true;
          }
        } else {
         return false;
        }

      },

    },

    watch: {
      'percentCompleted.portfolio': function (newVal) {
        let now = +new Date();
        let uploadedBytes = (this.uploadedMedia.filesize * newVal[this.timeStamp]) / 100;
        let uploadSpeed = uploadedBytes / ((now - this.timeStamp)/1000);
        let remaingSeconds = Math.round((((this.uploadedMedia.filesize - uploadedBytes) / uploadSpeed)));
        if(remaingSeconds && remaingSeconds > 1){
          if(remaingSeconds > 60){
            this.uploadRemainingTime = (Math.round(remaingSeconds / 60)) + ' dakika kaldı';
          } else {
            this.uploadRemainingTime = remaingSeconds + ' saniye kaldı';
          }
        } else {
          this.uploadRemainingTime = ' . . .';
        }


      }

    },

    beforeDestroy() {
      this.EventBus.$off("loader");
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$off("cropperImageFile");
    },
    created() {

      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);

      this.EventBus.$on('cropperImageFile', payload => {
        if (payload.fileType === "onFileChangeToUploadCover") {
          this.eventBusOnFileChangeToUploadCover(payload);
        }
      });


      this.EventBus.$on("loader", payload => {
        this.openLoader = payload;
      });
    }
  }

</script>

<style scoped lang="scss">



  /*dragdrop*/
  #portfolio-drag-drop-cover {

  }

  #portfolio-drag-drop-media {

  }


  .uploaded-area{

    .green{
      width: 693px;
      height: 97px;
      border-radius: 10px;
      border: solid 1px rgba(0, 165, 117, 0.3);
      background-color: rgba(0, 165, 117, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      .ok-icon /deep/ {
        margin-left: 40px;
        width: 36px;
        height: 36px;
        path {
          fill: #00a575;
        }
      }

      .text{
        padding-right: 40px;
        text-align: left;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43;
        color: #2d3640;
      }
    }

    .up{
      margin-top: 40px;
      display: flex;
      width: 693px;

      justify-content: space-between;
    }
   .main-img{
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;

     width: 320px;
     height: 180px;
     border-radius: 10px;
     border: dashed 2px #bfc8d2;
     background-color: rgba(191, 200, 210, 0.1);

     .delete-area{

       position: absolute;
       top: 20px;
       left: 20px;
       cursor: pointer;
       width: 36px;
       height: 36px;
       border-radius: 5px;
       background-color: #ffffff;
       display: flex;
       align-items: center;
       justify-content: center;


       &:hover{
         opacity: 0.8;
       }

       .delete-icon /deep/ {
         width: 20px;
         height: 20px;
         path {
           fill: #2d3640;
         }
       }
     }


     .show-original-area{
       position: absolute;
       bottom: 10px;
       right: 10px;
       cursor: pointer;
       width: 36px;
       height: 36px;
       border-radius: 5px;
       background-color: #ffffff;
       display: flex;
       align-items: center;
       justify-content: center;


       &:hover{
         opacity: 0.8;
       }

       .show-original-icon /deep/ {
         width: 20px;
         height: 20px;
         path {
           fill: #2d3640;
         }
       }
     }


     .uploaded-media-icon-png{
       width: 44px;
       height: 56px;
     }

     .uploaded-media-cover{
       width: 320px;
       height: 180px;
     }
   }

   .right-area{
     text-align: left;

     width: 320px;
     .title{
       text-align: left;
       font-size: 22px;
       font-weight: 500;
       color: #2d3640;
     }
     .help-area{
       margin-top: 5px;
       display: flex;
       align-items: flex-start;
       img{

       }
       .help-text3{

         margin-left: 5px;
         font-size: 12px;
         font-weight: normal;
         line-height: 1.67;
         color: #5e6b79;
       }
     }

     .small-img-area-label{
       margin-top: 10px;
       width: 131px;
       height: 74px;
       border-radius: 5px;
       background-color: rgba(191, 200, 210, 0.1);
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
     }
     .small-img-area{
       cursor: pointer;
       margin-top: 10px;
       width: 131px;
       height: 74px;
       border-radius: 5px;
       border: dashed 2px #bfc8d2;
       background-color: rgba(191, 200, 210, 0.1);
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       &:hover{
         border: dashed 2px #ff5364;
       }
       .small-img-upload{
         border-radius: 2px;
         height: 20px;
       }
       .cover-img{
         border-radius: 2px;
         height: 74px;
       }
       p{
         margin-top: 7px;
         font-size: 11px;
         font-weight: 500;
         color: #2d3640;
       }
     }

     .remove-cover-text{
       cursor: pointer;
       margin-top: 10px;
       font-size: 14px;
       font-weight: 500;
       color: #fd4056;
       &:hover{
         text-decoration: underline;
       }
     }
   }


    .bottom{
      display: flex;
      margin-top: 40px;
      img{
        width: 14px;
        height: 14px;
      }
      .text{
        max-width: 650px;
        text-align: left;
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.8;
        color: #5e6b79;
      }
    }
  }

  .upload-plus-icon /deep/ {
    width: 18px;
    height: 18px;
    path {
      fill: #fff;
      &:hover{
        opacity: 0.8;
      }
    }
  }

  .upload-area{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 693px;
    height: 349px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .upload-png{
      width: 31px;
      height: 41px;
    }

    .filename{
      font-size: 16px;
      font-weight: 500;
      color: #2d3640;
      text-overflow: ellipsis; max-width: 270px; overflow: hidden; white-space: pre
    }

    .cancel-upload-icon /deep/ {
      cursor: pointer;
      width: 14px;
      height: 14px;
      path {
        fill: #8b95a1;
        &:hover{
          opacity: 0.8;
        }
      }
    }

    .percent{
      display: flex;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #8b95a1;
    }
    .title{
      font-size: 26px;
      font-weight: 600;
      line-height: 1.08;
      color: #5e6b79;
    }
    .sub-title{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.43;
      color: #5e6b79;
    }
    .yada{
      margin-top: 33px;
      font-size: 14px;
      font-weight: normal;
      color: #8b95a1;
    }

    .help-text{
      margin-top: 33px;
      font-size: 12px;
      font-weight: 600;
      color: #2d3640;
      span{
        font-size: 12px;
        font-weight: 300;
        color: #8b95a1;
      }
    }
  }

  .title{

    font-size: 32px;
    font-weight: normal;
    text-align: center;
    color: #2d3640;
    span{
      font-weight: 600;
    }
  }


  .modal-container {
    width: 791px !important;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #eaedf2;
    padding: 20px 0 0;
    margin-top: 50px;
    overflow-y: scroll;
    position: relative;
  }

  .modal-mask {
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .4);
  }





  .foot{
    margin-top: 30px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid rgba(191, 200, 210,0.2);
  }



</style>
