<template>
  <div>

    <div v-if="activatedPlusModal" class="plusModal" style=" transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;">
      <div style=" background-color: rgba(0, 0, 0, 0.6);  z-index: 999999; margin: 0 auto; height: 10000%; position: fixed;  width: 100%; ">
        <div style=" margin: 0 auto; padding-bottom: 25px; background-color: #f4f5f7;  text-align: center;  width: 80%; ">

          <app-redirect :size="'modal'" :action="'initMessageList'" />

          <p style="margin-top: 20px;">
            <button @click="activatedPlusModal = false" class="basic-button basic-chat-button" style=" color: #2d3640!important;">Anladım, daha sonra...</button>
          </p>
        </div>
      </div>
    </div>

    <div :class="'mobileNavBar ' + deviceType">

      <div class="appBar-header">
        <div @click="routeBack('/panel', false)" class="header-route-back">
            <span v-if="deviceType === 'ios'">
              <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
          <span v-else>
              <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Mesajlar
          </p>
        </div>
      </div>
    </div>

    <div :class="'underAppBar ' + deviceType"></div>
    <div class="chat-list-body">
      <div style="position: fixed; width: 100%; background-color: #fff; z-index: 2;">
        <input class="chat-list-search"
               v-model="searchTerm"
               type="text"
               @keyup="inputEvent"
               placeholder="Kullanıcılar içerisinde ara"/>
        <div class="separator"></div>

        <div class="title-body">
          <div :class="{'title-main': true ,active: activeTitle ==='all'}"
               @click="makeActive('all')">
            <p class="title">Gelen Kutusu</p>
          </div>
          <div :class="{'title-main': true ,active: activeTitle ==='deleted'}"
               @click="makeActive('deleted')">
            <p class="title">Arşiv</p>
          </div>
        </div>
        <div class="separator"></div>
      </div>


      <div class="chat-row-body" v-if="users.length">
        <div v-if="userOpenLoading" class="loader"></div>

        <div class="chat-row" v-for="(user,index) in users"
             :style="returnCorrectStyle(user,'background')"
             @click="getConversation(user)"
             v-else>
          <div v-show="!user.isseen" class="isseen-div"></div>
          <div v-show="user.isseen" class="isseen-empty-div"></div>
          <div class="avatar-main">
            <div class="online-status-outer">
              <div class="online-status-inner" :style="user.onlineStatus ? 'background-color: #67cc64;':'background-color: #bfc8d2;'"></div>
            </div>
            <img class="avatar-image" :src="user.r_avatar_url"/>
          </div>
          <div class="user-name-main">
            <p class="user-name" :style="returnCorrectStyle(user, 'username')">{{user.r_username}}</p>
            <p class="user-last-message">{{user.message}}</p>
          </div>
          <div class="last-seen-div">
            {{ user.last_message_created_at | calculateElapsedTime }}
          </div>
        </div>
      </div>
      <loader v-if="loader" style="width: 30px;height: 30px"></loader>
      <div class="seemore-div"
           @click="getUsers(activeTitle, '', null, offset)"
           v-show="!loader && !userOpenLoading && seemorediv">
        Daha fazla gör +
      </div>
    </div>

  </div>
</template>

<script>
  import appRedirect from "@/pages/components/appRedirect"

  export default {
    name: "src-pages-body-workstation-messages-list-v1_mobile",
    components: {appRedirect},
    data() {
      return {
        userOpenLoading: false,
        users: [],
        searchTerm: '',
        tempSearchTerm: null,
        seemorediv: false,
        offset: 0,
        conversationCount: 0,
        activeTitle: "all",
        loader: false,
        activatedPlusModal: false
      }
    },

    methods: {
      inputEvent(event) {
        if (event.which === 13) {
          this.getUsers(this.activeTitle, this.searchTerm, null, null);
        }
      },

      makeActive(activeTitle) {
        if (activeTitle === "all") {
          this.activeTitle = activeTitle;
          this.getUsers(activeTitle, '', null, null)
        }
        else if (activeTitle === "deleted") {
          this.activeTitle = activeTitle;
          this.getUsers(activeTitle, '', null, null)
        }
      },

      returnCorrectStyle(user, type) {
        if (type === "background") {
          return user.isseen ? 'background-color: #f4f5f7;' : 'background-color: #ffffff;';
        }
        if (type === "username") {
          return user.isseen ? '' : 'font-weight: 600;';
        }
      },

      getConversation(user) {
          this.messageOffset = 0;
          this.$router.push("/chat/" + user.r_username);
      },

      getUsers(messageType, searchTerm, limit, offset) {
        this.loader = true;
        if (!searchTerm) {
          this.searchTerm = '';
        }
        if (!offset) {
          this.userOpenLoading = true;
          this.users = [];
          this.offset = 0;
        }
        this.api.workstation.getConversations(messageType, searchTerm, limit, offset)
          .then(({data}) => {
            this.userOpenLoading = false;
            let result = data;
            this.loader = false;
            if (result.success) {
              this.tempSearchTerm = ((searchTerm && searchTerm.length) ? searchTerm : null);
              this.conversationCount = result.data.conversation_count;
              this.users = this.users.concat(result.data.conversations);
              this.seemorediv = result.data.seemorediv;
              this.offset += 5;
              this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight);
              });
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

    },

    created() {
      this.activatedPlusModal = true;
      this.getUsers(this.activeTitle, '', null, null)
    }
  }
</script>

<style scoped lang="scss">
  .header-title {
    padding-right: 18px;
  }

  .chat-list-body {
    flex: 1;
    margin-top: -10px;
  }

  .chat-list-search {
    width: calc(100% - 69px);
    height: 10px;
    border: solid 1px #bfc8d2;
    margin: 27px 12px 14px 10px;
    padding-left: 35px;
    background: #f7f9fb url('https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg') no-repeat;
    background-position-x: 11px;
    background-position-y: 7px;
  }

  .separator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #eaedf2;
  }

  .title-body {
    display: flex;
    justify-content: center;
    padding-top: 17px;
  }

  .title-main {
    cursor: pointer;
    width: 50%;
    height: 28px;
    color: #8b95a1;
    font-size: 14px;
    font-weight: 500;
    .title {
      text-align: center;
    }
  }

  .active {
    margin-bottom: -3px;
    border-bottom: 3px solid black;
    color: #242424;
  }

  .chat-row-body {
    overflow: scroll;
    padding-top: 125px;
    .loader {
      text-align: center;
      width: 20px;
      height: 20px;
    }
    .chat-row {
      cursor: pointer;
      height: 59px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #eaedf2;
      .isseen-div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #e63262;
        margin-left: 8px;
      }
      .isseen-empty-div {
        width: 14px;
      }
    }
  }

  .avatar-main {
    position: relative;
    width: 44px;
    height: 44px;
    margin-left: 7px;
    .avatar-image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
    .online-status-outer {
      position: absolute;
      top: 0;
      right: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .online-status-inner {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .user-name-main {
    margin-left: 10px;
    .user-name {
      font-size: 14px;
      color: #242424;
    }
    .user-last-message {
      font-size: 12px;
      color: #939c9c;
      margin-top: 4px;
      width: 125px;
      overflow: hidden;
      height: 14px;
    }
  }

  .last-seen-div {
    font-size: 12px;
    text-align: right;
    color: #939c9c;
    margin-left: auto;
    padding-right: 13px;
  }

  .seemore-div {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }
</style>

