<template>
  <div class="follow-section">

    <div class="share-wrapper" style="margin: 30px auto 0">
      <div @click="clickedShareButton"  class="share-area">
        <p class="text">Paylaş</p>
        <social-sharing :url="url" inline-template>
          <div class="user-socialmedia-icons">
            <network network="facebook" style="cursor: pointer;">
              <img  style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
            </network>

            <network  network="twitter" style="cursor: pointer; margin-left: 20px;">
              <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
            </network>

            <network network="linkedin" style="cursor: pointer; margin-left: 20px;">
              <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
            </network>
          </div>
        </social-sharing>
        <div @click="copyToClipboard()" style="margin-left: 20px;">
          <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FavAndShare",
    props: {
      userProfile: {
        type: Object,
        default: () => {}
      },
      likeInfo: {
        type: Object,
        default: () => {}
      },
      uuid: {
        type: String,
        default: () => null
      },
      gigId: {
        type: Number,
        default: () => null
      }
    },
    data() {
      return {
        buttonLoading:false,
        url: window.location.href,
        likeStatus: {
          action: 0,
          actionText: "Favorilerime Ekle"
        }
      }
    },
    watch: {
      likeInfo() {
        this.setInitialData()
      }
    },
    methods: {
      activateGetLikedUsersModal(type, uuid) {
        if(this.buttonLoading) return;
        this.buttonLoading = true;
        this.api.general.getLikedUsers(type, uuid)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              let likes = result.data.likes;
              let likeCount = result.data.likeCount;
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.GET_LIKED_USERS, info: {id: uuid, likes: likes, likeCount: likeCount}});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      setInitialData() {
        this.likeStatus.action = this.likeInfo.likeStatus.action
        this.likeStatus.actionText = this.likeInfo.likeStatus.actionText
      },
      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/'+this.userProfile.username + '/' + this.$store.state.routerParams[1];
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'İş İlanı linki kopyalandı.'});
      },
      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v4', page:this.$store.state.componentMap.page, what:'gig share buttons', gig_id:this.gigId});
      },
      revertLikeStatus(likeStatus) {
        if (likeStatus.action === this.imageUrls.likedIcon.action) {
          this.likeStatus.action = this.imageUrls.likeIcon.action
          this.likeStatus.actionText = "İlanı Favorilerime Ekle"
        } else {
          this.likeStatus.action = this.imageUrls.likedIcon.action
          this.likeStatus.actionText = "İlanı Favorilerimden Çıkar"
        }
      },
      toggleLikeStatus(uuid, action) {
        this.revertLikeStatus(this.likeStatus);
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, action, uuid, null, this.$store.state.componentMap.page)
          .then(({data: result}) => {
            if (!result.success) {
              if (result.data.redirect_url) {
                return this.$router.push(result.data.redirect_url);
              }
              this.revertLikeStatus(this.likeStatus);
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(this.likeStatus);
          });
      }
    },
    created() {
      this.setInitialData()
    }
  }
</script>

<style lang="scss" scoped>
  .follow-section{
    width: 393px;
    height: 82px;
    margin-top: 30px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    .follow-section-button {
      width: 290px;
      height: 56px;
      border-radius: 2px;
      border: solid 1px #bfc8d2;
      cursor: pointer;
      &.active{
        background-color: #eaedf2;
      }
      &:hover{
        background-color: #eaedf2;
      }
      .text {
        font-size: 18px;
        color: #2d3640;
        line-height: 56px;
        font-weight: 600;
        text-align: center;
      }
    }
    .share-wrapper {
      width: 240px;
      height: 26px;
      .share-area{
        display: flex;
        .user-socialmedia-icons {
          display: flex;
          flex-direction: row;
          height: 26px;
        }
        .text{
          margin-right: 20px;
          font-size: 18px;
          font-weight: normal;
          line-height: 1.33;
          color: #8b95a1;
        }
      }
    }
  }

  .followers-count{
    padding-left: 30px;
    padding-right: 30px;
    height: 54px;
    border-radius: 2px;
    border: dashed 1px #bfc8d2;
    font-size: 16px;
    line-height: 53px;
    text-align: center;
    cursor: pointer;

    .inner-text {
      height: 18px;
      font-size: 14px;
      text-align: center;
      color: #5e6b79;
    }
    &:hover{
      border: solid 1px #2d3740;
    }
  }
</style>
