/**
 * Created by haluk on 4/18/17.
 */

import error from './errorModal';
import portfolio from './portfolio';
import editPortfolio from './editPortfolio';
import orderAction from './orderAction';
import customOffer from './customOffer';
import customOfferDetail from './customOfferDetail';
import getLikedUsers from './getLikedUsers';
import cropper from './cropper';
import checkout from './checkout';
import gigCategoryFilter from './gigCategoryFilter';
import search from './search';
import userSearch from './userSearch';
import orderContactInfo from './orderContactInfo';
import closeAccount from './closeAccount';
import complaint from './complaint';
import report from './report';
import disintermediationModal from './disintermediation';
import useBicoin from './useBicoin';
import bicoinFaq from './bicoinFaqModal';
import addList from './addList';
import forFreelancers from './forFreelancers'
import orderInfo from './orderInfo'
import unlockChat from './unlockChat'
import blogDetail from './blogDetail'
import freelancerLineFaq from './freelancerLineFaq';
import withdrawFunds from './withdrawFunds';

import freelancerChatLine from './freelancerChatLine';
import infoModal from './infoModal';
import pricing from './pricing';
import gallery from './gallery';
import globalWarningModal from './globalWarningModal';
import addNewCard from './addNewCard';
import phoneVerification from './phoneVerification';
import changeEmail from './changeEmail';
import changeLine from './changeLine';
import closeSession from './closeSession';
import welcomeSeller from './welcomeSeller';

import biaStart from  './biaStart';
import onboarding from  './onboarding';
import uploadGalery from  './uploadGalery';
import editGigFeaturedPortfolio from  './editGigFeaturedPortfolio';
import editorGigs from './editorGigs'


export default {
  error,
  portfolio,
  editPortfolio,
  orderAction,
  customOffer,
  customOfferDetail,
  getLikedUsers,
  cropper,
  checkout,
  gigCategoryFilter,
  search,
  userSearch,
  orderContactInfo,
  closeAccount,
  complaint,
  report,
  disintermediationModal,
  useBicoin,
  bicoinFaq,
  addList,
  forFreelancers,
  orderInfo,
  unlockChat,
  blogDetail,
  freelancerLineFaq,
  freelancerChatLine,
  infoModal,
  pricing,
  gallery,
  globalWarningModal,
  addNewCard,
  phoneVerification,
  changeEmail,
  changeLine,
  closeSession,
  welcomeSeller,
  biaStart,
  onboarding,
  uploadGalery,
  editGigFeaturedPortfolio,
  editorGigs,
  withdrawFunds
}
