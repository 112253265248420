<template>
  <div style="width: 372px; background-color: white">
    <div class="post-thumbnail">
      <a :href="`https://bionluk.com${blogView.pathToGo}`">
        <img style="border-radius: 5px 5px 0 0; height: 212px; object-fit: cover;" :src="blogView.imageUrl">
      </a>
    </div>
    <div class="entry-header">
      <div class="entry-meta before-title" style="margin-top: -50px; margin-left: -3px;">
        <ul>
          <li class="category-list">
            <ul>
              <li style="border: 3px solid white; border-radius: 16px; background: white; padding: 9px 0;">
                <a :href="`https://bionluk.com${blogView.pathToGo}`" style="background: #2d3460; padding: 7px 10px; color: white; text-decoration: none; border-radius: 14px">{{ blogView.tag }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <h3 class="entry-title">
        <a style="color: #2d3460; font-weight: 900" :href="`https://bionluk.com${blogView.pathToGo}`">{{ blogView.title }}</a>
      </h3>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BlogView',
    props:{
      blogView: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
	      pageLoaded: true,
      };
    }
  }
</script>

<style scoped lang="scss">
.post-thumbnail {
  width: 100%;
  position: relative;
}

.post-thumbnail img {
  height: 100%;
  object-fit: cover;
  display: flex;
}

img:not(.custom-logo) {
  width: 100%;
  height: auto;
}

.entry-header {
  padding: 30px;
  position: relative;
}

.entry-meta.before-title {
  margin-bottom: 15px;
}

.entry-meta {
  font-size: 13px;
  color: #2d3460;
  font-weight: 800;
  text-transform: capitalize;
}

ul {
  overflow-wrap: break-word;
}

.entry-meta li:not(.category-list) {
  display: inline-block;
}

.entry-title a {
  text-decoration: none;
}
</style>
