<template>
  <div class="review-container">
    <div class="review-header" style="display: flex; justify-content: space-between;">
      <div style="height: 50px; display: flex; flex-direction: column;">
        <p class="review-title">Paketleri Karşılaştırın</p>
        <p style="font-size: 12px; color: #5e6b79; margin-top: 7px;">Hangi paketi seçeceğine karar veremiyorsan freelancer’a hemen danışabilirsin.</p>
      </div>
      <div v-if="user.username !== seller.username" class="contact-freelancer-wrapper" @click="$emit('sendMessage')" :style="buttonLoading ? 'opacity:0.4': 'opacity:1'">
        <div style="padding: 8px 7px;  display: flex; justify-content: space-between;">
          <div class="avatar-wrapper">
            <div class="status-icon" :style="{backgroundColor: seller.onlineStatus ? '#5beb17' : '#8b95a1'}"/>
            <img class="avatar" :src="seller.avatar_url" :style="{border: seller.onlineStatus ? '1px solid #5beb17' : '1px solid #8b95a1'}"/>
          </div>
          <div>
            <p style="font-size: 14px; font-weight: bold;">Freelancer'a</p>
            <p>Danış</p>
          </div>
        </div>
      </div>

    </div>
    <div class="separator-review"/>

    <table class="package-table" cellspacing="0">
      <tr>
        <td style="border-right: solid 1px #eaedf2;">&nbsp;</td>
        <td style="padding-top:24px; padding-bottom:24px;text-align: left!important; background: rgba(244, 245, 247, 0.5);border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'premium' ? 'td-hover' : ''">
          <div v-if="packages.premium.is_preferred_package" class="preferred-label">
            {{packages.premium.preferred_package_text}}
          </div>
          <div class="hiddendiv" v-if="!packages.premium.is_preferred_package && (packages.standard.is_preferred_package || packages.basic.is_preferred_package)"></div>
          <p class="package-price">{{getPrice(packages.premium) | number_format}}<span class="package-price-tl">TL</span></p>
          <div class="package-price-title">Pro</div>
        </td>
        <td style="text-align: left!important;background: rgba(244, 245, 247, 0.5);border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'standard' ? 'td-hover' : ''">
          <div v-if="packages.standard.is_preferred_package" class="preferred-label">
            {{packages.standard.preferred_package_text}}
          </div>
          <div class="hiddendiv" v-if="!packages.standard.is_preferred_package && (packages.premium.is_preferred_package || packages.basic.is_preferred_package)"></div>
          <p class="package-price">{{getPrice(packages.standard) | number_format}}<span class="package-price-tl">TL</span></p>
          <div class="package-price-title">Standart</div>
        </td>
        <td style="text-align: left!important;background: rgba(244, 245, 247, 0.5);" :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'basic' ? 'td-hover' : ''">
          <div v-if="packages.basic.is_preferred_package" class="preferred-label">
            {{packages.basic.preferred_package_text}}
          </div>
          <div class="hiddendiv" v-if="!packages.basic.is_preferred_package && (packages.premium.is_preferred_package || packages.standard.is_preferred_package)"></div>
          <p class="package-price">{{getPrice(packages.basic) | number_format}}<span class="package-price-tl">TL</span></p>
          <div class="package-price-title">Temel</div>
        </td>
      </tr>
      <tr>
        <td class="table-value-header" style="border-bottom: solid 1px #eaedf2;border-top: solid 1px #dfe4ec;border-right: solid 1px #dfe4ec; font-weight: 300">Açıklama</td>
        <td style="text-align: left!important; vertical-align: text-top;border-bottom: solid 1px #eaedf2;border-top: solid 1px #dfe4ec;border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'premium' ? 'td-hover' : ''"><p
          class="package-title">{{packages.premium.title}}</p>
          <p class="package-description" style="margin-top:20px">{{packages.premium.description}}</p></td>
        <td style="text-align: left!important;vertical-align: text-top;border-bottom: solid 1px #eaedf2;border-top: solid 1px #dfe4ec;border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'standard' ? 'td-hover' : ''"><p
          class="package-title">{{packages.standard.title}}</p>
          <p class="package-description" style="margin-top:20px">{{packages.standard.description}}</p></td>
        <td style="text-align: left!important;vertical-align: text-top;border-bottom: solid 1px #eaedf2;border-top: solid 1px #dfe4ec;" :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'basic' ? 'td-hover' : ''"><p class="package-title">
          {{packages.basic.title}}</p>
          <p class="package-description" style="margin-top:20px">{{packages.basic.description}}</p></td>
      </tr>
      <!--dynamic-->
      <tr v-for="i in packages.basic.options.length" :key="'pricing_' + i">
        <td class="table-value-header"> <div style="display: flex; justify-content: space-between;">{{getPricingValue(i,'basic').title}} <div :data-balloon="getPricingValue(i,'basic').description" data-balloon-pos="up" data-balloon-length="medium" style="margin-left: 5px; cursor: help;"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="15"></div></div></td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'premium' ? 'td-hover' : ''">
          <p class="package-dynamic-value" v-if="getPricingValue(i,'premium').type === 'checkbox' && !getPricingValue(i,'premium').value">-</p>
          <img class="checkbox-img" v-if="getPricingValue(i,'premium').type === 'checkbox' && getPricingValue(i,'premium').value" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
          <p class="package-dynamic-value" v-if="getPricingValue(i,'premium').type === 'input' || getPricingValue(i,'premium').type === 'selectbox'">{{getPricingValue(i,'premium').value}}</p>
        </td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'standard' ? 'td-hover' : ''">
          <p class="package-dynamic-value" v-if="getPricingValue(i,'standard').type === 'checkbox' && !getPricingValue(i,'standard').value">-</p>
          <img class="checkbox-img" v-if="getPricingValue(i,'standard').type === 'checkbox' && getPricingValue(i,'standard').value" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
          <p class="package-dynamic-value" v-if="getPricingValue(i,'standard').type === 'input' || getPricingValue(i,'standard').type === 'selectbox'">{{getPricingValue(i,'standard').value}}</p>
        </td>
        <td style="border-bottom: solid 1px #eaedf2;" :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'basic' ? 'td-hover' : ''">
          <p class="package-dynamic-value" v-if="getPricingValue(i,'basic').type === 'checkbox' && !getPricingValue(i,'basic').value">-</p>
          <img class="checkbox-img" v-if="getPricingValue(i,'basic').type === 'checkbox' && getPricingValue(i,'basic').value" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
          <p class="package-dynamic-value" v-if="getPricingValue(i,'basic').type === 'input' || getPricingValue(i,'basic').type === 'selectbox'">{{getPricingValue(i,'basic').value}}</p>
        </td>
      </tr>
      <tr>
        <td class="table-value-header">
          <div style="display: flex; justify-content: space-between;">
            <div>Revizyon</div>
            <div data-balloon="Sipariş süreçlerinde revizyon genel anlamıyla sipariş sonundaki düzeltmeler anlamına gelmektedir.
Freelancer burada belirtilen sayı kadar, tercih edilen son çalışma üzerinde düzeltme/düzenleme yapar." data-balloon-length="medium" data-balloon-pos="up" style="margin-left: 10px; cursor: help;"><img src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" width="15"></div>
          </div>
        </td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'premium' ? 'td-hover' : ''" ><p class="package-dynamic-value">{{packages.premium.revisions}}</p></td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'standard' ? 'td-hover' : ''" ><p class="package-dynamic-value">{{packages.standard.revisions}}</p></td>
        <td style="border-bottom: solid 1px #eaedf2;" :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'basic' ? 'td-hover' : ''" ><p class="package-dynamic-value">{{packages.basic.revisions}}</p></td>
      </tr>
      <tr>
        <td class="table-value-header" >Teslim Süresi</td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'premium' ? 'td-hover' : ''" >
          <template v-if="packages.premium.extra_fast.price">
            <div>
              <input type="radio"
                     :checked="!packages.premium.extra_fast.selected"
                     v-model="packages.premium.extra_fast.selected"
                     id="radio_premium_delivery_normal"
                     name="radio-group_premium"
                     :value="false">
              <label style="width: 100%; text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_premium_delivery_normal" >{{ packages.premium.duration}} gün</label>
            </div>
            <div style="margin-top: 20px; margin-bottom: 10px;" @click="packages.premium.extra_fast.selected = true">
              <input type="radio"
                     :checked="packages.premium.extra_fast.selected"
                     v-model="packages.premium.extra_fast.selected"
                     id="radio_premium_delivery_fast"
                     name="group_premium"
                     :value="true">
              <label style="width: 100%;text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_premium_delivery_fast" >{{ packages.premium.extra_fast.duration}} gün (+{{packages.premium.extra_fast.price}} TL)</label>
            </div>
          </template>
          <template v-else>
            <p class="package-dynamic-value">{{packages.premium.duration}} gün</p>
          </template>
        </td>
        <td style="border-bottom: solid 1px #eaedf2;border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'standard' ? 'td-hover' : ''" >
          <template v-if="packages.standard.extra_fast.price">
            <div>
              <input type="radio"
                     :checked="!packages.standard.extra_fast.selected"
                     v-model="packages.standard.extra_fast.selected"
                     id="radio_standard_delivery_normal"
                     name="radio-standard"
                     :value="false">
              <label style="width: 100%; text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_standard_delivery_normal" >{{ packages.standard.duration}} gün</label>
            </div>
            <div style="margin-top: 20px; margin-bottom: 10px;" @click="packages.standard.extra_fast.selected = true">
              <input type="radio"
                     :checked="packages.standard.extra_fast.selected"
                     v-model="packages.standard.extra_fast.selected"
                     id="radio_standard_delivery_fast"
                     name="radio-standard"
                     :value="true">
              <label style="width: 100%;text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_standard_delivery_fast" >{{ packages.standard.extra_fast.duration}} gün (+{{packages.standard.extra_fast.price}} TL)</label>
            </div>
          </template>
          <template v-else>
            <p class="package-dynamic-value">{{packages.standard.duration}} gün</p>
          </template>
        </td>
        <td style="border-bottom: solid 1px #eaedf2;" :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover isPreferredPackage' : 'isPreferredPackage' : tdHover === 'basic' ? 'td-hover' : ''" >

          <template v-if="packages.basic.extra_fast.price">
            <div>
              <input type="radio"
                     :checked="!packages.basic.extra_fast.selected"
                     v-model="packages.basic.extra_fast.selected"
                     id="radio_basic_delivery_normal"
                     name="radio-basic"
                     :value="false">
              <label style="width: 100%; text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_basic_delivery_normal" >{{ packages.basic.duration}} gün</label>
            </div>
            <div style="margin-top: 20px; margin-bottom: 10px;" @click="packages.basic.extra_fast.selected = true">
              <input type="radio"
                     :checked="packages.basic.extra_fast.selected"
                     v-model="packages.basic.extra_fast.selected"
                     id="radio_basic_delivery_fast"
                     name="radio-basic"
                     :value="true">
              <label style="width: 100%;text-align: left; padding-left:30px;" class="radio-label package-dynamic-value" for="radio_basic_delivery_fast" >{{ packages.basic.extra_fast.duration}} gün (+{{packages.basic.extra_fast.price}} TL)</label>
            </div>
          </template>
          <template v-else>
            <p class="package-dynamic-value">{{packages.basic.duration}} gün</p>
          </template>
        </td>
      </tr>
      <tr>
        <td style="border-right: solid 1px #dfe4ec;">&nbsp;</td>
        <td style="border-right: solid 1px #eaedf2;" :class="packages.premium.is_preferred_package ? tdHover === 'premium' ? 'td-hover preferred' : 'preferred' : tdHover === 'premium' ? 'td-hover' : ''"  @mouseover="tdHover = 'premium'" @mouseout="tdHover = null">
          <button v-if="user.username === seller.username"
            :data-balloon="'Kendi ilanını satın alamazsın 🤓'"
            data-balloon-pos="up"
            disabled :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.premium.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.premium)">
            <span>Seç</span>
            <span style="float: right;">
                <span>{{ getPrice(packages.premium) | number_format }} TL</span>
              </span>
          </button>
          <button v-else :disabled="buttonLoading || user.username === seller.username || gigStatus !== 1" :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.premium.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.premium)">
            <span>Seç</span>
            <span style="float: right;">
                <span>{{ getPrice(packages.premium) | number_format }} TL</span>
              </span>
          </button>
        </td>
        <td style="border-right: solid 1px #eaedf2;" :class="packages.standard.is_preferred_package ? tdHover === 'standard' ? 'td-hover preferred' : 'preferred' : tdHover === 'standard' ? 'td-hover' : ''"  @mouseover="tdHover = 'standard'" @mouseout="tdHover = null">
          <button v-if="user.username === seller.username"
                  :data-balloon="'Kendi ilanını satın alamazsın 🤓'"
                  data-balloon-pos="up"
                  disabled :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.premium.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.premium)">
            <span>Seç</span>
            <span style="float: right;">
                <span>{{ getPrice(packages.standard) | number_format }} TL</span>
              </span>
          </button>
          <button v-else :disabled="buttonLoading || user.username === seller.username || gigStatus !== 1"  :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.standard.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.standard)">
            <span>Seç</span>
            <span style="float: right;">
                <span>{{ getPrice(packages.standard) | number_format }} TL</span>
              </span>
          </button>
        </td>
        <td :class="packages.basic.is_preferred_package ? tdHover === 'basic' ? 'td-hover preferred' : 'preferred' : tdHover === 'basic' ? 'td-hover' : ''"  @mouseover="tdHover = 'basic'" @mouseout="tdHover = null">
          <button v-if="user.username === seller.username"
                  :data-balloon="'Kendi ilanını satın alamazsın 🤓'"
                  data-balloon-pos="up"
                  disabled :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.premium.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.premium)">
            <span>Seç</span>
            <span style="float: right;">
                <span>{{ getPrice(packages.basic) | number_format }} TL</span>
              </span>
          </button>
          <button v-else :disabled="buttonLoading || user.username === seller.username || gigStatus !== 1" :class="buttonLoading || user.username === seller.username || gigStatus !== 1 ? 'package-disabled-button' : packages.basic.is_preferred_package? 'preferred-button' : 'package-button'" @click="$emit('packageClickAction', packages.basic)">
            <span>Seç</span>
            <span style="float: right;">
                    <span>{{ getPrice(packages.basic) |number_format }} TL</span>
                  </span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default {
    name: "ComparePackages",
    props: {
      packages: {
        type: Object,
        default: () => ({
          packages: {
            extras: []
          }
        })
      },
      seller: {
        type: Object,
        default: ()=> ({
          seller: {
            username: null,
            onlineStatus: false
          }
        })
      },
      buttonLoading: {
        type: Boolean,
        default: () => false
      },
      gigStatus: {
        type: Number,
        default: () => 1
      },
    },
    data() {
      return {
        tdHover: null
      }
    },
    methods: {
      getPricingValue(index, type) {
        return this.packages[type].options[index - 1];
      },
      getPrice(p){
       return p.price + (p.extra_fast.selected ? p.extra_fast.price : 0);
      }
    }
  }
</script>

<style lang="scss" scoped>


input[type=radio] {
  display: none;
}

input[type=radio] + .radio-label {
  height: 16px;
  width: 16px;
  display: inline-block;
  padding: 0 0 0 0px;
  cursor: pointer;
  background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg) no-repeat;
}

input[type=radio]:checked + .radio-label {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
}

label {
  margin-bottom: 0;
}

  button {
    &:disabled{
      background: #8b95a1!important;
      background-color: #8b95a1!important;
    }
  }

  .review-container {
    width: 750px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    margin-top: 19px;
    .review-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 17px 20px 27px;
    }

    .review-title {
      font-size: 20px;
      font-weight: 500;
      color: #2d3640;
    }

    .review-comment-type {
      font-size: 14px;
      text-align: left;
      color: #4b4f52;
    }

    .contact-freelancer-wrapper{
      width: 140px;
      height: 50px;
      border-radius: 5px;
      background-color: #2d3640;
      color: #ffffff;
      font-weight: 300;
      font-size: 13px;
      cursor: pointer;
      .avatar-wrapper {
        position: relative;
        display: inline-flex;
        .status-icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #8b95a1;
          position: absolute;
          right: -1px;
          bottom: 0;
        }
        .avatar {
          width: 34px;
          height: 34px;
          border-radius: 50%;
        }
      }
    }
  }

  .separator-review {
    width: 750px;
    height: 0;
    border-top: solid 1px #dfe4ec;
  }


  /*package pricing bottom*/
  .package-pricing-container {
    width: 750px;
    margin-top: 20px;
    background-color: #ffffff;
    border-left: solid #eaedf2 1px;
    border-right: solid #eaedf2 1px;
    border-top: solid #eaedf2 1px;
    border-bottom: solid #eaedf2 1px;
  }

  .package-table {
    width: 100%;
    position: relative;
    .isPreferredPackage{
      //box-shadow: inset 0 1px 0 0 #eaedf2, inset 1px -1px 0 0 #eaedf2 !important;
      background-color: rgba(242, 153, 51, 0.1) !important;
    }
    .preferred-label{
      max-width: 110px;
      height: 20px;
      overflow: hidden;
      border-radius: 10px;
      background-color: #f29933;
      line-height: 19px;
      color: #ffffff;
      text-align: left;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: -0.22px;
      margin-bottom: 10px;
    }
  }

  tr td {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 25%;
    padding: 13px;
    box-sizing: border-box;
    -webkit-transition: background .2s;
    transition: background .2s;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
  }

  tr td.no-border {
    border: 0 !important;
  }

  tr td:first-child {
    text-align: left;
    background: rgba(244, 245, 247, 0.5);
    color: #2d3640;


    border-left: none;
  }

  .td-hover {
    background-color: rgba(244, 245, 247, 0.8);
    &.preferred {
      background-color: rgba(242, 153, 51, 0.2);
    }
  }

  .package-price {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -1px;
    color: rgb(45, 54, 64);
    height: 36px;
  }

  .package-price-tl {
    font-weight: normal;
    font-size: 22px;
    margin-left: 5px;
    color: #2d3640;
  }

  .package-price-title {

    font-weight: 300;
    color: #2d3640 !important;
    font-size: 22px;
  }


  .package-title {
    font-weight: 500;
    color: #4b4f52;
    font-size: 16px;
  }

  .package-description {

    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    color: #4b4f52;
  }

  .table-value-header {
    color: #4b4f52;
    font-size: 14px;
    font-weight: normal;
    border-right: solid 1px #dfe4ec;
    border-bottom: solid 1px #eaedf2;
  }

  .checkbox-img /deep/{
    width: 16px;
    height: 16px;
    path {
      fill: #6dce6a
    }
  }

  .package-dynamic-value {

    font-weight: 300;
    font-size: 16px;
    color: #4b4f52

  }

  .package-button {
    width: 150px;
    min-width: 150px;
    height: 40px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    border: none;
    padding: 3px 10px 5px;
    &:hover {
      background-color: #00a575 !important;
    }
  }


  .package-disabled-button {
    width: 150px;
    min-width: 150px;
    height: 40px;
    border-radius: 2px;
    background-color: #8b95a1;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    border: none;
    padding: 3px 10px 5px;
    &:hover {
      background-color: #8b95a1 !important;
    }
  }

  .preferred-button {
    width: 150px;
    min-width: 150px;
    height: 40px;
    border-radius: 2px;
    background-color: rgba(242, 153, 51, 0.8);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    border: none;
    padding: 3px 10px 5px;
    &:hover{
      background-color: rgba(242, 153, 51, 1) !important;
    }
  }

  .price-button:hover {
    background-color: #00a575 !important;
  }

  .hover-helper {
    position: absolute;
    height: calc(100% - 66px);
    width: 188px;
    right: 0;
  }
</style>
