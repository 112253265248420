<template>
  <div>
    <div v-if="!receiver" class="loader" style="text-align: center; margin: 20px auto; height: 45px; width: 45px;"></div>
    <template v-else>


      <div v-if="activatedPlusModal" class="plusModal" style=" transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;">
        <div style=" background-color: rgba(0, 0, 0, 0.6);  z-index: 999999; margin: 0 auto; height: 10000%; position: fixed;  width: 100%; ">
          <div style=" margin: 0 auto; padding-bottom: 25px; background-color: #f4f5f7;  text-align: center;  width: 80%; ">

            <app-redirect :size="'modal'" :action="'afterSendMessage'" />

            <p style="margin-top: 20px;">
              <button @click="activatedPlusModal = false" class="basic-button basic-chat-button" style=" color: #2d3640!important;">Anladım, daha sonra...</button>
            </p>
          </div>
        </div>
      </div>

      <div :class="'mobileNavBar ' + deviceType">
        <div class="appBar-header">
          <div @click="routeBack('/panel/mesajlar/', false)" class="header-route-back">
            <span v-if="deviceType === 'ios'">
              <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
            <span v-else>
              <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
          </div>
          <div class="online-status-inner" :style="receiver.onlineStatus ? 'background-color: #67cc64;':'background-color: #bfc8d2;'"></div>
          <div class="header-title-container">
            <router-link :to="'/'+ receiver.username" style="text-decoration: none;">
              <span class="header-title">{{ receiver.username }}</span>
            </router-link>
            <div class="icon-div">
              <div v-if="f_t_info.isActive">
                <img class="chatbox-icons"
                     style="padding-top: 3px"
                     @click="openFreelancerChatLineModal(f_t_info,receiver)"
                     title="Sesli Arama"
                     src="https://gcdn.bionluk.com/site/icon/mm.svg">
              </div>
              <div v-else>
                <img class="chatbox-icons"
                     style="padding-top: 3px"
                     @click="openFreelancerChatLineModal(f_t_info,receiver)"
                     title="Sesli Arama"
                     src="https://gcdn.bionluk.com/site/icon/nn.svg">
              </div>


              <img class="chatbox-icons"
                   title="Settings"
                   @click="routeChatSettings(blocked, receiver)"
                   src="https://gcdn.bionluk.com/site/icon/tansel_chat_head_options.svg">
            </div>
          </div>
        </div>
      </div>
      <div :class="'underAppBar ' + deviceType"></div>


      <div v-if="infoBar.showOrderInfoBar" class="info-bar">
        <p style="padding-right: 4px;">Bu kullanıcıyla aranızda</p>
        <p v-if="infoBar.orderCount > 0">
          <router-link :to="'/panel/siparisler?username=' + receiver.username" style="color: rgb(230, 50, 98); ">{{infoBar.orderCount}} alış</router-link>
        </p>
        <p v-if="infoBar.orderCount > 0 && infoBar.salesCount > 0" style="padding-left: 4px;padding-right: 4px;"> ve </p>
        <p v-if="infoBar.salesCount > 0">
          <router-link :to="'/panel/satislar?username=' + receiver.username" style="color: rgb(230, 50, 98);">{{infoBar.salesCount}} satış</router-link>
        </p>
        <p style="padding-left: 4px;">işlemi var.</p>
      </div>


      <div class="message-detail-body" v-if="channelID">


        <div v-show="messageSeemorediv && !chatOpenLoading" class="seemorediv">
          <a @click="chatOpen($store.state.routerParams[0], messageOffset, true)" class="seemore">Daha fazla gör +</a>
        </div>
        <div v-show="!messageSeemorediv && !chatOpenLoading" style="height: 24px;"></div>
        <div v-show="chatOpenLoading" class="loader" style=" height:30px; width:30px;text-align: center; margin: 0 auto;"></div>


        <div class="messages-body" id="messages-body">
          <div id="message-array-div">
            <div class="chat-welcome-message" v-if="receiver && receiver.showWelcomeMessage">
              <div class="chat-welcome-message-user-info">
                <img :src="receiver.avatar_url"/>
                <div style="margin-left: 9px;">
                  <p class="welcome-message-user-info-username">{{receiver.username}}</p>
                  <star-rating v-if="receiver.rating.commentCount" :rating="receiver.rating.commentRating"
                               :max-rating="5"
                               :star-size="15"
                               :show-rating="false"
                               inactive-color="#dce1e1"
                               active-color="#ffb700"
                               :read-only="true"></star-rating>
                </div>
              </div>
              <div class="chat-welcome-message-content" style="background-color: white; font-size: 11px; text-align: center; padding: 10px 5px; border-radius: 30px;">
                <p>{{receiver.welcomeMessage}}</p>
              </div>
              <div class="chat-welcome-message-time-info">
                <div v-if=" receiver.lastseen !== 0" class="welcome-seperator"></div>
                <div class="chat-welcome-message-time-info-inner">
                  <div style="display: flex;">
                    <img src="https://gcdn.bionluk.com/site/icon/tanse_chatinfo_lastseen_v2.svg"/>
                    <p>Son Görülme</p>
                    <p v-if="receiver.onlineStatus" style="color: #67cc64;">Çevrimiçi</p>
                  </div>
                  <span v-if="!receiver.onlineStatus && receiver.lastseen !== 0">{{ receiver.lastseen | lastSeen }} önce</span>
                </div>
              </div>
            </div>
            <template v-for="(message, index) in messages">
              <div
                v-if="message.createdDay" class="chattarih">
                <span>{{message.createdDay}}</span>
              </div>

              <div class="messages-main">

                <div v-if="message.type === Constants.MESSAGE_TYPES.OFFER">
                  <div class="typeOrder"
                       :style="`background-color:${message.info.offer.bgcolor}; border: solid 1px ${message.info.offer.bordercolor};`">
                    <img style="height: 20px; margin-left: 10px;" :src="message.info.offer.icon">
                    <p style="color: #2d3640; font-weight: 500; font-size: 14px; text-align: left;">
                      {{ message.message }}
                    </p>
                    <div class="order-button" @click="activateCustomOfferDetailModal(message.info.offer)">
                      <p>{{message.info.offer.buttontext}}</p>
                    </div>
                  </div>
                  <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                       :style="message.u_uuid === user.uuid ? '' : 'margin-left: 0;'">{{ message.created_at | customDateFormat('HH:mm') }}
                  </div>
                </div>


                <div v-else-if="message.type === 'order'">
                  <div class="typeOrder"
                       :style="`background-color:${message.info.order.bgcolor}; border: solid 1px ${message.info.order.bordercolor};`">
                    <img style="height: 20px; margin-left: 10px;" :src="message.info.order.icon">
                    <p style="color: #2d3640; font-weight: 500; font-size: 14px; text-align: left;">
                      {{ message.message }}
                    </p>
                    <router-link style="text-decoration: none;" :to="'/orders/'+message.info.order.order_id">
                      <div class="order-button">
                        <p>{{message.info.order.buttontext}}</p>
                      </div>
                    </router-link>
                  </div>
                  <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'"
                       :style="message.u_uuid === user.uuid ? '' : 'margin-left: 0;'">{{ message.created_at | customDateFormat('HH:mm') }}
                  </div>
                </div>


                <div class="chatbubble-main" style=" margin-top: 5px;" v-else>
                  <div :style="{'display': 'flex','justify-content': message.u_uuid !== user.uuid ? 'flex-start' : 'flex-end'}">
                    <div v-if="message.u_uuid !== user.uuid"><img
                      style="width: 36px; height: 36px; border-radius: 50%; float: left; margin-right: 10px; margin-top: 5px;" :src="receiver.avatar_url"/></div>

                    <!--hide message-->
                    <div
                      v-if="message.hide_message"
                      style="max-width: 280px; display: flex;"
                      :class="message.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'">
                      <p style="font-size: 12px; font-style: italic; font-weight: 300;">{{message.hide_message_text}} </p>
                      <div class="hidden-message-info-div">
                        <img class="hidden-message-info-icon"
                             src="https://gcdn.bionluk.com/site/icon/tansel_info_black.svg"/>
                        <div class="tooltiptext">{{message.hide_message_description}}</div>
                      </div>
                    </div>

                    <!--is call message-->
                    <div
                      v-else-if="message.info && message.info.iscall"
                      style="max-width: 280px; display: flex;"
                      :class="message.u_uuid === user.uuid ? 'chatbubble right-iscall' : 'chatbubble left-iscall'">
                      <div class="iscall-body">
                        <img class="iscall-phone-icon"
                             src="https://gcdn.bionluk.com/site/icon/tansel_missed_call.svg"/>
                        <div>
                          <p class="iscall-username">{{receiver.username}} {{message.u_uuid === user.uuid ? 'arandı' : ' seni aradı'}}</p>
                          <p class="iscall-created-date">{{ message.created_at | customDateFormat('HH:mm') }}</p>
                        </div>
                      </div>
                    </div>

                    <!--normal massage-->
                    <div
                      :style="message.type === Constants.MESSAGE_TYPES.UPLOAD ? 'background-color: #D7EBFF; color: #2d3740; max-width: 280px;' :'max-width: 280px;'"
                      :class="message.u_uuid === user.uuid ? 'chatbubble right' : 'chatbubble left'"
                      v-else>
                      <span class="chatbuble-text" v-html="message.message"></span>
                      <div class="delete-uploaded-file" v-if="message.u_uuid === user.uuid && message.type === 'upload'" @click="deleteUploadedFile(message)">
                        <img src="https://gcdn.bionluk.com/site/c/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img"/>
                      </div>
                      <div class="partial-loader" v-if="deleteFileLoader && message.u_uuid === user.uuid && message.type === 'upload'">
                        <loader style="width: 30px; height: 30px;"/>
                      </div>
                      <div v-if="message.type === Constants.MESSAGE_TYPES.UPLOAD" style="padding-top: 10px;">
                        <div v-for="uploadChat in message.uploads">
                          <img style=" height: 16px; margin-top: 3px; position: absolute; display: inline-block;"
                               src="https://gcdn.bionluk.com/site/icon/tansel_chatin_attachment.svg">
                          <a target="_blank" :href="uploadChat.url" style="color: #56a5ff; padding-left: 22px;">{{ uploadChat.filename }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="clear: both"></div>
                  <div :class="message.u_uuid === user.uuid ? 'chattime right' : 'chattime left'">{{ message.created_at | customDateFormat('HH:mm') }}
                  </div>
                </div>


              </div>

              <div style="clear: both"></div>
            </template>
          </div>

          <div v-if="isSeenDate" class="isseendiv">
            <div class="seen">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_siyah_tick.svg">
              Görüldü
            </div>
            <div style="clear: both"></div>
          </div>


          <div v-if="onWriting" class="loading-div">
            {{receiver.username}} {{onWritingType === 'uploading' ? 'size dosya gönderiyor %' + parseInt(uploadingPercentage) : 'yazıyor'}}
          </div>

          <template v-if="$store.state.percentCompleted[channelID]" v-for="(value, key, index) in $store.state.percentCompleted[channelID]">

            <loader v-if=" value < 0.01 && value > 0"></loader>

            <div class="progress-main" v-if=" value > 0.01">
              <progress class="progressbar" :value='value' max="100"></progress>
              <p class="progress-percent">% {{ parseInt(value) }}</p>
              <a style="margin-left: 20px; margin-right: 15px;" @click="cancelUploadRequest(key)"> <img
                src="https://gcdn.bionluk.com/site/icon/tansel_chat_attachmentcancel.svg"/> </a>
            </div>
          </template>
        </div>
        <div class="first-message-body" v-if="firstMessage">
          <p class="first-message-header">Bu kullanıcı listende bulunmuyor</p>
          <img @click="firstMessage = false" :src="$root.CDN_SITE + 'icon/tansel_light_gray_close.svg'"/>
          <div class="first-message-button-div">
            <div @click="firstMessageActionButtons(channelID, 1)"
                 data-balloon-length="medium"
                 data-balloon="Gelen mesaj içerik olarak seni rahatsız ediyorsa ya da bu mesajın spam/reklam içerikli
                              olduğunu düşünüyorsan bu alanı kullanabilirsin. Böylece ’Yapılacaklar’ listenden
                              mesaja cevap ver uyarısı kalkacaktır. Bu alana tıkladığında karşı tarafa herhangi bir
                              bildirim gitmemektedir."
                 data-balloon-pos="up">Rahatsız oldum
            </div>
            <div @click="firstMessageActionButtons(channelID, 2)"
                 data-balloon-length="medium"
                 data-balloon="Bir kullanıcıdan artık mesaj almak istemiyorsan engelle seçeneğini kullanabilirsin.
                               Bu durumda kullanıcıya sen de mesaj gönderemezsin. Kendisini engellediğin bilgisi
                                ilgili kullanıcıya iletilir. Sana mesaj attıktan sonra engelle seçeneğini kullanırsan
                                ’Yapılacaklar’ listenden mesaja cevap ver uyarısı kalkacaktır.
                 " data-balloon-pos="up">Engelle
            </div>
            <div @click="firstMessageActionButtons(channelID, 3)"
                 data-balloon-length="medium"
                 data-balloon="Gelen mesajda rahatsız edici bir içerik yoksa bu mesaj önemli diyerek bize kullanıcı
                                hakkında olumlu bilgi verebilirsin. Bu alana tıkladığında karşı tarafa herhangi
                                bir bildirim gitmemektedir."
                 data-balloon-pos="up">Bu mesaj önemli
            </div>
          </div>
        </div>
        <div class="scroll-button" v-if="scrollButton" @click="scrollDown(true)">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
          <div class="scroll-button-message-count" v-if="messageCount">
            {{messageCount}}
          </div>
        </div>

        <!--input-->
        <div class="input-div" :style="messages.length < 5 ? 'margin-top: calc(100% + 22px)' : ''">
          <div v-if="!closedChatBoxText" style="position:relative;max-width: 100vw">

            <div v-show="!hide && !message" class="input-placeholder" style="visibility: visible;">Bir mesaj yazın...</div>
            <div :value="message"
                 @input="message = $event.target.innerHTML;whenInputFocus()"
                 @keypress="keyPressed($event)"
                 id="message-input"
                 class="input"
                 contenteditable="true"
                 data-tab="1"
                 dir="auto">
            </div>
            <div v-if="!closedChatBoxText && !showSendButton"
                 style="display: flex; flex-direction: row; position: absolute; right: 10px; top: 13px;z-index: 100">
              <label for="files_chat_workstation" style=" cursor: pointer;">
                <div title="Add Attachment" class="attachment-icon"></div>
              </label>
              <input id="files_chat_workstation" style="display:none;" type="file" @change="onFileChange">
              <div title="Send Offer" class="offer-icon" v-if="user.seller.isSeller" @click="activateCustomOfferModal()"></div>
            </div>
            <div class="send-button" @click="sendMessageButton()" v-if="!closedChatBoxText && showSendButton"> Gönder</div>
          </div>
          <div v-else class="input" style="padding-right: 30px">
            <input style="width: 100%" :disabled="closedChatBoxText" :placeholder="closedChatBoxText">
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="message-detail-body" v-else>
        <div v-for="x in 4" style="display: flex;  align-items: center; margin-bottom: 40px; margin-left: 15px;">
          <div class="messages-body-avatar-loader chat-loader"></div>
          <div>
            <div class="messages-body-message-loader chat-loader"></div>
            <div class="messages-body-time-loader chat-loader"></div>
          </div>
        </div>

        <div v-for="x in 3" style="display: flex; justify-content: flex-end;  align-items: center; margin-bottom: 40px; margin-right: 15px;">
          <div>
            <div class="messages-body-message-loader chat-loader"></div>
            <div class="messages-body-time-loader chat-loader" style="margin-left: auto;"></div>
          </div>
          <div class="messages-body-avatar-loader chat-loader" style="margin-right: 0; margin-left: 10px;"></div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
  import StarRating from 'vue-star-rating';
  import appRedirect from "@/pages/components/appRedirect";
  import { customDateFormat, getDayOfWeek } from '@/utils/dateFns'

  export default {
    name: "src-pages-body-workstation-messages-chat-chat_v2_mobile",
    components: {StarRating, appRedirect},
    data() {
      return {
      	chatTrackProps:{},
        activatedPlusModal: false,
        messageOffset: 0,
        messageIsseen: false,
        messageSeemorediv: false,
        messages: [],
        chatOpenLoading: false,
        receiver: {},
        openSettingBox: false,

        channelID: null,
        message: null,
        onWriting: null,
        onWritingType: null,
        uploadingPercentage: 0,
        hide: null,
        listenedWritingTimeout: null,
        triggeredWritingTimeout: null,
        sendWritingEvent: true,
        blocked: null,
        openedMessageMenu: false,
        closedChatBoxText: null,
        channel: null,
        uploads: [],
        archiveStatus: null,
        showSendButton: false,
        infoBar: {},
        f_t_info: {},
        firstMessage: false,
        scrollButton: false,
        messageCount: 0,

        force_bicoin: false,
        deleteFileLoader: false

      }
    },
    methods: {
      openFreelancerChatLineModal(info, receiver) {
        let go = {
          f_t_info: info,
          receiver: receiver,
          props:{
            userID:receiver.id,
            username:receiver.username
          }
        };
        this.$store.commit(this.types.ACTIVE_MODAL,
          {
            modalType: this.Modals.FREELANCER_CHAT_LINE,
            info: go
          }
        );
      },
      scrollEvent(e) {
        let height = e.target.scrollingElement.scrollHeight - document.getElementById('html-check').clientHeight;
        let scrollPosition = e.target.scrollingElement.scrollTop;
        if (height - scrollPosition > 100) {
          this.scrollButton = true;
        } else {
          this.scrollButton = false;
          this.messageCount = 0;
        }
      },
      scrollEventForMobile(e) {
        let height = document.getElementById('html-check').offsetHeight - document.getElementById('html-check').clientHeight;
        let scrollPosition = e.view.pageYOffset;
        if (height - scrollPosition > 100) {
          this.scrollButton = true;
        } else {
          this.scrollButton = false;
          this.messageCount = 0;
        }
      },
      chatOpen(username, offset = 0, comingFromSeemore = false) {
        let globalScrollMessage, messageArrayDiv, oldHeight;
        if (comingFromSeemore) {
          globalScrollMessage = document.getElementsByClassName('messages-body')[0];
          messageArrayDiv = document.getElementById("message-array-div"); // message-array-div
          oldHeight = messageArrayDiv.getBoundingClientRect().height;
        }
        this.chatOpenLoading = true;
        if (offset === 0) {
          this.messages = [];
        }
        this.api.workstation.chatOpen(username, offset, 20)
          .then(({data}) => {
            this.chatOpenLoading = false;
            this.openedMessageMenu = false;
            let result = data;
            if (result.success) {
              this.channelID = result.data.c_uuid;
              this.force_bicoin = result.data.force_bicoin;
              if (result.data.force_bicoin) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.UNLOCK_CHAT, info: {chatID: result.data.c_uuid, comingFrom: "mobileChat", disintermediation: result.data.disintermediationModal, totalBicoin: result.data.total_bicoin}});
              } else if (result.data.disintermediationModal) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.DISINTERMEDIATION, info: {comingFrom: "mobileChat"}});
              }

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              if ((result.data.ban_type === "mutual") || (result.data.ban_type === "user")) {
                this.blocked = true;
                this.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCK_MESSAGE;
              } else {
                if (result.data.ban_type === "sender") {
                  this.blocked = false;
                  this.closedChatBoxText = this.Constants.TEXTS.CHAT_BLOCKED_BY_OTHER_MESSAGE;
                } else {
                  // false durumu
                  this.blocked = false;
                  this.closedChatBoxText = null;
                }
              }

              this.messages = result.data.messages.concat(this.messages);
              this.messages.forEach(function (message) {
                message.created_at = Number(message.created_at);
              });
              this.messageSeemorediv = result.data.seemorediv;
              this.messageIsseen = result.data.isseen;
              if (!offset) {
                this.scrollDown();
              }
              if (comingFromSeemore) {
                this.$nextTick(() => {
                  window.scrollTo(0, messageArrayDiv.getBoundingClientRect().height - oldHeight + 50); //38 = loader'ların height'ları
                })
              }
              this.archiveStatus = result.data.status;
              this.messageOffset += 20;
              this.receiver = result.data.receiver;
              if (offset === 0) {
                this.Helper.trackEvents.pageView(this.Helper.chatPageProps(result.data.receiver));
              }
              this.infoBar = result.data.infoBar;
              this.f_t_info = result.data.f_t_info;
              this.firstMessage = result.data.firstMessage;
            } else {
              this.$router.push(this.prefixPath);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      activateCustomOfferDetailModal(offer) {
        this.api.seller.getOfferDetail(offer.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: result.data.offerDetail}});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      activateCustomOfferModal() {
        this.api.seller.retrieveGigs(null, 9999, 0, 1, 1, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {
                modalType: this.Modals.CUSTOM_OFFER,
                info: {
                  data: {
                    type: 'customOffer',
                    gigs: result.data.gigs,
                    receiver: this.receiver,
                    openedChannelID: this.channelID
                  }
                }
              })
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      keyPressed(event) {

        if (event.keyCode === 13 && (event.shiftKey || event.altKey || event.ctrlKey)) {
          if (event.altKey || event.ctrlKey) {
            document.getElementById('message-input').innerHTML = document.getElementById('message-input').innerHTML ? document.getElementById('message-input').innerHTML : '';
            if (!document.getElementById('message-input').childElementCount) {
              let createdBr = document.createElement('br');
              document.getElementById('message-input').appendChild(createdBr);
            }
            document.getElementById('message-input').appendChild(document.createElement('br'));
            this.placeCaretAtEnd(document.getElementById('message-input'));
          }


        } else if (event.keyCode === 13) {
          event.returnValue = false;
          let checkMessage = this.message ? this.message.split('<br>').join('') : null;
          checkMessage = this.message ? checkMessage.trim() : null;

          if (this.message && this.message.length && checkMessage && checkMessage.length && !this.closedChatBoxText) {
            this.sendMessage('message', this.message);
          }
        }

        if (event.keyCode !== 13 && !event.altKey && !event.shiftKey && !event.ctrlKey) {
          if (this.sendWritingEvent) {
            this.sendWritingEvent = false;
            this.triggeredWritingTimeout = setTimeout(() => {
              this.sendWritingEvent = true
            }, 5000);
            this.api.workstation.sendWriting(this.channelID);
          }
        }
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        let timeStamp = +new Date();
        this.uploads.push({stamp: timeStamp});
        setTimeout(() => {
          this.scrollDown();
        }, 0);
        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.MESSAGE, this.channelID, timeStamp)
          .then(result => {
            this.sendMessage('upload', null, result.externalID, result.c_uuid);
            e.target.value = '';
          }).catch(err => {
          e.target.value = '';
        });

      },
      sendMessage(type, message = null, externalID = null, c_uuid = this.channelID) {
        this.firstMessage = false;
        let payload_id = +new Date() + Math.floor(Math.random() * 1000) + 1;
        payload_id = payload_id.toString();
        if(c_uuid === this.channelID)
          this.messages.push({message, u_uuid: this.user.uuid, payload_id});
        this.scrollDown();
        this.showSendButton = false;

	      let lastSeenIdForRemove = '';

        let postProp = {userID:this.receiver.id}
	      this.api.workstation.sendMessageV2(type, c_uuid, message, externalID, this.force_bicoin, payload_id, lastSeenIdForRemove,JSON.stringify(postProp))
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.messageIsseen = null;
              let temp = [];
              this.messages.forEach(function (message) {
                if (message.payload_id === result.data.payload_id) {
                  temp.push(result.data);
                } else {
                  temp.push(message);
                }
              });
              this.messages = temp;
	            let chatProps = {...this.receiver, ...this.chatTrackProps};
              this.Helper.trackEvents.sendMessage(this.Helper.sendMessageProps("chatDetail", chatProps));
              this.activatedPlusModal = true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.arrangeMessages(payload_id);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.arrangeMessages(payload_id);
          });
        if (type === "upload") {
          document.getElementById('message-input').focus();
        } else {
          document.getElementById('message-input').innerHTML = '';
          this.message = null;
        }
      },
      arrangeMessages(payload_id) {
        let temp = [];
        this.messages.forEach(function (message) {
          if (message.payload_id !== payload_id) {
            temp.push(message);
          }
        });
        this.messages = temp;
      },
      blockUser(receiver) {
        let alert = `${receiver.r_username} isimli ${this.blocked ? 'kullanıcının' : 'kullanıcıyı'} ${this.blocked ? 'engelini kaldırmak' : 'engellemek'} istediğine emin misin?`;
        if (confirm(alert)) {
          if (this.blocked) {
            this.api.workstation.unblockUser(receiver.uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.blocked = false;
                  this.openSettingBox = false;
                  this.$toasted.global.infoToast({description: result.message});
                  this.initializeMethods();
                }
              })
              .catch(err => []);
          } else {
            this.api.workstation.blockUser(receiver.uuid)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.blocked = true;
                  this.openSettingBox = false;
                  this.$toasted.global.infoToast({description: result.message});
                  this.initializeMethods();
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
              })
              .catch(err => [])
          }
        }
      },
      inputEvent(event) {
        if (event.which === 13) {
          this.getUsers(this.activeTitle, this.searchTerm, null, null);
        }
      },
      closeEvent() {
        this.openSettingBox = false;
      },
      routeChatSettings(blocked, receiver) {
        this.$store.state.chatDetail.blocked = blocked;
        receiver.archiveStatus = this.archiveStatus;
        this.$store.state.chatDetail.receiver = receiver;
        this.$store.state.chatDetail.channelID = this.channelID;

        this.$router.push('/chat/' + receiver.username + '/detail')
      },
      scrollDown(scrollButton = false) {
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
          if (scrollButton) {
            this.messageCount = 0;
          }
        });
      },
      whenInputFocus() {
        if (this.message.length > 0) {
          this.hide = true;
          this.showSendButton = true;
        } else {
          this.hide = false;
          this.showSendButton = false;
        }
      },
      sendMessageButton() {
        let checkMessage = this.message ? this.message.split('<br>').join('') : null;
        checkMessage = this.message ? checkMessage.trim() : null;
        if (this.message && this.message.length && checkMessage && checkMessage.length && !this.closedChatBoxText) {
          this.sendMessage('message', this.message);
          document.getElementById('message-input').focus();
        }
      },
      firstMessageActionButtons(uuid, type, message = null) {
        this.api.chat.reportConversation(uuid, type, message)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if (type === 1 || type === 2) {
                this.routeBack('/', false)
              }
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
        this.firstMessage = false;
      },
      deleteUploadedFile(message) {
        this.deleteFileLoader = true;
        const id = message.id;
        this.api.chat.deleteMessage(id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              const index = this.messages.findIndex(m => m.id === id);
              if (index !== -1) {
                this.messages[index].hide_message = result.data.hide_message;
                this.messages[index].hide_message_description = result.data.hide_message_description;
                this.messages[index].hide_message_text = result.data.hide_message_text;
              }

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.deleteFileLoader = false;
          })
          .catch(err => {
            this.deleteFileLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },
    watch: {
      activatedPlusModal(newVal, oldVal) {
        if (newVal) {
          document.documentElement.style.overflow = 'hidden';
          document.body.scroll = 'yes';
        } else {
          document.documentElement.style.overflow = 'auto';
          document.body.scroll = 'no';
        }
      },

      "$store.state.routerParams": function (newValue, oldValue) {
        if (newValue.length) {
          this.channelID = null;
        }
      },
    },
    computed: {
      isSeenDate() {
        if (this.messageIsseen) {
          this.scrollDown()
          if (getDayOfWeek(new Date()) !== getDayOfWeek(this.messageIsseen)) {
            return customDateFormat(this.messageIsseen)
          } else {
            return customDateFormat(this.messageIsseen, 'HH:mm')
          }
        } else {
          return null;
        }
      }
    },
    mounted() {
    	let chatTrackProps = {};
    	if(this.$store.state.clickedProps){
		    chatTrackProps = this.$store.state.clickedProps;
      }
    	if(this.$store.state.whereFrom){
		    chatTrackProps.whereFrom = this.$store.state.whereFrom;
		    this.$store.state.whereFrom = null;
      }
    	if(this.$store.state.clickedFrom){
		    chatTrackProps.clickedFrom = this.$store.state.clickedFrom;
      }
    	this.chatTrackProps = chatTrackProps;


      this.chatOpen(this.$store.state.routerParams[0]);
      window.addEventListener('touchmove', this.scrollEventForMobile);
      window.addEventListener('scroll', this.scrollEvent);
    },
    beforeDestroy() {
      window.removeEventListener('touchmove', this.scrollEventForMobile);
      window.removeEventListener('scroll', this.scrollEvent);

      if (this.uploads.length) {
        this.uploads.forEach(upload => {
          this.cancelUploadRequest(upload.stamp);
        });
        this.uploads = [];
      }
    }
  }
</script>

<style scoped lang="scss">
  .header-title-container {
    margin-left: 8px;
  }

  .online-status-inner {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    align-self: center;
    margin-bottom: -4px;
    margin-left: 12px;
  }

  /*messages*/

  .chattarih {
    text-align: left;
    border-bottom: solid 1px #dde3eb;
    line-height: 0.1em;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .chattarih span {
    background: #fff;
    padding: 0 15px;
    font-size: 14px;

    color: #8b95a1;
  }

  .seemore {
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .seen {
    margin-top: 15px;
    float: right;
    font-size: 12px;
    color: #242424;
  }

  .chatsend {
    position: absolute;
    z-index: 11;
    margin-left: 555px;
    height: 36px;
    margin-top: 5px;
    border-radius: 2px;
    margin-bottom: 0;
  }

  .horizontal-seperator {
    width: 0;
    border-left: 1px solid #eaedf2;
  }

  .message-detail-body {
    width: 100%;
    flex: 1;
    height: calc(100vh - 96px);
    padding-top: 48px;

    .message-detail-header {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 27px 17px 27px;
    }
  }

  .icon-div {
    display: flex;
    align-items: center;

    .chatbox-icons {
      cursor: pointer;
      margin-left: 25px;
    }
  }

  .setting-box {
    position: absolute;
    width: 112px;
    height: 62px;
    padding: 18px 10px 10px 19px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
    right: -19px;
    top: 43px;
    z-index: 10;

    .setting-box-options {
      cursor: pointer;
      font-size: 14px;
      line-height: 2.29;
      color: #737878;
    }
  }

  .setting-box::after {
    content: '';
    position: absolute;
    right: 17px;
    top: -10.8px;
    width: 20px;
    height: 20px;
    border: 1px solid #bfc8d2;
    border-bottom: 0;
    border-right: 0;
    border-radius: 3px;
    transform: rotate(45deg);
    background-color: #fff;
  }

  .message-detail-username {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #242424;
  }

  .seemorediv {
    text-align: center;

  }

  .messages-body {
    overflow: scroll;
    padding: 20px 21px 10px 27px;
    //min-height: 430px
  }

  .messages-main {
    position: relative;
    margin-top: 10px;
  }

  .typeOrder {
    height: 78px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    background-color: #d7f8f5;
  }

  .order-button {
    width: 120px;
    height: 36px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    line-height: 36px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #2d3640;
    margin-right: 10px;
  }

  .chatbubble.right {
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: #f6d8d8;
    float: right;
    border-radius: 7.5px;
    border-bottom-right-radius: 0;
  }

  .chatbubble.left {
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #e4e8ed;
    float: left;
    border-radius: 7.5px;
    border-bottom-left-radius: 0;
  }

  .chatbubble {
    position: relative;
    max-width: 420px;
    width: auto;
    display: inline-block;
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.29;
    padding: 8px 15px;
  }

  .chatbuble-text {
    font-size: 13px;

    a {
      color: #fff
    }
  }

  .chattime {
    margin-bottom: 10px;
  }

  .chattime.right {
    position: relative;
    float: right;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-right: 5px;
  }

  .chattime.left {
    position: relative;
    float: left;
    font-size: 11px;
    color: #939c9c;
    margin-top: 5px;
    margin-left: 50px;
  }

  .loading-div {
    font-size: 15px;
    margin-bottom: -5px;
  }

  .loading-div:after {
    display: inline-block;
    animation: dotty steps(1, end) 1.5s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }

  /*input*/

  .input-div {
    position: sticky;
    bottom: 0;
    height: auto;
    width: 100%;
    border-top: solid 2px #bfc8d2;
  }

  .marginTop100 {
    margin-top: 100%;
  }

  .input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    margin-left: 21px;
    margin-top: 14px;

    color: #afb8b8;
    pointer-events: none;
    transition: opacity .08s linear;
    z-index: 100;
    font-size: 14px;
    line-height: 20px;

  }

  div.input {
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 0 2px 0 0;

    min-height: 34px;
    max-height: 124px;
    background-color: #f4f5f7;

    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    resize: none;
    padding: 13px 10px;
    padding-right: 80px;
    padding-bottom: 5px;
    font-size: 16px;

    color: #2d3640;
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

  div.input:focus {
    outline: none;
  }

  .attachment-icon {
    width: 22px;
    height: 24px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment.svg') no-repeat;
  }

  .attachment-icon:hover {
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_attachment_hover.svg') no-repeat;
  }

  .offer-icon {
    margin-left: 15px;
    cursor: pointer;
    width: 22px;
    height: 24px;
    background: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer.svg') no-repeat;
  }

  .offer-icon:hover {
    background-image: url('https://gcdn.bionluk.com/site/icon/tansel_chat_bottom_customoffer_hover.svg');
  }

  .send-button {
    padding: 8px;
    background-color: #e63262;
    color: #fff;
    margin-left: 15px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 100
  }

  /*progress bar */
  .progress-main {
    background-color: #ebf9fc;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: -27px;
    margin-top: 20px;

    .progress-percent {
      font-size: 16px;
      font-weight: 500;
      color: #85aab1;
      margin-left: 9px;
      width: 45px;
    }
  }

  .progressbar {
    margin-top: 0;
    margin-left: 15px;
    width: 100%;
    height: 6px;
  }

  .progressbar::-webkit-progress-bar {
    background-color: #c9e7ed;
    border-radius: 100px;

  }

  .progressbar::-webkit-progress-value {
    border-radius: 100px;
    background-color: #67cc64;
  }

  .progressbar[value] {
    background-color: #c9e7ed;
    border-radius: 100px;
  }

  .progressbar::-moz-progress-bar {
    border-radius: 100px;
    background-color: #67cc64;
  }

  .info-bar {

    position: fixed;
    margin-top: -4px;
    height: 40px;
    width: 100vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    z-index: 10;


    color: #2d3640;

    background: #ffefda;
  }

  .tel-line-bar {
    position: fixed;
    width: 100vw;
    margin-top: -4px;

    height: 32px;
    padding-left: 12px;
    z-index: 9999999;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    background: #e63262;
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
    color: #ffffff;
    opacity: 0.9;

  }

  /*firstmessage css*/

  .first-message-body {
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #bfc8d2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {

    }

    img {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

  .first-message-button-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;

    div {
      padding: 10px;
      background-color: #fff;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #bfc8d2;
      font-size: 14px;
      border-radius: 2px;

      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }
  }

  .first-message-header {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

  .chat-welcome-message {
    min-height: 156px;
    background-color: #f4f5f7;
    padding: 20px 22px;
  }

  .chat-welcome-message-user-info {
    display: flex;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      border-radius: 100px;
    }

    .welcome-message-user-info-username {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #242424;
      margin-bottom: 2px;
    }
  }

  .chat-welcome-message-content {
    font-size: 14px;
    line-height: 1.29;
    text-align: left;
    color: #8b95a1;
    margin-top: 16px;
    margin-bottom: 11px;
  }

  .chat-welcome-message-time-info {
    display: flex;
    flex-direction: column;

    .welcome-seperator {
      width: 100%;
      border-bottom: solid 1px #bfc8d2;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .chat-welcome-message-time-info-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 14px;
        line-height: 1.29;
        color: #8b95a1;
        margin-left: 7px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .right-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #242424;
    background-color: #fff;
    float: right;
    border-radius: 5px;
    border: solid 1px #e4e8ed;
  }

  .left-iscall {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.43;
    color: #2d3740;
    background-color: #fff;
    float: left;
    border: solid 1px #e4e8ed;
    border-radius: 5px;
  }

  .iscall-callback-div {
    background-color: rgb(230, 50, 98);
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    margin: 0 -15px -8px -15px;
    margin-top: 10px;
    cursor: pointer;
  }

  .iscall-body {
    display: flex;
    flex-direction: row;

    .iscall-phone-icon {
      margin-right: 8px;
    }

    .iscall-username {
      margin-bottom: 5px;
    }

    .iscall-created-date {
      color: rgb(147, 156, 156);
    }
  }


  .hidden-message-info-icon {
    position: relative;
    margin-left: 5px;
    margin-bottom: -3px;
  }

  .scroll-button {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: solid 1px #bfc8d2;
    position: sticky;
    bottom: 90px;
    left: 80%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

    .scroll-button-message-count {
      position: absolute;
      top: -5px;
      left: -3px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: rgb(230, 50, 98);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 15px;
      font-weight: 600;
    }
  }

  .hidden-message-info-div:hover {
    position: relative;

    .tooltiptext {
      visibility: visible;
    }
  }

  .tooltiptext {
    visibility: hidden;
    width: 128px;
    background-color: #ffffff;
    color: #afb8b8;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    padding: 5px 10px;
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -130px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfc8d2;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 44px;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }


  /*loader part*/
  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .chat-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .messages-body-avatar-loader {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #dfe4ec;
    margin-right: 10px;
  }

  .messages-body-message-loader {
    width: 140px;
    height: 16px;
    margin-bottom: 10px;
    background-color: #dfe4ec;
  }

  .messages-body-time-loader {
    width: 60px;
    height: 10px;
    background-color: #dfe4ec;
  }

  .delete-uploaded-file {
    z-index: 3;
    box-sizing: border-box;
    position: absolute;
    background-color: #65c4db;
    border: 2px solid #d7ebff;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -12px;
    left: -12px;
    cursor: pointer;

    .delete-uploaded-file-img /deep/ {
      width: 9px;
      height: 12px;

      path {
        fill: #fff;
      }
    }
  }
</style>
