<template>
  <transition name="modal">
    <div class="modal-mask">
      <div v-if="getModalInfo" v-loader-screen="waitUntilRequestDone" class="modal-container" @click.stop>
        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="modal-inner">
          <p class="modal-header">E-Posta <span>Değişiklik Talebi</span></p>
          <img src="https://gcdn.bionluk.com/site/c/ic-open-envelope.svg" onload="SVGInject(this)" class="open-envelope-img" />
          <p class="modal-text">
            Email değişiklik talebini aldık ve yeni girdiğin e-posta adresine bir onay e-postası gönderdik. Değişikliğin tamamlanması için gelen e-posta içerisindeki bağlantıya tıklaman gerekiyor. Buna tıklayana kadar mevcut e-posta adresingeçerli olacaktır.
          </p>

          <div class="button-body">
            <custom-button button-type="dark" style="width: 200px;" :disabled="waitUntilRequestDone" @click="hide()">Tamam</custom-button>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>

  import CustomButton from '../../components/CustomButton'

  export default {
    name: "src-pages-modals-changeEmail-v1",
    components: {
      CustomButton,
    },
    data() {
      return {
        openedModal: false,
        waitUntilRequestDone: false,
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info && !this.openedModal) {
          this.openedModal = true;
        }
        return true;
      }
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    box-sizing: border-box;
    width: calc(100vw - 20px) !important;
    max-height: 640px;
    max-width: 770px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 50px 140px 50px 140px;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #2d3640;

    span {
      font-weight: 600;
    }
  }

  .modal-text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 21px;
    color: #5e6b79;
  }

  .button-body {
    display: flex;
    margin-top: 70px;
  }

  .open-envelope-img /deep/{
    width: 50px;
    height: 47px;
    path {
      fill: #fd4056;
    }
  }

</style>
