<template>
  <div>
    <div class="register" v-loader-screen="disableRegister">
      <div style="margin: 0 auto; width:100%; text-align: center">
        <div class="hiddendiv"></div>

        <template v-if="!user.email">
          <div class="subheading1-text">
            <facebook-login v-if="false" style="display: inline-block!important; margin-left: -18px; cursor: pointer;" :activateLink="true"></facebook-login> veya <google-login style="display: inline-block!important; margin-left: -10px; cursor: pointer;" :activateLink="true"></google-login> ile kaydolun.
          </div>
        </template>
        <template v-if="user.email">
          <span class="subheading2-text">Son bir adım daha kaldı. Kullanıcı adı ve şifre belirleyerek kaydı tamamlayabilirsin.</span>
        </template>
        <div class="hiddendiv"></div>
        <div class="form-area">

          <div class="hiddendiv"></div>
          <form @submit.prevent="register(firstname, lastname, username, email, password)">
            <div  style="width: 90%; margin: 0 auto; text-align: center">

              <div>
                <input :class="{ error: errorInfo.firstname }" type="text" placeholder="İsim" v-model="computedFirstname" style="width: 90%; font-size: 16px; padding-left: 10px;">
                <span v-if="errorInfo.firstname" class="errorMessage">{{ errorInfo.firstname.message }}</span>
              </div>
              <div class="hiddendiv20"></div>
              <div style="">
                <input :class="{ error: errorInfo.lastname }" type="text" placeholder="Soy İsim" v-model="computedLastname"  style="width: 90%; font-size: 16px; padding-left: 10px;">
                <span v-if="errorInfo.lastname" class="errorMessage"> {{ errorInfo.lastname.message }} </span>
              </div>



              <div class="hiddendiv10"></div>
              <label style="font-size: 12px; text-align: left; padding-left: 15px; margin-bottom: 5px; color: #8b95a1;">bionluk.com/<span style="color: #2d3640;font-weight: 600;">{{username}}</span></label>
              <input :class="{ error: errorInfo.username }" @focus="createUsername()" type="text" placeholder="Kullanıcı Adı" v-model="username"  style=" width: 90%;font-size: 16px; padding-left: 10px;">
              <span v-if="errorInfo.username" class="errorMessage">{{ errorInfo.username.message }}</span>

              <div class="hiddendiv20"></div>
              <input :class="{ error: errorInfo.email }" type="text" placeholder="E-mail" v-model="computedEmail" style="width: 90%;font-size: 16px; padding-left: 10px;">
              <span v-if="errorInfo.email" class="errorMessage">{{ errorInfo.email.message }}</span>

              <div class="hiddendiv20"></div>

              <input  :class="{ error: errorInfo.password }" type="password" maxlength="16" placeholder="Şifre" v-model="password"  style="width: 90%;font-size: 16px;  padding-left: 10px;">
              <span v-if="errorInfo.password" class="errorMessage">{{ errorInfo.password.message }}</span>

              <div class="hiddendiv20"></div>
            </div>
            <div class="hiddendiv10"></div>

            <div style="font-size: 12px; line-height: 16px; width: 90%; text-align: center; margin: 0 auto;">
              Kaydol'a basarak
              <router-link :to="`${prefixPath}pages/kullanici-sozlesmesi`">Kullanıcı Sözleşmesi</router-link> ve <router-link :to="`${prefixPath}pages/gizlilik`">Gizlilik Sözleşmesini</router-link> kabul ediyorum.
            </div>
            <div class="hiddendiv10"></div>
            <button :disabled="disableRegister" type="submit" class="super-button-green">Kayıt Ol</button>
          </form>

        </div>





        <div class="hiddendiv20"></div>
        <p class="subheading2-text" style="font-weight: 500">Zaten bir hesabın var mı?
          <router-link :to="'/login'" >Giriş Yap</router-link>
        </p>
        <div class="hiddendiv20"></div>
      </div>

    </div>
  </div>
</template>

<script>

  import mixin from '../mixin';
  export default {
    name: "src-pages-body-auth-registerWithEmail-mobile-v1_mobile",
    mixins: [mixin],

    data() {
      return { }
    },

    methods: { },

    computed: { }
  }

</script>

<style scoped>

  .hiddendiv{height: 30px; visibility: hidden; }
  .hiddendiv20{height: 20px; visibility: hidden; }


  .form-area{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
  }

  .form-area p{
    font-size: 16px;
    color: #5E6B79;
    text-align:center;
    margin:46px 0 44px;
  }

  .form-area a{
    text-decoration: none;
    color: #37414b;
  }

  form{
    text-align: center;
  }

  button.signUp{

    width: 158.7px;
    height: 44px;
    margin-top: 12px;
    border-radius: 2px;
    border:none;
    background-color: #2d3640;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;


  }


  .error{
    border: 2px solid #d0011b!important;
  }

  .errorMessage{

    color: #d0011b;
    font-size: 14px;
  }


</style>
