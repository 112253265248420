/**
 * Created by haluk on 4/14/17.
 */

const v2 = resolve => require(['./v2.vue'], resolve);
const v2_mobile = resolve => require(['./v2_mobile.vue'], resolve);
const v2_white = resolve => require(['./v2_white.vue'], resolve);

export default {
  v2,
  v2_mobile,
  v2_white,
}
