/**
 * Created by bugra on 20.04.2017.
 */

const v1 = resolve => require(['./v2.vue'], resolve);
const v2 = resolve => require(['./v2.vue'], resolve);
const v1_mobile = resolve => require(['./v1_mobile.vue'], resolve);

export default {
  v1,
  v2,
  v1_mobile
}
