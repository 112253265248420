<template>
  <div>
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="position: relative">
      <div @click="slide('back')" class="backBox" :style="slideNo > 0 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg">
      </div>
      <div @click="slide('next')" class="nextBox" :style="items.length - slideNo > 4 ? '' : 'display:none'">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg">
      </div>
      <div style="position: relative!important; overflow: hidden">

        <div class="cardBoxContainer"  :style="'height:303px; transform: translate3d(-'+(228*slideNo)+'px, 0px, 0px);'">
          <div class="cardBoxFlexC" v-for="item in items" :key="item.name">
            <router-link @contextmenu.native="handleGlobalClick($store.state.componentMap.page, 'popularCategories')" @click.native="handleGlobalClick($store.state.previousComponentPage, 'popularCategories')" style="text-decoration: none!important;" :to="'/freelancer-bul/'+item.url">
              <div class="hBox">
                <img :src="item.image">
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
                  <p class="name">{{item.name}}</p>
                  <p class="freelancer"><span>{{item.freelancer_count}}</span> <span v-if="user.seller && user.seller.gigCount && user.seller.oldGigCount">alıcı</span><span v-else>freelancer</span></p>
                  <p v-if="user.seller && user.seller.gigCount && user.seller.oldGigCount" class="sub-text">bu kategoriye bakıyor</p>
                  <p v-else class="sub-text">hizmet veriyor</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>

	import Loader from "../toolbox/loader.vue";
	export default {
		props: ["list"],
		components:{Loader},
		data() {
			return {
				pageLoaded:false,
				slideNo: 0,
				sliding:false,

				items: this.list || [],

			};
		},

		methods:{


			slide(what){

				this.sliding = true;
				setTimeout(function(){
					this.sliding = false;
				}, 500);

				if(what === 'next'){
					this.slideNo++;
				} else{
					this.slideNo--;
				}

			},

			retrieveItems() {

				if(!this.list || this.list.length === 0){
					this.pageLoaded = false;
					this.api.feeds.newMainPage('category')
						.then(({data}) => {
							let result = data;
							this.pageLoaded = true;
							if (result.success) {
								this.items = result.data;
								this.$store.state.popularCategories = this.items;
							} else {
								this.pageLoaded = true;
							}
						})
						.catch(err => {
							this.$toasted.global.errorToast({description: err});
						});
				} else {
					this.pageLoaded = true;
				}
			},
		},

		created() {

			this.retrieveItems();

		}
	}
</script>

<style scoped lang="scss">

  .nextBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    right: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }


  .backBox{
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    top:calc(50% - 22px);
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    img{
      width: 18px;
      height: auto;
    }
  }
  .cardBoxContainer{

    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;

    .cardBoxFlexC{

      display: flex;


    }




    .hBox{
      text-decoration: none!important;
      width: 209px;
      height: 295px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

      position: relative;
      display: flex;



      flex-direction: column;
      align-items: center;
      margin-right: 17px;



      img{
        width: 210px; height: 180px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .name{
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: center;
        color: #2d3640;
        &:hover{
          text-decoration: none;
        }
      }

      .freelancer{
        margin-top: 11px;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #00a575;
        span{
          font-weight: bold;
        }
        &:hover{
          text-decoration: none;
        }
      }

      .sub-text{
        margin-top: 5px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #8b95a1;
        &:hover{
          text-decoration: none!important;
        }
      }
    }



  }


</style>
